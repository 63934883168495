import moment from 'moment'
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { addBettingCartItem, setSelectedBonusFolder } from '../../../redux/sportsBettingSlice'
import {
  fetchDomesticFixtureListAction,
  fetchDomesticFixtureMarketAction,
  setFixtureCollapse,
  setFixtureMarketAdditionalOption,
  setFixtureMarketParam,
  setPageNum,
} from '../../../redux/sportsDomesticInfoSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import { DomesticBetBox } from '../../../shared/components/SportDomesticBetBox'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { SportMarketType } from '../../../utils/enums/SportsGame/SportsGameEnums'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'

const SportEventFixtureItem = React.memo(
  ({ fixtureKey, fixtureData, onClickFixtureMarketAdditionalOptionHandler, onClickAddBettingCartHandler }) => {
    const filteredMainMarkets = useMemo(() => {
      return fixtureData.mainMarkets.filter(mainMarket => {
        return (
          mainMarket.usedMarket &&
          mainMarket.marketVisible &&
          mainMarket.betLines.every(betLine => {
            return betLine.bets.every(bet => {
              return bet.showStatus && (bet.betStatus === 'OPEN' || bet.betStatus === 'SUSPENDED')
            })
          })
        )
      })
    }, [fixtureData.mainMarkets])

    return (
      <Fragment key={`FixtureItem-${fixtureKey}-${fixtureData.fixtureId}`}>
        <div className="special_line">
          <table>
            <tbody>
              <tr>
                <td className="time">{convertToKstByFormat(fixtureData.fixture.startDate, 'MM/DD HH:mm')}</td>
                <td className="title">
                  {fixtureData.fixture.homeName} vs {fixtureData.fixture.awayName}
                </td>

                {fixtureData.totalMarketCount > 0 && (
                  <td
                    className={fixtureData.additionalOption ? 'addOption_pick' : 'addOption'}
                    onClick={() => {
                      onClickFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData.fixtureId)
                    }}
                  >
                    추가 배팅옵션
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        {fixtureData.additionalOption ? (
          <>
            {fixtureData.markets.map(market => {
              if (!market.usedMarket || !market.marketVisible) return null

              const filterBetLines = market.betLines.filter(betLine => {
                // 모든 bets가 'SUSPENDED'인 경우를 체크합니다.
                // const allSuspended = betLine.bets.every(bet => bet.betStatus === 'SUSPENDED')

                // if (allSuspended) return false

                // 각 betLine의 bets 배열을 검사하여 모든 조건을 충족하는지 확인합니다.
                return betLine.bets.every(bet => {
                  // showStatus가 true여야 하고, betStatus가 'OPEN' 또는 'SUSPEND'여야 합니다.
                  return bet.showStatus && (bet.betStatus === 'OPEN' || bet.betStatus === 'SUSPENDED')
                })
              })

              if (filterBetLines.length === 0) return null

              const customMarket = {
                ...market,
                betLines: filterBetLines,
              }

              return (
                <DomesticBetBox
                  fixtureInfo={{
                    fixtureId: fixtureData.fixtureId,
                    ...fixtureData.fixture,
                  }}
                  marketInfo={customMarket}
                  onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                />
              )
            })}
          </>
        ) : (
          <>
            {/* 없을 때 */}
            {filteredMainMarkets.length === 0 || filteredMainMarkets.every(itme => itme.betLines.length === 0) ? (
              <div className="gtype1">
                <table>
                  <tbody>
                    <tr>
                      <td className="time_special" />
                      <td
                        className="home"
                        onClick={() => {
                          onClickFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData.fixtureId)
                        }}
                      >
                        <span>{fixtureData.fixture.homeName}</span>
                      </td>
                      <td className="draw_hc">
                        <b>VS</b>
                      </td>
                      <td
                        className="away"
                        onClick={() => {
                          onClickFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData.fixtureId)
                        }}
                      >
                        <span>{fixtureData.fixture.awayName}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                {fixtureData.mainMarkets.map(mainMarket => {
                  if (!mainMarket.usedMarket || !mainMarket.marketVisible) return null

                  const filterBetLines = mainMarket.betLines.filter(betLine => {
                    // 모든 bets가 'SUSPENDED'인 경우를 체크합니다.
                    // const allSuspended = betLine.bets.every(bet => bet.betStatus === 'SUSPENDED')

                    // if (allSuspended) return false

                    // 각 betLine의 bets 배열을 검사하여 모든 조건을 충족하는지 확인합니다.
                    return betLine.bets.every(bet => {
                      // showStatus가 true여야 하고, betStatus가 'OPEN' 또는 'SUSPEND'여야 합니다.
                      return bet.showStatus && (bet.betStatus === 'OPEN' || bet.betStatus === 'SUSPENDED')
                    })
                  })

                  if (filterBetLines.length === 0) return null

                  const customMarket = {
                    ...mainMarket,
                    betLines: filterBetLines,
                  }

                  return (
                    <DomesticBetBox
                      fixtureInfo={{
                        fixtureId: fixtureData.fixtureId,
                        ...fixtureData.fixture,
                      }}
                      marketInfo={customMarket}
                      onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                    />
                  )
                })}
              </>
            )}
          </>
        )}
      </Fragment>
    )
  },
)

const SportEventFixtures = React.memo(
  ({
    eventKey,
    event,
    onClickFixtureCollapseHandler,
    onClickFixtureMarketAdditionalOptionHandler,
    onClickAddBettingCartHandler,
  }) => {
    return (
      <div className="lg_group">
        <div className="lg_line" onClick={() => onClickFixtureCollapseHandler(eventKey)}>
          <img src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${event.sportId}.png`} align="absmiddle" alt="" />

          {event.locationImageUrl && <img src={event.locationImageUrl} alt="" />}

          {event.leagueImageUrl && <img src={event.leagueImageUrl} alt="" />}

          <span style={{ color: '#fff' }}>
            [{event.locationName}] {event.leagueName}
          </span>

          <span style={{ float: 'right' }}>
            {event.fixtureLeagueCollapse ? <SportsEventTitleArrowUpIcon /> : <SportsEventTitleArrowDownIcon />}
          </span>

          <span style={{ color: '#fff', float: 'right' }}>{event.fixtures.length}경기</span>
        </div>

        {event.fixtureLeagueCollapse && (
          <>
            {event.fixtures.map(fixtureData => {
              return (
                <SportEventFixtureItem
                  key={`${eventKey}-${fixtureData.fixtureId}`}
                  fixtureKey={eventKey}
                  fixtureData={fixtureData}
                  onClickFixtureMarketAdditionalOptionHandler={onClickFixtureMarketAdditionalOptionHandler}
                  onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                />
              )
            })}
          </>
        )}
      </div>
    )
  },
)

const SportsDomesticContent = () => {
  const dispatch = useDispatch()

  const { totalElements, domesticParams, domesticMarketParams, domesticFixtures } = useSelector(state => {
    const { sportsDomesticInfo } = state

    return {
      totalElements: sportsDomesticInfo.totalElements,
      domesticParams: sportsDomesticInfo.domesticParams,
      domesticMarketParams: sportsDomesticInfo.domesticMarketParams,
      domesticFixtures: sportsDomesticInfo.domesticFixtures,
    }
  })

  const { bonusFolderInfo, selectedBonusFolder, bettingCartList, bettingOkTimeCriteria, bonusFolderPerCriteria } =
    useSelector(state => {
      const { sportsBetting } = state

      const sportsBettingInfo = sportsBetting.sportsBettingInfos[SportMarketType.DOMESTIC]

      return {
        bonusFolderInfo: sportsBettingInfo?.bonusFolderInfo || [],
        selectedBonusFolder: sportsBetting.selectedBonusFolder,
        bettingCartList: sportsBetting.bettingCartList,
        bettingOkTimeCriteria: sportsBettingInfo?.bettingOkTimeCriteria ?? 0,
        bonusFolderPerCriteria: sportsBettingInfo?.bonusFolderPerCriteria ?? 0,
      }
    })

  const bettingCartListRef = useRef(bettingCartList)

  useEffect(() => {
    bettingCartListRef.current = bettingCartList
  }, [bettingCartList])

  const sportsEventListWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / domesticParams.size))
  }, [totalElements, domesticParams])

  const onPageChange = pageNumber => {
    dispatch(setPageNum(pageNumber))

    if (sportsEventListWrapRef.current) {
      sportsEventListWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    dispatch(fetchDomesticFixtureListAction())
  }, [dispatch, domesticParams])

  useEffect(() => {
    dispatch(fetchDomesticFixtureMarketAction())
  }, [dispatch, domesticMarketParams])

  // 경기 리그 펼치기
  const onClickFixtureCollapseHandler = useCallback(
    key => {
      dispatch(setFixtureCollapse(key))
    },
    [dispatch],
  )

  const domesticFixturesRef = useRef(domesticFixtures)

  useEffect(() => {
    domesticFixturesRef.current = domesticFixtures
  }, [domesticFixtures])

  //  경기 추가 배팅 옵션
  const onClickFixtureMarketAdditionalOptionHandler = useCallback(
    (key, fixtureId) => {
      const idx = domesticFixturesRef.current[key].fixtures.findIndex(item => item.fixtureId === fixtureId)

      if (idx === -1) return

      if (!domesticFixturesRef.current[key].fixtures[idx].additionalOption) {
        // api 호출
        dispatch(setFixtureMarketParam(fixtureId))
      }

      dispatch(setFixtureMarketAdditionalOption({ key, fixtureId }))
    },
    [dispatch],
  )

  // 배팅 카트 담기
  const onClickAddBettingCartHandler = useCallback(
    bettingCartItem => {
      dispatch(
        addBettingCartItem({
          ...bettingCartItem,
          sportMarketType: SportMarketType.DOMESTIC,
        }),
      )
    },
    [dispatch],
  )

  // 보너스 폴더 선택
  const onClickSportBonusFolderHandler = selectedBonusFolderItem => {
    dispatch(setSelectedBonusFolder(selectedBonusFolderItem))
  }

  const getBonusFolderFont = (idx, bonusFolderNum) => {
    switch (idx) {
      case 0:
        return <font color="blue">{bonusFolderNum}폴더 보너스</font>
      case 1:
        return <font color="green">{bonusFolderNum}폴더 보너스</font>
      case 2:
        return <font color="red">{bonusFolderNum}폴더 보너스</font>
      default:
        return <font color="black">{bonusFolderNum}폴더 보너스</font>
    }
  }

  return (
    <div className="div_Games">
      <div className="lg_group">
        {/* 보너스 배당 */}
        <div className="lg_line">
          <img src={`${process.env.PUBLIC_URL}/img/sports/common/event1.png`} align="absmiddle" alt="" />
          <span style={{ color: '#fff' }}>보너스 배당</span>
        </div>
        {bonusFolderInfo.map((folder, index) => {
          return (
            <div className="gtype1">
              <table>
                <tbody>
                  <tr>
                    <input type="hidden" value="보너스 배당 UP !!" />
                    <input type="hidden" value="" />
                    <td className="time">{convertToKstByFormat(new Date(), 'MM/DD HH:mm')}</td>
                    <td
                      className={folder.bonusFolderNum === selectedBonusFolder?.bonusFolderNum ? 'home_pick' : 'home'}
                      onClick={() => {
                        onClickSportBonusFolderHandler(folder)
                      }}
                    >
                      <span>보너스 배당 UP !!&nbsp;{getBonusFolderFont(index, folder.bonusFolderNum)}</span>
                      <font color="blue">
                        <b>{folder.bonusFolderPer}</b>
                      </font>
                    </td>
                    <td className="draw_off">
                      <b>◀</b>
                    </td>
                    <td className="away_off">
                      <span> {bonusFolderPerCriteria} 배당이상 조합가능 &nbsp;</span>
                      <b>777.00</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        })}
      </div>

      {/* 게임 리스트 */}
      {Object.entries(domesticFixtures).map(([key, event]) => {
        const currentTime = moment()
        const startDate = moment(event.startDate)
        const diff = Math.abs(currentTime.diff(startDate, 'seconds'))
        if (bettingOkTimeCriteria !== 0 && diff <= bettingOkTimeCriteria) {
          // 배팅 마감시간 지난 것
          return null
        }

        const filteredEventFixtures = event.fixtures.filter(fixtureItem => {
          return (
            fixtureItem.fixture.fixtureVisible &&
            fixtureItem.fixture.sportVisible &&
            fixtureItem.fixture.locationVisible &&
            fixtureItem.fixture.leagueVisible &&
            fixtureItem.fixture.homeVisible &&
            fixtureItem.fixture.awayVisible &&
            (fixtureItem.fixture.fixtureStatusId === 1 || fixtureItem.fixture.fixtureStatusId === 9)
          )
        })

        if (filteredEventFixtures.length === 0) {
          return null
        }

        return (
          <SportEventFixtures
            eventKey={key}
            event={{
              ...event,
              fixtures: filteredEventFixtures,
            }}
            onClickFixtureCollapseHandler={onClickFixtureCollapseHandler}
            onClickFixtureMarketAdditionalOptionHandler={onClickFixtureMarketAdditionalOptionHandler}
            onClickAddBettingCartHandler={onClickAddBettingCartHandler}
          />
        )
      })}

      <CustomPagination currentPage={domesticParams.page} totalPages={totalPages} onPageChange={onPageChange} />
    </div>
  )
}

export default SportsDomesticContent

const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: #fff;
  width: 25px;
  height: 20px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: -2px;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: #fff;
  width: 25px;
  height: 20px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: -2px;
`
