import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import {
  createOnetoOne,
  deleteOnetoOneList,
  memberConfirmOneToOneAnswer,
} from '../../../api/customCenter/customCenterApi'
import CustomLoading from '../../../containers/CustomLoading'
import { fetchOneToOneList, setCustomerLoading } from '../../../redux/customCenterSlice'
import { fetchOneToOneSize } from '../../../redux/memberInfoSlice'
import Badge from '../../../shared/components/Badge'
import MobileCustomPagination from '../../../shared/components/MobileCustomPagination'
import { CustomButtonInfo } from '../../../shared/components/MobileMoneyCustomElement'
import { MobileListButtonWrap } from '../../../shared/components/MoneyCustomElement'
import PollingContext from '../../../utils/contexts/PollingInfo'
import { colorMainText } from '../../../utils/palette'
import MobileOnetoOneDetail from './MobileOnetoOneDetail'
import MobileOnetoOneWrite from './MobileOnetoOneWrite'
import { OneToOneAnswerStatusEnums, OneToOneConfirmStatusEnums } from '../../../utils/enums/Operation/OneToOneEnums'
import { convertToKstByFormat } from '../../../utils/dateTime'

const MobileOnetoOneList = ({ visible, onClose }) => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [isWriteOnetoOneShow, setWriteOnetoOneShow] = useState(false)

  const contextValue = useContext(PollingContext)

  const [oneToOneSize, setOneToOneSize] = useState(!contextValue?.oneToOneSize ? 0 : contextValue.oneToOneSize)
  useEffect(() => {
    if (!contextValue) return

    setOneToOneSize(contextValue.oneToOneSize)
  }, [contextValue])

  /**
   *  1:1문의 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [oneToOneParams, setOneToOneParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setOneToOneParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [checkRadio, setCheckRadio] = useState([])
  const [content, setContent] = useState([])
  const { customCenterLoading, totalElement, apiContent, holdingMoney, memberTotalGameMoney, nickName } = useSelector(
    state => {
      const { customCenter, memberInfo } = state

      return {
        customCenterLoading: customCenter.customCenterLoading,
        totalElement: customCenter.oneToOneInfo.totalElement,
        apiContent: customCenter.oneToOneInfo.content,
        holdingMoney: memberInfo.memberHoldingMoney,
        memberTotalGameMoney: memberInfo.memberTotalGameMoney,
        nickName: memberInfo.nickName,
      }
    },
  )
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.oneToOneKey === item.oneToOneKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchOneToOneList = async () => {
    dispatch(setCustomerLoading(true))
    try {
      dispatch(fetchOneToOneList(oneToOneParams))
      setLoading(false)
    } catch (error) {
      dispatch(setCustomerLoading(false))
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchSearchOneToOneList()
  }, [oneToOneParams])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = oneToOneKey => {
    const idx = content.findIndex(item => item.oneToOneKey === oneToOneKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        const idx2 = content.findIndex(item => item.oneToOneKey === oneToOneKey)
        if (idx2 !== -1 && content[idx2]?.confirmStatus === 'INCOMPLETE') {
          memberConfirmOneToOneAnswer({
            oneToOneKey: content[idx2].oneToOneKey,
          }).then(res => {
            fetchOneToOneList()
            dispatch(fetchOneToOneSize())
          })
        }
        return newContent
      })
    }
  }

  const [questionLoading, setQuestionLoading] = useState(false)

  const onQuestionAccountHandler = () => {
    if (questionLoading) return
    const body = {
      questionTitle: '⚡ 자동 계좌문의',
      questionContent: '계좌문의 드립니다.',
    }
    setQuestionLoading(true)
    createOnetoOne(body)
      .then(res => {
        alert('계좌문의가 접수되었습니다.')
        setQuestionLoading(false)
        fetchSearchOneToOneList()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(message)
            break
          default:
            alert('계좌문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setQuestionLoading(false)
      })
  }

  // 문의하기 버튼
  const onWriteFromHandler = () => {
    setWriteOnetoOneShow(prev => !prev)
  }

  const [deleteLoading, setDeleteLoading] = useState(false)

  // 삭제하기 버튼
  const onDeleteHandler = oneToOneKey => {
    if (deleteLoading) return

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteOnetoOneList({
        oneToOneKeyList: [oneToOneKey],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          fetchSearchOneToOneList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          fetchSearchOneToOneList()
        })
    }
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
    }
  }

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }

  const [showDetail, setShowDetail] = useState(false)
  const [writeDetail, setWriteDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    fetchSearchOneToOneList()
  }, [writeDetail, showDetail])

  const handleRowClick = oneToOneKey => {
    const idx = content.findIndex(item => item.oneToOneKey === oneToOneKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })

      const idx2 = content.findIndex(item => item.oneToOneKey === oneToOneKey)
      if (idx2 !== -1 && content[idx2]?.confirmStatus === 'INCOMPLETE') {
        memberConfirmOneToOneAnswer({
          oneToOneKey: content[idx2].oneToOneKey,
        }).then(res => {
          dispatch(fetchOneToOneList(oneToOneParams))
          dispatch(fetchOneToOneSize())
        })
      }
    }

    const boardContent = content.find(item => item.oneToOneKey === oneToOneKey)
    if (!boardContent) return
    setSelectedItem(boardContent)
    setShowDetail(true)
  }

  const renderTitleWithLargeEmoji = title => {
    const parts = title.split(/(⚡)/g) // '⚡'을 기준으로 문자열을 분할
    return parts.map((part, index) =>
      part === '⚡' ? (
        <span key={index} style={{ fontSize: '2em', verticalAlign: 'middle' }}>
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      ),
    )
  }

  return (
    <>
      {customCenterLoading && <CustomLoading />}
      {showDetail || writeDetail ? (
        <>
          {showDetail && (
            <MobileOnetoOneDetail
              nickName={nickName}
              notice={selectedItem}
              onBack={() => setShowDetail(false)}
              fetchSearchOneToOneList={fetchSearchOneToOneList}
            />
          )}
          {writeDetail && (
            <MobileOnetoOneWrite
              onBack={() => setWriteDetail(false)}
              fetchSearchOneToOneList={fetchSearchOneToOneList}
            />
          )}
        </>
      ) : (
        <div style={{ padding: '0px 0 0px 0' }}>
          <div id="hiddenBar" style={{ background: '#fff', boxShadow: '0px 7px 6px -5px #DFE1E5;' }}>
            <div className="header">
              <div style={{ height: '3px' }} />
              <table
                style={{
                  padding: '0px',
                  border: '0px',
                  borderCollapse: 'collapse',
                  borderSpacing: '0px',
                  width: '100%',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: '36px' }}>
                      <img
                        alt=""
                        style={{ width: '36px', height: '36px' }}
                        src="/m/images/menu_btn.png?v=1"
                        //  onclick="showMenuOn();"
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <a href="/m/Default.aspx">
                        <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                      </a>
                    </td>
                    <td style={{ width: '36px' }}>
                      <img
                        style={{ width: '36px', height: '36px' }}
                        alt=""
                        src="/m/images/icons8-refresh-64.png?v=4"
                        //  onClick="window.location.reload();"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ height: '2px' }} />
            </div>
          </div>

          <div className="body_title">
            <span className="title1">
              <strong>고객센터</strong>
            </span>
            <span className="title2">
              <strong>&nbsp;Contact Us</strong>
            </span>
          </div>
          <div style={{ height: '20px' }} />
          <div className="board_wrap">
            <div className="board_list">
              <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="board_list_table2">
                <tbody>
                  {content.length === 0 ? (
                    <tr>
                      <td>문의 내역이 없습니다.</td>
                    </tr>
                  ) : (
                    <>
                      {content.map(item => (
                        <>
                          <tr onClick={() => handleRowClick(item.oneToOneKey)}>
                            <td className="board_list_td" style={{ fontSize: '13px' }}>
                              <span style={{ color: '#000000' }}>
                                {renderTitleWithLargeEmoji(item.questionTitle)}
                                &nbsp;
                                <font
                                  className="bt_type2"
                                  style={{ width: '50px', fontSize: '11px', fontWeight: '100' }}
                                >
                                  {OneToOneAnswerStatusEnums[item.answerStatus]}
                                </font>
                                &nbsp;
                                <font
                                  className="bt_type3"
                                  style={{ width: '50px', fontSize: '11px', fontWeight: '100' }}
                                >
                                  {OneToOneConfirmStatusEnums[item.confirmStatus]}
                                </font>
                                <span>
                                  <br />
                                  <span style={{ color: '#aaa' }}>
                                    <b>
                                      ({item.num})&nbsp;{nickName}
                                    </b>
                                    <span>
                                      {' '}
                                      <span style={{ color: '#aaaaaa' }}>
                                        {convertToKstByFormat(item.registrationDate)}
                                        <span>
                                          &nbsp;&nbsp;&nbsp;
                                          {item.confirmStatus === 'COMPLETE' && item.answerStatus === 'COMPLETE' && (
                                            <span style={{ float: 'right' }}>
                                              <a
                                                onClick={() => {
                                                  onDeleteHandler(item.oneToOneKey)
                                                }}
                                                className="bt_type1"
                                                style={{
                                                  width: '50px',
                                                  color: 'black',
                                                  fontSize: '11px',
                                                  fontWeight: '100',
                                                }}
                                              >
                                                {' '}
                                                삭제하기
                                              </a>
                                            </span>
                                          )}
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </td>
                            {/* <td
                            className="board_list_td"
                            style={{ fontSize: '13px' }}
                            //  onClick="go_message_read('4601');"
                          >
                            <tr>
                              <td
                                className="board_list_td"
                                style={{ fontSize: '13px', border: 'none' }}
                                //  onclick="go_message_read('4601');"
                              >
                                <span style={{ color: '#656565' }}>
                                  {convertToKstByFormat(item.registrationDate)}
                                  <span>
                                    <br />
                                    <span style={{ color: '#656565' }}>
                                      <b>[{OneToOneAnswerStatusEnums[item.answerStatus]}]</b>
                                      <span>
                                        <span style={{ color: '#656565' }}>
                                          <b style={{ color: 'rgb(3, 162, 255)' }}>★Zeus★&nbsp;</b>
                                          <span style={{ fontWeight: 'bold', color: 'rgb(154, 84, 206)' }}>
                                            &nbsp;{item.questionTitle}
                                          </span>
                                          <span />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </td>
                            </tr>
                          </td> */}
                          </tr>
                        </>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ height: '20px' }} />
          </div>
          <div className="pageing_wrap">
            <table align="center" cellPadding="0" cellSpacing="5" border="0" width="100%" className="pageing_table">
              <tbody>
                <tr>
                  <td
                    width="50%"
                    onClick={() => {
                      onQuestionAccountHandler()
                    }}
                  >
                    <b style={{ color: '#000' }}>계좌문의</b>
                  </td>
                  <td width="50%" onClick={() => setWriteDetail(true)}>
                    <b style={{ color: '#000' }}>문의하기</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="board_wrap">
            <div className="board_list">
              <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="board_list_table2" />
            </div>

            <div style={{ height: '20px' }} />
          </div>
          <>
            <MobileCustomPagination
              currentPage={page}
              size={size}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </>
          {/* <div className="pageing_wrap">
    <table align="center" cellpadding="0" cellspacing="5" border="0" width="100%" className="pageing_table">
      <tbody>
        <tr>
          <td width="50%">
            <a href="message.aspx?page=1">◀ 이전10개</a>
            </td>
            <td width="50%">
              <a href="message.aspx?page=1">다음10개 ▶</a></td></tr></tbody></table>
              </div> */}
        </div>
      )}
    </>
    // <Portal elementId="signup-modal">
    //   <SignUpModalOverlay visible={visible} />
    //   <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
    //     <SignUpModalInner tabIndex="0">
    //       <SignUpModalHeader>
    //         <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
    //         <SignUpModalCloseButton
    //           onClick={close}
    //           src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
    //           alt=""
    //         />
    //       </SignUpModalHeader>
    //       <SignUpModalContentWrap>
    //         <MobileTopWrap ref={webPageWrapRef}>
    //           <ContentWrap>
    //             <ContentRowWrap>
    //               <FaChevronLeft
    //                 onClick={e => {
    //                   dispatch(onClickTitleHandler('MYPAGE'))
    //                 }}
    //               />
    //               <ContentRowSmall>
    //                 <div>
    //                   <span style={{ color: '#b2b2b2' }}>스포츠 캐쉬</span>
    //                   <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
    //                     {holdingMoney?.toString().replace(commonReg2, ',')} 원
    //                   </span>
    //                 </div>
    //               </ContentRowSmall>
    //               <ContentRowSmall>
    //                 <div>
    //                   <span style={{ color: '#b2b2b2' }}>카지노 캐쉬</span>
    //                   <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
    //                     {memberTotalGameMoney?.toString().replace(commonReg2, ',')} 원
    //                   </span>
    //                 </div>
    //               </ContentRowSmall>
    //             </ContentRowWrap>
    //             <div style={{ padding: '8px' }}>
    //               <ContentRowWrap>
    //                 <CustomButtonInfo>
    //                   1:1 문의 {!oneToOneSize ? '' : <MobileBadge bg="danger">NEW</MobileBadge>}
    //                 </CustomButtonInfo>
    //                 <CustomButtonWrap>
    //                   <CustomDeleteButton
    //                     onClick={() => {
    //                       setCheckRadio(content.map(value => value.oneToOneKey))
    //                     }}
    //                     type="button"
    //                   >
    //                     <CustomButtonInfos>전체선택</CustomButtonInfos>
    //                   </CustomDeleteButton>
    //                   <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
    //                     <FaTrash />
    //                     <CustomButtonInfos>삭제</CustomButtonInfos>
    //                   </CustomDeleteButton>
    //                   <CustomDeleteButton onClick={() => onWriteFromHandler()} type="button">
    //                     <CustomButtonInfos>문의</CustomButtonInfos>
    //                   </CustomDeleteButton>
    //                   <CustomDeleteButton onClick={() => onQuestionAccountHandler()} type="button">
    //                     <CustomButtonInfos>계좌문의</CustomButtonInfos>
    //                   </CustomDeleteButton>
    //                 </CustomButtonWrap>
    //               </ContentRowWrap>
    //               <ContentInfo>
    //                 {loading || deleteLoading ? (
    //                   <CustomLoading />
    //                 ) : (
    //                   <TableBodyTwoWrap>
    //                     {isWriteOnetoOneShow ? (
    //                       <MobileOnetoOneWrite
    //                         setWriteOnetoOneShow={setWriteOnetoOneShow}
    //                         fetchSearchOnetoOneList={fetchSearchOneToOneList}
    //                       />
    //                     ) : (
    //                       <CustomerCenterContentTable>
    //                         <CustomerCenterContentTableHead>
    //                           <CustomerCenterContentTableHeadTr>
    //                             <th style={{ width: '10%' }}>선택</th>
    //                             <th style={{ width: '50%' }}>내용</th>
    //                             <th style={{ width: '25%' }}>문의날짜</th>
    //                             <th style={{ width: '15%' }}>답변</th>
    //                           </CustomerCenterContentTableHeadTr>
    //                         </CustomerCenterContentTableHead>
    //                         <CustomerCenterContentTableBody>
    //                           {content.length === 0 ? (
    //                             <NoContentDiv>
    //                               <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
    //                               최근 게시물이 없습니다.
    //                             </NoContentDiv>
    //                           ) : (
    //                             <>
    //                               {content.map(item => (
    //                                 <>
    //                                   <CustomerCenterContentTableBodyTr key={item.oneToOneKey}>
    //                                     <CustomerCenterContentBox>
    //                                       <CustomerCenterContenItemBox width="10">
    //                                         <OnetoOneRadio
    //                                           rowInfo={{
    //                                             oneToOneKey: item.oneToOneKey,
    //                                             answerStatus: item.answerStatus,
    //                                           }}
    //                                           checkRadio={checkRadio}
    //                                           setCheckRadio={setCheckRadio}
    //                                         />
    //                                       </CustomerCenterContenItemBox>
    //                                       <CustomerCenterContenItemBox
    //                                         width="50"
    //                                         style={{ cursor: 'pointer', flexDirection: 'column' }}
    //                                         onClick={() => onClickRowHandler(item.oneToOneKey)}
    //                                       >
    //                                         {ReactHtmlParser(item.questionContent, { transform: transformFn })}
    //                                       </CustomerCenterContenItemBox>
    //                                       <CustomerCenterContenItemBox
    //                                         width="25"
    //                                         onClick={() => onClickRowHandler(item.oneToOneKey)}
    //                                       >
    //                                         {convertToKstByFormat(item.registrationDate, 'MM-DD HH:mm')}
    //                                       </CustomerCenterContenItemBox>
    //                                       <CustomerCenterContenItemBox
    //                                         width="15"
    //                                         onClick={() => onClickRowHandler(item.oneToOneKey)}
    //                                       >
    //                                         {OneToOneAnswerStatusEnums[item.answerStatus]}
    //                                       </CustomerCenterContenItemBox>
    //                                     </CustomerCenterContentBox>
    //                                   </CustomerCenterContentTableBodyTr>
    //                                   <CustomCollapse in={item.isVisible}>
    //                                     <CustomerCenterContentTableBodyTr style={{ border: 'solid 1px #525252' }}>
    //                                       <CustomerCenterContentDetailBox>
    //                                         {item.answerContent === null
    //                                           ? '문의가 접수됐습니다. 잠시만 기다려주세요'
    //                                           : ReactHtmlParser(item.answerContent, { transform: transformFn })}
    //                                       </CustomerCenterContentDetailBox>
    //                                     </CustomerCenterContentTableBodyTr>
    //                                   </CustomCollapse>
    //                                 </>
    //                               ))}
    //                             </>
    //                           )}
    //                         </CustomerCenterContentTableBody>
    //                       </CustomerCenterContentTable>
    //                     )}

    //                     {totalElement > 0 && (
    //                       <MobileCustomPagination
    //                         currentPage={page}
    //                         totalPages={totalPages}
    //                         onPageChange={onPageChange}
    //                       />
    //                     )}
    //                   </TableBodyTwoWrap>
    //                 )}
    //               </ContentInfo>
    //             </div>
    //           </ContentWrap>
    //         </MobileTopWrap>
    //       </SignUpModalContentWrap>
    //     </SignUpModalInner>
    //   </SignUpModalWrapper>
    // </Portal>
  )
}

export default MobileOnetoOneList

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  color: #fff;
  img {
    width: 100px;
    height: auto;
  }
`

export const CustomButtonWrap = styled(MobileListButtonWrap)`
  padding: 0px 10px;
`

export const CustomButtonInfos = styled(CustomButtonInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  min-width: 50px;
  padding: 0px 5px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 5px;
    color: grey;
  }
`

export const MobileBadge = styled(Badge)`
  width: 50px;
  height: 20px;
  padding-top: 5px;
`

export const MobileOneToOneHeaderWrap = styled.div`
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
`

export const MobileOnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 13px;
  opacity: 0.7;
  color: ${colorMainText};
`

const ListWrap = styled.div`
  display: flex;
`
export const OnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 0px;
  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 2px;
  color: ${colorMainText};
`
export const OneToOneHeaderWrap = styled.div`
  padding: 10px 0 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1px;
  height: 70px;
  border-bottom: solid 1px #edae07;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  th {
    padding: 16px 0px !important;
    background: rgba(9, 9, 10);
    borde: none;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 12px;
  border-radius: 6px;

  &:hover {
    border: solid 1px #757575;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;

  span {
  }
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
  font-weight: 400;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  padding: 16px 5px;
`
