import React, { useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { searchPartnerUrl, siginOut } from '../../api/authManage/authManageApi'
import CustomAlert from '../../containers/CustomAlert'
import { selectSideSportMenu } from '../../redux/sportsOverseasInfoSlice'
import { onClickMobileSignInHandlerInSideBar, onShowModalHandler } from '../../redux/modalSlice'
import { fetchSportsEventCount, setSportsType } from '../../redux/sportsGameBettingSlice'
import isLogin from '../../utils/enums/authority'
import { decodeAccessToken } from '../../utils/token'
import { HermesMainText } from '../../utils/palette'

const MobileMenuSidebar = ({
  isMobileMenuSidebarShow,
  changeMobileMenuSidebarShowHandler,
  setIsMobileMenuSidebarShow,
}) => {
  const location = useLocation()
  const pathName = location?.pathname
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { domesticCategory } = useSelector(state => {
    const { sportsDomesticInfo } = state

    return {
      domesticCategory: sportsDomesticInfo.domesticCategory,
    }
  })

  const { overseasCategory } = useSelector(state => {
    const { sportsOverseasInfo } = state

    return {
      overseasCategory: sportsOverseasInfo.overseasCategory,
    }
  })

  const { specialCategory } = useSelector(state => {
    const { sportsSpecialInfo } = state

    return {
      specialCategory: sportsSpecialInfo.specialCategory,
    }
  })

  const { liveCategory } = useSelector(state => {
    const { sportsLiveInfo } = state

    return {
      liveCategory: sportsLiveInfo.liveCategory,
    }
  })

  const { sideMenuModalShow } = useSelector(state => {
    const { modalInfo } = state

    return {
      sideMenuModalShow: modalInfo.modalShow,
    }
  })

  const {
    nickName,
    memberLevel,
    noteRedisSize,
    oneToOneRedisSize,
    realTimeDepositList,
    realTimeWithdrawalList,
    domesticSportsUsing,
    domesticSportsName,
    overseasSportsUsing,
    overseasSportsName,
    specialSportsUsing,
    specialSportsName,
    liveSportsUsing,
    liveSportsName,
    attendanceUsing,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      nickName: memberInfo.nickName,
      memberLevel: memberInfo.depositLevel,
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      realTimeDepositList: memberInfo.realTimeDepositList,
      realTimeWithdrawalList: memberInfo.realTimeWithdrawalList,
      domesticSportsUsing: memberInfo.domesticSportsUsing,
      domesticSportsName: memberInfo.domesticSportsName,
      overseasSportsUsing: memberInfo.overseasSportsUsing,
      overseasSportsName: memberInfo.overseasSportsName,
      specialSportsUsing: memberInfo.specialSportsUsing,
      specialSportsName: memberInfo.specialSportsName,
      liveSportsUsing: memberInfo.liveSportsUsing,
      liveSportsName: memberInfo.liveSportsName,
      attendanceUsing: memberInfo.attendanceUsing,
    }
  })

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const ClickHandler = link => {
    if (pathName === link) {
      window.location.href = link
      return
    }

    navigate(link)
    changeMobileMenuSidebarShowHandler()
    window.scrollTo(10, 0)
  }

  const mobileMenuRef = useRef()

  const clickMenuOutside = event => {
    if (isMobileMenuSidebarShow && !mobileMenuRef.current.contains(event.target)) {
      changeMobileMenuSidebarShowHandler()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickMenuOutside)

    return () => {
      document.removeEventListener('mousedown', clickMenuOutside)
    }
  })

  const [selectedSportId, setSelectedSportId] = useState(-1)
  const [selectedLocationId, setSelectedLocationId] = useState(-1)

  // 종목 선택했을때
  const onClickSportHandler = sportId => {
    if (selectedSportId === -1 || selectedSportId !== sportId) {
      setSelectedSportId(sportId)
    } else {
      if (!isLogin()) {
        changeMobileMenuSidebarShowHandler()
        dispatch(onClickMobileSignInHandlerInSideBar(true))
        return
      }

      if (noteRedisSize > 0) {
        setNoteAlertShow(true)
        return
      }

      // 초기화
      setSelectedSportId(-1)

      dispatch(
        selectSideSportMenu({
          sportId,
          locationId: -1,
          leagueId: -1,
        }),
      )

      // TODO 국내 해외 스페셜 라이브 구분하기
      if (location.pathname !== '/mobile/sports/overseas') {
        changeMobileMenuSidebarShowHandler()
        window.scrollTo(10, 0)
        navigate('/mobile/sports/overseas')
      }
    }
  }

  // 국가 선택시
  const onClickLocationHandler = (sportId, locationId) => {
    if (selectedLocationId === -1 || selectedLocationId !== locationId) {
      setSelectedSportId(sportId)
      setSelectedLocationId(locationId)
    } else {
      if (!isLogin()) {
        changeMobileMenuSidebarShowHandler()
        dispatch(onClickMobileSignInHandlerInSideBar(true))
        return
      }

      if (noteRedisSize > 0) {
        setNoteAlertShow(true)
        return
      }

      // 초기화
      setSelectedSportId(-1)
      setSelectedLocationId(-1)

      dispatch(
        selectSideSportMenu({
          sportId,
          locationId,
          leagueId: -1,
        }),
      )

      // TODO 국내 해외 스페셜 라이브 구분하기
      if (location.pathname !== '/mobile/sports/overseas') {
        changeMobileMenuSidebarShowHandler()
        window.scrollTo(10, 0)
        navigate('/mobile/sports/overseas')
      }
    }
  }

  // 스포츠 리그 선택시
  const onClickLeagueHandler = (sportId, locationId, leagueId) => {
    if (!isLogin()) {
      changeMobileMenuSidebarShowHandler()
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    // 초기화
    setSelectedSportId(-1)
    setSelectedLocationId(-1)

    dispatch(
      selectSideSportMenu({
        sportId,
        locationId,
        leagueId,
      }),
    )

    // TODO 국내 해외 스페셜 라이브 구분하기
    if (location.pathname !== '/mobile/sports/overseas') {
      changeMobileMenuSidebarShowHandler()
      window.scrollTo(10, 0)
      navigate('/mobile/sports/overseas')
    }
  }

  const onClickMenuModalHandler = title => {
    if (!isLogin()) {
      changeMobileMenuSidebarShowHandler()
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }

  const [isOpenedMainLinks, setIsOpenedMainLinks] = useState(true)
  const [isOpenedQuickLinks, setIsOpenedQuickLinks] = useState(true)
  const [isOpenedSportsLinks, setIsOpenedSportsLinks] = useState(true)
  useEffect(() => {
    if (sideMenuModalShow) {
      // 현재 스크롤 위치를 저장합니다.
      const { scrollY } = window
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
    } else {
      // 저장된 스크롤 위치를 복원합니다.
      const savedScrollY = parseInt(document.body.style.top || '0', 10)
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, savedScrollY * -1)
    }
  }, [sideMenuModalShow])

  const onClickPartnerUrlHandler = () => {
    searchPartnerUrl()
      .then(res => {
        const { partnerUrl } = res.data

        if (partnerUrl.includes('localhost')) {
          window.open(`http://${partnerUrl}`)
        } else {
          window.open(`https://${partnerUrl}`)
        }
      })
      .catch(error => {})
  }

  const getLevelIconUrl = () => {
    switch (true) {
      case memberLevel === 'ONE':
        return `${process.env.PUBLIC_URL}/img/level/01.png`
      case memberLevel === 'TWO':
        return `${process.env.PUBLIC_URL}/img/level/02.png`
      case memberLevel === 'THREE':
        return `${process.env.PUBLIC_URL}/img/level/03.png`
      case memberLevel === 'FOUR':
        return `${process.env.PUBLIC_URL}/img/level/04.png`
      case memberLevel === 'FIVE':
        return `${process.env.PUBLIC_URL}/img/level/05.png`
      case memberLevel === 'VIP1':
        return `${process.env.PUBLIC_URL}/img/level/06.png`
      case memberLevel === 'VIP2':
        return `${process.env.PUBLIC_URL}/img/level/07.png`
      case memberLevel === 'VIP3':
        return `${process.env.PUBLIC_URL}/img/level/08.png`
      default:
        return ''
    }
  }

  const onClickSignOutHandler = () => {
    siginOut()
      .then(res => {
        window.location.replace('/')
      })
      .catch(error => {
        window.location.replace('/')
      })
  }

  return (
    <>
      <MobileMenu $show={isMobileMenuSidebarShow} ref={mobileMenuRef}>
        <div id="q_top">
          <div className="quick_member_info" style={{ marginTop: '-10px' }}>
            <img
              src={getLevelIconUrl()}
              id="level_Icon"
              alt=""
              align="absmiddle"
              style={{ marginTop: '-2px', width: '35px', height: 'auto' }}
            />
            <font id="user_level" />
            &nbsp;
            <font id="user_nick" style={{ color: '#111111', fontWeight: 'bold' }}>
              {nickName}
            </font>
            &nbsp;님
          </div>
          <div
            className="quick_logout"
            onClick={() => {
              setIsMobileMenuSidebarShow(prev => !prev)
            }}
            style={{ marginTop: '12px', marginRight: '-10px' }}
          >
            {' '}
            <IconBox className="ic_cancel" style={{ backgroundPosition: '-220px -311px' }} />
          </div>
          <div className="quick_logout" style={{ marginTop: '10px' }}>
            <a onClick={onClickSignOutHandler}>
              <IconBox className="ic_logout" style={{ backgroundPosition: '-200px -423px' }} />
            </a>
          </div>
        </div>

        <div style={{ height: '1px', backgroundColor: 'rgba(0,0,0,.12)' }} />

        <div style={{ height: '5px', backgroundColor: '#fff' }} />

        {/* 빠른 메뉴 */}
        <div className="div_main_button2">
          <div style={{ height: '3px', backgroundColor: '#fff' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#976038' }}>
            &nbsp;빠른메뉴
          </div>
          <div style={{ height: '3px' }} />
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/money/deposit')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/charge.png`} height="45" />
                  <br />
                  <span>충전하기</span>
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/money/withdrawal')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/exchange.png`} height="45" />
                  <br />
                  <span>환전하기</span>
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/betting-history')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/bet_list.png`} height="45" />
                  <br />
                  <span>배팅내역</span>
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/onetoone')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/contact.png`} height="45" />
                  <br />
                  <span>고객센터</span>
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/note')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/message.png`} height="45" />
                  <br />
                  <span>쪽지함</span>
                </td>
                <td className="td_bt_empty" />
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ height: '5px', backgroundColor: '#fff' }} />

        {/* 스포츠 메뉴 */}
        <div className="div_main_button2">
          <div style={{ height: '3px', backgroundColor: '#fff' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#000' }}>
            &nbsp;스포츠메뉴
          </div>
          <div style={{ height: '3px' }} />
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                {domesticSportsUsing && (
                  <>
                    <td
                      onClick={() => {
                        ClickHandler('/mobile/sports/domestic')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/2winlose.png`} height="45" />
                      <br />
                      <span>{domesticSportsName}</span>
                    </td>
                    <td className="td_bt_empty" />
                  </>
                )}
                {domesticSportsUsing && (
                  <>
                    <td
                      onClick={() => {
                        ClickHandler('/mobile/sports/balance')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/balance.png`} height="45" />
                      <br />
                      <span>스포츠밸런스</span>
                    </td>
                    <td className="td_bt_empty" />
                  </>
                )}
                {overseasSportsUsing && (
                  <>
                    <td
                      onClick={() => {
                        ClickHandler('/mobile/sports/overseas')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/3handicap.png`} height="45" />
                      <br />
                      <span>{overseasSportsName}</span>
                    </td>
                    <td className="td_bt_empty" />
                  </>
                )}
                {specialSportsUsing && (
                  <>
                    <td
                      onClick={() => {
                        ClickHandler('/mobile/sports/special')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/4special.png`} height="45" />
                      <br />
                      <span>{specialSportsName}</span>
                    </td>
                    <td className="td_bt_empty" />
                  </>
                )}
                {liveSportsUsing && (
                  <>
                    <td
                      onClick={() => {
                        ClickHandler('/mobile/sports/live')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/5live.png`} height="45" />
                      <br />
                      <span>{liveSportsName}</span>
                    </td>
                    <td className="td_bt_empty" />
                  </>
                )}
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ height: '5px', backgroundColor: '#fff' }} />

        {/* 카지노 메뉴 */}

        <div className="div_main_button2">
          <div style={{ height: '3px', backgroundColor: '#fff' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#24247e' }}>
            &nbsp;카지노메뉴
          </div>
          <div style={{ height: '3px' }} />
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  onClick={() => {
                    ClickHandler('/mobile/live-casino')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/gm_banner55.png`}
                    style={{ borderRadius: '4px' }}
                    width="60"
                  />
                  <br />
                  <span>Live 카지노</span>
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/slot-casino')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/gm_banner66.png`}
                    style={{ borderRadius: '4px' }}
                    width="60"
                  />
                  <br />
                  <span>슬롯게임</span>
                </td>
                <td className="td_bt_empty" />
              </tr>
              <tr>
                <td colSpan="11" style={{ height: '10px' }} />
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ height: '5px', backgroundColor: '#fff' }} />

        {/* 미니게임 */}
        <div className="div_main_button2">
          <div style={{ height: '3px', backgroundColor: '#fff' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#8a2b63' }}>
            &nbsp;미니게임
          </div>
          <div style={{ height: '3px' }} />
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/mini-game/ntry/eos1m')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/mobile_ntry_logo.png`} height="45" />
                  <br />
                  엔트리
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/mini-game/boscore/stat1m')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/mobile_boscore_logo.png`}
                    height="45"
                  />
                  <br />
                  보스코어
                </td>
                <td className="td_bt_empty" />
                {/* <td
                  onClick={() => {
                    ClickHandler('/mobile/mini-game/named/n/powerball3m')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/mobile_named_logo.png`}
                    height="45"
                  />
                  <br />
                  네임드
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/mini-game/sureman/roulette1m')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/mobile_sureman_logo.png`}
                    height="45"
                  />
                  <br />
                  슈어맨
                </td>
                <td className="td_bt_empty" /> */}
                <td
                  onClick={() => {
                    ClickHandler('/mobile/mini-game/gopick/raccoon')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/mobile_gopick_logo.png`}
                    height="45"
                  />
                  <br />
                  고픽
                </td>
                <td className="td_bt_empty" />
              </tr>

              <tr>
                <td colSpan="11" style={{ height: '10px' }} />
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ height: '5px', backgroundColor: '#fff' }} />
        {/* 가상게임 */}
        {/* <div className="div_main_button2">
          <div style={{ height: '3px', backgroundColor: '#fff' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#8a2b63' }}>
            &nbsp;가상스포츠
          </div>
          <div style={{ height: '3px' }} />
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  onClick={() => {
                    ClickHandler('/mobile/virtual/soccer')
                  }}
                >
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/img/menu/mobile/game/gm_virtualgame.png`}
                    height="45"
                    width="45"
                    style={{ borderRadius: '4px' }}
                  />
                  <br />
                  가상스포츠
                </td>
                <td className="td_bt_empty" />
              </tr>

              <tr>
                <td colSpan="11" style={{ height: '10px' }} />
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ height: '5px', backgroundColor: '#fff' }} /> */}

        {/* 기타 */}
        <div className="div_main_button2">
          <div style={{ height: '3px', backgroundColor: '#fff' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#686868' }}>
            &nbsp;기타메뉴
          </div>
          <div style={{ height: '3px' }} />
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                <td
                  style={{ width: '45px' }}
                  onClick={() => {
                    ClickHandler('/mobile/notice')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/notice.png`} height="45" />
                  <br />
                  <span>공지사항</span>
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/betting-rule')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/betting_rule.png`} height="45" />
                  <br />
                  <span>이용규정</span>
                </td>
                {attendanceUsing && (
                  <>
                    <td className="td_bt_empty" />
                    <td
                      onClick={() => {
                        ClickHandler('/mobile/attendance')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/stamp.png`} height="45" />
                      <br />
                      <span>출석체크</span>
                    </td>
                  </>
                )}

                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/mypage')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/profile.png`} height="45" />
                  <br />
                  <span>회원정보</span>
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    ClickHandler('/mobile/event')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/event.png`} height="45" />
                  <br />
                  <span>이벤트</span>
                </td>
                <td className="td_bt_empty" />
              </tr>
              {decodeAccessToken()?.auth === 'PARTNER' && (
                <>
                  <tr>
                    <td colSpan="11" style={{ height: '10px' }} />
                  </tr>
                  <tr>
                    <td className="td_bt_empty" />
                    <td onClick={onClickPartnerUrlHandler}>
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/profile2.png`} height="45" />
                      <br />
                      파트너
                      <br />
                      페이지
                    </td>
                    <td className="td_bt_empty" />
                    <td className="td_bt_empty" />
                    <td className="td_bt_empty" />
                    <td className="td_bt_empty" />
                    <td className="td_bt_empty" />
                    <td className="td_bt_empty" />
                    <td className="td_bt_empty" />
                    <td className="td_bt_empty" />
                    <td className="td_bt_empty" />
                  </tr>
                </>
              )}
              <tr>
                <td colSpan="11" style={{ height: '10px' }} />
              </tr>
            </tbody>
          </table>
        </div>
      </MobileMenu>
    </>
  )
}

export default MobileMenuSidebar

const slideInFromRight = keyframes`
  from {
    transform: translateX(-350px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`

const MobileMenu = styled.div`
  position: fixed;
  width: 320px;
  height: 100%;
  top: 0;
  left: -330px;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 2147483647;

  animation: ${slideInFromRight} 0.5s ease-out;
  ${props =>
    props.$show &&
    `
    left: 0;
  `}
`

const IconBox = styled.div`
  background: url(${process.env.PUBLIC_URL}/img/common/sp_img.png) 0 0 no-repeat;
`

const MobileMenuCloseButton = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 500;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7); // 검은색 배경에 70% 투명도
  z-index: 999; // 다른 요소들 위에 나타나도록 z-index 설정
`

const CustomLink = styled.div`
  text-decoration: none;
  color: #fff;
`

const SidebarWrap = styled.div`
  transition: all 0.5s ease-out 0s;
  // 열고 닫는부분 처리해주기
  width: 271px;

  position: fixed;
  margin-top: 65px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1010;
  -webkit-transform: translateZ(0);

  font-family: 'Noto Sans', sans-serif;

  background-color: #141517;
`

// 스크롤 용도
const SlimScrollDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  height: 100%;
`

const SidbarMenuWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

// text-white 부분
const QuickLinksWrap = styled.div`
  color: #fff;
  height: max-content;
  padding: 35px 8px 0 8px;
`

// collapse 부분
const QuickLinksCollapse = styled.div`
  height: 44px;
  padding: 12px 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  display: table;
  transition: background-color 0.1s ease-out 0s;
  white-space: nowrap;

  &:hover {
    background-color: #1f1f1f;
  }

  margin-top: 8px;
  font-weight: 900;
  cursor: pointer;
`

const QuickLinksText = styled.div`
  width: calc(100% - 24px);
  float: left;
  font-size: 14px;
`

const CustomCollapse = styled(Collapse)`
  &.collapsing {
    will-change: height;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
    // transition: height 3s ease-in-out !important;
  }
`

const SportCustomCollapse = styled(Collapse)`
  &.collapsing {
    will-change: height;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
    // transition: height 3s ease-in-out !important;
  }
`

const QuickLinksBox = styled.div``

const SidbarQuickItem = styled.div`
  height: 44px;
  padding: 12px 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  display: table;
  transition: background-color 0.1s ease-out 0s;
  white-space: nowrap;
`

const SidbarQuickItemText = styled.div`
  float: right;
  padding-left: 24px;
  font-size: 13px;
  width: calc(100% - 16px);
  ${props =>
    props.active &&
    `
    color: ${HermesMainText} !important;
  `}
`
const MobileMenuHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; // 이 부분 추가
  padding-left: 10px;
  padding-right: 10px;
  border: solid 1px red;
  height: max-content !important;
  position: relative;
  svg {
    width: 20px;
    height: 20px;
    color: #fff;
  }
`

const SideLogoImg = styled.img`
  width: 16px;
  height: 16px;
  src: url(${props => `${process.env.PUBLIC_URL}/img/sidebar/${props.menuName}.png`});
`

// 레벨 이미지
export const LevelImg = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 3px;
  &:hover {
    cursor: pointer;
  }
`

const MobileMenuTitleWrap = styled.div`
  position: relative;
  top: 50px;

  color: white;

  display: flex;
  flex-direction: column;
  text-align: flex-start;
  gap: 5px;
`

const MobileMenuListWrap = styled.ul`
  position: relative;
  top: 50px;
  list-style: none;
`

const MobileMenuItemInfo = styled.span`
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  color: white;
`
const MobileMenuSubItemIcon = styled.img`
  width: 20px;
  height: 20px;

  margin-left: auto;
`

const MobileSubMenuItemWrap = styled.div`
  display: flex;
  align-items: center;

  padding: 0 5px;
  height: 35px;

  &:first-child {
    border-top: 1px solid #1b1a18;
  }
  border-bottom: 1px solid #1b1a18;
`

const MobileMenuItemWrap = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0 5px;
  padding-left: 15px;
  margin-bottom: 15px;

  ${props =>
    props.$isSubMenu &&
    `
    &:first-child {
      border-top: 1px solid transparent !important;
    }
    
    padding-left: 25px;
`}

  height: 35px;

  ${props =>
    props.$active &&
    `
    &.active {
      ${MobileMenuItemInfo} {
        color: #FFC01A;
      }
      
      background: linear-gradient(180deg, #525252 23.07%, #262626 91.02%);
    }
  `}

  &:first-child {
    border-top: 1px solid #1b1a18;
  }
  border-bottom: 1px solid #1b1a18;
`

const SportsMenuItemWrap = styled.div`
  position: relative;
  display: block;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
`

const SportsMenuItemCount = styled.div`
  font-weight: 100;
  float: right;
  padding: 5px 0;
  margin-top: 1px;
  display: inline;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  width: 16.66667%;
  background: #3d3d3d;
`
const SportsMenuItemIconBox = styled.div`
  width: 12.5%;
  float: left;
  display: block;
  margin-top: 4px;

  img {
    width: 16px;
  }
`

const SportsMenuItemText = styled.div`
  padding: 2px 3px 3px 3px;
  width: 70.83333%;
  float: left;
  display: block;
  font-size: 13px;
`
const SportsMenuBodyWrap = styled.div`
  border-radius: 0 0 3px 3px;
  background: #141517;
  padding: 0 8px;
  display: block;
  font-family: 'Noto Sans', sans-serif;
`

const SportsMenuContentBox = styled.div`
  display: block;
`

const SportsMenuItemBox = styled.div`
  line-height: 20px;
  position: relative;
  display: flex;
  background: #141517;
  padding: 12px 8px;
  border: none;
  transition: background-color 0.1s ease-out 0s;
  color: #fff;
  cursor: pointer;
  height: 100%;

  &:hover {
    cursor: pointer;
    background-color: #232425;
  }

  ${props =>
    props.active &&
    `
    background-color: #232425;
`}

  ${props =>
    props.isLeague &&
    `
    padding-left: 17px;
    &:hover {
      background-color: #555759;
    }
`}
`
