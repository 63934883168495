import React from 'react'
import PropTypes from 'prop-types'
import Error from '@/shared/components/form/Error'
// import MaskedInput from 'react-text-mask'
import MaskedInput from 'react-text-mask'
import styled from 'styled-components'
import { FormInputWrap } from '../../shared/components/form/FormElements'

const BettingCartFormField = ({
  input,
  meta: { touched, error },
  component: Component,
  isAboveError,
  wrapperClassName,
  ...props
}) => (
  <FormInputWrap className={wrapperClassName}>
    <Component {...props} {...input} />
    {touched && error && <Error error={error} top={isAboveError} />}
  </FormInputWrap>
)

BettingCartFormField.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  component: PropTypes.elementType,
  isAboveError: PropTypes.bool,
  wrapperClassName: PropTypes.string,
}

BettingCartFormField.defaultProps = {
  input: null,
  meta: {
    touched: false,
    error: '',
  },
  component: 'input',
  isAboveError: false,
  wrapperClassName: '',
}

export const renderComponentField = component => props => <BettingCartFormField component={component} {...props} />

export const renderMaskedField = renderComponentField(MaskedInput)

export default BettingCartFormField

// region STYLES

// endregion
