import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { SportMarketType } from '../../utils/enums/SportsGame/SportsGameEnums'
import { SocketIOEventName } from '../../utils/enums/WebSocket/SocketIOEventName'
import { SocketIORequestMessageType } from '../../utils/enums/WebSocket/SocketIORequestMessageType'
import { emitPromise, socket } from '../../utils/socket'
import {
  onUpdatedBettingCartItemHandlerByFixture,
  onUpdatedBettingCartItemHandlerByMarket,
  removeAllBettingCartItem,
} from '../sportsBettingSlice'
import { createSocketChannel } from './createSocketChannel'
import {
  fetchSpecialFixtureListAction,
  fetchSpecialFixtureMarketAction,
  setSpecialFixtureMarkets,
  setSpecialLoading,
  setSpecialSports,
  updateFixtureByWebSocket,
  updateLiveScoreByWebSocket,
  updateMarketByWebSocket,
} from '../sportsSpecialInfoSlice'

function getSportsSpecialInfo(state) {
  return state.sportsSpecialInfo
}

function getSportsBetting(state) {
  return state.sportsBetting
}

function* handleFetchSpecialFixtureList() {
  try {
    const sportsSpecialInfo = yield select(getSportsSpecialInfo)

    const payload = {
      type: SocketIORequestMessageType.SPORTS_FIXTURE_LIST,
      payload: sportsSpecialInfo.specialParams,
    }

    // 로딩 true
    yield put(setSpecialLoading(true))

    const sportsFixtureListRes = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (sportsFixtureListRes.status) {
      yield put(setSpecialSports(sportsFixtureListRes.data))
    }

    // 로딩 false
    yield put(setSpecialLoading(false))
  } catch (error) {
    yield put(setSpecialLoading(false))
  }
}

function* watchFetchSpecialFixtureList() {
  yield takeLatest(fetchSpecialFixtureListAction, handleFetchSpecialFixtureList)
}

function* handleFetchSpecialFixtureMarket() {
  try {
    const sportsSpecialInfo = yield select(getSportsSpecialInfo)

    if (sportsSpecialInfo.specialMarketParams === -1) return

    const payload = {
      type: SocketIORequestMessageType.SPORTS_FIXTURE_MARKET_LIST,
      payload: sportsSpecialInfo.specialMarketParams,
    }

    // 로딩 true
    yield put(setSpecialLoading(true))

    const sportsFixtureMarketRes = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (sportsFixtureMarketRes.status) {
      const sportsBetting = yield select(getSportsBetting)

      yield put(
        setSpecialFixtureMarkets({
          sportEvent: sportsFixtureMarketRes.data.sportEvent,
          marketTab: sportsFixtureMarketRes.data.marketTab,
          bettingCartItems: sportsBetting.bettingCartList,
        }),
      )
    }

    // 로딩 false
    yield put(setSpecialLoading(false))
  } catch (error) {
    yield put(setSpecialLoading(false))
  }
}

function* watchFetchSpecialFixtureMarket() {
  yield takeLatest(fetchSpecialFixtureMarketAction, handleFetchSpecialFixtureMarket)
}

/**
 * 경기 데이터 다시 재호출
 */
function* onRefreshSportsDataMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.REFRESH_SPORTS_DATA)

  while (true) {
    try {
      const message = yield take(channel)

      const sportsSpecialInfo = yield select(getSportsSpecialInfo)

      const sportsFixtureListpayload = {
        type: SocketIORequestMessageType.SPORTS_FIXTURE_LIST,
        payload: sportsSpecialInfo.specialParams,
      }

      const sportsFixtureListRes = yield call(emitPromise, socket, SocketIOEventName.FIND, sportsFixtureListpayload)

      if (sportsFixtureListRes.status) {
        yield put(setSpecialSports(sportsFixtureListRes.data))

        // 배팅 카트도 초기화 해부려
        yield put(removeAllBettingCartItem())
      }

      if (sportsSpecialInfo.specialMarketParams !== -1) {
        const sportsFixtureMarketpayload = {
          type: SocketIORequestMessageType.SPORTS_FIXTURE_MARKET_LIST,
          payload: sportsSpecialInfo.specialMarketParams,
        }

        const sportsFixtureMarketRes = yield call(
          emitPromise,
          socket,
          SocketIOEventName.FIND,
          sportsFixtureMarketpayload,
        )

        if (sportsFixtureMarketRes.status) {
          const sportsBetting = yield select(getSportsBetting)

          yield put(
            setSpecialFixtureMarkets({
              sportEvent: sportsFixtureMarketRes.data.sportEvent,
              marketTab: sportsFixtureMarketRes.data.marketTab,
              bettingCartItems: sportsBetting.bettingCartList,
            }),
          )
        }
      }

      // 배팅 카트에도 보내기
      yield put(
        onUpdatedBettingCartItemHandlerByMarket({
          data: sportsFixtureListRes.data,
          sportMarketType: SportMarketType.SPECIAL,
        }),
      )
    } catch (e) {
      //   alert(e.message)
    }
  }
}

function* onUpdatedFixtureMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.PRE_MATCH_FIXTURE_UPDATED)

  while (true) {
    try {
      const message = yield take(channel)

      if (message.sportMarketType === 'SPECIAL') {
        yield put(updateFixtureByWebSocket(message))

        // 배팅 카트에도 보내기
        yield put(
          onUpdatedBettingCartItemHandlerByFixture({
            fixture: message?.fixture || {},
            sportMarketType: SportMarketType.SPECIAL,
          }),
        )
      }
    } catch (e) {
      //   alert(e.message)
    }
  }
}

function* onUpdatedLiveScoreMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.PRE_MATCH_LIVE_SCORE_UPDATED)

  while (true) {
    try {
      const message = yield take(channel)

      yield put(updateLiveScoreByWebSocket(message))
    } catch (e) {
      //   alert(e.message)
    }
  }
}
function* onUpdatedMarketMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.PRE_MATCH_MARKET_UPDATED)

  while (true) {
    try {
      const message = yield take(channel)
      if (message.sportMarketType === 'SPECIAL') {
        const sportsBetting = yield select(getSportsBetting)

        yield put(
          updateMarketByWebSocket({
            ...message,
            bettingCartItems: sportsBetting.bettingCartList,
          }),
        )

        // 배팅 카트에도 보내기
        yield put(
          onUpdatedBettingCartItemHandlerByMarket({
            market: message?.market || [],
            sportMarketType: SportMarketType.SPECIAL,
          }),
        )
      }
    } catch (e) {
      //   alert(e.message)
    }
  }
}

export default function* sportsSpecialSaga() {
  yield all([
    fork(onUpdatedFixtureMessage),
    fork(onUpdatedLiveScoreMessage),
    fork(onUpdatedMarketMessage),
    fork(onRefreshSportsDataMessage),

    watchFetchSpecialFixtureList(),
    watchFetchSpecialFixtureMarket(),
  ])
}
