import styled from 'styled-components'

/**
 * 사용할 곳에서 재정의 해서 쓰기
 * position, width 등등.
 */

/**
 * 둥근...골드 버튼 H40
 */
export const ButtonH40 = styled.button`
  box-sizing: border-box;

  width: 132px;
  height: 40px;

  background: linear-gradient(180deg, #e2c98d 23.07%, #ba9954 99.82%);
  box-shadow: 0px 0px 5px 0px #745400 inset;
  border: 2px solid transparent;
  border-radius: 60px;

  background-image: linear-gradient(180deg, #e2c98d 23.07%, #ba9954 99.82%),
    linear-gradient(180deg, #fff7e4 0%, #fde2ab 53.12%, #c89030 67.19%, #ffe9b9 92.71%, #4a2111 100%);

  background-origin: border-box;
  background-clip: content-box, border-box;

  // 마우스 호버
  &:hover {
    cursor: pointer;

    // css 나온대로 쓰고
    background: #000000;

    background-image: linear-gradient(0deg, #000000, #000000),
      linear-gradient(180deg, #fff7e4 0%, #fde2ab 53.12%, #c89030 67.19%, #ffe9b9 92.71%, #4a2111 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    cursor: pointer;

    background: #383838;

    background-image: linear-gradient(0deg, #383838, #383838),
      linear-gradient(180deg, #fff7e4 0%, #fde2ab 53.12%, #c89030 67.19%, #ffe9b9 92.71%, #4a2111 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 비활성화
  &:disabled {
    box-shadow: none;
    background: #242424;

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffffff4d;
  }
`
