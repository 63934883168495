import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { searchNormalPopupImgList } from '../../../api/popupImg/popupImgApi'
import CustomAlert from '../../../containers/CustomAlert'
import isLogin from '../../../utils/enums/authority'
import { HermesMainBorder, HermesMainText } from '../../../utils/palette'
import MobileSignInPopup from '../../MobileLayout/MobileSignIn'

const MobileMainCarousel = () => {
  // mobile 용 으로 가져와야 함
  const [mobileMainCarousel, setMobileMainCarousel] = useState([{ num: '1' }, { num: '2' }])

  const navigate = useNavigate()

  const { noteRedisSize, oneToOneRedisSize } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)
  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    navigate(link)
  }

  const [showControls, setShowControls] = useState(false) // 컨트롤 보이기/숨기기 상태

  useEffect(() => {
    // 캐러셀이 처음에 로드되었을 때 컨트롤을 숨김
    setShowControls(false)
  }, [])

  const [popupList, setPopupList] = useState([])
  useEffect(() => {
    searchNormalPopupImgList({ page: 0, size: 4 }).then(res => {
      const filteredItems = res.data.content.filter(item => item.mobileBanner)
      setPopupList(
        filteredItems.map(item => ({
          title: item.title,
          imgName: item.imgName,
          visible: true,
        })),
      )
    })
  }, [])

  return (
    <>
      {/* {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )} */}
      {/* {authorityAlertShow && <MobileSignInPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />} */}
      <>
        {popupList.map((item, index) => {
          return (
            <>
              <div style={{ height: '0px', backgroundColor: '#633114' }} />
              <div
                className="div_Default_Main_Image"
                onClick={item.title === '제우스 사칭주의' ? () => window.open('https://t.me/Zeus_CSCS') : undefined}
              >
                <a
                // href="event_view.aspx?IDX=140225"
                >
                  <img
                    style={{
                      marginBottom: '0px',
                      border: '0',
                      padding: '0',
                      display: 'block',
                    }}
                    alt=""
                    src={item.imgName}
                  />
                </a>
              </div>
            </>
          )
        })}
      </>
    </>
  )
}

export default MobileMainCarousel

const MainPageCarouselBtnBox = styled.div`
  position: absolute;
  left: -10px;
  bottom: 10px;
`

const MainPageCarouselBtn = styled.div`
  padding: 3px 20px;
  display: inline-block;
  margin: 16px 0 0 35px;
  color: #fff;
  cursor: pointer;
  border: 1px solid ${HermesMainBorder};
  border-radius: 16px;
  font-size: 13px;
  &:hover {
    background-background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const MobileMainCarouselWrap = styled.div`
  width: 100%;
  margin-top: 1px;
`

// const MobileCarouselButton = styled.button`
//   width: 100%;
//   font-size: 12px;
//   color: #fff;
//   background: none;
//   border: 1px solid ${HermesMainBorder};
//   border-radius: 16px;
//   position: absolute;
//   bottom: 10px;
// `
const MobileCarousel = styled(Carousel)`
  width: 100%;
  height: auto;
  position: relative; /* MainCarousel 내부에서 절대 위치 지정을 위해 필요합니다. */
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='pink' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    opacity: 1;
    position: absolute;
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='pink' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    opacity: 1;
    position: absolute;
  }
`

const MobileCarouselItem = styled(Carousel.Item)``

const MobileCarouselImg = styled.img`
  width: 100%;
  height: auto;
`

// import { CustomSelect } from '@/shared/components/CustomSelect'
// import { MobileTopWrap } from '@/shared/components/MoneyCustomElement'
// import Error from '@/shared/components/form/Error'
// import { FormGroupField } from '@/shared/components/form/FormElements'
// import ReactTableBase from '@/shared/components/table/ReactTableBase'
// import { commonReg2 } from '@/utils/validate/commonValidate'
// import { MoneyDepositValidate } from '@/utils/validate/moneyFormValidate'
// import React, { useEffect, useState } from 'react'
// import { Field, Form } from 'react-final-form'
// import ReactHtmlParser from 'react-html-parser'
// import { FaChevronLeft, FaTrash } from 'react-icons/fa'
// import { MdKeyboardArrowDown } from 'react-icons/md'
// import { useDispatch, useSelector } from 'react-redux'
// import parse from 'style-to-object'
// import styled from 'styled-components'
// import { searchMemberMoneyDeposit } from '../../api/memberHistory/memberHistoryApi'
// import {
//   createUserMoneyDeposit,
//   searchDepositRule,
//   searchMinimumAmount,
//   updateMemberMoneyHistoryDelete,
// } from '../../api/memberManage/memberManageApi'
// import CustomLoading from '../../containers/CustomLoading'
// import { onClickTitleHandler } from '../../redux/modalSlice'
// import Portal from '../../shared/components/Portal'
// import {
//   MobileMoneyFormButtomInfo,
//   MoneyFormInput,
//   MoneyFormInputWrap,
//   MoneyFormModifyButtomInfo,
// } from '../../shared/components/form/newForm'
// import { HermesMainBorder, HermesMainText, colorMainText } from '../../utils/palette'
// import MobileMoneyDepositListData from '../mobileMyPage/mobileMoneyDepositHistory/MobileMoneyDepositListData'
// import { CustomButtonInfo } from '../../shared/components/MobileMoneyCustomElement'

// const MobileMoneyDepositForm = ({ visible, onClose }) => {
//   const [myInfoLoading, setMyInfoLoading] = useState(true)
//   const [loading, setLoading] = useState(true)
//   const dispatch = useDispatch()
//   const [page, setPage] = useState(0)
//   const [size, setSize] = useState(10)

//   /**
//    *  회원 입금내역 조회
//    */
//   const [historyParams, setHistoryParams] = useState({
//     page,
//     size,
//   })

//   useEffect(() => {
//     setHistoryParams(prev => ({
//       ...prev,
//       page,
//       size,
//     }))
//   }, [page, size])

//   const [totalElement, setTotalElement] = useState(0)
//   const [content, setContent] = useState([])

//   useEffect(() => {
//     setLoading(true)
//     searchMemberMoneyDeposit(historyParams)
//       .then(res => {
//         setTotalElement(res.data.totalElement)
//         setContent(res.data.content)
//         setLoading(false)
//       })
//       .catch(error => {
//         setLoading(false)
//       })
//   }, [historyParams])

//   const fetchSearchMemberMoneyDeposit = async () => {
//     setLoading(true)
//     await searchMemberMoneyDeposit(historyParams)
//       .then(response => {
//         setTotalElement(response.data.totalElement)
//         setContent(response.data.content)
//         setLoading(false)
//       })
//       .catch(error => {
//         setLoading(false)
//       })
//   }

//   const [checkRadio, setCheckRadio] = useState([])
//   const [depositListData, setDepositListData] = useState(MobileMoneyDepositListData(content, checkRadio, setCheckRadio))

//   useEffect(() => {
//     setDepositListData(MobileMoneyDepositListData(content, checkRadio, setCheckRadio))
//   }, [content, checkRadio])

//   const [rows, setData] = useState(depositListData.tableRowsData)

//   useEffect(() => {
//     setData(depositListData.tableRowsData)
//   }, [depositListData])

//   const tableConfig = {
//     // 테이블 col size
//     isResizable: false,
//     // 테이블 col 정렬 여부
//     isSortable: false,
//     // 페이지네이션
//     withPagination: true,
//     // page
//     _pageIndex: page,
//     // size
//     _pageSize: size,
//     // total element
//     totalElement,
//     // setPageIndex
//     _setPageIndex: setPage,
//     // row 클릭 핸들러
//     // onClickRowHandler,
//   }

//   const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
//     const { memberInfo } = state

//     return {
//       holdingMoney: memberInfo.memberHoldingMoney,
//       totalGameMoney: memberInfo.memberTotalGameMoney,
//       userId: memberInfo.userId,
//       userName: memberInfo.userName,
//       bankName: memberInfo.bankName,
//       accountNum: memberInfo.accountNum,
//     }
//   })
//   const [depositRuleContent, setDepositRuleContent] = useState('')
//   const [isShowDepositRuleContent, setIsShowDepositRuleContent] = useState(false)

//   const [depositBonusList, setDepositBonusList] = useState([])
//   const [isShowDepositBonusMainSelectDrop, setIsShowDepositBonusMainSelectDrop] = useState(false)

//   // 선택한 보너스 타입
//   const [selectedBonusType, setSelectedBonusType] = useState(null)

//   const onClickDepositBonusTypeHandler = key => {
//     const data = depositBonusList.find(item => item.moneyDepositBonusKey === key)
//     if (data) {
//       setSelectedBonusType({
//         moneyDepositBonusKey: data.moneyDepositBonusKey,
//         title: data.title,
//         bonusType: data.bonusType,
//       })
//     }
//     setIsShowDepositBonusMainSelectDrop(false)
//   }

//   const getDepositBonusImageUrl = type => {
//     // hover 때문에 확장자 뺌
//     switch (type) {
//       case 'BONUS_NONE':
//         return `${process.env.PUBLIC_URL}/img/main/bonus_sports_etc`
//       case 'BONUS_SPORTS':
//       case 'BONUS_SPORTS_NEW':
//       case 'BONUS_PLUS':
//         return `${process.env.PUBLIC_URL}/img/main/bonus_sports`
//       case 'BONUS_CASINO':
//       case 'BONUS_CASINO_NEW':
//         return `${process.env.PUBLIC_URL}/img/main/bonus_casino`
//       case 'BONUS_SLOT_CASINO':
//       case 'BONUS_SLOT_CASINO_NEW':
//         return `${process.env.PUBLIC_URL}/img/main/bonus_slot`
//       case 'BONUS_MINI_GAME':
//       case 'BONUS_MINI_GAME_NEW':
//         return `${process.env.PUBLIC_URL}/img/main/bonus_mini`
//       default:
//         return ''
//     }
//   }

//   const getSelectedBonusType = () => {
//     switch (selectedBonusType?.bonusType) {
//       case 'BONUS_NONE':
//         return <span>{selectedBonusType?.title}</span>
//       case 'BONUS_SPORTS_NEW':
//         return <span>{selectedBonusType?.title}</span>
//       case 'BONUS_SPORTS':
//         return <span>{selectedBonusType?.title}</span>
//       case 'BONUS_CASINO':
//         return <span>{selectedBonusType?.title}</span>
//       case 'BONUS_CASINO_NEW':
//         return <span>{selectedBonusType?.title}</span>
//       case 'BONUS_SLOT_CASINO':
//         return <span>{selectedBonusType?.title}</span>
//       case 'BONUS_SLOT_CASINO_NEW':
//         return <span>{selectedBonusType?.title}</span>
//       case 'BONUS_PLUS':
//         return <span>{selectedBonusType?.title}</span>
//       case 'BONUS_MINI_GAME':
//         return <span>{selectedBonusType?.title}</span>
//       case 'BONUS_MINI_GAME_NEW':
//         return <span>{selectedBonusType?.title}</span>
//       default:
//         return <span>입금 보너스 선택하기</span>
//     }
//   }

//   useEffect(() => {
//     searchDepositRule().then(res => {
//       setDepositRuleContent(res.data.depositRule)
//       setDepositBonusList(res.data.depositBonusList)
//     })
//   }, [])

//   // 최소 입금액
//   const [minimumDeposit, setMinimumDeposit] = useState('0')
//   const [depositMaxAmount, setDepositMaxAmount] = useState('0')

//   useEffect(() => {
//     searchMinimumAmount()
//       .then(res => {
//         setMinimumDeposit(res.data.minimumDeposit)
//         setDepositMaxAmount(res.data.depositMaxAmount)
//         setMyInfoLoading(false)
//       })
//       .catch(error => {
//         setMyInfoLoading(false)
//       })
//   }, [])
//   // 신청금액
//   const [applyMoney, setApplyMoney] = useState('0')

//   // 실입금액
//   const [realMoney, setRealMoney] = useState('0')

//   const onClickMoneyHandler = name => {
//     setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
//   }

//   const onClearClickHandler = () => {
//     setApplyMoney('0')
//   }

//   const checkNumber = checkMoney => {
//     return Math.sign(checkMoney)
//   }

//   const [inquiryButton, setInquiryButton] = useState(false)

//   // api 여러번 호출 막기
//   const [apiFlag, setApiFlag] = useState(false)
//   const onSubmit = e => {
//     if (apiFlag) return
//     const checkMoney = applyMoney?.toString().replaceAll(',', '')
//     if (Number(checkMoney) <= 0) {
//       alert('금액을 다시 확인해주세요.')
//       return
//     }

//     if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
//       alert('10,000원 단위로 입력이 가능합니다.')
//       return
//     }

//     if (Number(checkMoney) > Number(depositMaxAmount)) {
//       alert(`최대 ${depositMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 입금 가능합니다.`)
//       return
//     }
//     setApiFlag(true)

//     const body = {
//       moneyType: 'DEPOSIT',
//       applyMoney: checkMoney,
//       bonusType: selectedBonusType?.bonusType || 'BONUS_NONE',
//       bonusTypeKr: selectedBonusType?.title || '보너스를 선택하지 않음',
//     }
//     setLoading(true)
//     createUserMoneyDeposit(body)
//       .then(res => {
//         setLoading(false)
//         alert('입금신청이 완료되었습니다.')
//         setApiFlag(false)
//         fetchSearchMemberMoneyDeposit()
//         onClearClickHandler()
//       })
//       .catch(error => {
//         const { errorCode, message } = error.response.data
//         setLoading(false)
//         switch (errorCode) {
//           case 'MONEY-1007':
//             alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
//             break
//           case 'MONEY-1008':
//             alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역 탭에서 상태 확인 후 다시 시도해주세요.')
//             break
//           case 'MONEY-1009':
//             alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
//             break
//           case 'ASSET-1010':
//             alert('입금이 불가능한 시간입니다.')
//             break
//           case 'ASSET-1004':
//             alert(message)
//             break
//           case 'ASSET-7001':
//             alert('잠시후 다시 시도해주세요.')
//             break
//           default:
//             alert('입금신청이 실패했습니다. 다시 시도해주세요.')
//         }
//         fetchSearchMemberMoneyDeposit()
//         setApiFlag(false)
//       })
//   }
//   const onMaskClick = e => {
//     if (e.target === e.currentTarget) {
//       onClose(e)
//     }
//   }

//   const close = e => {
//     if (onClose) {
//       onClose(e)
//     }
//   }
//   const [selectedIndex, setSelectedIndex] = useState(0)

//   const transformFn = (node, index) => {
//     if (node.type === 'tag' && node.name === 'img') {
//       let style = {}
//       if (node.attribs?.style) {
//         style = parse(node.attribs?.style)
//       }
//       if (style?.width === 'auto' && style?.height === 'auto') {
//         return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
//       }
//       return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
//     }
//   }

//   const [deleteLoading, setDeleteLoading] = useState(false)
//   const onDeleteHandler = () => {
//     if (deleteLoading) return
//     if (checkRadio.length === 0) {
//       alert('삭제 할 목록을 선택해주세요.')
//       return
//     }

//     if (window.confirm('정말 삭제하시겠습니까?')) {
//       setDeleteLoading(true)
//       updateMemberMoneyHistoryDelete({
//         moneyType: 'DEPOSIT',
//         moneyHistoryKeyList: checkRadio,
//       })
//         .then(res => {
//           setDeleteLoading(false)
//           alert('삭제 완료 되었습니다.')
//           setCheckRadio([])
//           fetchSearchMemberMoneyDeposit()
//         })
//         .catch(error => {
//           setDeleteLoading(false)
//           alert('삭제 실패했습니다.')
//           setCheckRadio([])
//           fetchSearchMemberMoneyDeposit()
//         })
//     }
//   }

//   return (
//     <Portal elementId="signup-modal">
//       <SignUpModalOverlay visible={visible} />
//       <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
//         <SignUpModalInner tabIndex="0">
//           <SignUpModalHeader>
//             <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
//             <SignUpModalCloseButton
//               onClick={close}
//               src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
//               alt=""
//             />
//           </SignUpModalHeader>
//           <SignUpModalContentWrap>
//             <MobileTopWrap>
//               {myInfoLoading || loading ? (
//                 <CustomLoading />
//               ) : (
//                 <>
//                   <ContentWrap>
//                     <ContentRowWrap>
//                       <FaChevronLeft
//                         onClick={e => {
//                           dispatch(onClickTitleHandler('MYPAGE'))
//                         }}
//                       />
//                       <ContentRowSmall>
//                         <div>
//                           <span style={{ color: '#b2b2b2' }}>스포츠 캐쉬</span>
//                           <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
//                             {holdingMoney?.toString().replace(commonReg2, ',')} 원
//                           </span>
//                         </div>
//                       </ContentRowSmall>
//                       <ContentRowSmall>
//                         <div>
//                           <span style={{ color: '#b2b2b2' }}>카지노 캐쉬</span>
//                           <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
//                             {totalGameMoney?.toString().replace(commonReg2, ',')} 원
//                           </span>
//                         </div>
//                       </ContentRowSmall>
//                     </ContentRowWrap>
//                     <ContentRowThirdWrap>
//                       <ContentRowBig selectedIndex={selectedIndex}>
//                         <div onClick={() => setSelectedIndex(0)}>입금</div>
//                         <div onClick={() => setSelectedIndex(1)}>입금내역</div>
//                         <div onClick={() => setSelectedIndex(2)}>입금규정</div>
//                       </ContentRowBig>
//                     </ContentRowThirdWrap>
//                     <ContentInfo>
//                       {
//                         // 여기서 구분
//                       }
//                       {selectedIndex === 0 && (
//                         <Form
//                           onSubmit={onSubmit}
//                           initialValues={{
//                             memberId: userId,
//                             memberName: userName,
//                             bankName,
//                             accountHolder: accountNum,
//                             applyMoney,
//                             holdingMoney,
//                             minimumDeposit,
//                             depositMaxAmount,
//                           }}
//                           validate={MoneyDepositValidate}
//                         >
//                           {({ handleSubmit, form: { reset } }) => (
//                             <div onSubmit={handleSubmit}>
//                               <ContentRowSecondWrap>
//                                 <span style={{ fontWeight: '500', marginBottom: '30px' }}>
//                                   ※ <span style={{ color: `${HermesMainText}` }}>계좌문의</span>는 문의란 계좌문의
//                                   클릭하시면 관리자에게 신청됩니다.
//                                 </span>
//                                 <DepositBonusSelectWrap>
//                                   <DepositBonusSelectInnerWrap>
//                                     <DepositBonusMainSelectWrap
//                                       onClick={() => {
//                                         setIsShowDepositBonusMainSelectDrop(prev => !prev)
//                                       }}
//                                     >
//                                       {getSelectedBonusType()}
//                                       <CustomMdKeyboardArrowDown />
//                                     </DepositBonusMainSelectWrap>
//                                     {isShowDepositBonusMainSelectDrop && (
//                                       <DepositBonusMainSelectDropWrap>
//                                         {/* 아래부터 반복 */}
//                                         {depositBonusList.map(item => (
//                                           <DepositBonusMainSelectDropItemWrap>
//                                             <DepositBonusMainSelectDropItemBox
//                                               url={getDepositBonusImageUrl(item.bonusType)}
//                                               onClick={() => {
//                                                 onClickDepositBonusTypeHandler(item.moneyDepositBonusKey)
//                                               }}
//                                             >
//                                               <DepositBonusMainSelectDropItemImg />
//                                               <DepositBonusMainSelectDropItemTextBox sc={item.content}>
//                                                 <DepositBonusMainSelectDropItemTextContent>
//                                                   {item.title}
//                                                 </DepositBonusMainSelectDropItemTextContent>
//                                                 {item.content && (
//                                                   <DepositBonusMainSelectDropItemTextSubContent>
//                                                     {item.content}
//                                                   </DepositBonusMainSelectDropItemTextSubContent>
//                                                 )}
//                                               </DepositBonusMainSelectDropItemTextBox>
//                                             </DepositBonusMainSelectDropItemBox>
//                                           </DepositBonusMainSelectDropItemWrap>
//                                         ))}
//                                       </DepositBonusMainSelectDropWrap>
//                                     )}
//                                   </DepositBonusSelectInnerWrap>
//                                 </DepositBonusSelectWrap>
//                                 <div
//                                   style={{
//                                     color: '#fff',
//                                     marginTop: '16px',
//                                     width: '100%',
//                                     padding: '0 16px',
//                                     fontWeight: '500',
//                                     fontSize: '13px',
//                                   }}
//                                 >
//                                   <p style={{ wordBreak: 'keep-all' }}>
//                                     * 전날 입금 후 익일 환전 받을시 이벤트 재참여 가능 하십니다.{' '}
//                                     <span style={{ color: `${HermesMainText}` }}> (고객센터 문의)</span>
//                                   </p>
//                                   <p style={{ wordBreak: 'keep-all' }}>
//                                     * 원하시는 이벤트종목 참여 후 다른게임 이용시 불이익을 받을 수 있습니다.
//                                     <span style={{ color: `${HermesMainText}` }}> (몰수 또는 이벤트 참여 제제)</span>
//                                   </p>
//                                   <p style={{ wordBreak: 'keep-all' }}>
//                                     * 이벤트 참여 전 필히{' '}
//                                     <span style={{ color: `${HermesMainText}` }}>이벤트 이용규정</span> 확인
//                                     부탁드립니다.
//                                   </p>
//                                   <p style={{ wordBreak: 'keep-all' }}>
//                                     * 당일 환전내역 있을시 이벤트 참여 불가능 하십니다.
//                                   </p>
//                                 </div>
//                                 <CustomerCenterFormGroup style={{ marginBottom: '30px' }}>
//                                   <CustomerCenterFormLabel>
//                                     {' '}
//                                     입금신청 금액
//                                     <div
//                                       style={{ marginTop: '5px', width: '100%', fontSize: '12px', fontWeight: '400' }}
//                                     >
//                                       <p>
//                                         * 입금 최소액은{' '}
//                                         <span style={{ color: `${HermesMainBorder}` }}>
//                                           {' '}
//                                           {minimumDeposit?.toString().replace(commonReg2, ',') || 0}원
//                                         </span>
//                                         <br /> &nbsp;1회 입금 최대액
//                                         <span style={{ color: `${HermesMainBorder}` }}> (레벨별 상이)</span>은{' '}
//                                         <span style={{ color: `${HermesMainBorder}` }}>
//                                           {' '}
//                                           {depositMaxAmount?.toString().replace(commonReg2, ',') || 0}원
//                                         </span>{' '}
//                                         입니다.
//                                       </p>
//                                     </div>
//                                   </CustomerCenterFormLabel>
//                                   <CustomCenterFormField>
//                                     <Field name="applyMoney">
//                                       {({ input, meta }) => (
//                                         <MoneyFormInputWrap>
//                                           <MoneyFormInput
//                                             {...input}
//                                             type="text"
//                                             value={input.value}
//                                             onChange={e => {
//                                               input.onChange(e)
//                                               setApplyMoney(e.target.value)
//                                             }}
//                                           />
//                                           {meta.error && <Error error={meta.error} isMobile />}
//                                         </MoneyFormInputWrap>
//                                       )}
//                                     </Field>
//                                   </CustomCenterFormField>
//                                 </CustomerCenterFormGroup>
//                                 <CustomerCenterFormGroup>
//                                   <CustomCenterFormField>
//                                     <MoneyFormButton
//                                       size="sm"
//                                       type="button"
//                                       variant="outline-secondary"
//                                       name="10000"
//                                       onClick={e => {
//                                         onClickMoneyHandler('10000')
//                                       }}
//                                     >
//                                       <MobileMoneyFormButtomInfo>1만원</MobileMoneyFormButtomInfo>
//                                     </MoneyFormButton>
//                                     <MoneyFormButton
//                                       size="sm"
//                                       type="button"
//                                       variant="outline-secondary"
//                                       name="50000"
//                                       onClick={e => {
//                                         onClickMoneyHandler('50000')
//                                       }}
//                                     >
//                                       <MobileMoneyFormButtomInfo>5만원</MobileMoneyFormButtomInfo>
//                                     </MoneyFormButton>
//                                     <MoneyFormButton
//                                       size="sm"
//                                       type="button"
//                                       variant="outline-secondary"
//                                       name="100000"
//                                       onClick={e => {
//                                         onClickMoneyHandler('100000')
//                                       }}
//                                     >
//                                       <MobileMoneyFormButtomInfo>10만원</MobileMoneyFormButtomInfo>
//                                     </MoneyFormButton>
//                                     <MoneyFormButton
//                                       size="sm"
//                                       type="button"
//                                       variant="outline-secondary"
//                                       name="500000"
//                                       onClick={e => {
//                                         onClickMoneyHandler('500000')
//                                       }}
//                                     >
//                                       <MobileMoneyFormButtomInfo>50만원</MobileMoneyFormButtomInfo>
//                                     </MoneyFormButton>
//                                   </CustomCenterFormField>
//                                 </CustomerCenterFormGroup>
//                                 <CustomerCenterFormGroup>
//                                   <CustomCenterFormField>
//                                     <MoneyFormButton
//                                       size="sm"
//                                       type="button"
//                                       variant="outline-secondary"
//                                       name="1000000"
//                                       onClick={e => {
//                                         onClickMoneyHandler('1000000')
//                                       }}
//                                     >
//                                       <MobileMoneyFormButtomInfo>100만원</MobileMoneyFormButtomInfo>
//                                     </MoneyFormButton>
//                                     <MoneyFormButton
//                                       size="sm"
//                                       type="button"
//                                       variant="outline-secondary"
//                                       name="3000000"
//                                       onClick={e => {
//                                         onClickMoneyHandler('3000000')
//                                       }}
//                                     >
//                                       <MobileMoneyFormButtomInfo>300만원</MobileMoneyFormButtomInfo>
//                                     </MoneyFormButton>
//                                     <MoneyFormButton
//                                       size="sm"
//                                       type="button"
//                                       variant="outline-secondary"
//                                       name="10000000"
//                                       onClick={e => {
//                                         onClickMoneyHandler('10000000')
//                                       }}
//                                     >
//                                       <MobileMoneyFormButtomInfo>1000만원</MobileMoneyFormButtomInfo>
//                                     </MoneyFormButton>
//                                     <MoneyFormButton
//                                       size="sm"
//                                       type="button"
//                                       variant="outline-secondary"
//                                       name="0"
//                                       onClick={e => {
//                                         onClearClickHandler()
//                                       }}
//                                     >
//                                       <MobileMoneyFormButtomInfo>
//                                         <FaTrash />
//                                       </MobileMoneyFormButtomInfo>
//                                     </MoneyFormButton>
//                                   </CustomCenterFormField>
//                                 </CustomerCenterFormGroup>
//                                 <MoneyButton variant="primary" type="submit" onClick={onSubmit}>
//                                   <CustomMoneyFormModifyButtomInfo>입금신청</CustomMoneyFormModifyButtomInfo>
//                                 </MoneyButton>
//                               </ContentRowSecondWrap>
//                             </div>
//                           )}
//                         </Form>
//                       )}
//                       {selectedIndex === 1 && (
//                         <MoneyDepositBodyTwoWrap>
//                           <ContentRowWrap2>
//                             <CustomButtonWrap>
//                               <CustomDeleteButton
//                                 onClick={() => {
//                                   setCheckRadio(content.map(value => value.moneyDepositKey))
//                                 }}
//                                 type="button"
//                               >
//                                 <CustomButtonInfos>전체선택</CustomButtonInfos>
//                               </CustomDeleteButton>
//                               <CustomDeleteButton
//                                 onClick={() => {
//                                   onDeleteHandler()
//                                 }}
//                                 variant="primary"
//                                 type="button"
//                               >
//                                 <FaTrash />
//                                 <CustomButtonInfos>삭제</CustomButtonInfos>
//                               </CustomDeleteButton>
//                             </CustomButtonWrap>
//                           </ContentRowWrap2>
//                           <ReactTableBase
//                             columns={depositListData.tableHeaderData}
//                             data={rows}
//                             tableConfig={tableConfig}
//                           />
//                         </MoneyDepositBodyTwoWrap>
//                       )}
//                       {selectedIndex === 2 && (
//                         <MoneyDepositRuleWrap>
//                           <MoneyDepositRuleContentWrap>
//                             {ReactHtmlParser(depositRuleContent, { transform: transformFn })}
//                           </MoneyDepositRuleContentWrap>
//                         </MoneyDepositRuleWrap>
//                       )}
//                     </ContentInfo>
//                   </ContentWrap>
//                 </>
//               )}
//             </MobileTopWrap>
//           </SignUpModalContentWrap>
//         </SignUpModalInner>
//       </SignUpModalWrapper>
//     </Portal>
//   )
// }
