import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import parse from 'style-to-object'
import styled from 'styled-components'
import { fetchEventBoardList } from '../../../redux/customCenterSlice'
import { WebPageWrap } from '../../../shared/components/form/newForm'
import CustomLoading from '../../CustomLoading'
import Portal from '../../../shared/components/Portal'
import EventDetail from './EventDetail'
import { convertToKstByFormat } from '../../../utils/dateTime'
// import PartnerManageMentTabs from '../../PartnerManageMentTabs'

const EventListPage = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const navigate = useNavigate()
  useEffect(() => {
    setScreenHeight(window.innerHeight)
    const handleResize = () => {
      setScreenHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)

  const [boardParams, setBoardParams] = useState({
    boardType: 'EVENT',
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [content, setContent] = useState([])
  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.eventInfo.totalElement,
      apiContent: customCenter.eventInfo.content,
    }
  })
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.boardKey === item.boardKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchBoardList = async () => {
    try {
      dispatch(fetchEventBoardList(boardParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchBoardList()
  }, [boardParams])

  // row 클릭 이벤트 핸들러
  const [hangData, setHandData] = useState({})
  const onClickRowHandler = item => {
    setHandData({
      boardContent: item.boardContent,
      boardTitle: item.boardTitle,
      imgUrl: item.imgUrl,
    })
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)

  const [isModalShow, setModalShow] = useState(false)
  const onChangeModalState = () => {
    setModalShow(prev => !prev)
  }
  const onMaskClick = e => {
    // if (e.target === e.currentTarget) {
    //   setModalShow(false)
    // }
  }

  const close = e => {
    // setModalShow(false)
  }

  const [showDetail, setShowDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleRowClick = boardKey => {
    const boardContent = content.find(item => item.boardKey === boardKey)
    if (!boardContent) return
    setSelectedItem(boardContent)
    setShowDetail(true)
  }
  return (
    <>
      {showDetail ? (
        <EventDetail notice={selectedItem} onBack={() => setShowDetail(false)} />
      ) : (
        <div className="div_content" style={{ position: 'relative' }}>
          <div className="div_body">
            <div className="content">
              <table className="tb_notice_box">
                <tbody>
                  <tr>
                    <td className="td_notice_td">
                      <b>
                        <span style={{ fontSize: '11pt' }}>
                          <span
                            style={{ color: 'rgb(0, 0, 0)', cursor: 'pointer' }}
                            onClick={() => {
                              navigate('/one-to-one')
                            }}
                          >
                            이벤트 내용 참고 후 해당되는 이벤트는 고객센터 문의 바랍니다.
                          </span>
                        </span>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="height_20" />
              <div id="CPH_message_list" className="div_list">
                <table style={{ textAlign: 'center' }}>
                  <colgroup>
                    <col style={{ width: '8%' }} />
                    <col style={{ width: '75%' }} />
                    <col style={{ width: '17%' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>번호</th>
                      <th>제목</th>
                      <th>작성자</th>
                    </tr>
                  </thead>
                  <tbody>
                    {content.length === 0 ? (
                      <tr>
                        <td colSpan="3">이벤트 내역이 없습니다.</td>
                      </tr>
                    ) : (
                      <>
                        {content.map(item => (
                          <>
                            <tr key={item.boardKey} onClick={() => handleRowClick(item.boardKey)}>
                              <td>
                                {item.num}
                                {/* <img src={`${process.env.PUBLIC_URL}/img/customer/event_btn.gif`} alt="" /> */}
                              </td>
                              <td style={{ cursor: 'pointer' }}>
                                <b style={{ color: 'rgb(0,0,0)', fontWeight: '900' }}>★Zeus★&nbsp;</b>
                                <span style={{ color: 'rgb(0,0,0)', fontWeight: '500' }}>{item.boardTitle}</span>
                              </td>
                              <td
                                style={{ textAlign: 'center', paddingLeft: '10px' }}
                                // onclick="go_message_read('4586');"
                              >
                                <img
                                  src={`${process.env.PUBLIC_URL}/img/level/08.png`}
                                  alt=""
                                  align="absmiddle"
                                  style={{ marginRight: '5px', marginTop: '-2px', width: '40px', height: '32px' }}
                                />{' '}
                                제우스 운영팀
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default EventListPage

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9991;
  overflow: auto;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9990;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgb(16, 16, 18);
  // border: solid 1px blue;
  // padding-bottom: 30px;
  width: 1250px;
  margin: 0 auto;
  height: 80vh;
  margin-top: 7vh;
  overflow: hidden;
  color: #606266;
  word-break: break-all;
`

const SignUpModalHeader = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const SignUpModalHeaderLogo = styled.img`
  width: 200px;
  height: 50px;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 20px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  color: #606266;
  word-break: break-all;
  padding: 5px 20px;
  height: 100%;
  display: flex;
  gap: 20px;
  overflow: hidden;
`

// modal body content
const SignUpModalContentFirst = styled.img`
  width: 348px;
  height: 192px;
  // border: solid 1px red;
  // margin-right: 30px;
`

const SignUpModalContentSecondWrap = styled.div`
  width: 100%;
  height: 72vh;
  overflow: auto;
  padding: 10px;
  padding-bottom: 20px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  // border: solid 1px red;
  gap: 10px;
`

const SignUpModalContentSecond = styled.div`
  width: 100%;
  color: #fff;
  font-size: 18px;
`

const SignUpModalContentThird = styled.div`
  // height: auto;
`

const EventRealWrap = styled.div``

const EventWrap = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fill, minmax(348px, 1fr));
  margin-top: 20px;
  padding: 10px 2px;
  gap: 20px;
`

const EventContentBox = styled.div`
  width: 380px;
  height: 320px;
  background: rgb(26, 28, 31);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: center;
  gap: 10px;
  transition: transform 0.3s ease-out, background 0.3s ease-out; // 애니메이션 효과 지속 시간과 형태 설정

  &:hover {
    // 호버 상태 설정
    cursor: pointer;
    background: rgb(39, 43, 46);
    transform: translateY(-10px); // 상단으로 10픽셀만큼 올림
  }

  img {
    width: 348px;
    height: 192px;
    margin-left: 16px;
  }
`

const EventContentTitle = styled.div`
  width: 100%;
  color: #eec100;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 600;
`
const EventContentTitle2 = styled.div`
  width: 100%;
  color: #fff;
  padding-left: 20px;
  font-size: 24px;
  font-weight: 600;
`
