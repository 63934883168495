import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled, { keyframes } from 'styled-components'
import { Collapse } from 'react-bootstrap'
import { deleteNoteList, updateNoteStatus, updateNoteStatusAll } from '../../../api/customCenter/customCenterApi'
import { fetchNoteList } from '../../../redux/customCenterSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import {
  CustomerCenterContentWrap,
  CustomerCenterHeaderInfo,
  ListButton,
  MoneyFormModifyButtomInfo,
} from '../../../shared/components/MoneyCustomElement'
import { WebPageWrap } from '../../../shared/components/form/newForm'
import { convertToKstByFormat, convertToKstDateTime } from '../../../utils/dateTime'
import { NoteReadStatusEnums } from '../../../utils/enums/Operation/NoteEnums'
import { colorMainText } from '../../../utils/palette'
import CustomLoading from '../../CustomLoading'
import NoteRadio from './NoteRadio'
import { fetchNoteSize } from '../../../redux/memberInfoSlice'
import NoteDetail from './NoteDetail'

const NoteList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const dispatch = useDispatch()
  const [noteParams, setNoteParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setNoteParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [loading, setLoading] = useState(true)

  const [content, setContent] = useState([])
  const { totalElement, apiContent, noReadSize } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.noteInfo.totalElement,
      apiContent: customCenter.noteInfo.content,
      noReadSize: customCenter.noteInfo.noReadSize,
    }
  })

  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.noteKey === item.noteKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchNoteList = async () => {
    try {
      dispatch(fetchNoteList(noteParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchNoteList()
  }, [noteParams])

  const [checkRadio, setCheckRadio] = useState([])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = noteKey => {
    const idx = content.findIndex(item => item.noteKey === noteKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })

      const idx2 = content.findIndex(item => item.noteKey === noteKey)
      if (idx2 !== -1 && content[idx2]?.status === 'NOREAD') {
        updateNoteStatus({
          noteKey: content[idx2].noteKey,
        }).then(res => {
          fetchSearchNoteList()
          dispatch(fetchNoteSize())
        })
      }
    }
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 쪽지를 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteNoteList({
        noteKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
    }
  }
  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  const [showDetail, setShowDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleRowClick = boardKey => {
    const idx = content.findIndex(item => item.noteKey === boardKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })

      const idx2 = content.findIndex(item => item.noteKey === boardKey)
      if (idx2 !== -1 && content[idx2]?.status === 'NOREAD') {
        updateNoteStatus({
          noteKey: content[idx2].noteKey,
        }).then(res => {
          fetchSearchNoteList()
          dispatch(fetchNoteSize())
        })
      }
    }
    const boardContent = content.find(item => item.noteKey === boardKey)
    if (!boardContent) return
    setSelectedItem(boardContent)
    setShowDetail(true)
  }

  const [readLoading, setReadLoading] = useState(false)
  // 삭제하기 버튼
  const onReadHandler = () => {
    if (readLoading) return

    setReadLoading(true)
    updateNoteStatusAll({})
      .then(res => {
        setReadLoading(false)
        dispatch(fetchNoteSize())
        fetchSearchNoteList()
      })
      .catch(error => {
        setReadLoading(false)
        dispatch(fetchNoteSize())
        fetchSearchNoteList()
      })
  }

  return (
    <>
      {(loading || readLoading) && <CustomLoading />}
      {showDetail ? (
        <NoteDetail notice={selectedItem} onBack={() => setShowDetail(false)} />
      ) : (
        <div className="div_content" style={{ position: 'relative' }}>
          <div className="div_body">
            <div className="content">
              <table className="tb_notice_box">
                <tbody>
                  <tr>
                    <td className="td_notice_td">
                      <b>
                        <span style={{ fontSize: '11pt' }}>
                          <span style={{ color: 'rgb(0, 0, 0)' }}>
                            <span style={{ color: 'rgb(255, 0, 0)' }}>계좌변경</span> 및{' '}
                            <span style={{ color: 'rgb(255, 0, 0)' }}>도메인변경</span>에 관한 쪽지는 필히 확인
                            바랍니다.
                          </span>
                        </span>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="height_20" />
              <div id="CPH_message_list" className="div_list" style={{ marginBottom: '10px' }}>
                <div style={{ display: 'flex', gap: '5px', margin: '0 0 5px 0', float: 'right' }}>
                  <a
                    className="btMoney"
                    style={{ width: '66px' }}
                    onClick={() => {
                      onReadHandler()
                    }}
                  >
                    전체읽기
                  </a>
                  <a
                    className="btMoney"
                    style={{ width: '66px' }}
                    onClick={() => {
                      setCheckRadio(content.map(value => value.noteKey))
                    }}
                  >
                    전체선택
                  </a>
                  <a
                    className="btMoney"
                    style={{ width: '66px' }}
                    onClick={() => {
                      onDeleteHandler()
                    }}
                  >
                    삭제하기
                  </a>
                </div>
                <table style={{ textAlign: 'center' }}>
                  <colgroup>
                    <col style={{ width: '6%' }} />
                    <col style={{ width: '16%' }} />
                    <col style={{ width: '70%' }} />
                    <col style={{ width: '8%' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>선택</th>
                      <th>수신일자</th>
                      <th>쪽지제목</th>
                      <th>확인여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    {content.length === 0 ? (
                      <tr>
                        <td colSpan="4">쪽지 내역이 없습니다.</td>
                      </tr>
                    ) : (
                      <>
                        {content.map(item => (
                          <>
                            <tr key={item.noteKey}>
                              <td>
                                {item.status === 'READ' ? (
                                  <NoteRadio
                                    rowInfo={{
                                      noteKey: item.noteKey,
                                      status: item.status,
                                    }}
                                    setCheckRadio={setCheckRadio}
                                    checkRadio={checkRadio}
                                  />
                                ) : (
                                  <>&nbsp;</>
                                )}
                              </td>
                              <td
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleRowClick(item.noteKey)}
                              >
                                {convertToKstByFormat(item.sendDate)}
                              </td>
                              <td
                                onClick={() => handleRowClick(item.noteKey)}
                                style={{
                                  textAlign: 'left',
                                  paddingLeft: '10px',
                                  cursor: 'pointer',
                                  wordBreak: 'break-all',
                                }}
                                // onclick="go_message_read('4586');"
                              >
                                <b style={{ color: 'rgb(0,0,0)', fontWeight: '900' }}>★Zeus★&nbsp;</b>
                                <span style={{ color: 'rgb(0,0,0)', fontWeight: '500' }}>
                                  &nbsp;
                                  {item.noteTitle}
                                </span>
                              </td>
                              <td
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleRowClick(item.noteKey)}
                              >
                                {NoteReadStatusEnums[item.status]}
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {totalElement > 0 && (
                <>
                  <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default NoteList

const ListWrap = styled.div`
  display: flex;
`

export const OnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`
export const OneToOneHeaderWrap = styled.div`
  padding: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px
  border-bottom: solid 1px #edae07;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;

  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(22, 23, 25);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  padding: 10px 5px;

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-weight: 600;
  }
`
