import { useMemo } from 'react'
import { createPortal } from 'react-dom'

/**
 * @see : https://dantechblog.gatsbyjs.io/posts/react-portal-modal/
 * @see : https://medium.com/@bestseob93/%ED%9A%A8%EC%9C%A8%EC%A0%81%EC%9D%B8-%EB%A6%AC%EC%95%A1%ED%8A%B8-%EB%AA%A8%EB%8B%AC-react-modal-%EB%A7%8C%EB%93%A4%EA%B8%B0-bd003458e9d
 */
function Portal({ children, elementId }) {
  const rootElement = useMemo(() => document.getElementById(elementId), [elementId])

  return createPortal(children, rootElement)
}

export default Portal
