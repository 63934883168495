import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import { FaLock } from 'react-icons/fa'
import { searchSportsFixtureBalanceList } from '../../../api/game/sportsGameApi'
import {
  fetchFixtureMainBalanceListAction,
  selectSideSportMenu,
  setSelectedBalanceSportId,
} from '../../../redux/sportsDomesticInfoSlice'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import CustomLoading from '../../CustomLoading'
import { KyochonMainBorder } from '../../../utils/palette'

const createBettingCartItem = (fixture, market, selectedBetInfo) => {
  // betLines 를 제거하기 위함
  const { betLines, ...rest } = market

  return {
    ...fixture,
    ...rest,
    selectedBetInfo,
  }
}

const MainPageSportsBalanceContent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const location = useLocation()

  const { mainBalanceParams, mainBalanceEvents } = useSelector(state => {
    const { sportsDomesticInfo } = state

    return {
      mainBalanceParams: sportsDomesticInfo.mainBalanceParams,
      mainBalanceEvents: sportsDomesticInfo.mainBalanceEvents,
    }
  })

  const [apiLoading, setApiLoading] = useState(false)

  const fetchSportsFixtureBalanceList = async () => {
    dispatch(fetchFixtureMainBalanceListAction())
  }

  useEffect(() => {
    setApiLoading(true)

    fetchSportsFixtureBalanceList().then(() => {
      setApiLoading(false)
    })

    const intervalId = setInterval(fetchSportsFixtureBalanceList, 10000)

    return () => clearInterval(intervalId)
  }, [dispatch, mainBalanceParams])

  // 메인 마켓 클릭시 핸들러
  const onClickBetHandler = bettingCartItem => {
    if (location.pathname === '/main') {
      dispatch(setSelectedBalanceSportId(bettingCartItem.sportId))
      navigate('/sports/balance')
    }
  }

  const realTimeSliderSetting = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    fade: false,
    vertical: false,
    rows: 1,
    draggable: true,
    infinite: true,
  }

  return (
    <>
      {apiLoading && <CustomLoading />}
      <div id="sports_balance_wrap" style={{ marginBottom: '100px' }}>
        <HeaderTitleBox>
          <span>실시간 배팅 현황</span>
        </HeaderTitleBox>
        <div className="flexbox" style={{ minHeight: '600px' }}>
          <div className="centerbox">
            <section id="sports_balance_betting_list">
              <div id="board_list" style={{ minHeight: 0 }}>
                {Object.keys(mainBalanceEvents).length === 0 ? (
                  <div className="no_live_box" style={{ width: '100%', textAlign: 'center', lineHeight: '315px' }}>
                    <strong style={{ color: '#0062df', fontSize: '24px' }}>진행중인 경기가 없습니다.</strong>
                  </div>
                ) : (
                  <>
                    {Object.entries(mainBalanceEvents).map(([key, events]) => {
                      const parts = key.split(':')
                      return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <EventSportBox>
                            <img src={`${process.env.PUBLIC_URL}/img/sports/sportsType/side-${parts[0]}.png`} alt="" />
                            <span>{parts[1]}</span>
                          </EventSportBox>
                          <CustomSlider {...realTimeSliderSetting}>
                            {events.map(event => {
                              const totalBetMoeny =
                                event.fixture.fixtureHomeBetMoney +
                                event.fixture.fixtureDrawBetMoney +
                                event.fixture.fixtureAwayBetMoney

                              let homeRatio
                              if (totalBetMoeny === 0) {
                                homeRatio = 0
                              } else {
                                homeRatio = ((event.fixture.fixtureHomeBetMoney / totalBetMoeny) * 100).toFixed(2)
                              }

                              let awayRatio

                              if (totalBetMoeny === 0) {
                                awayRatio = 0
                              } else {
                                awayRatio = ((event.fixture.fixtureAwayBetMoney / totalBetMoeny) * 100).toFixed(2)
                              }

                              let drawRatio

                              if (totalBetMoeny === 0) {
                                drawRatio = 0
                              } else {
                                drawRatio = ((event.fixture.fixtureDrawBetMoney / totalBetMoeny) * 100).toFixed(2)
                              }

                              const mainMarket = event.mainMarkets?.[0] ?? []

                              const mainMarketBets = mainMarket?.betLines?.[0]?.bets ?? []

                              const { homeData, drawData, awayData } = mainMarketBets?.reduce(
                                (acc, bet) => {
                                  if (bet.betName === '1') acc.homeData = bet
                                  if (bet.betName === 'X') acc.drawData = bet
                                  if (bet.betName === '2') acc.awayData = bet
                                  return acc
                                },
                                { homeData: {}, drawData: {}, awayData: {} },
                              ) || { homeData: {}, drawData: {}, awayData: {} }

                              return (
                                <div className="betting_item">
                                  <div className="row row_match">
                                    <div className="col col_left">
                                      <div
                                        className="game_home_name"
                                        style={{
                                          display: 'flex',
                                          gap: '5px',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {event.fixture.homeImageUrl && (
                                          <img
                                            style={{ width: '30px', height: '30px' }}
                                            src={event.fixture.homeImageUrl}
                                            alt={event.fixture.homeName}
                                          />
                                        )}

                                        {event.fixture.homeName}
                                      </div>
                                    </div>
                                    <div className="col col_center">
                                      {homeRatio > 0 && <div className="game_home_score">{homeRatio}%</div>}
                                      {awayRatio > 0 && <div className="game_away_score">{awayRatio}%</div>}
                                      {drawRatio > 0 && <div className="game_draw_score">{drawRatio}%</div>}

                                      <div className="vs">VS</div>

                                      <div className="guagebar_home" style={{ width: `${homeRatio}%` }} />
                                      <div className="guagebar_away" style={{ width: `${awayRatio}%` }} />
                                      <div className="guagebar_draw" style={{ width: `${drawRatio}%` }} />
                                    </div>
                                    <div className="col col_right">
                                      <div
                                        className="game_away_name"
                                        style={{
                                          display: 'flex',
                                          gap: '5px',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {event.fixture.awayImageUrl && (
                                          <img
                                            style={{ width: '30px', height: '30px' }}
                                            src={event.fixture.awayImageUrl}
                                            alt={event.fixture.awayName}
                                          />
                                        )}
                                        {event.fixture.awayName}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="row row_moneyamount">
                                    <div className="col col_left">
                                      <div className="label">금액</div>
                                      <div className="amount">
                                        {event.fixture.fixtureHomeBetMoney?.toString().replace(commonReg2, ',')}원
                                      </div>
                                    </div>
                                    <div className="col col_center">
                                      <div className="label">금액</div>
                                      <div className="amount">
                                        {event.fixture.fixtureDrawBetMoney?.toString().replace(commonReg2, ',')}원
                                      </div>
                                    </div>
                                    <div className="col col_right">
                                      <div className="label">금액</div>
                                      <div className="amount">
                                        {event.fixture.fixtureAwayBetMoney?.toString().replace(commonReg2, ',')}원
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="row row_joincount">
                                    <div className="col col_left">
                                      <div className="label">참여인원</div>
                                      <div className="amount">
                                        {event.fixture.fixtureHomeBetCount?.toString().replace(commonReg2, ',')}명
                                      </div>
                                    </div>
                                    <div className="col col_center">
                                      <div className="label">참여인원</div>
                                      <div className="amount">
                                        {event.fixture.fixtureDrawBetCount?.toString().replace(commonReg2, ',')}명
                                      </div>
                                    </div>
                                    <div className="col col_right">
                                      <div className="label">참여인원</div>
                                      <div className="amount">
                                        {event.fixture.fixtureAwayBetCount?.toString().replace(commonReg2, ',')}명
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="row row_odds">
                                    <div
                                      className="col col_left activable cancelable"
                                      onClick={() => {
                                        if (Object.keys(homeData).length === 0) return
                                        if (homeData?.betStatusId !== 1) return

                                        onClickBetHandler(
                                          createBettingCartItem(
                                            event.fixture,
                                            {
                                              ...mainMarket,
                                              bets: mainMarket?.betLine?.bets ?? [],
                                            },
                                            homeData,
                                          ),
                                        )
                                      }}
                                    >
                                      {homeData?.betStatusId !== 1 && (
                                        <SportsEventContentItemOverlay>
                                          <SportsEventContentItemLockIcon />
                                        </SportsEventContentItemOverlay>
                                      )}
                                      <div className="label">홈팀 승리</div>{' '}
                                      <div className="odds">{homeData?.priceNum?.toFixed(2) ?? 0}</div>
                                    </div>
                                    <div
                                      className="col col_center activable cancelable"
                                      onClick={() => {
                                        if (Object.keys(drawData).length === 0) return
                                        if (Object.keys(drawData).length !== 0 && drawData?.betStatusId !== 1) return

                                        onClickBetHandler(
                                          createBettingCartItem(
                                            event.fixture,
                                            {
                                              ...mainMarket,
                                              bets: mainMarket?.betLine?.bets ?? [],
                                            },
                                            drawData,
                                          ),
                                        )
                                      }}
                                    >
                                      {Object.keys(drawData).length !== 0 && drawData?.betStatusId !== 1 && (
                                        <SportsEventContentItemOverlay>
                                          <SportsEventContentItemLockIcon />
                                        </SportsEventContentItemOverlay>
                                      )}
                                      <div className="label">무승부</div>{' '}
                                      <div className="odds">
                                        <font color="">{drawData?.priceNum?.toFixed(2) ?? 'VS'}</font>
                                      </div>
                                    </div>
                                    <div
                                      className="col col_right activable cancelable"
                                      onClick={() => {
                                        if (Object.keys(awayData).length === 0) return
                                        if (awayData?.betStatusId !== 1) return

                                        onClickBetHandler(
                                          createBettingCartItem(
                                            event.fixture,
                                            {
                                              ...mainMarket,
                                              bets: mainMarket?.betLine?.bets ?? [],
                                            },
                                            awayData,
                                          ),
                                        )
                                      }}
                                    >
                                      {awayData?.betStatusId !== 1 && (
                                        <SportsEventContentItemOverlay>
                                          <SportsEventContentItemLockIcon />
                                        </SportsEventContentItemOverlay>
                                      )}
                                      <div className="label">원정팀 승리</div>{' '}
                                      <div className="odds">{awayData?.priceNum?.toFixed(2) ?? 0}</div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </CustomSlider>
                        </div>
                      )
                    })}
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainPageSportsBalanceContent

const CustomSlider = styled(Slider)`
  position: relative;
`

const EventSportBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  padding: 3px 0 0 0;
  border: 1px solid ${KyochonMainBorder};
  border-left: none;
  border-right: none;
  border-top: none;

  img {
    width: 25px;
    height: 25px;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
`

const SportsEventContentItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsEventContentItemLockIcon = styled(FaLock)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
`

const HeaderTitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 5px 0;

  span {
    font-size: 20px;
    font-weight: 700;
  }
`
