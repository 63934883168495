import axios from '../base/axios'

// 서브 캐러샐 리스트 조회
export async function searchSubCarousels(params) {
  const response = await axios({
    url: '/tyson/member/v1/game/subcarousel',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 카지노 배팅내역 조회
export async function searchCasinoBettingHistory(params) {
  const response = await axios({
    url: '/tyson/member/v1/game/betting-history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 카지노 배팅내역 삭제
export async function deleteCasinoBettingHistory(body) {
  const response = await axios({
    url: '/tyson/member/v1/game/betting-history/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 서브 캐러샐 용 게임 접속 URL 조회
export async function searchSubCarouselGameUrl(params) {
  const response = await axios({
    url: '/tyson/member/v1/game/subcarousel/game-url',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// LIVE 벤더 리스트 조회
export async function searchLiveVendor(params) {
  const response = await axios({
    url: '/tyson/member/v1/game/live-vendors',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

//  SLOT 벤더 리스트 조회
export async function searchSlotVendor(params) {
  const response = await axios({
    url: '/tyson/member/v1/game/slot-vendors',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// SLOT 게임 리스트 조회
export async function searchGame(params) {
  const response = await axios({
    url: '/tyson/member/v1/game/slot-games',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 라이브 / 슬롯 게임 접속 URL 조회
export async function searchGameUrl(params) {
  const response = await axios({
    url: '/tyson/member/v1/game/game-url',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 카지노 게임 종료시
export async function sycnCasinoMoney() {
  const response = await axios({
    url: '/tyson/member/v1/game/sync-money',
    method: 'POST',
  }).then(res => {
    return res
  })
  return response
}

// CMD 홀덤 게임 접속
export async function searchHoldemGameUrl() {
  const response = await axios({
    url: '/tyson/member/v1/game/holdem/game-url',
    method: 'GET',
  }).then(res => {
    return res
  })
  return response
}

// CMD 홀덤 머니 회수
export async function withdrawHoldemMoney() {
  const response = await axios({
    url: '/tyson/member/v1/game/holdem/withdraw',
    method: 'POST',
  }).then(res => {
    return res
  })
  return response
}
