import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  cancelSportsBettingHistoryV2,
  deleteSportsBettingHistoryV2,
  searchSportsBettingHistoryV2,
} from '../../../api/game/sportsGameApi'
import BettingHistoryRadio from '../../../containers/BettingHistory/BettingHistoryRadio'
import CustomLoading from '../../../containers/CustomLoading'
import { fetchMemberInfo } from '../../../redux/memberInfoSlice'
import MobileCustomPagination from '../../../shared/components/MobileCustomPagination'
import { convertToKstByFormat } from '../../../utils/dateTime'
import {
  SportMarketTypeV2,
  SportsResultSettlementTypeEnumsV2,
  SportsResultStatusEnums,
} from '../../../utils/enums/SportsGame/SportsGameEnums'
import { HermesMainBorder, HermesMainText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'

const KyoChonMobileSportsBettingHistoryV2 = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [subMenuTitle, setSubMenuTitle] = useState('PREMATCH')

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
    subMenuTitle,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      ...prev,
      subMenuTitle,
      page: 0,
      size,
    }))
  }, [subMenuTitle])

  const [totalElements, setTotalElements] = useState(0)
  const [sportsBettingHistoryContent, setSportsBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(false)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchSportsBettingHistory = async params => {
    if (loading) return
    setLoading(true)
    await searchSportsBettingHistoryV2(params)
      .then(res => {
        setTotalElements(res.data.totalElements)
        setSportsBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchSportsBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  // 라이브트래커 노출여부
  const onClickLiveTrackerShowHandler = sportsDetailBettingLogKey => {
    setSportsBettingHistoryContent(prevContent =>
      prevContent.map(detailItem => {
        const updatedLogList = detailItem.sportsDetailBettingLogList.map(log => {
          if (log.sportsDetailBettingLogKey === sportsDetailBettingLogKey) {
            return {
              ...log,
              // isLiveTrackerShow가 없으면 true로, 있으면 현재 값의 반대로 설정
              isLiveTrackerShow: log.isLiveTrackerShow ? !log.isLiveTrackerShow : true,
            }
          }
          return log
        })

        return {
          ...detailItem,
          sportsDetailBettingLogList: updatedLogList,
        }
      }),
    )
  }

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const getMarketIconComponent = (marketName, oddName) => {
    switch (true) {
      case marketName.includes('승무패') || marketName.includes('승패'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
            alt=""
            style={{ width: '10px', height: '7px' }}
          />
        )
      case marketName.includes('핸디'):
        return (
          // <img src={`${process.env.PUBLIC_URL}/img/sports/icon_h.png`} alt="" style={{ width: '7px', height: '7px' }} />
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('언더'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('오버'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      default:
    }
  }

  const onClickBettingHistoryCancelHandler = item => {
    // 현재시간
    const currentTime = moment().tz('Asia/Seoul')

    // 취소 가능 시간
    const availableCancelTime = moment(item.availableCancelTime).tz('Asia/Seoul')

    if (currentTime >= availableCancelTime) {
      alert('해당 배팅의 취소가능 시간이 지나서 배팅 취소가 불가능합니다.')
      return
    }

    // 경기가 시작된 것이 하나라도 있는 지
    const findIdx = item.sportsDetailBettingLogList.findIndex(
      log => moment(log.bettingCancelTimeCriteriaBeforeStart).tz('Asia/Seoul') < currentTime,
    )

    if (findIdx !== -1) {
      // 이미 시작된 경기가 존재하는 상황
      alert('이미 시작된 경기가 있어, 배팅 취소가 불가능합니다.')
      return
    }

    setLoading(true)
    setLoadingInfo('배팅 취소 처리중입니다.')

    cancelSportsBettingHistoryV2({
      sportsBettingLogKey: item.sportsBettingLogKey,
      bettingAmount: item.bettingAmount,
      cancelTime: currentTime,
    })
      .then(res => {
        alert('해당 배팅건에 대해 취소 처리 되었습니다.')
        fetchSportsBettingHistory(sportsBettingHistoryParams)
        dispatch(fetchMemberInfo())
        setLoading(false)
        setLoadingInfo('')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'SPORTS-1017':
            alert(`${message}`)
            break
          case 'SPORTS-1043':
            alert('취소 가능 시간을 지나서 취소가 불가능합니다.')
            break
          case 'SPORTS-1044':
            alert('취소 가능 시간을 지나서 취소가 불가능합니다.')
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('해당 배팅건에 대해 취소 처리 실패하였습니다.')
        }

        setLoading(false)
        setLoadingInfo('')
      })
  }
  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteSportsBettingHistoryV2({
        sportsBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  const getBetBox = detail => {
    const pattern = /^-?\d+\.\d+ \(\d+-\d+\)$/
    const match = detail.baseLine?.match(pattern)

    // 핸디일때
    if (detail.marketFamilyType === 11 && match) {
      const innerPattern = /\((\d+)-(\d+)\)/
      const scoreMatch = detail.baseLine.match(innerPattern)

      const homeScore = Number.isNaN(Number(scoreMatch[1])) ? 0 : Number(scoreMatch[1])
      const awayScore = Number.isNaN(Number(scoreMatch[2])) ? 0 : Number(scoreMatch[2])

      // 공식 : 베이스라인 기준점 - 홈 스코어 + 어웨이 스코어
      let finalLine = detail.baseLineNum - homeScore + awayScore

      // 원정팀 선택시
      if (detail.betName === '2') {
        finalLine *= -1
      }

      return (
        <>
          {detail.betNameKr} {detail.line && <>({finalLine.toFixed(1) || '-'})</>}
        </>
      )
    }

    return (
      <>
        {detail.betNameKr} {detail.line && <>({detail.lineNum.toFixed(1) || '-'})</>}
      </>
    )
  }

  const getCalculatedHeight = sportId => {
    if (sportId === 6046) {
      // [축구] (404 / 800 * width) + 50 (142 - 92 통계 height )
      return 400
    }

    if (sportId === 48242) {
      // [농구] (404 / 800 * width) + 64 (156 - 92 통계 height)
      return 400
    }

    if (sportId === 54094) {
      // [테니스]
      return 400
    }

    if (sportId === 154830) {
      // [배구] (404 / 800 * width) + 50 (142 - 92 통계 height )
      return 355
    }

    if (sportId === 131506) {
      // [미식 축구]
      return 400
    }

    if (sportId === 154914) {
      // [야구] (404 / 800 * width) + 50 (142 - 92 통계 height )
      return 355
    }

    if (sportId === 35232) {
      // [아이스하키] (404 / 800 * width) + 50 (142 - 92 통계 height )
      return 355
    }
  }

  return (
    <>
      {(deleteLoading || loading) && <CustomLoading />}
      <ListWrap style={{ width: '100%' }}>
        <SubMenuItemListWrap>
          <SubMenuItemBox active={subMenuTitle === 'PREMATCH'} onClick={() => setSubMenuTitle('PREMATCH')}>
            프리매치
          </SubMenuItemBox>
          <SubMenuItemBox active={subMenuTitle === 'INPLAY'} onClick={() => setSubMenuTitle('INPLAY')}>
            인플레이
          </SubMenuItemBox>
        </SubMenuItemListWrap>
      </ListWrap>
      {sportsBettingHistoryContent.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          스포츠 배팅내역이 없습니다.
          {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
        </NoContentDiv>
      ) : (
        <>
          <div style={{ marginLeft: '10px', display: 'flex', gap: '5px' }}>
            <div
              style={{
                marginLeft: '5px',
                display: 'flex',
                gap: '5px',
                marginTop: '5px',
                width: '100%',
                justifyContent: 'right',
                paddingRight: '10px',
              }}
            >
              <button
                type="button"
                className="normal_button"
                style={{ height: '20px', width: '80px', color: '#000' }}
                onClick={() => {
                  setCheckRadio(
                    sportsBettingHistoryContent
                      .filter(value => value.sportsResultStatus !== 'ING')
                      .map(value => value.sportsBettingLogKey),
                  )
                }}
              >
                전체선택
              </button>
              <button
                type="button"
                style={{ height: '20px', width: '80px', color: '#000' }}
                className="normal_button"
                onClick={() => onDeleteHandler()}
              >
                삭제하기
              </button>
            </div>
          </div>
          {sportsBettingHistoryContent.map((item, index) => (
            <>
              <div className="hisinfo">
                <span style={{ float: 'right' }}>배팅시간 : {convertToKstByFormat(item.bettingAt)}</span>
              </div>

              <div className="choice_wrap2">
                {item.bonusFolderNum !== 0 && (
                  <div className="choice_wrap">
                    <div className="league">{SportMarketTypeV2[item.sportMarketType]} [보너스 배당]</div>
                    <div className="date">{convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}</div>
                    <div className="h-team" style={{ width: '70%' }}>
                      <div className="default">
                        <b style={{ paddingRight: '5px' }}>&nbsp;보너스 배당</b>
                      </div>
                    </div>
                    <div className="center" style={{ width: '20%' }}>
                      <div
                        className="selected"
                        style={{
                          height: '38px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <b>[{item.bonusFolderNum}폴더 이상]</b>
                      </div>
                    </div>
                    <div className="a-team" style={{ width: '10%' }}>
                      <div className="default">
                        &nbsp;<b>{item.bonusFolderPer}</b>
                      </div>
                    </div>
                    <div className="result_wrap">
                      <div className="icon" />
                      <div className="value">
                        결과 :{' '}
                        <b>
                          <BettingHistoryContentBoxFooterInfo bettingResult="WINNER">
                            적중
                          </BettingHistoryContentBoxFooterInfo>
                        </b>
                        {/* &nbsp; */}
                        {/* <span style={{ color: '#666666' }}>│</span>
                          &nbsp;스코어 : 0 vs 0&nbsp;<span style={{ color: '#666666' }}>│</span>
                          &nbsp;당첨 : <span style={{ color: '#fff' }}>진행</span> */}
                      </div>
                    </div>
                  </div>
                )}

                {item.sportsDetailBettingLogList.map(detail => (
                  <div className="choice_wrap">
                    <div className="league">
                      {item.sportsResultStatus !== 'ING' && (
                        <span style={{ float: 'left', marginRight: '5px' }}>
                          <a>
                            <BettingHistoryRadio
                              sportsBettingLogKey={item.sportsBettingLogKey}
                              sportsResultStatus={item.sportsResultStatus}
                              checkRadio={checkRadio}
                              setCheckRadio={setCheckRadio}
                            />
                          </a>
                        </span>
                      )}
                      {detail.leagueName || '-'} &nbsp;[{detail.marketName}]
                    </div>
                    <div className="date">{convertToKstByFormat(detail.startDate, 'MM-DD HH:mm')}</div>
                    {/* <table border="0" cellSpacing="0" cellPadding="0" style={{ width: '100%', marginBottom: '5px' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%', textAlign: 'left' }}>
                            <span>{detail.homeName}</span>
                          </td>
                          <td style={{ width: '50%', textAlign: 'right' }}>
                            <span>{detail.awayName}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table> */}
                    <div className="h-team" style={{ width: '62%' }}>
                      <div className="default">
                        <b style={{ paddingRight: '5px' }}>
                          &nbsp;{detail.homeName} VS {detail.awayName}
                        </b>
                      </div>
                    </div>
                    <div className="center" style={{ width: '20%' }}>
                      <div
                        className="selected"
                        style={{
                          height: '38px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <b>{getBetBox(detail)}</b>
                      </div>
                    </div>
                    <div className="a-team" style={{ width: '8%' }}>
                      <div className="default">
                        &nbsp;<b>{detail.priceNum || '-'}</b>
                      </div>
                    </div>
                    <div className="a-team" style={{ width: '10%', borderLeft: 'none' }}>
                      <div className="default" style={{ borderLeft: 'none' }}>
                        {detail?.liveTrackerUrl ? (
                          <BtnLiveTracker
                            onClick={e => {
                              e.preventDefault()

                              onClickLiveTrackerShowHandler(detail.sportsDetailBettingLogKey)
                            }}
                          >
                            <i className="fas fa-eye" aria-hidden="true" />
                          </BtnLiveTracker>
                        ) : (
                          <span>-</span>
                        )}
                      </div>
                    </div>
                    <div className="result_wrap">
                      <div className="icon" />
                      <div className="value">
                        결과 :{' '}
                        <b>
                          <BettingHistoryContentBoxFooterInfo bettingResult={detail.betSettlementType}>
                            {SportsResultSettlementTypeEnumsV2[detail.betSettlementType]}
                          </BettingHistoryContentBoxFooterInfo>
                        </b>
                      </div>
                      {detail.isLiveTrackerShow && (
                        <div className="value">
                          <LiveTrackerIframe
                            src={detail.liveTrackerUrl}
                            scrolling="no"
                            style={{ height: `${getCalculatedHeight(detail.sportId)}px` }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="result_info2">
                  <tbody>
                    <tr>
                      <td>배팅금액</td>
                      <td className="result_info2_td">{item.bettingAmount.toString().replace(commonReg2, ',')}원</td>
                      <td>결과</td>
                      <td className="result_info2_td">
                        <font>
                          <b>
                            <BettingHistoryContentBoxFooterInfo2 bettingResult={item.sportsResultStatus}>
                              {SportsResultStatusEnums[item.sportsResultStatus]}
                            </BettingHistoryContentBoxFooterInfo2>
                          </b>
                        </font>
                      </td>
                    </tr>
                    <tr>
                      <td>예상배당률</td>
                      <td className="result_info2_td">
                        {(() => {
                          const {
                            realReduceBettingPerForSingleFolder,
                            realReduceBettingPerForCombination,
                            totalOddValue,
                            originTotalOddValue,
                          } = item
                          const reduceBettingValue =
                            realReduceBettingPerForSingleFolder ?? realReduceBettingPerForCombination

                          if (reduceBettingValue === null) {
                            return <span>{totalOddValue}</span>
                          }

                          return (
                            <>
                              {Number(reduceBettingValue) < 0 ? (
                                <span>
                                  ({totalOddValue} / {originTotalOddValue} {reduceBettingValue})
                                </span>
                              ) : (
                                <span>
                                  ({totalOddValue} / {originTotalOddValue} + {reduceBettingValue})
                                </span>
                              )}
                            </>
                          )
                        })()}
                      </td>
                      <td>예상당첨금</td>
                      <td className="result_info2_td">
                        {item.bettingExpectedAmount.toString().replace(commonReg2, ',')}원
                      </td>
                    </tr>
                    <tr>
                      <td>비고</td>
                      <td className="result_info2_td">
                        <BettingHistoryContentItemBox sportMarketType={item.sportMarketType}>
                          {SportMarketTypeV2[item.sportMarketType]}
                        </BettingHistoryContentItemBox>
                      </td>
                      <td>당첨금액</td>
                      <td className="result_info2_td">
                        {item.bettingResultAmount.toString().replace(commonReg2, ',')}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {item.sportsResultStatus === 'ING' && (
                  <>
                    {item.allowBettingCancel && (
                      <>
                        <div className="result_btn">
                          <span style={{ float: 'right' }}>
                            <a
                              onClick={() => {
                                onClickBettingHistoryCancelHandler(item)
                              }}
                            >
                              <font className="bt_type3" style={{ padding: '7px', width: '40px' }}>
                                <b>취소</b>
                              </font>
                            </a>
                          </span>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          ))}
          <>
            <MobileCustomPagination
              currentPage={page}
              size={size}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </>
        </>
      )}
    </>
  )
}

export default KyoChonMobileSportsBettingHistoryV2

const BtnLiveTracker = styled.span`
  border: 1px solid #000;
  padding: 1px 5px;
  cursor: pointer;

  background: linear-gradient(to bottom, rgb(183 47 47) 1%, rgb(119 14 14) 100%);
  text-shadow: 0 0px 0px rgb(0 0 0 / 80%);
  color: #fff;
`

const LiveTrackerIframe = styled.iframe`
  width: 97%;
  margin: 0 auto;
`

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 100px;
    height: auto;
  }
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const ListWrap = styled.div`
  display: flex;
  text-align: right;
  align-items: right;
  justify-content: right;
  padding-left: 10px;
  gap: 10px;
`

const ResultBox = styled.div`
  position: absolute;
  display: block;
  padding: 0px;
  text-align: center;
  z-index: 150;
  width: 60px !important;
  height: 14px !important;
  line-height: 14px;
  bottom: 1px;
  left: 50%;
  margin-left: -56px;
  margin-bottom: -1px;
  span {
    border-radius: 36px 36px 0px 0px;
    width: 100%;
    height: 100%;
    display: block;
    font-weight: bold;
    background: #fff;
    color: ${props => getColor(props.bettingResult)};
    border: 1px solid #d5d4d3;
    border-bottom: 0px;
  }
`
const BettingHistoryTopHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: black;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-top: solid 1px gray;
  height: 25px;
  align-items: center;
  padding: 0px 5px;
`

const BettingHistoryHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
  height: 25px;
  align-items: center;
`

const BettingHistoryTh = styled.th`
  border: none !important;
  width: ${props => props.width && `${props.width}%`};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;

  span {
    padding-left: 2px;
    width: 100%;
    font-weight: 300;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    img {
      width: 15px;
      height: 15px;
      margin-right: 2px;
      margin-bottom: -3px;
    }
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 600;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
  th {
    background: rgba(48, 48, 48, 1);
    padding: 2px 2px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead``

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getFonwWeight = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '900'
    case bettingResult === 'LOSE':
      return '900'
    case bettingResult === 'DRAW':
      return '900'
    default:
      return '300'
  }
}

const getColor2 = settlementType => {
  switch (true) {
    case settlementType === 'WINNER':
      return 'blue'
    case settlementType === 'LOSER':
      return 'red'
    case settlementType === 'REFUND':
      return 'grey'
    case settlementType === 'NOT_SETTLED':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'black'
    default:
      return '#000'
  }
}

const BettingHistoryContentBoxFooterInfo = styled.span`
  font-weight: 500;
  color: ${props => getColor2(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo1 = styled.span`
  width: 45%;
  color: ${props => getColor2(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo2 = styled.span`
  width: 45%;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo3 = styled.span`
  width: 24%;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;

  span {
  }
`

const BettingHistoryBottomContentBox = styled.div`
  padding-left: 5px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 22px;
  span {
  }
`

const BettingHistoryBottomTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  // border-bottom: solid 1px gray;
  &:last-child {
    border-bottom: solid 1px gray;
  }
`

const getColor3 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#f7f754'
    case 'SPECIAL':
      return '#84f567'
    case 'OVERSEAS':
      return '#ee73f5'
    case 'LIVE':
      return '#faba73'
    default:
      return '#000'
  }
}

const BettingHistoryContentItemBox = styled.span`
  width: 100%;
  color: ${props => getColor3(props.sportMarketType)};
`

const BettingCancelBox = styled.div`
  width: 100%;
`

const BettingCancelButton = styled.button`
  width: 20%;
  position: relative;
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: auto;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${HermesMainBorder};
  padding: 2px;
  background: #262626;
`

const BettingHistoryLongContentItemDetailInfo1 = styled.div`
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 2px 1px 2px;
  float: left;

  span {
    font-weight: 300;
  }
`

const BettingHistoryContentItemDetailInfo1 = styled.div`
  width: 60%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 2px 1px 2px;
  float: left;

  span {
    font-weight: 300;
  }
`

const BettingHistoryContentItemDetailInfo2 = styled.div`
  width: 39%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  cursor: pointer;
  padding: 0 5px;
  font-weight: 600;

  span {
    font-size: 11px;
  }
`

const SubMenuItemBox = styled.div`
  padding: 0px;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 24px;
  width: 80px;
  height: 23px;
  border: solid 1px #dedede;
  ${props =>
    props.active &&
    `
    border: solid 1px  #000 !important;
    color: #000 !important;
    `}
`

const SubMenuItemListWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  margin-top: 0px;
  padding-bottom: 5px;
  gap: 10px;
  // border: solid 2px red;
`
