import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { HermesMainHover2 } from '../../../utils/palette'
import { CommonBetBox } from '../../../shared/components/SportCommonBetBox'
import { SportMarketType } from '../../../utils/enums/SportsGame/SportsGameEnums'
import { addBettingCartItem } from '../../../redux/sportsBettingSlice'
import { setSelectedFixtureMarketCollapse } from '../../../redux/sportsLiveInfoSlice'

const ContentMarket = React.memo(({ fixture, market, onClickMarketCollapseHandler, onClickAddBettingCartHandler }) => {
  return (
    <MarketWrap>
      <MarketNameBox
        onClick={() => {
          onClickMarketCollapseHandler(market.marketId)
        }}
      >
        <span>{market.marketName}</span>
        {market.marketCollapse ? <MarketCollapseArrowUpIcon /> : <MarketCollapseArrowDownIcon />}
      </MarketNameBox>
      {market.marketCollapse && (
        <MarketBetListBox>
          <CommonBetBox
            fixtureInfo={fixture}
            marketInfo={market}
            onClickAddBettingCartHandler={onClickAddBettingCartHandler}
          />
        </MarketBetListBox>
      )}
    </MarketWrap>
  )
})

const SportsLiveContentMarket = ({ selectedFixtureInfo }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedFixture, setSelectedFixture] = useState({})

  const { liveFixtures } = useSelector(state => {
    const { sportsLiveInfo } = state

    return {
      liveFixtures: sportsLiveInfo.liveFixtures,
    }
  })

  useEffect(() => {
    if (!selectedFixtureInfo) {
      setSelectedFixture({})
      return
    }

    if (Object.keys(selectedFixtureInfo).length === 0) return

    const fixture = liveFixtures[selectedFixtureInfo.eventKey]?.fixtures.find(
      fixtureItem => fixtureItem.fixtureId === selectedFixtureInfo.fixtureId,
    )

    if (!fixture) {
      setSelectedFixture({})
      return
    }

    setSelectedFixture(fixture)
  }, [liveFixtures, selectedFixtureInfo])

  const onClickMarketCollapseHandler = useCallback(
    marketId => {
      if (!selectedFixture) return

      dispatch(
        setSelectedFixtureMarketCollapse({
          selectedFixture,
          marketId,
        }),
      )
    },
    [selectedFixture, dispatch],
  )

  // 배팅 카트 담기
  const onClickAddBettingCartHandler = useCallback(
    bettingCartItem => {
      dispatch(
        addBettingCartItem({
          ...bettingCartItem,
          sportMarketType: SportMarketType.LIVE,
        }),
      )
    },
    [dispatch],
  )

  const sportsContentMarketWrapRef = useRef(null)

  const [liveTrackerIframeStyle, setLiveTrackerIframeStyle] = useState({
    width: '470px',
    height: '460px',
  })

  const getLiveTrackerIframeSize = sportId => {
    if (sportId === 6046) {
      // [축구] (404 / 800 * width) + 142 (142 - 92 통계 height )
      setLiveTrackerIframeStyle({
        minWidth: '320px',
        width: '470px',
        height: '460px',
      })
      return
    }

    if (sportId === 48242) {
      // [농구] (404 / 800 * width) + 156 (156 - 92 통계 height)
      setLiveTrackerIframeStyle({ minWidth: '320px', width: '470px', height: '460px' })
      return
    }

    if (sportId === 54094) {
      // [테니스] (404 / 800 * width) + 142
      setLiveTrackerIframeStyle({ width: '470px', height: '460px' })
      return
    }

    if (sportId === 154830) {
      // [배구] (404 / 800 * width) + 142 (142 - 92 통계 height )
      setLiveTrackerIframeStyle({ width: '470px', height: '460px' })
      return
    }

    if (sportId === 131506) {
      // [미식축구] (404 / 800 * width) + 142 (142 - 92 통계 height )
      setLiveTrackerIframeStyle({
        minWidth: '320px',
        width: '470px',
        height: '420px',
      })
      return
    }

    if (sportId === 154914) {
      // [야구] (404 / 800 * width) + 142 (142 - 92 통계 height )
      setLiveTrackerIframeStyle({ width: 470, height: 380 })
      return
    }

    if (sportId === 35232) {
      // [아이스하키] (404 / 800 * width) + 142 (142 - 92 통계 height )
      setLiveTrackerIframeStyle({ width: 470, height: 380 })
    }
  }

  useEffect(() => {
    if (Object.keys(selectedFixture).length === 0) return

    if (sportsContentMarketWrapRef.current) {
      sportsContentMarketWrapRef.current.scrollTop = 0
    }

    getLiveTrackerIframeSize(selectedFixture.fixture.sportId)
  }, [selectedFixture?.fixtureId])

  return (
    <SportsContentMarketWrap>
      {Object.keys(selectedFixture || {}).length === 0 ? (
        <DefaultWrap>
          {/* <img src={`${process.env.PUBLIC_URL}/img/logo/logo.png`} alt="" />
          <DefaultWrapText
            onClick={() => {
              navigate('/customcenter/betting-rule')
            }}
          >
            이용관련 규정은 &nbsp;{' '}
            <span style={{ color: '#000', fontWeight: '500', width: 'max-content' }}> 이용규정</span>란에 안내되어
            있으니, 필히 숙지 후 이용 바랍니다.
          </DefaultWrapText> */}
          <img
            style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
            onClick={() => {
              navigate('/customcenter/betting-rule')
            }}
            src={`${process.env.PUBLIC_URL}/img/main/live_rule.jpg`}
            alt=""
          />
        </DefaultWrap>
      ) : (
        <>
          <HeaderBoxWrap>
            <HeaderLeagueBox>
              {selectedFixture?.fixture.leagueImageUrl && (
                <img src={selectedFixture?.fixture.leagueImageUrl} alt="" style={{ marginRight: '10px' }} />
              )}
              <span>{selectedFixture?.fixture.leagueName}</span>
            </HeaderLeagueBox>

            {selectedFixture?.fixture?.liveTrackerUrl ? (
              <HeaderBox>
                <LiveTrackerIframe
                  src={selectedFixture?.fixture?.liveTrackerUrl}
                  scrolling="no"
                  style={liveTrackerIframeStyle}
                />
              </HeaderBox>
            ) : (
              <HeaderBox>
                <HeaderImg
                  src={`${process.env.PUBLIC_URL}/img/sports/sports-bg/sports-bg-${selectedFixture?.fixture.sportId}.png`}
                  alt=""
                />
                <HeaderTextBox>
                  <HeaderTeamBox style={{ paddingRight: '8px' }}>
                    {selectedFixture?.fixture.homeImageUrl && (
                      <HeaderTeamImage
                        src={selectedFixture?.fixture.homeImageUrl}
                        alt=""
                        style={{ float: 'right', paddingRight: '10px', marginLeft: '16px' }}
                      />
                    )}
                    <HeaderTeamText style={{ float: 'right', textAlign: 'right' }}>
                      {selectedFixture?.fixture.homeName}
                    </HeaderTeamText>
                  </HeaderTeamBox>
                  <span style={{ color: '#fff', fontSize: '16px', fontWeight: '700' }}>: </span>
                  <HeaderTeamBox style={{ paddingLeft: '8px' }}>
                    {selectedFixture?.fixture.awayImageUrl && (
                      <HeaderTeamImage
                        src={selectedFixture?.fixture.awayImageUrl}
                        alt=""
                        style={{ float: 'left', paddingLeft: '10px', marginRight: '16px' }}
                      />
                    )}
                    <HeaderTeamText style={{ float: 'left', textAlign: 'left' }}>
                      {selectedFixture?.fixture.awayName}
                    </HeaderTeamText>
                  </HeaderTeamBox>
                </HeaderTextBox>
              </HeaderBox>
            )}
          </HeaderBoxWrap>
          <ContentWrap ref={sportsContentMarketWrapRef}>
            {selectedFixture?.markets.map(market => {
              // market.betLines가 없거나 비어 있으면 아무 것도 렌더링하지 않음
              if (!market.usedMarket || !market.marketVisible) return null

              const filterBetLines = market.betLines.filter(betLine => {
                // 모든 bets가 'SUSPENDED'인 경우를 체크합니다.
                const allSuspended = betLine.bets.every(bet => bet.betStatus === 'SUSPENDED')

                if (allSuspended) return false

                // 각 betLine의 bets 배열을 검사하여 모든 조건을 충족하는지 확인합니다.
                return betLine.bets.every(bet => {
                  // showStatus가 true여야 하고, betStatus가 'OPEN' 또는 'SUSPEND'여야 합니다.
                  return bet.showStatus && (bet.betStatus === 'OPEN' || bet.betStatus === 'SUSPENDED')
                })
              })

              if (filterBetLines.length === 0) return null

              const customMarket = {
                ...market,
                betLines: filterBetLines,
              }

              return (
                <ContentMarket
                  key={`${market.marketId}-mm`}
                  fixture={{
                    ...selectedFixture?.fixture,
                    fixtureId: selectedFixture?.fixtureId,
                  }}
                  market={customMarket}
                  onClickMarketCollapseHandler={onClickMarketCollapseHandler}
                  onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                />
              )
            })}
          </ContentWrap>
        </>
      )}
    </SportsContentMarketWrap>
  )
}

export default SportsLiveContentMarket

const LiveTrackerIframe = styled.iframe`
  width: 470px;
  margin: 0 auto;
`

const SportsMarketBox = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 167px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 1px;
  }
`

const SportsContentMarketWrap = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
  overflow: hidden;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`

const DefaultWrap = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  overflow-y: scroll;
  img {
    width: 60%;
    height: auto;
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1 !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: black !important; /* 스크롤바 색상 */
  }
`

const DefaultWrapText = styled.div`
  display: flex;
  // align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 13px;
  color: #878e99;
  margin-top: 20px;
  cursor: pointer;
`

const HeaderBoxWrap = styled.div`
  width: 100%;
  min-height: 173px;
  display: flex;
  flex-direction: column;

  margin-bottom: 8px;
`

const HeaderLeagueBox = styled.div`
  display: flex;
  align-items: center;
  background: #eee;
  border-radius: 6px 6px 0px 0px;
  padding: 10px 16px;

  font-size: 14px;
  color: #000;

  img {
    width: 23px;
  }
`

const HeaderBox = styled.div`
  width: 100%;
  position: relative;
`

const HeaderImg = styled.img`
  width: 100%;
  height: 130px;
  vertical-align: middle;
  border: 0;
`

const HeaderTextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  height: 90%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(18px);
  border-radius: 8px;
`

const HeaderTeamBox = styled.div`
  width: 50%;
  float: left;
  display: block;
  color: #fff;
  font-size: 14px;
`
const HeaderTeamText = styled.div`
  width: calc(100% - 60px);
  font-weight: 700;
  font-size: 16px;
`

const HeaderTeamImage = styled.img`
  overflow-clip-margin: content-box;
  overflow: clip;
  border: 0;
  width: 40px;
  vertical-align: middle;
`

/**
 * 아래부터 Content 부분
 */

const ContentWrap = styled.div`
  width: 100%;
  // height: 100%;

  height: calc(100% - 190px);

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`

const MarketWrap = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

const MarketNameBox = styled.div`
  height: 100%;
  padding: 4px 5px 4px 5px;
  background: #eee;
  border-radius: 6px 6px 0 0;

  display: flex;
  align-items: center;

  font-size: 13px;
  font-weight: 500;
  color: #000;

  &:hover {
    cursor: pointer;
  }
`

const MarketCollapseArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: ${HermesMainHover2};
  width: 22px;
  height: auto;
  cursor: pointer;
  margin-left: auto;
`

const MarketCollapseArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: ${HermesMainHover2};
  width: 22px;
  height: auto;
  cursor: pointer;
  margin-left: auto;
`

const MarketBetListBox = styled.div`
  width: 100%;
  background: #fff;
`
