import { convertToKstDateTime } from '@/utils/dateTime'
import { commonReg2 } from '@/utils/validate/commonValidate'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MoneyExchangeListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 27,
    },
    {
      Header: '송신',
      accessor: 'sender',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 80,
    },
    {
      Header: '수신',
      accessor: 'receiver',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 80,
    },
    {
      Header: '변경액',
      accessor: 'changeAmount',
      disableGlobalFilter: true,
      width: 57,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changeAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 80,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MoneyExchangeListData
