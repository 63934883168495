import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import { FaLock } from 'react-icons/fa'
import { searchSportsFixtureBalanceList } from '../../../api/game/sportsGameApi'
import { addBettingCartItem } from '../../../redux/sportsBettingSlice'
import { SportMarketType } from '../../../utils/enums/SportsGame/SportsGameEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import CustomLoading from '../../CustomLoading'
import { KyochonMainBorder } from '../../../utils/palette'
import CustomPagination from '../../../shared/components/CustomPagination'
import {
  fetchFixtureBalanceListAction,
  setBalanceLoading,
  setBalancePageNum,
  setSelectedBalanceSportId,
} from '../../../redux/sportsDomesticInfoSlice'

const createBettingCartItem = (fixture, market, selectedBetInfo) => {
  // betLines 를 제거하기 위함
  const { betLines, ...rest } = market

  return {
    ...fixture,
    ...rest,
    selectedBetInfo,
  }
}

const SportsBalanceContent = () => {
  const dispatch = useDispatch()

  const {
    selectedBalanceSportId,
    domesticCategory,
    balanceParams,
    balanceLoading,
    balanceEvents,
    balanceTotalElements,
  } = useSelector(state => {
    const { sportsDomesticInfo } = state

    return {
      selectedBalanceSportId: sportsDomesticInfo.selectedBalanceSportId,
      domesticCategory: sportsDomesticInfo.domesticCategory,
      balanceParams: sportsDomesticInfo.balanceParams,
      balanceLoading: sportsDomesticInfo.balanceLoading,
      balanceEvents: sportsDomesticInfo.balanceEvents,
      balanceTotalElements: sportsDomesticInfo.balanceTotalElements,
    }
  })

  const selectedSportIdHandler = sportId => {
    dispatch(setSelectedBalanceSportId(sportId))
  }

  const fetchSportsFixtureBalanceList = async () => {
    dispatch(fetchFixtureBalanceListAction())
  }

  useEffect(() => {
    dispatch(setBalanceLoading(true))

    fetchSportsFixtureBalanceList()

    const intervalId = setInterval(fetchSportsFixtureBalanceList, 10000)

    return () => clearInterval(intervalId)
  }, [dispatch, balanceParams])

  // 메인 마켓 클릭시 핸들러
  const onClickBetHandler = bettingCartItem => {
    dispatch(
      addBettingCartItem({
        ...bettingCartItem,
        sportMarketType: SportMarketType.DOMESTIC,
      }),
    )
  }

  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    if (!balanceParams) return
    setTotalPages(Math.ceil(balanceTotalElements / balanceParams.size))
  }, [balanceTotalElements, balanceParams])

  const onPageChange = pageNumber => {
    dispatch(setBalancePageNum(pageNumber))

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {balanceLoading && <CustomLoading />}
      <div id="sports_balance_wrap">
        <div className="flexbox">
          <div className="centerbox">
            <section id="sports_league_list">
              <div className="swipebox">
                {domesticCategory.map(sport => {
                  return (
                    <div
                      className={
                        sport.sportId === selectedBalanceSportId ? 'ca_item activable active' : 'ca_item activable'
                      }
                      data-active-group="main_livesports_league_type"
                      onClick={() => {
                        selectedSportIdHandler(sport.sportId)
                      }}
                    >
                      <img src={`${process.env.PUBLIC_URL}/img/sports/sportsType/side-${sport.sportId}.png`} alt="" />
                      <span data-value="">{sport.sportName}</span>
                    </div>
                  )
                })}
              </div>
            </section>

            <section id="sports_balance_betting_list">
              <div id="board_list">
                {balanceEvents.length === 0 ? (
                  <div className="no_live_box" style={{ width: '100%', textAlign: 'center', lineHeight: '315px' }}>
                    <strong style={{ color: '#0062df', fontSize: '24px' }}>진행중인 경기가 없습니다.</strong>
                  </div>
                ) : (
                  <>
                    {balanceEvents.map(event => {
                      const totalBetMoeny =
                        event.fixture.fixtureHomeBetMoney +
                        event.fixture.fixtureDrawBetMoney +
                        event.fixture.fixtureAwayBetMoney

                      let homeRatio
                      if (totalBetMoeny === 0) {
                        homeRatio = 0
                      } else {
                        homeRatio = ((event.fixture.fixtureHomeBetMoney / totalBetMoeny) * 100).toFixed(2)
                      }

                      let awayRatio

                      if (totalBetMoeny === 0) {
                        awayRatio = 0
                      } else {
                        awayRatio = ((event.fixture.fixtureAwayBetMoney / totalBetMoeny) * 100).toFixed(2)
                      }

                      let drawRatio

                      if (totalBetMoeny === 0) {
                        drawRatio = 0
                      } else {
                        drawRatio = ((event.fixture.fixtureDrawBetMoney / totalBetMoeny) * 100).toFixed(2)
                      }

                      const mainMarket = event.mainMarkets?.[0] ?? []

                      const mainMarketBets = mainMarket?.betLines?.[0]?.bets ?? []

                      const { homeData, drawData, awayData } = mainMarketBets?.reduce(
                        (acc, bet) => {
                          if (bet.betName === '1') acc.homeData = bet
                          if (bet.betName === 'X') acc.drawData = bet
                          if (bet.betName === '2') acc.awayData = bet
                          return acc
                        },
                        { homeData: {}, drawData: {}, awayData: {} },
                      ) || { homeData: {}, drawData: {}, awayData: {} }

                      return (
                        <div className="betting_item">
                          <div className="row row_match">
                            <div className="col col_left">
                              <div
                                className="game_home_name"
                                style={{
                                  display: 'flex',
                                  gap: '5px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {event.fixture.homeImageUrl && (
                                  <img
                                    style={{ width: '30px', height: '30px' }}
                                    src={event.fixture.homeImageUrl}
                                    alt={event.fixture.homeName}
                                  />
                                )}

                                {event.fixture.homeName}
                              </div>
                            </div>
                            <div className="col col_center">
                              {homeRatio > 0 && <div className="game_home_score">{homeRatio}%</div>}
                              {awayRatio > 0 && <div className="game_away_score">{awayRatio}%</div>}
                              {drawRatio > 0 && <div className="game_draw_score">{drawRatio}%</div>}

                              <div className="vs">VS</div>

                              <div className="guagebar_home" style={{ width: `${homeRatio}%` }} />
                              <div className="guagebar_away" style={{ width: `${awayRatio}%` }} />
                              <div className="guagebar_draw" style={{ width: `${drawRatio}%` }} />
                            </div>
                            <div className="col col_right">
                              <div
                                className="game_away_name"
                                style={{
                                  display: 'flex',
                                  gap: '5px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {event.fixture.awayImageUrl && (
                                  <img
                                    style={{ width: '30px', height: '30px' }}
                                    src={event.fixture.awayImageUrl}
                                    alt={event.fixture.awayName}
                                  />
                                )}
                                {event.fixture.awayName}
                              </div>
                            </div>
                          </div>
                          {/* <div className="row row_moneyamount">
                            <div className="col col_left">
                              <div className="label">금액</div>
                              <div className="amount">
                                {event.fixture.fixtureHomeBetMoney?.toString().replace(commonReg2, ',')}원
                              </div>
                            </div>
                            <div className="col col_center">
                              <div className="label">금액</div>
                              <div className="amount">
                                {event.fixture.fixtureDrawBetMoney?.toString().replace(commonReg2, ',')}원
                              </div>
                            </div>
                            <div className="col col_right">
                              <div className="label">금액</div>
                              <div className="amount">
                                {event.fixture.fixtureAwayBetMoney?.toString().replace(commonReg2, ',')}원
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="row row_joincount">
                            <div className="col col_left">
                              <div className="label">참여인원</div>
                              <div className="amount">
                                {event.fixture.fixtureHomeBetCount?.toString().replace(commonReg2, ',')}명
                              </div>
                            </div>
                            <div className="col col_center">
                              <div className="label">참여인원</div>
                              <div className="amount">
                                {event.fixture.fixtureDrawBetCount?.toString().replace(commonReg2, ',')}명
                              </div>
                            </div>
                            <div className="col col_right">
                              <div className="label">참여인원</div>
                              <div className="amount">
                                {event.fixture.fixtureAwayBetCount?.toString().replace(commonReg2, ',')}명
                              </div>
                            </div>
                          </div> */}
                          <div className="row row_odds">
                            <div
                              className={
                                homeData.isActive
                                  ? 'col col_left activable cancelable on active'
                                  : 'col col_left activable cancelable'
                              }
                              onClick={() => {
                                if (Object.keys(homeData).length === 0) return
                                if (homeData?.betStatusId !== 1) return

                                onClickBetHandler(
                                  createBettingCartItem(
                                    event.fixture,
                                    {
                                      ...mainMarket,
                                      bets: mainMarket?.betLine?.bets ?? [],
                                    },
                                    homeData,
                                  ),
                                )
                              }}
                            >
                              {homeData?.betStatusId !== 1 && (
                                <SportsEventContentItemOverlay>
                                  <SportsEventContentItemLockIcon />
                                </SportsEventContentItemOverlay>
                              )}
                              <div className="label">홈팀 승리</div>{' '}
                              <div className="odds">{homeData?.priceNum?.toFixed(2) ?? 0}</div>
                            </div>
                            <div
                              className={
                                drawData.isActive
                                  ? 'col col_center activable cancelable on active'
                                  : 'col col_center activable cancelable'
                              }
                              onClick={() => {
                                if (Object.keys(drawData).length === 0) return
                                if (Object.keys(drawData).length !== 0 && drawData?.betStatusId !== 1) return

                                onClickBetHandler(
                                  createBettingCartItem(
                                    event.fixture,
                                    {
                                      ...mainMarket,
                                      bets: mainMarket?.betLine?.bets ?? [],
                                    },
                                    drawData,
                                  ),
                                )
                              }}
                            >
                              {Object.keys(drawData).length !== 0 && drawData?.betStatusId !== 1 && (
                                <SportsEventContentItemOverlay>
                                  <SportsEventContentItemLockIcon />
                                </SportsEventContentItemOverlay>
                              )}
                              <div className="label">무승부</div>{' '}
                              <div className="odds">
                                <font color="">{drawData?.priceNum?.toFixed(2) ?? 'VS'}</font>
                              </div>
                            </div>
                            <div
                              className={
                                awayData.isActive
                                  ? 'col col_right activable cancelable on active'
                                  : 'col col_right activable cancelable'
                              }
                              onClick={() => {
                                if (Object.keys(awayData).length === 0) return
                                if (awayData?.betStatusId !== 1) return

                                onClickBetHandler(
                                  createBettingCartItem(
                                    event.fixture,
                                    {
                                      ...mainMarket,
                                      bets: mainMarket?.betLine?.bets ?? [],
                                    },
                                    awayData,
                                  ),
                                )
                              }}
                            >
                              {awayData?.betStatusId !== 1 && (
                                <SportsEventContentItemOverlay>
                                  <SportsEventContentItemLockIcon />
                                </SportsEventContentItemOverlay>
                              )}
                              <div className="label">원정팀 승리</div>{' '}
                              <div className="odds">{awayData?.priceNum?.toFixed(2) ?? 0}</div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
              </div>

              {balanceTotalElements > 0 && (
                <div style={{ marginTop: '20px' }}>
                  <CustomPagination
                    currentPage={balanceParams.page}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                </div>
              )}
            </section>
          </div>
          {/* <div className="rightbox"> 오른쪽 </div> */}
        </div>
      </div>
    </>
  )
}

export default SportsBalanceContent

const CustomSlider = styled(Slider)`
  position: relative;
`

const EventSportBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  padding: 3px 0 0 0;
  border: 1px solid ${KyochonMainBorder};
  border-left: none;
  border-right: none;
  border-top: none;

  img {
    width: 25px;
    height: 25px;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
`

const SportsEventContentItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsEventContentItemLockIcon = styled(FaLock)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
`
