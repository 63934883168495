import theme from 'styled-theming'
import { darken, lighten } from 'polished'

// Main Body Background
export const colorMainBodyBackground = '#000'
// Main Body Color
export const colorMainBody = '#707478'

export const MainPageBackground = '#111314'

/**
 * 헤더
 */
export const colorHeaderMenuItemTextColor = '#fff'
export const colorHeaderMenuItemHoverBackground = '#1b1f23'
export const colorHeaderMenuItemHoverBorderBottom = '#f84b08'
export const colorHeaderMenuItemActiveBackground = '#6b0e11'

// 메인페이지 글씨 색상
export const colorMainText2 = 'linear-gradient(45deg, #8585E7 30%, #BEA6E6 70%, #FE7EFF 100%)'
export const colorMainText = 'linear-gradient(45deg, #70D6C8, #BEA6E6, #FE7EFF)'
export const HermesMainBorder = '#8585e7'
// export const HermesMainBorder = '-webkit-linear-gradient(45deg, #fbfcb9be, #ffcdf3aa, #65d3ffaa);'
export const HermesMainHover = '-webkit-linear-gradient(45deg, #70D6C8, #8c7ba8, #FE7EFF);'
export const HermesMainHover2 = '-webkit-linear-gradient(45deg, #9D7AFF, #8585E7, #269ECC, #00EAFE);'
export const HermesMainSelect = '#3f3f6b'
export const HermesMainText = '#000'
// export const HermesMainText = '-webkit-linear-gradient(bottom, #0060fd ,#03e6c8, #df30e2);'

export const KyochonMainText = '#0062df'
export const KyochonMainBorder = '#0062df'
export const KyochonMainSelect = '#ff8201'

// 메인페이지 테두리 색상
export const colorMainBorder = 'rgba(121, 130, 19, 0.871)'

// Footer 배경 색상
export const colorFooter = '#1F2D52'

// Header 배경 색상, Hover 색상
export const colorHeaderFirst = '#57627D'
export const colorHeaderSecond = '#3B4768'
export const colorHeaderHover = '#43537E'

// 메인페이지 Slider (실시간 출금 현황 등.) 색상
export const colorMainSliderFirst = '#3B4768'
export const colorMainSliderSecond = '#57627D'

// 메인페이지 board(이벤트 등.) 게시글 배경 색
export const colorMainBoard = '#3B4768'
// 메인페이지 board(이벤트 등.) 게시글 Hover 색
export const colorMainBoardHover = '#57627D'
// 메인페이지 board(이벤트 등.)  Tab 색
export const colorMainBoardTab = '#57627D'

// 메인페이지 고객센터 내용 배경 색, 버튼
export const colorMainCustomerCenter = '#57627D'
export const colorMainCustomerButton = '#57627D'
export const colorMainCustomerButtonHover = '#3B4768'

// to Top  스크롤 버튼
export const colorScrollButtonBorder = '#EDAE07'
export const colorScrollButtonBackground = 'rgba(56, 56, 56, 1)'
export const colorScrollButtonHover = 'rgba(0, 0, 0, 1)'

// card body 배경 색
export const colorCardBody = '#3B4768'

// tab
export const colorBorderBottomActive = '#f9e79f'

// 지갑머니, 게임머니 색상
export const colorMoneyInfo = '#d0d3d4'
export const colorMoneyInfoSvg = '#AEB1B2'
export const colorWalletSvg = '#803e00'
export const colorMoneySvg = 'green'
export const colorMoneyExchangeSvg = '#847E82'

// Form
export const colorFormBorder = '#d0d3d4'
export const colorFormInput = '#F6F6F6'
export const colorFormInputActive = '#AEC22A'
export const colorFormDisabledInput = '#C2C3C4'
export const colorFormGroupIcon = '#C2C3C4'
export const colorFormSelect = '#F6F6F6'
export const colorFormSelectInput = 'black'
export const colorFormSelectActive = '#185492'
export const colorFormButtonText = '#d0d3d4'
export const colorMoneyFormButton = '#185492'
export const colorMoneyFormHoverButton = '#2C8BF6'
export const colorMoneyFormGroupIcon = '#7A7979'
export const colorFormSelected = '#798BB6B3'
export const colorMoneyExchangeBottomBorder = '#798BB6B3'

// Error Validation
export const colorErrorText = '#F74040'

// Table
export const colorTableBorder = '#4A4A4A'
export const colorTableHeaderBackground = '#5e5e5e'
export const colorTableHeaderText = '#ffffff'
export const colorTableBodyBackground = '#333333'
export const colorTableBodyText = '#ffffff'
// 페이지 번호 색상
export const colorTablePaginationNum = '#FFFEF2'
// active 페이지 번호 색상
export const colorTablePaginationNumActive = '#8585e7'
// 페이지 번호 Hover 색상
export const colorTablePaginationNumHover = '#FFE600'
// 화살표 아이콘 색상
export const colorTablePaginationArrowIcon = 'black'
// hover 화살표 아이콘  색
export const colorTablePaginationArrowIconHover = 'black'
// disabled 화살표 아이콘 색
export const colorTablePaginationArrowIconDisabled = '#999999'
// 화살표 아이콘 배경 색
export const colorTablePaginationArrowIconBackground = '#f2f4f7'
// hover 화살표 아이콘 배경 색
export const colorTablePaginationArrowIconBackgroundHover = '#CDCDCD'
// disabled 화살표 아이콘 배경 색
export const colorTablePaginationArrowIconDisabledBackground = '#f2f4f7'

// Modal
// Close Button 아이콘 색상
export const colorModalCloseButton = '#f2f4f7'
// Modal Body 배경 색상
export const colorModalBody = '#3B4768'
// Modal Header Text 색상
export const colorModalHeaderText = '#d0d3d4'
// Modal 로그인 Info Text
export const colorModalSifnInInfoText = '#d0d3d4'

export const colorModalPhoneInfoText = '#F74040'

// Mobile 메뉴
export const colorMobileMenuBackground = '#57627D'
// 메뉴 닫기 버튼
export const colorMobileMenuCloseButton = '#3B4768'
// 메뉴 닫기 버튼 글자 색
export const colorMobileMenuCloseButtonText = '#d0d3d4'
// 메뉴 Info 글자 색
export const colorMobileMenuInfoText = '#EAEBEF'
// 메뉴 글자 색
export const colorMobileMenuText = '#EAEBEF'
// 메뉴 글자 Hover 색
export const colorMobileMenuTextHover = 'black'
// 선택된 메뉴 글자 색
export const colorMobileMenuTextActive = 'black'

export const colorMobileMenuBorder = '#81899E'
// 선택된 메뉴 배경 색
export const colorMobileMenuActive = '#EAEBEF'
// 메뉴 Hover 색
export const colorMobileMenuHover = '#EAEBEF'

export const colorBackground = theme('mode', {
  light: 'white',
  dark: '#232329',
})

export const colorBackgroundBody = theme('mode', {
  light: '#f2f4f7',
  dark: '#2a2a31',
})

export const inboxButtonBackground = theme('mode', {
  light: '#f2f4f7',
  dark: '#2a2a31',
})

export const inboxButtonBackgroundHover = theme('mode', {
  light: darken(0.05, '#f2f4f7'),
  dark: lighten(0.05, '#2a2a31'),
})

export const colorHeaderDataPicker = theme('mode', {
  light: '#6db0ff',
  dark: '#063263',
})

export const colorText = theme('mode', {
  light: '#646777',
  dark: '#dddddd',
})

export const colorDarkText = theme('mode', {
  light: '#555555',
  dark: '#dddddd',
})

export const colorTextAdditional = theme('mode', {
  light: '#646777',
  dark: '#999999',
})

export const logoImg = theme('mode', {
  light: `url(${process.env.PUBLIC_URL}/img/logo/logo_light.svg)`,
  dark: `url(${process.env.PUBLIC_URL}/img/logo/logo_dark.svg)`,
})

export const colorHover = theme('mode', {
  light: '#fafbfe',
  dark: '#38373f',
})

export const colorFolderHover = theme('mode', {
  light: '#f0eeee',
  dark: '#ffffff1A',
})

export const colorBorder = theme('mode', {
  light: '#eff1f5',
  dark: '#333246',
})

export const colorIcon = theme('mode', {
  light: '#dddddd',
  dark: '#605f7b',
})

export const imgInvert = theme('mode', {
  light: 'invert(0%)',
  dark: 'invert(100%)',
})

export const colorFieldsBorder = theme('mode', {
  light: '#f2f4f7',
  dark: '#33333a',
})

export const colorBubble = theme('mode', {
  light: 'rgba(242, 244, 247, 0.65)',
  dark: 'rgba(68, 79, 97, 0.65)',
})

export const colorBubbleActive = theme('mode', {
  light: 'rgba(234, 238, 255, 0.6)',
  dark: 'rgba(92, 104, 156, 0.6)',
})

export const colorScrollbar = theme('mode', {
  light: '#B4BFD0',
  dark: '#606071',
})

export const colorFitness = theme('mode', {
  light: '#646777',
  dark: '#ffffff',
})

export const colorEmoji = theme('mode', {
  light: '#232329',
  dark: '#ffffff',
})

export const sidebarColor = theme('mode', {
  light: 'aliceblue',
  dark: '#232329',
})

export const colorAccent = '#4ce1b6'
export const colorLightAccent = '#55e5bb'
export const colorAccentHover = darken(0.1, colorAccent)
export const colorAdditional = '#999999'
export const colorAdditionalHover = darken(0.1, colorAdditional)

export const colorYellow = '#f6da6e'
export const colorLightYellow = '#f4dd87'
export const colorYellowHover = darken(0.1, colorYellow)

export const colorViolet = '#c88ffa'

export const colorRed = '#ff4861'
export const colorDarkRed = '#721c24'
export const colorLightRed = '#f88e7d'
export const colorVeryLightRed = '#f8d7da'
export const colorRedHover = darken(0.1, colorRed)

export const colorBlue = '#70bbfd'
export const colorLightBlue = '#87c3f7'
export const colorBlueHover = darken(0.1, colorBlue)

export const colorBlack = '#000000'
export const colorGray = '#787985'
export const colorLightGray = '#d8dfe9'
export const colorDustyWhite = '#dddddd'
export const colorWhite = '#ffffff'

export const colorGreen = '#b8e986'
export const colorGreenHover = darken(0.1, colorGreen)

export const colorDarkFieldsBorder = '#33333a'
export const colorBlackBackground = '#232329'
// export const colorBackgroundBody= '#f2f4f7';
export const colorLightText = '#646777'
// export const colorHover = '#fafbfe';

export const landingAccentColor = '#244153'
export const landingButtonColor = '#2C86A2'
export const landingSaffronColor = '#ffde2c'
export const landingGradient = 'linear-gradient(228deg, rgba(127, 244, 222, 0.95), rgba(56, 199, 225, 1))'
// eslint-disable-next-line max-len
export const landingGradientColor =
  'linear-gradient(228deg, darken(rgba(127, 244, 222, 0.95), 5%), darken(rgba(56, 199, 225, 1), 5%))'
export const landingGradientBtn = 'linear-gradient(228deg, rgb(127, 244, 222), rgb(62, 59, 224))'
// eslint-disable-next-line max-len
export const landingGradientBtnHover =
  'linear-gradient(228deg, darken(rgb(127, 244, 222), 5%), darken(rgb(62, 59, 224), 5%))'

export const scrollbarStyles = props => `
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    opacity: 0.3;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${colorScrollbar(props)};
  }   
`
