import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { bettingForMiniGame } from '../../api/game/miniGameApi'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { setCloseHandler, setMiniGameCompleteFlag, setResetData } from '../../redux/miniGameBettingSlice'
import { PowerBallTypeEnums } from '../../utils/enums/MiniGame/PowerBallEnums'
import isLogin from '../../utils/enums/authority'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomLoading from '../CustomLoading'

const MiniGameBettingCartSidebar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loginCheck, setLoginCheck] = useState(false)

  useEffect(() => {
    setLoginCheck(isLogin())
  }, [isLogin()])

  // redux 사용
  const dispatch = useDispatch()

  const {
    holdingMoney,
    miniGameMaxBetting,
    miniGameMinBetting,
    miniGameMaxWinning,
    memberTotalGameMoney,
    userId,
    memberLevel,
    noteRedisSize,
    oneToOneRedisSize,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      memberLevel: memberInfo.depositLevel,
      miniGameMaxBetting: memberInfo.miniGameBettingInfo.miniGameMaxBetting,
      miniGameMinBetting: memberInfo.miniGameBettingInfo.miniGameMinBetting,
      miniGameMaxWinning: memberInfo.miniGameBettingInfo.miniGameMaxWinning,
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const {
    miniGameType,
    miniGameRound,
    bettingCloseFlag,
    bettingCompleteFlag,
    bettingMoney,
    miniGameBettingPer,
    miniGameBettingType,
    bettingCartNum,
  } = useSelector(state => {
    const { miniGameBetting } = state

    return {
      miniGameType: miniGameBetting.miniGameType,
      miniGameRound: miniGameBetting.miniGameRound,
      bettingCloseFlag: miniGameBetting.bettingCloseFlag,
      bettingCompleteFlag: miniGameBetting.bettingCompleteFlag,
      bettingMoney: miniGameBetting.bettingMoney,
      miniGameBettingPer: miniGameBetting.miniGameBettingPer,
      miniGameBettingType: miniGameBetting.miniGameBettingType,
      bettingCartNum: miniGameBetting.miniGameBettingPer && 1,
    }
  })
  const [inputBettingMoney, setInputBettingMoney] = useState(Number(bettingMoney) || 0)
  const [expectMoney, setExpectMoney] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)

  const onClickCloseHandler = event => {
    setExpectMoney(0)
    setInputBettingMoney(0)
    dispatch(setCloseHandler())
  }

  // 배팅 완료 및 초기화 시
  useEffect(() => {
    setExpectMoney(0)
    setInputBettingMoney(0)
  }, [miniGameBettingPer])

  useEffect(() => {
    dispatch(setResetData())
    setExpectMoney(0)
    setInputBettingMoney(0)
  }, [miniGameType])

  const onSubmit = e => {
    if (apiFlag) return

    setApiFlag(true)
    if (!miniGameBettingPer || miniGameBettingPer === 0 || inputBettingMoney === 0) {
      setApiFlag(false)
      return
    }

    if (Number(holdingMoney) < Number(bettingMoney?.toString().replaceAll(',', ''))) {
      alert('지갑머니가 부족합니다.')
      setApiFlag(false)
      return
    }

    if (Number(inputBettingMoney?.toString().replaceAll(',', '')) < Number(miniGameMinBetting)) {
      alert('최소 배팅금액 이상으로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (Number(inputBettingMoney) > Number(miniGameMaxBetting)) {
      alert('최대 배팅금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (Number(expectMoney?.toString().replaceAll(',', '')) > Number(miniGameMaxWinning)) {
      alert('최대 당첨금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (window.confirm('배팅하시겠습니까?')) {
      const body = {
        miniGameType,
        miniGameBettingType,
        bettingAmount: inputBettingMoney?.toString().replaceAll(',', ''),
        expectMoney: expectMoney?.toString().replaceAll(',', ''),
        bettingPer: miniGameBettingPer,
        bettingAt: moment().tz('Asia/Seoul').format(),
        gameRound: miniGameRound,
      }

      bettingForMiniGame(body)
        .then(res => {
          alert('배팅이 정상적으로 이루어졌습니다.')
          dispatch(setMiniGameCompleteFlag(!bettingCompleteFlag))
        })
        .catch(error => {
          const { errorCode, message } = error.response.data
          switch (errorCode) {
            case 'SYSTEM-1017':
              alert(`${message}`)
              break
            case 'SYSTEM-1018':
              alert(`${message}`)
              break
            case 'MINIGAME-1003':
              alert(`${message}`)
              break
            case 'MINIGAME-1008':
              alert(`${message}`)
              break
            case 'MINIGAME-1009':
              alert(`${message}`)
              break
            case 'ASSET-7001':
              alert('잠시후 다시 시도해주세요.')
              break
            default:
              alert('배팅이 실패했습니다.')
          }
        })
        .finally(() => {
          setApiFlag(false)
          onClickCloseHandler()
          dispatch(setResetData())
          dispatch(fetchMemberInfoAction())
        })
    } else {
      setApiFlag(false)
    }
  }

  useEffect(() => {
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니
    let resultBettingMoney = inputRealMoney
    let resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)

    if (inputRealMoney > miniGameMaxBetting) {
      // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
      resultBettingMoney = miniGameMaxBetting
      // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
      if (resultExpectMoney > miniGameMaxWinning) {
        resultBettingMoney = Math.floor(miniGameMaxWinning / miniGameBettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
      }
    }

    if (resultExpectMoney > miniGameMaxWinning) {
      // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
      resultBettingMoney = Math.floor(miniGameMaxWinning / miniGameBettingPer) // 비율에 따른 최대 배팅금액
      resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
    }

    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))

    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [inputBettingMoney, miniGameBettingPer])

  const onClickMoneyHandler = amountText => {
    const textNum = amountText || holdingMoney

    setInputBettingMoney(prev => {
      const prevprevValue = prev ? prev.toString().replaceAll(',', '') : ''
      const prevValue = prevprevValue.startsWith('0') ? prevprevValue.slice(1) : prevprevValue
      if ((prevValue === '' || prevValue === '0') && (textNum === '0' || textNum === '00' || textNum === '000')) {
        return prevValue?.toString().replace(commonReg2, ',')
      }

      if (textNum.length > 3) {
        // UI키패드 버튼으로 5만 ~ 200만 까지 눌렀을때
        if (prevValue === '' || prevValue === '0') {
          return textNum?.toString().replace(commonReg2, ',')
        }
        // UI키패드 버튼으로 0,00,000 , 1~9까지 눌렀을때
        return (Number(prevValue) + Number(textNum))?.toString().replace(commonReg2, ',')
      }
      return (prevValue + textNum)?.toString().replace(commonReg2, ',')
    })
  }

  useEffect(() => {
    if (miniGameRound && miniGameBettingPer && bettingCloseFlag) {
      dispatch(setResetData())
    }
  }, [bettingCloseFlag, bettingCompleteFlag, miniGameRound, miniGameBettingPer, miniGameType])

  return (
    <>
      {apiFlag && <CustomLoading />}
      <div
        className="powerball_cart"
        style={{
          background: `url(${process.env.PUBLIC_URL}/img/minigame/bg_powerball_cart.png) 0 0 no-repeat`,
        }}
      >
        <div className="cart_info">
          <ul>
            <li>
              <span>회차</span>
              <strong>{miniGameRound || '-'} 회차</strong>
            </li>
            <li id="selBet">
              <span>선택</span>
              <strong>{PowerBallTypeEnums[miniGameBettingType]}</strong>
            </li>
            <li>
              <span>배당률</span>
              <em>
                <b id="total_rate">{miniGameBettingPer}</b>
              </em>
            </li>
          </ul>
        </div>

        <div className="cart_pay">
          <h4 className="hidden">배팅금액선택</h4>
          <div className="bet_money">
            <label htmlFor="betExp2">배팅 금액</label>
            <input
              type="text"
              id="money_box"
              name="bet_amount_view"
              value={inputBettingMoney}
              placeholder={inputBettingMoney}
              onChange={e => {
                const value = e.target.value.trim()
                const numbersOnly = value.match(/\d+/g)?.join('')

                if (!numbersOnly) {
                  setInputBettingMoney('0')
                } else {
                  setInputBettingMoney(numbersOnly.replace(/^0+/, '').replace(commonReg2, ','))
                }
              }}
            />
          </div>
          <div className="bet_money i_blue">
            <label htmlFor="betExp3">적중 금액</label>
            <input
              type="text"
              id="result_money"
              name="bet_dividend_view"
              value={expectMoney?.toString().replace(commonReg2, ',') || 0}
              readOnly="readonly"
            />
          </div>
          <div className="bet_btn_inner">
            <input type="button" value="5,000" onClick={() => onClickMoneyHandler('5000')} />
            <input type="button" value="10,000" onClick={() => onClickMoneyHandler('10000')} />
            <input type="button" value="50,000" onClick={() => onClickMoneyHandler('50000')} />
            <input type="button" value="100,000" onClick={() => onClickMoneyHandler('100000')} />
            <input type="button" value="500,000" onClick={() => onClickMoneyHandler('500000')} />
            <input type="button" value="1,000,000" onClick={() => onClickMoneyHandler('1000000')} />
            <input
              type="button"
              className="i_brw"
              value="전액"
              onClick={() => onClickMoneyHandler('')}
              style={{ width: '147px' }}
            />
            <input
              type="button"
              className="i_gray"
              value="초기화"
              onClick={onClickCloseHandler}
              style={{ width: '147px' }}
            />
            <div className="bet_money_free">
              <label htmlFor="betExp4">보유금</label>
              <input
                type="text"
                id="betExp4"
                name="betExp4"
                value={holdingMoney?.toString().replace(commonReg2, ',')}
                style={{ fontSize: '20px' }}
                readOnly="readonly"
              />
            </div>
          </div>

          <input type="button" value="배팅하기" className="btn_bet" onClick={onSubmit} />
        </div>
      </div>
    </>
  )
}

export default MiniGameBettingCartSidebar
