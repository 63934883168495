import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Navigate, Outlet, Route, Routes, useSearchParams } from 'react-router-dom'
import AttendanceForm from '../containers/Attendance/AttendanceForm'
import BettingHistoryPageV2 from '../containers/BettingHistory/BettingHistoryPageV2'
import LiveCasino from '../containers/CasinoGame/LiveCasino'
import SlotCasino from '../containers/CasinoGame/SlotCasino'
import CouponUseForm from '../containers/CouponUse/CouponUseForm'
import EventDetail from '../containers/CustomCenter/Event/EventDetail'
import EventListPage from '../containers/CustomCenter/Event/EventListPage'
import NoteDetail from '../containers/CustomCenter/Note/NoteDetail'
import NoteList from '../containers/CustomCenter/Note/NoteList'
import BettingRuleListPage from '../containers/CustomCenter/Notice/BettingRuleListPage'
import NoticeListPage from '../containers/CustomCenter/Notice/NoticeListPage'
import OnetoOneDetail from '../containers/CustomCenter/OnetoOne/OnetoOneDetail'
import OnetoOneList from '../containers/CustomCenter/OnetoOne/OnetoOneList'
import OnetoOneWrite from '../containers/CustomCenter/OnetoOne/OnetoOneWrite'
import Layout from '../containers/Layout/Layout'
import LoginPage from '../containers/Login/LoginPage'
import WebSignUpPage from '../containers/Login/WebSignUpPage'
import MainPage from '../containers/MainPage/MainPage'
import StarLadderOne from '../containers/MiniGame/Boscore/StarLadderOne'
import StarLadderThree from '../containers/MiniGame/Boscore/StarLadderThree'
import StarLadderTwo from '../containers/MiniGame/Boscore/StarLadderTwo'
import GopickBaseball from '../containers/MiniGame/Gopick/GopickBaseball'
import GopickRaccoon from '../containers/MiniGame/Gopick/GopickRaccoon'
import GopickSoccer from '../containers/MiniGame/Gopick/GopickSoccer'
import GopickSquid from '../containers/MiniGame/Gopick/GopickSquid'
import MiniGameMain from '../containers/MiniGame/MiniGameMain'
import EosPowerBallFive from '../containers/MiniGame/Ntry/EosPowerBallFive'
import EosPowerBallFour from '../containers/MiniGame/Ntry/EosPowerBallFour'
import EosPowerBallOne from '../containers/MiniGame/Ntry/EosPowerBallOne'
import EosPowerBallThree from '../containers/MiniGame/Ntry/EosPowerBallThree'
import EosPowerBallTwo from '../containers/MiniGame/Ntry/EosPowerBallTwo'
import EosSevenBallFive from '../containers/MiniGame/Ntry/EosSevenBallFive'
import EosSevenBallOne from '../containers/MiniGame/Ntry/EosSevenBallOne'
import EosSevenBallThree from '../containers/MiniGame/Ntry/EosSevenBallThree'
import EosSpeedKeno from '../containers/MiniGame/Ntry/EosSpeedKeno'
import KenoLadder from '../containers/MiniGame/Ntry/KenoLadder'
import NtryPowerBall from '../containers/MiniGame/Ntry/NtryPowerBall'
import PowerLadder from '../containers/MiniGame/Ntry/PowerLadder'
import SpeedKeno from '../containers/MiniGame/Ntry/SpeedKeno'
import MoneyDepositForm from '../containers/MoneyDeposit/MoneyDepositForm'
import MoneyExchangeForm from '../containers/MoneyExchange/MoneyExchangeForm'
import MoneyWithdrawalForm from '../containers/MoneyWithdrawal/MoneyWithdrawalForm'
import FriendList from '../containers/MyPage/FriendListHistory/FriendList'
import MyInfo from '../containers/MyPage/MyInfo/MyInfo'
import NotFound404 from '../containers/NotFound/NotFound404'
import PointExchangeForm from '../containers/PointExchange/PointExchangeForm'
import GameMoneyPage from '../containers/SearchGameMoney/GameMoneyPage'
import SportsBalancePage from '../containers/SportsGame/balance/SportsBalancePage'
import SportsDomesticPage from '../containers/SportsGame/domestic/SportsDomesticPage'
import SportsLivePage from '../containers/SportsGame/live/SportsLivePage'
import LiveSportsGamePage from '../containers/SportsGame/LiveSportsGamePage'
import SportsOverseasPage from '../containers/SportsGame/overseas/SportsOverseasPage'
import SportsSpecialPage from '../containers/SportsGame/special/SportsSpecialPage'
import SportsGamePage from '../containers/SportsGame/SportsGamePage'
import SportsSpecialGamePage from '../containers/SportsGame/SportsSpecialGamePage'
import VirtualBaseballPage from '../containers/VirtualGame/Baseball/VirtualBaseballPage'
import VirtualBasketballPage from '../containers/VirtualGame/Basketball/VirtualBasketballPage'
import VirtualSoccerPage from '../containers/VirtualGame/Soccer/VirtualSoccerPage'
import isLogin from '../utils/enums/authority'
import VirtualGreyhoundsPage from '../containers/VirtualGame/Greyhounds/VirtualGreyhoundsPage'
import VirtualHorsePage from '../containers/VirtualGame/Horse/VirtualHorsePage'

const PrivateRoute = ({ alertMessage = '로그인 후 이용가능합니다.' }) => {
  const [recParams] = useSearchParams()

  if (!isLogin()) {
    const rec = recParams.get('rec')
    if (rec) {
      return <Navigate to={`/?rec=${rec}`} replace state={{ alertMessage }} />
    }
    return <Navigate to="/" replace state={{ alertMessage }} />
  }

  return <Outlet />
}

// 긴급 점검일때는 url 막아야 함
const Router = () => {
  return (
    <AnimatePresence>
      <Routes>
        {/* 공통으로 들어갈 컴포넌트 들 */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<WebSignUpPage />} />
        <Route element={<PrivateRoute />}>
          <Route element={<Layout />}>
            {/* 공통으로 들어갈 컴포넌트 들 */}
            {/* 메인페이지 */}
            <Route path="/main" element={<MainPage />} />

            {/* 그외 페이지 */}
            <Route path="/live-casino" element={<LiveCasino />} />
            <Route path="/slot-casino" element={<SlotCasino />} />
            <Route path="/mypage" element={<MyInfo />} />
            <Route path="/gamemoney" element={<GameMoneyPage />} />
            <Route path="/money/deposit" element={<MoneyDepositForm />} />
            <Route path="/money/withdrawal" element={<MoneyWithdrawalForm />} />
            <Route path="/money/moneyexchange" element={<MoneyExchangeForm />} />
            <Route path="/money/couponuse" element={<CouponUseForm />} />
            <Route path="/money/pointexchange" element={<PointExchangeForm />} />
            <Route path="/customcenter/betting-rule" element={<BettingRuleListPage />} />
            <Route path="/customcenter/notice" element={<NoticeListPage />} />
            <Route path="/customcenter/event" element={<EventListPage />} />
            <Route path="/customcenter/event-detail" element={<EventDetail />} />
            <Route path="/minigame" element={<MiniGameMain />} />
            <Route path="/minigame/ntry/eos-pb-1m" element={<EosPowerBallOne />} />
            <Route path="/minigame/ntry/eos-pb-2m" element={<EosPowerBallTwo />} />
            <Route path="/minigame/ntry/eos-pb-3m" element={<EosPowerBallThree />} />
            <Route path="/minigame/ntry/eos-pb-4m" element={<EosPowerBallFour />} />
            <Route path="/minigame/ntry/eos-pb-5m" element={<EosPowerBallFive />} />
            <Route path="/minigame/ntry/eos-pb" element={<NtryPowerBall />} />
            <Route path="/minigame/ntry/power-ladder" element={<PowerLadder />} />
            <Route path="/minigame/ntry/keno-ladder" element={<KenoLadder />} />
            <Route path="/minigame/ntry/speed-keno" element={<SpeedKeno />} />
            <Route path="/minigame/ntry/seven-1m" element={<EosSevenBallOne />} />
            <Route path="/minigame/ntry/seven-3m" element={<EosSevenBallThree />} />
            <Route path="/minigame/ntry/seven-5m" element={<EosSevenBallFive />} />
            <Route path="/minigame/ntry/eos-speedkeno" element={<EosSpeedKeno />} />
            {/* 보스코어 */}
            <Route path="/minigame/boscore/stat1m" element={<StarLadderOne />} />
            <Route path="/minigame/boscore/stat2m" element={<StarLadderTwo />} />
            <Route path="/minigame/boscore/stat3m" element={<StarLadderThree />} />
            {/* 네임드 */}
            {/* <Route path="/minigame/named/n/powerball3m" element={<NPowerBallThree />} />
            <Route path="/minigame/named/n/powerball5m" element={<NPowerBallFive />} />
            <Route path="/minigame/named/n/powerladder3m" element={<NPowerLadderThree />} />
            <Route path="/minigame/named/n/powerladder5m" element={<NPowerLadderFive />} />
            <Route path="/minigame/named/red/powerball" element={<RedPowerBall />} />
            <Route path="/minigame/named/red/powerladder" element={<RedPowerLadder />} />
            <Route path="/minigame/named/running/speed4" element={<RunningBallSpeedFour />} />
            <Route path="/minigame/named/running/speed6" element={<RunningBallSpeedSix />} />
            <Route path="/minigame/named/running/maze2" element={<RunningBallMazeTwo />} /> */}
            {/* 슈어맨 */}
            {/* <Route path="/minigame/sureman/ladder1m" element={<SuremanLadderOne />} />
            <Route path="/minigame/sureman/roulette1m" element={<SuremanRoulletOne />} />
            <Route path="/minigame/sureman/racing1m" element={<SuremanRacingOne />} /> */}
            {/* 고픽 */}
            <Route path="/minigame/gopick/raccoon" element={<GopickRaccoon />} />
            <Route path="/minigame/gopick/soccer" element={<GopickSoccer />} />
            <Route path="/minigame/gopick/baseball" element={<GopickBaseball />} />
            <Route path="/minigame/gopick/octopus" element={<GopickSquid />} />

            <Route path="/virtual/soccer" element={<VirtualSoccerPage />} />
            <Route path="/virtual/basketball" element={<VirtualBasketballPage />} />
            <Route path="/virtual/baseball" element={<VirtualBaseballPage />} />
            <Route path="/virtual/greyhounds" element={<VirtualGreyhoundsPage />} />
            <Route path="/virtual/horse" element={<VirtualHorsePage />} />

            <Route path="/sports/domestic" element={<SportsDomesticPage />} />
            <Route path="/sports/balance" element={<SportsBalancePage />} />
            <Route path="/sports/overseas" element={<SportsOverseasPage />} />
            <Route path="/sports/special" element={<SportsSpecialPage />} />
            <Route path="/sports/live" element={<SportsLivePage />} />
            <Route path="/sportsgame" element={<SportsGamePage />} />
            <Route path="/special-sports" element={<SportsSpecialGamePage />} />
            <Route path="/live-sports" element={<LiveSportsGamePage />} />
            <Route path="/attendance" element={<AttendanceForm />} />
            <Route path="/betting-history" element={<BettingHistoryPageV2 />} />
            <Route path="/one-to-one" element={<OnetoOneList />} />
            <Route path="/one-to-one-detail" element={<OnetoOneDetail />} />
            <Route path="/one-to-one-write" element={<OnetoOneWrite />} />
            <Route path="/note" element={<NoteList />} />
            <Route path="/note-detail" element={<NoteDetail />} />
            <Route path="/friend-list" element={<FriendList />} />
            {/* <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/sign-up" element={<SignUpPage />} /> */}
          </Route>
        </Route>

        {/* 공통 Layout 이 안보여야 하는 컴포넌트 들 */}
        {/* 아래는 페이지 not found  */}
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </AnimatePresence>
  )
}

export default Router
