import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { position } from 'polished'
import { addBettingCartItem, setSelectedBonusFolder } from '../../../redux/sportsBettingSlice'
import {
  fetchOverseasFixtureListAction,
  fetchOverseasFixtureMarketAction,
  setFixtureCollapse,
  setFixtureMarketParam,
  setPageNum,
} from '../../../redux/sportsOverseasInfoSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import { CommonBetBox } from '../../../shared/components/SportCommonBetBox'
import { DomesticBetBox } from '../../../shared/components/SportDomesticBetBox'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { SportMarketType } from '../../../utils/enums/SportsGame/SportsGameEnums'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'
import SportsOverseasContentMarket from './SportsOverseasContentMarket'
import { SportMainMarketBetBox } from '../../../shared/components/SportMainMarketBetBox'

const SportEventFixtureItem = React.memo(
  ({
    fixtureKey,
    fixtureData,
    onClickSportFixtureMarketAdditionalOptionHandler,
    onClickAddBettingCartHandler,
    selectedFixtureId,
  }) => {
    const filteredMainMarkets = useMemo(() => {
      return fixtureData.mainMarkets.filter(mainMarket => {
        return (
          mainMarket.usedMarket &&
          mainMarket.marketVisible &&
          mainMarket.betLines.every(betLine => {
            return betLine.bets.every(bet => {
              return bet.showStatus && (bet.betStatus === 'OPEN' || bet.betStatus === 'SUSPENDED')
            })
          })
        )
      })
    }, [fixtureData.mainMarkets])

    return (
      <Fragment key={`FixtureItem-${fixtureKey}-${fixtureData.fixtureId}`}>
        <div className="t-line">
          <li className="team-name">
            <span className="span_home_name">
              <span className="long">
                {fixtureData.fixture.homeName} vs {fixtureData.fixture.awayName}
              </span>
            </span>
            <span
              className={fixtureData.fixtureId === selectedFixtureId ? 'span_away_name_active' : 'span_away_name'}
              onClick={() => {
                onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
              }}
            >
              <span>추가 배팅옵션</span>
            </span>
          </li>
        </div>
        {filteredMainMarkets.length === 0 || filteredMainMarkets.every(itme => itme.betLines.length === 0) ? (
          <div className="show-type">
            <div className="t-line">
              <li className="date" style={{ width: '15%' }}>
                <b style={{ letterSpacing: '-1px' }}>-</b>
              </li>
              <li
                className="t-name"
                style={{ width: '29%' }}
                onClick={() => {
                  onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
                }}
              >
                <div className="odds">
                  <b>{fixtureData.fixture.homeName}</b>
                </div>
              </li>
              <li className="t-name-center" style={{ width: '20%' }}>
                <div className="odds">
                  <b>VS</b>
                </div>
              </li>
              <li
                className="t-name"
                style={{ width: '29%' }}
                onClick={() => {
                  onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
                }}
              >
                <div className="odds">
                  <b>{fixtureData.fixture.awayName}</b>
                </div>
              </li>
            </div>
          </div>
        ) : (
          <>
            {fixtureData.mainMarkets.map(mainMarket => {
              if (!mainMarket.usedMarket || !mainMarket.marketVisible) return null

              const filterBetLines = mainMarket.betLines.filter(betLine => {
                // 모든 bets가 'SUSPENDED'인 경우를 체크합니다.
                // const allSuspended = betLine.bets.every(bet => bet.betStatus === 'SUSPENDED')

                // if (allSuspended) return false

                // 각 betLine의 bets 배열을 검사하여 모든 조건을 충족하는지 확인합니다.
                return betLine.bets.every(bet => {
                  // showStatus가 true여야 하고, betStatus가 'OPEN' 또는 'SUSPEND'여야 합니다.
                  return bet.showStatus && (bet.betStatus === 'OPEN' || bet.betStatus === 'SUSPENDED')
                })
              })

              if (filterBetLines.length === 0) return null

              const customMarket = {
                ...mainMarket,
                betLines: filterBetLines,
              }

              return (
                <SportMainMarketBetBox
                  fixtureInfo={{
                    fixtureId: fixtureData.fixtureId,
                    ...fixtureData.fixture,
                  }}
                  marketInfo={customMarket}
                  onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                />
              )
            })}
          </>
        )}
      </Fragment>
    )
  },
)

const SportEventFixtures = React.memo(
  ({
    eventKey,
    event,
    onClickFixtureCollapseHandler,
    onClickSportFixtureMarketAdditionalOptionHandler,
    onClickAddBettingCartHandler,
    selectedFixtureId,
  }) => {
    return (
      <>
        <div className="t-league" onClick={() => onClickFixtureCollapseHandler(eventKey)}>
          <div className="league-name">
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${event.sportId}.png`}
                      align="absmiddle"
                      alt=""
                    />
                  </td>
                  <td>{event.leagueImageUrl && <img src={event.leagueImageUrl} alt="" />}</td>
                  <td>
                    <span className="long">
                      [{event.locationName}] {event.leagueName}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="league-icon">
            {convertToKstByFormat(event.startDate, 'MM/DD HH:mm')}

            <span style={{ float: 'right' }}>
              {event.fixtureLeagueCollapse ? <SportsEventTitleArrowUpIcon /> : <SportsEventTitleArrowDownIcon />}
            </span>
          </div>
        </div>
        {event.fixtureLeagueCollapse && (
          <>
            {event.fixtures.map(fixtureData => {
              return (
                <SportEventFixtureItem
                  key={`${eventKey}-${fixtureData.fixtureId}`}
                  fixtureKey={eventKey}
                  fixtureData={fixtureData}
                  onClickSportFixtureMarketAdditionalOptionHandler={onClickSportFixtureMarketAdditionalOptionHandler}
                  onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                  selectedFixtureId={selectedFixtureId}
                />
              )
            })}
          </>
        )}
      </>
    )
  },
)

const SportsOverseasContent = () => {
  const dispatch = useDispatch()

  const { totalElements, overseasParams, overseasMarketParams, overseasFixtures } = useSelector(state => {
    const { sportsOverseasInfo } = state

    return {
      totalElements: sportsOverseasInfo.totalElements,
      overseasParams: sportsOverseasInfo.overseasParams,
      overseasMarketParams: sportsOverseasInfo.overseasMarketParams,
      overseasFixtures: sportsOverseasInfo.overseasFixtures,
    }
  })

  const { bonusFolderInfo, selectedBonusFolder, bettingCartList, bettingOkTimeCriteria, bonusFolderPerCriteria } =
    useSelector(state => {
      const { sportsBetting } = state

      const sportsBettingInfo = sportsBetting.sportsBettingInfos[SportMarketType.OVERSEAS]

      return {
        bonusFolderInfo: sportsBettingInfo?.bonusFolderInfo || [],
        selectedBonusFolder: sportsBetting.selectedBonusFolder,
        bettingCartList: sportsBetting.bettingCartList,
        bettingOkTimeCriteria: sportsBettingInfo?.bettingOkTimeCriteria ?? 0,
        bonusFolderPerCriteria: sportsBettingInfo?.bonusFolderPerCriteria ?? 0,
      }
    })

  const bettingCartListRef = useRef(bettingCartList)

  useEffect(() => {
    bettingCartListRef.current = bettingCartList
  }, [bettingCartList])

  const sportsEventListWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / overseasParams.size))
  }, [totalElements, overseasParams])

  const onPageChange = pageNumber => {
    dispatch(setPageNum(pageNumber))

    if (sportsEventListWrapRef.current) {
      sportsEventListWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    dispatch(fetchOverseasFixtureListAction())
  }, [dispatch, overseasParams])

  useEffect(() => {
    dispatch(fetchOverseasFixtureMarketAction())
  }, [dispatch, overseasMarketParams])

  // 경기 리그 펼치기
  const onClickFixtureCollapseHandler = useCallback(
    key => {
      dispatch(setFixtureCollapse(key))
    },
    [dispatch],
  )

  const [selectedFixtureInfo, setSelectedFixtureInfo] = useState(null)
  const selectedFixtureInfoRef = useRef(selectedFixtureInfo)

  useEffect(() => {
    selectedFixtureInfoRef.current = selectedFixtureInfo
  }, [selectedFixtureInfo])

  const onClickSportFixtureMarketAdditionalOptionHandler = useCallback(
    (eventKey, fixtureData) => {
      const diff = !isEqual(selectedFixtureInfoRef.current, {
        eventKey,
        fixtureId: fixtureData.fixtureId,
      })

      if (diff) {
        dispatch(setFixtureMarketParam(fixtureData.fixtureId))
        setSelectedFixtureInfo({
          eventKey,
          fixtureId: fixtureData.fixtureId,
        })
      } else {
        setSelectedFixtureInfo(null)
      }
    },
    [dispatch],
  )

  // 배팅 카트 담기
  const onClickAddBettingCartHandler = useCallback(
    bettingCartItem => {
      dispatch(
        addBettingCartItem({
          ...bettingCartItem,
          sportMarketType: SportMarketType.OVERSEAS,
        }),
      )
    },
    [dispatch],
  )

  // 보너스 폴더 선택
  const onClickSportBonusFolderHandler = selectedBonusFolderItem => {
    dispatch(setSelectedBonusFolder(selectedBonusFolderItem))
  }

  const getBonusFolderFont = idx => {
    switch (idx) {
      case 0:
        return 'blue'
      case 1:
        return 'green'
      case 2:
        return 'red'
      default:
        return 'black'
    }
  }

  return (
    <>
      <SportsFixtureWrapper className="content">
        <div id="game_list">
          <div className="t-league" style={{ marginBottom: '1px' }}>
            {/* 보너스 배당 */}
            <div className="league-name">
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td style={{ height: '29px' }}>
                      <img src={`${process.env.PUBLIC_URL}/img/sports/common/event1.png`} alt="" />
                    </td>
                    <td>&nbsp;보너스 배당</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {bonusFolderInfo.map((folder, index) => {
            return (
              <>
                <div className="t-line">
                  <li className="team-name">
                    <span className="span_home_name">
                      <span>
                        보너스 배당 UP !!&nbsp;
                        <font color={getBonusFolderFont(index, folder.bonusFolderNum)}>
                          {folder.bonusFolderNum}폴더 보너스
                        </font>
                      </span>
                    </span>
                    <font color={getBonusFolderFont(index, folder.bonusFolderNum)}>
                      <span className="span_away_name">
                        <span>{bonusFolderPerCriteria} 배당이상 조합가능</span>
                      </span>
                    </font>
                    &nbsp;&nbsp;
                  </li>
                </div>
                <div className="show-type">
                  <div className="t-line">
                    <li className="date" style={{ width: '15%' }}>
                      <b>보너스</b>
                    </li>
                    <li
                      className={folder.bonusFolderNum === selectedBonusFolder?.bonusFolderNum ? 't-name-on' : 't-name'}
                      style={{ width: '29%' }}
                      onClick={() => {
                        onClickSportBonusFolderHandler(folder)
                      }}
                    >
                      <div className="odds">
                        <b>{folder.bonusFolderPer}</b>
                      </div>
                    </li>
                    <li className="t-name-center" style={{ width: '20%' }}>
                      <div className="odds">
                        <b>◀</b>
                      </div>
                    </li>
                    <li className="t-name-center" style={{ width: '29%' }}>
                      <div className="odds">
                        <b>777.00</b>
                      </div>
                    </li>
                  </div>
                </div>
              </>
            )
          })}

          {/* 게임 리스트 */}
          {Object.entries(overseasFixtures).map(([key, event]) => {
            const currentTime = moment()
            const startDate = moment(event.startDate)
            const diff = Math.abs(currentTime.diff(startDate, 'seconds'))
            if (bettingOkTimeCriteria !== 0 && diff <= bettingOkTimeCriteria) {
              // 배팅 마감시간 지난 것
              return null
            }

            const filteredEventFixtures = event.fixtures.filter(fixtureItem => {
              return (
                fixtureItem.fixture.fixtureVisible &&
                fixtureItem.fixture.sportVisible &&
                fixtureItem.fixture.locationVisible &&
                fixtureItem.fixture.leagueVisible &&
                fixtureItem.fixture.homeVisible &&
                fixtureItem.fixture.awayVisible &&
                (fixtureItem.fixture.fixtureStatusId === 1 || fixtureItem.fixture.fixtureStatusId === 9)
              )
            })

            if (filteredEventFixtures.length === 0) {
              return null
            }

            return (
              <SportEventFixtures
                eventKey={key}
                event={{
                  ...event,
                  fixtures: filteredEventFixtures,
                }}
                onClickFixtureCollapseHandler={onClickFixtureCollapseHandler}
                onClickSportFixtureMarketAdditionalOptionHandler={onClickSportFixtureMarketAdditionalOptionHandler}
                onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                selectedFixtureId={selectedFixtureInfo?.fixtureId}
              />
            )
          })}

          <CustomPagination currentPage={overseasParams.page} totalPages={totalPages} onPageChange={onPageChange} />
        </div>
      </SportsFixtureWrapper>
      <div className="content" style={{ width: '470px' }}>
        <SportsOverseasContentMarket selectedFixtureInfo={selectedFixtureInfo} />
      </div>
    </>
  )
}

export default SportsOverseasContent

const SportsFixtureWrapper = styled.div`
  width: 470px !important;
  height: calc(100vh - 200px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`

const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: #000;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: -2px;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: #000;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: -2px;
`
