import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setSelectedVirtuaGameSportType } from '../../../redux/virtualGameBettingSlice'
import { setResetVirtualFixtures } from '../../../redux/virtualGameInfoSlice'
import { VirtualGameSportTypeParam } from '../../../utils/enums/VirtualGame/VirtualGameEnums'
import CustomLoading from '../../CustomLoading'
import VirtualGameBettingCartSidebar from '../../Layout/VirtualGameBettingCartSidebar'
import VirtualGameHeader from '../VirtualGameHeader'
import VirtualHorseContent from './VirtualHorseContent'

const VirtualHorsePage = () => {
  const dispatch = useDispatch()

  const { virtualGameLoading } = useSelector(state => {
    const { virtualGameInfo } = state

    return {
      virtualGameLoading: virtualGameInfo.virtualGameLoading,
    }
  })

  const { bettingCartLoading } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      bettingCartLoading: virtualGameBetting.bettingCartLoading,
    }
  })

  useEffect(() => {
    dispatch(setSelectedVirtuaGameSportType(VirtualGameSportTypeParam.VIRTUAL_HORSE))

    return () => {
      dispatch(setSelectedVirtuaGameSportType(null))
      dispatch(setResetVirtualFixtures(null))
    }
  }, [])

  return (
    <>
      {(virtualGameLoading || bettingCartLoading) && (
        <VirtualGamePageLoadingOverlay>
          <CustomLoading isGameStart info={bettingCartLoading ? '배팅 처리중입니다.' : ''} />
        </VirtualGamePageLoadingOverlay>
      )}
      <div className="div_content" style={{ position: 'relative' }}>
        <input type="hidden" id="bet_cart_fixed" value="F" />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '963px', height: '0px' }} />
              <td style={{ width: '7px', height: '0px' }} />
              <td style={{ width: '230px', height: '0px' }} />
            </tr>
            <tr>
              {/* 메인 화면 */}
              <td style={{ width: '963px', verticalAlign: 'top' }}>
                <div className="div_body">
                  <div className="content" style={{ width: '943px' }}>
                    <VirtualGameHeader />

                    <div className="height_20" />

                    <VirtualHorseContent />
                  </div>
                </div>
              </td>

              {/* 공백 */}
              <td style={{ width: '7px' }} />
              {/* 배팅카트 */}
              <td style={{ verticalAlign: 'top', width: '230px' }}>
                <VirtualGameBettingCartSidebar />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default VirtualHorsePage

const VirtualGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1011;
  width: 100%;
  height: 100vh; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const VirtualGamePageWrap = styled.div`
  width: 100%;
`

const VirtualGamePageContentWrap = styled.div`
  border-radius: 0 0 3px 3px;
  background: #111314;
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Malgun Gothic', dotum;

  padding-bottom: 10px;
`
