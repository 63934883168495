import moment from 'moment'
import 'moment/locale/ko'
import React, { useEffect, useState } from 'react'

const TimeDisplay = () => {
  const [currentTime, setCurrentTime] = useState(moment().tz('Asia/Seoul'))

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().tz('Asia/Seoul'))
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  return <div>{currentTime.locale('ko').format('YYYY-MM-DD dddd A hh : mm : ss')}</div>
}

export default TimeDisplay
