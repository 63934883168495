import { removeToken, setCookie } from '../../utils/cookie'
import { deleteAccessToken } from '../../utils/token'
import axios from '../base/axios'

// 회원가입
export async function siginUp(body) {
  const response = await axios({
    url: '/tyson/member/v1/auth/sign-up',
    method: 'POST',
    data: body,
  }).then(res => {
    return res.data
  })
  return response
}

// 아이디 중복확인
export async function confirmUserId(params) {
  const response = await axios({
    url: '/tyson/member/v1/auth/confirm',
    method: 'GET',
    params,
  }).then(res => {
    return res.data
  })
  return response
}

// 아이디 중복확인
export async function confirmNickName(nickName) {
  const response = await axios({
    url: `/tyson/member/v1/auth/confirm/nickName/${nickName}`,
    method: 'GET',
  }).then(res => {
    return res.data
  })
  return response
}

// 추천 코드 확인
export async function searchRecommendCode(params) {
  const response = await axios({
    url: '/tyson/member/v1/auth/recommendcode',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 추천코드 확인
export async function confirmRecommendCode(params) {
  const response = await axios({
    url: '/tyson/member/v1/auth/confirm-recommendcode',
    method: 'GET',
    params,
  }).then(res => {
    return res.data
  })
  return response
}

// 로그인
export async function siginIn(body) {
  const response = await axios({
    url: '/tyson/member/v1/auth/sign-in',
    method: 'POST',
    data: body,
  }).then(res => {
    // cookie 에 refresh token 저장

    // setCookie('accessToken', res.data.accessToken, {
    //   path: '/',
    //   secure: true,
    //   sameSite: 'none',
    // })

    // setCookie('refreshToken', res.data.refreshToken, {
    //   path: '/',
    //   secure: true,
    //   sameSite: 'none',
    // })

    if (window.location.host.includes('localhost')) {
      setCookie('accessToken', res.data.accessToken, {
        path: '/',
        secure: true,
        sameSite: 'none',
      })

      setCookie('refreshToken', res.data.refreshToken, {
        path: '/',
        secure: true,
        sameSite: 'none',
      })
    } else {
      // eslint-disable-next-line no-useless-escape
      const domain = `.${window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1')}`

      setCookie('accessToken', res.data.accessToken, {
        path: '/',
        secure: true,
        domain,
        sameSite: 'none',
      })

      setCookie('refreshToken', res.data.refreshToken, {
        path: '/',
        secure: true,
        domain,
        sameSite: 'none',
      })
    }
    return res.data
  })
  return response
}

// 로그아웃
export async function siginOut() {
  const response = await axios({
    url: `/tyson/member/v1/auth/sign-out`,
    method: 'POST',
  }).then(res => {
    // local storage 데이터 전부 삭제
    deleteAccessToken()

    // cookie 에 refresh token 삭제
    removeToken()

    return res.data
  })

  return response
}

// recaptcha 확인
export async function verifyRecaptcha(body) {
  const response = await axios({
    url: '/tyson/v1/recaptcha',
    method: 'POST',
    data: body,
  }).then(res => {
    return res.data
  })
  return response
}

// 로그인 이력 조회 API
export async function searchPartnerUrl(params) {
  const response = await axios({
    url: '/tyson/member/v1/auth/partner-url',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
