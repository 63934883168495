import React, { useMemo, useState } from 'react'
import { convertToKstByFormat } from '@/utils/dateTime'
import { couponStatusEnums, couponTypeEnums } from '@/utils/enums/MyPage/myPageEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const CouponListData = content => {
  const columns = [
    {
      Header: '쿠폰명',
      accessor: 'couponTitle',
      disableGlobalFilter: true,
      width: 100,
    },
    {
      Header: '쿠폰액',
      accessor: 'couponPrice',
      disableGlobalFilter: true,
      width: 50,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.couponPrice?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '사용일',
      accessor: 'useDate',
      disableGlobalFilter: true,
      width: 55,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{convertToKstByFormat(original.useDate, 'MM-DD HH:mm')}</>
      },
    },
    // {
    //   Header: '만료일',
    //   accessor: 'expirationDate',
    //   disableGlobalFilter: true,
    //   width: 55,
    //   Cell: r => {
    //     const { row } = r
    //     const { original } = row
    //     return <>{convertToKstByFormat(original.expirationDate, 'MM-DD HH:mm')}</>
    //   },
    // },
    {
      Header: '상태',
      accessor: 'couponStatus',
      disableGlobalFilter: true,
      width: 30,
      Cell: r => {
        const { row } = r
        const { original } = row
        return couponStatusEnums[original.couponStatus]
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default CouponListData
