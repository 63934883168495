import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  searchLiveSportsEventList,
  searchSportsSettingInfo,
  searchSportsEventList,
  searchTodaySportsEventCount,
  searchSpecialSportsEventList,
} from '../api/game/sportsGameApi'

// 경기 조회
export const fetchSportsEvents = createAsyncThunk('sportsGameBetting/fetchSportsEvents', async params => {
  const res = await searchSportsEventList(params)

  return res.data
})

// 스페셜 경기 조회
export const fetchSpecialSportsEvents = createAsyncThunk('sportsGameBetting/fetchSpecialSportsEvents', async params => {
  const res = await searchSpecialSportsEventList(params)

  return res.data
})

// 라이브 스포츠 경기 조회
export const fetchLiveSportsEvents = createAsyncThunk('sportsGameBetting/fetchLiveSportsEvents', async params => {
  const res = await searchLiveSportsEventList(params)

  return res.data
})

// 경기 종목 별 경기 수 조회
export const fetchSportsEventCount = createAsyncThunk('sportsGameBetting/fetchSportsEventCount', async params => {
  const res = await searchTodaySportsEventCount(params)

  return res.data
})

// 크로스 스포츠 다폴더 보너스 조회
export const fetchSportsBonusFolder = createAsyncThunk('sportsGameBetting/fetchSportsBonusFolder', async params => {
  const res = await searchSportsSettingInfo(params)

  return res.data
})

const createKey = item => {
  return `${item.sportsEventId}-${item.marketId}-${item.selectedSportsMarketOddKey}-${item.selectedOddName}-${item.name}`
}

// 배당 계산 (크로스, 스페셜  + 보너스 전용)
const calcBettingPer = items => {
  // 리스트를 받아서 계싼?
  const { CROSS, SPECIAL, BONUS } = items

  const allBettingCartItems = [...CROSS, ...SPECIAL, ...BONUS]

  if (allBettingCartItems.length === 0) return 0

  let result = Number(allBettingCartItems[0].selectedOddValue)
  for (let i = 1; i < allBettingCartItems.length; i++) {
    const value = Number(allBettingCartItems[i].selectedOddValue)
    result *= value
  }
  // 소수점 2자리 표기
  return Math.floor(result * 100) / 100
}

const calcBettingPerExcludeBonusPer = items => {
  // 리스트를 받아서 계싼?
  const { CROSS, SPECIAL, BONUS } = items

  const allBettingCartItems = [...CROSS, ...SPECIAL]

  if (allBettingCartItems.length === 0) return 0

  let result = Number(allBettingCartItems[0].selectedOddValue)
  for (let i = 1; i < allBettingCartItems.length; i++) {
    const value = Number(allBettingCartItems[i].selectedOddValue)
    result *= value
  }
  // 소수점 2자리 표기
  return Math.floor(result * 100) / 100
}

// 배당 계산 (라이브 전용)
const calcLiveBettingPer = items => {
  // 리스트를 받아서 계싼?
  const { LIVE } = items

  const allBettingCartItems = [...LIVE]

  if (allBettingCartItems.length === 0) return 0

  let result = Number(allBettingCartItems[0].selectedOddValue)
  for (let i = 1; i < allBettingCartItems.length; i++) {
    const value = Number(allBettingCartItems[i].selectedOddValue)
    result *= value
  }

  // 소수점 2자리 표기
  return Math.floor(result * 100) / 100
}

const sportsTypeInfo = [
  {
    sportsType: 'ALL',
    typeKr: 'ALL',
    count: 0,
  },
  {
    sportsType: 'SOCCER',
    typeKr: '축구',
    count: 0,
  },
  {
    sportsType: 'BASKETBALL',
    typeKr: '농구',
    count: 0,
  },
  {
    sportsType: 'BASEBALL',
    typeKr: '야구',
    count: 0,
  },
  {
    sportsType: 'VOLLEYBALL',
    typeKr: '배구',
    count: 0,
  },
  {
    sportsType: 'ICE_HOCKEY',
    typeKr: '아이스 하키',
    count: 0,
  },
  {
    sportsType: 'HANDBALL',
    typeKr: '핸드볼',
    count: 0,
  },
  {
    sportsType: 'TENNIS',
    typeKr: '테니스',
    count: 0,
  },
  {
    sportsType: 'AMERICAN_FOOTBALL',
    typeKr: '미식축구',
    count: 0,
  },
  {
    sportsType: 'TABLE_TENNIS',
    typeKr: '탁구',
    count: 0,
  },
  {
    sportsType: 'BOXING',
    typeKr: 'UFC',
    count: 0,
  },
  {
    sportsType: 'E_SPORTS',
    typeKr: 'E-스포츠',
    count: 0,
  },
]

const sportsGameBettingSlice = createSlice({
  name: 'sportsGameBetting',
  initialState: {
    maxChecked: false,
    minChecked: false,
    totalElement: 0,
    mainSportsEventList: [],
    crossMarkets: [],
    specialMarkets: [],
    liveSportsEventList: [],
    sportsTypeInfo,
    selectedSportsType: null,
    selectedSportsEventId: -1,
    bettingMoney: 0,
    bettingPer: 0,
    bettingPerExcludeBonusPer: 0,
    bettingCartList: {
      CROSS: [],
      SPECIAL: [],
      LIVE: [],
      BONUS: [],
    },
    loading: true,
    loadingInfo: '',
    bettingOkTimeCriteria: 0,
    bonusFolderNum: 0,
    bonusFolderInfo: [],
    bonusFolderPerCriteria: 0,
    combinationInfo: {},
    bettingCartAlertMessage: '',
    bettingCartUpdateMessage: '',
    processLiveBetting: false, // 라이브 배팅 API 요청 진행중인지
    marketTapActive: 'CROSS',
    sportsMixedCriteria: [], // 스포츠 조합
    reduceBettingPerForOneFolder: 0, // 단폴 배팅시 삭감되는 비율 (%)
  },
  reducers: {
    setSportsType: (state, action) => {
      state.selectedSportsType = action.payload
    },

    setMarketTapActive: (state, action) => {
      state.marketTapActive = action.payload
    },

    // 라이브 배팅 API 요청 진행중인지
    updateProcessLiveBetting: (state, action) => {
      state.processLiveBetting = action.payload
    },

    // 경기 추가 배팅 옵션 버튼 클릭시
    selectSportsEventOptionBtn: (state, action) => {
      const { key, sportsEventId, isMobile } = action.payload
      state.marketTapActive = 'CROSS'
      state.selectedSportsEventId = sportsEventId

      if (isMobile) {
        const updatedMainSportEventList = { ...state.mainSportsEventList }
        const idx = updatedMainSportEventList[key].sportsEvents.findIndex(item => item.sportsEventId === sportsEventId)
        if (idx !== -1) {
          const originAddNewOptionFlag = updatedMainSportEventList[key].sportsEvents[idx]?.addNewOptionFlag
          updatedMainSportEventList[key].sportsEvents[idx] = {
            ...updatedMainSportEventList[key].sportsEvents[idx],
            addNewOptionFlag: originAddNewOptionFlag ? !originAddNewOptionFlag : true,
          }
          state.mainSportsEventList = updatedMainSportEventList
        }
      }
    },

    // 경기별 메인 마켓 숨기기 클릭시
    onClickHideSportsEventMainMarket: (state, action) => {
      const { key, isVisible } = action.payload

      state.mainSportsEventList[key] = {
        ...state.mainSportsEventList[key],
        isVisible,
      }
    },

    // 경기별 라이브 스포츠 마켓 숨기기 클릭시
    onClickHideLiveSportsEventMarket: (state, action) => {
      const { sportsEventId, isVisible } = action.payload

      state.liveSportsEventList = state.liveSportsEventList.map(item => {
        if (item.sportsEventId === sportsEventId) {
          return { ...item, isVisible }
        }
        return item
      })
    },

    // 경기별 CROSS 마켓 숨기기 클릭시
    onClickHideSportsEventCrossMarket: (state, action) => {
      const { sportsEventId, marketId, isVisible } = action.payload

      state.crossMarkets = {
        ...state.crossMarkets,
        [sportsEventId]: {
          ...state.crossMarkets[sportsEventId],
          markets: state.crossMarkets[sportsEventId].markets.map(market => {
            if (market.marketId === marketId) {
              return { ...market, isVisible }
            }
            return market
          }),
        },
      }
    },

    // 경기별 SPECIAL 마켓 숨기기 클릭시
    onClickHideSportsEventSpecialMarket: (state, action) => {
      const { sportsEventId, marketId, isVisible } = action.payload

      state.specialMarkets = {
        ...state.specialMarkets,
        [sportsEventId]: {
          ...state.specialMarkets[sportsEventId],
          markets: state.specialMarkets[sportsEventId].markets.map(market => {
            if (market.marketId === marketId) {
              return { ...market, isVisible }
            }
            return market
          }),
        },
      }
    },

    // 배팅 최대 / 최소 라디오 버튼 클릭 핸들러
    onClickMaxMinRadioButtonHandler: (state, action) => {
      const { maxChecked, minChecked } = action.payload

      state.maxChecked = maxChecked
      state.minChecked = minChecked
    },

    // 배팅 카트에 담기 (크로스 + 스페셜 만)
    addBettingCart: (state, action) => {
      const { type, item } = action.payload

      const updatedBettingCartList = { ...state.bettingCartList }

      const { CROSS, SPECIAL, BONUS } = updatedBettingCartList

      if (type === 'CROSS') {
        // 동일한걸 선택시 삭제하기
        const equalIdx = CROSS.findIndex(cartItem => createKey(cartItem) === createKey(item))

        if (equalIdx !== -1) {
          updatedBettingCartList[type].splice(equalIdx, 1)
          state.bettingCartList = updatedBettingCartList

          state.bettingPer = calcBettingPer(state.bettingCartList)
          state.bettingPerExcludeBonusPer = calcBettingPerExcludeBonusPer(state.bettingCartList)
          return
        }

        // CROSS 선택 시 SPECIAL.length > 0 &&
        if (SPECIAL.some(si => si.sportsType === item.sportsType && si.sportsEventId === item.sportsEventId)) {
          // 해당 종목, 해당 경기에 스페셜 담은게 하나라도 있으면
          state.bettingCartAlertMessage = '크로스, 스페셜은 조합이 불가능합니다.'
          return
        }

        // 동일한 마켓을 담았을 경우
        const idx = CROSS.findIndex(cartItem => cartItem.sportsMarketKey === item.sportsMarketKey)

        if (idx !== -1) {
          // 동일한게 있는 경우 변경만
          updatedBettingCartList[type][idx] = { ...item, type }
          // 보너스 계산
          const allBettingCartItems = [...CROSS, ...SPECIAL]
          const filteredList = allBettingCartItems.filter(al => al.selectedOddValue > state.bonusFolderPerCriteria)
          if (filteredList.length < state.bonusFolderNum) {
            updatedBettingCartList.BONUS = []
          }
        } else {
          // 동일한게 없는 경우 같은 경기 기준 최대 폴더 수 체크하기
          const { crossFolderSize } = state.combinationInfo[item.sportsType]
          const count = CROSS.filter(crossItem => crossItem.sportsEventId === item.sportsEventId)

          if (count.length >= crossFolderSize) {
            state.bettingCartAlertMessage = '담을 수 있는 크로스 폴더 수를 넘었습니다.'
            return
          }

          if (count.length > 0) {
            const valuesToCheck = [count[0].marketId, item.marketId]

            const exists = state.sportsMixedCriteria.some(mixedItem =>
              valuesToCheck.every(value => mixedItem.includes(value)),
            )

            if (!exists) {
              state.bettingCartAlertMessage = `동일 경기내에서 [${count[0].marketName}] 와 [${item.marketName}] 는(은) 조합 불가능합니다.`
              return
            }
          }

          updatedBettingCartList[type] = [...updatedBettingCartList[type], { ...item, type, isLocked: false }]
          // 보너스 계산
          const allBettingCartItems = [...CROSS, ...SPECIAL]
          const filteredList = allBettingCartItems.filter(al => al.selectedOddValue > state.bonusFolderPerCriteria)
          if (filteredList.length < state.bonusFolderNum) {
            updatedBettingCartList.BONUS = []
          }
        }
      } else if (type === 'SPECIAL') {
        // SPECIAL 선택 시
        // 동일한걸 선택시 삭제하기
        const equalIdx = SPECIAL.findIndex(cartItem => createKey(cartItem) === createKey(item))

        if (equalIdx !== -1) {
          updatedBettingCartList[type].splice(equalIdx, 1)
          state.bettingCartList = updatedBettingCartList

          state.bettingPer = calcBettingPer(state.bettingCartList)
          state.bettingPerExcludeBonusPer = calcBettingPerExcludeBonusPer(state.bettingCartList)
          return
        }

        if (CROSS.some(si => si.sportsType === item.sportsType && si.sportsEventId === item.sportsEventId)) {
          // 크로스 담은게 하나라도 있으면
          state.bettingCartAlertMessage = '크로스, 스페셜은 조합이 불가능합니다.'
          return
        }
        // 동일한 마켓을 담았을 경우
        const idx = SPECIAL.findIndex(cartItem => cartItem.sportsMarketKey === item.sportsMarketKey)

        if (idx !== -1) {
          // 동일한게 있는 경우 변경만
          updatedBettingCartList[type][idx] = { ...item, type }
          // 보너스 계산
          const allBettingCartItems = [...CROSS, ...SPECIAL]
          const filteredList = allBettingCartItems.filter(al => al.selectedOddValue > state.bonusFolderPerCriteria)
          if (filteredList.length < state.bonusFolderNum) {
            updatedBettingCartList.BONUS = []
          }
        } else {
          // 동일한게 없는 경우 같은 경기 기준 최대 폴더 수 체크하기

          const { specialFolderSize } = state.combinationInfo[item.sportsType]
          const count = SPECIAL.filter(specialItem => specialItem.sportsEventId === item.sportsEventId)
          if (count.length >= specialFolderSize) {
            state.bettingCartAlertMessage = '담을 수 있는 스페셜 폴더 수를 넘었습니다.'
            return
          }

          if (count.length > 0) {
            const valuesToCheck = [count[0].marketId, item.marketId]

            const exists = state.sportsMixedCriteria.some(mixedItem =>
              valuesToCheck.every(value => mixedItem.includes(value)),
            )

            if (!exists) {
              state.bettingCartAlertMessage = `동일 경기내에서  [${count[0].marketName}] 와 [${item.marketName}] 는(은) 조합 불가능합니다.`
              return
            }
          }

          updatedBettingCartList[type] = [...updatedBettingCartList[type], { ...item, type, isLocked: false }]
          // 보너스 계산
          const allBettingCartItems = [...CROSS, ...SPECIAL]
          const filteredList = allBettingCartItems.filter(al => al.selectedOddValue > state.bonusFolderPerCriteria)
          if (filteredList.length < state.bonusFolderNum) {
            updatedBettingCartList.BONUS = []
          }
        }
      } else if (type === 'BONUS') {
        // .....
        // 동일한게 담기면 빼고
        const equalIdx = BONUS.findIndex(cartItem => cartItem.marketId === item.marketId)
        if (equalIdx !== -1) {
          updatedBettingCartList[type].splice(equalIdx, 1)
          state.bettingCartList = updatedBettingCartList

          state.bettingPer = calcBettingPer(state.bettingCartList)
          return
        }

        // 폴더 수가 맞는지
        const allBettingCartItems = [...CROSS, ...SPECIAL]

        const temp = item.selectedOddName.match(/^\d+/)
        if (!temp) {
          return
        }
        const bonusFolderNum = parseInt(temp[0], 10)

        // 폴더수 체크
        if (allBettingCartItems.length < bonusFolderNum) {
          state.bettingCartAlertMessage = `${bonusFolderNum}폴더 이상만 해당 보너스 선택이 가능합니다.`
          return
        }

        // 배당 체크
        const { bonusFolderPerCriteria } = state
        if (bonusFolderPerCriteria <= 0) {
          return
        }

        // 1.3 이하인 거 제외하고 보너스 폴더 이상이면 넘어가기
        const filteredList = allBettingCartItems.filter(al => al.selectedOddValue > bonusFolderPerCriteria)

        if (filteredList.length < bonusFolderNum) {
          state.bettingCartAlertMessage = `배당이 ${bonusFolderPerCriteria} 이하인 경우 보너스 선택이 불가합니다.`
          return
        }

        // 최종 반영
        updatedBettingCartList[type] = [{ ...item, type, isLocked: false }]
        state.bonusFolderNum = bonusFolderNum
      } else {
        return
      }

      state.bettingCartList = updatedBettingCartList

      state.bettingPer = calcBettingPer(state.bettingCartList)
      state.bettingPerExcludeBonusPer = calcBettingPerExcludeBonusPer(state.bettingCartList)
    },

    // 배팅 카트에 담기 (라이브 만)
    addLiveBettingCart: (state, action) => {
      const { type, item } = action.payload

      const updatedBettingCartList = { ...state.bettingCartList }

      const { LIVE } = updatedBettingCartList

      if (type === 'LIVE') {
        // 동일한걸 선택시 삭제하기
        const equalIdx = LIVE.findIndex(cartItem => createKey(cartItem) === createKey(item))

        if (equalIdx !== -1) {
          updatedBettingCartList[type].splice(equalIdx, 1)
          state.bettingCartList = updatedBettingCartList

          state.bettingPer = calcBettingPer(state.bettingCartList)
          return
        }

        // 동일한 마켓을 담았을 경우
        const idx = LIVE.findIndex(cartItem => cartItem.sportsMarketKey === item.sportsMarketKey)

        if (idx !== -1) {
          // 동일한게 있는 경우 변경만
          updatedBettingCartList[type][idx] = { ...item, type }
        } else {
          // 동일한게 없는 경우 같은 경기 기준 최대 폴더 수 체크하기
          const { liveFolderSize } = state.combinationInfo[item.sportsType]
          const count = LIVE.filter(crossItem => crossItem.sportsEventId === item.sportsEventId)
          if (count.length >= liveFolderSize) {
            state.bettingCartAlertMessage = '담을 수 있는 라이브 폴더 수를 넘었습니다.'
            return
          }

          if (count.length > 0) {
            const valuesToCheck = [count[0].marketId, item.marketId]

            const exists = state.sportsMixedCriteria.some(mixedItem =>
              valuesToCheck.every(value => mixedItem.includes(value)),
            )

            if (!exists) {
              state.bettingCartAlertMessage = `[${count[0].marketName}] 와 [${item.marketName}] 는(은) 조합 불가능합니다.`
              return
            }
          }

          updatedBettingCartList[type] = [...updatedBettingCartList[type], { ...item, type, isLocked: false }]
        }
      } else {
        return
      }

      state.bettingCartList = updatedBettingCartList

      state.bettingPer = calcLiveBettingPer(state.bettingCartList)
    },

    // 배팅 카트에서 제거
    removeBettingCart: (state, action) => {
      const { type, sportsMarketKey } = action.payload

      const typeCart = state.bettingCartList[type]

      const itemIdx = typeCart.findIndex(item => item.sportsMarketKey === sportsMarketKey)

      if (itemIdx !== -1) {
        // 담은거 삭제
        state.bettingCartList[type].splice(itemIdx, 1)

        // 보너스 배당 계산
        const { CROSS, SPECIAL } = { ...state.bettingCartList }

        const allBettingCartItems = [...CROSS, ...SPECIAL]

        const filteredList = allBettingCartItems.filter(al => al.selectedOddValue > state.bonusFolderPerCriteria)

        if (filteredList.length < state.bonusFolderNum) {
          state.bettingCartList.BONUS = []
        }

        if (type !== 'LIVE') {
          state.bettingPer = calcBettingPer(state.bettingCartList)
        } else {
          state.bettingPer = calcLiveBettingPer(state.bettingCartList)
        }
      }
    },

    // 게임 리스트 초기화 (다른 페이지 갔을 때)
    clearAllInfo: (state, action) => {
      state.maxChecked = false
      state.minChecked = false
      state.bettingCartList = {
        CROSS: [],
        SPECIAL: [],
        LIVE: [],
        BONUS: [],
      }
      state.bettingMoney = 0
      state.bettingPer = 0
      state.mainSportsEventList = []
      state.crossMarkets = []
      state.specialMarkets = []
      state.liveSportsEventList = []
      state.selectedSportsType = null
    },

    // 배팅 카트 비우기
    clearBettingCart: (state, action) => {
      state.maxChecked = false
      state.minChecked = false
      state.bettingCartList = {
        CROSS: [],
        SPECIAL: [],
        LIVE: [],
        BONUS: [],
      }
      state.bettingMoney = 0
      state.bettingPer = 0
    },

    // 배팅 관련 초기화
    resetBettingCart: (state, action) => {
      state.maxChecked = false
      state.minChecked = false
      state.bettingCartList = {
        CROSS: [],
        SPECIAL: [],
        LIVE: [],
        BONUS: [],
      }
      state.bettingMoney = 0
      state.bettingPer = 0
    },

    // 배팅 로딩 처리
    processBettingLoading: (state, action) => {
      const { loading, loadingInfo } = action.payload

      state.loading = loading
      state.loadingInfo = loadingInfo
    },

    // 배팅카트 Alert Close 처리
    onClickBettingCartAlertCloseHandler: (state, action) => {
      state.bettingCartAlertMessage = ''
    },

    // 배팅카트 Alert Close 처리
    onClickBettingCartUpdateMessageCloseHandler: (state, action) => {
      state.bettingCartUpdateMessage = ''
    },

    // 크로스 스포츠 - 웹소켓으로 인한 주기적인 데이터 변경
    handleWebSocketCrossSportsEvent: (state, action) => {
      const { serverTime, totalElement, requestSportsType, mainSportsEventList, crossMarkets, sortType } =
        action.payload

      if (state.selectedSportsType !== requestSportsType) return

      const currentTime = moment(serverTime).tz('Asia/Seoul')
      currentTime.add(state.bettingOkTimeCriteria, 'seconds')

      state.totalElement = totalElement

      const prevMainSportsEventList = { ...state.mainSportsEventList }

      state.mainSportsEventList = mainSportsEventList.reduce((grouped, sportsEvent) => {
        let key = `${sportsEvent.leagueId}-${sportsEvent.leagueName}-${sportsEvent.startAt}`
        if (sortType === 'LEAGUE_SORT') {
          key = `${sportsEvent.leagueId}-${sportsEvent.leagueName}`
        }
        if (!grouped[key]) {
          grouped[key] = {
            leagueId: sportsEvent.leagueId,
            leagueName: sportsEvent.leagueName,
            leagueImage: sportsEvent.leagueImage,
            ccKr: sportsEvent.ccKr,
            ccImage: sportsEvent.ccImage,
            sportsType: sportsEvent.sportsType,
            isVisible: prevMainSportsEventList[key]?.isVisible ?? true, // 수정된 부분
            sportsEvents: [],
          }
        }

        const prevSportsEvent = prevMainSportsEventList[key]?.sportsEvents?.find(
          item => item.sportsEventId === sportsEvent.sportsEventId,
        )

        grouped[key].sportsEvents.push({
          ...sportsEvent,
          isLocked: moment(sportsEvent.startAt).tz('Asia/Seoul') <= currentTime,
          addNewOptionFlag: prevSportsEvent?.addNewOptionFlag ?? false,
        })

        return grouped
      }, {})

      // 담은 값이 mainSportsEventLis에 없을 때 첫번재껄로 할당
      if (
        mainSportsEventList.length > 0 &&
        mainSportsEventList.findIndex(item => item.sportsEventId === state.selectedSportsEventId) === -1
      ) {
        state.selectedSportsEventId = mainSportsEventList[0].sportsEventId
      }

      state.crossMarkets = crossMarkets?.reduce((acc, item) => {
        const prevMarket = state.crossMarkets[item.sportsEventId]

        acc[item.sportsEventId] = {
          ...item,
          markets: item.markets.map(market => {
            const existedMarket = prevMarket?.markets.find(data => data.sportsMarketKey === market.sportsMarketKey)

            return {
              ...market,
              isLocked: moment(item.startAt).tz('Asia/Seoul') <= currentTime,
              isVisible: existedMarket ? existedMarket.isVisible : true,
            }
          }),
        }
        return acc
      }, {})
    },

    // 스페셜 스포츠 - 웹소켓으로 인한 주기적인 데이터 변경
    handleWebSocketSpecialSportsEvent: (state, action) => {
      const { serverTime, totalElement, requestSportsType, specialMarkets, sortType } = action.payload

      if (state.selectedSportsType !== requestSportsType) return

      const currentTime = moment(serverTime).tz('Asia/Seoul')
      currentTime.add(state.bettingOkTimeCriteria, 'seconds')

      state.totalElement = totalElement

      const prevMainSportsEventList = { ...state.mainSportsEventList }

      state.mainSportsEventList = specialMarkets.reduce((grouped, sportsEvent) => {
        let key = `${sportsEvent.leagueId}-${sportsEvent.leagueName}-${sportsEvent.startAt}`
        if (sortType === 'LEAGUE_SORT') {
          key = `${sportsEvent.leagueId}-${sportsEvent.leagueName}`
        }
        if (!grouped[key]) {
          grouped[key] = {
            leagueId: sportsEvent.leagueId,
            leagueName: sportsEvent.leagueName,
            leagueImage: sportsEvent.leagueImage,
            ccKr: sportsEvent.ccKr,
            ccImage: sportsEvent.ccImage,
            sportsType: sportsEvent.sportsType,
            isVisible: prevMainSportsEventList[key]?.isVisible ?? true, // 수정된 부분
            sportsEvents: [],
          }
        }

        const prevSportsEvent = prevMainSportsEventList[key]?.sportsEvents?.find(
          item => item.sportsEventId === sportsEvent.sportsEventId,
        )

        grouped[key].sportsEvents.push({
          ...sportsEvent,
          isLocked: moment(sportsEvent.startAt).tz('Asia/Seoul') <= currentTime,
          addNewOptionFlag: prevSportsEvent?.addNewOptionFlag ?? false,
        })

        return grouped
      }, {})

      // 담은 값이 mainSportsEventLis에 없을 때 첫번재껄로 할당
      if (
        specialMarkets.length > 0 &&
        specialMarkets.findIndex(item => item.sportsEventId === state.selectedSportsEventId) === -1
      ) {
        state.selectedSportsEventId = specialMarkets[0].sportsEventId
      }

      state.specialMarkets = specialMarkets?.reduce((acc, item) => {
        const prevMarket = state.specialMarkets[item.sportsEventId]
        acc[item.sportsEventId] = {
          ...item,
          markets: item.markets.map(market => {
            const existedMarket = prevMarket?.markets.find(data => data.sportsMarketKey === market.sportsMarketKey)

            return {
              ...market,
              isLocked: moment(item.startAt).tz('Asia/Seoul') <= currentTime,
              isVisible: existedMarket ? existedMarket.isVisible : true,
            }
          }),
        }
        return acc
      }, {})
    },

    // 라이브 스포츠 - 웹소켓으로 인한 주기적인 데이터 변경
    handleWebSocketLiveSportsEvent: (state, action) => {
      const { totalElement, liveSportsEventList } = action.payload

      state.totalElement = totalElement

      const prevLiveSportsEventList = [...state.liveSportsEventList]

      state.liveSportsEventList = liveSportsEventList.map(item => {
        const existedMainSportsEvent = prevLiveSportsEventList.find(data => data.sportsEventId === item.sportsEventId)

        return {
          ...item,
          isVisible: existedMainSportsEvent ? existedMainSportsEvent.isVisible : true, // 기존 데이터의 isVisible 값 또는 기본 펼치기 상태
        }
      })
    },

    // 웹소켓으로 인한 주기적인 배팅 카트 데이터 변경
    handleWebSocketSportsBettingCart: (state, action) => {
      const { content, serverTime } = action.payload

      if (!content) return

      const updateItem = item => {
        // 경기 시작 체크
        const currentTime = moment(serverTime).tz('Asia/Seoul')

        // 상태가 변경 되었는지
        const updatedItem = content.find(event => event.sportsMarketOddKey === item.selectedSportsMarketOddKey)

        if (updatedItem) {
          currentTime.add(state.bettingOkTimeCriteria, 'seconds')
          const validTime = moment(item.startAt).tz('Asia/Seoul') <= currentTime
          if (!item.isLocked && validTime) {
            state.bettingCartUpdateMessage = '선택된 경기중 변경된 사항이 있습니다.'
            return {
              ...item,
              isLocked: true,
            }
          }

          // 배당이 변경된 것
          if (updatedItem.oddValue !== item.selectedOddValue) {
            const { homeOddName, homeOddValue, awayOddName, awayOddValue } = item
            const isHomeOddMatch = homeOddName === updatedItem.oddName
            const isAwayOddMatch = awayOddName === updatedItem.oddName

            state.bettingCartUpdateMessage = '선택된 경기중 변경된 사항이 있습니다.'
            return {
              ...item,
              homeOddValue: isHomeOddMatch ? updatedItem.oddValue : homeOddValue,
              awayOddValue: isAwayOddMatch ? updatedItem.oddValue : awayOddValue,
              selectedOddValue: updatedItem.oddValue,
            }
          }

          if (
            !item.isLocked &&
            (validTime || updatedItem.marketStop || updatedItem.oddsStop || !updatedItem.showStatus)
          ) {
            state.bettingCartUpdateMessage = '선택된 경기중 변경된 사항이 있습니다.'
            return {
              ...item,
              isLocked: true,
            }
          }
          if (
            item.isLocked &&
            !validTime &&
            !updatedItem.marketStop &&
            !updatedItem.oddsStop &&
            updatedItem.showStatus
          ) {
            return {
              ...item,
              isLocked: false,
            }
          }
        }

        return item
      }

      const updateLiveItem = item => {
        // 상태가 변경 되었는지
        const updatedItem = content.find(event => event.sportsMarketOddKey === item.selectedSportsMarketOddKey)

        if (updatedItem) {
          // 배당이 변경된 것
          if (updatedItem.oddValue !== item.selectedOddValue) {
            const { homeOddName, homeOddValue, awayOddName, awayOddValue } = item
            const isHomeOddMatch = homeOddName === updatedItem.oddName
            const isAwayOddMatch = awayOddName === updatedItem.oddName

            if (!state.processLiveBetting) {
              state.bettingCartUpdateMessage = '선택된 경기중 변경된 사항이 있습니다.'
            }
            return {
              ...item,
              homeOddValue: isHomeOddMatch ? updatedItem.oddValue : homeOddValue,
              awayOddValue: isAwayOddMatch ? updatedItem.oddValue : awayOddValue,
              selectedOddValue: updatedItem.oddValue,
            }
          }

          if (!item.isLocked && (updatedItem.marketStop || updatedItem.oddsStop || !updatedItem.showStatus)) {
            if (!state.processLiveBetting) {
              state.bettingCartUpdateMessage = '선택된 경기중 변경된 사항이 있습니다.'
            }

            return {
              ...item,
              isLocked: true,
            }
          }
          if (item.isLocked && !updatedItem.marketStop && !updatedItem.oddsStop && updatedItem.showStatus) {
            return {
              ...item,
              isLocked: false,
            }
          }
        }

        return item
      }

      const updatedBettingCartList = {
        ...state.bettingCartList,
        CROSS: state.bettingCartList.CROSS.map(updateItem),
        SPECIAL: state.bettingCartList.SPECIAL.map(updateItem),
        LIVE: state.bettingCartList.LIVE.map(updateLiveItem),
      }

      state.bettingCartList = updatedBettingCartList

      if (state.bettingCartList.LIVE.length > 0) {
        state.bettingPer = calcLiveBettingPer(state.bettingCartList)
      } else {
        state.bettingPer = calcBettingPer(state.bettingCartList)
        state.bettingPerExcludeBonusPer = calcBettingPerExcludeBonusPer(state.bettingCartList)
      }
    },
  },
  extraReducers: builder => {
    // fetchSportsEvents API 요청시
    builder.addCase(fetchSportsEvents.pending, (state, action) => {
      state.loading = true // 요청 시작 시 로딩 상태를 true로 설정
    })

    // fetchSportsEvents API 응답시
    builder.addCase(fetchSportsEvents.fulfilled, (state, action) => {
      const { serverTime, totalElement, mainSportsEventList, crossMarkets, sortType } = action.payload

      const currentTime = moment(serverTime).tz('Asia/Seoul')
      currentTime.add(state.bettingOkTimeCriteria, 'seconds')

      state.totalElement = totalElement

      state.mainSportsEventList = mainSportsEventList?.reduce((grouped, sportsEvent) => {
        let key = `${sportsEvent.leagueId}-${sportsEvent.leagueName}-${sportsEvent.startAt}`
        if (sortType === 'LEAGUE_SORT') {
          key = `${sportsEvent.leagueId}-${sportsEvent.leagueName}`
        }
        if (!grouped[key]) {
          grouped[key] = {
            leagueId: sportsEvent.leagueId,
            leagueName: sportsEvent.leagueName,
            leagueImage: sportsEvent.leagueImage,
            ccKr: sportsEvent.ccKr,
            ccImage: sportsEvent.ccImage,
            sportsType: sportsEvent.sportsType,
            isVisible: true,
            sportsEvents: [],
          }
        }

        grouped[key].sportsEvents.push({
          ...sportsEvent,
          isLocked: moment(sportsEvent.startAt).tz('Asia/Seoul') <= currentTime,
        })

        return grouped
      }, {})

      state.crossMarkets = crossMarkets?.reduce((acc, item) => {
        acc[item.sportsEventId] = {
          ...item,
          markets: item.markets.map(market => ({
            ...market,
            isLocked: moment(item.startAt).tz('Asia/Seoul') <= currentTime,
            isVisible: true,
          })),
        }
        return acc
      }, {})

      if (mainSportsEventList.length > 0) {
        state.selectedSportsEventId = mainSportsEventList[0].sportsEventId
      }

      state.loading = false
    })

    // fetchSpecialSportsEvents API 요청시
    builder.addCase(fetchSpecialSportsEvents.pending, (state, action) => {
      state.loading = true // 요청 시작 시 로딩 상태를 true로 설정
    })

    // fetchSpecialSportsEvents API 응답시
    builder.addCase(fetchSpecialSportsEvents.fulfilled, (state, action) => {
      const { serverTime, totalElement, specialMarkets, sortType } = action.payload

      const currentTime = moment(serverTime).tz('Asia/Seoul')
      currentTime.add(state.bettingOkTimeCriteria, 'seconds')

      state.totalElement = totalElement

      state.mainSportsEventList = specialMarkets?.reduce((grouped, sportsEvent) => {
        let key = `${sportsEvent.leagueId}-${sportsEvent.leagueName}-${sportsEvent.startAt}`
        if (sortType === 'LEAGUE_SORT') {
          key = `${sportsEvent.leagueId}-${sportsEvent.leagueName}`
        }
        if (!grouped[key]) {
          grouped[key] = {
            leagueId: sportsEvent.leagueId,
            leagueName: sportsEvent.leagueName,
            leagueImage: sportsEvent.leagueImage,
            ccKr: sportsEvent.ccKr,
            ccImage: sportsEvent.ccImage,
            sportsType: sportsEvent.sportsType,
            isVisible: true,
            sportsEvents: [],
          }
        }

        grouped[key].sportsEvents.push({
          ...sportsEvent,
          isLocked: moment(sportsEvent.startAt).tz('Asia/Seoul') <= currentTime,
        })

        return grouped
      }, {})

      state.specialMarkets = specialMarkets?.reduce((acc, item) => {
        acc[item.sportsEventId] = {
          ...item,
          markets: item.markets.map(market => ({
            ...market,
            isLocked: moment(item.startAt).tz('Asia/Seoul') <= currentTime,
            isVisible: true,
          })),
        }
        return acc
      }, {})

      if (specialMarkets.length > 0) {
        state.selectedSportsEventId = specialMarkets[0].sportsEventId
      }

      state.loading = false
    })

    // fetchLiveSportsEvents API 요청시
    builder.addCase(fetchLiveSportsEvents.pending, (state, action) => {
      state.loading = true // 요청 시작 시 로딩 상태를 true로 설정
    })

    // fetchLiveSportsEvents API 응답시
    builder.addCase(fetchLiveSportsEvents.fulfilled, (state, action) => {
      const { totalElement, liveSportsEventList } = action.payload

      state.totalElement = totalElement

      state.liveSportsEventList = liveSportsEventList?.map(item => ({
        ...item,
        isVisible: true, // 기본 펼치기 상태
      }))

      state.loading = false
    })

    builder.addCase(fetchSportsEventCount.fulfilled, (state, action) => {
      const updatedSportsTypeInfo = [...state.sportsTypeInfo]
      let totalCount = 0
      action.payload.content.forEach(item => {
        const index = updatedSportsTypeInfo.findIndex(info => info.sportsType === item.sportsType)
        if (index !== -1) {
          totalCount += item.count
          updatedSportsTypeInfo[index] = item
        }
      })
      const index = updatedSportsTypeInfo.findIndex(info => info.sportsType === 'ALL')
      if (index !== -1) {
        updatedSportsTypeInfo[index].count = totalCount
      }
      state.sportsTypeInfo = updatedSportsTypeInfo
    })

    builder.addCase(fetchSportsBonusFolder.fulfilled, (state, action) => {
      const {
        bettingOkTimeCriteria,
        bonusFolders,
        bonusFolderPerCriteria,
        combinationInfo,
        sportsMixedCriteria,
        reduceBettingPerForOneFolder,
      } = action.payload

      state.bettingOkTimeCriteria = bettingOkTimeCriteria
      state.bonusFolderInfo = bonusFolders
      state.bonusFolderPerCriteria = Number(bonusFolderPerCriteria)
      state.combinationInfo = combinationInfo ? JSON.parse(combinationInfo) : []

      let result = []

      if (sportsMixedCriteria) {
        const parsedCriteria = JSON.parse(sportsMixedCriteria)
        result = parsedCriteria.map(item => [item.marketIdFirst, item.marketIdSecond])
      }

      state.sportsMixedCriteria = result

      state.reduceBettingPerForOneFolder = reduceBettingPerForOneFolder
    })
  },
})

export const {
  setSportsType,
  setMarketTapActive,
  updateProcessLiveBetting,
  selectSportsEventOptionBtn,
  onClickHideSportsEventMainMarket,
  onClickHideSportsEventCrossMarket,
  onClickHideSportsEventSpecialMarket,
  onClickHideLiveSportsEventMarket,
  onClickMaxMinRadioButtonHandler,
  addBettingCart,
  addLiveBettingCart,
  removeBettingCart,
  clearAllInfo,
  clearBettingCart,
  resetBettingCart,
  processBettingLoading,
  onClickBettingCartAlertCloseHandler,
  onClickBettingCartUpdateMessageCloseHandler,
  handleWebSocketCrossSportsEvent,
  handleWebSocketSpecialSportsEvent,
  handleWebSocketLiveSportsEvent,
  handleWebSocketSportsBettingCart,
} = sportsGameBettingSlice.actions

export default sportsGameBettingSlice.reducer
