import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const modalSlice = createSlice({
  name: 'modalInfo',
  initialState: {
    modalShow: false,
    modalTitle: '',
    isShowLeftMenuSidebar: true,
    mobileSignInShow: false,
  },
  reducers: {
    toggleLeftMenuSidebarShow: (state, action) => {
      state.isShowLeftMenuSidebar = action.payload
    },
    setModalShow: (state, action) => {
      // action.payload : true , false
      state.modalShow = action.payload
    },
    onShowModalHandler: (state, aciont) => {
      const { isModalShow, title } = aciont.payload

      state.modalShow = isModalShow
      state.modalTitle = title
    },

    onClickTitleHandler: (state, action) => {
      const newTitle = action.payload
      state.modalTitle = newTitle
    },
    onClickMobileSignInHandlerInSideBar: (state, action) => {
      state.mobileSignInShow = action.payload
    },
  },
})

export const {
  setModalShow,
  onShowModalHandler,
  onClickTitleHandler,
  toggleLeftMenuSidebarShow,
  onClickMobileSignInHandlerInSideBar,
} = modalSlice.actions

export default modalSlice.reducer
