import { Cookies } from 'react-cookie'

const cookies = new Cookies()

/* 쿠키 옵션에 대한 부분 
export interface CookieSetOptions {
    path?: string;
    expires?: Date;
    maxAge?: number;
    domain?: string;
    secure?: boolean;
    httpOnly?: boolean;
    sameSite?: boolean | 'none' | 'lax' | 'strict';
    encode?: (value: string) => string;
}
*/

/**
 * @Param : cookieName , value , option: CookieSetOptions (cookie 옵션)
 */
export const setCookie = (cookieName, value, option) => {
  return cookies.set(cookieName, value, option)
}

/**
 * @Param : cookieName
 * @Return : cookie Value
 */
export const getCookie = cookieName => {
  return cookies.get(cookieName)
}

/**
 * @Param : cookieName
 */
export const removeCookie = cookieName => {
  if (window.location.host.includes('localhost')) {
    return cookies.remove(cookieName, {
      path: '/',
    })
  }
  // eslint-disable-next-line no-useless-escape
  const domain = `.${window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1')}`

  return cookies.remove(cookieName, {
    path: '/',
    secure: true,
    domain,
    sameSite: 'none',
  })
}

// export const getAccessToken = () => {
//   // TODO : 수정 필요
//   // return getCookie('accessToken')
//   return localStorage.getItem('ur_at')
// }

export const getRefreshToken = () => {
  return getCookie('refreshToken')
}

export const removeToken = () => {
  removeCookie('accessToken')
  removeCookie('refreshToken')
}
