import moment from 'moment'
import 'moment/locale/ko'
import React, { useEffect, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { bettingVirtualGame } from '../../api/game/virtualGameApi'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import {
  onClickBettingCartAlertCloseHandler,
  onClickBettingCartUpdateMessageCloseHandler,
  removeAllBettingCartItem,
  removeBettingCartItem,
  setBettingCartLoading,
} from '../../redux/virtualGameBettingSlice'
import { VirtualGameBettingTypeKr } from '../../utils/enums/VirtualGame/VirtualGameEnums'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomAlert from '../CustomAlert'
import CustomLoading from '../CustomLoading'

const VirtualGameBettingCartSidebar = () => {
  const navigate = useNavigate()

  // redux 사용
  const dispatch = useDispatch()

  const location = useLocation()

  const [showBettingCart, setShowBettingCart] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const resultMatches = window.matchMedia('(max-width: 1440px)').matches
      setIsSmallScreen(resultMatches)
      if (!resultMatches) {
        setShowBettingCart(true)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const toggleBettingCart = () => {
    setShowBettingCart(prevShowBettingCart => !prevShowBettingCart)
  }

  const { holdingMoney, memberTotalGameMoney, userId, memberLevel, noteRedisSize, oneToOneRedisSize } = useSelector(
    state => {
      const { memberInfo } = state

      return {
        holdingMoney: memberInfo.memberHoldingMoney,
        memberTotalGameMoney: memberInfo.memberTotalGameMoney,
        userId: memberInfo.userId,
        memberLevel: memberInfo.depositLevel,
        noteRedisSize: memberInfo.noteRedisSize,
        oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      }
    },
  )

  const {
    selectedVirtuaGameSportType,
    virtualGameBettingInfos,
    marketCombinationList,
    totalBettingPer,
    bettingCartList,
    bettingCartAlertMessage,
    bettingCartUpdateMessage,
    bettingCartCount,
  } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      selectedVirtuaGameSportType: virtualGameBetting.selectedVirtuaGameSportType,
      virtualGameBettingInfos: virtualGameBetting.virtualGameBettingInfos,
      marketCombinationList: virtualGameBetting.marketCombinationList,
      totalBettingPer: virtualGameBetting.totalBettingPer,
      bettingCartList: virtualGameBetting.bettingCartList,
      bettingCartAlertMessage: virtualGameBetting.bettingCartAlertMessage,
      bettingCartUpdateMessage: virtualGameBetting.bettingCartUpdateMessage,
      bettingCartCount: virtualGameBetting.bettingCartList.length,
    }
  })

  const calculateTimeLeft = () => {
    const now = moment().tz('Asia/Seoul')

    bettingCartList.forEach(item => {
      const target = moment(item.fixture.startDate).subtract(item.fixture.bettingEndTime, 'seconds')
      const duration = moment.duration(target.diff(now))

      if (duration.asSeconds() <= 0) {
        dispatch(removeBettingCartItem(item))
      }
    })
  }

  useEffect(() => {
    if (bettingCartList.length === 0) return

    const timer = setInterval(calculateTimeLeft, 500)

    return () => {
      clearInterval(timer)
    }
  }, [bettingCartList])

  const [realVirtualGameBettingInfo, setRealVirtualGameBettingInfo] = useState({})

  useEffect(() => {
    setRealVirtualGameBettingInfo(virtualGameBettingInfos?.[selectedVirtuaGameSportType] ?? {})
  }, [virtualGameBettingInfos, selectedVirtuaGameSportType])

  const [inputBettingMoney, setInputBettingMoney] = useState('0')
  const [expectMoney, setExpectMoney] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)

  useEffect(() => {
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니

    let resultBettingMoney = inputRealMoney
    let resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)

    const maxBettingAmount = realVirtualGameBettingInfo?.maxBettingAmount || 0
    const maxWinningAmount = realVirtualGameBettingInfo?.maxWinningAmount || 0

    if (inputRealMoney > maxBettingAmount) {
      // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
      resultBettingMoney = maxBettingAmount
      // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
      if (resultExpectMoney > maxWinningAmount) {
        resultBettingMoney = Math.floor(maxWinningAmount / totalBettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)
      }
    }
    if (resultExpectMoney > maxWinningAmount) {
      // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
      resultBettingMoney = Math.floor((maxWinningAmount || 0) / totalBettingPer) // 비율에 따른 최대 배팅금액
      resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)
    }

    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))
    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [inputBettingMoney, totalBettingPer])

  const onClickMoneyHandler = amountText => {
    const textNum = amountText || holdingMoney

    setInputBettingMoney(prev => {
      const prevprevValue = prev ? prev.toString().replaceAll(',', '') : ''
      const prevValue = prevprevValue.startsWith('0') ? prevprevValue.slice(1) : prevprevValue
      if ((prevValue === '' || prevValue === '0') && (textNum === '0' || textNum === '00' || textNum === '000')) {
        return prevValue?.toString().replace(commonReg2, ',')
      }

      if (textNum.length > 3) {
        // UI키패드 버튼으로 5만 ~ 200만 까지 눌렀을때
        if (prevValue === '' || prevValue === '0') {
          return textNum?.toString().replace(commonReg2, ',')
        }
        // UI키패드 버튼으로 0,00,000 , 1~9까지 눌렀을때
        return (Number(prevValue) + Number(textNum))?.toString().replace(commonReg2, ',')
      }
      return (prevValue + textNum)?.toString().replace(commonReg2, ',')
    })
  }

  // 정정 버튼 클릭 핸들러
  const onClickResetMoneyHandler = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
  }

  // 하나 삭제
  const onCloseSportsBettingCart = bettingCartItem => {
    dispatch(removeBettingCartItem(bettingCartItem))
  }
  // 전체 삭제
  const onClearSportsBettingCart = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
    dispatch(removeAllBettingCartItem())
  }

  const onSubmit = e => {
    if (apiFlag) return
    setApiFlag(true)

    if (bettingCartList.length === 0) {
      alert('배팅할 마켓을 먼저 선택해주세요.')
      setApiFlag(false)
      return
    }

    const maxBettingPer = realVirtualGameBettingInfo?.maxBettingPer || 0

    if (totalBettingPer > maxBettingPer) {
      alert(`최대 ${maxBettingPer}배당까지 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    if (Number(holdingMoney) < Number(inputBettingMoney?.toString().replaceAll(',', ''))) {
      alert('지갑머니가 부족합니다.')
      setApiFlag(false)
      return
    }

    const minBettingAmount = realVirtualGameBettingInfo?.minBettingAmount || 0

    if (Number(inputBettingMoney?.toString().replaceAll(',', '')) < Number(minBettingAmount)) {
      alert('최소 배팅금액 이상으로 가능 합니다.')
      setApiFlag(false)
      return
    }

    const maxBettingAmount = realVirtualGameBettingInfo?.maxBettingAmount || 0

    if (Number(inputBettingMoney) > Number(maxBettingAmount)) {
      alert('최대 배팅금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    const maxWinningAmount = realVirtualGameBettingInfo?.maxWinningAmount || 0

    if (Number(expectMoney?.toString().replaceAll(',', '')) > Number(maxWinningAmount)) {
      alert('최대 당첨금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (window.confirm('배팅하시겠습니까?')) {
      const body = {
        bettingAmount: Number(inputBettingMoney?.toString().replaceAll(',', '')),
        sportType: bettingCartList[0].fixture.sportType,
        betInfoList: bettingCartList.map(cartItem => {
          return {
            leagueType: cartItem.fixture.leagueType,
            eventId: cartItem.fixture.eventId,
            marketKey: cartItem.marketInfo.marketKey,
            betType: cartItem.selectedBetInfo.betType,
            raceNumber: cartItem.selectedBetInfo?.raceNumber || null,
            raceName: cartItem.selectedBetInfo?.raceName || null,
            raceRider: cartItem.selectedBetInfo?.raceRider || null,
            raceImageUrl: cartItem.selectedBetInfo?.raceImageUrl || null,
          }
        }),
      }

      dispatch(setBettingCartLoading(true))
      // API 호출
      bettingVirtualGame(body)
        .then(res => {
          alert('배팅 성공했습니다.')
        })
        .catch(error => {
          const errorCode = error?.response?.data?.errorCode ?? ''

          switch (errorCode) {
            case 'SYSTEM-1017':
              alert('보유한 머니가 충분하지 않아 배팅이 거절됩니다.')
              break
            case 'VIRTUAL-1009':
              alert('최대 배당 조건에 부합되지 않아 배팅이 거절됩니다.')
              break
            case 'VIRTUAL-1010':
              alert('최소 배팅액 조건에 부합되지 않아 배팅이 거절됩니다.')
              break
            case 'VIRTUAL-1011':
              alert('최대 배팅액 조건에 부합되지 않아 배팅이 거절됩니다.')
              break
            case 'VIRTUAL-1012':
              alert('최대 당첨액 조건에 부합되지 않아 배팅이 거절됩니다.')
              break
            case 'VIRTUAL-1013':
              alert('마감된 경기가 있어 거절됩니다.')
              break
            case 'VIRTUAL-1014':
              alert('추가 배팅이 불가능한 배팅건이 있어 거절됩니다.')
              break
            default:
              alert('배팅 실패했습니다. 잠시후 다시 시도해주세요.')
          }

          dispatch(setBettingCartLoading(false))
          setApiFlag(false)
        })
        .finally(() => {
          dispatch(setBettingCartLoading(false))
          setApiFlag(false)
          onClickResetMoneyHandler()
          dispatch(removeAllBettingCartItem())
          dispatch(fetchMemberInfoAction())
        })
    } else {
      dispatch(setBettingCartLoading(false))
      setApiFlag(false)
    }
  }

  useEffect(() => {
    // 다른페이지 배팅카트 초기화
    onClearSportsBettingCart()
  }, [location])

  const onClickCloseHandler = () => {
    dispatch(onClickBettingCartAlertCloseHandler())
  }

  const onClickBettingCartUpdateCloseHandler = () => {
    dispatch(onClickBettingCartUpdateMessageCloseHandler())
  }

  const getPriceBox = item => {
    // 핸디
    if (item.marketInfo.marketId === 3) {
      return (
        <span>
          {item.marketInfo.marketName}({item.fixture.handicapPoint.toFixed(1)}) -{' '}
          {VirtualGameBettingTypeKr[item.selectedBetInfo.betType]}
        </span>
      )
    }

    // 오버언더
    if (item.marketInfo.marketId === 4) {
      return (
        <span>
          {item.marketInfo.marketName}({item.fixture.overUnderPoint.toFixed(1)}) -{' '}
          {VirtualGameBettingTypeKr[item.selectedBetInfo.betType]}
        </span>
      )
    }

    // 우승 맞추기
    if (item.marketInfo.marketId === 8) {
      return (
        <span>
          {item.marketInfo.marketName}, {item.selectedBetInfo.raceNumber}-{item.selectedBetInfo.raceName}
        </span>
      )
    }

    return (
      <span>
        {item.marketInfo.marketName} - {VirtualGameBettingTypeKr[item.selectedBetInfo.betType]}
      </span>
    )
  }

  const getBetTeamNameBox = item => {
    const { sportType } = item.fixture

    const notRaceTypes = ['VIRTUAL_SOCCER', 'VIRTUAL_BASKETBALL', 'VIRTUAL_BASEBALL']
    const raceTypes = ['VIRTUAL_GREYHOUNDS', 'VIRTUAL_HORSE']

    if (notRaceTypes.includes(sportType)) {
      return <>{item.fixture?.homeName && `${item.fixture.homeName} vs ${item.fixture.awayName}`}</>
    }

    if (raceTypes.includes(sportType)) {
      return <>{item.fixture.leagueName}</>
    }
  }

  const [scrollY, setScrollY] = useState(0)
  const betCartRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      if (!betCartRef.current) return

      const szNowTop = parseInt(betCartRef.current.style.top || '0', 10)
      const nBetween = scrollTop - szNowTop

      if (nBetween !== 0) {
        let newContentHeight = scrollTop
        if (scrollTop > 165) {
          newContentHeight = scrollTop - 165
        } else {
          newContentHeight = 0
        }
        setScrollY(newContentHeight)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      {apiFlag && <CustomLoading />}
      {bettingCartAlertMessage && (
        <CustomAlert info={bettingCartAlertMessage} onClickCloseHandler={onClickCloseHandler} />
      )}
      {bettingCartUpdateMessage && (
        <CustomAlert info={bettingCartUpdateMessage} onClickCloseHandler={onClickBettingCartUpdateCloseHandler} />
      )}

      <div
        className="bet_cart"
        id="bet_cart"
        style={{ top: `${scrollY}px`, transition: 'all 0.3s linear' }}
        ref={betCartRef}
      >
        <div className="body">
          <div className="bet_cart_title">
            <table style={{ width: '100%', height: '100%' }} className="tb_basic">
              <tbody>
                <tr>
                  <td>&nbsp;&nbsp;BETTING CART</td>
                  <td style={{ width: '35px', textAlign: 'right' }}>
                    <a
                      style={{ color: '#000' }}
                      onClick={() => {
                        onClearSportsBettingCart()
                      }}
                    >
                      삭제
                    </a>
                  </td>
                  <td style={{ width: '10px' }} />
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ height: '2px' }} />

          {/* bet item */}
          {bettingCartList.map(item => {
            const isLock = false

            return (
              <div>
                <div className="bet_item">
                  {isLock && (
                    <BettingWhatWrapOverlay>
                      <BettingWhatLockIcon />
                    </BettingWhatWrapOverlay>
                  )}
                  <div style={{ height: '16px', lineHeight: '16px' }}>
                    <span className="team" id="phn0">
                      {getBetTeamNameBox(item)}
                    </span>
                    <b>
                      <a
                        onClick={() => {
                          onCloseSportsBettingCart(item)
                        }}
                      >
                        <img src={`${process.env.PUBLIC_URL}/img/common/button_bet_del.png`} alt="" />
                      </a>
                    </b>
                  </div>
                  <div style={{ height: '1px', clear: 'both' }} />
                  <div style={{ height: '16px', lineHeight: '16px' }}>
                    {getPriceBox(item)}
                    <b>
                      &nbsp;<span id="pr0">{item.selectedBetInfo.betPrice}</span>
                    </b>
                  </div>
                </div>
              </div>
            )
          })}

          {/* 보유액 */}
          <div style={{ height: '1px' }} />
          <div className="back">
            <div className="bet_info_box">
              <div className="height_30">
                <span>보유액 &gt;&gt;</span>
                <b>{holdingMoney?.toString().replace(commonReg2, ',')} 원</b>
              </div>
              <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />
              <div className="height_30">
                <span>배당률 &gt;&gt;</span>
                <b>{totalBettingPer || 0}</b>
              </div>
              <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

              <div className="height_30">
                <span>배팅액 &gt;&gt;</span>
                <b>
                  <a
                    className="btMax"
                    style={{ width: '40px', marginRight: '3px' }}
                    onClick={() => {
                      onClickMoneyHandler('')
                    }}
                  >
                    MAX
                  </a>
                  <input
                    type="text"
                    className="input_money_box"
                    style={{ width: '80px', height: '21px' }}
                    value={inputBettingMoney}
                    onChange={e => {
                      const value = e.target.value.trim()
                      const numbersOnly = value.match(/\d+/g)?.join('')

                      if (!numbersOnly) {
                        setInputBettingMoney('0')
                      } else {
                        setInputBettingMoney(numbersOnly.replace(/^0+/, '').replace(commonReg2, ','))
                      }
                    }}
                  />
                </b>
              </div>
              <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

              <div className="height_30">
                <span>적중액 &gt;&gt;</span>
                <b>{expectMoney?.toString().replace(commonReg2, ',') || 0} 원</b>
              </div>
              <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

              <>
                <div className="height_30">
                  <span style={{ letterSpacing: '-1px' }}> 최소 배팅금액 &gt;&gt;</span>
                  <b>{realVirtualGameBettingInfo?.minBettingAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
                </div>
                <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />
                <div className="height_30">
                  <span style={{ letterSpacing: '-1px' }}> 최대 배팅금액 &gt;&gt;</span>
                  <b>{realVirtualGameBettingInfo?.maxBettingAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
                </div>
                <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

                <div className="height_30">
                  <span style={{ letterSpacing: '-1px' }}> 최대 당첨금액 &gt;&gt;</span>
                  <b>{realVirtualGameBettingInfo?.maxWinningAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
                </div>
                <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

                <div className="height_30">
                  <span style={{ letterSpacing: '-1px' }}> 최대 배팅배당 &gt;&gt;</span>
                  <b>{realVirtualGameBettingInfo?.maxBettingPer?.toString().replace(commonReg2, ',') || 0} 배</b>
                </div>
                <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />
              </>
            </div>
          </div>

          {/* 금액 버튼 */}
          <div style={{ height: '3px' }} />
          <div className="back">
            <div className="height_5" />
            <div className="bet_info_box">
              <div style={{ height: '1px' }} />
              <div style={{ textAlign: 'center' }}>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickMoneyHandler('10000')
                  }}
                >
                  1만원
                </a>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickMoneyHandler('50000')
                  }}
                >
                  5만원
                </a>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickMoneyHandler('100000')
                  }}
                >
                  10만원
                </a>
              </div>
              <div style={{ height: '3px' }} />
              <div style={{ textAlign: 'center' }}>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickMoneyHandler('500000')
                  }}
                >
                  50만원
                </a>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickMoneyHandler('1000000')
                  }}
                >
                  100만원
                </a>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickResetMoneyHandler()
                  }}
                >
                  초기화
                </a>
              </div>
              <div style={{ height: '1px' }} />
            </div>
            <div className="height_5" />
          </div>

          {/* 배팅 버튼 */}
          <div style={{ height: '3px' }} />
          <div className="back">
            <div style={{ height: '8px' }} />
            <div style={{ textAlign: 'center' }}>
              <a
                className="btBet_Money"
                style={{ width: '210px', lineHeight: '34px', height: '34px' }}
                onClick={() => {
                  onSubmit()
                }}
              >
                배팅하기
              </a>
            </div>
            <div style={{ height: '8px' }} />
          </div>
        </div>
      </div>
    </>
  )
}

export default VirtualGameBettingCartSidebar

const BettingWhatWrapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100vh; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
`

const BettingWhatLockIcon = styled(FaLock)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`
