import { convertToKstByFormat } from '@/utils/dateTime'
import { MemberPointDepositReceiverTypeEnums } from '@/utils/enums/MyPage/myPageEnums'
import { commonReg2 } from '@/utils/validate/commonValidate'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MobilePointDepositListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 25,
    },
    {
      Header: '지급일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 90,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstByFormat(original.executeDate, 'MM-DD HH:mm')
      },
    },
    {
      Header: '구분',
      accessor: 'receiverType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 70,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MemberPointDepositReceiverTypeEnums[original.receiverType]
      },
    },
    {
      Header: '지급액',
      accessor: 'changePoint',
      disableGlobalFilter: true,
      width: 50,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changePoint.toString().replace(commonReg2, ',')
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MobilePointDepositListData
