import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import CustomLoading from '../../../containers/CustomLoading'
import { setSelectedVirtuaGameSportType } from '../../../redux/virtualGameBettingSlice'
import { setResetVirtualFixtures } from '../../../redux/virtualGameInfoSlice'
import { VirtualGameSportTypeParam } from '../../../utils/enums/VirtualGame/VirtualGameEnums'
import MobileVirtualBettingCartSidebar from '../../MobileLayout/MobileVirtualBettingCartSidebar'
import MobileVirtualGameHeader from '../MobileVirtualGameHeader'
import MobileVirtualGreyhoundsContent from './MobileVirtualGreyhoundsContent'

const MobileVirtualGreyhoundsPage = () => {
  const dispatch = useDispatch()

  const { virtualGameLoading } = useSelector(state => {
    const { virtualGameInfo } = state

    return {
      virtualGameLoading: virtualGameInfo.virtualGameLoading,
    }
  })

  const { bettingCartLoading } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      bettingCartLoading: virtualGameBetting.bettingCartLoading,
    }
  })

  useEffect(() => {
    dispatch(setSelectedVirtuaGameSportType(VirtualGameSportTypeParam.VIRTUAL_GREYHOUNDS))

    return () => {
      dispatch(setSelectedVirtuaGameSportType(null))
      dispatch(setResetVirtualFixtures(null))
    }
  }, [])

  return (
    <>
      {(virtualGameLoading || bettingCartLoading) && (
        <VirtualGamePageLoadingOverlay>
          <CustomLoading isGameStart info={bettingCartLoading ? '배팅 처리중입니다.' : ''} />
        </VirtualGamePageLoadingOverlay>
      )}
      <div>
        <MobileVirtualGameHeader />
        <MobileVirtualGreyhoundsContent />

        <MobileVirtualBettingCartSidebar />
      </div>
    </>
  )
}

export default MobileVirtualGreyhoundsPage

const VirtualGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1011;
  width: 100%;
  height: 100vh; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const VirtualGamePageWrap = styled.div`
  width: 100%;
`

const VirtualGamePageContentWrap = styled.div`
  border-radius: 0 0 3px 3px;
  background: #111314;
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Malgun Gothic', dotum;

  padding-bottom: 10px;
`
