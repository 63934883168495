import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 회원 머니입금내역 조회 시작
export async function searchMemberYesterdayAttendance(params) {
  const response = await axios({
    url: '/tyson/member/v1/attendance/yesterday',
    method: 'GET',
    params,
  })
    .then(res => {
      return res
    })
    .catch(error => {
      return error
    })

  return response
}

// 회원 머니입금내역 조회 시작
export async function searchMemberAttendanceHistory(params) {
  const response = await axios({
    url: '/tyson/member/v1/attendance/history',
    method: 'GET',
    params,
  })
    .then(res => {
      return res
    })
    .catch(error => {
      return error
    })

  return response
}

// 회원 머니입금내역 조회 시작
export async function searchMemberMoneyDeposit(params) {
  const response = await axios({
    url: '/tyson/member/v1/asset/money/deposit',
    method: 'GET',
    params,
  })
    .then(res => {
      return res
    })
    .catch(error => {
      return error
    })

  return response
}

export function useSearchMemberMoneyDeposit(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberMoneyDeposit() {
    await searchMemberMoneyDeposit(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberMoneyDeposit()
  }, [params])

  return { totalElement, content, fetchSearchMemberMoneyDeposit }
}
// 회원 머니입금내역 조회 끝

// 회원 머니출금내역 조회 시작
export async function searchMemberMoneyWithdrawal(params) {
  const response = await axios({
    url: '/tyson/member/v1/asset/moneywithdrawal',
    method: 'GET',
    params,
  })
    .then(res => {
      return res
    })
    .catch(error => {
      return error
    })

  return response
}

export function useSearchMemberMoneyWithdrawal(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberMoneyWithdrawal() {
    await searchMemberMoneyWithdrawal(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberMoneyWithdrawal()
  }, [params])

  return { totalElement, content, fetchSearchMemberMoneyWithdrawal }
}
// 회원 머니출금내역 조회 끝

// 회원 머니이동내역 조회 시작
export async function searchMemberMoneyExchange(params) {
  const response = await axios({
    url: '/tyson/member/v1/asset/money-exchange',
    method: 'GET',
    params,
  })
    .then(res => {
      return res
    })
    .catch(error => {
      return error
    })

  return response
}

export function useSearchMemberMoneyExchange(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberMoneyExchange() {
    await searchMemberMoneyExchange(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberMoneyExchange()
  }, [params])

  return { totalElement, content, fetchSearchMemberMoneyExchange }
}
// 회원 머니이동내역 조회 끝

// 회원 포인트 입금내역 조회 시작
export async function searchMemberPointDeposit(params) {
  const response = await axios({
    url: '/tyson/member/v1/member/history/point-deposit',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberPointDeposit(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberPointDeposit() {
    await searchMemberPointDeposit(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberPointDeposit()
  }, [params])

  return { totalElement, content, fetchSearchMemberPointDeposit }
}
// 회원 포인트 입금내역 조회 끝

// 회원 포인트 전환내역 조회 시작
export async function searchMemberPointExchange(params) {
  const response = await axios({
    url: '/tyson/partner/v1/asset/user/pointexchange',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberPointExchange(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberPointExchange() {
    await searchMemberPointExchange(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberPointExchange()
  }, [params])

  return { totalElement, content, fetchSearchMemberPointExchange }
}
// 회원 포인트 전환내역 조회 끝

// 쿠폰관리 - 쿠폰사용내역
export async function searchCouponUseList(params) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/member-couponuselist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

export function useSearchCouponUseList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchCouponUseList() {
    await searchCouponUseList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchCouponUseList()
  }, [params])

  return { totalElement, content, fetchSearchCouponUseList }
}

// 지인조회
export async function searchFriendList(params) {
  const response = await axios({
    url: '/tyson/member/v1/member/friendlist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchFriendList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchFriendList() {
    await searchFriendList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchFriendList()
  }, [params])

  return { totalElement, content, fetchSearchFriendList }
}
