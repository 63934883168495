import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { deleteCasinoBettingHistory, searchCasinoBettingHistory } from '../../../api/game/gameApi'
import MobileCustomPagination from '../../../shared/components/MobileCustomPagination'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import CasinoBettingHistoryRadio from './MobileCasinoBettingHistoryRadio'
import { MoneyFormModifyButtomInfo } from '../../../shared/components/MoneyCustomElement'
import CustomLoading from '../../../containers/CustomLoading'
import { HermesMainText } from '../../../utils/palette'

const MobileCasinoBettingHistoryPage = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [boardParams, setBoardParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElements, setTotalElements] = useState(0)
  const [content, setContent] = useState([])

  const fetchCasinoBettingHistory = async params => {
    await searchCasinoBettingHistory(params)
      .then(res => {
        setTotalElements(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchCasinoBettingHistory(boardParams)
  }, [boardParams])

  /**
   * 페이징처리
   */
  const casinoBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (casinoBettingHistoryWrapRef.current) {
      casinoBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)

  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteCasinoBettingHistory({
        casinoBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchCasinoBettingHistory(boardParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchCasinoBettingHistory(boardParams)
        })
    }
  }
  //   <CustomerCenterHeaderWrap>
  //   <CustomerCenterHeaderInfo>배팅내역</CustomerCenterHeaderInfo>
  //   <ListButton
  //     onClick={() => {
  //       setCheckRadio(content.map(value => value.bettingId))
  //     }}
  //     type="button"
  //   >
  //     <MoneyFormModifyButtomInfo>전체선택</MoneyFormModifyButtomInfo>
  //   </ListButton>
  //   <ListButton onClick={() => onDeleteHandler()} variant="primary" type="button">
  //     <MoneyFormModifyButtomInfo>삭제하기</MoneyFormModifyButtomInfo>
  //   </ListButton>
  // </CustomerCenterHeaderWrap>
  return (
    <>
      {loading ? (
        <CustomLoading />
      ) : (
        <BettingHistoryTableWrap className="sports-container" ref={casinoBettingHistoryWrapRef}>
          {content.length === 0 ? (
            <NoContentDiv>
              <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
              카지노 배팅내역이 없습니다.
              {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
            </NoContentDiv>
          ) : (
            <>
              {content.map((item, index) => (
                <BettingHistoryTable key={item.bettingId}>
                  <BettingHistoryTopHeaderTr>
                    <CasinoBettingHistoryRadio
                      casinoBettingLogKey={item.bettingId}
                      checkRadio={checkRadio}
                      setCheckRadio={setCheckRadio}
                    />
                    배팅시간 : {convertToKstByFormat(item.bettingTime, 'MM-DD HH:mm')}
                  </BettingHistoryTopHeaderTr>
                  <BettingHistoryHeaderTr>
                    <th style={{ width: '25%' }}>배팅일</th>
                    <th style={{ width: '25%' }}>게임명</th>

                    <th style={{ width: '25%' }}>배팅액</th>
                    <th style={{ width: '25%' }}>당첨금</th>
                  </BettingHistoryHeaderTr>
                  <BettingHistoryTableBody>
                    <BettingHistoryTableTr>
                      <BettingHistoryContentBox>
                        <BettingHistoryContentItemBox width="25">
                          {convertToKstByFormat(item.bettingTime, 'MM-DD HH:mm')}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="25">{item.gameName}</BettingHistoryContentItemBox>

                        <BettingHistoryContentItemBox width="25">
                          {item.bettingAmount?.toString().replace(commonReg2, ',')}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox
                          width="25"
                          style={{ color: `${HermesMainText}`, fontWeight: '600' }}
                        >
                          {item.bettingResultAmount?.toString().replace(commonReg2, ',')}
                        </BettingHistoryContentItemBox>
                      </BettingHistoryContentBox>
                    </BettingHistoryTableTr>
                  </BettingHistoryTableBody>
                </BettingHistoryTable>
              ))}
            </>
          )}
          {content.length === 0 ? (
            <></>
          ) : (
            <>
              <ListWrap>
                <CustomDeleteButton
                  onClick={() => {
                    setCheckRadio(content.map(value => value.bettingId))
                  }}
                  type="button"
                >
                  <CustomButtonInfos>전체선택</CustomButtonInfos>
                </CustomDeleteButton>
                <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
                  <CustomButtonInfos>삭제하기</CustomButtonInfos>
                </CustomDeleteButton>
              </ListWrap>
              {totalElements > 0 && (
                <MobileCustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
              )}
            </>
          )}
        </BettingHistoryTableWrap>
      )}
    </>
  )
}

export default MobileCasinoBettingHistoryPage

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 100px;
    height: auto;
  }
`

export const CustomButtonInfos = styled(MoneyFormModifyButtomInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  padding: 0px 8px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    color: grey;
  }
`

const ListWrap = styled.div`
  display: flex;
  text-align: right;
  align-items: right;
  justify-content: right;
  padding-right: 10px;
  gap: 10px;
`

const BettingHistoryHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: rgba(48, 48, 48, 1);
  // border-left: solid 1px gray;
  // border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  align-items: center;
`

const BettingHistoryTopHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  gap: 20px;
  background: black;
  // border-left: solid 1px gray;
  // border-right: solid 1px gray;
  // border-top: solid 1px gray;
  height: 25px;
  align-items: center;
  padding: 0px 5px;
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  // font-family: 'Pretendard', Dotum, Helvetica, 'Apple SD Gothic Neo', Sans-serif;
  // font-weight: 600;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    background: rgba(48, 48, 48, 1);
    padding: 2px 2px;
    border-top: 1px solid #888;
    // border-right: 1px solid #888;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead``

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  // border-left: solid 1px gray;
  // border-bottom: solid 1px gray;
  &:last-child {
    border-bottom: solid 1px gray;
  }
`

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 11px;
  font-weight: 300;

  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: #b88907;
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NoContent = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%;
  color: white;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    height: 40px;
    padding: 4px 4px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
