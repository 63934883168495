import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { deleteCasinoBettingHistory, searchCasinoBettingHistory } from '../../../api/game/gameApi'
import { CustomButtonInfos } from '../../../mobile/mobileBettingHistory/MobileMiniGameBettingHistory'
import { CustomDeleteButton } from '../../../mobile/mobileCasinoGame/MobileLiveCasino'
import CustomPagination from '../../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { HermesMainBorder } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import CustomLoading from '../../CustomLoading'
import CasinoBettingHistoryRadio from './CasinoBettingHistoryRadio'

const CasinoBettingHistoryPageV2 = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [boardParams, setBoardParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElements, setTotalElements] = useState(0)
  const [content, setContent] = useState([])

  const fetchCasinoBettingHistory = async params => {
    await searchCasinoBettingHistory(params)
      .then(res => {
        setTotalElements(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }
  console.log()
  useEffect(() => {
    fetchCasinoBettingHistory(boardParams)
  }, [boardParams])

  /**
   * 페이징처리
   */
  const casinoBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (casinoBettingHistoryWrapRef.current) {
      casinoBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)

  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteCasinoBettingHistory({
        casinoBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchCasinoBettingHistory(boardParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchCasinoBettingHistory(boardParams)
        })
    }
  }

  return (
    <>
      <BettingHistoryTableWrap className="div_Bet_List" ref={casinoBettingHistoryWrapRef}>
        {loading && <CustomLoading />}
        <ListWrap>
          <div style={{ display: 'flex', gap: '5px', marginTop: '15px' }}>
            <a
              className="normal_button"
              style={{ width: '66px' }}
              onClick={() => {
                setCheckRadio(content.map(value => value.bettingId))
              }}
            >
              전체선택
            </a>
            <a className="normal_button" style={{ width: '66px' }} onClick={() => onDeleteHandler()}>
              삭제하기
            </a>
            {/* <CustomDeleteButton
              
              type="button"
            >
              <CustomButtonInfos>전체선택</CustomButtonInfos>
            </CustomDeleteButton>
            <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <CustomButtonInfos>삭제하기</CustomButtonInfos>
            </CustomDeleteButton> */}
          </div>
        </ListWrap>
        {content.length === 0 ? (
          <NoContentDiv>
            <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
            카지노 배팅내역이 없습니다.
            {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
          </NoContentDiv>
        ) : (
          <div className="bet_group" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {content.map((item, index) => (
              <table style={{ textAlign: 'center' }} className="tb_bet_list">
                <colgroup>
                  <col style={{ width: '3%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '16%' }} />
                  <col style={{ width: '16%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>배팅시간</th>
                    <th>게임사</th>
                    <th>게임명</th>
                    <th>배팅액</th>
                    <th>당첨금</th>
                    <th>배팅후 잔액</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <CasinoBettingHistoryRadio
                        casinoBettingLogKey={item.bettingId}
                        checkRadio={checkRadio}
                        setCheckRadio={setCheckRadio}
                      />
                    </td>
                    <td>{convertToKstByFormat(item.bettingTime, 'MM-DD HH:mm')}</td>
                    <td style={{ color: '#000', background: 'linear-gradient(#fff, #f5fcff)' }}>{item.vendorName}</td>
                    <td style={{ color: '#000', background: 'linear-gradient(#fff, #f5fcff)' }}>{item.gameName}</td>
                    <td style={{ color: '#000', background: 'linear-gradient(#fff, #f5fcff)' }}>
                      {item.bettingAmount?.toString().replace(commonReg2, ',')}
                    </td>
                    <td style={{ color: '#fff', fontWeight: '600', background: 'linear-gradient(#000, #000)' }}>
                      {item.bettingResultAmount?.toString().replace(commonReg2, ',')}
                    </td>
                    <td>{item.bettingAfterAmount?.toString().replace(commonReg2, ',')}</td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>
        )}
        {/* {totalElements > 0 && (
          <ListWrap>
            <CustomDeleteButton
              onClick={() => {
                setCheckRadio(content.map(value => value.bettingId))
              }}
              type="button"
            >
              <CustomButtonInfos>전체선택</CustomButtonInfos>
            </CustomDeleteButton>
            <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <CustomButtonInfos>삭제하기</CustomButtonInfos>
            </CustomDeleteButton>
          </ListWrap>
        )} */}
      </BettingHistoryTableWrap>
      {totalElements > 0 && <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />}
    </>
  )
}

export default CasinoBettingHistoryPageV2

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 120px;
    height: auto;
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  margin-bottom: 20px;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #525252;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    border: solid 1px ${HermesMainBorder};
    cursor: pointer;
  }
`

const BettingHistoryTableHead = styled.thead`
  th {
    background: none;
  }
`

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  div {
    font-weight: 500;
  }
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px #525252;
  border-right: solid 1px #525252;
  border-bottom: solid 1px #525252;
  font-weight: 700;
  // &:hover {
  //   border: solid 2px #eec100;
  //   cursor: pointer;
  // }
`

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;

  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  &:not(:last-child) {
    border-right: 1px solid #525252;
  }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: #b88907;
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`
