import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { CustomTableWrap } from '../TableElements'

const ReactTableDefaultBody = ({ page, headerGroups, getTableBodyProps, prepareRow, onClickRowHandler }) => (
  <tbody {...getTableBodyProps()}>
    {page.length === 0 ? (
      <NoTableContentTr isMobile={isMobile}>
        <td
          colSpan={headerGroups[0].headers.length}
          style={{ fontSize: isMobile ? '12px' : '14px', background: 'none' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              gap: '5px',
            }}
          >
            <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
            최근 게시물이 없습니다.
          </div>
        </td>
      </NoTableContentTr>
    ) : (
      <>
        {page.map(row => {
          prepareRow(row)
          return (
            <tr
              {...row.getRowProps()}
              onClick={e => {
                if (onClickRowHandler !== null) {
                  // row 에 대한걸 넘겨도 될듯?
                  onClickRowHandler(row)
                }
              }}
            >
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>
                  <CustomTableWrap>{cell.render('Cell')}</CustomTableWrap>
                </td>
              ))}
            </tr>
          )
        })}
      </>
    )}
  </tbody>
)

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.shape()),
      getHeaderGroupProps: PropTypes.func,
      getFooterGroupProps: PropTypes.func,
    }),
  ).isRequired,
  onClickRowHandler: PropTypes.func,
}

ReactTableDefaultBody.defaultProps = {
  onClickRowHandler: null,
}

const ReactTableBody = ({ page, headerGroups, getTableBodyProps, prepareRow, onClickRowHandler }) => {
  return (
    <ReactTableDefaultBody
      headerGroups={headerGroups}
      page={page}
      getTableBodyProps={getTableBodyProps}
      prepareRow={prepareRow}
      onClickRowHandler={onClickRowHandler}
    />
  )
}

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.shape()),
      getHeaderGroupProps: PropTypes.func,
      getFooterGroupProps: PropTypes.func,
    }),
  ).isRequired,
  onClickRowHandler: PropTypes.func,
}

ReactTableBody.defaultProps = {
  onClickRowHandler: null,
}

export default ReactTableBody

const NoTableContentTr = styled.tr`
  height: 100px;
  font-weight: bolder;
  font-size: 12px;
  background: ${props => (props.isMobile ? '#161719' : 'rgb(16, 16, 18)')} !important;
  border: solid 1px rgb(22, 23, 25) !important;
  td {
    div {
      img {
        width: ${props => (props.isMobile ? '100px' : '120px')} !important;
        height: auto;
      }
    }
  }
`
