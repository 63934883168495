import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { HermesMainHover2, KyochonMainBorder, KyochonMainText } from '../../../utils/palette'
import { VirtualGameLeagueTypeKr } from '../../../utils/enums/VirtualGame/VirtualGameEnums'

const MobileVirtualSoccerContentTop = () => {
  const [leagueList, setLeagueList] = useState([])

  const [activeLeagueType, setActiveLeagueType] = useState(null)

  const onClickActiveLeagueTypeHandler = league => {
    setActiveLeagueType(league)
  }

  const { sportType } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      sportType: virtualGameBetting.selectedVirtuaGameSportType,
    }
  })
  const { allLeagueList } = useSelector(state => {
    const { virtualGameInfo } = state

    return {
      allLeagueList: virtualGameInfo.virtualSportList,
    }
  })

  useEffect(() => {
    if (sportType !== 'VIRTUAL_SOCCER') return

    setLeagueList(
      allLeagueList.find(item => item.sportType === sportType)?.leagueList.filter(item => item.leagueIsShow) ?? [],
    )
  }, [allLeagueList, sportType])

  useEffect(() => {
    if (leagueList.length === 0) return

    if (activeLeagueType) return

    setActiveLeagueType(leagueList[0].leagueType)
  }, [activeLeagueType, leagueList])

  const getLeagueVideoCompo = () => {
    switch (activeLeagueType) {
      case 'VIRTUAL_PREMIERSHIP':
        return <VirtualGameVideo src="https://b1.nutv365.com/ios/?vn=1&sw=370&sh=208" />
      case 'VIRTUAL_SUPERLEAGUE':
        return <VirtualGameVideo src="https://b1.nutv365.com/ios/?vn=2&sw=370&sh=208" />
      case 'VIRTUAL_EURO_CUP':
        return <VirtualGameVideo src="https://b1.nutv365.com/ios/?vn=13&sw=370&sh=208" />
      case 'VIRTUAL_WORLD_CUP':
        return <VirtualGameVideo src="https://b1.nutv365.com/ios/?vn=3&sw=370&sh=208" />
      default:
        return null
    }
  }

  return (
    <VirtualGameContentTopWrap>
      <VirtualGameVideoWrap>
        <VirtualGameVideoInnerWrap>
          <VirtualGameVideoInnerWrapHeader count={leagueList.length}>
            {leagueList.length === 0 ? (
              <div
                style={{
                  width: '100%',
                  height: '400px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '20px',
                  color: 'white',
                }}
              >
                영상을 지원하지 않습니다.
              </div>
            ) : (
              <>
                {leagueList.map(leagueItem => {
                  return (
                    <VirtualGameVideoInnerWrapHeaderTab
                      active={activeLeagueType === leagueItem.leagueType}
                      onClick={() => {
                        onClickActiveLeagueTypeHandler(leagueItem.leagueType)
                      }}
                    >
                      {VirtualGameLeagueTypeKr[leagueItem.leagueType]}
                    </VirtualGameVideoInnerWrapHeaderTab>
                  )
                })}
              </>
            )}
          </VirtualGameVideoInnerWrapHeader>

          <VirtualGameVideoInnerWrapBox>{getLeagueVideoCompo()}</VirtualGameVideoInnerWrapBox>
        </VirtualGameVideoInnerWrap>
      </VirtualGameVideoWrap>
    </VirtualGameContentTopWrap>
  )
}

export default MobileVirtualSoccerContentTop

const VirtualGameContentTopWrap = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

const VirtualGameVideoWrap = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 3px;
`

const VirtualGameVideoInnerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

const VirtualGameVideoInnerWrapHeader = styled.div`
  width: 100%;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: ${props =>
    props.count !== 0 ? `repeat(${props.count}, minmax(0, 1fr))` : 'repeat($1, minmax(0, 1fr))'};
`
const VirtualGameVideoInnerWrapHeaderTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  cursor: pointer;
  z-index: 0;
  overflow: unset;
  white-space: nowrap;
  font-weight: bold;
  text-align: center;
  width: auto;
  padding: 0 15px;
  height: 35px;
  font-size: 12px;
  color: #454545;
  box-sizing: border-box;
  border: 1px solid #aaaaaa;
  background: linear-gradient(#ffffff, #ffffff);
  border-radius: 5px;

  ${props =>
    props.active &&
    `
    background: ${KyochonMainBorder};
  color: black    `}

  &:hover {
    background: ${KyochonMainBorder};
    color: black;
  }
`

const VirtualGameVideoInnerWrapBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const VirtualGameVideo = styled.iframe`
  width: 370px;
  height: 208px;
`
