import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import ReactHtmlParser from 'react-html-parser'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { deleteNoteList, updateNoteStatus } from '../../../api/customCenter/customCenterApi'
import { fetchNoteList } from '../../../redux/customCenterSlice'
import { fetchNoteSize } from '../../../redux/memberInfoSlice'
import { colorMainText } from '../../../utils/palette'
import CustomLoading from '../../CustomLoading'
import { BoardTypeEnums } from '../../../utils/enums/Operation/boardEnums'

const BettingRuleDetail = ({ notice, onBack }) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 맨 위로 이동
    window.scrollTo(0, 0)
  }, [])
  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  return (
    <>
      {loading && <CustomLoading />}
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            <table className="tb_notice_box">
              <tbody>
                <tr>
                  <td className="td_notice_td">
                    <span style={{ fontSize: '10pt' }}>
                      <b>
                        <span style={{ color: 'rgb(0,0,0)' }}>필히 이용규정을 숙지하시고 이용하시기 바랍니다. </span>
                      </b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="height_20" />
            <div>
              <table className="tb_solid_table">
                <tbody>
                  <tr>
                    <td className="title">제목</td>
                    <td id="CPH_td_title" className="body_white" style={{ padding: '0px 10px 0px 10px' }}>
                      <b style={{ color: 'rgb(0,0,0)', fontWeight: '900' }}>★Zeus★&nbsp;</b>
                      <span style={{ color: 'rgb(0,0,0)', fontWeight: '500' }}>{notice.boardTitle}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">구분</td>
                    <td id="CPH_td_title" className="body_white" style={{ padding: '0px 10px 0px 10px' }}>
                      {notice.type === 'NOTICE' ? (
                        <span className="ic_notice">{BoardTypeEnums[notice.type]}</span>
                      ) : (
                        <span className="ic_bettingrule">{BoardTypeEnums[notice.type]}</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="title">내용</td>
                    <td id="CPH_td_body" className="body_view" style={{ padding: '10px', wordBreak: 'break-all' }}>
                      {ReactHtmlParser(notice.boardContent, { transform: transformFn })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="height_20" />
            <div style={{ textAlign: 'center' }}>
              <a onClick={onBack} className="normal_button">
                목록으로
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
    // <WebPageWrap ref={webPageWrapRef}>
    //   <OneToOneHeaderWrap>
    //     <CustomerCenterHeaderInfo>쪽지함</CustomerCenterHeaderInfo>
    //     <OnetoOneSubhead>* 읽음 처리 된 것만 삭제 하실 수 있습니다.</OnetoOneSubhead>
    //   </OneToOneHeaderWrap>

    //   {loading || deleteLoading ? (
    //     <CustomLoading />
    //   ) : (
    //     <CustomerCenterContentWrap>
    //       <ListWrap>
    //         <ListButton
    //           onClick={() => {
    //             setCheckRadio(content.map(value => value.noteKey))
    //           }}
    //           type="button"
    //         >
    //           <MoneyFormModifyButtomInfo>전체선택</MoneyFormModifyButtomInfo>
    //         </ListButton>
    //         <ListButton onClick={() => onDeleteHandler()} variant="primary" type="button">
    //           <MoneyFormModifyButtomInfo>삭제하기</MoneyFormModifyButtomInfo>
    //         </ListButton>
    //       </ListWrap>
    //       <CustomerCenterContentTable>
    //         <CustomerCenterContentTableHead>
    //           <CustomerCenterContentTableHeadTr>
    //             <th style={{ width: '5%' }}>선택!</th>
    //             <th style={{ width: '5%' }}>번호</th>
    //             <th style={{ width: '70%' }}>내용</th>
    //             <th style={{ width: '12%' }}>수신일</th>
    //             <th style={{ width: '8%' }}>상태</th>
    //           </CustomerCenterContentTableHeadTr>
    //         </CustomerCenterContentTableHead>
    //         <CustomerCenterContentTableBody>
    //           {content.map(item => (
    //             <>
    //               <CustomerCenterContentTableBodyTr key={item.noteKey}>
    //                 <CustomerCenterContentBox>
    //                   <CustomerCenterContenItemBox width="5">
    //                     <NoteRadio
    //                       rowInfo={{
    //                         noteKey: item.noteKey,
    //                         status: item.status,
    //                       }}
    //                       setCheckRadio={setCheckRadio}
    //                       checkRadio={checkRadio}
    //                     />
    //                   </CustomerCenterContenItemBox>
    //                   <CustomerCenterContenItemBox width="5">{item.num}</CustomerCenterContenItemBox>
    //                   <CustomerCenterContenItemBox
    //                     width="70"
    //                     onClick={() => onClickRowHandler(item.noteKey)}
    //                     style={{ cursor: 'pointer' }}
    //                   >
    //                     {item.noteTitle}
    //                   </CustomerCenterContenItemBox>
    //                   <CustomerCenterContenItemBox width="12">
    //                     {convertToKstDateTime(item.sendDate)}
    //                   </CustomerCenterContenItemBox>
    //                   <CustomerCenterContenItemBox width="8">
    //                     {NoteReadStatusEnums[item.status]}
    //                   </CustomerCenterContenItemBox>
    //                 </CustomerCenterContentBox>
    //               </CustomerCenterContentTableBodyTr>
    //               <Collapse in={item.isVisible}>
    //                 <CustomerCenterContentTableBodyTr>
    //                   <CustomerCenterContentDetailBox>
    //                     {ReactHtmlParser(item.sendContent, { transform: transformFn })}
    //                   </CustomerCenterContentDetailBox>
    //                 </CustomerCenterContentTableBodyTr>
    //               </Collapse>
    //             </>
    //           ))}
    //         </CustomerCenterContentTableBody>
    //       </CustomerCenterContentTable>
    //       {totalElement > 0 && (
    //         <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
    //       )}
    //     </CustomerCenterContentWrap>
    //   )}
    // </WebPageWrap>
  )
}

export default BettingRuleDetail

const ListWrap = styled.div`
  display: flex;
`

export const OnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`
export const OneToOneHeaderWrap = styled.div`
  padding: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px
  border-bottom: solid 1px #edae07;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;

  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(22, 23, 25);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  padding: 10px 5px;

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-weight: 600;
  }
`
