import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 팝업 리스트 조회
export async function searchNormalPopupImgList(params) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/popuplist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchNormalPopupImgList(params) {
  const [content, setContent] = useState([])

  async function fetchSearchNormalPopupImgList() {
    await searchNormalPopupImgList(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchNormalPopupImgList()
  }, [params])

  return { content, fetchSearchNormalPopupImgList }
}

// 점검 팝업 조회
export async function searchInspectionPopupImg(params) {
  const response = await axios({
    url: '/tyson/common/v1/popup/inspection',
    method: 'GET',
    params,
  })
    .then(res => {
      return res
    })
    .catch(error => {
      return error
    })

  return response
}

export function useSearchInspectionPopupImg(params) {
  const [content, setContent] = useState([])

  async function fetchSearchInspectionPopupImg() {
    await searchInspectionPopupImg(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchInspectionPopupImg()
  }, [params])

  return { content, fetchSearchInspectionPopupImg }
}
