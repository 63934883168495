import React, { useState } from 'react'
// import PartnerManageMentTabs from '../../PartnerManageMentTabs'

const MobileGopickTabs = ({ activeMenu }) => {
  return (
    <div className="mobile_mini_content">
      <div className="tab_wrap">
        <ul className="sub_tap">
          <li
            className={activeMenu === 'GO_PICK_NUGURI' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/gopick/raccoon'
            }}
          >
            <a>너구리</a>
          </li>
          <li
            className={activeMenu === 'GO_PICK_SOCCER' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/gopick/soccer'
            }}
          >
            <a>축구</a>
          </li>
          <li
            className={activeMenu === 'GO_PICK_BASEBALL' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/gopick/baseball'
            }}
          >
            <a>야구</a>
          </li>
          <li
            className={activeMenu === 'GO_PICK_SQUIDGAME' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/gopick/octopus'
            }}
          >
            <a>오징어게임</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MobileGopickTabs
