import React, { Fragment } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import styled from 'styled-components'
import { convertToKstByFormat } from '../../../utils/dateTime'

const SportEventNotInProgressFixtureItem = React.memo(({ fixtureKey, fixtureData }) => {
  return (
    <Fragment key={`FixtureItem-${fixtureKey}-${fixtureData.fixtureId}`}>
      <NotInProgressFixtureItemWrap>
        <NotInProgressFixtureItemBox>
          <NotInProgressFixtureItemInnerBox>
            <NotInProgressFixtureItemInnerBoxItem>
              {fixtureData.fixture.homeImageUrl ? (
                <img src={fixtureData.fixture.homeImageUrl} alt="" />
              ) : (
                <img src={`${process.env.PUBLIC_URL}/img/sports/common/default.png`} alt="" />
              )}
              <div>{fixtureData.fixture.homeName}</div>
            </NotInProgressFixtureItemInnerBoxItem>
            <NotInProgressFixtureItemInnerBoxItem>
              <div>{convertToKstByFormat(fixtureData.fixture.startDate)}</div>
              <div style={{ fontSize: '14px', fontWeight: '700' }}>VS</div>
            </NotInProgressFixtureItemInnerBoxItem>
            <NotInProgressFixtureItemInnerBoxItem>
              {fixtureData.fixture.awayImageUrl ? (
                <img src={fixtureData.fixture.awayImageUrl} alt="" />
              ) : (
                <img src={`${process.env.PUBLIC_URL}/img/sports/common/default.png`} alt="" />
              )}
              <div>{fixtureData.fixture.awayName}</div>
            </NotInProgressFixtureItemInnerBoxItem>
          </NotInProgressFixtureItemInnerBox>
        </NotInProgressFixtureItemBox>
      </NotInProgressFixtureItemWrap>
    </Fragment>
  )
})

const NotInProgressFixtureItemWrap = styled.div`
  display: block;
  padding: 0 8px;
  background: linear-gradient(#fff, #f5fcff);
  margin-top: 5px;
  margin-bottom: 3px;
  border: 1px solid #eee;
`

const NotInProgressFixtureItemBox = styled.div`
  height: 92px;
  padding: 2px;
  padding-top: 5px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`

const NotInProgressFixtureItemInnerBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 12px;
  text-align: center;
  color: #000;
`
const NotInProgressFixtureItemInnerBoxItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 33.333%;
  flex-direction: column;
  font-size: 11px;

  img {
    width: auto;
    height: 30px;
    margin-bottom: 2px;
  }
`

export const MobileSportEventNotInProgressFixtures = React.memo(
  ({ eventKey, event, onClickFixtureCollapseHandler }) => {
    return (
      <>
        <div>
          <div style={{ position: 'relative' }}>
            <SportFixtureLeagueTitleWrap onClick={() => onClickFixtureCollapseHandler(eventKey)}>
              <SportFixtureLeagueTitleBox>
                <SportsEventTitleTypeImg
                  src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${event.sportId}.png`}
                  alt=""
                />
                {/* {event.locationImageUrl && <SportsEventTitleCountryImg src={event.locationImageUrl} alt="" />} */}
                <SportsEventTitleCountryInfo>{event.locationName}</SportsEventTitleCountryInfo>
                <SportsEventTitleArrowRightIcon />
                {/* {event.leagueImageUrl && <SportsEventTitleLeagueImg src={event.leagueImageUrl} alt="" />} */}
                <SportsEventTitleLeagueInfo>{event.leagueName}</SportsEventTitleLeagueInfo>
                {/* <SportsEventTitleLeagueCount>{event.fixtures.length}경기</SportsEventTitleLeagueCount> */}
                {event.fixtureLeagueCollapse ? <SportsEventTitleArrowUpIcon /> : <SportsEventTitleArrowDownIcon />}
              </SportFixtureLeagueTitleBox>
            </SportFixtureLeagueTitleWrap>
          </div>
        </div>
        <SportsEventWrap key={eventKey}>
          {event.fixtureLeagueCollapse && (
            <SportsEventContentBox>
              {event.fixtures.map(fixtureData => {
                return (
                  <SportEventNotInProgressFixtureItem
                    key={`${eventKey}-${fixtureData.fixtureId}`}
                    fixtureKey={eventKey}
                    fixtureData={fixtureData}
                  />
                )
              })}
            </SportsEventContentBox>
          )}
        </SportsEventWrap>
      </>
    )
  },
)

const SportFixtureLeagueTitleWrap = styled.div`
  margin-top: 10px;
  padding: 4px 8px;
  cursor: pointer;
  background: #eee;
  height: 36px;
  color: #000;
  border-radius: 6px 6px 0 0;
`

const SportFixtureLeagueTitleBox = styled.div`
  font-weight: 500;
  font-size: 12px;
  height: 100%;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
`

const SportsEventWrap = styled.div`
  width: 100%;
  padding: 0 0 15px 0;
  display: flex;
  flex-direction: column;
`

const SportsEventTitleTypeImg = styled.img`
  width: 16px;
  height: auto;
`
const SportsEventTitleCountryImg = styled.img`
  width: 16px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleCountryInfo = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleArrowRightIcon = styled(MdOutlineArrowForwardIos)`
  color: #000;
  width: 14px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueImg = styled.img`
  width: 23px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueInfo = styled.span`
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleLeagueCount = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  display: inline-block;
`

const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: #000;
  width: 20px;
  height: auto;
  cursor: pointer;
  margin-left: auto !important;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: #000;
  width: 20px;
  height: auto;
  cursor: pointer;
  margin-left: auto !important;
`

const SportsEventContentBox = styled.div`
  // width: 100%;
  min-height: 50px;
  color: #000;
  padding: 7px;
  display: flex;
  flex-direction: column;
  border: 2px solid #eee;

  // background: rgb(20, 21, 23);
  border-radius: 0px 0px 6px 6px;
  gap: 2px;
`
