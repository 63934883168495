import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Footer = () => {
  const location = useLocation()
  const [footerStyle, setFooterStyle] = useState({
    background: '#f5f5f5',
    height: '50px',
    marginTop: '600px',
  })

  useEffect(() => {
    if (location.pathname === '/main') {
      setFooterStyle({
        background: '#f5f5f5',
        height: '50px',
        width: '100%',
        // marginTop: '0',
        // marginTop: '150px',
        position: 'fixed',
        bottom: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })
    } else {
      setFooterStyle({
        background: '#f5f5f5',
        height: '50px',
        marginTop: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })
    }
  }, [location])

  return (
    <>
      {/* <div style={footerStyle}>
        <div className="div_footer_menu" />
      </div> */}

      {/* <div style={{ height: '30px' }}>
        <div className="div_footer_menu">
          <table style={{ height: '30px' }}>
            <tbody>
              <tr>
                <td className="footer_menu_logo">Infomation</td>
                <td style={{ color: '#aaa', fontWeight: '100', fontSize: '10px' }}>&nbsp;|&nbsp;</td>
                <td>
                  <a href="/mypage" className="footer_menu">
                    {' '}
                    회원정보
                  </a>
                </td>
                <td>
                  <a href="/betting-history" className="footer_menu">
                    배팅내역
                  </a>
                </td>
                <td>
                  <a href="/money/moneyexchange" className="footer_menu">
                    머니내역
                  </a>
                </td>
                <td>
                  <a href="/friend-list" className="footer_menu">
                    추천내역
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <div style={footerStyle}>
        <div
          className="div_copyright"
          style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '5px' }}
        >
          <span>ⓒ 2019 제우스. All rights reserved.</span>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.open('https://t.me/Zeus_CSCS')
            }}
          >
            {/* Ⓢ Solution By Zeus Teconology */}
          </span>
        </div>
      </div>
    </>
  )
}

export default Footer

const FootWrap = styled.div`
  // top: 170px;
  width: 100%;
  z-index: 115;
`

const FooterImgWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  // top: 100px;
`

// Footer
const FooterImg = styled.div`
  height: 240px;
  background: url(${process.env.PUBLIC_URL}/img/footer/footer.png) center no-repeat;
`

// Footer bottom
const FooterBottomWrap = styled.div`
  box-sizing: border-box;
  height: 40px;

  /* 라인컬러 */

  border-top: 1px solid #edae07;
`

const FooterBottomInfoWrap = styled.div`
  width: 1260px;
  margin: 0 auto;
  position: relative;

  text-align: center;
  line-height: 40px;
`

const FooterBottomInfo = styled.span`
  width: 237px;
  height: 17px;

  font-style: normal;
  font-weight: 400;
  line-height: 17px;

  /* 엘로우폰트 */
  color: #ffc01a;
`
