import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { SocketIOEventName } from '../../utils/enums/WebSocket/SocketIOEventName'
import { SocketIORequestMessageType } from '../../utils/enums/WebSocket/SocketIORequestMessageType'
import { emitPromise, socket } from '../../utils/socket'
import {
  fetchVirtualFixtureListAction,
  setVirtualBetLogs,
  setVirtualFixtures,
  setVirtualGameLoading,
  setVirtualMarketInfos,
  setVirtualResultHistory,
} from '../virtualGameInfoSlice'

function getVirtualGameInfo(state) {
  return state.virtualGameInfo
}

function getVirtualGameBetting(state) {
  return state.virtualGameBetting
}

function* handleFetchVirtualFixtureList() {
  try {
    const virtualGameInfo = yield select(getVirtualGameInfo)

    const payload = {
      type: SocketIORequestMessageType.VIRTUAL_FIXTURE_LIST,
      payload: virtualGameInfo.virtualGameParams,
    }

    // 로딩 true
    // yield put(setDomesticLoading(true))

    const virtualFixtureListRes = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (virtualFixtureListRes.status) {
      // const virtualGameBetting = yield select(getVirtualGameBetting)

      yield put(setVirtualFixtures(virtualFixtureListRes.data.currentGame))
      yield put(setVirtualMarketInfos(virtualFixtureListRes.data.marketInfos))
      yield put(setVirtualResultHistory(virtualFixtureListRes.data.resultHistory))
      yield put(setVirtualBetLogs(virtualFixtureListRes.data.betLogs))
    }

    // 로딩 false
    yield put(setVirtualGameLoading(false))
  } catch (error) {
    yield put(setVirtualGameLoading(false))
  }
}

function* watchFetchVirtualFixtureList() {
  yield takeLatest(fetchVirtualFixtureListAction, handleFetchVirtualFixtureList)
}

export default function* virtualGameSaga() {
  yield all([watchFetchVirtualFixtureList()])
}
