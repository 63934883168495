import io from 'socket.io-client'

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'ws://15.152.246.85:9992'
// const URL = 'ws://localhost:9992'
const URL = 'wss://zu-000.com'

// eslint-disable-next-line no-useless-escape
const clientHost = window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1')

export const socket = io(URL, {
  path: '/socket.io',
  transports: ['websocket'],
  timestampParam: 'time',
  timestampRequests: true,
  cors: {
    origin: '*',
  },
  autoConnect: false,
})

/**
 *
 * @param {Socket} soc
 * @param {SocketIOEventName} event
 * @param {Any} data
 * @returns
 */
export const emitPromise = (soc, event, data) => {
  return new Promise((resolve, reject) => {
    soc.emit(event, data, response => {
      if (response.error) {
        reject(response.error)
      } else {
        resolve(response)
      }
    })
  })
}

// // <BettingHistoryTable key={item.sportsBettingLogKey}>
//             //   <BettingHistoryTableHead>
//             //     <BettingHistoryTableTr>
//             //       <th style={{ width: '8%' }}>경기일시</th>
//             //       <th style={{ width: '5%' }}>타입</th>
//             //       <th style={{ width: '20%' }}>국가 - 리그</th>
//             //       <th style={{ width: '35%' }}>경기</th>
//             //       <th style={{ width: '12%' }}>마켓</th>
//             //       <th style={{ width: '10%' }}>배팅</th>
//             //       <th style={{ width: '5%' }}>배당</th>
//             //       {/* <th style={{ width: '5%' }}>점수</th> */}
//             //       <th style={{ width: '5%' }}>결과</th>
//             //     </BettingHistoryTableTr>
//             //   </BettingHistoryTableHead>
//             //   <BettingHistoryTableBody>
//             //     {/* 폴더 수만큼 생겨야 함 */}
//             //     {item.bonusFolderNum !== 0 && (
//             //       <>
//             //         <BettingHistoryTableTr>
//                       <BettingHistoryContentBox>
//                         <BettingHistoryContentItemBox width="8">
//                           {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
//                         </BettingHistoryContentItemBox>
//                         <BettingHistoryContentItemBox width="5" sportMarketType={item.sportMarketType}>
//                           {SportMarketTypeV2[item.sportMarketType]}
//                         </BettingHistoryContentItemBox>
//                         <BettingHistoryContentItemBox width="20">-</BettingHistoryContentItemBox>
//                         <BettingHistoryContentItemBox width="35">
//                           [{item.bonusFolderNum}폴더 이상]
//                         </BettingHistoryContentItemBox>
//                         <BettingHistoryContentItemBox width="12">
//                           <SportsEventTitleTypeImg
//                             src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
//                             style={{ width: '20px', height: '20px' }}
//                             alt=""
//                           />
//                           보너스 배당
//                         </BettingHistoryContentItemBox>
//                         <BettingHistoryContentItemBox width="10" active>
//                           -
//                         </BettingHistoryContentItemBox>
//                         <BettingHistoryContentItemBox width="5">{item.bonusFolderPer}</BettingHistoryContentItemBox>
//                         {/* <BettingHistoryContentItemBox width="5">-</BettingHistoryContentItemBox> */}
//                         <BettingHistoryContentItemBox width="5">
//                           <BettingHistoryContentBoxFooterInfo bettingResult="WINNER">
//                             적중
//                           </BettingHistoryContentBoxFooterInfo>
//                         </BettingHistoryContentItemBox>
//                       </BettingHistoryContentBox>
//             //         </BettingHistoryTableTr>
//             //       </>
//             //     )}
//             //     {item.sportsDetailBettingLogList.map(detail => (
//             //       <>
//             //         <BettingHistoryTableTr key={detail.sportsDetailBettingLogKey}>
//                       <BettingHistoryContentBox>
//                         <BettingHistoryContentItemBox width="8">
//                           {convertToKstByFormat(detail.startDate, 'MM-DD HH:mm')}
//                         </BettingHistoryContentItemBox>
//                         <BettingHistoryContentItemBox width="5" sportMarketType={item.sportMarketType}>
//                           {SportMarketTypeV2[item.sportMarketType]}
//                         </BettingHistoryContentItemBox>
//                         <BettingHistoryContentItemBox width="20">
//                           <img
//                             style={{ width: '20px', height: '20px' }}
//                             src={`${process.env.PUBLIC_URL}/img/sports/sportsIdType/${detail.sportId}.png`}
//                             alt=""
//                           />
//                           {detail.locationName || '-'} - {detail.leagueName || '-'}
//                         </BettingHistoryContentItemBox>

//                         <BettingHistoryContentItemBox width="35">
//                           {detail.homeName} VS {detail.awayName}

//                         </BettingHistoryContentItemBox>

//                         <BettingHistoryContentItemBox width="12">{detail.marketName}</BettingHistoryContentItemBox>
//                         <BettingHistoryContentItemBox width="10" active>
//                           {detail.betNameKr} {detail.lineNum && <>({detail.lineNum || '-'})</>}
//                         </BettingHistoryContentItemBox>
//                         <BettingHistoryContentItemBox width="5">{detail.priceNum || '-'}</BettingHistoryContentItemBox>
//                         {/* <BettingHistoryContentItemBox width="5">-</BettingHistoryContentItemBox> */}
//                         <BettingHistoryContentItemBox width="5">
//                           <BettingHistoryContentBoxFooterInfo bettingResult={detail.betSettlementType}>
//                             {SportsResultSettlementTypeEnumsV2[detail.betSettlementType]}
//                           </BettingHistoryContentBoxFooterInfo>
//                         </BettingHistoryContentItemBox>
//                       </BettingHistoryContentBox>
//                     </BettingHistoryTableTr>
//                   </>
//                 ))}
//                 <BettingHistoryTableTr>
//                   <BettingHistoryContentBox>
//                     <BettingHistoryRadio
//                       sportsBettingLogKey={item.sportsBettingLogKey}
//                       sportsResultStatus={item.sportsResultStatus}
//                       checkRadio={checkRadio}
//                       setCheckRadio={setCheckRadio}
//                     />
//                     <BettingHistoryContentBoxFooterInfo>
//                       <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배팅 시간 :</span>{' '}
//                       {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
//                     </BettingHistoryContentBoxFooterInfo>
//                     <BettingHistoryContentBoxFooterInfo>
//                       <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배팅 금액 : </span>
//                       {item.bettingAmount.toString().replace(commonReg2, ',')} 원
//                     </BettingHistoryContentBoxFooterInfo>
//                     <BettingHistoryContentBoxFooterInfo>
//                       <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배당 : </span>
//                       {item.totalOddValue}
//                     </BettingHistoryContentBoxFooterInfo>
//                     <BettingHistoryContentBoxFooterInfo>
//                       <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>예상 적중금액 : </span>
//                       {item.bettingExpectedAmount.toString().replace(commonReg2, ',')} 원
//                     </BettingHistoryContentBoxFooterInfo>
//                     <BettingHistoryContentBoxFooterInfo>
//                       <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>적중 금액 : </span>
//                       {item.bettingResultAmount.toString().replace(commonReg2, ',')} 원
//                     </BettingHistoryContentBoxFooterInfo>
//                     <BettingHistoryContentBoxFooterInfo2 bettingResult={item.sportsResultStatus}>
//                       <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>결과 : </span>
//                       {SportsResultStatusEnums[item.sportsResultStatus]}
//                     </BettingHistoryContentBoxFooterInfo2>
//                   </BettingHistoryContentBox>
//                 </BettingHistoryTableTr>
//               </BettingHistoryTableBody>
//               {item.allowBettingCancel && (
//                 <BettingCancelBox>
//                   <BettingCancelButton
//                     onClick={() => {
//                       onClickBettingHistoryCancelHandler(item)
//                     }}
//                   >
//                     배팅 취소
//                   </BettingCancelButton>
//                 </BettingCancelBox>
//               )}
//             </BettingHistoryTable>
