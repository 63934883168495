import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { SportMarketType } from '../../utils/enums/SportsGame/SportsGameEnums'
import { SocketIOEventName } from '../../utils/enums/WebSocket/SocketIOEventName'
import { SocketIORequestMessageType } from '../../utils/enums/WebSocket/SocketIORequestMessageType'
import { emitPromise, socket } from '../../utils/socket'
import {
  onUpdatedBettingCartItemHandlerByFixture,
  onUpdatedBettingCartItemHandlerByMarket,
  removeAllBettingCartItem,
} from '../sportsBettingSlice'
import { createSocketChannel } from './createSocketChannel'
import {
  fetchOverseasFixtureListAction,
  fetchOverseasFixtureMarketAction,
  setOverseasFixtureMarkets,
  setOverseasLoading,
  setOverseasSports,
  updateFixtureByWebSocket,
  updateLiveScoreByWebSocket,
  updateMarketByWebSocket,
} from '../sportsOverseasInfoSlice'

function getSportsOverseasInfo(state) {
  return state.sportsOverseasInfo
}

function getSportsBetting(state) {
  return state.sportsBetting
}

function* handleFetchOverseasFixtureList() {
  try {
    const sportsOverseasInfo = yield select(getSportsOverseasInfo)

    const payload = {
      type: SocketIORequestMessageType.SPORTS_FIXTURE_LIST,
      payload: sportsOverseasInfo.overseasParams,
    }

    // 로딩 true
    yield put(setOverseasLoading(true))

    const sportsFixtureListRes = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (sportsFixtureListRes.status) {
      yield put(setOverseasSports(sportsFixtureListRes.data))
    }

    // 로딩 false
    yield put(setOverseasLoading(false))
  } catch (error) {
    yield put(setOverseasLoading(false))
  }
}

function* watchFetchOverseasFixtureList() {
  yield takeLatest(fetchOverseasFixtureListAction, handleFetchOverseasFixtureList)
}

function* handleFetchOverseasFixtureMarket() {
  try {
    const sportsOverseasInfo = yield select(getSportsOverseasInfo)

    if (sportsOverseasInfo.overseasMarketParams === -1) return

    const payload = {
      type: SocketIORequestMessageType.SPORTS_FIXTURE_MARKET_LIST,
      payload: sportsOverseasInfo.overseasMarketParams,
    }

    // 로딩 true
    yield put(setOverseasLoading(true))

    const sportsFixtureMarketRes = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (sportsFixtureMarketRes.status) {
      const sportsBetting = yield select(getSportsBetting)

      yield put(
        setOverseasFixtureMarkets({
          sportEvent: sportsFixtureMarketRes.data.sportEvent,
          marketTab: sportsFixtureMarketRes.data.marketTab,
          bettingCartItems: sportsBetting.bettingCartList,
        }),
      )
    }

    // 로딩 false
    yield put(setOverseasLoading(false))
  } catch (error) {
    yield put(setOverseasLoading(false))
  }
}

function* watchFetchOverseasFixtureMarket() {
  yield takeLatest(fetchOverseasFixtureMarketAction, handleFetchOverseasFixtureMarket)
}

/**
 * 경기 데이터 다시 재호출
 */
function* onRefreshSportsDataMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.REFRESH_SPORTS_DATA)

  while (true) {
    try {
      const message = yield take(channel)

      const sportsOverseasInfo = yield select(getSportsOverseasInfo)

      const sportsFixtureListpayload = {
        type: SocketIORequestMessageType.SPORTS_FIXTURE_LIST,
        payload: sportsOverseasInfo.overseasParams,
      }

      const sportsFixtureListRes = yield call(emitPromise, socket, SocketIOEventName.FIND, sportsFixtureListpayload)

      if (sportsFixtureListRes.status) {
        yield put(setOverseasSports(sportsFixtureListRes.data))

        // 배팅 카트도 초기화 해부려
        yield put(removeAllBettingCartItem())
      }

      if (sportsOverseasInfo.overseasMarketParams !== -1) {
        const sportsFixtureMarketpayload = {
          type: SocketIORequestMessageType.SPORTS_FIXTURE_MARKET_LIST,
          payload: sportsOverseasInfo.overseasMarketParams,
        }

        const sportsFixtureMarketRes = yield call(
          emitPromise,
          socket,
          SocketIOEventName.FIND,
          sportsFixtureMarketpayload,
        )

        if (sportsFixtureMarketRes.status) {
          const sportsBetting = yield select(getSportsBetting)

          yield put(
            setOverseasFixtureMarkets({
              sportEvent: sportsFixtureMarketRes.data.sportEvent,
              marketTab: sportsFixtureMarketRes.data.marketTab,
              bettingCartItems: sportsBetting.bettingCartList,
            }),
          )
        }
      }

      // 배팅 카트에도 보내기
      yield put(
        onUpdatedBettingCartItemHandlerByMarket({
          data: sportsFixtureListRes.data,
          sportMarketType: SportMarketType.OVERSEAS,
        }),
      )
    } catch (e) {
      console.log('errror :::: ', e)
      //   alert(e.message)
    }
  }
}

function* onUpdatedFixtureMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.PRE_MATCH_FIXTURE_UPDATED)

  while (true) {
    try {
      const message = yield take(channel)

      if (message.sportMarketType === 'OVERSEAS') {
        yield put(updateFixtureByWebSocket(message))

        // 배팅 카트에도 보내기
        yield put(
          onUpdatedBettingCartItemHandlerByFixture({
            fixture: message?.fixture || {},
            sportMarketType: SportMarketType.OVERSEAS,
          }),
        )
      }
    } catch (e) {
      //   alert(e.message)
    }
  }
}

function* onUpdatedLiveScoreMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.PRE_MATCH_LIVE_SCORE_UPDATED)

  while (true) {
    try {
      const message = yield take(channel)

      yield put(updateLiveScoreByWebSocket(message))
    } catch (e) {
      //   alert(e.message)
    }
  }
}
function* onUpdatedMarketMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.PRE_MATCH_MARKET_UPDATED)

  while (true) {
    try {
      const message = yield take(channel)
      if (message.sportMarketType === 'OVERSEAS') {
        const sportsBetting = yield select(getSportsBetting)

        yield put(
          updateMarketByWebSocket({
            ...message,
            bettingCartItems: sportsBetting.bettingCartList,
          }),
        )

        // 배팅 카트에도 보내기
        yield put(
          onUpdatedBettingCartItemHandlerByMarket({
            market: message?.market || [],
            sportMarketType: SportMarketType.OVERSEAS,
          }),
        )
      }
    } catch (e) {
      //   alert(e.message)
    }
  }
}

export default function* sportsOverseasSaga() {
  yield all([
    fork(onUpdatedFixtureMessage),
    fork(onUpdatedLiveScoreMessage),
    fork(onUpdatedMarketMessage),
    fork(onRefreshSportsDataMessage),

    watchFetchOverseasFixtureList(),
    watchFetchOverseasFixtureMarket(),
  ])
}
