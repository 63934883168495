import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { bettingVirtualGame } from '../../api/game/virtualGameApi'
import CustomAlert from '../../containers/CustomAlert'
import CustomLoading from '../../containers/CustomLoading'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import {
  onClickBettingCartAlertCloseHandler,
  onClickBettingCartUpdateMessageCloseHandler,
  removeAllBettingCartItem,
  removeBettingCartItem,
  setBettingCartLoading,
} from '../../redux/virtualGameBettingSlice'
import { VirtualGameBettingTypeKr } from '../../utils/enums/VirtualGame/VirtualGameEnums'
import { commonReg2 } from '../../utils/validate/commonValidate'

const MobileVirtualBettingCartSidebar = () => {
  const navigate = useNavigate()

  // redux 사용
  const dispatch = useDispatch()

  const location = useLocation()

  const [showBettingCart, setShowBettingCart] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const resultMatches = window.matchMedia('(max-width: 1440px)').matches
      setIsSmallScreen(resultMatches)
      if (!resultMatches) {
        setShowBettingCart(true)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const toggleBettingCart = () => {
    setShowBettingCart(prevShowBettingCart => !prevShowBettingCart)
  }

  const { holdingMoney, memberTotalGameMoney, userId, memberLevel, noteRedisSize, oneToOneRedisSize } = useSelector(
    state => {
      const { memberInfo } = state

      return {
        holdingMoney: memberInfo.memberHoldingMoney,
        memberTotalGameMoney: memberInfo.memberTotalGameMoney,
        userId: memberInfo.userId,
        memberLevel: memberInfo.depositLevel,
        noteRedisSize: memberInfo.noteRedisSize,
        oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      }
    },
  )

  const {
    selectedVirtuaGameSportType,
    virtualGameBettingInfos,
    marketCombinationList,
    totalBettingPer,
    bettingCartList,
    bettingCartAlertMessage,
    bettingCartUpdateMessage,
    bettingCartCount,
  } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      selectedVirtuaGameSportType: virtualGameBetting.selectedVirtuaGameSportType,
      virtualGameBettingInfos: virtualGameBetting.virtualGameBettingInfos,
      marketCombinationList: virtualGameBetting.marketCombinationList,
      totalBettingPer: virtualGameBetting.totalBettingPer,
      bettingCartList: virtualGameBetting.bettingCartList,
      bettingCartAlertMessage: virtualGameBetting.bettingCartAlertMessage,
      bettingCartUpdateMessage: virtualGameBetting.bettingCartUpdateMessage,
      bettingCartCount: virtualGameBetting.bettingCartList.length,
    }
  })

  const calculateTimeLeft = () => {
    const now = moment().tz('Asia/Seoul')

    bettingCartList.forEach(item => {
      const target = moment(item.fixture.startDate).subtract(item.fixture.bettingEndTime, 'seconds')
      const duration = moment.duration(target.diff(now))

      if (duration.asSeconds() <= 0) {
        dispatch(removeBettingCartItem(item))
      }
    })
  }

  useEffect(() => {
    if (bettingCartList.length === 0) return

    const timer = setInterval(calculateTimeLeft, 500)

    return () => {
      clearInterval(timer)
    }
  }, [bettingCartList])

  const [realVirtualGameBettingInfo, setRealVirtualGameBettingInfo] = useState({})

  useEffect(() => {
    setRealVirtualGameBettingInfo(virtualGameBettingInfos?.[selectedVirtuaGameSportType] ?? {})
  }, [virtualGameBettingInfos, selectedVirtuaGameSportType])

  const [inputBettingMoney, setInputBettingMoney] = useState('0')
  const [expectMoney, setExpectMoney] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)

  useEffect(() => {
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니

    let resultBettingMoney = inputRealMoney
    let resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)

    const maxBettingAmount = realVirtualGameBettingInfo?.maxBettingAmount || 0
    const maxWinningAmount = realVirtualGameBettingInfo?.maxWinningAmount || 0

    if (inputRealMoney > maxBettingAmount) {
      // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
      resultBettingMoney = maxBettingAmount
      // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
      if (resultExpectMoney > maxWinningAmount) {
        resultBettingMoney = Math.floor(maxWinningAmount / totalBettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)
      }
    }
    if (resultExpectMoney > maxWinningAmount) {
      // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
      resultBettingMoney = Math.floor((maxWinningAmount || 0) / totalBettingPer) // 비율에 따른 최대 배팅금액
      resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)
    }

    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))
    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [inputBettingMoney, totalBettingPer])

  const onClickMoneyHandler = amountText => {
    const textNum = amountText || holdingMoney

    setInputBettingMoney(prev => {
      const prevprevValue = prev ? prev.toString().replaceAll(',', '') : ''
      const prevValue = prevprevValue.startsWith('0') ? prevprevValue.slice(1) : prevprevValue
      if ((prevValue === '' || prevValue === '0') && (textNum === '0' || textNum === '00' || textNum === '000')) {
        return prevValue?.toString().replace(commonReg2, ',')
      }

      if (textNum.length > 3) {
        // UI키패드 버튼으로 5만 ~ 200만 까지 눌렀을때
        if (prevValue === '' || prevValue === '0') {
          return textNum?.toString().replace(commonReg2, ',')
        }
        // UI키패드 버튼으로 0,00,000 , 1~9까지 눌렀을때
        return (Number(prevValue) + Number(textNum))?.toString().replace(commonReg2, ',')
      }
      return (prevValue + textNum)?.toString().replace(commonReg2, ',')
    })
  }

  // 정정 버튼 클릭 핸들러
  const onClickResetMoneyHandler = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
  }

  // 하나 삭제
  const onCloseSportsBettingCart = bettingCartItem => {
    dispatch(removeBettingCartItem(bettingCartItem))
  }
  // 전체 삭제
  const onClearSportsBettingCart = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
    dispatch(removeAllBettingCartItem())
  }

  const onSubmit = e => {
    if (apiFlag) return
    setApiFlag(true)

    if (bettingCartList.length === 0) {
      alert('배팅할 마켓을 먼저 선택해주세요.')
      setApiFlag(false)
      return
    }

    const maxBettingPer = realVirtualGameBettingInfo?.maxBettingPer || 0

    if (totalBettingPer > maxBettingPer) {
      alert(`최대 ${maxBettingPer}배당까지 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    if (Number(holdingMoney) < Number(inputBettingMoney?.toString().replaceAll(',', ''))) {
      alert('지갑머니가 부족합니다.')
      setApiFlag(false)
      return
    }

    const minBettingAmount = realVirtualGameBettingInfo?.minBettingAmount || 0

    if (Number(inputBettingMoney?.toString().replaceAll(',', '')) < Number(minBettingAmount)) {
      alert('최소 배팅금액 이상으로 가능 합니다.')
      setApiFlag(false)
      return
    }

    const maxBettingAmount = realVirtualGameBettingInfo?.maxBettingAmount || 0

    if (Number(inputBettingMoney) > Number(maxBettingAmount)) {
      alert('최대 배팅금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    const maxWinningAmount = realVirtualGameBettingInfo?.maxWinningAmount || 0

    if (Number(expectMoney?.toString().replaceAll(',', '')) > Number(maxWinningAmount)) {
      alert('최대 당첨금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (window.confirm('배팅하시겠습니까?')) {
      const body = {
        bettingAmount: Number(inputBettingMoney?.toString().replaceAll(',', '')),
        sportType: bettingCartList[0].fixture.sportType,
        betInfoList: bettingCartList.map(cartItem => {
          return {
            leagueType: cartItem.fixture.leagueType,
            eventId: cartItem.fixture.eventId,
            marketKey: cartItem.marketInfo.marketKey,
            betType: cartItem.selectedBetInfo.betType,
            raceNumber: cartItem.selectedBetInfo?.raceNumber || null,
            raceName: cartItem.selectedBetInfo?.raceName || null,
            raceRider: cartItem.selectedBetInfo?.raceRider || null,
            raceImageUrl: cartItem.selectedBetInfo?.raceImageUrl || null,
          }
        }),
      }

      dispatch(setBettingCartLoading(true))
      // API 호출
      bettingVirtualGame(body)
        .then(res => {
          alert('배팅 성공했습니다.')
        })
        .catch(error => {
          const errorCode = error?.response?.data?.errorCode ?? ''

          switch (errorCode) {
            case 'SYSTEM-1017':
              alert('보유한 머니가 충분하지 않아 배팅이 거절됩니다.')
              break
            case 'VIRTUAL-1009':
              alert('최대 배당 조건에 부합되지 않아 배팅이 거절됩니다.')
              break
            case 'VIRTUAL-1010':
              alert('최소 배팅액 조건에 부합되지 않아 배팅이 거절됩니다.')
              break
            case 'VIRTUAL-1011':
              alert('최대 배팅액 조건에 부합되지 않아 배팅이 거절됩니다.')
              break
            case 'VIRTUAL-1012':
              alert('최대 당첨액 조건에 부합되지 않아 배팅이 거절됩니다.')
              break
            case 'VIRTUAL-1013':
              alert('마감된 경기가 있어 거절됩니다.')
              break
            case 'VIRTUAL-1014':
              alert('추가 배팅이 불가능한 배팅건이 있어 거절됩니다.')
              break
            default:
              alert('배팅 실패했습니다. 잠시후 다시 시도해주세요.')
          }

          dispatch(setBettingCartLoading(false))
          setApiFlag(false)
        })
        .finally(() => {
          dispatch(setBettingCartLoading(false))
          setApiFlag(false)
          onClickResetMoneyHandler()
          dispatch(removeAllBettingCartItem())
          dispatch(fetchMemberInfoAction())
        })
    } else {
      dispatch(setBettingCartLoading(false))
      setApiFlag(false)
    }
  }

  useEffect(() => {
    // 다른페이지 배팅카트 초기화
    onClearSportsBettingCart()
  }, [location])

  const onClickCloseHandler = () => {
    dispatch(onClickBettingCartAlertCloseHandler())
  }

  const onClickBettingCartUpdateCloseHandler = () => {
    dispatch(onClickBettingCartUpdateMessageCloseHandler())
  }

  const getPriceBox = item => {
    // 핸디
    if (item.marketInfo.marketId === 3) {
      return (
        <span>
          {item.marketInfo.marketName}({item.fixture.handicapPoint.toFixed(1)}) -{' '}
          {VirtualGameBettingTypeKr[item.selectedBetInfo.betType]}
        </span>
      )
    }

    // 오버언더
    if (item.marketInfo.marketId === 4) {
      return (
        <span>
          {item.marketInfo.marketName}({item.fixture.overUnderPoint.toFixed(1)}) -{' '}
          {VirtualGameBettingTypeKr[item.selectedBetInfo.betType]}
        </span>
      )
    }

    // 우승 맞추기
    if (item.marketInfo.marketId === 8) {
      return (
        <span>
          {item.marketInfo.marketName}, {item.selectedBetInfo.raceNumber}-{item.selectedBetInfo.raceName}
        </span>
      )
    }

    return (
      <span>
        {item.marketInfo.marketName} - {VirtualGameBettingTypeKr[item.selectedBetInfo.betType]}
      </span>
    )
  }

  const getBetTeamNameBox = item => {
    const { sportType } = item.fixture

    const notRaceTypes = ['VIRTUAL_SOCCER', 'VIRTUAL_BASKETBALL', 'VIRTUAL_BASEBALL']
    const raceTypes = ['VIRTUAL_GREYHOUNDS', 'VIRTUAL_HORSE']

    if (notRaceTypes.includes(sportType)) {
      return <>{item.fixture?.homeName && `${item.fixture.homeName} vs ${item.fixture.awayName}`}</>
    }

    if (raceTypes.includes(sportType)) {
      return <>{item.fixture.leagueName}</>
    }
  }

  const [scrollY, setScrollY] = useState(0)
  const betCartRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      if (!betCartRef.current) return

      const szNowTop = parseInt(betCartRef.current.style.top || '0', 10)
      const nBetween = scrollTop - szNowTop

      if (nBetween !== 0) {
        let newContentHeight = scrollTop
        if (scrollTop > 165) {
          newContentHeight = scrollTop - 165
        } else {
          newContentHeight = 0
        }
        setScrollY(newContentHeight)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [hideIconCart, setHideIconCart] = useState(false)
  const [bettingCartWrapperHeight, setBettingCartWrapperHeight] = useState('70px')
  const [bettingCartInnerHeight, setBettingCartInnerHeight] = useState('45px')

  const toggleIconCart = () => {
    setHideIconCart(prevShowBettingCart => !prevShowBettingCart)
  }

  useEffect(() => {
    if (hideIconCart) {
      setBettingCartWrapperHeight('430px')
      setBettingCartInnerHeight('405px')
    } else {
      setBettingCartWrapperHeight('70px')
      setBettingCartInnerHeight('45px')
    }
  }, [hideIconCart])

  const mobileMenuRef = useRef()
  const clickMenuOutside = event => {
    if (hideIconCart && !mobileMenuRef.current.contains(event.target)) {
      toggleIconCart()
    }
  }

  return (
    <>
      {apiFlag && <CustomLoading />}

      {bettingCartAlertMessage && (
        <CustomAlert info={bettingCartAlertMessage} onClickCloseHandler={onClickCloseHandler} />
      )}
      {bettingCartUpdateMessage && (
        <CustomAlert info={bettingCartUpdateMessage} onClickCloseHandler={onClickBettingCartUpdateCloseHandler} />
      )}
      <BettingCartWrapper id="footer_cart" ht={bettingCartWrapperHeight} ref={mobileMenuRef}>
        <div id="cart_btn1">
          {hideIconCart ? (
            <img
              src={`${process.env.PUBLIC_URL}/img/sports/common/fct2.png`}
              onClick={() => toggleIconCart()}
              id="ftbtnimg"
              alt=""
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/img/sports/common/fct1.png`}
              onClick={() => toggleIconCart()}
              id="ftbtnimg"
              alt=""
            />
          )}
        </div>

        <BettingCartInnerWrapper id="ftcartwrap" ht={bettingCartInnerHeight}>
          <BettingCartListBox id="betting_result" show={hideIconCart}>
            {bettingCartList.map(item => {
              const isLock = false

              return (
                <div id="bet_item_0">
                  <div style={{ height: '1px' }} />
                  <div className="bet_item" style={{ position: 'relative' }}>
                    {isLock && (
                      <BettingWhatWrapOverlay>
                        <BettingWhatLockIcon />
                      </BettingWhatWrapOverlay>
                    )}
                    <table cellPadding="0" cellSpacing="0" border="0" width="100%" align="center">
                      <tbody>
                        <tr>
                          <td style={{ lineHeight: '9px' }}>
                            <span className="team_on long" id="phn0">
                              {getBetTeamNameBox(item)}
                            </span>
                            <br />
                            <span className="team" id="pan0">
                              {item.marketName}
                            </span>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            <a
                              onClick={() => {
                                onCloseSportsBettingCart(item)
                              }}
                            >
                              {getPriceBox(item)}
                              <font
                                style={{ color: '#f8b411', fontWeight: '700', fontSize: '12px', marginLeft: '5px' }}
                              >
                                (<span id="pr0">{item.selectedBetInfo.betPrice}</span>)
                              </font>
                              <img src={`${process.env.PUBLIC_URL}/img/sports/common/footer_cart_btn5.png`} alt="" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            })}
          </BettingCartListBox>

          <div id="ftcartwrap22">
            <div id="cart_info">
              <div>
                게임 :{' '}
                <b>
                  <span id="bet_cnt">{bettingCartCount}</span>
                </b>{' '}
                폴더
              </div>
              <div>
                배당 : <span id="total_rate">{totalBettingPer || 0}</span>
              </div>
            </div>

            <div id="cart_info2">
              <ul>
                <li style={{ marginRight: '2px' }}>
                  <a
                    onClick={() => {
                      onSubmit()
                    }}
                  >
                    <font className="bt_type1" style={{ width: '60px', padding: '5px 3px', fontWeight: '700' }}>
                      배팅하기
                    </font>
                  </a>
                </li>
                <li style={{ marginRight: '1px' }}>
                  <a
                    onClick={() => {
                      onClickMoneyHandler('')
                    }}
                  >
                    <font className="bt_type3" style={{ width: '30px', padding: '5px 3px', fontWeight: '700' }}>
                      맥스
                    </font>
                  </a>
                </li>
                <li style={{ marginRight: '5px', color: '#fff' }}>
                  <input
                    name="BettingMoney"
                    id="BettingMoney"
                    value={inputBettingMoney}
                    style={{ border: 'solid 1px #fff !important' }}
                    onChange={e => {
                      const value = e.target.value.trim()
                      const numbersOnly = value.match(/\d+/g)?.join('')

                      if (!numbersOnly) {
                        setInputBettingMoney('0')
                      } else {
                        setInputBettingMoney(numbersOnly.replace(/^0+/, '').replace(commonReg2, ','))
                      }
                    }}
                  />
                  <span style={{ color: '#fff' }}>&nbsp;원</span>
                </li>
              </ul>
            </div>
          </div>

          <div id="betting_quota_money_wrap">
            적중 예상금액 :{' '}
            <b>
              <font id="result_money">{expectMoney?.toString().replace(commonReg2, ',') || 0}</font>
            </b>
            &nbsp;원
          </div>
        </BettingCartInnerWrapper>
      </BettingCartWrapper>
    </>
  )
}

export default MobileVirtualBettingCartSidebar

const slideInFromRight = keyframes`
  from {
    transform: translateY(100%); /* 시작 지점을 오른쪽 끝으로 설정 */
    opacity: 0;
  }
  to {
    transform: translateY(0); /* 끝 지점을 오른쪽으로부터 이동 없음으로 설정 */
    opacity: 1;
  }
`

const BettingCartWrapper = styled.div`
  transition: all 0.5s ease-out 0s;
  animation: ${slideInFromRight} 0.5s ease-out;
  height: ${props => props.ht} !important;

  z-index: 301;
`

const BettingCartInnerWrapper = styled.div`
  transition: all 0.5s ease-out 0s;
  animation: ${slideInFromRight} 0.5s ease-out;
  height: ${props => props.ht} !important;
  overflow-y: auto;
`

const BettingCartListBox = styled.div`
  min-height: 150px;
  display: ${props => (props.show ? 'block' : 'none')};
`

const BettingWhatWrapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 302;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
`

const BettingWhatLockIcon = styled(FaLock)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`
