import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const BettingHistoryRadio = ({ sportsBettingLogKey, sportsResultStatus, checkRadio, setCheckRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        if (sportsResultStatus === 'ING') {
          alert('진행중 건은 삭제하실 수 없습니다.')
          break
        }
        setCheckRadio(prev => [...prev, sportsBettingLogKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== sportsBettingLogKey))
        break
      default:
        break
    }
  }
  return (
    <div>
      <CustomCheckInput
        style={{ width: '100%' }}
        checked={sportsResultStatus === 'ING' ? false : checkRadio.find(e => e === sportsBettingLogKey)}
        type="checkbox"
        name="chech"
        onChange={e => {
          approveHandler(e)
        }}
      />
    </div>
  )
}
export default BettingHistoryRadio

BettingHistoryRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  sportsBettingLogKey: PropTypes.string,
  sportsResultStatus: PropTypes.string,
}

BettingHistoryRadio.defaultProps = {
  sportsBettingLogKey: null,
  sportsResultStatus: null,
  checkRadio: [],
}

const CustomCheckInput = styled.input`
  accent-color: ${props => (props.checked ? '#000' : 'initial')};
`
