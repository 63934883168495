import React, { useState } from 'react'
import PropTypes from 'prop-types'

const MobileMiniGameBettingHistoryRadio = ({ miniGameBettingLogKey, bettingResult, checkRadio, setCheckRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        if (bettingResult === 'ING') {
          alert('진행중 건은 삭제하실 수 없습니다.')
          break
        }
        setCheckRadio(prev => [...prev, miniGameBettingLogKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== miniGameBettingLogKey))
        break
      default:
        break
    }
  }
  return (
    <div>
      <input
        style={{ width: '100%', minWidth: '13px' }}
        checked={bettingResult === 'ING' ? false : checkRadio.find(e => e === miniGameBettingLogKey)}
        type="checkbox"
        name="chech"
        onChange={e => {
          approveHandler(e)
        }}
      />
    </div>
  )
}
export default MobileMiniGameBettingHistoryRadio

MobileMiniGameBettingHistoryRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  miniGameBettingLogKey: PropTypes.string,
  bettingResult: PropTypes.string,
}

MobileMiniGameBettingHistoryRadio.defaultProps = {
  miniGameBettingLogKey: null,
  bettingResult: null,
  checkRadio: [],
}
