import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

/**
 * 서버 to 프론트 convert
 *
 */
export const convertFromServer = content => {
  if (content) {
    return EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(content)))
  }
  return EditorState.createEmpty()
}

/**
 * 프론트 to 서버 convert
 *
 */
export const convertFromFront = content => {
  return draftToHtml(convertToRaw(content.getCurrentContent()), {
    inlineStyles: false,
  })
}

/**
 * ... 처리
 */
const MAX_LENGTH = 10
export const convertLongData = value => {
  if (!value) {
    return 0
  }

  if (value?.length <= MAX_LENGTH) {
    return value
  }
  return `${value.substring(0, MAX_LENGTH)} ...`
}
