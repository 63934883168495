import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { searchFriendList } from '../../../api/memberHistory/memberHistoryApi'
import CustomLoading from '../../CustomLoading'

import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import FriendListData from './FriendListData'

const FriendList = () => {
  /**
   지인 조회
   */
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [friendListparams, setFriendListparamsParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setFriendListparamsParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    searchFriendList(friendListparams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [friendListparams])

  const [friendListData, setFriendListData] = useState(FriendListData(content))
  useEffect(() => {
    setFriendListData(FriendListData(content))
  }, [content])

  const [rows, setData] = useState(friendListData.tableRowsData)
  useEffect(() => {
    setData(friendListData.tableRowsData)
  }, [friendListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <>
      {loading && <CustomLoading />}
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            <div>
              <table className="tb_notice_box">
                <tbody>
                  <tr>
                    <td className="td_notice_td">
                      <b>
                        <span
                          style={{ fontSize: '11pt', color: 'rgb(0, 0, 0)' }}
                          onClick={() => {
                            navigate('/one-to-one')
                          }}
                        >
                          지인추천시 고객센터 문의 바랍니다.
                        </span>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="height_20" />
            <div>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: '140px' }}>
                      <div>
                        <a
                          className="buttonoff"
                          onClick={() => {
                            navigate('/mypage')
                          }}
                        >
                          회원정보
                        </a>
                      </div>
                    </td>
                    <td style={{ width: '4px' }} />
                    <td style={{ width: '140px' }}>
                      <div>
                        <a
                          className="buttonoff"
                          onClick={() => {
                            navigate('/money/moneyexchange')
                          }}
                        >
                          머니변동내역
                        </a>
                      </div>
                    </td>
                    <td style={{ width: '4px' }} />
                    <td style={{ width: '140px' }}>
                      <div>
                        <a
                          className="buttonon"
                          onClick={() => {
                            navigate('/friend-list')
                          }}
                        >
                          추천목록
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="height_20" />
            <div id="CPH_recommend_list" className="div_list">
              <table style={{ textAlign: 'center' }}>
                <colgroup>
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '40%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>추천인명</th>
                    <th>추천일자</th>
                    <th>발생포인트</th>
                  </tr>
                </thead>
                <tbody>
                  {totalElement > 0 ? (
                    <>
                      {content.map(item => {
                        return (
                          <tr>
                            <td>{item.friendId}</td>
                            <td>{convertToKstDateTime(item.registrationDate)}</td>
                            <td>{item.rollingPoint?.toString().replace(commonReg2, ',')}</td>
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="6">추천인 내역이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
    // <WebPageWrap>
    //   <MyPageHeaderWrap>
    //     <MyPageHeaderInfo>지인내역</MyPageHeaderInfo>
    //   </MyPageHeaderWrap>
    //   {loading ? (
    //     <CustomLoading />
    //   ) : (
    //     <MyPageContentWrap>
    //       <ReactTableBase columns={friendListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
    //     </MyPageContentWrap>
    //   )}
    // </WebPageWrap>
  )
}

export default FriendList
