import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { searchNormalPopupImgList } from '../../api/popupImg/popupImgApi'
import MainPageSportsBalanceContent from '../SportsGame/balance/MainPageSportsBalanceContent'

const MainPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.state?.alertMessage) {
      alert(location.state.alertMessage)
      navigate(location.pathname, { replace: true })
    }
  }, [])

  const [locationFlag, setLocationFlag] = useState(false)
  useEffect(() => {
    if (location.pathname === '/main') {
      setLocationFlag(true)
      return
    }
    setLocationFlag(false)
  }, [location])

  const [popupList, setPopupList] = useState([])

  const checkVisible = title => {
    const check = localStorage.getItem(`popup-${title}`)
    const today = new Date().getDate()
    if (!check) {
      return true
    }
    return Number(check) !== today
  }

  useEffect(() => {
    searchNormalPopupImgList({ page: 0, size: 4 }).then(res => {
      const filteredItems = res.data.content.filter(item => !item.mobileBigPopup)
      setPopupList(
        filteredItems.map(item => ({
          title: item.title,
          imgName: item.imgName,
          visible: checkVisible(item.title),
        })),
      )
    })
  }, [])
  useEffect(() => {
    if (popupList.filter(el => el.visible).length <= 0) {
      return () => {
        document.body.style = `overflow: auto`
      }
    }

    if (location.pathname !== '/main') {
      return () => {
        document.body.style = `overflow: auto`
      }
    }

    document.body.style = `overflow: hidden`
    return () => {
      document.body.style = `overflow: auto`
    }
  }, [popupList, location])

  const [visible, setVisible] = useState(true)
  const [totalVisible, setTotalVisible] = useState(false)

  const checkTotalVisible = () => {
    const visiblePopup = popupList.filter(value => value.visible === true)

    if (visiblePopup.length > 0) {
      setTotalVisible(true)
    } else {
      setTotalVisible(false)
    }
  }
  // 오늘 그만보기 , 닫기 핸들러

  const closeHandler = (e, isDayClose = false) => {
    checkTotalVisible()
    const title = e.target.getAttribute('value')

    // 오늘 그만보기 일때
    if (isDayClose) {
      // +1일 계산
      const expiredDate = new Date().getDate()
      // 로컬스토리지 저장
      localStorage.setItem(`popup-${title}`, expiredDate)
    }
    setVisible(false)
    setPopupList(prev => prev.map(item => (item.title === `${title}` ? { ...item, visible: false } : item)))
  }

  useEffect(() => {
    checkTotalVisible()
  }, [popupList])

  const getPopupStyle = index => {
    const positions = [
      { left: '0px', top: '0px' },
      { left: '0px', top: '240px' },
      { left: '603px', top: '0px' },
      { left: '603px', top: '240px' },
    ]

    return positions[index % 4]
  }

  return (
    <>
      <div className="div_content" style={{ position: 'relative', minHeight: '500px' }}>
        <div>
          {popupList.map((item, index) => {
            if (!item.visible) return null
            const { left, top } = getPopupStyle(index)

            return (
              <div style={{ height: '0px' }} id={item.title}>
                <div
                  style={{
                    zIndex: '99999',
                    border: '0px solid #00a131',
                    left,
                    top,
                    width: '597px',
                    position: 'relative',
                    backgroundColor: '#fff',
                    borderLeft: 'solid 0x #ddd',
                    borderRight: 'solid 0px #ddd',
                    borderBottom: 'solid 1px #ddd',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                  }}
                >
                  <div
                    style={{
                      height: '200px',
                      padding: '0px',
                      overflowY: 'auto',
                      wordBreak: 'break-all',
                      cursor: item.title === '제우스 사칭주의' && 'pointer',
                    }}
                    onClick={item.title === '제우스 사칭주의' ? () => window.open('https://t.me/Zeus_CSCS') : undefined}
                  >
                    <img
                      src={item.imgName}
                      // src={`${process.env.PUBLIC_URL}/img/popup/normalpopup1.png`}
                      alt=""
                      title="ggurl18.png"
                      style={{ display: 'block', width: '100%', height: '198px' }}
                    />
                  </div>
                  <div
                    style={{
                      verticalAlign: 'middle',
                      backgroundColor: '#fff',
                      padding: '2px',
                      lineHeight: '16px',
                      margin: '5px',
                    }}
                  >
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '12px' }}>
                            <input
                              type="checkbox"
                              value="N"
                              id="today_pop_8"
                              style={{ marginTop: '1px', width: '12px', height: '12px', cursor: 'pointer' }}
                            />
                          </td>
                          <td style={{ textAlign: 'left' }}>
                            <label
                              htmlFor="today_pop_8"
                              style={{ cursor: 'pointer' }}
                              value={item.title}
                              onClick={e => {
                                closeHandler(e, true)
                              }}
                            >
                              오늘은 그만보기
                            </label>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            <a
                              style={{ color: 'black' }}
                              value={item.title}
                              onClick={e => {
                                closeHandler(e)
                              }}
                            >
                              닫기
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <MainPageSportsBalanceContent />
      </div>
    </>
  )
}

export default MainPage

const MainPageWrap = styled.div`
  width: 1277px;
`
const MainPageContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
