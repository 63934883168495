import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { Card, CardBody } from '@/shared/components/Card'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import MobileCouponListData from './MobileCouponListData'
import { searchCouponUseList, useSearchCouponUseList } from '../../../api/memberHistory/memberHistoryApi'
import CustomLoading from '../../../containers/CustomLoading'
import { MyPageContentWrap, MyPageHeaderInfo, MyPageHeaderWrap } from '../../../containers/MyPage/MyPageElement'
import {
  MobileCustomerCenterContentWrap,
  MobileCustomerCenterHeaderInfo,
  MobileCustomerCenterHeaderWrap,
  MobileTopWrap,
  MobileTableWrap,
} from '../../../shared/components/MoneyCustomElement'

const MobileCouponList = () => {
  /**
   쿠폰 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [couponUseListparams, setCouponUseListparamsParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setCouponUseListparamsParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    searchCouponUseList(couponUseListparams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [couponUseListparams])

  const [couponListData, setCouponListData] = useState(MobileCouponListData(content))
  useEffect(() => {
    setCouponListData(MobileCouponListData(content))
  }, [content])

  const [rows, setData] = useState(couponListData.tableRowsData)
  useEffect(() => {
    setData(couponListData.tableRowsData)
  }, [couponListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <MobileTopWrap>
      <MobileCustomerCenterHeaderWrap>
        <MobileCustomerCenterHeaderInfo>쿠폰 내역</MobileCustomerCenterHeaderInfo>
      </MobileCustomerCenterHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <MobileCustomerCenterContentWrap>
          <MobileTableWrap>
            <ReactTableBase columns={couponListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </MobileTableWrap>
        </MobileCustomerCenterContentWrap>
      )}
    </MobileTopWrap>
  )
}

export default MobileCouponList
