export const NoteAuthorityEnums = {
  BONSA: '본사',
  BOOBONSA: '부본사',
  CHONGPAN: '총판',
  MAEJANG: '매장',
  MEMBER: '회원',
}

export const NoteAuthoritySelectOptions = () => {
  return Object.entries(NoteAuthorityEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const NoteReadStatusEnums = {
  READ: '확인',
  NOREAD: '미확인',
}

export const NoteReadStatusSelectOptions = () => {
  return Object.entries(NoteReadStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
