export const VirtualGameSportType = {
  VIRTUAL_SOCCER: '가상 축구',
  VIRTUAL_BASKETBALL: '가상 농구',
  VIRTUAL_BASEBALL: '가상 야구',
  VIRTUAL_GREYHOUNDS: '개 경주',
  VIRTUAL_HORSE: '경마',
}

export const VirtualSoccerLeagueType = {
  VIRTUAL_PREMIERSHIP: 'Premiership',
  VIRTUAL_SUPERLEAGUE: 'Superleague',
  VIRTUAL_EURO_CUP: 'Euro Cup',
  VIRTUAL_WORLD_CUP: 'World Cup',
}

export const VirtualBasketBallLeagueType = {
  VIRTUAL_BAKER_GARDENS: 'Baker Gardens',
  VIRTUAL_WATERPORT_ARENA: 'Waterport Arena',
}

export const VirtualBaseBallLeagueType = {
  VIRTUAL_BRANDON_FIELD: 'Brandon Field',
  VIRTUAL_NORTH_STADIUM: 'North Stadium',
}

export const VirtualGreyhoundsLeagueType = {
  VIRTUAL_BENNETT_PARK: 'Bennett Park',
  VIRTUAL_GOLDENHILL_PARK: 'Goldenhill Park',
  VIRTUAL_HILLSIDE_PARK: 'Hillside Park',
}

export const VirtualHorseLeagueType = {
  VIRTUAL_FESTIVAL_DOWNS: 'Festival Downs',
  VIRTUAL_BRITANNIA_WAY: 'Britannia Way',
  VIRTUAL_VICTORIA_PARK: 'Victoria Park',
  VIRTUAL_TRENTHAM_PARK: 'Trentham Park',
  VIRTUAL_NATIONAL_PARK: 'National Park',
}

export const VirtualGameLeagueType = {
  VIRTUAL_EURO_CUP: 'Euro Cup',
  VIRTUAL_PREMIERSHIP: 'Premiership',
  VIRTUAL_SUPERLEAGUE: 'Superleague',
  VIRTUAL_WORLD_CUP: 'World Cup',

  /**
   * 가상 농구
   * */
  VIRTUAL_BAKER_GARDENS: 'Baker Gardens',
  VIRTUAL_WATERPORT_ARENA: 'Waterport Arena',

  /**
   * 가상 야구
   * */
  VIRTUAL_BRANDON_FIELD: 'Brandon Field',
  VIRTUAL_NORTH_STADIUM: 'North Stadium',
  /**
   * 개 경주
   * */
  VIRTUAL_BENNETT_PARK: 'Bennett Park',
  VIRTUAL_GOLDENHILL_PARK: 'Goldenhill Park',
  VIRTUAL_HILLSIDE_PARK: 'Hillside Park',

  /**
   * 말 경주
   * */
  VIRTUAL_TRENTHAM_PARK: 'Trentham Park',
  VIRTUAL_NATIONAL_PARK: 'National Park',
  VIRTUAL_FESTIVAL_DOWNS: 'Festival Downs',
  VIRTUAL_BRITANNIA_WAY: 'Britannia Way',
  VIRTUAL_VICTORIA_PARK: 'Victoria Park',
}

export const VirtualGameLeagueTypeKr = {
  VIRTUAL_EURO_CUP: '유로컵',
  VIRTUAL_PREMIERSHIP: '프리미어쉽',
  VIRTUAL_SUPERLEAGUE: '슈퍼리그',
  VIRTUAL_WORLD_CUP: '월드컵',
  VIRTUAL_BAKER_GARDENS: '베이커 가든스',
  VIRTUAL_WATERPORT_ARENA: '워터포트 아레나',
  VIRTUAL_BRANDON_FIELD: '브랜든 필드',
  VIRTUAL_NORTH_STADIUM: '노스 스타디움',
  VIRTUAL_BENNETT_PARK: '베넷 파크',
  VIRTUAL_GOLDENHILL_PARK: '골든힐 파크',
  VIRTUAL_HILLSIDE_PARK: '힐사이드 파크',
  VIRTUAL_TRENTHAM_PARK: '트렌트햄',
  VIRTUAL_FESTIVAL_DOWNS: '페스티벌',
  VIRTUAL_BRITANNIA_WAY: '브리타니아',
  VIRTUAL_VICTORIA_PARK: '빅토리아',
}

export const VirtualGameSportTypeParam = {
  VIRTUAL_SOCCER: 'VIRTUAL_SOCCER',
  VIRTUAL_BASKETBALL: 'VIRTUAL_BASKETBALL',
  VIRTUAL_BASEBALL: 'VIRTUAL_BASEBALL',
  VIRTUAL_GREYHOUNDS: 'VIRTUAL_GREYHOUNDS',
  VIRTUAL_HORSE: 'VIRTUAL_HORSE',
}

export const VirtualGameBettingType = {
  WIN_DRAW_LOSE_HOME_PICK: 'WIN_DRAW_LOSE_HOME_PICK',
  WIN_DRAW_LOSE_DRAW_PICK: 'WIN_DRAW_LOSE_DRAW_PICK',
  WIN_DRAW_LOSE_AWAY_PICK: 'WIN_DRAW_LOSE_AWAY_PICK',
  HANDICAP_HOME_PICK: 'HANDICAP_HOME_PICK',
  HANDICAP_AWAY_PICK: 'HANDICAP_AWAY_PICK',
  UNDER_OVER_UNDER_PICK: 'UNDER_OVER_UNDER_PICK',
  UNDER_OVER_OVER_PICK: 'UNDER_OVER_OVER_PICK',
  RACE_ODD_EVEN_ODD_PICK: 'RACE_ODD_EVEN_ODD_PICK',
  RACE_ODD_EVEN_EVEN_PICK: 'RACE_ODD_EVEN_EVEN_PICK',
  DOUBLE_CHANCE_HOME_OR_DRAW: 'DOUBLE_CHANCE_HOME_OR_DRAW',
  DOUBLE_CHANCE_HOME_OR_AWAY: 'DOUBLE_CHANCE_HOME_OR_AWAY',
  DOUBLE_CHANCE_DRAW_OR_AWAY: 'DOUBLE_CHANCE_DRAW_OR_AWAY',
  EXACT_TOTAL_GOALS_ZERO: 'EXACT_TOTAL_GOALS_ZERO',
  EXACT_TOTAL_GOALS_ONE: 'EXACT_TOTAL_GOALS_ONE',
  EXACT_TOTAL_GOALS_TWO: 'EXACT_TOTAL_GOALS_TWO',
  EXACT_TOTAL_GOALS_THREE: 'EXACT_TOTAL_GOALS_THREE',
  EXACT_TOTAL_GOALS_FOUR: 'EXACT_TOTAL_GOALS_FOUR',
  RACE_WINNER_PICK: 'RACE_WINNER_PICK',
}

export const VirtualGameBettingTypeKr = {
  WIN_DRAW_LOSE_HOME_PICK: '홈팀승',
  WIN_DRAW_LOSE_DRAW_PICK: '무승부',
  WIN_DRAW_LOSE_AWAY_PICK: '원정승',
  HANDICAP_HOME_PICK: '홈팀승',
  HANDICAP_AWAY_PICK: '원정승',
  UNDER_OVER_UNDER_PICK: '언더',
  UNDER_OVER_OVER_PICK: '오버',
  RACE_ODD_EVEN_ODD_PICK: '홀',
  RACE_ODD_EVEN_EVEN_PICK: '짝',
  DOUBLE_CHANCE_HOME_OR_DRAW: '홈승 또는 무승부',
  DOUBLE_CHANCE_HOME_OR_AWAY: '홈승 또는 원정승',
  DOUBLE_CHANCE_DRAW_OR_AWAY: '무승부 또는 홈승',
  EXACT_TOTAL_GOALS_ZERO: '0 골',
  EXACT_TOTAL_GOALS_ONE: '1 골',
  EXACT_TOTAL_GOALS_TWO: '2 골',
  EXACT_TOTAL_GOALS_THREE: '3 골',
  EXACT_TOTAL_GOALS_FOUR: '4 골',

  RACE_WINNER_PICK: '우승',
}

export const VirtualResultSettlementTypeEnums = {
  NOT_SETTLED: '진행중',
  WINNER: '적중',
  LOSER: '미적중',
  REFUND: '적특',
  CANCELLED: '취소',
  HALF_LOST: '미적중',
  HALF_WON: '적중',
  INVALID: '무효',
}

export const VirtualGameResultStatusEnums = {
  ING: '진행중',
  WIN: '적중',
  LOSE: '미적중',
  DRAW: '적특',
  CANCEL: '취소',
}

export const FixtureBetResult = {
  HOME: '홈승',
  AWAY: '원정승',
  DRAW: '무승부',
  UNDER: '언더',
  OVER: '오버',
}
