import styled from 'styled-components'
import { Table as BootstrapTable } from 'react-bootstrap'
import {
  colorBackground,
  colorBackgroundBody,
  colorBlue,
  colorBorder,
  colorHeaderDataPicker,
  colorHover,
  colorTableBodyBackground,
  colorTableBodyText,
  colorTableBorder,
  colorTableHeaderBackground,
  colorTableHeaderText,
  colorText,
  colorTextAdditional,
  HermesMainBorder,
} from '../../utils/palette'
import { right, left } from '../../utils/directions'

// eslint-disable-next-line import/prefer-default-export
export const CustomTableWrap = styled.div`
  min-width: max-content;
`

export const CustomTableButton = styled.span`
  font-size: 12px;
  font-weight: 900;
  cursor: pointer;
  padding: 5px 5px;
  border: 1px solid black;
  background: lightgray;

  &:hover {
    color: red;
  }
`

export const Table = styled(BootstrapTable)`
  text-align: left;

  // Table Border 색상
  // border: 2px solid ${colorTableBorder};

  &:last-child {
    margin-bottom: 0;
  }

  // table 클래스는 여기서 적용되는듯
  &.table {
    min-width: max-content !important;
    table-layout: auto !important;
  }

  table {
    // min-width: max-content !important;
    // width: 100%;

    table-layout: auto;
  }

  thead {
    // Header 배경 색상
    background: rgba(9, 9, 10);
  }

  thead tr {
    position: sticky;
    top: 0;

    text-align: center !important;
  }

  thead span {
    text-align: center !important;
  }

  thead th {
    font-style: normal;
    font-size: 14px;
    text-align: center !important;
    line-height: 20px;
    font-weight: 500;
    min-height: 30px;
    top: 0;
    position: sticky;

    // Header 폰트 색상
    color: ${colorTableHeaderText};

    &:first-child {
      min-width: 45px;
    }
  }

  tbody {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    overflow-x: auto !important;

    // Body 배경 색
    background: rgb(22, 23, 25);
  }

  tbody td,
  tfoot td,
  th {
    padding: 5px 5px;
    color: ${colorTableBodyText};

    border: 1px solid black;
  }

  // 새로 추가
  tbody td {
    min-width: max-content;
  }

  th,
  td {
    width: auto;
    vertical-align: middle;
    background: transparent !important;
    justify-content: center;
    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }

  .badge {
    font-size: 100%;
  }

  tfoot {
    tr {
      border-top: 1px solid #eff1f5;
      border-bottom: none;
    }

    td {
      min-height: 45px;
      min-width: 100px;
      padding: 10px 5px 10px 5px;
      // font-family: 'Roboto', sans-serif;
      color: #646777;
      font-weight: 700;

      &:first-child {
        min-width: 45px;
        white-space: nowrap;
      }
    }
  }

  ${props =>
    props.bordered &&
    `
    text-align: left;

    tr {
      // border-top: none;
      // border-bottom: 1px solid #eff1f5;
    }

    tbody tr {
      height: 40px;
      &:hover {
        cursor: pointer; 
        border: solid 1px ${HermesMainBorder} !important;
      }
    }
    

    tbody tr:last-child {
      // border-bottom: none;
    }
  `}

  &.table-hover {
    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: #f2f4f7;
      }
    }
  }

  &.react-table {
    thead tr {
      position: sticky;
      top: 0;
      background: white;
    }
  }

  .react-table__column-header {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    text-align: center !important;

    min-width: max-content;

    &.sortable {
      cursor: pointer;

      svg {
        margin-left: 10px;
        width: max-content;
        height: 16px;
        right: -20px;
      }
    }
  }

  &.resizable-table {
    thead {
      min-width: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;
      }

      // // Header 배경 색상
      // background: yellow;
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 100px !important;
      width: auto !important;

      &:first-child {
        max-width: 60px;
        min-width: 60px !important;
      }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action: none;
        background: #eff1f5;

        &.isResizing {
          background: #6db0ff;
        }
      }
    }
  }

  .react-dnd--off {
    pointer-events: none;
  }
`
