import styled from 'styled-components'

/**
 * 사용할 곳에서 재정의 해서 쓰기
 * position, width 등등.
 */

/**
 * 둥근...실버 버튼 H40
 */

const BlackButtonH40 = styled.button`
  box-sizing: border-box;

  width: 80px;
  height: 40px;

  // css 나온대로 쓰고
  background: linear-gradient(180deg,#ffee00 23.07%, #000000 91.02%);
  box-shadow: 0px 0px 3px 0px #000000 inset;

  border-radius: 60px;

  border: 2px solid transparent;

  // Colors - 위아래 순서 바꾸기!!
  background-image: linear-gradient(180deg, #573902 23.07%, #786200 91.02%);
  linear-gradient(180deg, #ffffff 0%, #424242 58.85%, #c1c1c1 63.02%, #dedede 83.85%, #313131 100%);

  background-origin: border-box;
  background-clip:  border-box;

  // 마우스 호버
  &:hover {
    cursor: pointer;

    // css 나온대로 쓰고
    background: #000000;

    background: linear-gradient(0deg, #000000, #000000),
      linear-gradient(180deg, #ffffff 0%, #424242 58.85%, #c1c1c1 63.02%, #dedede 83.85%, #313131 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    cursor: pointer;

    background: #383838;

    background: linear-gradient(0deg, #383838, #383838),
      linear-gradient(180deg, #ffffff 0%, #424242 58.85%, #c1c1c1 63.02%, #dedede 83.85%, #313131 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 비활성화
  &:disabled {
    box-shadow: none;
    background: #242424;

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffffff4d;
  }
`

export default BlackButtonH40
