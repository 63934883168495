import React from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import PacmanLoader from 'react-spinners/PacmanLoader'
import BeatLoader from 'react-spinners/BeatLoader'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { FaStar, FaEnvelope } from 'react-icons/fa'

import { useDispatch } from 'react-redux'
import { HermesMainBorder, HermesMainHover, HermesMainHover2, MainPageBackground } from '../utils/palette'
import { setNoteMessageInfo } from '../redux/memberInfoSlice'
import { onShowModalHandler } from '../redux/modalSlice'

const OneToOneAlert = ({ noteSize = 0 }) => {
  const dispatch = useDispatch()
  const onClickCloseHandler = () => {
    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title: 'ONETOONE',
      }),
    )
  }

  return (
    <NoteAlertWrap onClick={onClickCloseHandler}>
      <NoteAlertInnerWrap>
        <NoteAlertHeaderWrap>
          <NoteAlertHeaderBox>
            <strong style={{ fontWeight: '700' }}>
              <CustomFaStar />
              &nbsp;알람 내용 확인하세요&nbsp;
              <CustomFaStar />
            </strong>
          </NoteAlertHeaderBox>
        </NoteAlertHeaderWrap>
        <NoteAlertContentWrap>
          <NoteAlertContentBox>
            <span>
              <CustomFaEnvelope />
              <br />
              문의 답변 {noteSize}통이 도착했습니다.
            </span>
          </NoteAlertContentBox>
        </NoteAlertContentWrap>
      </NoteAlertInnerWrap>

      {/* <LoadingIconWrap isMobile={isMobile}>
        <LoadingHeader>
          <LoadingHeaderLogo src={`${process.env.PUBLIC_URL}/img/popup/popup-logo.png`} alt="" />
          <CloseBtn onClick={() => onClickCloseHandler()}>
            <img src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`} alt="" />
          </CloseBtn>
        </LoadingHeader>
        <LoadingInfoWrap>
          <LoadingInfo>{!info ? '데이터를 불러오는 중입니다.' : info} </LoadingInfo>
          {checkIsRequired && <LoadingInfo>쪽지함을 먼저 확인부탁드립니다.</LoadingInfo>}
          {checkIsRequired && <CheckButton onClick={() => onClickCloseHandler()}>확인</CheckButton>}
        </LoadingInfoWrap>
      </LoadingIconWrap> */}
    </NoteAlertWrap>
  )
}

export default OneToOneAlert

const NoteAlertWrap = styled.div`
  position: fixed;
  z-index: 1000000;
  right: 3%;
  bottom: 0;
  opacity: 0.85;
  display: block;
`

const NoteAlertInnerWrap = styled.div`
  border: 1px solid rgb(80, 108, 158);
  background: rgb(18, 22, 25);
  text-align: left;
  vertical-align: top;
  border-radius: 5px;

  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 7px 7px 15px 7px;
  display: block;
`

const NoteAlertHeaderWrap = styled.div`
  background: linear-gradient(180deg, #151515, #000);
  border-color: #000;

  width: 100%;
  text-align: center;
  padding: 5px;
  margin-bottom: 5px;
  display: block;
`

const NoteAlertHeaderBox = styled.span`
  color: #fff;
`

const CustomFaStar = styled(FaStar)`
  color: #ff1515;
  margin-top: -1px;
`

const NoteAlertContentWrap = styled.div`
  text-align: center;
  clear: both;
  width: 100%;
  padding: 0;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background: #121619;
  color: #f59c1a;
  width: 170px;
  padding: 5px;
  display: block;
  cursor: pointer;
`

const CustomFaEnvelope = styled(FaEnvelope)`
  color: #49b6d6 !important;
  width: 80px;
  height: auto;
  margin-bottom: 5px;
  display: inline-block;
  cursor: pointer;

  path {
    cursor: pointer;
  }
`

const NoteAlertContentBox = styled.div`
  clear: both;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;

  span {
    cursor: pointer;
  }
`

const CheckButton = styled.div`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background: ${HermesMainHover2};
  }
  width: 100px;
  height: 30px;
  color: #fff;
  background: ${HermesMainBorder};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AlertOverlay = styled.div`
  position: ${props => (props.isMobile ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
`

const LoadingIconWrap = styled.div`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
  height: 200px;
  background-color: ${MainPageBackground};

  display: flex;
  flex-direction: column;

  border: 1px solid ${HermesMainBorder};
  z-index: 900;

  ${props =>
    props.isMobile &&
    `
    position: absolute;
    top: 35vh;
    width: 100%;
    width: calc(100% - 10px);
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 300;
`}
`
const LoadingIcon = styled(BeatLoader)`
  position: relative;
  top: 40px;
  margin: 0 auto;
`

const LoadingInfoWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const LoadingInfo = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  width: 100%;
  color: #fff;
`

const LoadingHeader = styled.div`
  height: 60px;
  border: 1px solid ${HermesMainBorder};

  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const LoadingHeaderLogo = styled.img`
  width: 200px;
  height: 50px;
`

export const CloseBtn = styled.div`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background: ${HermesMainHover2};
  }
  position: absolute;
  right: 2%;
  width: 40px;
  height: 40px;
  background: ${HermesMainBorder};
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
`
