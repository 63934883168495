import React, { useState } from 'react'
import PropTypes from 'prop-types'

const VirtualGameBettingHistoryRadio = ({ virtualBettingLogKey, virtualResultStatus, checkRadio, setCheckRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        if (virtualResultStatus === 'ING') {
          alert('진행중 건은 삭제하실 수 없습니다.')
          break
        }
        setCheckRadio(prev => [...prev, virtualBettingLogKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== virtualBettingLogKey))
        break
      default:
        break
    }
  }
  return (
    <div>
      <input
        style={{ width: '100%' }}
        checked={virtualResultStatus === 'ING' ? false : checkRadio.find(e => e === virtualBettingLogKey)}
        type="checkbox"
        name="chech"
        onChange={e => {
          approveHandler(e)
        }}
      />
    </div>
  )
}
export default VirtualGameBettingHistoryRadio

VirtualGameBettingHistoryRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  virtualBettingLogKey: PropTypes.string,
  virtualResultStatus: PropTypes.string,
}

VirtualGameBettingHistoryRadio.defaultProps = {
  virtualBettingLogKey: null,
  virtualResultStatus: null,
  checkRadio: [],
}
