import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { fetchSportsBonusFolder, setMarketTapActive, setSportsType } from '../../redux/sportsGameBettingSlice'
import CustomLoading from '../CustomLoading'
import SportsEventList from './SportsEventList'
import SportsMarketList from './SportsMarketList'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../utils/palette'

const SportsGamePage = () => {
  const dispatch = useDispatch()

  const { sportsTypeInfo, sportsType, loading, loadingInfo } = useSelector(state => {
    const { sportsGameBetting } = state

    return {
      sportsTypeInfo: sportsGameBetting.sportsTypeInfo,
      sportsType: sportsGameBetting.selectedSportsType,
      loading: sportsGameBetting.loading,
      loadingInfo: sportsGameBetting.loadingInfo,
    }
  })

  useEffect(() => {
    dispatch(fetchSportsBonusFolder())
  }, [])

  const [showAddSportsType, setShowAddSportsType] = useState(false)

  const onClickSportsEventType = info => {
    dispatch(setSportsType(info))
    setSportsType('TODAY_SORT')
    // setPage(0)
  }

  const getSportsTypeIconUrl = type => {
    switch (type) {
      case 'SOCCER':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-soccer.png`} alt="" />
      case 'BASKETBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-basketball.png`} alt="" />
      case 'BASEBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-baseball.png`} alt="" />
      case 'VOLLEYBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-volleyball.png`} alt="" />
      case 'ICE_HOCKEY':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-ice-hockey.png`} alt="" />
      case 'HANDBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-handball.png`} alt="" />
      case 'TENNIS':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-tennis.png`} alt="" />
      case 'AMERICAN_FOOTBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-football.png`} alt="" />
      case 'TABLE_TENNIS':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-table-tennis.png`} alt="" />
      case 'BOXING':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-boxing.png`} alt="" />
      case 'E_SPORTS':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-esports.png`} alt="" />
      default:
        return <div style={{ width: '16px', height: '23px' }} />
    }
  }

  return (
    <SportsGamePageWrap>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart info={loadingInfo || ''} />
        </SportsGamePageLoadingOverlay>
      )}
      <SportsGameContentBox>
        <SportsGameHeaderBox>
          <SportsGameHeaderInnerBox>
            <div style={{ width: '100%', position: 'relative' }}>
              <div style={{ position: 'relative' }}>
                <SportsEventTypeListBox>
                  {sportsTypeInfo.slice(0, 6).map((item, index) => (
                    <SportsEventTypeItemBox
                      onClick={() => {
                        onClickSportsEventType(item.sportsType === 'ALL' ? null : item.sportsType)
                      }}
                      active={(item.sportsType === 'ALL' && sportsType === null) || item.sportsType === sportsType}
                    >
                      <SportsEventTypeIcon>{getSportsTypeIconUrl(item.sportsType)}</SportsEventTypeIcon>
                      <SportsEventTypeTitle>{item.typeKr}</SportsEventTypeTitle>
                      <SportsEventTypeCount>{item.count}</SportsEventTypeCount>
                    </SportsEventTypeItemBox>
                  ))}
                </SportsEventTypeListBox>
                <SportsEventAddListBox
                  onClick={() => {
                    setShowAddSportsType(true)
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/sidebar/etc.png`} alt="" />
                </SportsEventAddListBox>
              </div>
            </div>
          </SportsGameHeaderInnerBox>
          {showAddSportsType && (
            <SportsEventAddListBoxContent>
              <SportsEventAddListBoxClose
                onClick={() => {
                  setShowAddSportsType(false)
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`} alt="" />
              </SportsEventAddListBoxClose>
              {sportsTypeInfo.slice(6, 12).map(item => (
                <div style={{ position: 'relative', display: 'block' }}>
                  <SportsEventAddListBoxItem
                    onClick={() => {
                      onClickSportsEventType(item.sportsType === 'ALL' ? null : item.sportsType)
                      setShowAddSportsType(false)
                    }}
                    active={(item.sportsType === 'ALL' && sportsType === null) || item.sportsType === sportsType}
                  >
                    <SportsEventAddListBoxItemIcon>
                      {getSportsTypeIconUrl(item.sportsType)}
                    </SportsEventAddListBoxItemIcon>
                    <SportsEventAddListBoxItemTitle>{item.typeKr}</SportsEventAddListBoxItemTitle>
                    <SportsEventAddListBoxItemCount>{item.count}</SportsEventAddListBoxItemCount>
                  </SportsEventAddListBoxItem>
                </div>
              ))}
            </SportsEventAddListBoxContent>
          )}
        </SportsGameHeaderBox>

        <SportsGameContentWrap>
          <SportsEventList />

          <SportsMarketList />
        </SportsGameContentWrap>
      </SportsGameContentBox>
    </SportsGamePageWrap>
  )
}

export default SportsGamePage

const SportsGamePageWrap = styled.div`
  width: 1277px;
`

const SportsGameContentBox = styled.div`
border-radius: 0 0 3px 3px;
background: #111314
position: relative;
display: block;
`

const SportsGameHeaderBox = styled.div`
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-top: 48px;
  box-sizing: border-box;
  position: relative;
`

const SportsGameHeaderInnerBox = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
`

const SportsEventTypeListBox = styled.div`
  width: calc(100% - 32px);
  float: left;
  display: block;
`

const SportsEventTypeItemBox = styled.div`
  float: left;
  background: #1c1f21;
  border-radius: 16px;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  margin-right: 16px;
  min-width: 120px;
  width: calc(16.6% - 16px);
  transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s;
  display: block;
  padding-right: 20px;

  // active 일 때,
  ${props =>
    props.active &&
    `
    background: ${HermesMainHover2};
    font-weight: 700;
 `}

  &:hover {
    background: ${HermesMainSelect};
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const SportsEventTypeIcon = styled.div`
  float: left;
  margin-right: 12px;
  display: block;

  img {
    margin-top: 3px;
    width: 16px;
    height: 16px;
  }
`

const SportsEventTypeTitle = styled.div`
  float: left;
  margin-top: 2px;
  display: block;
`

const SportsEventTypeCount = styled.div`
  float: right;
  margin-top: 2px;
  display: block;
`

const SportsEventAddListBox = styled.div`
  float: right;
  cursor: pointer;

  img {
    cursor: pointer;
    margin-top: 3px;
    border: 0;
  }
`

const SportsEventAddListBoxContent = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 999;
  background: rgb(28, 31, 33);
  padding: 8px;
  border-radius: 6px;
  display: block;
`

const SportsEventAddListBoxClose = styled.div`
  display: inline-block;
  width: 100%;
  padding: 12px;

  img {
    width: 14px;
    height: 14px;
    float: right;
    cursor: pointer;
  }
`

const SportsEventAddListBoxItem = styled.div`
  padding: 12px;
  background: #272b2e;
  border-radius: 6px;
  display: inline-block;
  width: 100%;
  cursor: pointer;

  ${props =>
    props.active &&
    `
    background: ${HermesMainBorder};
    color: #fff; 
    `}

  &:hover {
    background: ${HermesMainBorder};
    color: #fff;
  }
`

const SportsEventAddListBoxItemIcon = styled.div`
  float: left;
  margin-right: 12px;

  img {
    margin-top: 3px;
    width: 16px;
    height: 16px;
  }
`
const SportsEventAddListBoxItemTitle = styled.div`
  color: #fff;
  float: left;
  margin-right: 40px;
  margin-top: 1px;
  cursor: pointer;
`
const SportsEventAddListBoxItemCount = styled.div`
  float: right;
  margin-top: 1px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsGameContentWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`
