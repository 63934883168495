import { all, fork } from 'redux-saga/effects'
import sportsDomesticSaga from './sportsDomesticSaga'
import sportsOverseasSaga from './sportsOverseasSaga'
import sportsSpecialSaga from './sportsSpecialSaga'
import sportsLiveSaga from './sportsLiveSaga'
import userInfoSaga from './userInfoSaga'
import authSaga from './authSaga'
import virtualGameSaga from './virtualGameSaga'

export default function* rootSaga() {
  yield all([
    fork(sportsDomesticSaga),
    fork(sportsOverseasSaga),
    fork(sportsSpecialSaga),
    fork(sportsLiveSaga),
    fork(userInfoSaga),
    fork(authSaga),
    fork(virtualGameSaga),
  ])
}
