import React from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import PacmanLoader from 'react-spinners/PacmanLoader'
import BeatLoader from 'react-spinners/BeatLoader'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { HermesMainBorder, MainPageBackground, colorMainText } from '../utils/palette'

const CustomLoading = ({ info, isGameStart, isMoneyExchange }) => {
  return (
    <LoadingOverLay>
      <LoadingIconWrap isMobile={isMobile} isGameStart={isGameStart} isMoneyExchange={isMoneyExchange}>
        <img src={`${process.env.PUBLIC_URL}/img/main/loading.gif`} alt="" />
        {/* <img src={`${process.env.PUBLIC_URL}/img/main/loading.gif`} alt="" /> */}
        {/* <LoadingHeader>
        <LoadingHeaderLogo src={`${process.env.PUBLIC_URL}/img/popup/popup-logo.png`} alt="" />
      </LoadingHeader>
      <LoadingIcon color={HermesMainBorder} size={35} />
      <LoadingInfoWrap>
        <LoadingInfo>{!info ? '데이터를 불러오는 중입니다.' : info}</LoadingInfo>
        <LoadingInfo>잠시만 기다려주세요.</LoadingInfo>
      </LoadingInfoWrap> */}
      </LoadingIconWrap>
    </LoadingOverLay>
  )
}

export default CustomLoading

const LoadingOverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;

  z-index: 100000;
`

const LoadingIconWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  // width: ${props => (props.isMoneyExchange ? '500px' : '350px')};
  height: 250px;
  // background-color: ${MainPageBackground};

  display: flex;
  flex-direction: column;

  // border: 1px solid ${HermesMainBorder};
  // z-index: 900;
  z-index: 100001;

  ${props =>
    props.isMobile &&
    `
    position: absolute;
    top: 50vh;
    width: 100%;
    width: calc(100% - 10px);
    // background-color: rgba(0, 0, 0, 0.8);
    z-index: 400;
`}
`
const LoadingIcon = styled(BeatLoader)`
  position: relative;
  top: 40px;
  margin: 0 auto;
`

const LoadingInfoWrap = styled.div`
  position: relative;
  top: 60px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 10px;

  justify-content: center;
  align-items: center;
`

const LoadingInfo = styled.span`
  font-style: normal;
  font-weight: 900;
  line-height: 23px;
  text-align: center;

  width: 100%;
  color: ${colorMainText};
`

const LoadingHeader = styled.div`
  height: 60px;
  border-bottom: 1px solid ${HermesMainBorder};

  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const LoadingHeaderLogo = styled.img`
  width: 200px;
  height: 50px;
`
