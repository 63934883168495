import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CustomAlert from '../../containers/CustomAlert'
import isLogin from '../../utils/enums/authority'
import MobileSignInPopup from '../MobileLayout/MobileSignIn'

const MobileMainQuickMenu = () => {
  const navigate = useNavigate()

  const {
    noteRedisSize,
    oneToOneRedisSize,
    domesticSportsUsing,
    domesticSportsName,
    overseasSportsUsing,
    overseasSportsName,
    specialSportsUsing,
    specialSportsName,
    liveSportsUsing,
    liveSportsName,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      domesticSportsUsing: memberInfo.domesticSportsUsing,
      domesticSportsName: memberInfo.domesticSportsName,
      overseasSportsUsing: memberInfo.overseasSportsUsing,
      overseasSportsName: memberInfo.overseasSportsName,
      specialSportsUsing: memberInfo.specialSportsUsing,
      specialSportsName: memberInfo.specialSportsName,
      liveSportsUsing: memberInfo.liveSportsUsing,
      liveSportsName: memberInfo.liveSportsName,
    }
  })

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)
  const onClickMenuHandler = link => {
    navigate(link)
  }

  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {authorityAlertShow && <MobileSignInPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />}
      <>
        <div style={{ height: '0px', backgroundColor: '#fff' }} />
        {/* <div className="div_kakao_line" style={{ marginTop: '-2px', textAlign: 'center' }}>
          <span
            style={{ color: '#24247e', fontSize: '16px', fontWeight: 'bold' }}
            onClick={() => {
              window.open('https://t.me/Zeus_CSCS')
            }}
          >
            텔레그램&nbsp;고객센터&nbsp;:&nbsp;@Zeus_CSCS{' '}
          </span>
        </div> */}
        {/* <div style={{ height: '5px' }} />
        <div className="div_main_button2">
          <div style={{ height: '3px' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#976038' }}>
            &nbsp;빠른메뉴
          </div>

          <div style={{ height: '0px', background: 'rgba(0,0,0,12)' }} />
          <div style={{ height: '3px' }} />

          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/money/deposit')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/charge.png`} height="45" />
                  <br />
                  충전하기
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/money/withdrawal')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/exchange.png`} height="45" />
                  <br />
                  환전하기
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/betting-history')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/bet_list.png`} height="45" />
                  <br />
                  배팅내역
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/onetoone')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/contact.png`} height="45" />
                  <br />
                  고객센터
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/note')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/message.png`} height="45" />
                  <br />
                  쪽지함
                </td>
                <td className="td_bt_empty" />
              </tr>
            </tbody>
          </table>
        </div> */}

        <div style={{ height: '5px', backgroundColor: '#fff' }} />
        <div className="div_main_button2">
          <div style={{ height: '3px' }} />

          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#000' }}>
            &nbsp;스포츠메뉴
          </div>
          <div style={{ height: '3px' }} />

          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                {domesticSportsUsing && (
                  <>
                    <td
                      onClick={() => {
                        onClickMenuHandler('/mobile/sports/domestic')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/2winlose.png`} height="45" />
                      <br />
                      {domesticSportsName}
                    </td>
                    <td className="td_bt_empty" />
                  </>
                )}
                {domesticSportsUsing && (
                  <>
                    <td
                      onClick={() => {
                        onClickMenuHandler('/mobile/sports/balance')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/balance.png`} height="45" />
                      <br />
                      스포츠밸런스
                    </td>
                    <td className="td_bt_empty" />
                  </>
                )}
                {overseasSportsUsing && (
                  <>
                    <td
                      onClick={() => {
                        onClickMenuHandler('/mobile/sports/overseas')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/3handicap.png`} height="45" />
                      <br />
                      {overseasSportsName}
                    </td>
                    <td className="td_bt_empty" />
                  </>
                )}
                {specialSportsUsing && (
                  <>
                    <td
                      onClick={() => {
                        onClickMenuHandler('/mobile/sports/special')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/4special.png`} height="45" />
                      <br />
                      {specialSportsName}
                    </td>
                    <td className="td_bt_empty" />
                  </>
                )}
                {liveSportsUsing && (
                  <>
                    <td
                      onClick={() => {
                        onClickMenuHandler('/mobile/sports/live')
                      }}
                    >
                      <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/5live.png`} height="45" />
                      <br />
                      {liveSportsName}
                    </td>
                    <td className="td_bt_empty" />
                  </>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ height: '5px', backgroundColor: '#fff' }} />
      </>
    </>
  )
}

export default MobileMainQuickMenu
