import axios from '../base/axios'

// 스포츠 경기 목록 조회
export async function searchVirtualGameBettingHistory(params) {
  const response = await axios({
    url: '/tyson/member/v1/virtual-game/betting-history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

export async function deleteVirtualGameBettingHistory(body) {
  const response = await axios({
    url: '/tyson/member/v1/virtual-game/betting-history',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

export async function bettingVirtualGame(body) {
  const response = await axios({
    url: '/tyson/member/v1/virtual-game/betting',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })
  return response
}
