import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { IoWalletOutline } from 'react-icons/io5'
import styled from 'styled-components'
import { searchMemberMoneyExchange } from '../../api/memberHistory/memberHistoryApi'
import { WebPageWrap } from '../../shared/components/form/newForm'
import { colorWalletSvg } from '../../utils/palette'
import CustomLoading from '../CustomLoading'
import MoneyExchangeListData from '../MyPage/MoneyExchangeHistory/MoneyExchangeListData'
import { commonReg2 } from '../../utils/validate/commonValidate'
import { convertToKstDateTime } from '../../utils/dateTime'

/**
 * 머니이동 내역임
 * 9.14 머니이동 기능은 라이브/슬롯쪽으로 이동
 */
const MoneyExchangeForm = ({ tabTitle }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  /**
   * 회원 머니이동내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size, tabTitle])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberMoneyExchange = async () => {
    setLoading(true)
    await searchMemberMoneyExchange(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberMoneyExchange()
  }, [historyParams, tabTitle])

  const [moneyExchangeListData, setMoneyExchangeListData] = useState(MoneyExchangeListData(content))

  useEffect(() => {
    setMoneyExchangeListData(MoneyExchangeListData(content))
  }, [content, tabTitle])

  const [rows, setData] = useState(moneyExchangeListData.tableRowsData)

  useEffect(() => {
    setData(moneyExchangeListData.tableRowsData)
  }, [moneyExchangeListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <>
      {loading && <CustomLoading />}
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            <div>
              <table className="tb_notice_box">
                <tbody>
                  <tr>
                    <td className="td_notice_td">
                      <b>
                        <span style={{ fontSize: '11pt' }}>
                          포인트 내역에 이상이 있을시&nbsp;<span style={{ color: 'rgb(0, 0, 0)' }}>고객센터</span>로
                          문의 주시기 바랍니다.
                        </span>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="height_20" />
            <div>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: '140px' }}>
                      <div>
                        <a
                          className="buttonoff"
                          onClick={() => {
                            navigate('/mypage')
                          }}
                        >
                          회원정보
                        </a>
                      </div>
                    </td>
                    <td style={{ width: '4px' }} />
                    <td style={{ width: '140px' }}>
                      <div>
                        <a
                          className="buttonon"
                          onClick={() => {
                            navigate('/money/moneyexchange')
                          }}
                        >
                          머니이동내역
                        </a>
                      </div>
                    </td>
                    <td style={{ width: '4px' }} />
                    <td style={{ width: '140px' }}>
                      <div>
                        <a
                          className="buttonoff"
                          onClick={() => {
                            navigate('/friend-list')
                          }}
                        >
                          추천목록
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="height_20" />
            <div id="CPH_point_list" className="div_list">
              <table style={{ textAlign: 'center' }}>
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '34%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '16%' }} />
                  <col style={{ width: '15%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>일시</th>
                    <th>처리내용</th>
                    <th>이전머니</th>
                    <th>이동금액</th>
                    <th>이후머니</th>
                  </tr>
                </thead>
                <tbody>
                  {totalElement > 0 ? (
                    <>
                      {content.map(item => {
                        return (
                          <tr>
                            <td>{convertToKstDateTime(item.executeDate)}</td>
                            <td>
                              {item.sender} -&gt; {item.receiver}
                            </td>
                            <td>{item.changeAmount?.toString().replace(commonReg2, ',')}</td>
                            <td>{item.changeAmount?.toString().replace(commonReg2, ',')}</td>
                            <td>{item.changeAmount?.toString().replace(commonReg2, ',')}</td>
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="6">최근 머니이동 내역이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
    // <CustomWebPageWrap>
    //   <MoneyExchangeHeaderWrap>
    //     <MoneyExchangeHeaderInfo>머니 이동내역</MoneyExchangeHeaderInfo>
    //   </MoneyExchangeHeaderWrap>
    //   {loading ? (
    //     <CustomLoading />
    //   ) : (
    //     <MoneyExchangeContentWrap>
    //       <ReactTableBase columns={moneyExchangeListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
    //     </MoneyExchangeContentWrap>
    //   )}
    // </CustomWebPageWrap>
  )
}

export default MoneyExchangeForm

const CustomWebPageWrap = styled(WebPageWrap)`
  svg {
    color: green;
  }
  min-height: 846px;
`

// Header Wrap
const MoneyExchangeHeaderWrap = styled.div`
  border-top: 1px solid #edae07;
  padding: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #edae07;
`

// header info
const MoneyExchangeHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

// content Wrap
const MoneyExchangeContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`
export const CustomIoWalletOutline = styled(IoWalletOutline)`
  color: red;
  width: 22px;
  height: 22px;
  margin: 0 8px 3px 0;
  color: ${colorWalletSvg};
`
export const CustomIoIosArrowForward = styled(IoIosArrowForward)`
  width: 30px;
  height: 30px;
  color: white;
`

export const CustomIoIosArrowBack = styled(IoIosArrowBack)`
  width: 30px;
  height: 30px;
  color: white;
`

export const SelectedGameButtom = styled.button`
  width: 200px;
  // min-width: max-content;
  padding: 6px;
  height: 32px;
  font-style: normal;
  font-weight: 500;
  color: black;
  background: #f9e79f;
  border: 1px solid #f2f4f7;
  border-radius: 60%;
`

const GameImage = styled.img`
  width: 100%;
  height: 100%;

  transition: all 0.2s linear;
`
