import React from 'react'
import PropTypes from 'prop-types'
import { Pagination, Form } from 'react-bootstrap'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import {
  colorAccent,
  colorAdditional,
  colorBackground,
  colorFieldsBorder,
  colorIcon,
  colorTablePaginationArrowIcon,
  colorTablePaginationArrowIconBackground,
  colorTablePaginationArrowIconBackgroundHover,
  colorTablePaginationArrowIconDisabled,
  colorTablePaginationArrowIconDisabledBackground,
  colorTablePaginationArrowIconHover,
  colorTablePaginationNum,
  colorTablePaginationNumActive,
  colorTablePaginationNumHover,
  colorText,
} from '../../../utils/palette'
import { marginLeft, marginRight } from '../../../utils/directions'

const ReactTablePagination = ({
  gotoPage,
  canPreviousPage,
  pageOptions,
  pageIndex,
  previousPage,
  nextPage,
  canNextPage,
  setPageIndex,
}) => {
  const pageLength = pageOptions.length

  let arrayPageIndex = []

  if (pageLength > 10) {
    if (pageLength - pageIndex < 6) {
      arrayPageIndex = pageOptions.slice(pageLength - 10, pageLength + 1)
    } else {
      arrayPageIndex = pageIndex - 5 < 0 ? pageOptions.slice(0, 10) : pageOptions.slice(pageIndex - 4, pageIndex + 6)
    }
  } else {
    arrayPageIndex = pageOptions.slice(0, 10)
  }

  return (
    <PaginationWrap isMobile={isMobile}>
      <Pagination>
        <Pagination.First
          disabled={!canPreviousPage}
          className="pagination__item--arrow"
          onClick={() => {
            setPageIndex(0)
            gotoPage(0)
          }}
        >
          <ChevronDoubleLeftIcon />
        </Pagination.First>
        <Pagination.Prev
          disabled={!canPreviousPage}
          className="pagination__item--arrow"
          onClick={() => {
            setPageIndex(pageIndex - 1)
            previousPage(pageIndex - 1)
          }}
        >
          <ChevronLeftIcon />
        </Pagination.Prev>
        {arrayPageIndex.map(i => (
          <Pagination.Item
            key={i}
            active={pageIndex === i}
            onClick={() => {
              setPageIndex(i)
              gotoPage(i)
            }}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={!canNextPage}
          className="pagination__item--arrow"
          onClick={() => {
            setPageIndex(pageIndex + 1)
            nextPage(pageIndex + 1)
          }}
        >
          <ChevronRightIcon />
        </Pagination.Next>
        <Pagination.Last
          disabled={!canNextPage}
          className="pagination__item--arrow"
          onClick={() => {
            setPageIndex(pageOptions.length - 1)
            gotoPage(pageOptions.length - 1)
          }}
        >
          <ChevronDoubleRightIcon />
        </Pagination.Last>
      </Pagination>
    </PaginationWrap>
  )
}

ReactTablePagination.propTypes = {
  gotoPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageIndex: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
}

export default ReactTablePagination

// region STYLES

export const PaginationWrap = styled.div`
  display: flex;
  // 가운데로 이동
  justify-content: center;
  align-items: center;

  .page-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;

    .page-link {
      background: transparent;
      border: none;

      // 페이지 번호 색상
      color: ${colorTablePaginationNum};

      font-size: ${props => (props.isMobile ? '12px' : '12px')};
      padding: 5px;
      text-align: center;
      min-width: ${props => (props.isMobile ? '25px' : '33px')};
      transition: all 0.3s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        // 페이지 번호 Hover 색상
        color: ${colorTablePaginationNumHover};
        background: transparent;
      }

      &:focus,
      &:active {
        box-shadow: none;
      }

      svg {
        width: 25px;
        height: 25px;
        margin: 0;
        // 화살표 아이콘 색상
        fill: ${colorTablePaginationArrowIcon};
        transition: 0.3s;
      }
    }

    &.pagination__item--arrow .page-link {
      border-radius: 50%;
      width: ${props => (props.isMobile ? '25px' : '33px')};
      height: ${props => (props.isMobile ? '25px' : '33px')};

      // 화살표 아이콘 배경 색
      background: ${colorTablePaginationArrowIconBackground};

      &:hover {
        // hover 화살표 아이콘 배경 색
        background: ${colorTablePaginationArrowIconBackgroundHover};

        svg {
          // hover 화살표 아이콘  색
          fill: ${colorTablePaginationArrowIconHover};
        }
      }
    }

    &.pagination__item--arrow.disabled .page-link {
      cursor: default;
      opacity: 0.4;
      // disabled 화살표 아이콘 배경 색
      background: ${colorTablePaginationArrowIconDisabledBackground};

      svg {
        // disabled 화살표 아이콘 색
        fill: ${colorTablePaginationArrowIconDisabled};
      }
    }

    &.active .page-link {
      background-color: transparent;
      font-weight: bold;

      // active 페이지 번호 색상
      color: ${colorTablePaginationNumActive};
    }

    &.disabled .page-link svg {
      // disabled 페이지 번호 색상
      fill: #dddddd;
    }
  }
`

const PaginationInfo = styled.div`
  color: #999999;
  font-size: 12px;
  margin-right: 0;
  margin-left: 10px;
`

const PaginationSelectWrap = styled(PaginationInfo)`
  margin-left: 20px;
  color: #999999;
`

const PaginationFormSelect = styled(Form.Select)`
  color: #999999;
  font-size: 12px;
  background-color: white;
  padding: 6px 12px;
  appearance: auto;
  background-image: none;
`

const PaginationFormOption = styled.option`
  color: #999999;
  font-size: 12px;
`

// endregion
