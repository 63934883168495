import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { deleteMiniGameBettingHistory, searchMiniGameBettingLogAll } from '../../api/game/miniGameApi'
import CustomPagination from '../../shared/components/CustomPagination'
import { MobileListButton, MoneyFormModifyButtomInfo } from '../../shared/components/MoneyCustomElement'
import { convertToKstByFormat } from '../../utils/dateTime'
import {
  MiniGameBettingTypeEnums,
  MiniGameTypeEnums,
  PowerBallTypeEnums,
} from '../../utils/enums/MiniGame/PowerBallEnums'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomLoading from '../../containers/CustomLoading'
import MobileMiniGameBettingHistoryRadio from './MobileMiniGameBettingHistoryRadio'
import MobileCustomPagination from '../../shared/components/MobileCustomPagination'
import { HermesMainBorder, HermesMainText } from '../../utils/palette'

const MobileMiniGameBettingHistory = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      page,
      size,
    }))
  }, [page, size])

  const [totalElements, setTotalElements] = useState(0)
  const [miniGameBettingHistoryContent, setMiniGameBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchMiniGameBettingHistory = async params => {
    await searchMiniGameBettingLogAll(params)
      .then(res => {
        setTotalElements(res.data.totalElement)
        setMiniGameBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchMiniGameBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteMiniGameBettingHistory({
        miniGameBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchMiniGameBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchMiniGameBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  return (
    <>
      {loading ? (
        <CustomLoading />
      ) : (
        <BettingHistoryTableWrap className="sports-container" ref={sportsBettingHistoryWrapRef}>
          {miniGameBettingHistoryContent.length === 0 ? (
            <NoContentDiv>
              <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
              미니게임 배팅내역이 없습니다.
              {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
            </NoContentDiv>
          ) : (
            <>
              {miniGameBettingHistoryContent.map((item, index) => (
                <BettingHistoryTable key={item.miniGameBettingLogKey}>
                  <BettingHistoryTopHeaderTr>
                    <MobileMiniGameBettingHistoryRadio
                      miniGameBettingLogKey={item.miniGameBettingLogKey}
                      bettingResult={item.bettingResult}
                      checkRadio={checkRadio}
                      setCheckRadio={setCheckRadio}
                    />
                    배팅시간 : {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
                  </BettingHistoryTopHeaderTr>
                  <BettingHistoryHeaderTr>
                    <th style={{ width: '20%' }}>게임명</th>
                    <th style={{ width: '20%' }}>배팅종목</th>
                    <th style={{ width: '8%' }}>회차</th>
                    <th style={{ width: '8%' }}>배당</th>
                    <th style={{ width: '16%' }}>배팅액</th>
                    <th style={{ width: '18%' }}>당첨액</th>
                    <th style={{ width: '10%' }}>결과</th>
                  </BettingHistoryHeaderTr>
                  <BettingHistoryTableBody>
                    <BettingHistoryTableTr>
                      <BettingHistoryContentBox>
                        <BettingHistoryContentItemBox
                          width="20"
                          style={{ color: `${HermesMainBorder}`, fontWeight: '500' }}
                        >
                          {MiniGameTypeEnums[item.miniGameType]}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox
                          width="20"
                          style={{ color: `${HermesMainBorder}`, fontWeight: '500' }}
                        >
                          {PowerBallTypeEnums[item.miniGameBettingType]}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="8">{item.gameRound}</BettingHistoryContentItemBox>

                        <BettingHistoryContentItemBox width="8">{item.bettingPer}</BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="16">
                          {item.bettingAmount?.toString().replace(commonReg2, ',')}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox
                          width="18"
                          style={{ color: `${HermesMainBorder}`, fontWeight: '600' }}
                        >
                          {item.winningAmount?.toString().replace(commonReg2, ',')}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="10">
                          {
                            {
                              ING: (
                                <button
                                  type="button"
                                  style={{
                                    width: '30px',
                                    height: '20px',
                                    background: 'green',
                                    color: 'white',
                                    fontWeight: '600',
                                    border: 'none',
                                  }}
                                >
                                  {MiniGameBettingTypeEnums[item.bettingResult]}
                                </button>
                              ),

                              WIN: (
                                <button
                                  type="button"
                                  style={{
                                    width: '30px',
                                    height: '20px',
                                    background: 'blue',
                                    color: 'white',
                                    fontWeight: '600',
                                    border: 'none',
                                  }}
                                >
                                  {MiniGameBettingTypeEnums[item.bettingResult]}
                                </button>
                              ),
                              LOSE: (
                                <button
                                  type="button"
                                  style={{
                                    width: '30px',
                                    height: '20px',
                                    background: 'red',
                                    color: 'white',
                                    fontWeight: '600',
                                    border: 'none',
                                  }}
                                >
                                  {MiniGameBettingTypeEnums[item.bettingResult]}
                                </button>
                              ),
                            }[item.bettingResult]
                          }
                        </BettingHistoryContentItemBox>
                      </BettingHistoryContentBox>
                    </BettingHistoryTableTr>
                  </BettingHistoryTableBody>
                </BettingHistoryTable>
              ))}
            </>
          )}
          {miniGameBettingHistoryContent.length === 0 ? (
            <></>
          ) : (
            <>
              <ListWrap>
                <CustomDeleteButton
                  onClick={() => {
                    setCheckRadio(
                      miniGameBettingHistoryContent
                        .filter(value => value.bettingResult !== 'ING')
                        .map(value => value.miniGameBettingLogKey),
                    )
                  }}
                  type="button"
                >
                  <CustomButtonInfos>전체선택</CustomButtonInfos>
                </CustomDeleteButton>
                <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
                  <CustomButtonInfos>삭제하기</CustomButtonInfos>
                </CustomDeleteButton>
              </ListWrap>
              {totalElements > 0 && (
                <MobileCustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
              )}
            </>
          )}
        </BettingHistoryTableWrap>
      )}
    </>
  )
}

export default MobileMiniGameBettingHistory

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 100px;
    height: auto;
  }
`

export const CustomButtonInfos = styled(MoneyFormModifyButtomInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  padding: 0px 8px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    color: grey;
  }
`

const BettingHistoryHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: rgba(48, 48, 48, 1);
  // border-left: solid 1px gray;
  // border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  align-items: center;
  font-weight: 500;
`

const BettingHistoryTopHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  gap: 20px;
  font-weight: 400;
  background: black;
  // border-left: solid 1px gray;
  // border-right: solid 1px gray;
  // border-top: solid 1px gray;
  height: 25px;
  align-items: center;
  padding: 0px 5px;
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ListWrap = styled.div`
  display: flex;
  text-align: right;
  align-items: right;
  justify-content: right;
  padding-right: 10px;
  gap: 10px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  overflow-x: hidden;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    background: rgba(48, 48, 48, 1);
    padding: 2px 2px;
    border-top: 1px solid #888;
    // border-right: 1px solid #888;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead``

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  // border-left: solid 1px gray;
  // border-bottom: solid 1px gray;
  &:last-child {
    border-bottom: solid 1px gray;
  }
`

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;

  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: #b88907;
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`
