import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMiniGameBettingLog, searchMiniGameInfo } from '../../../api/game/miniGameApi'
import {
  addBettingCart,
  setBettingCloseHandler,
  setMiniGameCompleteFlag,
  setMiniGameInfo,
  setResetData,
} from '../../../redux/miniGameBettingSlice'
import { MiniGameBettingTypeEnums, PowerBallTypeEnums } from '../../../utils/enums/MiniGame/PowerBallEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MobileMiniGameBettingCart from '../../MobileLayout/MobileMiniGameBettingCart'
import MobileMiniGameTabs from '../MobileMiniGameTabs'
import MobileSuremanTabs from './MobileSuremanTabs'
import { convertToKstDateTimeSimple } from '../../../utils/dateTime'
import MobileCustomPagination from '../../../shared/components/MobileCustomPagination'
import { formatSeconds, getCurrentTime } from '../../../containers/MiniGame/miniGameTimeUtils'

const MobileSuremanRacingOne = () => {
  // redux 사용
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const [params, setParams] = useState({
    miniGameType: 'SUREMAN_RACING_1MIN',
  })

  const {
    miniGameType,
    miniGameBettingInfo,
    miniGameRound,
    deadLineTime,
    isShow,
    endTime,
    bettingCountOkFlag,
    bettingCloseFlag,
    bettingCompleteFlag,
  } = useSelector(state => {
    const { miniGameBetting } = state

    return {
      miniGameType: miniGameBetting.miniGameType,
      miniGameBettingInfo: miniGameBetting.miniGameBettingInfo,
      miniGameRound: miniGameBetting.miniGameRound,
      deadLineTime: miniGameBetting.deadLineTime,
      isShow: miniGameBetting.isShow,
      endTime: miniGameBetting.endTime,
      bettingCountOkFlag: miniGameBetting.bettingCountOkFlag,
      bettingCloseFlag: miniGameBetting.bettingCloseFlag,
      bettingCompleteFlag: miniGameBetting.bettingCompleteFlag,
    }
  })

  // 서버 시간 == 현재 시간
  const [currentTime, setCurrentTime] = useState(null)

  const intervalRef = useRef(null)
  // svt : serverTime , stt : startTime
  const getCurrentTimeDifference = deadLine => {
    if (!currentTime)
      return {
        minutes: 0,
        seconds: 0,
      }

    let totalSeconds = endTime - currentTime - deadLine

    if (totalSeconds < 0) {
      totalSeconds = 0
    }

    return {
      minutes: Math.floor(totalSeconds / 60),
      seconds: totalSeconds % 60,
    }
  }

  const [time, setTime] = useState({
    minutes: 0,
    seconds: 0,
  })

  const fetchSearchMiniGameInfo = async () => {
    await searchMiniGameInfo(params)
      .then(res => {
        dispatch(
          setMiniGameInfo({
            miniGameType: res.data.miniGameType,
            miniGameBettingInfo: JSON.parse(res.data.miniGameBettingPer),
            round: res.data.round,
            bettingCountOkFlag: res.data.bettingCountOkFlag,
            endTime: res.data.endTime,
            deadLineTime: res.data.deadLineTime,
            isShow: res.data.isShow,
          }),
        )

        setCurrentTime(res.data.serverTime)

        setTime(getCurrentTimeDifference(res.data.deadLineTime))

        if (!bettingCompleteFlag) {
          dispatch(setResetData())
        }
      })
      .catch(error => {})
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchMiniGameInfo()

    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentTime >= endTime) {
        fetchSearchMiniGameInfo()
        dispatch(setMiniGameCompleteFlag(false))
        dispatch(setResetData())
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [currentTime, endTime])

  const updateTimer = () => {
    setCurrentTime(getCurrentTime())
    setTime(getCurrentTimeDifference(deadLineTime))
  }

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(updateTimer, 1000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [time])

  /**
   *  배팅 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [totalPages, setTotalPages] = useState(0)

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  const [miniGameBettingLogParams, setMiniGameBettingLogParams] = useState({
    miniGameType: 'SUREMAN_RACING_1MIN',
    page,
    size,
  })

  useEffect(() => {
    setMiniGameBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const fetchSearchMiniGameBettingLog = () => {
    searchMiniGameBettingLog(miniGameBettingLogParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
      })
      .catch(error => {})
  }

  useEffect(() => {
    fetchSearchMiniGameBettingLog()
  }, [miniGameBettingLogParams, bettingCompleteFlag])

  const isDisabled = time.minutes === 0 && time.seconds === 0

  // 배팅 마감일 때 배팅카트 초기화
  useEffect(() => {
    dispatch(setBettingCloseHandler(isDisabled))
  }, [isDisabled])

  // 배팅 카트 담기
  const handleBettingCart = event => {
    const miniGameBettingPer = event.currentTarget.getAttribute('name') || 0
    const miniGameBettingType = event.currentTarget.id

    dispatch(
      addBettingCart({
        miniGameBettingPer,
        miniGameBettingType,
      }),
    )
  }

  const [selectedTab, setSelectedTab] = useState(1)

  const handleClick = tabNumber => {
    setSelectedTab(tabNumber)
  }

  return (
    <>
      <div className="mobile_mini_content">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <td>
                <MobileMiniGameTabs activeMenu="SUREMAN" />
                <MobileSuremanTabs activeMenu="SUREMAN_RACING_1MIN" />{' '}
                <div
                  className="powerball_wrap"
                  style={{
                    background: `#13205c url(${process.env.PUBLIC_URL}/img/main/97437ad.jpg)`,
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ height: '20px' }} />
                    <MiniGameIframeWrap>
                      {!loading ? (
                        <div>
                          <MiniGameContainer>
                            <MiniGameIframe
                              src="https://www.sureracer.com/?id=one"
                              title="EOS1"
                              // width="900"
                              // height="880"
                              // scrolling="no"
                              frameBorder="0"
                            />
                          </MiniGameContainer>
                          <MiniGameIframeHidden />
                        </div>
                      ) : (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.1 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0.5, scale: 0.1 }}
                          transition={{ duration: 0.6 }}
                        >
                          <IframeOverlay>
                            <HeaderLogoImg src={`${process.env.PUBLIC_URL}/img/logo/logo.png`} alt="" />
                          </IframeOverlay>
                        </motion.div>
                      )}
                    </MiniGameIframeWrap>
                  </div>

                  <div id="powerball_count" className="powerball_count">
                    <span className="order" id="play_num_view">
                      [제 {miniGameRound || '-'} 회차] &nbsp;
                    </span>
                    <strong className="count" id="remaind_time">
                      {time.minutes} : {formatSeconds(time.seconds)}
                    </strong>
                  </div>
                  <div className="powerball_cnt" style={{ position: 'relative' }}>
                    {!isShow && (
                      <InspectOverlay>
                        <BiLock />
                        <span>점검중</span>
                      </InspectOverlay>
                    )}
                    {(isDisabled || !bettingCountOkFlag) && (
                      <InspectOverlay>
                        <BiLock />
                        <span>배팅 마감</span>
                      </InspectOverlay>
                    )}
                    {selectedTab === 1 ? (
                      <PowerballChoice id="powerball_choice" className="powerball_choice">
                        <PowerballChoiceInner className="powerball_choice_inner" colNum={1}>
                          <PowerballChoiceBox>
                            <h4>1등 찾기</h4>
                            <div>
                              <ul>
                                <li style={{ width: '24.99%' }}>
                                  <MiniGameBettingCartContentWrapper
                                    id="SUREMAN_RACING_NUM_1"
                                    name={miniGameBettingInfo?.firstNum1 || 0}
                                    onClick={handleBettingCart}
                                  >
                                    <img
                                      style={{ width: '50px', height: 'auto', marginTop: '10px' }}
                                      src={`${process.env.PUBLIC_URL}/img/main/redbull.png`}
                                      alt=""
                                    />
                                  </MiniGameBettingCartContentWrapper>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.firstNum1 || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.99%' }}>
                                  <MiniGameBettingCartContentWrapper
                                    id="SUREMAN_RACING_NUM_2"
                                    name={miniGameBettingInfo?.firstNum2 || 0}
                                    onClick={handleBettingCart}
                                  >
                                    <img
                                      style={{ width: '50px', height: 'auto', marginTop: '10px' }}
                                      src={`${process.env.PUBLIC_URL}/img/main/yellowbull.png`}
                                      alt=""
                                    />
                                  </MiniGameBettingCartContentWrapper>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.firstNum2 || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.99%' }}>
                                  <MiniGameBettingCartContentWrapper
                                    id="SUREMAN_RACING_NUM_3"
                                    name={miniGameBettingInfo?.firstNum3 || 0}
                                    onClick={handleBettingCart}
                                  >
                                    <img
                                      style={{ width: '50px', height: 'auto', marginTop: '10px' }}
                                      src={`${process.env.PUBLIC_URL}/img/main/bluebull.png`}
                                      alt=""
                                    />
                                  </MiniGameBettingCartContentWrapper>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.firstNum3 || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.99%' }}>
                                  <MiniGameBettingCartContentWrapper
                                    id="SUREMAN_RACING_NUM_4"
                                    name={miniGameBettingInfo?.firstNum4 || 0}
                                    onClick={handleBettingCart}
                                  >
                                    <img
                                      style={{ width: '50px', height: 'auto', marginTop: '10px' }}
                                      src={`${process.env.PUBLIC_URL}/img/main/greenbull.png`}
                                      alt=""
                                    />
                                  </MiniGameBettingCartContentWrapper>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.firstNum4 || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                          <PowerballChoiceBox>
                            <h4>1등 홀/짝 언더/오버</h4>
                            <div>
                              <ul>
                                <li style={{ width: '24.99%' }}>
                                  <MiniGameBettingCartContentWrapper
                                    id="SUREMAN_RACING_ODD"
                                    name={miniGameBettingInfo?.odd || 0}
                                    onClick={handleBettingCart}
                                  >
                                    <HexagonBlue>홀</HexagonBlue>
                                  </MiniGameBettingCartContentWrapper>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.odd || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.99%' }}>
                                  <MiniGameBettingCartContentWrapper
                                    id="SUREMAN_RACING_EVEN"
                                    name={miniGameBettingInfo?.even || 0}
                                    onClick={handleBettingCart}
                                  >
                                    <HexagonRed>짝</HexagonRed>
                                  </MiniGameBettingCartContentWrapper>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.even || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.99%' }}>
                                  <MiniGameBettingCartContentWrapper
                                    id="SUREMAN_RACING_UNDER"
                                    name={miniGameBettingInfo?.under || 0}
                                    onClick={handleBettingCart}
                                  >
                                    <HexagonBlue>언더</HexagonBlue>
                                  </MiniGameBettingCartContentWrapper>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.under || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.99%' }}>
                                  <MiniGameBettingCartContentWrapper
                                    id="SUREMAN_RACING_OVER"
                                    name={miniGameBettingInfo?.over || 0}
                                    onClick={handleBettingCart}
                                  >
                                    <HexagonRed>오버</HexagonRed>
                                  </MiniGameBettingCartContentWrapper>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.over || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>
                      </PowerballChoice>
                    ) : (
                      <PowerballChoice id="powerball_choice" className="powerball_choice">
                        <PowerballChoiceInner className="powerball_choice_inner" colNum={1}>
                          <PowerballChoiceBox>
                            <h4>조합</h4>
                            <div>
                              <ul>
                                <li style={{ width: '50%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="LADDER_LEFT_THREE_EVEN"
                                    name={miniGameBettingInfo?.leftThreeEven || 0}
                                    onClick={handleBettingCart}
                                    style={{
                                      fontSize: '18px',
                                      letterSpacing: '-3px',
                                      width: '85px',
                                      height: '85px',
                                      lineHeight: '80px',
                                    }}
                                  >
                                    좌 + 3 + 짝
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.leftThreeEven || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '50%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="LADDER_LEFT_FOUR_ODD"
                                    name={miniGameBettingInfo?.leftFourOdd || 0}
                                    onClick={handleBettingCart}
                                    style={{
                                      fontSize: '18px',
                                      letterSpacing: '-3px',
                                      width: '85px',
                                      height: '85px',
                                      lineHeight: '80px',
                                    }}
                                  >
                                    좌 + 4 + 홀
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.leftFourOdd || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>
                        <PowerballChoiceInner className="powerball_choice_inner" colNum={1}>
                          <PowerballChoiceBox>
                            <div>
                              <ul>
                                <li style={{ width: '50%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="LADDER_RIGHT_THREE_ODD"
                                    name={miniGameBettingInfo?.rightThreeOdd || 0}
                                    onClick={handleBettingCart}
                                    style={{
                                      fontSize: '18px',
                                      letterSpacing: '-3px',
                                      width: '85px',
                                      height: '85px',
                                      lineHeight: '80px',
                                    }}
                                  >
                                    우 + 3 + 홀
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.rightThreeOdd || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '50%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="LADDER_RIGHT_FOUR_EVEN"
                                    name={miniGameBettingInfo?.rightFourEven || 0}
                                    onClick={handleBettingCart}
                                    style={{
                                      fontSize: '18px',
                                      letterSpacing: '-3px',
                                      width: '85px',
                                      height: '85px',
                                      lineHeight: '80px',
                                    }}
                                  >
                                    우 + 4 + 짝
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.rightFourEven || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>
                      </PowerballChoice>
                    )}

                    {/* 배팅 카트 */}
                    <MobileMiniGameBettingCart />
                  </div>

                  {/* 배팅 내역 */}
                  <div className="powerball_btm">
                    <div className="powerball_bet_list">
                      <h4
                        style={{
                          paddingTop: '30px',
                          fontSize: '30px',
                          color: 'white',
                          textAlign: 'center',
                          marginBottom: '-20px',
                        }}
                      >
                        - 배팅내역 -
                      </h4>
                      <div className="powerball_bet_list_inner">
                        <table summary="배팅내역">
                          <colgroup>
                            <col width="30px" />
                            <col width="70px" />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col width="80px" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th scope="col">번호</th>
                              <th scope="col">배팅종목</th>
                              <th scope="col">회차</th>
                              <th scope="col">배당</th>
                              <th scope="col">배팅액</th>
                              <th scope="col">당첨액</th>
                              <th scope="col">배팅결과</th>
                              <th scope="col">배팅시간</th>
                            </tr>
                          </thead>
                          <tbody>
                            {content.map(item => {
                              return (
                                <tr>
                                  <td>{item.num}</td>
                                  <td>{PowerBallTypeEnums[item.miniGameBettingType]}</td>
                                  <td>{item.gameRound?.toString().replace(commonReg2, ',')}</td>
                                  <td>{item.bettingPer}</td>
                                  <td>{item.bettingAmount?.toString().replace(commonReg2, ',')}</td>
                                  <td>{item.winningAmount?.toString().replace(commonReg2, ',')}</td>
                                  <td>{MiniGameBettingTypeEnums[item.bettingResult]}</td>
                                  <td>{convertToKstDateTimeSimple(item.bettingAt)}</td>
                                </tr>
                              )
                            })}

                            <tr>
                              <td colSpan="10" style={{ margin: '0', padding: '3px 0' }}>
                                {totalElement > 0 && (
                                  <MobileCustomPagination
                                    currentPage={page}
                                    totalPages={totalPages}
                                    onPageChange={onPageChange}
                                    activeColor="#ff0000"
                                  />
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
export default MobileSuremanRacingOne

const PowerballChoice = styled.div`
  background: #213076;
  padding: 3px 0;
`

const PowerballChoiceInner = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.colNum ? `repeat(${props.colNum}, 1fr)` : 'repeat(2, 1fr)')};
  margin: 10px auto 0 !important;

  gap: 10px;
`

const PowerballChoiceBox = styled.div`
  background: linear-gradient(to bottom, #051153, #1d2a62);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 100%;
`

const InspectOverlay = styled.div`
  position: absolute;
  z-index: 102;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);

  span {
    position: absolute;
    flex-direction: column;
    margin-top: 15px;
    top: 60%;
    left: 50%;
    font-size: 18px;
    transform: translate(-50%, -60%);
    color: #fff;
  }

  svg {
    position: absolute;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
    color: #fff;
  }
`

export const HeaderLogoImg = styled.img`
  width: 270px;
  height: auto;
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const IframeOverlay = styled.div`
  position: relative;
  color: white;
  justify-content: center;
  width: 100%;
  height: 513px;
  background-color: rgba(255, 255, 255, 0.1);
`

export const TableBettingInfo = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  height: 48px;
  background: #1b1c1f;
  color: #fff;
  font-size: 14px;
  .text {
    flex-grow: 1;
    display: flex;
    justify-content: left;
    padding-left: 20px;
    align-items: center;
  }

  .icon {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-right: 10px; // 오른쪽 여백을 조정하려면 이 값을 조절하세요.
  }
`

export const BettingFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

const MiniGameBettingSortTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
`

const MiniGameBettingSortTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 3px solid #005dd6;

  border-radius: 5px;
  color: #fff;

  font-size: 14px;
  height: 50px;
  width: 48%;
  cursor: pointer;

  ${props =>
    props.selected &&
    `
    border: 3px solid #fff;
    background: #005dd6;
`}
`

const MiniGameBettingCartContentRed = styled.div`
  border: 3px solid #e02727;
  background: linear-gradient(to bottom, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 30px;
  font-weight: 700;
  display: flex;
  align-item: center;
  justify-content: center;
  margin: 10px auto 3px auto;
  width: 77px;
  height: 77px;
  line-height: 70px;

  &:hover {
    border: 3px solid #fff;
    background: linear-gradient(to top, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  }
`

const MiniGameBettingCartContentBlue = styled.div`
  border: 3px solid #0069ec;
  background: linear-gradient(to bottom, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 30px;
  font-weight: 700;
  display: flex;
  align-item: center;
  justify-content: center;
  margin: 10px auto 3px auto;
  width: 77px;
  height: 77px;
  line-height: 70px;

  &:hover {
    border: 3px solid #fff;
    background: linear-gradient(to top, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  }
`

const MiniGameBettingCartContentOver = styled.div`
  border: 3px solid #e02727;
  background: linear-gradient(to bottom, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 30px;
  font-weight: 700;
  display: block;
  margin: 10px auto 3px auto;
  width: 77px;
  height: 77px;
  line-height: 70px;

  &:hover {
    border: 3px solid #fff;
    background: linear-gradient(to top, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  }
`

const MiniGameBettingCartContentUnder = styled.div`
  border: 3px solid #0069ec;
  background: linear-gradient(to bottom, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 30px;
  font-weight: 700;
  display: block;
  margin: 10px auto 3px auto;
  width: 77px;
  height: 77px;
  line-height: 70px;

  &:hover {
    border: 3px solid #fff;
    background: linear-gradient(to top, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  }
`

const MiniGameBettingCartContentBlack = styled.div`
  border: 2px solid #c0c0c0;
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #5c5c5c 40%, #6c6c6c 60%, #7c7c7c 100%);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  display: block;
  margin: 5px auto 2px auto;
  width: 35px;
  height: 35px;
  line-height: 35px;

  &:hover {
    border: 2px solid #fff;
    background-image: linear-gradient(to top, #4c4c4c 0%, #5c5c5c 40%, #6c6c6c 60%, #7c7c7c 100%);
  }
`

const MiniGameBettingCartContentSize = styled.div`
  border: 2px solid #1a9728;
  background: #1a9728;
  background-image: linear-gradient(#fff9, #fff0 50%, #00000017 0, #fff3);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 30px;
  font-weight: 700;
  display: block;
  margin: 10px auto 3px auto;
  width: 77px;
  height: 77px;
  line-height: 70px;

  &:hover {
    border: 2px solid #fff;
  }
`

const MiniGameIframe = styled.iframe`
  width: 1840px;
  height: 1745px;
  border: 0;
  transform-origin: top left;

  @media (max-width: 600px) {
    transform: scale(0.503);
    width: 1840px;
    height: 1745px;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    margin-left: -30px;
  }

  @media (max-width: 400px) {
    transform: scale(0.453);
    width: 1840px;
    height: 1745px;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    margin-left: -90px;
  }
`

const MiniGameIframeWrap = styled.div`
  position: relative;
  overflow: hidden !important;
  margin-bottom: -10px;
  width: 100%;
  height: 440px; /* 고정 높이 설정 */
`
const MiniGameIframeHidden = styled.div`
  position: absolute;
  top: 26%; // iframe의 맨 위에 위치
  left: 50%; // 가운데 정렬을 위해 왼쪽 50%로 설정
  transform: translateX(-15%); // 가운데 정렬을 위해 왼쪽으로 이동
  width: 180px;
  height: 40px;
  background-color: #182229;
  z-index: 10; // iframe 위에 오도록 z-index 설정
`

const HexagonBlue = styled.div`
  position: relative;
  width: 40px; /* 너비를 조정하여 크기 조절 가능 */
  height: 23.09px; /* 너비의 57.74% */
  background: #0d63ce;
  margin: 11.54px 0; /* 높이의 반 */
  display: flex; /* Flexbox 사용 */
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  color: white; /* 텍스트 색상 */
  font-size: 18px; /* 텍스트 크기 */
  z-index: 2;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 20px solid transparent; /* 너비의 절반 */
    border-right: 20px solid transparent; /* 너비의 절반 */
  }

  &:before {
    bottom: 100%;
    border-bottom: 11.54px solid #0d63ce; /* 높이의 반 */
  }

  &:after {
    top: 100%;
    width: 0;
    border-top: 11.54px solid #0d63ce; /* 높이의 반 */
  }

  &:hover {
    transform: scale(1.2);
  }
`

const HexagonRed = styled.div`
  position: relative;
  width: 40px; /* 너비를 조정하여 크기 조절 가능 */
  height: 23.09px; /* 너비의 57.74% */
  background: #ff0000;
  margin: 11.54px 0; /* 높이의 반 */
  display: flex; /* Flexbox 사용 */
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  color: white; /* 텍스트 색상 */
  font-size: 18px; /* 텍스트 크기 */
  z-index: 2;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 20px solid transparent; /* 너비의 절반 */
    border-right: 20px solid transparent; /* 너비의 절반 */
  }

  &:before {
    bottom: 100%;
    border-bottom: 11.54px solid #ff0000; /* 높이의 반 */
  }

  &:after {
    top: 100%;
    width: 0;
    border-top: 11.54px solid #ff0000; /* 높이의 반 */
  }

  &:hover {
    transform: scale(1.2);
  }
`

const MiniGameBettingCartContentWrapper = styled.div`
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 30px;
  font-weight: 700;
  display: flex;
  align-item: center;
  justify-content: center;
  margin: 10px auto 3px auto;
  width: 60px;
  height: 60px;
  line-height: 60px;
`

const MiniGameContainer = styled.div`
  width: 100%; /* 부모 컨테이너의 너비를 100%로 설정 */
  position: relative;
`
