import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colorDarkRed, colorVeryLightRed, colorErrorText } from '@/utils/palette'
import { right } from '@/utils/directions'

const Error = ({ error, top, isMobile }) => (
  <ErrorText $top={top} isMobile={isMobile}>
    {error}
  </ErrorText>
)

Error.propTypes = {
  error: PropTypes.string.isRequired,
  top: PropTypes.bool,
}

Error.defaultProps = {
  top: false,
}

export default Error

// region STYLES

const ErrorText = styled.span`
  font-size: ${props => (props.isMobile ? '10px' : '12px')};
  font-weight: 500;
  line-height: 10px;
  color: red !important;
  margin-bottom: -2px;
  display: block;
  margin-top: 10px;

  ${props =>
    props.$top &&
    `
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: ${colorVeryLightRed};
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid ${colorVeryLightRed};
    }
  `}
`

// endregion
