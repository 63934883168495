import React, { useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Quill의 스타일 시트
import { createOnetoOne } from '../../../api/customCenter/customCenterApi'
import CustomLoading from '../../CustomLoading'
import '../Notice/Notice.css'
import './customStyle.css'

const OnetoOneWrite = ({ onBack, fetchSearchOneToOneList }) => {
  const [questionLoading, setQuestionLoading] = useState(false)
  const [questionTitle, setQuestionTitle] = useState('')
  const onChangeQuestionTitle = e => {
    setQuestionTitle(e.target.value)
  }

  // 문의 내용
  const [questionContent, setQuestionContent] = useState('')
  const onChangeQuestionContentHandler = editorState => {
    setQuestionContent(editorState)
  }
  const onSubmit = e => {
    if (questionLoading) return
    setQuestionLoading(true)
    // TODO userID에 로그인한사람 ID박아주기
    const body = {
      questionTitle,
      questionContent,
    }
    createOnetoOne(body)
      .then(res => {
        setQuestionLoading(false)
        alert('1:1문의가 접수되었습니다.')
        onBack()
        fetchSearchOneToOneList()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(message)
            break
          default:
            alert('1:1문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        onBack()
        setQuestionLoading(false)
      })
  }

  return (
    <>
      {questionLoading && <CustomLoading />}
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            <table className="tb_notice_box">
              <tbody>
                <tr>
                  <td className="td_notice_td">
                    <b>
                      <span style={{ fontSize: '11pt', display: 'flex', flexDirection: 'column' }}>
                        <span style={{ color: 'rgb(0, 0, 0)' }}>
                          궁금한 문의사항 및 개인정보변경에 관해서는 고객센터로 문의 바라며, 같은 내용의 문의는 자제
                          부탁드립니다.
                        </span>
                        <span style={{ color: 'rgb(0, 0, 0)' }}>
                          보다 자세한상담을 원하시면 공식텔레그램{' '}
                          <span
                            style={{ color: '#229ED9', fontWeight: '800', cursor: 'pointer' }}
                            onClick={() => {
                              window.open('https://t.me/Zeus_CSCS')
                            }}
                          >
                            @Zeus_CSCS
                          </span>{' '}
                          로 상담 부탁드립니다.
                        </span>
                      </span>
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="height_20" />
            <div>
              <table className="tb_solid_table">
                <tbody>
                  <tr>
                    <td className="title">제목</td>
                    <td style={{ padding: '10px' }}>
                      <input
                        type="text"
                        style={{ width: '99%', height: '19px' }}
                        className="input_left_white"
                        value={questionTitle}
                        onChange={e => {
                          onChangeQuestionTitle(e)
                        }}
                        id="contact_title"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="title">내용</td>
                    <td className="body" style={{ padding: '10px' }}>
                      <ReactQuill
                        placeholder={`해당 영역에 문의내용을 입력해주세요 \n\n매너 있는 채팅을 부탁드리며 '욕설 및 협박' 등의 내용을 기재 시 회원자격이 박탈됩니다`}
                        onChange={value => {
                          setQuestionContent(value) // questionContent 업데이트
                        }}
                        modules={{
                          toolbar: [
                            // [{ header: '1' }, { header: '2' }, { font: [] }],
                            // [{ list: 'ordered' }, { list: 'bullet' }],
                            // ['bold', 'italic', 'underline'],
                            // ['link'],
                          ],
                        }}
                        className="custom-quill"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="height_10" />
            <div style={{ textAlign: 'center' }}>
              <a onClick={onSubmit} className="normal_button">
                문의하기
              </a>
              <a
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  fetchSearchOneToOneList()
                  onBack()
                }}
                className="normal_button"
              >
                목록으로
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <Form
        onSubmit={onSubmit}
        initialValues={{
          userId: decodeAccessToken().userId,
          questionTitle,
          questionContent,
        }}
        validate={OnetoOneValidate}
      >
        {({ handleSubmit, form: { reset } }) => (
          <CustomerCenterFormWrap horizontal onSubmit={handleSubmit} style={{ marginBottom: '10px' }}>
            <CustomerCenterFormGroup>
              <CustomerCenterFormLabel>문의 제목</CustomerCenterFormLabel>
              <CustomCenterFormField>
                <Field name="questionTitle">
                  {({ input, meta }) => (
                    <FormInputWrap>
                      <CustomCenterFormInput
                        {...input}
                        style={{ background: '#C2C3C4' }}
                        type="text"
                        value={questionTitle}
                        onChange={e => {
                          input.onChange(e)
                          onChangeQuestionTitle(e)
                        }}
                        placeholder="제목을 작성하세요"
                      />
                      {meta.touched && meta.error && <Error error={meta.error} />}
                    </FormInputWrap>
                  )}
                </Field>
              </CustomCenterFormField>
            </CustomerCenterFormGroup>
            <CustomerCenterFormGroup>
              <CustomerCenterFormLabel>문의 내용</CustomerCenterFormLabel>
              <CustomCenterFormField>
                <Field name="questionContent">
                  {({ input, meta }) => (
                    <FormInputWrap style={{ background: '#c2c3c4' }}>
                      <ReactQuill
                        placeholder={`해당 영역에 문의내용을 입력해주세요 \n\n매너 있는 채팅을 부탁드리며 '욕설 및 협박' 등의 내용을 기재 시 회원자격이 박탈됩니다`}
                        onChange={value => {
                          input.onChange(value)
                          setQuestionContent(value) // questionContent 업데이트
                        }}
                        modules={{
                          toolbar: [
                            // [{ header: '1' }, { header: '2' }, { font: [] }],
                            // [{ list: 'ordered' }, { list: 'bullet' }],
                            // ['bold', 'italic', 'underline'],
                            // ['link'],
                          ],
                        }}
                      />
                      {meta.touched && meta.error && <Error error={meta.error} />}
                    </FormInputWrap>
                  )}
                </Field>
              </CustomCenterFormField>
            </CustomerCenterFormGroup>
            <CustomCenterFormBottomWrap>
              <CustomDeleteButton
                variant="primary"
                type="submit"
                style={{ width: '30%', margin: '2px 2px', background: '#880000' }}
              >
                <CustomButtonInfos>1:1 문의 등록하기</CustomButtonInfos>
              </CustomDeleteButton>
            </CustomCenterFormBottomWrap>
          </CustomerCenterFormWrap>
        )}
      </Form> */}
    </>
  )
}

export default OnetoOneWrite

// endregion
