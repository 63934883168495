import React, { useState } from 'react'
import PropTypes from 'prop-types'

const MobileBettingHistoryRadio = ({ sportsBettingLogKey, sportsResultStatus, checkRadio, setCheckRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        if (sportsResultStatus === 'ING') {
          alert('진행중 건은 삭제하실 수 없습니다.')
          break
        }
        setCheckRadio(prev => [...prev, sportsBettingLogKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== sportsBettingLogKey))
        break
      default:
        break
    }
  }
  return (
    <div>
      <input
        style={{ width: '100%', minWidth: '13px' }}
        checked={sportsResultStatus === 'ING' ? false : checkRadio.find(e => e === sportsBettingLogKey)}
        type="checkbox"
        name="chech"
        onChange={e => {
          approveHandler(e)
        }}
      />
    </div>
  )
}
export default MobileBettingHistoryRadio

MobileBettingHistoryRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  sportsBettingLogKey: PropTypes.string,
  sportsResultStatus: PropTypes.string,
}

MobileBettingHistoryRadio.defaultProps = {
  sportsBettingLogKey: null,
  sportsResultStatus: null,
  checkRadio: [],
}
