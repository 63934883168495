import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaSearchPlus } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { searchGame, searchGameUrl } from '../../api/game/gameApi'
import { ButtonH40 } from '../../shared/components/GoldButton'
import Portal from '../../shared/components/Portal'
import { convertLongData } from '../../utils/editor'
import CustomLoading from '../CustomLoading'
import AlertPopup from '../Popup/Error/ErrorPopup'
import '../Popup/Popup.css'
import {
  HermesMainBorder,
  HermesMainHover2,
  HermesMainSelect,
  HermesMainText,
  MainPageBackground,
} from '../../utils/palette'
import CasinoGameViewPopup from './CasinoGameViewPopup'
import { fetchMemberInfo, fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import CustomAlert from '../CustomAlert'

/**
 * @see : https://github.com/masroorejaz/react-simple-captcha
 */

const SlotCasinoGameModal = ({ onClose, visible, vendorName, agencyName, vendorType }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    document.body.style = `overflow: hidden`
    return () => {
      document.body.style = `overflow: auto`
    }
  }, [])

  const [games, setGames] = useState([])

  const [loading, setLoading] = useState(true)
  const [gameLoading, setGameLoading] = useState(false)

  const [searchGameName, setSearchGameName] = useState('')
  const [searchBoolean, setSearchBoolean] = useState(false)

  // 경고창 용 팝업
  const [alertPopupShow, setAlertPopupShow] = useState(false)
  const [alertPopupMessage, setAlertPopupMessage] = useState('')

  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
    setAlertPopupMessage('')
  }

  const onChangeAlertPopupMessageHandler = text => {
    setAlertPopupShow(true)
    setAlertPopupMessage(text)
  }

  useEffect(() => {
    setLoading(true)
    searchGame({
      vendorName,
      agencyName,
      gameName: searchGameName,
    })
      .then(res => {
        setGames(res.data.games)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [vendorName, agencyName, searchBoolean])

  const [casinoGameViewPopupShow, setCasinoGameViewPopupShow] = useState(false)
  const onCloseCasinoGameViewPopupShowHandler = () => {
    setCasinoGameViewPopupShow(false)
  }

  const [casinoGameUrl, setCasinoGameUrl] = useState('')

  const notGamePopupVendors = ['ASIA GAMING SLOT']

  const gameStartHandler = game => {
    if (gameLoading) return

    setGameLoading(true)
    searchGameUrl({
      agencyName,
      vendorType,
      gameKey: game.gameKey,
    })
      .then(res => {
        const { gameUrl } = res.data
        if (!gameUrl) {
          // 없어진 게임일때 빈문자열로 내려옴
          onChangeAlertPopupMessageHandler('이용이 불가능합니다. 관리자에게 문의하세요.')
          return
        }
        const idx = notGamePopupVendors.findIndex(item => item === vendorName)

        if (idx === -1) {
          setCasinoGameUrl(gameUrl)
          setCasinoGameViewPopupShow(true)
        } else {
          window.open(
            `${gameUrl}`,
            '',
            `height=${window.screen.height}, width=${window.screen.width}, location=no, status=no, scrollbars=yes`,
          )
        }

        setGameLoading(false)
      })
      .catch(error => {
        dispatch(fetchMemberInfoAction())
        setGameLoading(false)
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'GAME-1001':
            onChangeAlertPopupMessageHandler('이용이 불가능합니다. 관리자에게 문의하세요.')
            break
          case 'GAME-1014':
            onChangeAlertPopupMessageHandler('카지노 머니 동기화중입니다. \n잠시후에 다시 시도해주세요.')
            break
          case 'ASSET-7001':
            onChangeAlertPopupMessageHandler('잠시후 다시 시도해주세요.')
            break
          default:
            onChangeAlertPopupMessageHandler('잠시후에 다시 시도해주세요.')
        }
      })
      .finally(() => {
        dispatch(fetchMemberInfoAction())
        setGameLoading(false)
      })
  }

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }

  return (
    <>
      {casinoGameViewPopupShow && (
        <CasinoGameViewPopup
          casinoGameUrl={casinoGameUrl}
          onCloseCasinoGameViewPopupShowHandler={onCloseCasinoGameViewPopupShowHandler}
        />
      )}
      {alertPopupShow && <CustomAlert info={alertPopupMessage} onClickCloseHandler={onCloseAlertPopup} />}
      <Portal elementId="game-modal">
        <SlotCasinoGameModalOverlay visible={visible} />
        {(loading || gameLoading) && <CustomLoading info={gameLoading ? '게임 접속 중입니다.' : ''} />}
        <SlotCasinoGameModalWrapper tabIndex="-1" visible={visible}>
          <SlotCasinoGameModalInner tabIndex="0">
            <SlotCasinoGameModalHeader>
              <SlotCasinoGameModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/logo/logo.png`} alt="" />
              <SlotCasinoGameModalCloseButton
                onClick={close}
                src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                alt=""
              />
            </SlotCasinoGameModalHeader>

            <SlotCasinoGameModalContentWrap>
              {/* <SlotCasinoGameModalHeaderVendorLogoWrap>
                <SlotCasinoGameModalHeaderVendorLogo
                  src={`${process.env.PUBLIC_URL}/img/game/${vendorName?.toString()?.replace(/(\s*)/g, '')}-header.png`}
                />
              </SlotCasinoGameModalHeaderVendorLogoWrap> */}
              <SportsGameSearchWrap>
                게임명 검색:
                <input
                  type="text"
                  name="searchGameName"
                  value={searchGameName}
                  placeholder="게임명을 검색하세요"
                  onChange={e => {
                    setSearchGameName(e.target.value)
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setSearchBoolean(prev => !prev)
                    }
                  }}
                />
                <FaSearchPlus
                  onClick={() => {
                    setSearchBoolean(prev => !prev)
                  }}
                />
              </SportsGameSearchWrap>

              <SlotCasinoGameModalGameWrap>
                {games.map(item => {
                  return (
                    <SlotCasinoGameImgWrap key={item.gameKey}>
                      <SlotCasinoGameImg src={item.gameImg} />
                      <SlotCasinoGameTitleWrap>
                        <SlotCasinoGameTitle>{convertLongData(item.gameTitleKo)}</SlotCasinoGameTitle>
                      </SlotCasinoGameTitleWrap>
                      <CasinoPlayWrap onClick={() => gameStartHandler(item)}>
                        <CasinoPlayButtonWrap>Play Now</CasinoPlayButtonWrap>
                      </CasinoPlayWrap>
                    </SlotCasinoGameImgWrap>
                  )
                })}
              </SlotCasinoGameModalGameWrap>
            </SlotCasinoGameModalContentWrap>
          </SlotCasinoGameModalInner>
        </SlotCasinoGameModalWrapper>
      </Portal>
    </>
  )
}

export default SlotCasinoGameModal

const SportsGameSearchWrap = styled.div`
  width: 100%;
  background: linear-gradient(#f5fcff, #f5fcff);
  border-bottom: 1px solid #ddd;
  padding: 4px 8px;
  position: relative;
  display: flex;
  gap: 5px;
  justify-content: right;
  align-items: center;
  font-weight: 400;
  color: #000;
  font-size: 14px;
  margin-top: 2px;

  input {
    // background: rgb(29, 29, 29);
    height: 30px;
    border: 1px solid #000;
    background: linear-gradient(#f5fcff, #f5fcff);
    font-weight: 700;
    font-size: 14px;
    padding-left: 5px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 2px #000;
    }
  }
  svg {
    width: 25px;
    height: 20px;
    color: #000;
    &:hover {
      cursor: pointer;
    }
  }
`

const CasinoPlayWrap = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0; // 초기 상태에서 숨김
  visibility: hidden; // 초기 상태에서 숨김
  position: absolute;
  cursor: pointer;
  transition: opacity 0.5s ease, visibility 1s ease; // opacity와 visibility에 트랜지션 적용
  background: rgba(0, 0, 0, 0.6);

  display: flex;
  align-items: center;
  justify-content: center;
`

const CasinoPlayButtonWrap = styled.div`
  padding: 8px 36px;
  background: ${HermesMainHover2};
  border-radius: 36px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
`

const SlotCasinoGameModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9990;
  overflow: auto;
  outline: 0;
`
const SlotCasinoGameModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9990;
`

const SlotCasinoGameModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(#f5fcff, #f5fcff);
  width: 1000px;
  margin: 0 auto;
  min-height: 730px;
  margin-top: 10vh;

  border-radius: 8px;
`

const SlotCasinoGameModalHeader = styled.div`
  height: 60px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const SlotCasinoGameModalHeaderLogo = styled.img`
  width: auto;
  height: 45px;
`

// 닫기 아이콘
const SlotCasinoGameModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`
// 게임사 (벤더) 로고
const SlotCasinoGameModalHeaderVendorLogoWrap = styled.div`
  width: 978px;
  height: 78px;

  position: relative;
`

const SlotCasinoGameModalHeaderVendorLogo = styled.img`
  width: 100%;
  height: 100%;
`

// modal body content
const SlotCasinoGameModalContentWrap = styled.div`
  text-align: center;

  // form 없을 때 임시로 사용
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  max-height: 650px;
  background: linear-gradient(#f5fcff, #f5fcff);
  padding: 0 20px;
`

const SlotCasinoGameModalGameWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  // position: relative;

  gap: 12px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    background-color: #ddd !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff !important;
  }
`

// 슬롯 게임 이미지 - 145 x 140
const SlotCasinoGameImg = styled.img`
  width: 180px;
  height: 140px;

  transition: all 0.2s linear;
`

// 슬롯 게임 타이틀 wrap
const SlotCasinoGameTitleWrap = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  // background: #000;
  background: linear-gradient(#f5fcff, #f5fcff);
  border: 1px solid #ddd;
  color: #000;
`

// 슬롯 게임 타이틀
const SlotCasinoGameTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  color: #000;
`

// 슬롯 게임 이미지 wrap
const SlotCasinoGameImgWrap = styled.div`
  width: 180px;
  height: 170px;
  position: relative;
  display: flex;
  flex-direction: column;
  // border: 2px solid ${HermesMainBorder};

  &:hover {
    ${CasinoPlayWrap} {
      opacity: ${props => (props.isCheck ? 0 : 1)};
      visibility: ${props => (props.isCheck ? 'hidden' : 'visible')};
    }
  }
`
