import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import { fetchEventBoardList, setCustomerLoading } from '../../../redux/customCenterSlice'
import MobileEventDetail from './MobileEventDetail'
import MobileCustomPagination from '../../../shared/components/MobileCustomPagination'
import CustomLoading from '../../../containers/CustomLoading'

const MobileEventList = () => {
  const [isModalShow, setModalShow] = useState(false)
  useEffect(() => {
    if (isModalShow) {
      // 현재 스크롤 위치를 저장합니다.
      const { scrollY } = window
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
    } else {
      // 저장된 스크롤 위치를 복원합니다.
      const savedScrollY = parseInt(document.body.style.top || '0', 10)
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, savedScrollY * -1)
    }
  }, [isModalShow])
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)

  useEffect(() => {
    setScreenHeight(window.innerHeight)
    const handleResize = () => {
      setScreenHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [boardParams, setBoardParams] = useState({
    boardType: 'EVENT',
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [content, setContent] = useState([])
  const { customCenterLoading, totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      customCenterLoading: customCenter.customCenterLoading,
      totalElement: customCenter.eventInfo.totalElement,
      apiContent: customCenter.eventInfo.content,
    }
  })
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.boardKey === item.boardKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchBoardList = async () => {
    dispatch(setCustomerLoading(true))
    try {
      dispatch(fetchEventBoardList(boardParams))
      setLoading(false)
    } catch (error) {
      dispatch(setCustomerLoading(false))
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchBoardList()
  }, [boardParams])

  // row 클릭 이벤트 핸들러
  const [hangData, setHandData] = useState({})
  const onClickRowHandler = item => {
    setHandData({
      boardContent: item.boardContent,
      boardTitle: item.boardTitle,
      imgUrl: item.imgUrl,
    })
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const onChangeModalState = () => {
    setModalShow(prev => !prev)
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
    }
  }

  const [showDetail, setShowDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const handleRowClick = boardKey => {
    const boardContent = content.find(item => item.boardKey === boardKey)
    if (!boardContent) return
    setSelectedItem(boardContent)
    setShowDetail(true)
  }

  return (
    <>
      {customCenterLoading && <CustomLoading />}
      {showDetail ? (
        <MobileEventDetail notice={selectedItem} onBack={() => setShowDetail(false)} />
      ) : (
        <div style={{ padding: '0px 0 0px 0' }}>
          <div id="hiddenBar" style={{ background: '#fff', boxShadow: '0px 7px 6px -5px #DFE1E5;' }}>
            <div className="header">
              <div style={{ height: '3px' }} />
              <table
                style={{
                  padding: '0px',
                  border: '0px',
                  borderCollapse: 'collapse',
                  borderSpacing: '0px',
                  width: '100%',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: '36px' }}>
                      <img
                        alt=""
                        style={{ width: '36px', height: '36px' }}
                        // src="/m/images/menu_btn.png?v=1"
                        //  onclick="showMenuOn();"
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <a href="/m/Default.aspx">
                        <img
                          // src="/pc/account/img/google_lopo2.png?v=1"
                          style={{ height: '36px' }}
                          alt=""
                        />
                      </a>
                    </td>
                    <td style={{ width: '36px' }}>
                      <img
                        style={{ width: '36px', height: '36px' }}
                        alt=""
                        // src="/m/images/icons8-refresh-64.png?v=4"
                        //  onClick="window.location.reload();"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ height: '2px' }} />
            </div>
          </div>

          <div className="body_title">
            <span className="title1">
              <strong>이벤트</strong>
            </span>
            <span className="title2">
              <strong>&nbsp;Event</strong>
            </span>
          </div>
          <div style={{ height: '20px' }} />
          <div className="board_wrap">
            <div className="board_list">
              <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="board_list_table2">
                <tbody>
                  {content.length === 0 ? (
                    <tr>
                      <td>이벤트 내역이 없습니다.</td>
                    </tr>
                  ) : (
                    <>
                      {content.map(item => (
                        <>
                          <tr>
                            <td
                              onClick={() => handleRowClick(item.boardKey)}
                              className="board_list_td_notice"
                              style={{ wordBreak: 'break-all', color: '#111', fontSize: '12px' }}
                            >
                              <b>[이벤트]</b>
                              <span style={{ color: '#000' }}>
                                <b style={{ color: 'rgb(0,0,0)', fontWeight: '900' }}>★Zeus★&nbsp;</b>
                                <span style={{ color: 'rgb(0,0,0)', fontWeight: '500' }}>&nbsp;{item.boardTitle}</span>
                              </span>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ height: '20px' }} />
          </div>
          <>
            <MobileCustomPagination
              currentPage={page}
              size={size}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </>
          {/* <div className="pageing_wrap">
    <table align="center" cellpadding="0" cellspacing="5" border="0" width="100%" className="pageing_table">
      <tbody>
        <tr>
          <td width="50%">
            <a href="message.aspx?page=1">◀ 이전10개</a>
            </td>
            <td width="50%">
              <a href="message.aspx?page=1">다음10개 ▶</a></td></tr></tbody></table>
              </div> */}
        </div>
      )}
      {/* <div style={{ overflow: 'hidden' }}>
        <motion.div initial={{ y: screenHeight }} animate={{ y: 0 }} exit={{ y: -50 }} transition={{ duration: 0.6 }}>
          <MobileTopWrap ref={webPageWrapRef}>
            {loading ? (
              <CustomLoading />
            ) : (
              <EventRealWrap>
                {content.length === 0 ? (
                  <NoContentDiv>
                    <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
                    최근 이벤트가 없습니다.
                  </NoContentDiv>
                ) : (
                  <EventWrap>
                    {content.map((item, index) => (
                      <>
                        <EventContentBox
                          onClick={() => {
                            onChangeModalState()
                            onClickRowHandler(item)
                          }}
                        >
                          <div
                            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          >
                            <img src={`${item.imgUrl}`} alt="" />
                          </div>
                        </EventContentBox>
                      </>
                    ))}
                  </EventWrap>
                )}
              </EventRealWrap>
            )}
          </MobileTopWrap>
        </motion.div>
      </div>
      {isModalShow && (
        <Portal elementId="signup-modal">
          <SignUpModalOverlay visible={isModalShow} />
          <SignUpModalWrapper
            // onClick={onChangeModalState}
            tabIndex="-1"
            visible={isModalShow}
            className="sidebar-container"
          >
            <SignUpModalInner tabIndex="0">
              <SignUpModalHeader>
                <SignUpModalCloseButton
                  onClick={onChangeModalState}
                  src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                  alt=""
                />
              </SignUpModalHeader>
              <SignUpModalContentWrap className="game-container">
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <SignUpModalContentFirst src={`${hangData.imgUrl}`} alt="" />
                </div>

                <SignUpModalContentSecondWrap>
                  <SignUpModalContentSecond>{ReactHtmlParser(hangData.boardTitle)}</SignUpModalContentSecond>
                  <SignUpModalContentThird>
                    {ReactHtmlParser(hangData.boardContent, { transform: transformFn })}
                  </SignUpModalContentThird>
                </SignUpModalContentSecondWrap>
              </SignUpModalContentWrap>
            </SignUpModalInner>
          </SignUpModalWrapper>
        </Portal>
      )} */}
    </>
  )
}
export default MobileEventList

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 30px;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  color: #fff;
  img {
    width: 100px;
    height: auto;
  }
`

export const MobileNoContentBox = styled.div`
  display: flex
  width: 100%;
  color: #fff;
  font-size: 13px;
  text-align: center;
  align-items: center;
  margin-top: 30px;
`

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9991;
  overflow: auto;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9990;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgb(16, 16, 18, 1);
  width: 100vw;
  margin: 0 auto;
  max-height: 550px;
  margin-top: 5vh;
  overflow: auto;
  word-break: break-all;
  border: solid 1px #525252;
`

const SignUpModalHeader = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 20px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  color: #606266;
  word-break: break-all;
  padding: 0 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  // padding-bottom: 30px;
`

// modal body content
const SignUpModalContentFirst = styled.img`
  width: 348px;
  height: 192px;
`

const SignUpModalContentSecondWrap = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const SignUpModalContentSecond = styled.div`
  width: 100%;
  color: #fff;
  font-size: 14px;
`

const SignUpModalContentThird = styled.div``

const EventRealWrap = styled.div``

const EventWrap = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fill, minmax(348px, 1fr));
  margin-top: 20px;
  padding: 10px 2px;
  gap: 20px;
`

const EventContentBox = styled.div`
  width: 370px;
  height: 200px;
  background: rgb(26, 28, 31);
  display: flex;
  padding: 5px;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  transition: transform 0.3s ease-out, background 0.3s ease-out; // 애니메이션 효과 지속 시간과 형태 설정

  &:hover {
    // 호버 상태 설정
    cursor: pointer;
    background: rgb(39, 43, 46);
    transform: translateY(-10px); // 상단으로 10픽셀만큼 올림
  }

  img {
    width: 100%;
    height: auto;
  }
`

const EventContentTitle = styled.div`
  width: 100%;
  color: #eec100;
  padding-left: 20px;
`
const EventContentTitle2 = styled.div`
  width: 100%;
  color: #fff;
  padding-left: 20px;
`
