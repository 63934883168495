import 'moment/locale/ko'
import React, { useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FaLock } from 'react-icons/fa'
import { HiOutlineRefresh } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { siginOut } from '../../api/authManage/authManageApi'
import { withdrawCasinoMoney } from '../../api/memberManage/memberManageApi'
import { fetchMemberInfo, fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { onShowModalHandler } from '../../redux/modalSlice'
import {
  onClickBettingCartAlertCloseHandler,
  onClickBettingCartUpdateMessageCloseHandler,
  removeAllBettingCartItem,
  removeBettingCartItem,
  setActiveSportsMarketType,
  setBettingCartLoading,
  setSelectedBonusFolder,
  setBettingDelay,
} from '../../redux/sportsBettingSlice'
import { setBalanceActiveBetInfo, setDomesticActiveBetInfo } from '../../redux/sportsDomesticInfoSlice'
import { setLiveActiveBetInfo } from '../../redux/sportsLiveInfoSlice'
import { setOverseasActiveBetInfo } from '../../redux/sportsOverseasInfoSlice'
import { setSpecialActiveBetInfo } from '../../redux/sportsSpecialInfoSlice'
import { createSportsBettingLog } from '../../socketio/sportsSocketIOHandler'
import { SportMarketType } from '../../utils/enums/SportsGame/SportsGameEnums'
import isLogin from '../../utils/enums/authority'
import { HermesMainBorder, HermesMainHover, HermesMainHover2, HermesMainText } from '../../utils/palette'
import { decodeAccessToken } from '../../utils/token'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomAlert from '../CustomAlert'
import BettingCartFormField from './BettingCartFormField'
import CustomLoading from '../CustomLoading'
import { withdrawHoldemMoney } from '../../api/game/gameApi'
import { bettingSportsEventV2 } from '../../api/game/sportsGameApi'
import CustomBettingAlert from '../CustomBettingAlert'

const SportsBettingCartSidebar = () => {
  const navigate = useNavigate()
  const [loginCheck, setLoginCheck] = useState(false)

  useEffect(() => {
    setLoginCheck(isLogin())
  }, [isLogin()])

  // redux 사용
  const dispatch = useDispatch()

  const location = useLocation()

  const {
    holdingMoney,
    memberTotalGameMoney,
    holdemMoneyAmount,
    holdingMemberPoint,
    userId,
    memberLevel,
    noteRedisSize,
    oneToOneRedisSize,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
      holdemMoneyAmount: memberInfo.holdemMoneyAmount,
      holdingMemberPoint: memberInfo.memberHoldingPoint,
      userId: memberInfo.userId,
      memberLevel: memberInfo.depositLevel,
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const {
    totalBettingPer,
    bettingCartList,
    bettingCartAlertMessage,
    bettingCartUpdateMessage,
    bettingCartCountExcludeBonus,
    bettingCartCount,
    selectedBonusFolder,
    activeSportsMarketType,
    sportsBettingInfo,
    bettingDelay,
  } = useSelector(state => {
    const { sportsBetting } = state

    const tempSelectedBonusFolder = sportsBetting.selectedBonusFolder

    let tempBettingCartCount

    if (Object.keys(tempSelectedBonusFolder).length === 0) {
      tempBettingCartCount = sportsBetting.bettingCartList.length
    } else {
      tempBettingCartCount = sportsBetting.bettingCartList.length + 1
    }

    return {
      totalBettingPer: sportsBetting.totalBettingPer,
      bettingCartList: sportsBetting.bettingCartList,
      bettingCartAlertMessage: sportsBetting.bettingCartAlertMessage,
      bettingCartUpdateMessage: sportsBetting.bettingCartUpdateMessage,
      bettingCartCountExcludeBonus: sportsBetting.bettingCartList.length,
      bettingCartCount: tempBettingCartCount,
      selectedBonusFolder: sportsBetting.selectedBonusFolder,
      activeSportsMarketType: sportsBetting.activeSportsMarketType,
      sportsBettingInfo: sportsBetting.sportsBettingInfos[sportsBetting.activeSportsMarketType],
      bettingDelay: sportsBetting.bettingDelay,
    }
  })

  const [realSportsBettingInfo, setRealSportsBettingInfo] = useState({})

  useEffect(() => {
    let sportsMaxBettingAmount = 0
    let sportsMaxWinningAmount = 0
    let sportsMaxBettingPer = 0
    let sportsMinBettingAmount = 0
    const sportsMinFolder = sportsBettingInfo?.sportsMinFolder || 0
    const sportsMaxFolder = sportsBettingInfo?.sportsMaxFolder || 0

    if (bettingCartCountExcludeBonus <= 1) {
      sportsMaxBettingAmount = sportsBettingInfo?.singleFolderSportsMaxBettingAmount || 0
      sportsMaxWinningAmount = sportsBettingInfo?.singleFolderSportsMaxWinningAmount || 0
      sportsMaxBettingPer = sportsBettingInfo?.singleFolderSportsMaxBettingPer || 0
      sportsMinBettingAmount = sportsBettingInfo?.singleFolderSportsMinBettingAmount || 0
    } else if (bettingCartCountExcludeBonus === 2) {
      sportsMaxBettingAmount = sportsBettingInfo?.twoFolderSportsMaxBettingAmount || 0
      sportsMaxWinningAmount = sportsBettingInfo?.twoFolderSportsMaxWinningAmount || 0
      sportsMaxBettingPer = sportsBettingInfo?.twoFolderSportsMaxBettingPer || 0
      sportsMinBettingAmount = sportsBettingInfo?.twoFolderSportsMinBettingAmount || 0
    } else {
      sportsMaxBettingAmount = sportsBettingInfo?.multiFolderSportsMaxBettingAmount || 0
      sportsMaxWinningAmount = sportsBettingInfo?.multiFolderSportsMaxWinningAmount || 0
      sportsMaxBettingPer = sportsBettingInfo?.multiFolderSportsMaxBettingPer || 0
      sportsMinBettingAmount = sportsBettingInfo?.multiFolderSportsMinBettingAmount || 0
    }

    setRealSportsBettingInfo({
      sportsMaxBettingAmount,
      sportsMaxWinningAmount,
      sportsMaxBettingPer,
      sportsMinBettingAmount,
      sportsMinFolder,
      sportsMaxFolder,
    })
  }, [bettingCartCountExcludeBonus, sportsBettingInfo])

  // betting cart 요소 변경시
  useEffect(() => {
    switch (activeSportsMarketType) {
      case SportMarketType.DOMESTIC:
        dispatch(setDomesticActiveBetInfo(bettingCartList))
        dispatch(setBalanceActiveBetInfo(bettingCartList))
        break
      case SportMarketType.OVERSEAS:
        dispatch(setOverseasActiveBetInfo(bettingCartList))
        break
      case SportMarketType.SPECIAL:
        dispatch(setSpecialActiveBetInfo(bettingCartList))
        break
      case SportMarketType.LIVE:
        dispatch(setLiveActiveBetInfo(bettingCartList))
        break
      default:
        dispatch(setDomesticActiveBetInfo(bettingCartList))
    }
  }, [activeSportsMarketType, bettingCartList, dispatch])

  const [inputBettingMoney, setInputBettingMoney] = useState('0')
  const [expectMoney, setExpectMoney] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)

  useEffect(() => {
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니

    let resultBettingMoney = inputRealMoney
    let resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)

    const sportsMaxBettingAmount = realSportsBettingInfo?.sportsMaxBettingAmount || 0
    const sportsMaxWinningAmount = realSportsBettingInfo?.sportsMaxWinningAmount || 0

    if (inputRealMoney > sportsMaxBettingAmount) {
      // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
      resultBettingMoney = sportsMaxBettingAmount
      // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
      if (resultExpectMoney > sportsMaxWinningAmount) {
        resultBettingMoney = Math.floor(sportsMaxWinningAmount / totalBettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)
      }
    }
    if (resultExpectMoney > sportsMaxWinningAmount) {
      // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
      resultBettingMoney = Math.floor((sportsMaxWinningAmount || 0) / totalBettingPer) // 비율에 따른 최대 배팅금액
      resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(totalBettingPer * 100)) / 100)
    }

    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))
    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [inputBettingMoney, totalBettingPer])

  const onClickMoneyHandler = amountText => {
    const textNum = amountText || holdingMoney

    setInputBettingMoney(prev => {
      const prevprevValue = prev ? prev.toString().replaceAll(',', '') : ''
      const prevValue = prevprevValue.startsWith('0') ? prevprevValue.slice(1) : prevprevValue
      if ((prevValue === '' || prevValue === '0') && (textNum === '0' || textNum === '00' || textNum === '000')) {
        return prevValue?.toString().replace(commonReg2, ',')
      }

      if (textNum.length > 3) {
        // UI키패드 버튼으로 5만 ~ 200만 까지 눌렀을때
        if (prevValue === '' || prevValue === '0') {
          return textNum?.toString().replace(commonReg2, ',')
        }
        // UI키패드 버튼으로 0,00,000 , 1~9까지 눌렀을때
        return (Number(prevValue) + Number(textNum))?.toString().replace(commonReg2, ',')
      }
      return (prevValue + textNum)?.toString().replace(commonReg2, ',')
    })
  }

  // 정정 버튼 클릭 핸들러
  const onClickResetMoneyHandler = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
  }

  // 하나 삭제
  const onCloseSportsBettingCart = bettingCartItem => {
    dispatch(removeBettingCartItem(bettingCartItem))
  }
  // 전체 삭제
  const onClearSportsBettingCart = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
    dispatch(removeAllBettingCartItem())
  }

  // 배팅 딜레이
  const timeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      // Clean up the timeout when the component unmounts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
      dispatch(setBettingDelay(false))
      dispatch(setBettingCartLoading(false))
      setApiFlag(false)
    }
  }, [bettingCartAlertMessage, bettingCartUpdateMessage])

  const onSubmit = e => {
    if (apiFlag) return
    setApiFlag(true)

    if (bettingCartList.length === 0) {
      alert('배팅할 마켓을 먼저 선택해주세요.')
      setApiFlag(false)
      return
    }

    const resultLock = bettingCartList.some(
      item =>
        !item.sportVisible ||
        !item.locationVisible ||
        !item.leagueVisible ||
        !item.fixtureVisible ||
        !item.homeVisible ||
        !item.awayVisible ||
        !item.usedMarket ||
        !item.marketVisible ||
        !item.selectedBetInfo.showStatus ||
        item.selectedBetInfo.betStatus !== 'OPEN',
    )

    if (resultLock) {
      alert('배팅 불가인 옵션이 있습니다.')
      setApiFlag(false)
      return
    }

    const sportsMinFolder = realSportsBettingInfo?.sportsMinFolder || 0

    if (bettingCartList.length < sportsMinFolder) {
      alert(`최소 ${sportsMinFolder}폴더부터 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    const sportsMaxFolder = realSportsBettingInfo?.sportsMaxFolder || 0

    if (bettingCartList.length > sportsMaxFolder) {
      alert(`최대 ${sportsMaxFolder}폴더까지 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    const sportsMaxBettingPer = realSportsBettingInfo?.sportsMaxBettingPer || 0

    if (totalBettingPer > sportsMaxBettingPer) {
      alert(`최대 ${sportsMaxBettingPer}배당까지 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    if (Number(holdingMoney) < Number(inputBettingMoney?.toString().replaceAll(',', ''))) {
      alert('지갑머니가 부족합니다.')
      setApiFlag(false)
      return
    }

    const sportsMinBettingAmount = realSportsBettingInfo?.sportsMinBettingAmount || 0

    if (Number(inputBettingMoney?.toString().replaceAll(',', '')) < Number(sportsMinBettingAmount)) {
      alert('최소 배팅금액 이상으로 가능 합니다.')
      setApiFlag(false)
      return
    }

    const sportsMaxBettingAmount = realSportsBettingInfo?.sportsMaxBettingAmount || 0

    if (Number(inputBettingMoney) > Number(sportsMaxBettingAmount)) {
      alert('최대 배팅금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    const sportsMaxWinningAmount = realSportsBettingInfo?.sportsMaxWinningAmount || 0

    if (Number(expectMoney?.toString().replaceAll(',', '')) > Number(sportsMaxWinningAmount)) {
      alert('최대 당첨금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (window.confirm('배팅하시겠습니까?')) {
      const body = {
        bettingAmount: Number(inputBettingMoney?.toString().replaceAll(',', '')),
        sportMarketType: bettingCartList[0].sportMarketType,
        sportsDetailBettingKeyList: bettingCartList.map(cartItem => {
          return {
            fixtureId: cartItem.fixtureId,
            eventBetKeyList: cartItem.bets.map(betItem => betItem.eventBetKey),
            selectedBetKey: cartItem.selectedBetInfo.eventBetKey,
          }
        }),
        bonusFolder: Object.keys(selectedBonusFolder).length === 0 ? null : selectedBonusFolder,
      }

      if (sportsBettingInfo.bettingDelayTime > 0) {
        dispatch(setBettingDelay(true))
      }

      timeoutRef.current = setTimeout(() => {
        dispatch(setBettingDelay(false))
        dispatch(setBettingCartLoading(true))
        // API 호출
        bettingSportsEventV2(body)
          .then(res => {
            alert('배팅 성공했습니다.')
          })
          .catch(error => {
            const errorCode = error?.response?.data?.errorCode ?? ''

            switch (errorCode) {
              case 'SPORTS-1010':
                alert('마감된 경기가 있어 거절됩니다.')
                break
              case 'SYSTEM-1017':
                alert('보유한 머니가 충분하지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1031':
                alert('다폴 배팅허용기준에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1036':
                alert('최소 배당 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1037':
                alert('최대 배당 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1038':
                alert('최소 배팅액 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1039':
                alert('최대 배팅액 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1040':
                alert('최대 당첨액 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1041':
                alert('단폴 배팅이 허용되지 않은 마켓입니다.')
                break
              case 'SPORTS-1042':
                alert('배팅 대기시간으로 인해 배팅이 거절됩니다.')
                break
              case 'SPORTS-1045':
                alert('배당 변경으로 인해 배팅이 거절됩니다.')
                break
              default:
                alert('배팅 실패했습니다. 잠시후 다시 시도해주세요.')
            }

            dispatch(setBettingCartLoading(false))
            setApiFlag(false)
          })
          .finally(() => {
            dispatch(setBettingCartLoading(false))
            setApiFlag(false)
            onClickResetMoneyHandler()
            dispatch(removeAllBettingCartItem())
            dispatch(fetchMemberInfoAction())
          })
      }, (sportsBettingInfo?.bettingDelayTime ?? 0) * 1000)
    } else {
      dispatch(setBettingCartLoading(false))
      setApiFlag(false)
    }
  }

  useEffect(() => {
    // 다른페이지 배팅카트 초기화
    onClearSportsBettingCart()

    const { pathname } = location

    switch (pathname) {
      case '/sports/overseas':
        dispatch(setActiveSportsMarketType(SportMarketType.OVERSEAS))
        break
      case '/sports/special':
        dispatch(setActiveSportsMarketType(SportMarketType.SPECIAL))
        break
      case '/sports/live':
        dispatch(setActiveSportsMarketType(SportMarketType.LIVE))
        break
      case '/sports/domestic':
      case '/sports/balance':
      default:
        dispatch(setActiveSportsMarketType(SportMarketType.DOMESTIC))
        break
    }
  }, [location])

  const onClickCloseHandler = () => {
    dispatch(onClickBettingCartAlertCloseHandler())
  }

  const onClickBettingCartUpdateCloseHandler = () => {
    dispatch(onClickBettingCartUpdateMessageCloseHandler())
  }

  const [loading, setLoading] = useState(false)

  const getBettingInfoCompo = folderSize => {
    if (folderSize <= 1) {
      return (
        <>
          <div className="height_30">
            <span style={{ letterSpacing: '-1px' }}>단폴 최소 배팅금액 &gt;&gt;</span>
            <b>{realSportsBettingInfo?.sportsMinBettingAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
          </div>
          <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />
          <div className="height_30">
            <span style={{ letterSpacing: '-1px' }}>단폴 최대 배팅금액 &gt;&gt;</span>
            <b>{realSportsBettingInfo?.sportsMaxBettingAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
          </div>
          <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

          <div className="height_30">
            <span style={{ letterSpacing: '-1px' }}>단폴 최대 당첨금액 &gt;&gt;</span>
            <b>{realSportsBettingInfo?.sportsMaxWinningAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
          </div>
          <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

          <div className="height_30">
            <span style={{ letterSpacing: '-1px' }}>단폴 최대 배팅배당 &gt;&gt;</span>
            <b>{realSportsBettingInfo?.sportsMaxBettingPer?.toString().replace(commonReg2, ',') || 0} 배</b>
          </div>
          <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />
        </>
      )
    }
    if (folderSize === 2) {
      return (
        <>
          <div className="height_30">
            <span style={{ letterSpacing: '-1px' }}>두폴 최소 배팅금액 &gt;&gt;</span>
            <b>{realSportsBettingInfo?.sportsMinBettingAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
          </div>
          <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />
          <div className="height_30">
            <span style={{ letterSpacing: '-1px' }}>두폴 최대 배팅금액 &gt;&gt;</span>
            <b>{realSportsBettingInfo?.sportsMaxBettingAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
          </div>
          <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

          <div className="height_30">
            <span style={{ letterSpacing: '-1px' }}>두폴 최대 당첨금액 &gt;&gt;</span>
            <b>{realSportsBettingInfo?.sportsMaxWinningAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
          </div>
          <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

          <div className="height_30">
            <span style={{ letterSpacing: '-1px' }}>두폴 최대 배팅배당 &gt;&gt;</span>
            <b>{realSportsBettingInfo?.sportsMaxBettingPer?.toString().replace(commonReg2, ',') || 0} 배</b>
          </div>
          <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />
        </>
      )
    }
    return (
      <>
        <div className="height_30">
          <span style={{ letterSpacing: '-1px' }}>다폴 최소 배팅금액 &gt;&gt;</span>
          <b>{realSportsBettingInfo?.sportsMinBettingAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
        </div>
        <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />
        <div className="height_30">
          <span style={{ letterSpacing: '-1px' }}>다폴 최대 배팅금액 &gt;&gt;</span>
          <b>{realSportsBettingInfo?.sportsMaxBettingAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
        </div>
        <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

        <div className="height_30">
          <span style={{ letterSpacing: '-1px' }}>다폴 최대 당첨금액 &gt;&gt;</span>
          <b>{realSportsBettingInfo?.sportsMaxWinningAmount?.toString().replace(commonReg2, ',') || 0} 원</b>
        </div>
        <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

        <div className="height_30">
          <span style={{ letterSpacing: '-1px' }}>다폴 최대 배팅배당 &gt;&gt;</span>
          <b>{realSportsBettingInfo?.sportsMaxBettingPer?.toString().replace(commonReg2, ',') || 0} 배</b>
        </div>
        <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />
      </>
    )
  }

  const [scrollY, setScrollY] = useState(0)
  const betCartRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      if (!betCartRef.current) return

      const szNowTop = parseInt(betCartRef.current.style.top || '0', 10)
      const nBetween = scrollTop - szNowTop

      if (nBetween !== 0) {
        let newContentHeight = scrollTop
        if (scrollTop > 165) {
          newContentHeight = scrollTop - 165
        } else {
          newContentHeight = 0
        }
        setScrollY(newContentHeight)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const getPriceBox = item => {
    // 정규식 패턴: "0-0" 형태 검사
    const pattern = /^(\d+)-(\d+)$/
    const match = item.selectedBetInfo?.baseLineScore?.match(pattern)

    // 핸디 일때만
    if (item.marketFamilyType === 11 && match) {
      const homeScore = Number.isNaN(Number(match[1])) ? 0 : Number(match[1])
      const awayScore = Number.isNaN(Number(match[2])) ? 0 : Number(match[2])

      // 공식 : 베이스라인 기준점 - 홈 스코어 + 어웨이 스코어
      let finalLine = item.selectedBetInfo.baseLineNum - homeScore + awayScore

      // 원정팀 선택시
      if (item.selectedBetInfo.betName === '2') {
        finalLine *= -1
      }

      return (
        <>
          {item.selectedBetInfo?.line ? (
            <span className="team_on" id="pan0">
              {item.marketName}({finalLine.toFixed(1)}) - {item.selectedBetInfo.betNameKr}
            </span>
          ) : (
            <span className="team_on" id="pan0">
              {item.marketName} - {item.selectedBetInfo.betNameKr}
            </span>
          )}
        </>
      )
    }

    // e-sports 특정 라운드 승자 (마켓)
    if (item.marketFamilyType === 5) {
      return (
        <>
          {item.selectedBetInfo?.line ? (
            <span>
              {item.marketName}({item.selectedBetInfo?.line}) - {item.selectedBetInfo.betNameKr}
            </span>
          ) : (
            <span>
              {item.marketName} - {item.selectedBetInfo.betNameKr}
            </span>
          )}
        </>
      )
    }

    return (
      <>
        {item.selectedBetInfo?.line ? (
          <span className="team_on" id="pan0">
            {item.marketName}({item.selectedBetInfo.lineNum.toFixed(1)}) - {item.selectedBetInfo.betNameKr}
          </span>
        ) : (
          <span className="team_on" id="pan0">
            {item.marketName} - {item.selectedBetInfo.betNameKr}
          </span>
        )}
      </>
    )
  }

  return (
    <>
      {loading && <CustomLoading />}

      {bettingDelay && <CustomBettingAlert bettingDelayTime={sportsBettingInfo?.bettingDelayTime ?? 0} />}

      {bettingCartAlertMessage && (
        <CustomAlert info={bettingCartAlertMessage} onClickCloseHandler={onClickCloseHandler} />
      )}
      {bettingCartUpdateMessage && (
        <CustomAlert info={bettingCartUpdateMessage} onClickCloseHandler={onClickBettingCartUpdateCloseHandler} />
      )}

      <div
        className="bet_cart"
        id="bet_cart"
        style={{ top: `${scrollY}px`, transition: 'all 0.3s linear' }}
        ref={betCartRef}
      >
        <div className="body">
          <div className="bet_cart_title">
            <table style={{ width: '100%', height: '100%' }} className="tb_basic">
              <tbody>
                <tr>
                  <td>&nbsp;&nbsp;BETTING CART</td>
                  <td style={{ width: '35px', textAlign: 'right' }}>
                    <a
                      style={{ color: '#fff' }}
                      onClick={() => {
                        onClearSportsBettingCart()
                      }}
                    >
                      삭제
                    </a>
                  </td>
                  {/* <td style={{ width: '35px', textAlign: 'right' }}>
                    <a href="javascript:change_fixed_cart();" id="fix_cart" style={{ color: 'white' }}>
                      고정
                    </a>
                  </td> */}
                  <td style={{ width: '10px' }} />
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ height: '2px' }} />

          {/* bet item */}
          {bettingCartList.map(item => {
            const isLock =
              !item.sportVisible ||
              !item.locationVisible ||
              !item.leagueVisible ||
              !item.fixtureVisible ||
              !item.homeVisible ||
              !item.awayVisible ||
              !item.usedMarket ||
              !item.marketVisible ||
              !item.selectedBetInfo.showStatus ||
              item.selectedBetInfo.betStatus !== 'OPEN'

            return (
              <div>
                <div className="bet_item">
                  {isLock && (
                    <BettingWhatWrapOverlay>
                      <BettingWhatLockIcon />
                    </BettingWhatWrapOverlay>
                  )}
                  <div style={{ height: '16px', lineHeight: '16px' }}>
                    <span className="team" id="phn0">
                      {`${item.homeName} vs ${item.awayName}`}
                    </span>
                    <b>
                      <a
                        onClick={() => {
                          onCloseSportsBettingCart(item)
                        }}
                      >
                        <img src={`${process.env.PUBLIC_URL}/img/common/button_bet_del.png`} alt="" />
                      </a>
                    </b>
                  </div>
                  <div style={{ height: '1px', clear: 'both' }} />
                  <div style={{ height: '16px', lineHeight: '16px' }}>
                    {getPriceBox(item)}
                    <b>
                      &nbsp;<span id="pr0">{item.selectedBetInfo.priceNum}</span>
                    </b>
                  </div>
                </div>
              </div>
            )
          })}

          {Object.keys(selectedBonusFolder).length !== 0 && (
            <div>
              <div className="bet_item">
                <div style={{ height: '16px', lineHeight: '16px' }}>
                  <span className="team" id="phn0">
                    보너스 배당
                  </span>
                  <b>
                    <a
                      onClick={() => {
                        dispatch(setSelectedBonusFolder({}))
                      }}
                    >
                      <img src={`${process.env.PUBLIC_URL}/img/common/button_bet_del.png`} alt="" />
                    </a>
                  </b>
                </div>
                <div style={{ height: '1px', clear: 'both' }} />
                <div style={{ height: '16px', lineHeight: '16px' }}>
                  <span className="team_on" id="pan0">
                    {selectedBonusFolder.bonusFolderNum}폴더 이상
                  </span>
                  <b>
                    &nbsp;<span id="pr0">{selectedBonusFolder.bonusFolderPer}</span>
                  </b>
                </div>
              </div>
            </div>
          )}

          {/* 보유액 */}
          <div style={{ height: '1px' }} />
          <div className="back">
            <div className="bet_info_box">
              <div className="height_30">
                <span>보유액 &gt;&gt;</span>
                <b>{holdingMoney?.toString().replace(commonReg2, ',')} 원</b>
              </div>
              <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />
              <div className="height_30">
                <span>배당률 &gt;&gt;</span>
                <b>{totalBettingPer || 0}</b>
              </div>
              <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

              <div className="height_30">
                <span>배팅액 &gt;&gt;</span>
                <b>
                  <a
                    className="btMax"
                    style={{ width: '40px', marginRight: '3px' }}
                    onClick={() => {
                      onClickMoneyHandler('')
                    }}
                  >
                    MAX
                  </a>
                  <input
                    type="text"
                    className="input_money_box"
                    style={{ width: '80px', height: '21px' }}
                    value={inputBettingMoney}
                    onChange={e => {
                      const value = e.target.value.trim()
                      const numbersOnly = value.match(/\d+/g)?.join('')

                      if (!numbersOnly) {
                        setInputBettingMoney('0')
                      } else {
                        setInputBettingMoney(numbersOnly.replace(/^0+/, '').replace(commonReg2, ','))
                      }
                    }}
                  />
                </b>
              </div>
              <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

              <div className="height_30">
                <span>적중액 &gt;&gt;</span>
                <b>{expectMoney?.toString().replace(commonReg2, ',') || 0} 원</b>
              </div>
              <div style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.50)' }} />

              {getBettingInfoCompo(bettingCartCountExcludeBonus)}
            </div>
          </div>

          {/* 금액 버튼 */}
          <div style={{ height: '3px' }} />
          <div className="back">
            <div className="height_5" />
            <div className="bet_info_box">
              <div style={{ height: '1px' }} />
              <div style={{ textAlign: 'center' }}>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickMoneyHandler('10000')
                  }}
                >
                  1만원
                </a>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickMoneyHandler('50000')
                  }}
                >
                  5만원
                </a>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickMoneyHandler('100000')
                  }}
                >
                  10만원
                </a>
              </div>
              <div style={{ height: '3px' }} />
              <div style={{ textAlign: 'center' }}>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickMoneyHandler('500000')
                  }}
                >
                  50만원
                </a>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickMoneyHandler('1000000')
                  }}
                >
                  100만원
                </a>
                <a
                  className="btBet_Money"
                  style={{ width: '66px' }}
                  onClick={() => {
                    onClickResetMoneyHandler()
                  }}
                >
                  초기화
                </a>
              </div>
              <div style={{ height: '1px' }} />
            </div>
            <div className="height_5" />
          </div>

          {/* 배팅 버튼 */}
          <div style={{ height: '3px' }} />
          <div className="back">
            <div style={{ height: '8px' }} />
            <div style={{ textAlign: 'center' }}>
              <a
                className="btBet_Money"
                style={{ width: '210px', lineHeight: '34px', height: '34px' }}
                onClick={() => {
                  onSubmit()
                }}
              >
                배팅하기
              </a>
            </div>
            <div style={{ height: '8px' }} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SportsBettingCartSidebar

const BettingWhatWrapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
`

const BettingWhatLockIcon = styled(FaLock)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`
