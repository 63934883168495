import React from 'react'
import { MoneyWithdrawalEnumsStatusEnums } from '@/utils/enums/MyPage/myPageEnums'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { convertToKstDateTime } from '@/utils/dateTime'
import WithdrawalDeleteRadio from '../../MoneyWithdrawal/WithdrawalDeleteRadio'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MoneyWithdrawalListData = (content, checkRadio, setCheckRadio) => {
  const columns = [
    {
      Header: '삭제',
      accessor: 'deleteRadio',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <WithdrawalDeleteRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 25,
    },
    {
      Header: 'ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 80,
    },
    {
      Header: '출금액',
      accessor: 'applyMoney',
      disableGlobalFilter: true,
      width: 90,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.applyMoney.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 30,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MoneyWithdrawalEnumsStatusEnums[original.status]
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MoneyWithdrawalListData
