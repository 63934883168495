import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import CustomLoading from '../../CustomLoading'
import SportsBettingCartSidebar from '../../Layout/SportsBettingCartSidebar'
import SportsSpecialHeader from './SportsSpecialHeader'
import SportsSpecialContent from './SportsSpecialContent'
import { setSelectedSportId } from '../../../redux/sportsSpecialInfoSlice'

const SportsSpecialPage = () => {
  const { specialLoading, bettingCartLoading } = useSelector(state => {
    const { sportsSpecialInfo, sportsBetting } = state

    return {
      specialLoading: sportsSpecialInfo.specialLoading,
      bettingCartLoading: sportsBetting.bettingCartLoading,
    }
  })

  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(setSelectedSportId(-1))
    }
  }, [])

  return (
    <>
      {(specialLoading || bettingCartLoading) && (
        <SportsPageLoadingOverlay>
          <CustomLoading isGameStart info={bettingCartLoading ? '배팅 처리중입니다.' : ''} />
        </SportsPageLoadingOverlay>
      )}

      <div className="div_content" style={{ position: 'relative' }}>
        <input type="hidden" id="bet_cart_fixed" value="F" />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '963px', height: '0px' }} />
              <td style={{ width: '7px', height: '0px' }} />
              <td style={{ width: '230px', height: '0px' }} />
            </tr>
            <tr>
              {/* 메인 화면 */}
              <td style={{ width: '963px', verticalAlign: 'top' }}>
                <div className="div_body" style={{ minHeight: '0' }}>
                  <div className="content" style={{ width: '963px', marginBottom: '10px' }}>
                    <SportsSpecialHeader />
                  </div>
                </div>

                <div className="div_body" style={{ display: 'flex' }}>
                  <SportsSpecialContent />
                </div>
              </td>

              {/* 공백 */}
              <td style={{ width: '7px' }} />
              {/* 배팅카트 */}
              <td style={{ verticalAlign: 'top', width: '230px' }}>
                <SportsBettingCartSidebar />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SportsSpecialPage

const SportsPageLoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsPageWrap = styled.div`
  width: 1277px;
`

const SportsContentWrap = styled.div`
  border-radius: 0 0 3px 3px;
  background: #111314;
  position: relative;
  display: flex;
  flex-direction: column;
`
