import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { deleteMiniGameBettingHistory, searchMiniGameBettingLogAll } from '../../api/game/miniGameApi'
import { CustomButtonInfos } from '../../mobile/mobileBettingHistory/MobileMiniGameBettingHistory'
import { CustomDeleteButton } from '../../mobile/mobileCasinoGame/MobileLiveCasino'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import {
  MiniGameBettingTypeEnums,
  MiniGameTypeEnums,
  MiniGameVendor,
  PowerBallTypeEnums,
} from '../../utils/enums/MiniGame/PowerBallEnums'
import { HermesMainBorder } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomLoading from '../CustomLoading'
import MiniGameBettingHistoryRadio from './MiniGameBettingHistoryRadio'

const MiniGameBettingHistoryV2 = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      page,
      size,
    }))
  }, [page, size])

  const [totalElements, setTotalElements] = useState(0)
  const [miniGameBettingHistoryContent, setMiniGameBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchMiniGameBettingHistory = async params => {
    await searchMiniGameBettingLogAll(params)
      .then(res => {
        setTotalElements(res.data.totalElement)
        setMiniGameBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchMiniGameBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteMiniGameBettingHistory({
        miniGameBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchMiniGameBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchMiniGameBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  const [miniGameVendor, setMiniGameVendor] = useState(null)

  // 미니게임 종목 선택
  const onClickMiniGameTypeHandler = type => {
    setMiniGameVendor(type)

    setSportsBettingHistoryParams(prev => ({
      ...prev,
      page: 0,
      miniGameVendor: type || null,
    }))
  }

  return (
    <>
      <BettingHistoryTableWrap className="div_Bet_List" ref={sportsBettingHistoryWrapRef}>
        {loading && (
          <SportsGamePageLoadingOverlay>
            <CustomLoading isGameStart info={loadingInfo} />
          </SportsGamePageLoadingOverlay>
        )}
        <ListWrap>
          <SubMenuItemListWrap>
            <SubMenuItemBox
              className="normal_button"
              active={!miniGameVendor}
              onClick={() => {
                onClickMiniGameTypeHandler(null)
              }}
            >
              전체
            </SubMenuItemBox>
            {Object.entries(MiniGameVendor).map(([key, value]) => {
              return (
                <SubMenuItemBox
                  className="normal_button"
                  active={key === miniGameVendor}
                  onClick={() => {
                    onClickMiniGameTypeHandler(key)
                  }}
                >
                  {value}
                </SubMenuItemBox>
              )
            })}
          </SubMenuItemListWrap>

          <div style={{ display: 'flex', gap: '5px' }}>
            <a
              className="normal_button"
              style={{ width: '110px' }}
              onClick={() => {
                setCheckRadio(
                  miniGameBettingHistoryContent
                    .filter(value => value.bettingResult !== 'ING')
                    .map(value => value.miniGameBettingLogKey),
                )
              }}
            >
              전체선택
            </a>
            <a className="normal_button" style={{ width: '110px' }} onClick={() => onDeleteHandler()}>
              삭제하기
            </a>
            {/* <CustomDeleteButton
              
              type="button"
            >
              <CustomButtonInfos>전체선택</CustomButtonInfos>
            </CustomDeleteButton>
            <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <CustomButtonInfos>삭제하기</CustomButtonInfos>
            </CustomDeleteButton> */}
          </div>
        </ListWrap>
        {miniGameBettingHistoryContent.length === 0 ? (
          <NoContentDiv>
            <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
            미니게임 배팅내역이 없습니다.
            {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
          </NoContentDiv>
        ) : (
          <div className="bet_group" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {miniGameBettingHistoryContent.map((item, index) => (
              <table style={{ textAlign: 'center' }} className="tb_bet_list">
                <colgroup>
                  <col style={{ width: '3%' }} />
                  <col style={{ width: '8%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '7%' }} />
                  <col style={{ width: '7%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>배팅일시</th>
                    <th>게임명</th>
                    <th>배팅종목</th>
                    <th>회차</th>
                    <th>배당</th>
                    <th>배팅액</th>
                    <th>당첨액</th>
                    <th>배팅결과</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <MiniGameBettingHistoryRadio
                        miniGameBettingLogKey={item.miniGameBettingLogKey}
                        bettingResult={item.bettingResult}
                        checkRadio={checkRadio}
                        setCheckRadio={setCheckRadio}
                      />
                    </td>
                    <td>{convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}</td>
                    <td style={{ color: '#000', background: 'linear-gradient(#fff, #f5fcff)' }}>
                      {MiniGameTypeEnums[item.miniGameType]}
                    </td>
                    <td style={{ color: '#fff', fontWeight: '600', background: 'linear-gradient(#000, #000)' }}>
                      {PowerBallTypeEnums[item.miniGameBettingType]}
                    </td>
                    <td>{item.gameRound}</td>
                    <td>{item.bettingPer}</td>
                    <td style={{ color: '#000', background: 'linear-gradient(#fff, #f5fcff)' }}>
                      {item.bettingAmount?.toString().replace(commonReg2, ',')}
                    </td>
                    <td style={{ color: '#fff', fontWeight: '600', background: 'linear-gradient(#000, #000)' }}>
                      {item.winningAmount?.toString().replace(commonReg2, ',')}
                    </td>
                    <td>
                      {
                        {
                          ING: (
                            <button
                              type="button"
                              style={{
                                width: '60px',
                                height: '20px',
                                background: 'green',
                                color: 'white',
                                fontWeight: '900',
                                border: 'none',
                              }}
                            >
                              {MiniGameBettingTypeEnums[item.bettingResult]}
                            </button>
                          ),

                          WIN: (
                            <button
                              type="button"
                              style={{
                                width: '60px',
                                height: '20px',
                                background: 'blue',
                                color: 'white',
                                fontWeight: '900',
                                border: 'none',
                              }}
                            >
                              {MiniGameBettingTypeEnums[item.bettingResult]}
                            </button>
                          ),
                          LOSE: (
                            <button
                              type="button"
                              style={{
                                width: '60px',
                                height: '20px',
                                background: 'red',
                                color: 'white',
                                fontWeight: '900',
                                border: 'none',
                              }}
                            >
                              {MiniGameBettingTypeEnums[item.bettingResult]}
                            </button>
                          ),
                        }[item.bettingResult]
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>
        )}
        {/* {totalElements > 0 && (
          <ListWrap>
            <CustomDeleteButton
              onClick={() => {
                setCheckRadio(
                  miniGameBettingHistoryContent
                    .filter(value => value.bettingResult !== 'ING')
                    .map(value => value.miniGameBettingLogKey),
                )
              }}
              type="button"
            >
              <CustomButtonInfos>전체선택</CustomButtonInfos>
            </CustomDeleteButton>
            <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <CustomButtonInfos>삭제하기</CustomButtonInfos>
            </CustomDeleteButton>
          </ListWrap>
        )} */}
      </BettingHistoryTableWrap>
      {totalElements > 0 && <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />}
    </>
  )
}

export default MiniGameBettingHistoryV2

const SubMenuItemListWrap = styled.div`
  // border-bottom: 1px solid rgb(41, 41, 41, 0.2);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  // padding-left: 10px;
  gap: 10px;
  // border: solid 2px red;
`

const SubMenuItemBox = styled.div`
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  border-radius: 24px;
  &:hover {
    border: solid 1px #000;
  }

  ${props =>
    props.active &&
    `
    border: solid 1px #000 !important;
    font-weight: 500 !important;
    `}
`

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 120px;
    height: auto;
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  margin-bottom: 20px;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #525252;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    border: solid 1px ${HermesMainBorder};
    cursor: pointer;
  }
`

const BettingHistoryTableHead = styled.thead`
  th {
    background: none;
  }
`

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  div {
    font-weight: 500;
  }
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px #525252;
  border-right: solid 1px #525252;
  border-bottom: solid 1px #525252;
  font-weight: 700;
  // &:hover {
  //   border: solid 2px #eec100;
  //   cursor: pointer;
  // }
`

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;

  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  &:not(:last-child) {
    border-right: 1px solid #525252;
  }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: #b88907;
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`
