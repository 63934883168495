import Error from '@/shared/components/form/Error'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { siginOut } from '../../../api/authManage/authManageApi'
import { searchMemberInfo, updateMemberInfo } from '../../../api/memberManage/memberManageApi'
import { ButtonH40 } from '../../../shared/components/GoldButton'
import { WebPageWrap } from '../../../shared/components/form/newForm'
import { convertToKstDate } from '../../../utils/dateTime'
import {
  MemberCasinoLevelEnum,
  MemberDepositLevelEnum,
  MemberMiniGameLevelEnum,
  MemberSportsGameLevelEnum,
} from '../../../utils/enums/MemberManage/MemberInfoEnums'
import { memberInfoModifyValidate } from '../../../utils/validate/myPage/myPageValidate'
import CustomLoading from '../../CustomLoading'
import { MyPageContentWrap, MyPageHeaderInfo, MyPageHeaderWrap, MyPageSubhead } from '../MyPageElement'

const MyInfo = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [writeLoading, setWriteLoading] = useState(false)

  // 버튼 이름 false : '내정보 수정하기' , true : '내정보 수정완료'
  const [btnToComplete, setBtnToComplete] = useState(false)
  const [myInfoBtnDefault, setMyInfoBtnDefault] = useState(false)

  // 새로운 비밀번호
  const [newPassword, setNewPassword] = useState('')
  const [nowPassword, setNowPassword] = useState('')
  const [withdrawlPassword, setWithdrawlPassword] = useState('')
  const [isPassCheck, setIsPassCheck] = useState(false)
  const [isBankPassCheck, setIsBankPassCheck] = useState(false)
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')

  const onChangeMyInfoStatusBtnHandler = () => {
    setBtnToComplete(true)
  }

  // 아이디
  const [userId, setUserId] = useState('')
  // 닉네임
  const [nickName, setNickName] = useState('')
  // 이름
  const [memberName, setMemberName] = useState('')
  // 연락처
  const [memberPhoneNum, setMemberPhoneNum] = useState('')
  // 닉네임
  const [userBirthDay, setUserBirthDay] = useState('')

  // 입금 레벨
  const [depositLevel, setDepositLevel] = useState('')

  // 예금주명
  const [accountHolder, setAccountHolder] = useState('')
  // 은행명
  const [bankName, setBankName] = useState('')
  // 계좌번호
  const [accountNum, setAccountNum] = useState('')

  useEffect(() => {
    setLoading(true)
    searchMemberInfo({})
      .then(res => {
        setUserId(res.data.content.memberId)
        setMemberName(res.data.content.memberName)
        setMemberPhoneNum(res.data.content.phoneNum)
        setNickName(res.data.content.nickName)
        setDepositLevel(MemberDepositLevelEnum[res.data.content.depositLevel])
        setUserBirthDay(res.data.content.userBirthDay)
        setAccountHolder(res.data.content.memberName)
        setBankName(res.data.content.bankInfo.bankName)
        setAccountNum(res.data.content.accountNum)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const onSubmit = e => {
    if (writeLoading) return
    if (isPassCheck) {
      if (!newPassword) {
        alert('새로운 암호를 다시 확인해주세요.')
        return
      }
      if (newPassword === nowPassword) {
        alert('새로운 암호와 기존암호가 동일합니다.')
        return
      }
      if (newPassword.length === 1) {
        alert('새로운 암호를 올바르게 입력하세요.')
        return
      }
    }
    if (isBankPassCheck) {
      if (!withdrawlPassword) {
        alert('새로운 출금암호를 다시 확인해주세요.')
        return
      }
      if (newPassword.length !== 4) {
        alert('출금암호는 4자리로 입력해주세요.')
        return
      }
    }
    const body = {
      password: nowPassword,
      newPassword: isPassCheck ? newPassword : null,
      newWithdrawlPassword: isBankPassCheck ? withdrawlPassword : null,
    }
    setWriteLoading(true)
    updateMemberInfo(body)
      .then(res => {
        // onModalShowHandler()
        setWriteLoading(false)
        alert('회원 정보가 수정됐습니다. 다시 로그인해주세요.')
        siginOut()
          .then(resp => {
            navigate('/', { replace: true })
          })
          .catch(error => {})
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'SIGN-1005':
            alert('기존 비밀번호가 일치하지 않습니다.')
            break
          default:
            alert('회원 정보 수정에 실패하였습니다.')
        }
        setWriteLoading(false)
        window.location.reload()
      })
  }

  return (
    <>
      {loading && <CustomLoading />}
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            <div>
              <table className="tb_notice_box">
                <tbody>
                  <tr>
                    <td className="td_notice_td">
                      <span style={{ fontSize: '11pt' }}>
                        <b>
                          <span style={{ color: 'rgb(0,0,0)' }}>개인정보 변경은 고객센터로 문의 부탁드립니다.</span>
                        </b>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="height_20" />
            <div>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: '140px' }}>
                      <div>
                        <a
                          className="buttonon"
                          onClick={() => {
                            navigate('/mypage')
                          }}
                        >
                          회원정보
                        </a>
                      </div>
                    </td>
                    <td style={{ width: '4px' }} />
                    <td style={{ width: '140px' }}>
                      <div>
                        <a
                          className="buttonoff"
                          onClick={() => {
                            navigate('/money/moneyexchange')
                          }}
                        >
                          머니이동내역
                        </a>
                      </div>
                    </td>
                    <td style={{ width: '4px' }} />
                    <td style={{ width: '140px' }}>
                      <div>
                        <a
                          className="buttonoff"
                          onClick={() => {
                            navigate('/friend-list')
                          }}
                        >
                          추천목록
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="height_20" />
            <div>
              <table className="tb_solid_table">
                <tbody>
                  <tr>
                    <td className="title">회원아이디</td>
                    <td id="CPH_ui_id" className="body_white">
                      {userId}
                    </td>
                  </tr>
                  <tr>
                    <td className="title">회원닉네임</td>
                    <td id="CPH_ui_nick" className="body_white">
                      {nickName}
                    </td>
                  </tr>
                  <tr>
                    <td className="title">비밀번호</td>
                    <td className="body_white">
                      <input
                        type="password"
                        className="input_pass"
                        id="ui_passwd"
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                        disabled={!isPassCheck}
                      />
                      &nbsp;
                      <label htmlFor="pass_check" style={{ cursor: 'pointer' }}>
                        <input
                          type="checkbox"
                          id="pass_check"
                          checked={isPassCheck}
                          onChange={e => setIsPassCheck(e.target.checked)}
                          className={isPassCheck ? 'checked' : ''}
                        />{' '}
                        비밀번호 변경
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">환전 비밀번호</td>
                    <td className="body_white">
                      <input
                        type="password"
                        className="input_pass"
                        id="ui_bank_pass"
                        value={withdrawlPassword}
                        onChange={e => setWithdrawlPassword(e.target.value)}
                        disabled={!isBankPassCheck}
                      />
                      &nbsp;
                      <label htmlFor="bank_pass_check" style={{ cursor: 'pointer' }}>
                        <input
                          style={{ width: '14px', height: '14px' }}
                          type="checkbox"
                          id="bank_pass_check"
                          checked={isBankPassCheck}
                          onChange={e => setIsBankPassCheck(e.target.checked)}
                          className={isBankPassCheck ? 'checked' : ''}
                        />{' '}
                        환전 비밀번호 변경
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="title">은행명</td>
                    <td id="CPH_ui_banktype" className="body_white">
                      ****
                    </td>
                  </tr>
                  <tr>
                    <td className="title">계좌번호</td>
                    <td id="CPH_ui_banknumber" className="body_white">
                      ************
                    </td>
                  </tr>
                  <tr>
                    <td className="title">예금주</td>
                    <td id="CPH_ui_bankname" className="body_white">
                      ***
                    </td>
                  </tr>
                  <tr>
                    <td className="title">핸드폰</td>
                    <td id="CPH_ui_phone" className="body_white">
                      *************
                    </td>
                  </tr>
                  <tr>
                    <td className="title">현재 비밀번호</td>
                    <td className="body_white">
                      <input
                        type="password"
                        className="input_pass"
                        id="ui_old_pass"
                        value={nowPassword}
                        onChange={e => setNowPassword(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="div_button">
              <div style={{ height: '20px' }} />
              <a
                onClick={() => {
                  onSubmit()
                }}
                className="normal_button"
              >
                회원정보수정
              </a>
            </div>
          </div>
        </div>
      </div>
    </>

    // <WebPageWrap>
    //   <MyPageHeaderWrap>
    //     <MyPageHeaderInfo>내 정보</MyPageHeaderInfo>
    //     <MyPageSubhead>* 비밀번호 외 변경사항은 관리자에게 문의 부탁드립니다.</MyPageSubhead>
    //   </MyPageHeaderWrap>
    //   {loading ? (
    //     <CustomLoading />
    //   ) : (
    //     <MyPageContentWrap>
    //       <Form
    //         onSubmit={onSubmit}
    //         initialValues={{
    //           userId,
    //           memberPhoneNum: memberPhoneNum || '-',
    //           level: `${depositLevel}`,
    //           nickName,
    //           userBirthDay: convertToKstDate(userBirthDay),
    //           memberName,
    //           bankName: bankName || '-',
    //           accountNum: accountNum || '-',
    //           accountHolder: accountHolder || '-',
    //           nowPassword,
    //           newPassword,
    //           newPasswordConfirm,
    //         }}
    //         validate={memberInfoModifyValidate}
    //       >
    //         {({ handleSubmit, form: { reset } }) => (
    //           <MyInfoFormWrap horizontal onSubmit={handleSubmit}>
    //             <MyInfoFormGroup>
    //               <MyInfoFormLabel>아이디</MyInfoFormLabel>
    //               <MyInfoFormField>
    //                 <Field name="userId">
    //                   {({ input, meta }) => (
    //                     <MyInfoFormInputWrap>
    //                       <MyInfoFormInput {...input} type="text" readOnly disabled />
    //                     </MyInfoFormInputWrap>
    //                   )}
    //                 </Field>
    //               </MyInfoFormField>
    //             </MyInfoFormGroup>
    //             <MyInfoFormGroup>
    //               <MyInfoFormLabel>닉네임</MyInfoFormLabel>
    //               <MyInfoFormField>
    //                 <Field name="nickName">
    //                   {({ input, meta }) => (
    //                     <MyInfoFormInputWrap>
    //                       <MyInfoFormInput {...input} type="text" readOnly disabled />
    //                     </MyInfoFormInputWrap>
    //                   )}
    //                 </Field>
    //               </MyInfoFormField>
    //             </MyInfoFormGroup>
    //             <MyInfoFormGroup>
    //               <MyInfoFormLabel>이름</MyInfoFormLabel>
    //               <MyInfoFormField>
    //                 <Field name="memberName">
    //                   {({ input, meta }) => (
    //                     <MyInfoFormInputWrap>
    //                       <MyInfoFormInput {...input} type="text" readOnly disabled />
    //                     </MyInfoFormInputWrap>
    //                   )}
    //                 </Field>
    //               </MyInfoFormField>
    //             </MyInfoFormGroup>
    //             <MyInfoFormGroup>
    //               <MyInfoFormLabel>연락처</MyInfoFormLabel>
    //               <MyInfoFormField>
    //                 <Field name="memberPhoneNum">
    //                   {({ input, meta }) => (
    //                     <MyInfoFormInputWrap>
    //                       <MyInfoFormInput {...input} type="text" readOnly disabled />
    //                     </MyInfoFormInputWrap>
    //                   )}
    //                 </Field>
    //               </MyInfoFormField>
    //             </MyInfoFormGroup>
    //             <MyInfoFormGroup>
    //               <MyInfoFormLabel>생년월일</MyInfoFormLabel>
    //               <MyInfoFormField>
    //                 <Field name="userBirthDay">
    //                   {({ input, meta }) => (
    //                     <MyInfoFormInputWrap>
    //                       <MyInfoFormInput {...input} type="text" readOnly disabled />
    //                     </MyInfoFormInputWrap>
    //                   )}
    //                 </Field>
    //               </MyInfoFormField>
    //             </MyInfoFormGroup>
    //             <MyInfoFormGroup>
    //               <MyInfoFormLabel>레벨</MyInfoFormLabel>
    //               <MyInfoFormField>
    //                 <Field name="level">
    //                   {({ input, meta }) => (
    //                     <MyInfoFormInputWrap>
    //                       <MyInfoFormInput {...input} type="text" readOnly disabled />
    //                     </MyInfoFormInputWrap>
    //                   )}
    //                 </Field>
    //               </MyInfoFormField>
    //             </MyInfoFormGroup>
    //             <MyInfoFormGroup>
    //               <MyInfoFormLabel>예금주명</MyInfoFormLabel>
    //               <MyInfoFormField>
    //                 <Field name="accountHolder">
    //                   {({ input, meta }) => (
    //                     <MyInfoFormInputWrap>
    //                       <MyInfoFormInput {...input} type="text" readOnly disabled />
    //                     </MyInfoFormInputWrap>
    //                   )}
    //                 </Field>
    //               </MyInfoFormField>
    //             </MyInfoFormGroup>
    //             <MyInfoFormGroup>
    //               <MyInfoFormLabel>은행명</MyInfoFormLabel>
    //               <MyInfoFormField>
    //                 <Field name="bankName">
    //                   {({ input, meta }) => (
    //                     <MyInfoFormInputWrap>
    //                       <MyInfoFormInput {...input} type="text" readOnly disabled />
    //                     </MyInfoFormInputWrap>
    //                   )}
    //                 </Field>
    //               </MyInfoFormField>
    //             </MyInfoFormGroup>
    //             <MyInfoFormGroup>
    //               <MyInfoFormLabel>계좌번호</MyInfoFormLabel>
    //               <MyInfoFormField>
    //                 <Field name="accountNum">
    //                   {({ input, meta }) => (
    //                     <MyInfoFormInputWrap>
    //                       <MyInfoFormInput {...input} type="text" readOnly disabled />
    //                     </MyInfoFormInputWrap>
    //                   )}
    //                 </Field>
    //               </MyInfoFormField>
    //             </MyInfoFormGroup>
    //             {btnToComplete ? (
    //               <>
    //                 <MyInfoFormGroup>
    //                   <MyInfoFormLabel>현재 비밀번호</MyInfoFormLabel>
    //                   <MyInfoFormField>
    //                     <Field name="nowPassword">
    //                       {({ input, meta }) => (
    //                         <>
    //                           <MyInfoFormInputWrap>
    //                             <MyInfoFormInput
    //                               {...input}
    //                               type="password"
    //                               inputMode="latin"
    //                               value={nowPassword}
    //                               onChange={e => {
    //                                 setNowPassword(e.target.value)
    //                               }}
    //                             />
    //                           </MyInfoFormInputWrap>
    //                           {meta.touched && meta.error && <Error error={meta.error} />}
    //                         </>
    //                       )}
    //                     </Field>
    //                   </MyInfoFormField>
    //                 </MyInfoFormGroup>
    //                 <MyInfoFormGroup>
    //                   <MyInfoFormLabel>변경 비밀번호</MyInfoFormLabel>
    //                   <MyInfoFormField>
    //                     <Field name="newPassword">
    //                       {({ input, meta }) => (
    //                         <>
    //                           <MyInfoFormInputWrap>
    //                             <MyInfoFormInput
    //                               {...input}
    //                               type="password"
    //                               inputMode="latin"
    //                               value={newPassword}
    //                               onChange={e => {
    //                                 setNewPassword(e.target.value)
    //                               }}
    //                             />
    //                           </MyInfoFormInputWrap>
    //                           {meta.touched && meta.error && <Error error={meta.error} />}
    //                         </>
    //                       )}
    //                     </Field>
    //                   </MyInfoFormField>
    //                 </MyInfoFormGroup>
    //                 <MyInfoFormGroup>
    //                   <MyInfoFormLabel>변경 비밀번호 확인</MyInfoFormLabel>
    //                   <MyInfoFormField>
    //                     <Field name="newPasswordConfirm">
    //                       {({ input, meta }) => (
    //                         <>
    //                           <MyInfoFormInputWrap>
    //                             <MyInfoFormInput
    //                               {...input}
    //                               type="password"
    //                               inputMode="latin"
    //                               value={newPasswordConfirm}
    //                               onChange={e => {
    //                                 setNewPasswordConfirm(e.target.value)
    //                               }}
    //                             />
    //                           </MyInfoFormInputWrap>
    //                           {meta.touched && meta.error && <Error error={meta.error} />}
    //                         </>
    //                       )}
    //                     </Field>
    //                   </MyInfoFormField>
    //                 </MyInfoFormGroup>
    //               </>
    //             ) : (
    //               <></>
    //             )}
    //             <MyInfoFormButtonWrap>
    //               {btnToComplete ? (
    //                 <MyInfoFormButtom variant="outline-secondary" visible type="submit">
    //                   <MyInfoFormButtomInfo>내정보 수정완료</MyInfoFormButtomInfo>
    //                 </MyInfoFormButtom>
    //               ) : (
    //                 <MyInfoFormButtom
    //                   type="button"
    //                   visible={!btnToComplete}
    //                   onClick={() => onChangeMyInfoStatusBtnHandler()}
    //                 >
    //                   <MyInfoFormButtomInfo>내정보 수정하기</MyInfoFormButtomInfo>
    //                 </MyInfoFormButtom>
    //               )}
    //             </MyInfoFormButtonWrap>
    //           </MyInfoFormWrap>
    //         )}
    //       </Form>
    //     </MyPageContentWrap>
    //   )}
    // </WebPageWrap>
  )
}

export default MyInfo

const MyInfoFormWrap = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`

const MyInfoFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

// sign up form label
const MyInfoFormLabel = styled.div`
  width: 160px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: right;
`

// sign up form field
const MyInfoFormField = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
`

// sign up form input wrap
const MyInfoFormInputWrap = styled.div`
  width: 100%;
  border: 1px solid #4a4a4a;
  position: relative;
  // height: 35px;
  height: 35px;
`

// sign up form input
const MyInfoFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  line-height: 35px;

  color: #d3d3d3;

  border: transparent;
  background: transparent;

  outline: none;

  &:disabled {
    background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`

// 수정하기, 수정완료 button  wrap
const MyInfoFormButtonWrap = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`

// 버튼 안에 텍스트
const MyInfoFormButtomInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-align: center;

  color: #000000;
`

// sign up from button
const MyInfoFormButtom = styled(ButtonH40)`
  display: ${props => (props.visible ? 'block' : 'none')};

  // width: 100%;
  width: 400px;
  height: 40px;
  border-radius: 0;

  // 호버
  &:hover {
    ${MyInfoFormButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MyInfoFormButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MyInfoFormButtomInfo} {
      color: #ffffff4d;
    }
  }
`
