import moment from 'moment'
import { decodeAccessToken, deleteAccessToken, getAccessToken } from '../token'

// 추천 코드 저장 - 헤더에서 사용
export function setRecommendCode(recommendCode) {
  sessionStorage.setItem('RC', recommendCode)
}
export function getRecommendCode() {
  return sessionStorage.getItem('RC')
}
export function deleteRecommendCode() {
  sessionStorage.removeItem('RC')
}

/**
 * 로그인 여부 확인
 * @see : sessiong storage 에 access token 존재 여부로 파악
 * @return : accessToken 이 undefined, '' , 0  이면 false , 존재하면 true
 */
const isLogin = () => {
  const token = decodeAccessToken()

  // 토큰이 없거나, 비워있거나, 발급 시간이 없으면
  if (!token || Object.keys(token).length === 0) {
    return !!getAccessToken()
  }

  // 토큰이 있는데, 발급 날짜 없으면
  if (!token?.iat) {
    deleteAccessToken()
    window.location.reload()
    return false
  }

  const currentTime = moment()

  // 토큰 발급 받은 시간
  const startDate = moment.unix(token.iat)

  const diff = Math.abs(currentTime.diff(startDate, 'seconds'))

  // 1시간 3600 , 6시간 21600
  if (diff > 21600) {
    deleteAccessToken()
    window.location.reload()
    return false
  }
  return !!getAccessToken()
}

export default isLogin
