import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { HermesMainBorder } from '../../../utils/palette'
import KyoChonMobileSportsBettingHistoryV2 from './KyoChonMobileSportsBettingHistoryV2'
import MobileMiniGameBettingHistoryV2 from './MobileMiniGameBettingHistoryV2'
import MobileCasinoBettingHistoryPageV2 from './MobileCasinoBettingHistoryPageV2'
import MobileVirtualGameBettingHistory from '../MobileVirtualGameBettingHistory'

const MobileBettingHistoryPageV2 = () => {
  const location = useLocation()

  const [active, setActive] = useState('sports')
  const getBettingHistoryComponent = () => {
    switch (active) {
      case 'sports':
        return <KyoChonMobileSportsBettingHistoryV2 />
      case 'casino':
        return <MobileCasinoBettingHistoryPageV2 />
      case 'minigame':
        return <MobileMiniGameBettingHistoryV2 />
      case 'virtual-game':
        return <MobileVirtualGameBettingHistory />
      default:
        return <></>
    }
  }

  return (
    <>
      <div style={{ padding: '0px 0 0px 0' }}>
        <div id="hiddenBar" style={{ background: '#fff', boxShadow: '0px 7px 6px -5px #DFE1E5;' }}>
          <div className="header">
            <div style={{ height: '3px' }} />
            <table
              style={{ padding: '0px', border: '0px', borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '36px' }}>
                    <img
                      alt=""
                      style={{ width: '36px', height: '36px' }}
                      src="/m/images/menu_btn.png?v=1"
                      //  onclick="showMenuOn();"
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <a href="/m/Default.aspx">
                      <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                    </a>
                  </td>
                  <td style={{ width: '36px' }}>
                    <img
                      style={{ width: '36px', height: '36px' }}
                      alt=""
                      src="/m/images/icons8-refresh-64.png?v=4"
                      //  onClick="window.location.reload();"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: '2px' }} />
          </div>
        </div>

        <div className="body_title">
          <span className="title1">
            <strong>배팅내역</strong>
          </span>
          <span className="title2">
            <strong>&nbsp;Betting List</strong>
          </span>
        </div>

        <div id="game_wrap">
          <div style={{ height: '10px' }} />
          <div>
            <a
              onClick={() => setActive('sports')}
              style={{
                width: '140px',
                padding: '5px',
                backgroundColor: '#976038',
                fontSize: '14px',
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              스포츠
            </a>
            <a
              onClick={() => setActive('minigame')}
              style={{
                width: '140px',
                padding: '5px',
                backgroundColor: '#976038',
                fontSize: '14px',
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              미니게임
            </a>
            <a
              onClick={() => setActive('casino')}
              style={{
                width: '140px',
                padding: '5px',
                backgroundColor: '#976038',
                fontSize: '14px',
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              카지노
            </a>
            {/* <a
              onClick={() => setActive('virtual-game')}
              style={{
                width: '140px',
                padding: '5px',
                backgroundColor: '#976038',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              가상게임
            </a> */}
          </div>
          <div style={{ height: '30px' }} />
        </div>
        {getBettingHistoryComponent()}
        {/* <div className="pageing_wrap">
    <table align="center" cellpadding="0" cellspacing="5" border="0" width="100%" className="pageing_table">
      <tbody>
        <tr>
          <td width="50%">
            <a href="message.aspx?page=1">◀ 이전10개</a>
            </td>
            <td width="50%">
              <a href="message.aspx?page=1">다음10개 ▶</a></td></tr></tbody></table>
              </div> */}
      </div>
      {/* <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            <div>
              <table className="tb_notice_box">
                <tbody>
                  <tr>
                    <td className="td_notice_td">
                      <b>
                        <span style={{ color: 'rgb(0, 0, 0)', fontSize: '11pt' }}>
                          스포츠 경기 배팅 후 <span style={{ color: ' rgb(255, 0, 0)' }}>10분</span>이 지나거나, 배팅한
                          경기 시작
                          <span style={{ color: ' rgb(255, 0, 0)' }}>30분</span>&nbsp;전에는 배팅 취소가 불가능합니다.
                        </span>
                        <br />
                        <span style={{ color: ' rgb(0, 0, 0)', fontSize: '11pt' }}>
                          라이브,미니게임 실시간으로 진행되는 게임은 배팅 취소가
                          <span style={{ color: 'rgb(255, 0, 0)' }}>절대 불가능</span>합니다.
                        </span>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="height_20" />
            <div>
              <a
                // href="/betting-history"
                onClick={() => setActive('sports')}
                className="normal_button"
                style={
                  active === 'sports'
                    ? { width: '140px', border: 'solid 1px #000', fontWeight: '600' }
                    : { width: '140px' }
                }
              >
                스포츠 배팅
              </a>
              <a
                // href="/minigame"
                onClick={() => setActive('minigame')}
                className="normal_button"
                style={
                  active === 'minigame'
                    ? { width: '140px', border: 'solid 1px #000', fontWeight: '600' }
                    : { width: '140px' }
                }
              >
                미니게임 배팅
              </a>
              <a
                // href="/casino-betting"
                onClick={() => setActive('casino')}
                className="normal_button"
                style={
                  active === 'casino'
                    ? { width: '140px', border: 'solid 1px #000', fontWeight: '600' }
                    : { width: '140px' }
                }
              >
                카지노 배팅
              </a>
            </div>
            <div className="height_10" style={{ clear: 'both' }} />
            <div id="CPH_recommend_list">{getBettingHistoryComponent()}</div>
          </div>
        </div>
      </div> */}
    </>
    // <ContentWrap>
    //   <ContentInnerScrollWrap>
    //     <ContentInnerWrap classNameName="sidebar-container">
    //       <SubMenuItemListWrap>
    //         <div>
    //           <SubMenuItemBox active={active === 'sports'} onClick={() => setActive('sports')}>
    //             스포츠
    //           </SubMenuItemBox>
    //           <SubMenuItemBox active={active === 'casino'} onClick={() => setActive('casino')}>
    //             카지노
    //           </SubMenuItemBox>
    //           {/* <SubMenuItemBox active={active === 'minigame'} onClick={() => setActive('minigame')}>
    //             미니게임
    //           </SubMenuItemBox> */}
    //         </div>
    //       </SubMenuItemListWrap>

    //       <ContentBox>{getBettingHistoryComponent()}</ContentBox>
    //     </ContentInnerWrap>
    //   </ContentInnerScrollWrap>
    // </ContentWrap>
  )
}

export default MobileBettingHistoryPageV2

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;
  font-family: 'Noto Sans', sans-serif;
  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  height: 650px;
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuItemBox = styled.div`
  padding: 16px 50px;
  float: left;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    border-bottom: 1px solid ${HermesMainBorder};
  }

  // active 일때
  ${props =>
    props.active &&
    `
    border-bottom: 1px solid ${HermesMainBorder};
    `}
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`
