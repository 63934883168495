import {
  CustomCenterFormBottomWrap,
  MobileAttendanceHeaderWrap,
  MobileTopWrap,
} from '@/shared/components/MoneyCustomElement'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  searchMemberAttendanceHistory,
  searchMemberYesterdayAttendance,
} from '../../api/memberHistory/memberHistoryApi'
import { createUserAttendance } from '../../api/memberManage/memberManageApi'
import CustomLoading from '../../containers/CustomLoading'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import SilverButtonH40 from '../../shared/components/SilverButton'
import { MoneyFormWrap } from '../../shared/components/form/newForm'
import { HermesMainBorder, HermesMainText, colorMainText } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'
import MobileAttendanceListData from './MobileAttendanceListData'

const MobileAttendanceForm = () => {
  const [myInfoLoading] = useState(true)
  const [assetLoading, setAssetLoading] = useState(true)
  const [loading, setLoading] = useState(true)

  /**
   *  회원 어제 출석연속 번호 조회
   */
  const [consecutiveNum, setConsecutiveNum] = useState(0)
  const [minimumAttendanceAmount, setMinimumAttendanceAmount] = useState(0)
  const [thirdAttendanceBonus, setThirdAttendanceBonus] = useState(0)
  const [sixthAttendanceBonus, setSixthAttendanceBonus] = useState(0)

  useEffect(() => {
    setLoading(true)
    searchMemberYesterdayAttendance()
      .then(res => {
        setConsecutiveNum(res.data.consecutiveNum)
        setMinimumAttendanceAmount(res.data.minimumAttendanceAmount)
        setThirdAttendanceBonus(res.data.thirdAttendanceBonus)
        setSixthAttendanceBonus(res.data.sixthAttendanceBonus)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const fetchSearchMemberConsecutiveNum = async () => {
    await searchMemberYesterdayAttendance()
      .then(res => {
        setConsecutiveNum(res.data.consecutiveNum)
      })
      .catch(error => {})
  }

  /**
   *  회원 출석내역 조회
   */

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberAttendanceHistory(historyParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [historyParams])

  const fetchSearchMemberAttendance = async () => {
    setLoading(true)
    await searchMemberAttendanceHistory(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [historyListData, setHistoryListData] = useState(MobileAttendanceListData(content))

  useEffect(() => {
    setHistoryListData(MobileAttendanceListData(content))
  }, [content])

  const [rows, setData] = useState(historyListData.tableRowsData)

  useEffect(() => {
    setData(historyListData.tableRowsData)
  }, [historyListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  // redux 사용
  const dispatch = useDispatch()

  const onSubmit = e => {
    console.log('ddd')
    if (apiFlag) return
    setApiFlag(true)
    const body = {}

    createUserAttendance(body)
      .then(res => {
        if (consecutiveNum === 2) {
          alert(`${thirdAttendanceBonus?.toString().replace(commonReg2, ',')} 포인트 지급이 완료됐습니다.`)
        } else if (consecutiveNum === 5) {
          alert(`${sixthAttendanceBonus?.toString().replace(commonReg2, ',')} 포인트 지급이 완료됐습니다.`)
        } else {
          alert('출석이 완료됐습니다.')
        }
        dispatch(fetchMemberInfo())
        fetchSearchMemberConsecutiveNum()
        fetchSearchMemberAttendance()
        setApiFlag(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ATTD-1001':
            alert(`${message}`)
            break
          case 'ATTD-1002':
            alert(`${message}`)
            break
          case 'EVENT-1002':
            alert(`${message}`)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('출석이 실패했습니다. 다시 시도해주세요.')
        }
        setApiFlag(false)
      })
  }

  return (
    <>
      <div style={{ padding: '0px 0 0px 0' }}>
        <div
          id="hiddenBar"
          style={{
            background: 'rgb(255, 255, 255)',
            boxShadow: 'rgb(223, 225, 229) 0px 7px 6px -5px',
            display: 'none',
          }}
        >
          <div className="header">
            <div style={{ height: '3px' }} />
            <table
              style={{ padding: '0px', border: '0px', borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '36px' }}>
                    <img
                      alt=""
                      style={{ height: '36px', width: '36px' }}
                      src="/m/images/menu_btn.png?v=1"
                      // onClick="showMenuOn()"
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <a
                    //  href="/m/Default.aspx"
                    >
                      <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                    </a>
                  </td>
                  <td style={{ width: '36px' }}>
                    <img
                      style={{ width: '36px', height: '36px' }}
                      alt=""
                      src="/m/images/icons8-refresh-64.png?v=4"
                      //  onclick="window.location.reload()"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: '2px' }} />
          </div>
        </div>

        <div className="height_5" />
        <div className="div_body">
          <div className="height_10" />
          <div className="content">
            <div style={{ height: '20px' }} />
            <div>
              <table cellPadding="0" cellSpacing="0" style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: '#9aeaf1' }}>
                      <div style={{ height: '15px' }} />
                      <div style={{ textAlign: 'center' }}>
                        <img
                          style={{ width: '350px' }}
                          alt=""
                          src={`${process.env.PUBLIC_URL}/img/main/dc_title.png`}
                        />
                      </div>

                      <div style={{ height: '20px' }} />
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        className="tb_solid_table"
                        style={{ width: '320px', fontWeight: 'bold', backgroundColor: '#9aeaf1', textAlign: 'center' }}
                        align="center"
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: 'white' }}
                            >
                              1일차
                            </td>
                            <td
                              style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: 'white' }}
                            >
                              2일차
                            </td>
                            <td
                              style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: 'white' }}
                            >
                              3일차
                            </td>
                            <td
                              style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: 'white' }}
                            >
                              4일차
                            </td>
                            <td
                              style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: 'white' }}
                            >
                              5일차
                            </td>
                            <td
                              style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: 'white' }}
                            >
                              6일차
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                width: '50px',
                                height: '50px',
                                verticalAlign: 'top',
                                textAlign: 'left',
                                backgroundImage:
                                  consecutiveNum > 0
                                    ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                    : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                                backgroundSize: '50px 50px',
                              }}
                            >
                              <div
                                style={{ textAlign: 'center', height: '50px', lineHeight: '60px', color: '#ff0000' }}
                              >
                                &nbsp;
                              </div>
                            </td>

                            <td
                              style={{
                                width: '50px',
                                height: '50px',
                                verticalAlign: 'top',
                                textAlign: 'left',
                                backgroundImage:
                                  consecutiveNum > 1
                                    ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                    : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                                backgroundSize: '50px 50px',
                              }}
                            >
                              <div
                                style={{ textAlign: 'center', height: '50px', lineHeight: '60px', color: '#ff0000' }}
                              >
                                &nbsp;
                              </div>
                            </td>

                            <td
                              style={{
                                width: '50px',
                                height: '50px',
                                verticalAlign: 'top',
                                textAlign: 'left',
                                backgroundImage:
                                  consecutiveNum > 2
                                    ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                    : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                                backgroundSize: '50px 50px',
                              }}
                            >
                              <div
                                style={{ textAlign: 'center', height: '50px', lineHeight: '60px', color: '#ff0000' }}
                              >
                                {thirdAttendanceBonus?.toString().replace(commonReg2, ',')}P
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                height: '50px',
                                verticalAlign: 'top',
                                textAlign: 'left',
                                backgroundImage:
                                  consecutiveNum > 3
                                    ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                    : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                                backgroundSize: '50px 50px',
                              }}
                            >
                              <div
                                style={{ textAlign: 'center', height: '50px', lineHeight: '60px', color: '#ff0000' }}
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                height: '50px',
                                verticalAlign: 'top',
                                textAlign: 'left',
                                backgroundImage:
                                  consecutiveNum > 4
                                    ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                    : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                                backgroundSize: '50px 50px',
                              }}
                            >
                              <div
                                style={{ textAlign: 'center', height: '50px', lineHeight: '60px', color: '#ff0000' }}
                              >
                                &nbsp;
                              </div>
                            </td>
                            <td
                              style={{
                                width: '50px',
                                height: '50px',
                                verticalAlign: 'top',
                                textAlign: 'left',
                                backgroundImage:
                                  consecutiveNum > 5
                                    ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                    : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                                backgroundSize: '50px 50px',
                              }}
                            >
                              <div
                                style={{ textAlign: 'center', height: '50px', lineHeight: '60px', color: '#ff0000' }}
                              >
                                {sixthAttendanceBonus?.toString().replace(commonReg2, ',')}P
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div style={{ height: '10px' }} />
                      <div style={{ textAlign: 'center', color: '#3097a0' }}>
                        5만원 이상 배팅 확인 기준은 배팅 10분 후 를 기준으로함.
                      </div>
                      <div style={{ height: '20px' }} />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '300px', backgroundColor: '#caf3f9', verticalAlign: 'top' }}>
                      <div style={{ height: '20px' }} />
                      <div style={{ textAlign: 'center' }}>
                        <a
                        // href="javascript:check_evnet(),"
                        >
                          <img
                            alt=""
                            src={`${process.env.PUBLIC_URL}/img/main/btGoCheck.png`}
                            // src="/skin/hp0424/pc/img/btGoCheck.png"
                          />
                        </a>
                      </div>
                      <div style={{ height: '30px' }} />
                      <div>
                        <div
                          style={{
                            width: '210px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            backgroundColor: 'white',
                            border: '2px solid #aaaaaa',
                            borderRadius: '5px',
                            textAlign: 'center',
                          }}
                        >
                          <div style={{ height: '15px' }} />
                          <div style={{ fontSize: '20px', height: '30px', lineHeight: '30px' }}>
                            <b>
                              연속 출석 : <font color="red">{consecutiveNum}</font>일
                            </b>
                          </div>
                          <div style={{ height: '10px' }} />
                          {/* <div>
                            오늘 포인트 : <font color="red">500</font>포인트
                          </div> */}
                          <div style={{ height: '10px' }} />
                          <div>
                            이벤트 참여 : <font color="blue">미참여</font>
                          </div>
                          <div style={{ height: '15px' }} />
                        </div>
                      </div>
                      <div style={{ height: '25px' }} />
                      <div>
                        <div
                          style={{
                            width: '214px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            backgroundColor: '#77c2c8',
                            height: '1px',
                          }}
                        />
                      </div>
                      <div style={{ height: '20px' }} />
                      <div style={{ textAlign: 'center' }}>
                        <img alt="" src="/skin/hp0424/pc/img/check_detail.jpg" />
                      </div>
                      <div style={{ height: '10px' }} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
    // <MobileTopWrap>
    //   <MobileAttendanceHeaderWrap>
    //     <MoneyDepositHeaderInfo>출석하기</MoneyDepositHeaderInfo>
    //     <MoneyDepositCardSubhead>
    //       * 당일 1회에 {minimumAttendanceAmount?.toString().replace(commonReg2, ',')}원 이상 입금내역이 있어야
    //       출석체크가 가능합니다.
    //       <br />
    //       &nbsp,&nbsp,(2만원 입금 후, 추가 3만원 시 불가)
    //     </MoneyDepositCardSubhead>
    //   </MobileAttendanceHeaderWrap>
    //   {loading ? (
    //     <CustomLoading />
    //   ) : (
    //     <MoneyDepositBodyWrap>
    //       <MoneyDepositInfoWrap style={{ gap: '10px' }}>
    //         <AttendanceWrap>
    //           {consecutiveNum > 0 ? (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
    //               <AttendanceInfo style={{ color: `${HermesMainText}` }}>출석 1일차</AttendanceInfo>
    //             </>
    //           ) : (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
    //               <AttendanceInfo>출석 1일차</AttendanceInfo>
    //             </>
    //           )}
    //         </AttendanceWrap>
    //         <AttendanceWrap>
    //           {consecutiveNum > 1 ? (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
    //               <AttendanceInfo style={{ color: `${HermesMainText}` }}>출석 2일차</AttendanceInfo>
    //             </>
    //           ) : (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
    //               <AttendanceInfo>연속 2일차</AttendanceInfo>
    //             </>
    //           )}
    //         </AttendanceWrap>
    //         <AttendanceWrap>
    //           {consecutiveNum > 2 ? (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
    //               <AttendanceInfo style={{ color: `${HermesMainText}` }}>
    //                 {thirdAttendanceBonus?.toString().replace(commonReg2, ',')}포인트
    //               </AttendanceInfo>
    //             </>
    //           ) : (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
    //               <AttendanceInfo>{thirdAttendanceBonus?.toString().replace(commonReg2, ',')}포인트</AttendanceInfo>
    //             </>
    //           )}
    //         </AttendanceWrap>
    //       </MoneyDepositInfoWrap>
    //       <MoneyDepositInfoWrap style={{ gap: '10px' }}>
    //         <AttendanceWrap>
    //           {consecutiveNum > 3 ? (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
    //               <AttendanceInfo style={{ color: `${HermesMainText}` }}>연속 4일차</AttendanceInfo>
    //             </>
    //           ) : (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
    //               <AttendanceInfo>연속 4일차</AttendanceInfo>
    //             </>
    //           )}
    //         </AttendanceWrap>
    //         <AttendanceWrap>
    //           {consecutiveNum > 4 ? (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
    //               <AttendanceInfo style={{ color: `${HermesMainText}` }}>연속 5일차</AttendanceInfo>
    //             </>
    //           ) : (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
    //               <AttendanceInfo>연속 5일차</AttendanceInfo>
    //             </>
    //           )}
    //         </AttendanceWrap>
    //         <AttendanceWrap>
    //           {consecutiveNum > 5 ? (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
    //               <AttendanceInfo style={{ color: `${HermesMainText}` }}>
    //                 {sixthAttendanceBonus?.toString().replace(commonReg2, ',')}포인트
    //               </AttendanceInfo>
    //             </>
    //           ) : (
    //             <>
    //               <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
    //               <AttendanceInfo>{sixthAttendanceBonus?.toString().replace(commonReg2, ',')}포인트</AttendanceInfo>
    //             </>
    //           )}
    //         </AttendanceWrap>
    //       </MoneyDepositInfoWrap>
    //       <Form
    //         onSubmit={onSubmit}
    //         initialValues={
    //           {
    //             // inquiryButton,
    //           }
    //         }
    //       >
    //         {({ handleSubmit, form: { reset } }) => (
    //           <CustomCenterFormBottomWrap onSubmit={handleSubmit}>
    //             <AttendanceBtn type="submit" onClick={handleSubmit}>
    //               <AttendanceBtnInfo>출석하기</AttendanceBtnInfo>
    //             </AttendanceBtn>
    //           </CustomCenterFormBottomWrap>
    //         )}
    //       </Form>
    //     </MoneyDepositBodyWrap>
    //   )}
    //   <MoneyDepositHeaderThirdInfo>&lt,&nbsp,출석 내역&nbsp,&gt,</MoneyDepositHeaderThirdInfo>
    //   <ReactTableBase columns={historyListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
    // </MobileTopWrap>
  )
}

export default MobileAttendanceForm
