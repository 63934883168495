import React, { useState } from 'react'
// import PartnerManageMentTabs from '../../PartnerManageMentTabs'

const MobileBoscoreTabs = ({ activeMenu }) => {
  return (
    <div className="mobile_mini_content">
      <div className="tab_wrap">
        <ul className="sub_tap">
          <li
            className={activeMenu === 'BOSCORE_LADDER_1MIN' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/boscore/stat1m'
            }}
          >
            <a>1분 별다리</a>
          </li>
          <li
            className={activeMenu === 'BOSCORE_LADDER_2MIN' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/boscore/stat2m'
            }}
          >
            <a>2분 별다리</a>
          </li>
          <li
            className={activeMenu === 'BOSCORE_LADDER_3MIN' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/boscore/stat3m'
            }}
          >
            <a>3분 별다리</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MobileBoscoreTabs
