import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import parse from 'style-to-object'
import styled from 'styled-components'
import Badge from '../../../shared/components/Badge'
import { CustomButtonInfo } from '../../../shared/components/MobileMoneyCustomElement'
import { MobileListButtonWrap } from '../../../shared/components/MoneyCustomElement'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { colorMainText } from '../../../utils/palette'

const MobileNoteDetail = ({ notice, onBack }) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 맨 위로 이동
    window.scrollTo(0, 0)
  }, [])
  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }
  return (
    <>
      <div style={{ padding: '0px 0 0px 0' }}>
        <div id="hiddenBar" style={{ background: '#fff', boxShadow: '0px 7px 6px -5px #DFE1E5;' }}>
          <div className="header">
            <div style={{ height: '3px' }} />
            <table
              style={{ padding: '0px', border: '0px', borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '36px' }}>
                    <img
                      alt=""
                      style={{ width: '36px', height: '36px' }}
                      src="/m/images/menu_btn.png?v=1"
                      //  onclick="showMenuOn();"
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <a href="/m/Default.aspx">
                      <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                    </a>
                  </td>
                  <td style={{ width: '36px' }}>
                    <img
                      style={{ width: '36px', height: '36px' }}
                      alt=""
                      src="/m/images/icons8-refresh-64.png?v=4"
                      //  onClick="window.location.reload();"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: '2px' }} />
          </div>
        </div>

        <div className="body_title">
          <span className="title1">
            <strong>쪽지함</strong>
          </span>
          <span className="title2">
            <strong>&nbsp;Message</strong>
          </span>
        </div>
        <div style={{ height: '20px' }} />
        <div className="board_wrap">
          <div className="mome_contents_wrap" style={{ wordBreak: 'break-all' }}>
            <span style={{ color: '#000', fontSize: '12px', fontFamily: '돋움' }}>
              {convertToKstByFormat(notice.sendDate)}
            </span>
            <br />
            <div className="board_subject">
              <b style={{ color: 'rgb(0,0,0)', fontWeight: '900' }}>★Zeus★&nbsp;</b>
              <span style={{ color: 'rgb(0,0,0)', fontWeight: '500' }}>{notice.noteTitle}</span>
            </div>
            <br />
            <div className="board_body">{ReactHtmlParser(notice.sendContent, { transform: transformFn })}</div>
            <div style={{ height: '20px' }} />
            <div className="pageing_wrap">
              <a onClick={onBack}>
                <font className="bt_type1" style={{ padding: '7px', width: '90%' }}>
                  <b>목록으로</b>
                </font>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileNoteDetail

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  color: #fff;
  img {
    width: 100px;
    height: auto;
  }
`

export const CustomButtonWrap = styled(MobileListButtonWrap)`
  padding: 0px 10px;
`

export const CustomButtonInfos = styled(CustomButtonInfo)``

export const CustomButtonInfos2 = styled(CustomButtonInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  padding: 0px 8px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    color: grey;
  }
`

export const MobileOneToOneHeaderWrap = styled.div`
  padding: 10px 0 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
`

export const MobileOnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 13px;
  opacity: 0.7;
  color: ${colorMainText};
`

export const MobileBadge = styled(Badge)`
  width: 50px;
  height: 20px;
  padding-top: 5px;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    padding: 16px 0px !important;
    background: rgba(9, 9, 10);
    borde: none;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;

  margin-top: 12px;
  border-radius: 6px;

  &:hover {
    border: solid 1px #757575;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
  font-weight: 400;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  padding: 16px 5px;
`
