import React from 'react'
import Pagination from 'react-bootstrap/Pagination'
import styled from 'styled-components'
import { HermesMainText } from '../../utils/palette'

const MobileCustomPagination = ({ currentPage, size, totalPages, onPageChange }) => {
  const handlePageChange = pageNumber => {
    if (pageNumber === currentPage) return
    onPageChange(pageNumber)
  }
  const pageSize = 1 // 페이지 그룹의 크기

  // 현재 페이지 그룹의 시작 페이지와 끝 페이지 계산
  const startPage = Math.floor(currentPage / pageSize) * pageSize
  const endPage = Math.min(startPage + pageSize - 1, totalPages - 1)
  return (
    <div className="pageing_wrap">
      <table align="center" cellPadding="0" cellSpacing="5" border="0" width="100%" className="pageing_table">
        <tbody>
          <tr>
            <td width="50%">
              <a
                onClick={() => {
                  if (currentPage === 0) return
                  handlePageChange(currentPage - 1)
                }}
                //  href="charge.aspx?page=1"
              >
                ◀ 이전{size}개
              </a>
            </td>
            <td width="50%">
              <a
                onClick={() => {
                  if (currentPage === totalPages - 1) return
                  handlePageChange(currentPage + 1)
                }}
                // href="charge.aspx?page=1"
              >
                다음{size}개 ▶
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    // <PaginationWrap>
    //   <Pagination>
    //     <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
    //     <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
    //     {[...Array(endPage - startPage + 1).keys()].map(pageIndex => {
    //       const pageNumber = startPage + pageIndex
    //       return (
    //         <Pagination.Item
    //           key={pageNumber}
    //           active={currentPage === pageNumber}
    //           onClick={() => handlePageChange(pageNumber)}
    //         >
    //           {pageNumber + 1}
    //         </Pagination.Item>
    //       )
    //     })}
    //     <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
    //     <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
    //   </Pagination>
    // </PaginationWrap>
  )
}

export default MobileCustomPagination

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  // 가운데로 이동
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  .page-item {
    margin-right: 2px;

    .page-link {
      background: #3a3a3a;
      border: none;

      // 페이지 번호 색상
      color: #fffef2;

      height: 33px;
      font-size: ${props => (props.isMobile ? '12px' : '12px')};
      text-align: center;
      min-width: ${props => (props.isMobile ? '23px' : '33px')};
      transition: all 0.3s;

      &:hover {
        // 페이지 번호 Hover 색상
        background: ${HermesMainText};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(180deg, #2d353c, #222);
      }

      &:focus,
      &:active {
        box-shadow: none;
      }

      svg {
        width: 18px;
        height: 18px;
        // 화살표 아이콘 색상
        fill: black;
        transition: 0.3s;
        position: absolute;

        top: ${props => (props.isMobile ? '4px' : '7px')};
        left: ${props => (props.isMobile ? '5px' : '8px')};
      }
    }

    &.pagination__item--arrow .page-link {
      border-radius: 50%;
      width: ${props => (props.isMobile ? '25px' : '33px')};
      height: ${props => (props.isMobile ? '25px' : '33px')};

      // 화살표 아이콘 배경 색
      background: #f2f4f7;

      &:hover {
        // hover 화살표 아이콘 배경 색
        background: #cdcdcd;

        svg {
          // hover 화살표 아이콘  색
          fill: black;
        }
      }
    }

    &.pagination__item--arrow.disabled .page-link {
      cursor: default;
      opacity: 0.4;
      // disabled 화살표 아이콘 배경 색
      background: #f2f4f7;

      svg {
        // disabled 화살표 아이콘 색
        fill: #999999;
      }
    }

    &.active .page-link {
      background-color: transparent;
      border: 1px solid #2d353c;
      font-weight: bold;

      // active 페이지 번호 색상
      background: ${HermesMainText};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.disabled .page-link svg {
      // disabled 페이지 번호 색상
      fill: #dddddd;
    }
  }
`
