import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { HermesMainBorder } from '../../utils/palette'
import CasinoBettingHistoryPageV2 from './CasinoBettingHistory/CasinoBettingHistoryPageV2'
import MiniGameBettingHistoryV2 from './MiniGameBettingHistoryV2'
import SportsBettingHistoryV2 from './SportsBettingHistoryV2'
import VirtualGameBettingHistory from './VirtualGameBettingHistory'

const BettingHistoryPageV2 = () => {
  const location = useLocation()

  const [active, setActive] = useState('sports')
  const getBettingHistoryComponent = () => {
    switch (active) {
      case 'sports':
        return <SportsBettingHistoryV2 />
      case 'casino':
        return <CasinoBettingHistoryPageV2 />
      case 'minigame':
        return <MiniGameBettingHistoryV2 />
      case 'virtualgame':
        return <VirtualGameBettingHistory />
      default:
        return <></>
    }
  }

  return (
    <>
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            <div>
              <table className="tb_notice_box">
                <tbody>
                  <tr>
                    <td className="td_notice_td">
                      <b>
                        <span style={{ color: 'rgb(0, 0, 0)', fontSize: '11pt' }}>
                          스포츠 경기 배팅 후 <span style={{ color: ' rgb(255, 0, 0)' }}>20분</span>이 지나거나, 배팅한
                          경기 시작&nbsp;
                          <span style={{ color: ' rgb(255, 0, 0)' }}>10분</span>&nbsp;전에는 배팅 취소가 불가능합니다.
                        </span>
                        <br />
                        <span style={{ color: ' rgb(0, 0, 0)', fontSize: '11pt' }}>
                          인플레이, 미니게임, 카지노, 슬롯 등 실시간으로 진행되는 게임은 배팅취소가&nbsp;
                          <span style={{ color: 'rgb(255, 0, 0)' }}>절대 불가능</span>합니다.
                        </span>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="height_20" />
            <div>
              <a
                // href="/betting-history"
                onClick={() => setActive('sports')}
                className="normal_button"
                style={
                  active === 'sports'
                    ? { width: '140px', border: 'solid 1px #000', fontWeight: '600' }
                    : { width: '140px' }
                }
              >
                스포츠 배팅
              </a>
              <a
                // href="/minigame"
                onClick={() => setActive('minigame')}
                className="normal_button"
                style={
                  active === 'minigame'
                    ? { width: '140px', border: 'solid 1px #000', fontWeight: '600' }
                    : { width: '140px' }
                }
              >
                미니게임 배팅
              </a>
              <a
                // href="/casino-betting"
                onClick={() => setActive('casino')}
                className="normal_button"
                style={
                  active === 'casino'
                    ? { width: '140px', border: 'solid 1px #000', fontWeight: '600' }
                    : { width: '140px' }
                }
              >
                카지노 배팅
              </a>
              {/* <a
                // href="/betting-history"
                onClick={() => setActive('virtualgame')}
                className="normal_button"
                style={
                  active === 'virtualgame'
                    ? { width: '140px', border: 'solid 1px #000', fontWeight: '600' }
                    : { width: '140px' }
                }
              >
                가상스포츠 배팅
              </a> */}
            </div>
            <div className="height_10" style={{ clear: 'both' }} />
            <div id="CPH_recommend_list">{getBettingHistoryComponent()}</div>
          </div>
        </div>
      </div>
    </>
    // <ContentWrap>
    //   <ContentInnerScrollWrap>
    //     <ContentInnerWrap classNameName="sidebar-container">
    //       <SubMenuItemListWrap>
    //         <div>
    //           <SubMenuItemBox active={active === 'sports'} onClick={() => setActive('sports')}>
    //             스포츠
    //           </SubMenuItemBox>
    //           <SubMenuItemBox active={active === 'casino'} onClick={() => setActive('casino')}>
    //             카지노
    //           </SubMenuItemBox>
    //           {/* <SubMenuItemBox active={active === 'minigame'} onClick={() => setActive('minigame')}>
    //             미니게임
    //           </SubMenuItemBox> */}
    //         </div>
    //       </SubMenuItemListWrap>

    //       <ContentBox>{getBettingHistoryComponent()}</ContentBox>
    //     </ContentInnerWrap>
    //   </ContentInnerScrollWrap>
    // </ContentWrap>
  )
}

export default BettingHistoryPageV2

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;
  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  height: 650px;
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuItemBox = styled.div`
  padding: 16px 50px;
  float: left;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    border-bottom: 1px solid ${HermesMainBorder};
  }

  // active 일때
  ${props =>
    props.active &&
    `
    border-bottom: 1px solid ${HermesMainBorder};
    `}
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`
