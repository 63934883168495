import axios from '../base/axios'

/**
 * 은행 리스트 조회
 */
export async function searchBankList() {
  const response = await axios({
    url: '/tyson/v1/bank',
    method: 'GET',
  }).then(res => {
    return res
  })

  return response
}
