import React from 'react'
import { convertToKstByFormat } from '../../utils/dateTime'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MobileAttendanceListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 25,
    },
    {
      Header: '출석일',
      accessor: 'attendanceDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstByFormat(original.attendanceDate, 'MM-DD HH:mm')
      },
    },
    {
      Header: '비고',
      accessor: 'attendanceMemo',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default MobileAttendanceListData
