import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { searchSportsFixtureBalanceList } from '../../../api/game/sportsGameApi'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import CustomLoading from '../../CustomLoading'
import { selectSideSportMenu } from '../../../redux/sportsDomesticInfoSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import SportsBalanceContent from './SportsBalanceContent'
import SportsBettingCartSidebar from '../../Layout/SportsBettingCartSidebar'

const SportsBalancePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { domesticLoading, bettingCartLoading } = useSelector(state => {
    const { sportsDomesticInfo, sportsBetting } = state

    return {
      domesticLoading: sportsDomesticInfo.domesticLoading,
      bettingCartLoading: sportsBetting.bettingCartLoading,
    }
  })

  return (
    <>
      {(domesticLoading || bettingCartLoading) && <CustomLoading />}
      <div className="div_content" style={{ position: 'relative' }}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '963px', height: '0px' }} />
              <td style={{ width: '7px', height: '0px' }} />
              <td style={{ width: '230px', height: '0px' }} />
            </tr>
            <tr>
              {/* 메인 화면 */}
              <td style={{ width: '963px', verticalAlign: 'top' }}>
                <div className="div_body">
                  <div className="content" style={{ width: '943px' }}>
                    <div>
                      <table className="tb_notice_box">
                        <tbody>
                          <tr>
                            <td className="td_notice_td">
                              <span style={{ fontSize: '10pt' }}>
                                <b>
                                  <span style={{ color: 'rgb(0, 0, 0)' }}>
                                    이용관련 규정은 게시판에 안내되어 있으니,숙지 후 이용 바랍니다.
                                  </span>
                                </b>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <SportsBalanceContent />
                  </div>
                </div>
              </td>

              {/* 공백 */}
              <td style={{ width: '7px' }} />
              {/* 배팅카트 */}
              <td style={{ verticalAlign: 'top', width: '230px' }}>
                <SportsBettingCartSidebar />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SportsBalancePage
