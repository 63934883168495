import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const OnetoOneRadio = ({ rowInfo, checkRadio, setCheckRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        if (rowInfo.answerStatus === 'INCOMPLETE') {
          alert('미완료 건은 삭제하실 수 없습니다.')
          break
        }
        setCheckRadio(prev => [...prev, rowInfo.oneToOneKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== rowInfo.oneToOneKey))
        break
      default:
        break
    }
  }
  return (
    <div style={{ cursor: 'pointer', marginLeft: '-5px' }}>
      <CustomCheckInput
        style={{ width: '100%', cursor: 'pointer', minWidth: '13px' }}
        checked={rowInfo.answerStatus === 'INCOMPLETE' ? false : checkRadio.find(e => e === rowInfo.oneToOneKey)}
        type="checkbox"
        name="chech"
        onChange={e => {
          approveHandler(e)
        }}
      />
    </div>
  )
}
export default OnetoOneRadio

OnetoOneRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  rowInfo: PropTypes.shape({
    oneToOneKey: PropTypes.string,
    answerStatus: PropTypes.string,
  }).isRequired,
}

OnetoOneRadio.defaultProps = {
  checkRadio: [],
}

const CustomCheckInput = styled.input`
  accent-color: ${props => (props.checked ? '#000' : 'initial')};
`
