// import 'bootstrap/dist/css/bootstrap.min.css'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { fetchClientInspectionPolling } from '../api/poling/pollingApi'
import CustomAlert from '../containers/CustomAlert'
import store from '../redux/store'
import usePollingHook from '../utils/pollingHook'
import { socket } from '../utils/socket'
import MobileRouter from './MobileRouter'
import Router from './Router'

const App = () => {
  // 우클릭방지
  useEffect(() => {
    document.oncontextmenu = function () {
      return false
    }
  }, [])

  // 웹소켓으로 계속 받아서 바꿔야 함
  const isInspectionPopupShow = false
  const [edgeSize, setEdgeSize] = useState(0)

  const [pollingFlag, setPollingFlag] = useState(false)
  // 점검 시작일
  const [startDate, setStartDate] = useState('')

  // 점검 종료일
  const [endDate, setEndDate] = useState('')
  usePollingHook(
    async () => {
      await fetchClientInspectionPolling().then(res => {
        setEdgeSize(res.data.edgeSize)
        setStartDate(res.data.inspectionStartDate)
        setEndDate(res.data.inspectionEndDate)
      })
    },
    [],
    {},
  )

  // 웹소켓 연결되었을 때
  const onSocketIOConnect = () => {
    console.log('connect')
  }

  // 웹소켓 연결이 끊어졌을 때
  const onSocketIODisconnect = () => {
    console.log('disconnect')
  }

  // 웹소켓 연결 코드
  useEffect(() => {
    socket.connect()

    socket.on('connect', onSocketIOConnect)
    socket.on('disconnect', onSocketIODisconnect)

    return () => {
      socket.off('connect', onSocketIOConnect)
      socket.off('disconnect', onSocketIODisconnect)

      socket.disconnect()
    }
  }, [])

  const [stylesLoaded, setStylesLoaded] = useState(false)

  const loadStyles = () => {
    if (isMobile) {
      Promise.all([
        import('../cssmobile/mobilelogin.css'),
        import('../cssmobile/mobilecommon.css'),
        import('../cssmobile/mobile_sports_balance.css'),
        import('../cssmobile/myPageBetting.css'),
        import('../cssmobile/mobileeos.css'),
        import('../cssmobile/mobilejoin.css'),
        import('../cssmobile/mobilegame.css'),
      ]).then(() => setStylesLoaded(true))
      // 모바일 스타일 로드
    } else {
      Promise.all([
        import('../css/common.css'),
        import('../css/common2.css'),
        import('../css/sports_balance.css'),
        import('../css/game.css'),
        import('../css/login.css'),
        import('../css/eos.css'),
        import('../css/join.css'),
      ]).then(() => setStylesLoaded(true))
    }
  }

  useEffect(() => {
    loadStyles()
  }, [])

  if (!stylesLoaded) {
    return (
      <div
        style={{
          background: '#fff',
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <img style={{ width: '150px' }} alt="" src={`${process.env.PUBLIC_URL}/img/logo/logo.png`} />
      </div>
    )
  }

  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          {/* <GlobalStyles /> */}
          {edgeSize !== 0 && (
            <CustomAlert
              info={`점검 일정 : ${moment(new Date(startDate)).format('MM월 DD일 HH:mm')} ~
              ${moment(new Date(endDate)).format('MM월 DD일 HH:mm')}`}
              isInspection
            />
          )}

          <BrowserView>
            <Router />
          </BrowserView>
          <MobileView>
            <MobileRouter />
          </MobileView>
        </Provider>
      </BrowserRouter>
    </>
  )
}

export default App
