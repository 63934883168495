import { useEffect, useRef, useState } from 'react'

/**
 * @see : https://pgarciacamou.medium.com/react-simple-polling-custom-hook-usepollingeffect-1e9b6b8c9c71
 */

function usePollingHook(
  asyncCallback,
  dependencies = [],
  {
    // interval = 60000, // 60 초 - 1분
    interval = 50000, // 60 초 - 1분
    onCleanUp = () => {},
  } = {},
) {
  const timeoutIdRef = useRef(null)

  useEffect(() => {
    let stopped = false

    ;(async function pollingCallback() {
      try {
        await asyncCallback()
      } finally {
        timeoutIdRef.current = !stopped && setTimeout(pollingCallback, interval)
      }
    })()

    // Clean up
    return () => {
      stopped = true
      clearTimeout(timeoutIdRef.current)
      onCleanUp()
    }
  }, [...dependencies, interval])
}

export default usePollingHook
