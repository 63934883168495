export const MemberStatusEnums = {
  NORMAL: '정상',
  WAITING: '대기',
  BLOCK: '블록',
  DELETE: '삭제',
  CANCEL: '취소',
}

export const MemberStatusEnumsSelectOptions = () => {
  return Object.entries(MemberStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberDepositLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
}

export const MemberCasinoLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
}
export const MemberMiniGameLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
}
export const MemberSportsGameLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
}

export const MemberCasinoLevelEnumSelectOptions = () => {
  return Object.entries(MemberCasinoLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
