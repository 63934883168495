import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { KyochonMainBorder } from '../../../utils/palette'

import { addBettingCartItem } from '../../../redux/virtualGameBettingSlice'
import {
  VirtualGameBettingType,
  VirtualGameBettingTypeKr,
  VirtualGameLeagueTypeKr,
  VirtualResultSettlementTypeEnums,
} from '../../../utils/enums/VirtualGame/VirtualGameEnums'
import { formatSeconds } from '../../MiniGame/miniGameTimeUtils'

const getColor = settlementType => {
  switch (true) {
    case settlementType === 'WINNER':
      return 'blue'
    case settlementType === 'LOSER':
      return 'red'
    case settlementType === 'REFUND':
      return 'grey'
    case settlementType === 'NOT_SETTLED':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'black'
    default:
      return 'white'
  }
}

const VirtualBaseballContentBotom = () => {
  const dispatch = useDispatch()

  const [leagueList, setLeagueList] = useState([])
  const [newVirtualFixtures, setNewVirtualFixtures] = useState([])

  const { virtualFixtures, virtualMarketInfos, allLeagueList, virtualResultHistory, virtualBetLogs } = useSelector(
    state => {
      const { virtualGameInfo } = state

      return {
        virtualFixtures: virtualGameInfo.virtualFixtures,
        virtualMarketInfos: virtualGameInfo.virtualMarketInfos,
        allLeagueList: virtualGameInfo.virtualSportList,
        virtualResultHistory: virtualGameInfo.virtualResultHistory,
        virtualBetLogs: virtualGameInfo.virtualBetLogs,
      }
    },
  )

  const { bettingCartList, sportType } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      bettingCartList: virtualGameBetting.bettingCartList,
      sportType: virtualGameBetting.selectedVirtuaGameSportType,
    }
  })

  useEffect(() => {
    if (sportType !== 'VIRTUAL_BASEBALL') return

    setLeagueList(
      allLeagueList.find(item => item.sportType === sportType)?.leagueList.filter(item => item.leagueIsShow) ?? [],
    )
  }, [allLeagueList, sportType])

  const [activeKeys, setActiveKeys] = useState([])

  useEffect(() => {
    setActiveKeys(
      bettingCartList.map(item => `${item.fixture.eventId}-${item.fixture.leagueType}-${item.selectedBetInfo.betType}`),
    )
  }, [bettingCartList])

  useEffect(() => {
    const updateFixtures = () => {
      const now = moment().tz('Asia/Seoul')

      const updatedFixtures = virtualFixtures.map(fixture => {
        const target = moment(fixture.startDate).subtract(fixture.bettingEndTime, 'seconds')
        const duration = moment.duration(target.diff(now))

        let remainMins = Math.floor(duration.asMinutes())
        let remainSecs = Math.floor(duration.asSeconds() % 60)

        if (duration.asSeconds() <= 0) {
          remainMins = 0
          remainSecs = 0
        }

        return {
          ...fixture,
          remainMins,
          remainSecs,
        }
      })

      setNewVirtualFixtures(updatedFixtures)
    }

    const intervalId = setInterval(updateFixtures, 1000) // 1초 간격으로 업데이트

    // 초기 호출
    updateFixtures()

    // Cleanup on component unmount
    return () => clearInterval(intervalId)
  }, [virtualFixtures])

  const [newMarketInfos, setNewMarketInfos] = useState({})

  // 마켓 가공
  useEffect(() => {
    setNewMarketInfos(
      virtualMarketInfos.reduce((result, market) => {
        const key = market.marketId

        if (!result[key]) {
          result[key] = market
        }

        return result
      }, {}),
    )
  }, [virtualMarketInfos])

  const [activeLeagueType, setActiveLeagueType] = useState(null)

  const onClickActiveLeagueTypeHandler = league => {
    setActiveLeagueType(league)
  }

  const onClickBettingHandler = (fixture, marketInfo, selectedBetInfo) => {
    dispatch(
      addBettingCartItem({
        fixture,
        marketInfo,
        selectedBetInfo,
      }),
    )
  }

  const [activeTab, setActiveTab] = useState('BET')

  const onClickTabHandler = tabName => {
    setActiveTab(tabName)
  }

  const getBetCompo = () => {
    return (
      <>
        <VirtualGameContentBottomHeaderBox>
          <VirtualGameContentBottomHeaderItem
            active={!activeLeagueType}
            onClick={() => {
              onClickActiveLeagueTypeHandler(null)
            }}
          >
            모든 리그
          </VirtualGameContentBottomHeaderItem>
          {leagueList.map(leagueItem => {
            return (
              <VirtualGameContentBottomHeaderItem
                active={activeLeagueType === leagueItem.leagueType}
                onClick={() => {
                  onClickActiveLeagueTypeHandler(leagueItem.leagueType)
                }}
              >
                {VirtualGameLeagueTypeKr[leagueItem.leagueType]}
              </VirtualGameContentBottomHeaderItem>
            )
          })}
        </VirtualGameContentBottomHeaderBox>
        {newVirtualFixtures.length > 0 &&
          newVirtualFixtures
            .filter(fixture => {
              if (activeLeagueType === null) {
                return true
              }

              return fixture.leagueType === activeLeagueType
            })
            .map(fixture => {
              return (
                <VirtualGameContentBottomContentWrap>
                  <VirtualGameContentBottomContentLeagueWrap>
                    <VirtualGameContentBottomContentLeagueBox>
                      <img
                        src={`${process.env.PUBLIC_URL}/img/virtual-game/VIRTUAL_BASEBALL_COLOR.png`}
                        alt=""
                        style={{ width: '24px', marginRight: '5px', marginLeft: '5px', float: 'left' }}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/img/virtual-game/bet365.png`}
                        alt=""
                        style={{ width: '25px', marginRight: '5px', float: 'left' }}
                      />
                      <span>{fixture.leagueName}</span>
                    </VirtualGameContentBottomContentLeagueBox>
                  </VirtualGameContentBottomContentLeagueWrap>

                  <VirtualGameContentBottomContentEventWrap>
                    {(((fixture?.remainMins || 0) === 0 && (fixture?.remainSecs || 0) === 0) ||
                      fixture.fixtureStatus !== 'NSY') && (
                      <VirtualGameContentBottomConntentLockBox>
                        <BiLock />
                      </VirtualGameContentBottomConntentLockBox>
                    )}

                    <VirtualGameContentBottomContentEventBox>
                      <VirtualGameContentBottomContentEventTimeBox>
                        <div className="left">
                          경기 시간 - {convertToKstByFormat(fixture.startDate, 'YYYY-MM-DD HH:mm')}
                        </div>

                        <div className="right">
                          <span>
                            배팅마감: {fixture?.remainMins || 0} : {formatSeconds(fixture?.remainSecs || 0)}
                          </span>
                        </div>
                      </VirtualGameContentBottomContentEventTimeBox>

                      {/* 승무패 */}
                      {newMarketInfos?.[1]?.usedMarket && (
                        <VirtualGameContentBottomContentEventBetWrap>
                          <VirtualGameContentBottomContentEventBetBox>
                            <VirtualGameContentBottomContentEventBetBoxItem>
                              <li className="home-t">
                                <VirtualGameContentBottomContentEventBet
                                  active={activeKeys.includes(
                                    `${fixture.eventId}-${fixture.leagueType}-${VirtualGameBettingType.WIN_DRAW_LOSE_HOME_PICK}`,
                                  )}
                                  style={{ paddingLeft: '8px', paddingRight: '8px' }}
                                  onClick={() => {
                                    onClickBettingHandler(fixture, newMarketInfos?.[1] || {}, {
                                      betType: VirtualGameBettingType.WIN_DRAW_LOSE_HOME_PICK,
                                      betPrice: fixture.winOdd ?? 0,
                                    })
                                  }}
                                >
                                  <ul className="list-box-g">
                                    <li className="hva-txt main-odd-team">
                                      <div>{fixture.homeName}</div>
                                    </li>
                                    <li className="hva-txt main-odd-val" style={{ float: 'left' }}>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
                                        alt=""
                                        style={{ marginRight: '10px' }}
                                      />
                                      <span>{fixture.winOdd?.toFixed(2) || 0}</span>
                                    </li>
                                  </ul>
                                </VirtualGameContentBottomContentEventBet>
                              </li>
                              <li className="vs-t">
                                <VirtualGameContentBottomContentEventBet
                                  active={activeKeys.includes(
                                    `${fixture.eventId}-${fixture.leagueType}-${VirtualGameBettingType.WIN_DRAW_LOSE_DRAW_PICK}`,
                                  )}
                                  onClick={() => {
                                    onClickBettingHandler(fixture, newMarketInfos?.[1] || {}, {
                                      betType: VirtualGameBettingType.WIN_DRAW_LOSE_DRAW_PICK,
                                      betPrice: fixture.drawOdd ?? 0,
                                    })
                                  }}
                                >
                                  <ul className="list-box-g">
                                    <li className="hva-txt">
                                      <span>{fixture.drawOdd?.toFixed(2) || 0}</span>
                                    </li>
                                  </ul>
                                </VirtualGameContentBottomContentEventBet>
                              </li>

                              <li className="away-t">
                                <VirtualGameContentBottomContentEventBet
                                  active={activeKeys.includes(
                                    `${fixture.eventId}-${fixture.leagueType}-${VirtualGameBettingType.WIN_DRAW_LOSE_AWAY_PICK}`,
                                  )}
                                  style={{ paddingLeft: '8px', paddingRight: '8px' }}
                                  onClick={() => {
                                    onClickBettingHandler(fixture, newMarketInfos?.[1] || {}, {
                                      betType: VirtualGameBettingType.WIN_DRAW_LOSE_AWAY_PICK,
                                      betPrice: fixture.loseOdd ?? 0,
                                    })
                                  }}
                                >
                                  <ul className="list-box-g">
                                    <li className="hva-txt main-odd-val" style={{ textAlign: 'left', float: 'left' }}>
                                      <span>{fixture.loseOdd?.toFixed(2) || 0}</span>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
                                        alt=""
                                        style={{ marginLeft: '10px' }}
                                      />
                                    </li>
                                    <li
                                      className="hva-txt main-odd-team"
                                      style={{ textAlign: 'right', float: 'right' }}
                                    >
                                      <div>{fixture.awayName}</div>
                                    </li>
                                  </ul>
                                </VirtualGameContentBottomContentEventBet>
                              </li>
                            </VirtualGameContentBottomContentEventBetBoxItem>
                          </VirtualGameContentBottomContentEventBetBox>
                        </VirtualGameContentBottomContentEventBetWrap>
                      )}

                      {/* 오버언더 */}
                      {newMarketInfos?.[4]?.usedMarket && (
                        <VirtualGameContentBottomContentEventBetWrap>
                          <VirtualGameContentBottomContentEventBetBox>
                            <VirtualGameContentBottomContentEventBetBoxItem>
                              <li className="home-t">
                                <VirtualGameContentBottomContentEventBet
                                  active={activeKeys.includes(
                                    `${fixture.eventId}-${fixture.leagueType}-${VirtualGameBettingType.UNDER_OVER_OVER_PICK}`,
                                  )}
                                  style={{ paddingLeft: '8px', paddingRight: '8px' }}
                                  onClick={() => {
                                    onClickBettingHandler(fixture, newMarketInfos?.[4] || {}, {
                                      betType: VirtualGameBettingType.UNDER_OVER_OVER_PICK,
                                      betPrice: fixture.overOdd ?? 0,
                                    })
                                  }}
                                >
                                  <ul className="list-box-g">
                                    <li className="hva-txt main-odd-team">
                                      <div>{fixture.homeName}</div>
                                    </li>
                                    <li className="hva-txt main-odd-val" style={{ float: 'left' }}>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
                                        alt=""
                                        style={{ width: '10px', height: '10px', marginRight: '10px' }}
                                      />
                                      <span>{fixture.overOdd?.toFixed(2) || 0}</span>
                                    </li>
                                  </ul>
                                </VirtualGameContentBottomContentEventBet>
                              </li>
                              <li className="vs-t">
                                <VirtualGameContentBottomContentEventBet disableHover>
                                  <ul className="list-box-g">
                                    <li className="hva-txt">
                                      <span>{fixture.overUnderPoint?.toFixed(1)}</span>
                                    </li>
                                  </ul>
                                </VirtualGameContentBottomContentEventBet>
                              </li>

                              <li className="away-t">
                                <VirtualGameContentBottomContentEventBet
                                  active={activeKeys.includes(
                                    `${fixture.eventId}-${fixture.leagueType}-${VirtualGameBettingType.UNDER_OVER_UNDER_PICK}`,
                                  )}
                                  style={{ paddingLeft: '8px', paddingRight: '8px' }}
                                  onClick={() => {
                                    onClickBettingHandler(fixture, newMarketInfos?.[4] || {}, {
                                      betType: VirtualGameBettingType.UNDER_OVER_UNDER_PICK,
                                      betPrice: fixture.underOdd ?? 0,
                                    })
                                  }}
                                >
                                  <ul className="list-box-g">
                                    <li className="hva-txt main-odd-val" style={{ textAlign: 'left', float: 'left' }}>
                                      <span>{fixture.underOdd?.toFixed(2) || 0}</span>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
                                        alt=""
                                        style={{ width: '10px', height: '10px', marginLeft: '10px' }}
                                      />
                                    </li>
                                    <li
                                      className="hva-txt main-odd-team"
                                      style={{ textAlign: 'right', float: 'right' }}
                                    >
                                      <div>{fixture.awayName}</div>
                                    </li>
                                  </ul>
                                </VirtualGameContentBottomContentEventBet>
                              </li>
                            </VirtualGameContentBottomContentEventBetBoxItem>
                          </VirtualGameContentBottomContentEventBetBox>
                        </VirtualGameContentBottomContentEventBetWrap>
                      )}
                    </VirtualGameContentBottomContentEventBox>
                  </VirtualGameContentBottomContentEventWrap>
                </VirtualGameContentBottomContentWrap>
              )
            })}
      </>
    )
  }

  const getResultHistoryCompo = () => {
    return (
      <VirtualGameVideoRightContentBox>
        <table>
          <colgroup>
            <col width="20%" />
            <col width="40%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <tbody>
            <tr>
              <th>경기일시</th>
              <th>리그</th>
              <th>홈팀</th>
              <th>VS</th>
              <th>원정</th>
            </tr>
            {virtualResultHistory.map(result => {
              return (
                <tr className="tbody-tr">
                  <td>{convertToKstByFormat(result.startDate, 'MM-DD HH:mm')}</td>
                  <td> {VirtualGameLeagueTypeKr[result.leagueType]} </td>
                  <td>
                    <div className="text-ellipsis">{result.homeName}</div>
                  </td>
                  <td>
                    {' '}
                    {result.homeScore} : {result.awayScore}{' '}
                  </td>
                  <td>
                    <div className="text-ellipsis">{result.awayName}</div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </VirtualGameVideoRightContentBox>
    )
  }

  const getBetLogCompo = () => {
    return (
      <VirtualGameVideoRightContentBox>
        {virtualBetLogs.length === 0 ? (
          <VirtaulGameBetHistoryEmptyBox>해당 게임에 배팅내역이 존재하지 않습니다.</VirtaulGameBetHistoryEmptyBox>
        ) : (
          <>
            <div>
              <table>
                <colgroup>
                  <col width="20%" />
                  <col width="30%" />
                  <col width="10%" />
                  <col width="10%" />
                  <col width="20%" />
                  <col width="20%" />
                </colgroup>

                <tr>
                  <th>경기일시</th>
                  <th>리그</th>
                  <th>홈</th>
                  <th>원정</th>
                  <th>배팅</th>
                  <th>결과</th>
                </tr>
              </table>
            </div>

            {virtualBetLogs.map(log => {
              return (
                <div>
                  <table>
                    <colgroup>
                      <col width="20%" />
                      <col width="30%" />
                      <col width="10%" />
                      <col width="10%" />
                      <col width="20%" />
                      <col width="20%" />
                    </colgroup>

                    <tbody>
                      <tr className="tbody-tr">
                        <td>{convertToKstByFormat(log.startDate, 'MM-DD HH:mm')}</td>
                        <td> {VirtualGameLeagueTypeKr[log.leagueType]} </td>
                        <td>
                          <div className="text-ellipsis">{log.homeName}</div>
                        </td>
                        <td>
                          <div className="text-ellipsis">{log.awayName}</div>
                        </td>
                        <td style={{ color: '#d1a700' }}>
                          {log.marketName} - {VirtualGameBettingTypeKr[log.selectedBetType]}
                        </td>
                        <td style={{ color: `${getColor(log.betSettlementType)}`, fontWeight: '700' }}>
                          {VirtualResultSettlementTypeEnums[log.betSettlementType]}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            })}
          </>
        )}
      </VirtualGameVideoRightContentBox>
    )
  }

  const getContentCompo = () => {
    switch (activeTab) {
      case 'BET':
        return getBetCompo()
      case 'RESULT_HISTORY':
        return getResultHistoryCompo()
      case 'BETTING_LOG':
        return getBetLogCompo()
      default:
        return null
    }
  }

  return (
    <VirtualGameContentBottomWrap>
      <VirtualGameContentBottomTabWrap>
        <VirtualGameContentBottomTabItemBox
          active={activeTab === 'BET'}
          onClick={() => {
            onClickTabHandler('BET')
          }}
        >
          회차선택
        </VirtualGameContentBottomTabItemBox>
        <VirtualGameContentBottomTabItemBox
          active={activeTab === 'RESULT_HISTORY'}
          onClick={() => {
            onClickTabHandler('RESULT_HISTORY')
          }}
        >
          지난결과
        </VirtualGameContentBottomTabItemBox>
        <VirtualGameContentBottomTabItemBox
          active={activeTab === 'BETTING_LOG'}
          onClick={() => {
            onClickTabHandler('BETTING_LOG')
          }}
        >
          배팅내역
        </VirtualGameContentBottomTabItemBox>
      </VirtualGameContentBottomTabWrap>

      {getContentCompo()}
    </VirtualGameContentBottomWrap>
  )
}

export default VirtualBaseballContentBotom

const VirtualGameContentBottomWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 0;
  margin-bottom: 14px;
`

const VirtualGameContentBottomTabWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const VirtualGameContentBottomTabItemBox = styled.div`
  margin-right: 6px;

  overflow: unset;

  font-weight: bold;
  text-align: center;
  width: auto;
  padding: 0 15px;
  font-size: 12px;
  color: #454545;
  box-sizing: border-box;
  border: 1px solid #aaaaaa;
  border-radius: 5px;

  background: #fdfdfd;
  text-align: center;
  color: var(--font);
  cursor: pointer;
  flex: 120px;
  padding: 12px 0;
  display: block;
  white-space: nowrap;

  ${props =>
    props.active &&
    `
  background: ${KyochonMainBorder};
  color: black    `}

  &:hover {
    background: ${KyochonMainBorder};
    color: black;
  }
`

const VirtualGameContentBottomHeaderBox = styled.div`
  width: 100%;
  border-radius: 6px 6px 0 0;
  color: #000;
  font-size: 12px;
  font-weight: 700;
  padding: 7px;
  display: flex;

  white-space: nowrap !important;
  overflow: auto;
`

const VirtualGameContentBottomHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  cursor: pointer;
  z-index: 0;
  overflow: unset;
  white-space: nowrap;
  font-weight: bold;
  text-align: center;
  width: auto;
  padding: 0 15px;
  height: 35px;
  font-size: 12px;
  color: #454545;
  box-sizing: border-box;
  border: 1px solid #aaaaaa;
  background: linear-gradient(#ffffff, #ffffff);
  border-radius: 5px;

  ${props =>
    props.active &&
    `
  background: ${KyochonMainBorder};
  color: black    `}

  &:hover {
    background: ${KyochonMainBorder};
    color: black;
  }
`

const VirtualGameContentBottomContentWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;

  display: flex;
  flex-direction: column;
`

const VirtualGameContentBottomContentLeagueWrap = styled.div`
  background: ${KyochonMainBorder};
  border-radius: 6px;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  font-size: 11px;
`

const VirtualGameContentBottomContentLeagueBox = styled.div`
  font-size: 12px;
  float: left;
  font-weight: 700;
  height: 100%;
  width: 100%;
  color: $a0a0a0;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;

  span {
    float: left;
    margin-top: 3px;
    color: #000;
  }
`

const VirtualGameContentBottomContentEventWrap = styled.div`
  width: 100%;
  position: relative;
`

const VirtualGameContentBottomConntentLockBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);

  z-index: 290;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    color: ${KyochonMainBorder};
  }
`

const VirtualGameContentBottomContentEventBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const VirtualGameContentBottomContentEventTimeBox = styled.div`
  width: 100%;
  text-align: left;
  border-radius: 6px 6px 0 0;
  font-size: 11px;

  .left {
    float: left;
    padding: 10px 10px;
    font-size: 12px;
    font-weight: 700;
    color: #a10000;
  }

  .right {
    float: right;
    text-align: center;

    color: #a10000;
    background: none;
    border: none;
    padding: 10px 10px;
    border-radius: 16px;
    font-weight: 900;
    font-size: 14px;
    transition: background-color 0.1s ease-out 0s;

    .hva-txt {
      display: table-cell;
      vertical-align: middle;
      overflow-x: hidden;
    }
  }
`

const VirtualGameContentBottomContentEventBetWrap = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 8px;
`

const VirtualGameContentBottomContentEventBetBox = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
`

const VirtualGameContentBottomContentEventBetBoxItem = styled.ul`
  width: 100%;
  min-height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;

  .home-t,
  .vs-t {
    display: flex;
    text-align: center;
    vertical-align: middle;
    padding: 1px;
    overflow: hidden;
    float: left;
  }

  .home-t {
    width: calc(50% - 39px);
  }

  .home-line-t {
    width: 100%;
    margin-bottom: 2px;
  }

  .away-t {
    text-align: center;
    vertical-align: middle;
    padding: 1px;
    display: flex;
    width: calc(50% - 39px);
    overflow: hidden;
    float: left;
  }

  .vs-t {
    width: 78px;
  }
`

const VirtualGameContentBottomContentEventBet = styled.div`
  // display: inline-block;
  // margin-bottom: 0;
  // font-size: 14px;
  font-weight: 600;
  // text-align: center;
  // white-space: nowrap;
  // cursor: pointer;
  // border-radius: 6px;
  // width: 100%;
  // font-size: 12px;

  width: 100%;
  padding: 5px 0px 5px 0px;
  background: linear-gradient(#fff, #f5fcff);
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  cursor: pointer;
  line-height: 19px;

  border-radius: 6px;
  transition: background-color 0.1s ease-out 0s, border 0.1s ease-out 0s;

  color: #000;

  ${props =>
    !props.disableHover &&
    `
        &:hover {
            border: solid 1px black;
        }
    `}

  ${props =>
    props.active &&
    `
        background:#000;
        color: #fff;
    `}

  .list-box-g {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    height: 24px;
  }

  .hva-txt {
    display: table-cell;
    vertical-align: middle;
    overflow-x: auto;
  }

  .main-odd-team {
    width: calc(100% - 60px);
    float: left;
    text-align: left;
  }

  .main-odd-val {
    width: 60px;
    float: right;
    text-align: right;
  }

  .line {
    color: ${KyochonMainBorder};
  }
`

const VirtualGameVideoRightContentBox = styled.div`
  position: relative;
  margin-top: 1px;
  text-align: center;
  min-height: 362px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(58, 59, 59, 0.5);
  }

  table {
    width: 100%;
    color: #000;
    font-size: 13px;

    border-collapse: separate;
    border-spacing: 0 8px;

    colgroup col {
      text-align: center;
    }

    th {
      background: linear-gradient(#999999, #999999);
      font-weight: 700;
      font-size: 16px;
      padding: 3px 0;
      height: 30px;
    }

    .tbody-tr {
      td {
        margin-bottom: 5px;
        border-bottom: 1px solid #ccc;
        padding: 5px 3px;
        text-align: center;

        .text-ellipsis {
          padding: 2px;
          overflow: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap !important;

          &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
          }

          &::-webkit-scrollbar-thumb {
            border: 1px solid transparent;
            background: #ccc;
          }
        }
      }
    }
  }
`

const VirtaulGameBetHistoryEmptyBox = styled.div`
  width: 100%;
  height: 100%;
  min-height: 400px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: #fff;
`
