import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import Portal from '../../../shared/components/Portal'
import { HermesMainBorder, HermesMainHover, HermesMainText } from '../../../utils/palette'
import MobileSignInPopup from '../../../mobile/MobileLayout/MobileSignIn'
import MobileSignUpPopup from '../../../mobile/MobileLayout/MobileSignUp'

/**
 * @see : https://github.com/masroorejaz/react-simple-captcha
 */

const MobileAuthorityAlertPopup = ({ onClose, visible }) => {
  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }
  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
  }

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onShowSignUpModal = () => {
    setSignUpModalShow(true)
  }
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  return (
    <>
      {signInModalShow && (
        <MobileSignInPopup
          visible={signInModalShow}
          onClose={onCloseSignInModal}
          onShowSignUpModal={onShowSignUpModal}
        />
      )}
      {signUpModalShow && <MobileSignUpPopup visible={signUpModalShow} onClose={onCloseSignUpModal} />}
      <Portal elementId="signin-modal">
        <SignInModalOverlay visible={visible} />
        <SignInModalWrapper tabIndex="-1" visible={visible}>
          <SignInModalInner tabIndex="0">
            <SignInModalHeader>
              <SignInModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/popup/signup-logo.png`} alt="" />
              <SignInModalCloseButton
                onClick={close}
                src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                alt=""
              />
            </SignInModalHeader>
            <SignInModalContentWrap>
              <SignInModalContentInfo1>로그인 후 이용하실 수 있습니다.</SignInModalContentInfo1>
            </SignInModalContentWrap>
            <SignInModalContentWrap2>
              <HeaderRightMenuSignUpButton
                onClick={() => {
                  setSignInModalShow(true)
                }}
              >
                로그인
              </HeaderRightMenuSignUpButton>
              <HeaderRightMenuLoginButton
                onClick={() => {
                  setSignUpModalShow(true)
                }}
              >
                회원가입
              </HeaderRightMenuLoginButton>
            </SignInModalContentWrap2>
          </SignInModalInner>
        </SignInModalWrapper>
      </Portal>
    </>
  )
}

export default MobileAuthorityAlertPopup

const HeaderRightMenuLoginButton = styled.button`
  background: none;
  padding: 7px 20px;
  border-radius: 5px;
  outline: none;
  width: 100%;
  text-align: center;
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid ${HermesMainHover};
  height: 34px;
  font-weight: 500;
  font-size: 13px;
  &:hover {
    background-color: ${HermesMainHover};
  }
`

const HeaderRightMenuSignUpButton = styled.button`
  outline: none;
  color: #000;
  background: ${HermesMainHover};
  border: 1px solid ${HermesMainHover};
  border-radius: 5px;
  height: 34px;
  font-weight: 500;
  padding: 7px 20px;
  width: 100%;
  text-align: center;
  font-size: 13px;
`

const SignInModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 95%;
  transform: translate(-50%, -50%);
  top: 45vh;
  left: 50%;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`
const SignInModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`

const SignInModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgb(30, 32, 36);
  width: 100%;
  margin: 0 auto;
  height: 490px;
`

const SignInModalHeader = styled.div`
  height: 245px;
  background: #16171a !important;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: solid 1px red;
  // border-bottom: 2px solid ${HermesMainBorder};
`
const SignInModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  top: 20px;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

// 로고
const SignInModalHeaderLogo = styled.img`
  width: 364px;
  height: 240px;
`

// modal body content
const SignInModalContentWrap = styled.div`
  text-align: center;

  // form 없을 때 임시로 사용
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  height: 70px;
  background: #16171a !important;
  padding: 0 20px;

  text-align: center;

  // form 없을 때 임시로 사용
  display: flex;
  flex-direction: column;
  gap: 10px;
`

// modal body content
const SignInModalContentWrap2 = styled.div`
  text-align: center;

  // form 없을 때 임시로 사용
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  height: calc(100% - 315px);
  background: #16171a !important;

  padding: 0px 20px;
  padding-bottom: 20px;
  text-align: center;
  gap: 10px;
`

// 이용 권한이 없습니다.
const SignInModalContentInfo1 = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  font-size: 13px;
  color: #fff;
`
