import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { confirmNickName, confirmUserId, siginUp } from '../../api/authManage/authManageApi'
import { searchBankList } from '../../api/bank/bankApi'
import { resetCodeValidity } from '../../redux/authSlice'
import CustomLoading from '../CustomLoading'

const WebSignUpPage = () => {
  const dispatch = useDispatch()
  const [recommendCode, setRecommendCode] = useState(null)
  const location = useLocation()
  const { isCodeValid } = useSelector(state => state.auth)
  useEffect(() => {
    const originalMinWidth = document.body.style.minWidth
    document.body.style.minWidth = '390px'

    return () => {
      document.body.style.minWidth = originalMinWidth
    }
  }, [])
  const navigate = useNavigate()

  useEffect(() => {
    if (!isCodeValid) {
      navigate('/')
      return
    }
    const params = new URLSearchParams(location.search)
    const code = params.get('recommendCode')
    setRecommendCode(code)

    // 페이지를 벗어날 때 유효성 상태를 리셋
    return () => {
      dispatch(resetCodeValidity())
    }
  }, [isCodeValid, location, navigate, dispatch])

  const [recParams] = useSearchParams()
  const [telecomOptionValue, setTelecomOptionValue] = useState('')
  const [telecomOptions, setTelecomOptions] = useState([])
  const onChangeTelecomHandler = event => {
    const selectedOption = telecomOptions.find(option => option.value === event.target.value)
    setTelecomOptionValue(selectedOption)
  }
  // 아이디
  const [userId, setUserId] = useState('')
  // 아이디 중복확인
  const [isConfirmUserId, setIsConfirmUserId] = useState(false)
  const [isProcessUserId, setIsProcessUserId] = useState(false)
  const onClickConfirmUserIdHandler = () => {
    if (!userId) {
      alert('아이디를 먼저 입력해주세요.')
      return
    }

    if (/^\d+$/.test(userId)) {
      alert('아이디는 숫자로만 구성될 수 없습니다.')
      return
    }

    if (isProcessUserId) {
      return
    }

    setIsProcessUserId(true)

    confirmUserId({
      userId,
    })
      .then(res => {
        const { result, message } = res
        setIsConfirmUserId(true)
        setIsProcessUserId(false)
        alert(message)
      })
      .catch(error => {
        const { message } = error.response.data
        alert(message)
        setIsConfirmUserId(false)
        setIsProcessUserId(false)
      })
  }

  const [nickName, setNickName] = useState('')
  const [isConfirmNickName, setIsConfirmNickName] = useState(false)
  const [isProcessNickName, setIsProcessNickName] = useState(false)

  const onClickConfirmNickNameHandler = () => {
    if (!nickName) {
      alert('닉네임을 먼저 입력해주세요.')
      return
    }

    if (isProcessNickName) {
      return
    }

    if (userId === nickName) {
      alert('ID와 동일한 닉네임은 불가합니다.')
      return
    }

    const koreanPattern = /[ㄱ-ㅎㅏ-ㅣ가-힣]/ // 한글 문자가 포함되어 있는지 확인하는 정규표현식
    const koreanConsonantsPattern = /[ㄱ-ㅎ]/ // 한글 자음 패턴
    const koreanVowelsPattern = /[ㅏ-ㅣ]/ // 한글 모음 패턴
    const numberPattern = /^[0-9]+$/ // 숫자로만 이루어진지 확인하는 정규표현식

    if (koreanPattern.test(nickName)) {
      // 닉네임에 한글 문자가 포함된 경우
      if (koreanConsonantsPattern.test(nickName) || koreanVowelsPattern.test(nickName)) {
        // 닉네임이 자음 또는 모음으로만 이루어진 경우
        alert('잘못된 닉네임입니다.')
        return
      }
    } else if (numberPattern.test(nickName)) {
      // 닉네임이 숫자로만 이루어진 경우
      alert('숫자로만 이루어진 닉네임은 불가합니다.')
      return
    }

    setIsProcessNickName(true)

    confirmNickName(nickName)
      .then(res => {
        const { result, message } = res
        setIsConfirmNickName(true)
        setIsProcessNickName(false)
        alert(message)
      })
      .catch(error => {
        const { message } = error.response.data
        alert(message)
        setIsConfirmNickName(false)
        setIsProcessNickName(false)
      })
  }

  // 은행
  const [bankNameOptionValue, setBankNameOptionValue] = useState('')
  const [bankNameOption, setBankNameOption] = useState([])
  const onChangeBankNameHandler = event => {
    const selectedOption = bankNameOption.find(option => option.value === event.target.value)
    setBankNameOptionValue(selectedOption)
  }

  useEffect(() => {
    searchBankList().then(res => {
      res.data.bankList.map(item =>
        setBankNameOption(prev => [
          ...prev,
          {
            value: item.bankId,
            label: item.bankName,
          },
        ]),
      )
    })

    setTelecomOptions([
      { value: 'LG', label: 'LG' },
      { value: 'SKT', label: 'SKT' },
      { value: 'KT', label: 'KT' },
      { value: 'LG_PLUS', label: 'LG 알뜰폰' },
      { value: 'SKT_PLUS', label: 'SKT 알뜰폰' },
      { value: 'KT_PLUS', label: 'KT 알뜰폰' },
    ])

    // searchRecommendCode().then(res => {
    //   const rec = res.data.recommendCode
    //   const temp = rec || getRecommendCode()
    //   if (temp) {
    //     setIsConfirmRecommendCode(true)
    //   }
    //   setRecommendCode(temp)
    // })
  }, [])

  const [userBirthDayFirst, setUserBirthDayFirst] = useState('')
  const [userBirthDaySecond, setUserBirthDaySecond] = useState('')
  // 계좌번호
  const [accountNum, setAccountNum] = useState('')

  // 비밀번호
  const [password, setPassword] = useState('')
  // 비밀번호 확인
  const [confirmPassword, setConfirmPassword] = useState('')

  // 비밀번호
  const [withdrawalPassword, setWithdrawalPassword] = useState('')
  // 비밀번호 확인
  const [confirmWithdrawalPassword, setConfirmWithdrawalPassword] = useState('')

  // 이름
  const [userName, setUserName] = useState('')
  // 연락처
  const [userPhoneNum, setUserPhoneNum] = useState('')

  const [siginInFlag, setSignInFlag] = useState(false)
  const [loginIp, setLoginIp] = useState('')
  const [userAgent, setUserAgent] = useState('')

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/').then(res => {
      setLoginIp(res.data.IPv4 || res.data.IPv6)
    })
  }, [])

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onShowSignUpModal = () => {
    setSignUpModalShow(true)
  }
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  const [siginUpApiLoading, setSiginUpApiLoading] = useState(false)
  const onSubmit = e => {
    if (siginUpApiLoading) return
    if (!isConfirmUserId) {
      alert('ID를 다시 확인해주세요.')
      return
    }
    if (!isConfirmNickName) {
      alert('닉네임을 다시 확인해주세요.')
      return
    }
    if (!password) {
      alert('암호를 다시 확인해주세요.')
      return
    }
    if (password !== confirmPassword) {
      alert('암호를 다시 확인해주세요.')
      return
    }
    if (!withdrawalPassword) {
      alert('출금암호를 다시 확인해주세요.')
      return
    }
    if (withdrawalPassword !== confirmWithdrawalPassword) {
      alert('출금암호를 다시 확인해주세요.')
      return
    }

    if (withdrawalPassword.length !== 4) {
      alert('출금암호는 숫자로만 4자리를 입력해주세요.')
      return
    }

    if (!userBirthDayFirst) {
      alert('생년월일 앞 6자리를 다시 확인해주세요.')
      return
    }
    if (!userBirthDaySecond) {
      alert('생년월일 뒤 1자리를 다시 확인해주세요.')
      return
    }
    if (!userName) {
      alert('예금주를 다시 확인해주세요.')
      return
    }

    if (!bankNameOptionValue) {
      alert('은행을 다시 확인해주세요.')
      return
    }

    if (!accountNum) {
      alert('계좌번호를 다시 확인해주세요.')
      return
    }

    if (!telecomOptionValue) {
      alert('통신사를 다시 확인해주세요.')
      return
    }

    if (!userPhoneNum) {
      alert('핸드폰번호를 다시 확인해주세요.')
      return
    }

    if (userPhoneNum.length !== 10 && userPhoneNum.length !== 11) {
      alert('핸드폰번호를 다시 확인해주세요.')
      return
    }

    setSiginUpApiLoading(true)
    const body = {
      signUpIp: loginIp,
      userId,
      nickName,
      password,
      withdrawalPassword,
      memberName: userName,
      memberPhoneNum: userPhoneNum,
      bankName: bankNameOptionValue?.label,
      telecom: telecomOptionValue?.value,
      accountNum,
      recommendCode,
      userBirthDayFirst,
      userBirthDaySecond,
    }
    siginUp(body)
      .then(res => {
        alert('회원가입에 성공했습니다. \n잠시 후 로그인 부탁드립니다.')
        setSiginUpApiLoading(false)
        navigate('/')
        // close()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        if (errorCode === 'SIGN-1004') {
          alert(`${message}\n잠시후 다시 시도해주세요.`)
        } else if (errorCode === 'SIGN-1010') {
          alert(`${message}`)
        } else {
          alert('회원가입을 실패했습니다. \n 다시 시도해주세요.')
        }
        setSiginUpApiLoading(false)
        // navigate('/')
      })
  }

  return (
    <LoginPageWrap>
      {siginInFlag && <CustomLoading />}
      <div style={{ height: '50px' }} />
      <div style={{ width: '272px', margin: '0 auto' }}>
        <img style={{ width: '270px', height: '95px' }} src={`${process.env.PUBLIC_URL}/img/logo/logo.png`} alt="" />
      </div>
      <div style={{ height: '20px' }} />
      <Form
        onSubmit={onSubmit}
        initialValues={
          {
            // memberId,
            // memberPassword,
            // inputCaptchaCode,
          }
        }
      >
        {({ handleSubmit, form: { reset } }) => (
          <div onSubmit={handleSubmit} style={{}}>
            <div className="signup_box" style={{ width: '300px', padding: '10px', margin: '0 auto' }}>
              <div>
                <div style={{ float: 'left' }}>
                  <input
                    type="text"
                    id="ip_account"
                    className={`account_input ${isConfirmUserId ? 'read-only' : ''}`}
                    autoComplete="off"
                    maxLength="16"
                    value={userId}
                    readOnly={isConfirmUserId}
                    placeholder="아이디 - 영문,숫자"
                    onChange={e => {
                      const { value } = e.target
                      const onlyEnglish = value.replace(/[^A-Za-z0-9]/gi, '')
                      if (onlyEnglish.length > 15) {
                        return
                      }
                      setUserId(onlyEnglish)
                    }}
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/account_icon.png)`,
                    }}
                  />
                </div>
                <div style={{ float: 'right' }}>
                  <input
                    type="button"
                    id="bt_confirm_account"
                    disabled={isConfirmUserId}
                    className="check_button"
                    onClick={() => {
                      onClickConfirmUserIdHandler()
                    }}
                    value="중복확인"
                  />
                </div>
              </div>
              <div style={{ height: '5px', clear: 'both' }} />
              <div>
                <div style={{ float: 'left' }}>
                  <input
                    type="text"
                    id="ip_nickname"
                    value={nickName}
                    readOnly={isConfirmNickName}
                    onChange={e => {
                      const { value } = e.target
                      if (value.length > 15) {
                        return
                      }
                      setNickName(value)
                    }}
                    autoComplete="off"
                    className={`account_input ${isConfirmNickName ? 'read-only' : ''}`}
                    maxLength="16"
                    placeholder="닉네임 - 한글,영문,숫자"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/account_icon.png)`,
                    }}
                  />
                </div>
                <div style={{ float: 'right' }}>
                  <input
                    type="button"
                    id="bt_confirm_nickname"
                    className="check_button"
                    disabled={isConfirmNickName}
                    onClick={() => {
                      onClickConfirmNickNameHandler()
                    }}
                    value="중복확인"
                  />
                </div>
              </div>
              <div style={{ height: '5px', clear: 'both' }} />
              <div>
                <input
                  type="password"
                  id="ip_password"
                  className="passwd_input"
                  maxLength="16"
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value)
                  }}
                  placeholder="비밀번호"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/passwd_icon.png)`,
                  }}
                />
              </div>
              <div style={{ height: '5px' }} />
              <div>
                <input
                  type="password"
                  id="ip_password_confirm"
                  className="passwd_input"
                  value={confirmPassword}
                  onChange={e => {
                    setConfirmPassword(e.target.value)
                  }}
                  maxLength="16"
                  placeholder="비밀번호 확인"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/passwd_icon.png)`,
                  }}
                />
              </div>
              <div style={{ height: '20px' }} />
              <div>
                <input
                  type="password"
                  id="ip_exchange_password"
                  value={withdrawalPassword}
                  onChange={({ target: { value } }) => {
                    // 숫자만 입력되도록 필터링
                    if (/^\d*$/.test(value)) {
                      setWithdrawalPassword(value)
                    }
                  }}
                  className="passwd_input"
                  maxLength="4"
                  placeholder="환전 비밀번호"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/passwd_icon.png)`,
                  }}
                />
              </div>
              <div style={{ height: '5px' }} />
              <div>
                <input
                  type="password"
                  id="ip_exchange_password_confirm"
                  className="passwd_input"
                  maxLength="16"
                  value={confirmWithdrawalPassword}
                  onChange={e => {
                    setConfirmWithdrawalPassword(e.target.value)
                  }}
                  placeholder="환전 비밀번호 확인"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/passwd_icon.png)`,
                  }}
                />
              </div>
              <div style={{ height: '20px' }} />
              <div style={{ display: 'flex' }}>
                <input
                  type="text"
                  id="ip_bankname"
                  className="bankname_input"
                  maxLength="30"
                  value={userBirthDayFirst}
                  autoComplete="off"
                  onChange={e => {
                    const { value } = e.target
                    const onlyNumber = value.replace(/[^0-9]/g, '')
                    if (onlyNumber.length > 6) {
                      return
                    }
                    setUserBirthDayFirst(onlyNumber)
                  }}
                  placeholder="주민번호 앞 6자리"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/bank_icon.png)`,
                  }}
                />
                <span style={{ display: 'block', margin: '0 10px', fontSize: '30px' }}> - </span>
                <input
                  type="text"
                  id="ip_bankname"
                  style={{ width: '52px', paddingLeft: '10px', borderRadius: '15px' }}
                  className="bankname_input2"
                  maxLength="1"
                  value={userBirthDaySecond}
                  autoComplete="off"
                  placeholder="뒤 1자리"
                  onChange={e => {
                    const { value } = e.target
                    const onlyNumber = value.replace(/[^0-9]/g, '')
                    if (onlyNumber.length > 1) {
                      return
                    }
                    setUserBirthDaySecond(onlyNumber)
                  }}
                />
              </div>
              <div style={{ height: '5px' }} />
              <div>
                <input
                  type="text"
                  id="ip_bankname"
                  className="bankname_input"
                  maxLength="30"
                  value={userName}
                  onChange={e => {
                    setUserName(e.target.value)
                  }}
                  placeholder="예금주"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/bank_icon.png)`,
                  }}
                />
              </div>
              <div style={{ height: '5px' }} />
              <div>
                <select
                  name="ip_banktype"
                  id="ip_banktype"
                  className="banktype_input"
                  value={bankNameOptionValue?.value}
                  onChange={option => {
                    // input.onChange(option)
                    onChangeBankNameHandler(option)
                  }}
                  alt="이용은행"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/bank_icon.png)`,
                    height: '34px',
                    backgroundPosition: '15px 10px',
                  }}
                >
                  <option value="">입금은행 선택</option>
                  {bankNameOption.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ height: '5px' }} />
              <div>
                <input
                  type="text"
                  id="ip_banknumber"
                  className="banknumber_input"
                  maxLength="40"
                  value={accountNum}
                  autoComplete="off"
                  onChange={e => {
                    const { value } = e.target
                    const onlyNumber = value.replace(/[^0-9]/g, '')
                    setAccountNum(onlyNumber)
                  }}
                  placeholder="계좌번호"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/bank_icon.png)`,
                  }}
                />
              </div>
              <div style={{ height: '5px' }} />
              <div style={{ display: 'flex', gap: '5px' }}>
                <select
                  name="ip_banktype"
                  id="ip_phonenumber"
                  className="banktype_input"
                  value={telecomOptionValue?.value}
                  onChange={onChangeTelecomHandler}
                  alt="통신사"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/phone_icon.png)`,
                    width: '130px',
                  }}
                >
                  <option value="">통신사</option>
                  {telecomOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  id="ip_phonenumber"
                  className="phonenumber_input"
                  //  onkeyup="check_input_only_number(event);"
                  maxLength="15"
                  value={userPhoneNum}
                  autoComplete="off"
                  onChange={e => {
                    const { value } = e.target
                    const onlyNumber = value.replace(/[^0-9]/g, '')
                    setUserPhoneNum(onlyNumber)
                  }}
                  placeholder="전화번호"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/phone_icon.png)`,
                    width: '115px',
                  }}
                />
              </div>
              <div style={{ height: '10px' }} />
              <div>
                <div style={{ float: 'left' }}>
                  <input
                    type="button"
                    id=""
                    className="signup_button"
                    onClick={() => {
                      onSubmit()
                    }}
                    value="가입신청"
                  />
                </div>
                <div style={{ float: 'right' }}>
                  <a
                    onClick={() => {
                      navigate('/')
                    }}
                  >
                    <input type="button" id="" className="goback_button" value="취소하기" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    </LoginPageWrap>
  )
}

export default WebSignUpPage

const LoginPageWrap = styled.div`
  display: block;
  margin-top: 0em;
  font-size: 12px;
`
