import { createSlice } from '@reduxjs/toolkit'

const miniGameBettingSlice = createSlice({
  name: 'miniGameBetting',
  initialState: {
    miniGameType: null,
    miniGameBettingInfo: {}, // 미니게임별 배당 정보
    miniGameRound: null, // 현재 라운드

    deadLineTime: 0,
    isShow: false,
    endTime: 0,
    bettingCountOkFlag: false,

    bettingCloseFlag: false, // 배팅 마감
    bettingCompleteFlag: false, // 배팅 완료

    miniGameBettingPer: null, // 배당
    miniGameBettingType: null, // 배팅타입
  },
  reducers: {
    setMiniGameInfo: (state, action) => {
      state.miniGameType = action.payload.miniGameType
      state.miniGameBettingInfo = action.payload.miniGameBettingInfo
      state.miniGameRound = action.payload.round
      state.bettingCountOkFlag = action.payload.bettingCountOkFlag
      state.endTime = action.payload.endTime
      state.deadLineTime = action.payload.deadLineTime
      state.isShow = action.payload.isShow
    },

    setResetData: (state, action) => {
      state.miniGameBettingPer = 0
      state.miniGameBettingType = null
      state.bettingMoney = 0
    },

    addBettingCart: (state, action) => {
      const { miniGameBettingPer, miniGameBettingType } = action.payload
      state.miniGameBettingPer = miniGameBettingPer
      state.miniGameBettingType = miniGameBettingType
    },
    // tab이동 시, minigameType 담아주기
    setResetRoundAndGameTypeHandler: (state, action) => {
      const { miniGameType, miniGameRound } = action.payload
      state.miniGameType = miniGameType
      state.miniGameRound = miniGameRound
    },
    // 배팅 마감됐을 때 용도
    setBettingCloseHandler: (state, action) => {
      state.bettingCloseFlag = action.payload
    },
    // X 눌렀을떄 용도
    setCloseHandler: (state, action) => {
      state.miniGameBettingPer = 0
      state.miniGameBettingType = null
      state.bettingMoney = 0
    },
    setMiniGameCompleteFlag: (state, action) => {
      state.bettingCompleteFlag = action.payload
    },
  },
})

export const {
  setMiniGameInfo,
  setResetData,
  addBettingCart,
  setBettingCloseHandler,
  setCloseHandler,
  setResetRoundAndGameTypeHandler,
  setMiniGameCompleteFlag,
} = miniGameBettingSlice.actions

export default miniGameBettingSlice.reducer
