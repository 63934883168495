import React, { useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'
import parse from 'style-to-object'
import styled from 'styled-components'
import { convertToKstDate } from '../../../utils/dateTime'
import { HermesMainHover, HermesMainHover2 } from '../../../utils/palette'

const MobileBettingRuleDetail = ({ notice, onBack }) => {
  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 맨 위로 이동
    window.scrollTo(0, 0)
  }, [])
  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
    }
  }

  return (
    <>
      <div style={{ padding: '0px 0 0px 0' }}>
        <div id="hiddenBar" style={{ background: '#fff', boxShadow: '0px 7px 6px -5px #DFE1E5;' }}>
          <div className="header">
            <div style={{ height: '3px' }} />
            <table
              style={{ padding: '0px', border: '0px', borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '36px' }}>
                    <img
                      alt=""
                      style={{ width: '36px', height: '36px' }}
                      src="/m/images/menu_btn.png?v=1"
                      //  onclick="showMenuOn();"
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <a>
                      <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                    </a>
                  </td>
                  <td style={{ width: '36px' }}>
                    <img
                      style={{ width: '36px', height: '36px' }}
                      alt=""
                      // src="/m/images/icons8-refresh-64.png?v=4"
                      //  onClick="window.location.reload();"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: '2px' }} />
          </div>
        </div>

        <div className="body_title">
          <span className="title1">
            <strong>이용규정</strong>
          </span>
          <span className="title2">
            <strong>&nbsp;Using Rule</strong>
          </span>
        </div>
        <div style={{ height: '20px' }} />
        <div className="board_wrap">
          <div className="board_subject" style={{ wordBreak: 'break-all' }}>
            {notice.boardTitle}
          </div>
          <div className="board_date">
            {convertToKstDate(notice.registrationDate)}&nbsp;│&nbsp;
            <span style={{ color: '#f4ad00' }}>
              <b>운영자</b>
            </span>
          </div>
          <div className="board_body" style={{ wordBreak: 'break-all' }}>
            {ReactHtmlParser(notice.boardContent, { transform: transformFn })}
          </div>
          <div className="pageing_wrap">
            <a onClick={onBack}>
              <font className="bt_type1" style={{ padding: '7px', width: '90%' }}>
                <b>목록으로</b>
              </font>
            </a>
          </div>
        </div>
        {/* <div className="pageing_wrap">
    <table align="center" cellpadding="0" cellspacing="5" border="0" width="100%" className="pageing_table">
      <tbody>
        <tr>
          <td width="50%">
            <a href="message.aspx?page=1">◀ 이전10개</a>
            </td>
            <td width="50%">
              <a href="message.aspx?page=1">다음10개 ▶</a></td></tr></tbody></table>
              </div> */}
      </div>
    </>
    // <MobileTopWrap ref={webPageWrapRef}>
    //   <MobileCustomerCenterHeaderWrap>
    //     <MobileCustomerCenterHeaderInfo>공지/규정</MobileCustomerCenterHeaderInfo>
    //   </MobileCustomerCenterHeaderWrap>
    //   <div style={{ padding: '8px' }}>
    //     <NoticeButtonWrap>
    //       <NoticeButtonBox>
    //         <NoticeButton
    //           isActive={boardType === 'NOTICE'}
    //           onClick={() => {
    //             onClickBoardTypeHandler('NOTICE')
    //           }}
    //         >
    //           공지사항
    //         </NoticeButton>
    //       </NoticeButtonBox>
    //       <NoticeButtonBox>
    //         <NoticeButton
    //           isActive={boardType === 'BETTING_RULE'}
    //           onClick={() => {
    //             onClickBoardTypeHandler('BETTING_RULE')
    //           }}
    //         >
    //           배팅규정
    //         </NoticeButton>
    //       </NoticeButtonBox>
    //     </NoticeButtonWrap>
    //     {loading ? (
    //       <CustomLoading />
    //     ) : (
    //       <MobileCustomerCenterContentWrap>
    //         <CustomerCenterContentTable>
    //           <CustomerCenterContentTableHead>
    //             <CustomerCenterContentTableHeadTr>
    //               <th style={{ width: '20%' }}>타입</th>
    //               <th style={{ width: '80%' }}>내용</th>
    //             </CustomerCenterContentTableHeadTr>
    //           </CustomerCenterContentTableHead>
    //           <CustomerCenterContentTableBody>
    //             {content.length === 0 ? (
    //               <NoContentDiv>
    //                 <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
    //                 최근 게시물이 없습니다.
    //               </NoContentDiv>
    //             ) : (
    //               <>
    //                 {content.map(item => (
    //                   <>
    //                     <CustomerCenterContentTableBodyTr key={item.boardKey}>
    //                       <CustomerCenterContentBox>
    //                         {/* <CustomerCenterContenItemBox width="10" onClick={() => onClickRowHandler(item.boardKey)}>
    //                     {item.num}
    //                   </CustomerCenterContenItemBox> */}
    //                         <CustomerCenterContenItemBox
    //                           width="20"
    //                           onClick={() => onClickRowHandler(item.boardKey)}
    //                           style={{ color: `${HermesMainHover2}` }}
    //                         >
    //                           {BoardTypeEnums[item.type]}
    //                         </CustomerCenterContenItemBox>
    //                         <CustomerCenterContenItemBox
    //                           width="80"
    //                           onClick={() => onClickRowHandler(item.boardKey)}
    //                           style={{ cursor: 'pointer', flexDirection: 'column' }}
    //                         >
    //                           {item.boardTitle}
    //                         </CustomerCenterContenItemBox>
    //                       </CustomerCenterContentBox>
    //                     </CustomerCenterContentTableBodyTr>
    //                     <CustomCollapse in={item.isVisible}>
    //                       <CustomerCenterContentTableBodyTr style={{ border: 'solid 1px #525252' }}>
    //                         <CustomerCenterContentDetailBox>
    //                           {ReactHtmlParser(item.boardContent, { transform: transformFn })}
    //                         </CustomerCenterContentDetailBox>
    //                       </CustomerCenterContentTableBodyTr>
    //                     </CustomCollapse>
    //                   </>
    //                 ))}
    //               </>
    //             )}
    //           </CustomerCenterContentTableBody>
    //         </CustomerCenterContentTable>
    //         {totalElement > 0 && (
    //           <MobileCustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
    //         )}
    //       </MobileCustomerCenterContentWrap>
    //     )}
    //   </div>
    // </MobileTopWrap>
  )
}
export default MobileBettingRuleDetail

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  color: #fff;
  img {
    width: 100px;
    height: auto;
  }
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    padding: 16px 0px !important;
    background: rgba(9, 9, 10);
    borde: none;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 12px;
  border-radius: 6px;

  &:hover {
    border: solid 1px #757575;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  justify-content: center;
  align-items: start;
  color: white;
  font-weight: 700;
  padding: 16px 5px;
  word-break: keep-all;
`

const NoticeButtonWrap = styled.div`
  margin-bottom: 16px;
  box-sizing: border-box;
  position: relative;
  display: block;
  margin-top: 10px;
`

const NoticeButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: 50%;
  box-sizing: border-box;
`

const NoticeButton = styled.div`
  width: 98%;
  padding: 8px 32px;

  background-color: #1b1c1f;
  float: left;
  padding: 12px 27px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
  margin-right: 3px;
  color: #fff;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s;

  &:hover {
    background-color: ${HermesMainHover};
  }

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainHover2};
`}

  margin: 0;
  box-sizing: border-box;
  display: block;
`
