export const gameTypeEnums = {
  SLOT: '슬롯',
  LIVE: '라이브',
}

export const GameTypeEnumsSelectOptions = () => {
  return Object.entries(gameTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const gameStatusEnums = {
  NORMAL: '정상',
  HIDDEN: '숨김',
  INSPECTION: '점검',
}

export const GameStatusEnumsSelectOptions = () => {
  return Object.entries(gameStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const GameStatusEnumsRadioOptions = () => {
  return Object.entries(gameStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'gameStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'gameStatus',
          label: value,
          radioValue: key,
        },
  )
}
