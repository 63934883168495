export const SocketIOEventName = {
  CREATE: 'CREATE',
  FIND: 'FIND',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',

  DUPLICATE_LOGIN: 'DUPLICATE_LOGIN',
  LOST_SESSION: 'LOST_SESSION',
  REFRESH_SESSION: 'REFRESH_SESSION',
  UPDATED_SETTING: 'UPDATED_SETTING',

  REFRESH_SPORTS_DATA: 'REFRESH_SPORTS_DATA',

  PRE_MATCH_FIXTURE_UPDATED: 'PRE_MATCH_FIXTURE_UPDATED',
  PRE_MATCH_LIVE_SCORE_UPDATED: 'PRE_MATCH_LIVE_SCORE_UPDATED',
  PRE_MATCH_MARKET_UPDATED: 'PRE_MATCH_MARKET_UPDATED',
  IN_PLAY_FIXTURE_UPDATED: 'IN_PLAY_FIXTURE_UPDATED',
  IN_PLAY_LIVE_SCORE_UPDATED: 'IN_PLAY_LIVE_SCORE_UPDATED',
  IN_PLAY_MARKET_UPDATED: 'IN_PLAY_MARKET_UPDATED',
}
