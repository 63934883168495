import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import CustomLoading from '../../../containers/CustomLoading'
import MobileSportsBettingCartSidebar from '../../MobileLayout/MobileSportsBettingCartSidebar'
import MobileSportsOverseasContent from './MobileSportsOverseasContent'
import MobileSportsOverseasHeader from './MobileSportsOverseasHeader'
import { setSelectedSportId } from '../../../redux/sportsOverseasInfoSlice'

const MoblieSportsOverseasPage = () => {
  const { overseasLoading, bettingCartLoading } = useSelector(state => {
    const { sportsOverseasInfo, sportsBetting } = state

    return {
      overseasLoading: sportsOverseasInfo.overseasLoading,
      bettingCartLoading: sportsBetting.bettingCartLoading,
    }
  })
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(setSelectedSportId(-1))
    }
  }, [])

  return (
    <>
      {(overseasLoading || bettingCartLoading) && (
        <SportsPageLoadingOverlay>
          <CustomLoading isGameStart info={bettingCartLoading ? '배팅 처리중입니다.' : ''} />
        </SportsPageLoadingOverlay>
      )}
      <div style={{ padding: 0 }}>
        <MobileSportsOverseasHeader />
        <MobileSportsOverseasContent />

        <div style={{ height: '100px' }} />
        <MobileSportsBettingCartSidebar />
      </div>
    </>
  )
}

export default MoblieSportsOverseasPage

const SportsPageLoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`
