import { commonReg2, commonReg6, commonReg7 } from './commonValidate'

export const MoneyDepositValidate = values => {
  const errors = {}

  if (!Number(values.applyMoney?.toString().replaceAll(',', ''))) {
    errors.applyMoney = '숫자만 입력해주세요.'
  } else if (
    !Number(values.applyMoney?.toString().replaceAll(',', '')) ||
    Number(values.applyMoney?.toString().replaceAll(',', '')) < Number(values.minimumDeposit)
  ) {
    errors.applyMoney = `최소 ${values.minimumDeposit
      .toString()
      .replace(commonReg2, ',')}원 이상 입금신청이 가능합니다.`
  }

  return errors
}

export const CouponUseValidate = values => {
  const errors = {}

  if (
    !Number(values.applyMoney?.toString().replaceAll(',', '')) ||
    Number(values.applyMoney?.toString().replaceAll(',', '')) === 0
  ) {
    errors.applyMoney = '쿠폰을 먼저 선택해주세요.'
  }

  return errors
}

export const MoneyWithdrawalValidate = values => {
  const errors = {}
  if (!Number(values.applyMoney?.toString().replaceAll(',', ''))) {
    errors.applyMoney = '숫자만 입력해주세요.'
  } else if (
    !Number(values.applyMoney?.toString().replaceAll(',', '')) ||
    Number(values.applyMoney?.toString().replaceAll(',', '')) < Number(values.minimumWithdrawal)
  ) {
    errors.applyMoney = `최소 ${values.minimumWithdrawal
      .toString()
      .replace(commonReg2, ',')}원 이상 출금신청이 가능합니다.`
  }

  return errors
}

export const MoneyExchangeValidate = values => {
  const errors = {}

  if (
    values.toGameMoney &&
    Number(values.holdingMoney?.toString().replaceAll(',', '')) <
      Number(values.toGameMoney?.toString().replaceAll(',', ''))
  ) {
    errors.toGameMoney = '지갑머니를 초과한 금액입니다.'
  }

  if (
    values.toWalletMoney &&
    Number(values.selectedGameMoney?.toString().replaceAll(',', '')) <
      Number(values.toWalletMoney?.toString().replaceAll(',', ''))
  ) {
    errors.toWalletMoney = '선택한 게임 머니를 초과한 금액입니다.'
  }

  return errors
}

export const CompExchangeValidate = values => {
  const errors = {}

  if (!commonReg7.test(values.applyComp?.toString().replaceAll(',', ''))) {
    errors.applyComp = '1,000원 단위로 전환이 가능합니다.'
  }

  if (
    commonReg7.test(values.applyComp?.toString().replaceAll(',', '')) &&
    values.applyComp?.substr(values.applyComp.length - 3, 3) !== '000'
  ) {
    errors.applyComp = '1,000원 단위로 전환이 가능합니다.'
  }

  if (
    commonReg7.test(values.applyComp?.toString().replaceAll(',', '')) &&
    values.applyComp.substr(values.applyComp.length - 3, 3) === '000' &&
    Number(values?.compAmount?.toString().replaceAll(',', '')) < Number(values.applyComp)
  ) {
    errors.applyComp = '콤프 총 잔액이하로 전환이 가능합니다.'
  }

  return errors
}
