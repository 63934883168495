import React, { useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { FaChevronLeft, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import { deleteNoteList, updateNoteStatus, updateNoteStatusAll } from '../../../api/customCenter/customCenterApi'
import NoteRadio from '../../../containers/CustomCenter/Note/NoteRadio'
import CustomLoading from '../../../containers/CustomLoading'
import { fetchNoteList, setCustomerLoading } from '../../../redux/customCenterSlice'
import { onClickTitleHandler } from '../../../redux/modalSlice'
import Badge from '../../../shared/components/Badge'
import MobileCustomPagination from '../../../shared/components/MobileCustomPagination'
import {
  ContentInfo,
  ContentRowSmall,
  ContentRowWrap,
  ContentWrap,
  CustomButtonInfo,
  SignUpModalCloseButton,
  SignUpModalContentWrap,
  SignUpModalHeader,
  SignUpModalHeaderLogo,
  SignUpModalInner,
  SignUpModalOverlay,
  SignUpModalWrapper,
  TableBodyTwoWrap,
} from '../../../shared/components/MobileMoneyCustomElement'
import { CustomCollapse, MobileListButtonWrap, MobileTopWrap } from '../../../shared/components/MoneyCustomElement'
import Portal from '../../../shared/components/Portal'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { NoteReadStatusEnums } from '../../../utils/enums/Operation/NoteEnums'
import { HermesMainBorder, HermesMainText, colorMainText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { MobileNoContentBox } from '../MobileEvent/MobileEventList'
import { fetchNoteSize } from '../../../redux/memberInfoSlice'
import MobileNoticeDetail from '../MobileNotice/MobileNoticeDetail'
import MobileNoteDetail from './MobileNoteDetail'

const MobileNoteList = ({ visible, onClose }) => {
  const [isShowNoteContent, setIsShowNoteContent] = useState(false)
  const dispatch = useDispatch()
  const [noteSize, setNoteSize] = useState(0)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [noteParams, setNoteParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setNoteParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState([])
  const { customCenterLoading, totalElement, apiContent, noReadSize, holdingMoney, memberTotalGameMoney } = useSelector(
    state => {
      const { customCenter, memberInfo } = state

      return {
        customCenterLoading: customCenter.customCenterLoading,
        totalElement: customCenter.noteInfo.totalElement,
        apiContent: customCenter.noteInfo.content,
        noReadSize: customCenter.noteInfo.noReadSize,
        holdingMoney: memberInfo.memberHoldingMoney,
        memberTotalGameMoney: memberInfo.memberTotalGameMoney,
      }
    },
  )

  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.noteKey === item.noteKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchNoteList = async () => {
    dispatch(setCustomerLoading(true))
    try {
      dispatch(fetchNoteList(noteParams))
      setLoading(false)
    } catch (error) {
      dispatch(setCustomerLoading(false))
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchNoteList()
  }, [noteParams])

  const [checkRadio, setCheckRadio] = useState([])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = noteKey => {
    const idx = content.findIndex(item => item.noteKey === noteKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })

      const idx2 = content.findIndex(item => item.noteKey === noteKey)
      if (idx2 !== -1 && content[idx2]?.status === 'NOREAD') {
        updateNoteStatus({
          noteKey: content[idx2].noteKey,
        }).then(res => {
          fetchSearchNoteList()
          dispatch(fetchNoteSize())
        })
      }
    }
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = noteKey => {
    if (deleteLoading) return
    // if (checkRadio.length === 0) {
    //   alert('삭제 할 쪽지를 선택해주세요.')
    //   return
    // }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteNoteList({
        noteKeyList: [noteKey],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          fetchSearchNoteList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          fetchSearchNoteList()
        })
    }
  }

  const [readLoading, setReadLoading] = useState(false)
  // 삭제하기 버튼
  const onReadHandler = () => {
    if (readLoading) return

    setReadLoading(true)
    updateNoteStatusAll({})
      .then(res => {
        setReadLoading(false)
        dispatch(fetchNoteSize())
        fetchSearchNoteList()
      })
      .catch(error => {
        setReadLoading(false)
        dispatch(fetchNoteSize())
        fetchSearchNoteList()
      })
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
    }
  }
  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [showDetail, setShowDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleRowClick = boardKey => {
    const idx = content.findIndex(item => item.noteKey === boardKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })

      const idx2 = content.findIndex(item => item.noteKey === boardKey)
      if (idx2 !== -1 && content[idx2]?.status === 'NOREAD') {
        updateNoteStatus({
          noteKey: content[idx2].noteKey,
        }).then(res => {
          fetchSearchNoteList()
          dispatch(fetchNoteSize())
        })
      }
    }
    const boardContent = content.find(item => item.noteKey === boardKey)
    if (!boardContent) return
    setSelectedItem(boardContent)
    setShowDetail(true)
  }

  return (
    <>
      {customCenterLoading && <CustomLoading />}
      {showDetail ? (
        <MobileNoteDetail notice={selectedItem} onBack={() => setShowDetail(false)} />
      ) : (
        <div style={{ padding: '0px 0 0px 0' }}>
          <div id="hiddenBar" style={{ background: '#fff', boxShadow: '0px 7px 6px -5px #DFE1E5;' }}>
            <div className="header">
              <div style={{ height: '3px' }} />
              <table
                style={{
                  padding: '0px',
                  border: '0px',
                  borderCollapse: 'collapse',
                  borderSpacing: '0px',
                  width: '100%',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: '36px' }}>
                      <img
                        alt=""
                        style={{ width: '36px', height: '36px' }}
                        src="/m/images/menu_btn.png?v=1"
                        //  onclick="showMenuOn();"
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <a href="/m/Default.aspx">
                        <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                      </a>
                    </td>
                    <td style={{ width: '36px' }}>
                      <img
                        style={{ width: '36px', height: '36px' }}
                        alt=""
                        src="/m/images/icons8-refresh-64.png?v=4"
                        //  onClick="window.location.reload();"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ height: '2px' }} />
            </div>
          </div>

          <div className="body_title">
            <span className="title1">
              <strong>쪽지함</strong>
            </span>
            <span className="title2">
              <strong>&nbsp;Message</strong>
            </span>
          </div>
          <div style={{ height: '20px' }} />
          <div className="board_wrap">
            <div className="board_list">
              <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="board_list_table2">
                <tbody>
                  {content.length === 0 ? (
                    <tr>
                      <td>쪽지 내역이 없습니다.</td>
                    </tr>
                  ) : (
                    <>
                      {content.map(item => (
                        <>
                          <tr onClick={() => handleRowClick(item.noteKey)}>
                            <td
                              className="board_list_td"
                              style={{ fontSize: '13px' }}
                              //  onClick="go_message_read('4601');"
                            >
                              <tr>
                                <td
                                  className="board_list_td"
                                  style={{ fontSize: '13px', border: 'none' }}
                                  //  onclick="go_message_read('4601');"
                                >
                                  <span style={{ color: '#656565' }}>
                                    {convertToKstByFormat(item.sendDate, 'MM-DD HH:mm')}
                                    <span>
                                      <br />
                                      <span style={{ color: '#656565' }}>
                                        <b>[{NoteReadStatusEnums[item.status]}]</b>
                                        <span>
                                          <span style={{ color: '#656565' }}>
                                            <b style={{ color: 'rgb(0,0,0)', fontWeight: '900' }}>★Zeus★&nbsp;</b>
                                            <span style={{ color: 'rgb(0,0,0)', fontWeight: '500' }}>
                                              &nbsp;{item.noteTitle}
                                              {item.status === 'READ' && (
                                                <span>
                                                  &nbsp;&nbsp;&nbsp;
                                                  <span style={{ float: 'right' }}>
                                                    <a
                                                      onClick={() => {
                                                        onDeleteHandler(item.noteKey)
                                                      }}
                                                      className="bt_type1"
                                                      style={{
                                                        width: '50px',
                                                        color: 'black',
                                                        fontSize: '11px',
                                                        fontWeight: '100',
                                                      }}
                                                    >
                                                      {' '}
                                                      삭제하기
                                                    </a>
                                                  </span>
                                                </span>
                                              )}
                                            </span>
                                            <span />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ height: '20px' }} />
          </div>
          <>
            <MobileCustomPagination
              currentPage={page}
              size={size}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </>
          {/* <div className="pageing_wrap">
    <table align="center" cellpadding="0" cellspacing="5" border="0" width="100%" className="pageing_table">
      <tbody>
        <tr>
          <td width="50%">
            <a href="message.aspx?page=1">◀ 이전10개</a>
            </td>
            <td width="50%">
              <a href="message.aspx?page=1">다음10개 ▶</a></td></tr></tbody></table>
              </div> */}
        </div>
      )}
    </>
    // <Portal elementId="signup-modal">
    //       <SignUpModalOverlay visible={visible} />
    //       <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
    //         <SignUpModalInner tabIndex="0">
    //           <SignUpModalHeader>
    //             <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
    //             <SignUpModalCloseButton
    //               onClick={close}
    //               src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
    //               alt=""
    //             />
    //           </SignUpModalHeader>
    //           <SignUpModalContentWrap>
    //             <MobileTopWrap ref={webPageWrapRef}>
    //               <ContentWrap>
    //                 <ContentRowWrap>
    //                   <FaChevronLeft
    //                     onClick={e => {
    //                       dispatch(onClickTitleHandler('MYPAGE'))
    //                     }}
    //                   />
    //                   <ContentRowSmall>
    //                     <div>
    //                       <span style={{ color: '#b2b2b2' }}>스포츠 캐쉬</span>
    //                       <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
    //                         {holdingMoney?.toString().replace(commonReg2, ',')} 원
    //                       </span>
    //                     </div>
    //                   </ContentRowSmall>
    //                   <ContentRowSmall>
    //                     <div>
    //                       <span style={{ color: '#b2b2b2' }}>카지노 캐쉬</span>
    //                       <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
    //                         {memberTotalGameMoney?.toString().replace(commonReg2, ',')} 원
    //                       </span>
    //                     </div>
    //                   </ContentRowSmall>
    //                 </ContentRowWrap>
    //                 <div style={{ padding: '8px' }}>
    //                   <ContentRowWrap>
    //                     <CustomButtonInfo>
    //                       쪽지함 {!noteSize ? '' : <MobileBadge bg="danger">NEW</MobileBadge>}
    //                     </CustomButtonInfo>
    //                     <CustomButtonWrap>
    //                       <CustomDeleteButton
    //                         onClick={() => {
    //                           setCheckRadio(content.map(value => value.noteKey))
    //                         }}
    //                         type="button"
    //                       >
    //                         <CustomButtonInfos>전체선택</CustomButtonInfos>
    //                       </CustomDeleteButton>
    //                       <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
    //                         <FaTrash />
    //                         <CustomButtonInfos>삭제하기</CustomButtonInfos>
    //                       </CustomDeleteButton>
    //                       <CustomDeleteButton
    //                         style={{ background: `${HermesMainBorder}` }}
    //                         onClick={() => {
    //                           onReadHandler()
    //                         }}
    //                         variant="primary"
    //                         type="button"
    //                       >
    //                         <CustomButtonInfos2 style={{ color: 'black' }}>전체읽기</CustomButtonInfos2>
    //                       </CustomDeleteButton>
    //                     </CustomButtonWrap>
    //                   </ContentRowWrap>
    //                   <ContentInfo>
    //                     {loading || deleteLoading ? (
    //                       <CustomLoading />
    //                     ) : (
    //                       <TableBodyTwoWrap>
    //                         <CustomerCenterContentTable>
    //                           <CustomerCenterContentTableHead>
    //                             <CustomerCenterContentTableHeadTr>
    //                               <th style={{ width: '10%' }}>선택</th>
    //                               <th style={{ width: '50%' }}>내용</th>
    //                               <th style={{ width: '25%' }}>수신일</th>
    //                               <th style={{ width: '15%' }}>상태</th>
    //                             </CustomerCenterContentTableHeadTr>
    //                           </CustomerCenterContentTableHead>
    //                           <CustomerCenterContentTableBody>
    //                             {content.length === 0 ? (
    //                               <NoContentDiv>
    //                                 <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
    //                                 최근 게시물이 없습니다.
    //                               </NoContentDiv>
    //                             ) : (
    //                               <>
    //                                 {content.map(item => (
    //                                   <>
    //                                     <CustomerCenterContentTableBodyTr key={item.noteKey}>
    //                                       <CustomerCenterContentBox>
    //                                         <CustomerCenterContenItemBox width="10">
    //                                           <NoteRadio
    //                                             rowInfo={{
    //                                               noteKey: item.noteKey,
    //                                               status: item.status,
    //                                             }}
    //                                             setCheckRadio={setCheckRadio}
    //                                             checkRadio={checkRadio}
    //                                           />
    //                                         </CustomerCenterContenItemBox>
    //                                         <CustomerCenterContenItemBox
    //                                           width="50"
    //                                           onClick={() => onClickRowHandler(item.noteKey)}
    //                                           style={{ cursor: 'pointer' }}
    //                                         >
    //                                           {item.noteTitle}
    //                                         </CustomerCenterContenItemBox>
    //                                         <CustomerCenterContenItemBox
    //                                           width="25"
    //                                           onClick={() => onClickRowHandler(item.noteKey)}
    //                                         >
    //                                           {convertToKstByFormat(item.sendDate, 'MM-DD HH:mm')}
    //                                         </CustomerCenterContenItemBox>
    //                                         <CustomerCenterContenItemBox
    //                                           width="15"
    //                                           onClick={() => onClickRowHandler(item.noteKey)}
    //                                         >
    //                                           {NoteReadStatusEnums[item.status]}
    //                                         </CustomerCenterContenItemBox>
    //                                       </CustomerCenterContentBox>
    //                                     </CustomerCenterContentTableBodyTr>
    //                                     <CustomCollapse in={item.isVisible}>
    //                                       <CustomerCenterContentTableBodyTr style={{ border: 'solid 1px #525252' }}>
    //                                         <CustomerCenterContentDetailBox>
    //                                           {ReactHtmlParser(item.sendContent, { transform: transformFn })}
    //                                         </CustomerCenterContentDetailBox>
    //                                       </CustomerCenterContentTableBodyTr>
    //                                     </CustomCollapse>
    //                                   </>
    //                                 ))}
    //                               </>
    //                             )}
    //                           </CustomerCenterContentTableBody>
    //                         </CustomerCenterContentTable>
    //                         {totalElement > 0 && (
    //                           <MobileCustomPagination
    //                             currentPage={page}
    //                             totalPages={totalPages}
    //                             onPageChange={onPageChange}
    //                           />
    //                         )}
    //                       </TableBodyTwoWrap>
    //                     )}
    //                   </ContentInfo>
    //                 </div>
    //               </ContentWrap>
    //             </MobileTopWrap>
    //           </SignUpModalContentWrap>
    //         </SignUpModalInner>
    //       </SignUpModalWrapper>
    //     </Portal>
  )
}

export default MobileNoteList

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  color: #fff;
  img {
    width: 100px;
    height: auto;
  }
`

export const CustomButtonWrap = styled(MobileListButtonWrap)`
  padding: 0px 10px;
`

export const CustomButtonInfos = styled(CustomButtonInfo)``

export const CustomButtonInfos2 = styled(CustomButtonInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  padding: 0px 8px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    color: grey;
  }
`

export const MobileOneToOneHeaderWrap = styled.div`
  padding: 10px 0 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
`

export const MobileOnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 13px;
  opacity: 0.7;
  color: ${colorMainText};
`

export const MobileBadge = styled(Badge)`
  width: 50px;
  height: 20px;
  padding-top: 5px;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    padding: 16px 0px !important;
    background: rgba(9, 9, 10);
    borde: none;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;

  margin-top: 12px;
  border-radius: 6px;

  &:hover {
    border: solid 1px #757575;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
  font-weight: 400;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  padding: 16px 5px;
`
