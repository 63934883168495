import moment from 'moment'
import 'moment/locale/ko'
import React, { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'
import { AiOutlineMenu } from 'react-icons/ai'
import { IoVolumeHighSharp, IoVolumeMute } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { searchPartnerUrl } from '../../api/authManage/authManageApi'
import { createReloadCheckLog } from '../../api/reloadCheck/reloadCheck'
import { fetchMemberInfo, fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { toggleLeftMenuSidebarShow } from '../../redux/modalSlice'
import { handleDeleteAuthentication } from '../../socketio/userAuthSocketIOHandler'
import isLogin, { setRecommendCode } from '../../utils/enums/authority'
import {
  HermesMainBorder,
  HermesMainHover2,
  HermesMainText,
  KyochonMainBorder,
  KyochonMainSelect,
  KyochonMainText,
} from '../../utils/palette'
import { decodeAccessToken, deleteAccessToken, getAccessToken } from '../../utils/token'
import CustomAlert from '../CustomAlert'
import AuthorityAlertPopup from '../Popup/Error/AuthorityAlertPopup'
import SignInModal from './SignInModal'
import SignUpModal from './SignUpModal'
import CustomLoading from '../CustomLoading'
import { commonReg2 } from '../../utils/validate/commonValidate'
import { withdrawCasinoMoney } from '../../api/memberManage/memberManageApi'
import { setSelectedBalanceSportId } from '../../redux/sportsDomesticInfoSlice'

const Header = () => {
  const loginCheck = isLogin()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const pathName = location?.pathname
  const [currentTime, setCurrentTime] = useState(moment().tz('Asia/Seoul'))

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().tz('Asia/Seoul'))
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  const { isShowLeftMenuSidebar } = useSelector(state => {
    const { modalInfo } = state

    return {
      isShowLeftMenuSidebar: modalInfo.isShowLeftMenuSidebar,
    }
  })

  const {
    nickName,
    oneToOneRedisSize,
    noteRedisSize,
    reloadCount,
    reloadTime,
    realTimeDepositList,
    realTimeWithdrawalList,
    domesticSportsUsing,
    domesticSportsName,
    overseasSportsUsing,
    overseasSportsName,
    specialSportsUsing,
    specialSportsName,
    liveSportsUsing,
    liveSportsName,
    welcomeInfo,
    attendanceUsing,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      nickName: memberInfo.nickName,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      noteRedisSize: memberInfo.noteRedisSize,
      reloadCount: memberInfo.reloadCount,
      reloadTime: memberInfo.reloadTime,
      realTimeDepositList: memberInfo.realTimeDepositList,
      realTimeWithdrawalList: memberInfo.realTimeWithdrawalList,
      domesticSportsUsing: memberInfo.domesticSportsUsing,
      domesticSportsName: memberInfo.domesticSportsName,
      overseasSportsUsing: memberInfo.overseasSportsUsing,
      overseasSportsName: memberInfo.overseasSportsName,
      specialSportsUsing: memberInfo.specialSportsUsing,
      specialSportsName: memberInfo.specialSportsName,
      liveSportsUsing: memberInfo.liveSportsUsing,
      liveSportsName: memberInfo.liveSportsName,
      welcomeInfo: memberInfo.welcomeInfo,
      attendanceUsing: memberInfo.attendanceUsing,
    }
  })

  const { memberLevel, holdingMoney, pointAmount, totalGameMoney, userId, userName, bankName, accountNum } =
    useSelector(state => {
      const { memberInfo } = state

      return {
        memberLevel: memberInfo.depositLevel,
        holdingMoney: memberInfo.memberHoldingMoney,
        totalGameMoney: memberInfo.memberTotalGameMoney,
        pointAmount: memberInfo.memberHoldingPoint,
        userId: memberInfo.userId,
        userName: memberInfo.userName,
        bankName: memberInfo.bankName,
        accountNum: memberInfo.accountNum,
      }
    })

  // 파트너 추천 코드 용 쿼리 스트링
  const [recParams] = useSearchParams()

  useEffect(() => {
    const rec = recParams.get('rec')

    if (!rec) {
      return
    }
    setRecommendCode(rec)
  }, [recParams])

  useEffect(() => {
    if (!isLogin()) return

    dispatch(fetchMemberInfoAction())

    const intervalId = setInterval(() => {
      dispatch(fetchMemberInfoAction())
    }, 5000)

    return () => {
      clearInterval(intervalId) // 컴포넌트 언마운트 시 인터벌 정리
    }
  }, [loginCheck])

  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
  }

  const onClickSignOutHandler = () => {
    handleDeleteAuthentication({
      accessToken: getAccessToken(),
    }).then(res => {
      deleteAccessToken()
      window.location.href = '/'
    })
  }

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onShowSignUpModal = () => {
    setSignUpModalShow(true)
  }
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const selectedSportIdHandler = sportId => {
    dispatch(setSelectedBalanceSportId(sportId))
  }

  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    navigate(link)
  }

  const onClickPartnerUrlHandler = () => {
    searchPartnerUrl().then(res => {
      const { partnerUrl } = res.data

      if (partnerUrl.includes('localhost')) {
        window.open(`http://${partnerUrl}`)
      } else {
        window.open(`https://${partnerUrl}`)
      }
    })
  }

  const changeButton = () => {
    dispatch(toggleLeftMenuSidebarShow(!isShowLeftMenuSidebar))
  }
  const [createLogLoading, setCreateLogLoading] = useState(false)

  useEffect(() => {
    if (!isLogin()) return

    if (reloadCount === 0 || reloadTime === 0) return

    const navigationEntries = performance.getEntriesByType('navigation')
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
      // 상태 업데이트
      const timestamps = JSON.parse(sessionStorage.getItem('timestamps') || '[]')
      timestamps.push(Date.now())

      // 관리자에서 설정한 시간 차이 ex) 1분
      const diffMinuteAgo = Date.now() - reloadTime

      const filteredTimestamps = timestamps.filter(timestamp => timestamp > diffMinuteAgo)

      // sessionStorage 업데이트
      sessionStorage.setItem('timestamps', JSON.stringify(filteredTimestamps))

      // 1분 동안의 새로고침 횟수가 10번을 초과하면 악의적인 새로고침으로 판단
      if (filteredTimestamps.length > reloadCount) {
        sessionStorage.setItem('timestamps', JSON.stringify([]))
        if (createLogLoading) return
        setCreateLogLoading(true)
        createReloadCheckLog()
          .then(res => {
            setCreateLogLoading(false)
          })
          .catch(error => {
            setCreateLogLoading(false)
          })
      }
    }
  }, [reloadCount, reloadTime]) // 의존성 배열을 빈 배열로 변경

  const [gameLoading, setGameLoading] = useState(false)

  const holdemGameStartHandler = game => {
    if (!isLogin()) {
      setSignInModalShow(true)
      return
    }

    alert('현재 점검중입니다.')

    // if (gameLoading) return

    // setGameLoading(true)
    // searchHoldemGameUrl()
    //   .then(res => {
    //     const { gameUrl } = res.data

    //     window.open(
    //       `${gameUrl}`,
    //       '',
    //       `height=${window.screen.height}, width=${window.screen.width}, location=no, status=no, scrollbars=yes`,
    //     )
    //     setGameLoading(false)
    //   })
    //   .catch(error => {
    //     setGameLoading(false)
    //   })
  }

  const handleMouseOver = event => {
    event.target.src = `${process.env.PUBLIC_URL}/img/menu/mn_icon1_11.png`
  }

  const handleMouseOut = event => {
    event.target.src = `${process.env.PUBLIC_URL}/img/menu/mn_icon1_1.png`
  }

  const [skyparkShow, setSkyparkShow] = useState(false)
  const [miniGameShow, setMiniGameShow] = useState(false)
  const [liveCasinoShow, setLiveCasinoShow] = useState(false)
  const [slotCasinoShow, setSlotCasinoShow] = useState(false)
  const [domesticShow, setDomesticShow] = useState(false)
  const [overseasShow, setOverseasShow] = useState(false)
  const [specialShow, setSpecialShow] = useState(false)
  const [liveShow, setLiveShow] = useState(false)
  const [moreShow, setMoreShow] = useState(false)

  const [alertPopupShow, setAlertPopupShow] = useState(false)
  // 경고창 용 팝업
  const [alertPopupMessage, setAlertPopupMessage] = useState('')

  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
    setAlertPopupMessage('')
  }

  const onChangeAlertPopupMessageHandler = text => {
    setAlertPopupShow(true)
    setAlertPopupMessage(text)
  }
  const [loading, setLoading] = useState(false)
  const [moneyExchangeLoading, setMoneyExchangeLoading] = useState(false)
  const onClickCasinoMoneyWithdrawHandler = () => {
    if (!isLogin()) {
      return
    }
    if (loading) {
      onChangeAlertPopupMessageHandler('카지노 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    if (totalGameMoney <= 0) return

    setLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfoAction())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'GAME-1014':
            onChangeAlertPopupMessageHandler('카지노 머니 동기화중입니다. \n잠시후에 다시 시도해주세요.')
            break
          case 'ASSET-7001':
            onChangeAlertPopupMessageHandler('잠시후 다시 시도해주세요.')
            break
          default:
            onChangeAlertPopupMessageHandler('처리 실패했습니다.')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getLevelIconUrl = () => {
    switch (true) {
      case memberLevel === 'ONE':
        return `${process.env.PUBLIC_URL}/img/level/01.png`
      case memberLevel === 'TWO':
        return `${process.env.PUBLIC_URL}/img/level/02.png`
      case memberLevel === 'THREE':
        return `${process.env.PUBLIC_URL}/img/level/03.png`
      case memberLevel === 'FOUR':
        return `${process.env.PUBLIC_URL}/img/level/04.png`
      case memberLevel === 'FIVE':
        return `${process.env.PUBLIC_URL}/img/level/05.png`
      case memberLevel === 'VIP1':
        return `${process.env.PUBLIC_URL}/img/level/06.png`
      case memberLevel === 'VIP2':
        return `${process.env.PUBLIC_URL}/img/level/07.png`
      case memberLevel === 'VIP3':
        return `${process.env.PUBLIC_URL}/img/level/08.png`
      default:
        return ''
    }
  }
  return (
    <>
      {(loading || moneyExchangeLoading) && <CustomLoading />}
      {alertPopupShow && <CustomAlert info={alertPopupMessage} onClickCloseHandler={onCloseAlertPopup} />}
      <div className="top_menu_line">
        <div className="div_top_user_info">
          <table style={{ height: '30px' }}>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center', display: 'flex' }}>
                  <img
                    src={getLevelIconUrl()}
                    alt=""
                    align="absmiddle"
                    style={{ marginRight: '5px', marginTop: '2px', width: '32px', height: 'auto' }}
                  />
                </td>
                <td style={{ textAlign: 'left' }}>
                  <b id="user_nick" style={{ color: '#fff' }}>
                    {nickName}
                  </b>{' '}
                  님
                </td>
                <td style={{ color: '#aaa' }}>&nbsp;|&nbsp;</td>
                <td style={{ width: '90px', textAlign: 'center' }}>
                  <a href="/note" style={{ textDecoration: 'none', color: '#fff' }}>
                    쪽지함 :{' '}
                    <b id="note_count" style={{ color: '#fff' }}>
                      {noteRedisSize}
                    </b>
                    <span>통</span>
                  </a>
                </td>
                <td style={{ color: '#aaa' }}>&nbsp;|&nbsp;</td>
                <td>
                  보유금액 :{' '}
                  <b id="user_money" style={{ color: '#fff' }}>
                    {holdingMoney?.toString().replace(commonReg2, ',')}
                  </b>{' '}
                  <b>원</b>
                </td>
                <td style={{ color: '#aaa' }}>&nbsp;|&nbsp;</td>
                <td>
                  카지노머니 :{' '}
                  <b id="user_money" style={{ color: '#fff' }}>
                    {totalGameMoney?.toString().replace(commonReg2, ',')}
                  </b>{' '}
                  <b>원</b>
                  <a onClick={onClickCasinoMoneyWithdrawHandler} style={{ marginLeft: '10px', color: '#fff' }}>
                    [회수]
                  </a>
                </td>

                <td style={{ color: '#aaa' }}>&nbsp;|&nbsp;</td>
                <td>
                  포인트 :{' '}
                  <b id="user_point" style={{ color: '#fff' }}>
                    {pointAmount?.toString().replace(commonReg2, ',')}
                  </b>{' '}
                  <b>ⓟ</b>
                </td>
                <td style={{ width: '80px', textAlign: 'center' }}>
                  <a
                    onClick={() => {
                      if (pathName === '/money/pointexchange') {
                        window.location.href = '/money/pointexchange'
                        return
                      }
                      navigate('/money/pointexchange')
                    }}
                    style={{ color: '#fff' }}
                  >
                    [포인트사용]
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ float: 'right', marginRight: '10px' }}>
          <table style={{ height: '30px', verticalAlign: 'middle', textAlign: 'center' }}>
            <tbody>
              <tr>
                {decodeAccessToken()?.auth === 'PARTNER' && (
                  <>
                    <td style={{ width: 'max-content' }}>
                      <a onClick={onClickPartnerUrlHandler} style={{ color: '#fff' }}>
                        파트너페이지
                      </a>
                    </td>
                    <td style={{ color: '#aaa' }}>&nbsp;|&nbsp;</td>
                  </>
                )}
                <td style={{ width: '55px' }}>
                  <a
                    onClick={() => {
                      if (pathName === '/mypage') {
                        window.location.href = '/mypage'
                        return
                      }
                      navigate('/mypage')
                    }}
                    style={{ color: '#fff' }}
                  >
                    회원정보
                  </a>
                </td>
                <td style={{ color: '#aaa' }}>&nbsp;|&nbsp;</td>
                {/* <td style={{ width: '55px' }}>
                  <a
                    onClick={() => {
                      navigate('/betting-history')
                    }}
                  >
                    배팅내역
                  </a>
                </td>
                <td style={{ color: '#aaa' }}>&nbsp;|&nbsp;</td>
                <td style={{ width: '55px' }}>
                  <a
                    onClick={() => {
                      navigate('/money/moneyexchange')
                    }}
                  >
                    머니내역
                  </a>
                </td>
                <td style={{ color: '#aaa' }}>&nbsp;|&nbsp;</td>
                <td style={{ width: '55px' }}>
                  <a
                    onClick={() => {
                      navigate('/friend-list')
                    }}
                  >
                    추천내역
                  </a>
                </td>
                <td style={{ color: '#aaa' }}>&nbsp;|&nbsp;</td>
                <td style={{ width: '55px' }}>
                  <a
                    onClick={() => {
                      navigate('/one-to-one')
                    }}
                  >
                    고객센터
                  </a>
                </td> */}
                {/* <td style={{ color: '#aaa' }}>&nbsp;|&nbsp;</td> */}
                <td>
                  <a
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}
                    onClick={() => {
                      onClickSignOutHandler()
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/menu/mn_icon1_1.png`}
                      alt=""
                      style={{ height: '24px', marginTop: '-5px' }}
                      onMouseOut={handleMouseOut}
                      onMouseOver={handleMouseOver}
                      onBlur={handleMouseOut}
                      onFocus={handleMouseOver}
                    />
                    <span style={{ color: '#fff' }}>로그아웃</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ height: '20px' }} />
      {/* 공지 */}
      <div>
        <table>
          <tbody>
            <tr>
              <td style={{ width: '10px' }} />
              <td style={{ width: '150px' }}>
                <a
                  onClick={() => {
                    if (pathName === '/main') {
                      window.location.href = '/main'
                      return
                    }
                    navigate('/main')
                  }}
                >
                  <img style={{ width: '150px' }} alt="" src={`${process.env.PUBLIC_URL}/img/logo/logo.png`} />
                </a>
              </td>
              <td style={{ width: '20px' }} />
              <td>
                <div style={{ height: '34px', width: '600px', border: '1px solid #ccc', borderRadius: '20px' }}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '580px' }}>
                          <div style={{ padding: '5px 0px' }}>
                            <div style={{ border: '0px solid #755b45', height: '24px', lineHeight: '24px' }}>
                              <div style={{ float: 'right', height: '24px' }}>
                                <img
                                  alt=""
                                  src={`${process.env.PUBLIC_URL}/img/menu/icon_voice.png`}
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                    marginRight: '10px',
                                    display: 'block',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    alert('안녕하세요. 제우스입니다.')
                                  }}
                                />
                              </div>
                              <div style={{ float: 'left', width: '515px', marginLeft: '10px', fontWeight: 'bold' }}>
                                <Marquee>{welcomeInfo}</Marquee>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* 메뉴 */}
      <div className="menu_bar">
        <table>
          <tbody>
            <tr>
              {domesticSportsUsing && (
                <td>
                  <a
                    id="b_mn_4"
                    className="menu_style"
                    onClick={() => {
                      if (pathName === '/sports/domestic') {
                        window.location.href = '/sports/domestic'
                        return
                      }
                      navigate('/sports/domestic')
                    }}
                  >
                    <span
                      className="icon_img"
                      style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                    >
                      <img
                        id="mn_4"
                        style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                        src={`${process.env.PUBLIC_URL}/img/menu/domestic.png`}
                        alt=""
                      />
                    </span>
                    {domesticSportsName}
                  </a>
                </td>
              )}

              <td style={{ width: '5px' }} />
              <td>
                <a
                  id="b_mn_4"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/sports/balance') {
                      window.location.href = '/sports/balance'
                      return
                    }
                    selectedSportIdHandler(-1)
                    navigate('/sports/balance')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_4"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/balance.png`}
                      alt=""
                    />
                  </span>
                  스포츠밸런스
                </a>
              </td>
              {/* <td style={{ width: '5px' }} /> */}
              {overseasSportsUsing && (
                <td>
                  <a
                    id="b_mn_5"
                    className="menu_style"
                    onClick={() => {
                      if (pathName === '/sports/overseas') {
                        window.location.href = '/sports/overseas'
                        return
                      }
                      navigate('/sports/overseas')
                    }}
                  >
                    <span
                      className="icon_img"
                      style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                    >
                      <img
                        id="mn_5"
                        style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                        src={`${process.env.PUBLIC_URL}/img/menu/overseas.png`}
                        alt=""
                      />
                    </span>
                    {overseasSportsName}
                  </a>
                </td>
              )}

              <td style={{ width: '5px' }} />
              {specialSportsUsing && (
                <td>
                  <a
                    id="b_mn_6"
                    className="menu_style"
                    onClick={() => {
                      if (pathName === '/sports/special') {
                        window.location.href = '/sports/special'
                        return
                      }
                      navigate('/sports/special')
                    }}
                  >
                    <span
                      className="icon_img"
                      style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                    >
                      <img
                        id="mn_6"
                        style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                        src={`${process.env.PUBLIC_URL}/img/menu/special.png`}
                        alt=""
                      />
                    </span>
                    {specialSportsName}
                  </a>
                </td>
              )}

              <td style={{ width: '5px' }} />
              {liveSportsUsing && (
                <td>
                  <a
                    id="b_mn_7"
                    className="menu_style"
                    onClick={() => {
                      if (pathName === '/sports/live') {
                        window.location.href = '/sports/live'
                        return
                      }
                      navigate('/sports/live')
                    }}
                  >
                    <span
                      className="icon_img"
                      style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                    >
                      <img
                        id="mn_7"
                        style={{ width: '14px', height: '14px', display: 'inline', marginRight: '1px' }}
                        src={`${process.env.PUBLIC_URL}/img/menu/live.png`}
                        alt=""
                      />
                    </span>
                    {liveSportsName}
                  </a>
                </td>
              )}

              <td style={{ width: '40px' }} />
              <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/live-casino') {
                      window.location.href = '/live-casino'
                      return
                    }
                    navigate('/live-casino')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/live_casino.png`}
                      alt=""
                    />
                  </span>
                  라이브 카지노
                </a>
              </td>
              <td style={{ width: '5px' }} />
              <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/slot-casino') {
                      window.location.href = '/slot-casino'
                      return
                    }
                    navigate('/slot-casino')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/slot.png`}
                      alt=""
                    />
                  </span>
                  슬롯 게임
                </a>
              </td>
              <td style={{ width: '5px' }} />
              <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/minigame') {
                      window.location.href = '/minigame'
                      return
                    }
                    navigate('/minigame')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/mini.png`}
                      alt=""
                    />
                  </span>
                  미니게임
                </a>
              </td>
              {/* <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/virtual/soccer') {
                      window.location.href = '/virtual/soccer'
                      return
                    }
                    navigate('/virtual/soccer')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/VIRTUALGAME.png`}
                      alt=""
                    />
                  </span>
                  가상스포츠
                </a>
              </td> */}
              <td style={{ width: '40px' }} />
              <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/customcenter/notice') {
                      window.location.href = '/customcenter/notice'
                      return
                    }
                    navigate('/customcenter/notice')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/notice.png`}
                      alt=""
                    />
                  </span>
                  공지사항
                </a>
              </td>
              <td style={{ width: '5px' }} />
              <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/customcenter/betting-rule') {
                      window.location.href = '/customcenter/betting-rule'
                      return
                    }
                    navigate('/customcenter/betting-rule')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/betting_rule.png`}
                      alt=""
                    />
                  </span>
                  이용규정
                </a>
              </td>
              <td style={{ width: '5px' }} />
              <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/customcenter/event') {
                      window.location.href = '/customcenter/event'
                      return
                    }
                    navigate('/customcenter/event')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/event.png`}
                      alt=""
                    />
                  </span>
                  이벤트
                </a>
              </td>
              <td style={{ width: '0px' }} />
              <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/one-to-one') {
                      window.location.href = '/one-to-one'
                      return
                    }
                    navigate('/one-to-one')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/onetoone.png`}
                      alt=""
                    />
                  </span>
                  고객센터
                </a>
              </td>
              <td style={{ width: '0px' }} />
              <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/note') {
                      window.location.href = '/note'
                      return
                    }
                    navigate('/note')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/note.png`}
                      alt=""
                    />
                  </span>
                  쪽지
                </a>
              </td>
              <td style={{ width: '20px' }} />
              {attendanceUsing && (
                <td>
                  <a
                    id="b_mn_7"
                    className="menu_style"
                    onClick={() => {
                      if (pathName === '/attendance') {
                        window.location.href = '/attendance'
                        return
                      }
                      navigate('/attendance')
                    }}
                  >
                    <span
                      className="icon_img"
                      style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                    >
                      <img
                        id="mn_7"
                        style={{ width: '14px', height: '14px', display: 'inline', marginRight: '1px' }}
                        src={`${process.env.PUBLIC_URL}/img/menu/attendance.png`}
                        alt=""
                      />
                    </span>
                    출석체크
                  </a>
                </td>
              )}
              <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/betting-history') {
                      window.location.href = '/betting-history'
                      return
                    }
                    navigate('/betting-history')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/betting.png`}
                      alt=""
                    />
                  </span>
                  배팅내역
                </a>
              </td>
              <td style={{ width: '5px' }} />
              <td>
                <a
                  id="b_mn_22"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/friend-list') {
                      window.location.href = '/friend-list'
                      return
                    }
                    navigate('/friend-list')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_22"
                      style={{ width: '13px', height: '13px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/friend.png`}
                      alt=""
                    />
                  </span>
                  추천내역
                </a>
              </td>
              <td style={{ width: '10px' }} />

              <td style={{ width: '100px' }} />
              <td>
                <a
                  id="b_mn_9"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/money/deposit') {
                      window.location.href = '/money/deposit'
                      return
                    }
                    navigate('/money/deposit')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_9"
                      style={{ width: '14px', height: '14px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/deposit.png`}
                      alt=""
                    />
                  </span>
                  충전하기
                </a>
              </td>
              <td style={{ width: '10px' }} />
              <td>
                <a
                  id="b_mn_10"
                  className="menu_style"
                  onClick={() => {
                    if (pathName === '/money/withdrawal') {
                      window.location.href = '/money/withdrawal'
                      return
                    }
                    navigate('/money/withdrawal')
                  }}
                >
                  <span
                    className="icon_img"
                    style={{ width: '16px', height: '16px', display: 'inline', marginRight: '3px' }}
                  >
                    <img
                      id="mn_10"
                      style={{ width: '14px', height: '14px', display: 'inline', marginRight: '1px' }}
                      src={`${process.env.PUBLIC_URL}/img/menu/withdrawal.png`}
                      alt=""
                    />
                  </span>
                  환전하기
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ height: '2px' }} />
      <div style={{ height: '1px', backgroundColor: '#ddd' }} />
      <div style={{ height: '10px' }} />
    </>
  )
}

export default Header

//  .btn_gnb:after{background:#ff8201;width:40px;margin-left:0;}

//  .btn_gnb i,  .btn_gnb:before,  .btn_gnb:after {
//   width: 55px;
//   height: 3px;
//   display: block;
//   background: #000;
//   content: '';
//   margin: 0 auto 10px;
//   transition: transform .2s ease-in-out, left .5s ease;
//   -moz-transition: transform .2s ease-in-out, left .5s ease;
//   -webkit-transition: -webkit-transform .2s ease-in-out, left .5s ease;
// }

//  .btn_gnb i, .btn_gnb:before, .btn_gnb:after {
//   width: 55px;
//   height: 3px;
//   display: block;
//   background: #000;
//   content: '';
//   margin: 0 auto 10px;
//   transition: transform .2s ease-in-out, left .5s ease;
//   -moz-transition: transform .2s ease-in-out, left .5s ease;
//   -webkit-transition: -webkit-transform .2s ease-in-out, left .5s ease;
// }

// .btn_gnb {
//   width: 55px;
//   height: 110px;
//   position: relative;
//   font-size: 0;
//   z-index: 120;
//   left: 20px;
//   cursor: pointer;
//   transition: transform .2s ease-in-out;
//   -moz-transition: transform .2s ease-in-out;
//   -webkit-transition: transform .2s ease-in-out;
// }

//  .btn_gnb:after {
//   background: #ff8201;
//   width: 40px;
//   margin-left: 0;
// }
