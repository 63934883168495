import { CustomSelect } from '@/shared/components/CustomSelect'
import { MobileTopWrap } from '@/shared/components/MoneyCustomElement'
import Error from '@/shared/components/form/Error'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { CouponUseValidate } from '@/utils/validate/moneyFormValidate'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FaChevronLeft } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchCouponUseList } from '../../api/memberHistory/memberHistoryApi'
import {
  createMemberCouponUse,
  searchCouponUseSelectList,
  searchMinimumAmount,
} from '../../api/memberManage/memberManageApi'
import CustomLoading from '../../containers/CustomLoading'
import CouponListData from '../../containers/MyPage/CouponHistory/CouponListData'
import { onClickTitleHandler } from '../../redux/modalSlice'
import {
  ContentInfo,
  ContentRowBig,
  ContentRowSecondWrap,
  ContentRowSmall,
  ContentRowThirdWrap,
  ContentRowWrap,
  ContentWrap,
  CustomButtonInfo,
  CustomCenterFormField,
  CustomerCenterFormGroup,
  CustomerCenterFormLabel,
  MoneyButton,
  SignUpModalCloseButton,
  SignUpModalContentWrap,
  SignUpModalHeader,
  SignUpModalHeaderLogo,
  SignUpModalInner,
  SignUpModalOverlay,
  SignUpModalWrapper,
  TableBodyTwoWrap,
} from '../../shared/components/MobileMoneyCustomElement'
import Portal from '../../shared/components/Portal'
import { MoneyFormInput, MoneyFormInputWrap } from '../../shared/components/form/newForm'
import { HermesMainBorder, HermesMainText, colorMainText } from '../../utils/palette'

const MobileCouponUseForm = ({ visible, onClose }) => {
  const [couponLoading, setCouponLoading] = useState(true)
  const dispatch = useDispatch()
  const { holdingMoney, memberTotalGameMoney, userId } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
    }
  })

  // 쿠폰 금액 / 비율
  const [couponPrice, setCouponPrice] = useState('')
  const [couponId, setCouponId] = useState('')

  // 쿠폰선택
  const [couponOptionValue, setCouponOptionValue] = useState(null)
  const [couponOption, setCouponOption] = useState([])

  // 최소 입금액
  const [minimumDeposit, setMinimumDeposit] = useState('0')

  useEffect(() => {
    setCouponLoading(true)

    searchCouponUseSelectList()
      .then(res => {
        res.data.content.map(item =>
          setCouponOption(prev => [
            ...prev,
            {
              value: item.couponUseKey,
              label: item.couponTitle,
              couponPrice: item.couponPrice,
            },
          ]),
        )
        setCouponLoading(false)
      })
      .catch(error => {
        setCouponLoading(false)
      })

    searchMinimumAmount()
      .then(res => {
        setMinimumDeposit(res.data.minimumDeposit)
      })
      .catch(error => {})
  }, [])
  // 신청금액
  const [applyMoney, setApplyMoney] = useState('0')

  // 실입금액
  const [realMoney, setRealMoney] = useState('0')

  const onChangeCouponSelectHandler = option => {
    if (option) {
      setCouponOptionValue(option)
      setCouponPrice(option.couponPrice)
      setCouponId(option.couponUseKey)
      setApplyMoney(option.couponPrice)
    } else {
      setCouponOptionValue(null)
      setCouponPrice('')
      setCouponId('')
      setApplyMoney('0')
    }
  }

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  const checkNumber = checkMoney => {
    return Math.sign(checkMoney)
  }

  useEffect(() => {
    const discountMoneyPrice = Number(applyMoney.replaceAll(',', '')) - Number(couponPrice)
    if (checkNumber(discountMoneyPrice) === 1) {
      setRealMoney(discountMoneyPrice.toString().replace(commonReg2, ','))
    } else {
      setRealMoney('0')
    }
  }, [applyMoney, couponOptionValue])

  const [inquiryButton, setInquiryButton] = useState(false)

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)
  const [couponUseLoading, setCouponUseLoading] = useState(false)

  /**
   쿠폰 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [couponUseListparams, setCouponUseListparamsParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setCouponUseListparamsParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    searchCouponUseList(couponUseListparams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [couponUseListparams])

  const fetchSearchCouponUseList = async () => {
    setLoading(true)
    await searchCouponUseList(couponUseListparams)
      .then(response => {
        setLoading(false)
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [couponListData, setCouponListData] = useState(CouponListData(content))
  useEffect(() => {
    setCouponListData(CouponListData(content))
  }, [content])

  const [rows, setData] = useState(couponListData.tableRowsData)
  useEffect(() => {
    setData(couponListData.tableRowsData)
  }, [couponListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  const onSubmit = e => {
    if (apiFlag) return

    setApiFlag(true)
    setCouponUseLoading(true)
    const body = {
      couponUseKey: couponOptionValue?.value,
    }

    createMemberCouponUse(body)
      .then(res => {
        setCouponUseLoading(false)
        alert('쿠폰 사용이 완료됐습니다.')
        setApiFlag(false)
        window.location.reload()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('쿠폰 사용이 실패했습니다.')
        }
        setCouponUseLoading(false)
        setApiFlag(false)
      })
  }
  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Portal elementId="signup-modal">
      <SignUpModalOverlay visible={visible} />
      <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
        <SignUpModalInner tabIndex="0">
          <SignUpModalHeader>
            <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
            <SignUpModalCloseButton
              onClick={close}
              src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
              alt=""
            />
          </SignUpModalHeader>
          <SignUpModalContentWrap>
            <MobileTopWrap>
              {couponLoading || couponLoading ? (
                <CustomLoading />
              ) : (
                <>
                  <ContentWrap>
                    <ContentRowWrap>
                      <FaChevronLeft
                        onClick={e => {
                          dispatch(onClickTitleHandler('MYPAGE'))
                        }}
                      />
                      <ContentRowSmall>
                        <div>
                          <span style={{ color: '#b2b2b2' }}>스포츠 캐쉬</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {holdingMoney?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>
                      </ContentRowSmall>
                      <ContentRowSmall>
                        <div>
                          <span style={{ color: '#b2b2b2' }}>카지노 캐쉬</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {memberTotalGameMoney?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>
                      </ContentRowSmall>
                    </ContentRowWrap>
                    <ContentRowThirdWrap>
                      <ContentRowBig selectedIndex={selectedIndex}>
                        <div onClick={() => setSelectedIndex(0)}>쿠폰</div>
                        <div onClick={() => setSelectedIndex(1)}>사용내역</div>
                        {/* <div onClick={() => setSelectedIndex(2)}>입금규정</div> */}
                      </ContentRowBig>
                    </ContentRowThirdWrap>
                    <ContentInfo>
                      {
                        // 여기서 구분
                      }
                      {selectedIndex === 0 && (
                        <Form
                          onSubmit={onSubmit}
                          initialValues={{
                            memberId: userId,
                            applyMoney,
                            couponOptionValue,
                          }}
                          validate={CouponUseValidate}
                        >
                          {({ handleSubmit, form: { reset } }) => (
                            <div onSubmit={handleSubmit}>
                              <ContentRowSecondWrap>
                                <CustomerCenterFormGroup style={{ marginBottom: '-15px' }}>
                                  <CustomerCenterFormLabel>쿠폰목록</CustomerCenterFormLabel>
                                  <CustomCenterFormField>
                                    <Field name="couponOptionValue">
                                      {({ input, meta }) => (
                                        <>
                                          <CouponSelect
                                            {...input}
                                            value={couponOptionValue}
                                            onChange={option => {
                                              input.onChange(option)
                                              onChangeCouponSelectHandler(option)
                                            }}
                                            options={couponOption}
                                            placeholder="쿠폰을 선택하세요"
                                            isClearable
                                            className="react-select"
                                            classNamePrefix="react-select"
                                          />
                                          {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                                        </>
                                      )}
                                    </Field>
                                  </CustomCenterFormField>
                                </CustomerCenterFormGroup>
                                <CustomerCenterFormGroup style={{ marginBottom: '30px' }}>
                                  <CustomerCenterFormLabel>전환 금액</CustomerCenterFormLabel>
                                  <CustomCenterFormField>
                                    <Field name="applyMoney">
                                      {({ input, meta }) => (
                                        <MoneyFormInputWrap>
                                          <MoneyFormInput
                                            {...input}
                                            type="text"
                                            value={input.value}
                                            disabled
                                            onChange={e => {
                                              input.onChange(e)
                                            }}
                                          />
                                          {meta.error && <Error error={meta.error} isMobile />}
                                        </MoneyFormInputWrap>
                                      )}
                                    </Field>
                                  </CustomCenterFormField>
                                </CustomerCenterFormGroup>
                                <MoneyButton variant="primary" type="submit" onClick={onSubmit}>
                                  <CustomButtonInfo>전환하기</CustomButtonInfo>
                                </MoneyButton>
                              </ContentRowSecondWrap>
                            </div>
                          )}
                        </Form>
                      )}
                      {selectedIndex === 1 && (
                        <MoneyDepositBodyTwoWrap>
                          <ReactTableBase
                            columns={couponListData.tableHeaderData}
                            data={rows}
                            tableConfig={tableConfig}
                          />
                        </MoneyDepositBodyTwoWrap>
                      )}
                      {/* {selectedIndex === 2 && <div>입금규정 관련 정보</div>} */}
                    </ContentInfo>
                  </ContentWrap>
                </>
              )}
            </MobileTopWrap>
          </SignUpModalContentWrap>
        </SignUpModalInner>
      </SignUpModalWrapper>
    </Portal>
  )
}
export default MobileCouponUseForm

export const MoneyDepositCardSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 14px;
  opacity: 0.7;
  // margin-top: 3px;
  // margin-bottom: 5px;
  color: ${colorMainText};
`

const MoneyDepositBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // justify-content: center;

  padding: 10px 2px;

  gap: 10px;
`

export const MoneyDepositInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CouponHeaderTwoWrap = styled.div`
  height: 50px;

  padding: 20px 0 10px 2px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

// Select
export const CouponSelect = styled(CustomSelect)`
  .react-select__control {
    height: 28px;
    border: 1px solid #4a4a4a;
    background-color: transparent;
    &.react-select__control--is-focused,
    &:hover {
      border: solid 2px;
      border-color: white !important;
      box-shadow: none;
      background: transparent;
      .react-select__placeholder {
        color: ${HermesMainBorder} !important;
      }
    }
  }

  .react-select__input {
    height: 28px;
    color: #d3d3d3 !important;
    &.react-select__control--is-focused {
      color: ${HermesMainBorder} !important;
    }
  }

  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto !important;
    background: rgba(0, 0, 0, 0.7) !important;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    color: ${HermesMainBorder} !important;
  }

  .react-select__single-value {
    color: #d3d3d3;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    border: 1px solid black !important;
    color: #fff;
    font-weight: 600;
  }
`

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 0px;

  gap: 10px;

  .react-table__column-header {
    font-size: 12px;
    font-weight: 700;
    padding: 16px 0;
  }

  thead th {
    padding: 0;
  }

  tbody tr td {
    font-family: 'Noto Sans', sans-serif;
    padding: 14px 0;
    font-size: 12px;
    font-weight: 700;
  }
`
