import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { deleteMiniGameBettingHistory, searchMiniGameBettingLogAll } from '../../../api/game/miniGameApi'
import CustomLoading from '../../../containers/CustomLoading'
import { MoneyFormModifyButtomInfo } from '../../../shared/components/MoneyCustomElement'
import { convertToKstByFormat } from '../../../utils/dateTime'
import {
  MiniGameBettingTypeEnums,
  MiniGameTypeEnums,
  PowerBallTypeEnums,
  MiniGameVendor,
} from '../../../utils/enums/MiniGame/PowerBallEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MobileMiniGameBettingHistoryRadio from '../MobileMiniGameBettingHistoryRadio'
import MobileCustomPagination from '../../../shared/components/MobileCustomPagination'

const MobileMiniGameBettingHistoryV2 = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      page,
      size,
    }))
  }, [page, size])

  const [totalElements, setTotalElements] = useState(0)
  const [miniGameBettingHistoryContent, setMiniGameBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchMiniGameBettingHistory = async params => {
    await searchMiniGameBettingLogAll(params)
      .then(res => {
        setTotalElements(res.data.totalElement)
        setMiniGameBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchMiniGameBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteMiniGameBettingHistory({
        miniGameBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchMiniGameBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchMiniGameBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  const [miniGameVendor, setMiniGameVendor] = useState(null)

  // 미니게임 종목 선택
  const onClickMiniGameTypeHandler = type => {
    setMiniGameVendor(type)

    setSportsBettingHistoryParams(prev => ({
      ...prev,
      page: 0,
      miniGameVendor: type || null,
    }))
  }

  return (
    <>
      {(deleteLoading || loading) && <CustomLoading />}
      {miniGameBettingHistoryContent.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          미니게임 배팅내역이 없습니다.
          {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
        </NoContentDiv>
      ) : (
        <>
          <SubMenuItemListWrap>
            <SubMenuItemBox
              active={!miniGameVendor}
              onClick={() => {
                onClickMiniGameTypeHandler(null)
              }}
            >
              전체
            </SubMenuItemBox>
            {Object.entries(MiniGameVendor).map(([key, value]) => {
              return (
                <SubMenuItemBox
                  active={key === miniGameVendor}
                  onClick={() => {
                    onClickMiniGameTypeHandler(key)
                  }}
                >
                  {value}
                </SubMenuItemBox>
              )
            })}
          </SubMenuItemListWrap>

          <div style={{ marginLeft: '10px', display: 'flex', gap: '5px' }}>
            <div
              style={{
                marginLeft: '5px',
                display: 'flex',
                gap: '5px',
                marginTop: '5px',
                width: '100%',
                justifyContent: 'right',
                paddingRight: '10px',
              }}
            >
              <button
                type="button"
                className="normal_button"
                style={{ height: '20px', width: '80px', color: '#000' }}
                onClick={() => {
                  setCheckRadio(
                    miniGameBettingHistoryContent
                      .filter(value => value.bettingResult !== 'ING')
                      .map(value => value.miniGameBettingLogKey),
                  )
                }}
              >
                전체선택
              </button>
              <button
                type="button"
                style={{ height: '20px', width: '80px', color: '#000' }}
                className="normal_button"
                onClick={() => onDeleteHandler()}
              >
                삭제하기
              </button>
            </div>
          </div>
          {miniGameBettingHistoryContent.map((item, index) => (
            <>
              <div className="hisinfo">
                <span style={{ float: 'right' }}>배팅시간 : {convertToKstByFormat(item.bettingAt)}</span>
              </div>

              <div className="choice_wrap2">
                <div className="choice_wrap">
                  <div className="league">{MiniGameTypeEnums[item.miniGameType]}</div>
                  <div className="date" />

                  <div className="h-team" style={{ width: '70%' }}>
                    <div className="default">
                      <b style={{ paddingRight: '5px' }}>&nbsp;{PowerBallTypeEnums[item.miniGameBettingType]}</b>
                    </div>
                  </div>
                  <div className="center" style={{ width: '30%' }}>
                    <div
                      className="selected"
                      style={{
                        height: '38px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <b>{item.gameRound}회차</b>
                    </div>
                  </div>
                  <div className="result_wrap" />
                </div>

                <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="result_info2">
                  <tbody>
                    <tr>
                      <td>배팅금액</td>
                      <td className="result_info2_td">{item.bettingAmount.toString().replace(commonReg2, ',')}원</td>
                      <td>결과</td>
                      <td className="result_info2_td">
                        <font>
                          <b>
                            <BettingHistoryContentBoxFooterInfo bettingResult={item.bettingResult}>
                              {MiniGameBettingTypeEnums[item.bettingResult]}
                            </BettingHistoryContentBoxFooterInfo>
                          </b>
                        </font>
                      </td>
                    </tr>
                    <tr>
                      <td>배당률</td>
                      <td className="result_info2_td">{item.bettingPer}</td>
                      <td>당첨금액</td>
                      <td className="result_info2_td">{item.winningAmount.toString().replace(commonReg2, ',')}</td>
                    </tr>
                  </tbody>
                </table>
                {item.bettingResult !== 'ING' && (
                  <span style={{ float: 'left' }}>
                    <a>
                      <MobileMiniGameBettingHistoryRadio
                        miniGameBettingLogKey={item.miniGameBettingLogKey}
                        bettingResult={item.bettingResult}
                        checkRadio={checkRadio}
                        setCheckRadio={setCheckRadio}
                      />
                    </a>
                  </span>
                )}
              </div>
            </>
          ))}
        </>
      )}
      <>
        <MobileCustomPagination currentPage={page} size={size} totalPages={totalPages} onPageChange={onPageChange} />
      </>
      {/* <div className="pageing_wrap"><table align="center" cellpadding="0" cellspacing="5" border="0" width="100%" className="pageing_table"><tbody><tr><td width="50%"><a href="betting_list.aspx?kind=sports&amp;page=1">◀ 이전5개</a></td><td width="50%"><a href="betting_list.aspx?kind=sports&amp;page=1">다음5개 ▶</a></td></tr></tbody></table></div> */}
    </>
  )
}

export default MobileMiniGameBettingHistoryV2

const SubMenuItemBox = styled.div`
  padding: 0px;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  border-radius: 24px;
  width: 80px;
  height: 23px;
  border: solid 1px #dedede;
  ${props =>
    props.active &&
    `
    border: solid 1px  #000 !important;
    color: #000 !important;
    `}
`

const SubMenuItemListWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  margin-top: 0px;
  padding-bottom: 5px;
  gap: 10px;
  // border: solid 2px red;
`

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 100px;
    height: auto;
  }
`

export const CustomButtonInfos = styled(MoneyFormModifyButtomInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  padding: 0px 8px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    color: grey;
  }
`

const BettingHistoryHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: rgba(48, 48, 48, 1);
  // border-left: solid 1px gray;
  // border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  align-items: center;
  font-weight: 500;
`

const BettingHistoryTopHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  gap: 20px;
  font-weight: 400;
  background: black;
  // border-left: solid 1px gray;
  // border-right: solid 1px gray;
  // border-top: solid 1px gray;
  height: 25px;
  align-items: center;
  padding: 0px 5px;
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ListWrap = styled.div`
  display: flex;
  text-align: right;
  align-items: right;
  justify-content: right;
  padding-right: 10px;
  gap: 10px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  // font-family: 'Pretendard', Dotum, Helvetica, 'Apple SD Gothic Neo', Sans-serif;
  // font-weight: 600;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    background: rgba(48, 48, 48, 1);
    padding: 2px 2px;
    border-top: 1px solid #888;
    // border-right: 1px solid #888;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead``

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  // border-left: solid 1px gray;
  // border-bottom: solid 1px gray;
  &:last-child {
    border-bottom: solid 1px gray;
  }
`

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;

  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: #b88907;
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`

const getColor2 = settlementType => {
  switch (true) {
    case settlementType === 'WIN':
      return 'blue'
    case settlementType === 'LOSE':
      return 'red'
    case settlementType === 'ING':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'red'
    default:
      return '#000'
  }
}

const BettingHistoryContentBoxFooterInfo = styled.span`
  font-weight: 500;
  color: ${props => getColor2(props.bettingResult)};
`
