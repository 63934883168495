import React, { useState } from 'react'
// import PartnerManageMentTabs from '../../PartnerManageMentTabs'

const MobileMiniGameTabs = ({ activeMenu }) => {
  return (
    <div className="mobile_mini_content">
      <div className="tab_wrap">
        <ul className="sub_tap">
          <li
            className={activeMenu === 'NTRY' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/ntry/eos1m'
            }}
          >
            <a>
              <img src={`${process.env.PUBLIC_URL}/img/main/ntry.png`} alt="" />
              엔트리
            </a>
          </li>
          <li
            className={activeMenu === 'BOSCORE' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/boscore/stat1m'
            }}
          >
            <a>
              <img src={`${process.env.PUBLIC_URL}/img/main/boscore.png`} alt="" />
              보스코어
            </a>
          </li>
          {/* <li
            className={activeMenu === 'NAMED' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/named/n/powerball3m'
            }}
          >
            <a>
              <img src={`${process.env.PUBLIC_URL}/img/main/named.png`} alt="" />
              네임드
            </a>
          </li>
          <li
            className={activeMenu === 'SUREMAN' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/sureman/roulette1m'
            }}
          >
            <a>
              <img src={`${process.env.PUBLIC_URL}/img/main/sureman.png`} alt="" />
              슈어맨
            </a>
          </li> */}
          <li
            className={activeMenu === 'GOPICK' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/mobile/mini-game/gopick/raccoon'
            }}
          >
            <a>
              <img src={`${process.env.PUBLIC_URL}/img/main/gopick.png`} alt="" />
              고픽
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MobileMiniGameTabs
