import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import CustomLoading from '../../containers/CustomLoading'
import { fetchSportsBonusFolder } from '../../redux/sportsGameBettingSlice'
import MobileSportsBettingCartSidebar from '../MobileLayout/MobileSportsBettingCartSidebar'
import MobileSportsSpecialEventList from './MobileSportsSpecialEventList'
import { HermesMainBorder } from '../../utils/palette'

const MobileSportsSpecialGamePage = () => {
  const dispatch = useDispatch()

  const { loading, loadingInfo } = useSelector(state => {
    const { sportsGameBetting } = state

    return {
      loading: sportsGameBetting.loading,
      loadingInfo: sportsGameBetting.loadingInfo,
    }
  })

  useEffect(() => {
    dispatch(fetchSportsBonusFolder())
  }, [])

  return (
    <SportsGamePageWrap>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart info={loadingInfo || ''} />
        </SportsGamePageLoadingOverlay>
      )}
      {/* <SportsGameHeaderWrap>
        <SportsGameHeaderInfo>스포츠 게임</SportsGameHeaderInfo>
      </SportsGameHeaderWrap> */}

      <SportsGameContentWrap>
        {/* 경기 목록 */}
        <MobileSportsSpecialEventList />
        <MobileSportsBettingCartSidebar />
      </SportsGameContentWrap>
    </SportsGamePageWrap>
  )
}

export default MobileSportsSpecialGamePage

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9990;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsGamePageWrap = styled.div`
  width: 100%;
  background: #161719;
  // border: 1px solid #edae07;
  min-height: calc(100vh - 105px);

  padding-bottom: 10px;
  position: relative;
`

const SportsGameHeaderWrap = styled.div`
  border-top: 1px solid #252525;
  width: 100%;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #252525;
`

const SportsGameHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: #ffc01a;
`

const SportsGameEventCount = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: ${props => (props.active ? `${HermesMainBorder}` : '#fff')};
  font-size: 12px;
  float: right;
  padding: 3px 5px 3px 5px;
  margin: 1px;
`

const SportsGameContentWrap = styled.div`
  width: 100%;
`
