import {
  FormButtonToolbar,
  FormContainer,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import { commonReg2 } from '@/utils/validate/commonValidate'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMemberPointExchange } from '../../api/memberHistory/memberHistoryApi'
import { createMemberPointExchange } from '../../api/memberManage/memberManageApi'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import { convertToKstDateTime, minusDayToKst } from '../../utils/dateTime'
import { colorMainText, colorMoneyExchangeSvg, colorWalletSvg } from '../../utils/palette'
import './pointExchange.css'

const PointExchangeForm = ({ tabTitle }) => {
  const [pageLoading, setPageLoading] = useState(true)
  const dispatch = useDispatch()

  const { moneyAmount, pointAmount } = useSelector(state => {
    const { memberInfo } = state

    return {
      moneyAmount: memberInfo.memberHoldingMoney,
      pointAmount: memberInfo.memberHoldingPoint,
    }
  })

  /**
    회원 최근 일주일 롤링 전환내역 조회
*/
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [pointWeekHistoryParams, setPointWeekHistoryParams] = useState({
    pointType: 'ROLLING',
    page,
    size,
    startDate: minusDayToKst(7),
  })

  useEffect(() => {
    setPointWeekHistoryParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberPointExchange = async () => {
    setPageLoading(true)
    await searchMemberPointExchange(pointWeekHistoryParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberPointExchange()
  }, [tabTitle])

  const [compExchangeLoading, setCompExchangeLoading] = useState(false)
  const onSubmit = () => {
    if (compExchangeLoading) return
    if (pointAmount === 0) return
    setCompExchangeLoading(true)
    createMemberPointExchange()
      .then(res => {
        setCompExchangeLoading(false)
        alert('포인트 전환이 완료되었습니다.')
        fetchSearchMemberPointExchange()
        dispatch(fetchMemberInfo())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('포인트 전환이 실패했습니다. 다시 시도해주세요.')
        }
        setCompExchangeLoading(false)
      })
  }

  return (
    <>
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div className="content">
            {/* <table className="tb_notice_box">
              <tbody>
                <tr>
                  <td className="td_notice_td">
                    <span style={{ fontSize: '10pt' }}>
                      <b>
                        <span style={{ color: 'rgb(0,0,0)' }}>
                          <b>적립금 포인트 안내</b>
                          배팅머니 적립금(미당첨)
                          <br />
                          회원님께서 배팅하신 금액의 일정 % 만큼 포인트를 등급별로 차등지급됩니다.(미당첨시 적용){' '}
                          포인트 전환은 <span style={{ color: 'rgb(255, 170, 0)' }}>5,000 P</span> 이상 전환이
                          가능합니다.
                        </span>
                      </b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table> */}
            <div className="height_20" />
            <div className="height_40" style={{ textAlign: 'center' }}>
              <a
                className="big_button"
                onClick={() => {
                  onSubmit()
                }}
                style={{ cursor: 'pointer', width: '120px' }}
              >
                포인트전환
              </a>
            </div>
            <div className="height_20" />
            <div id="CPH_point_list" className="div_list">
              <table style={{ textAlign: 'center' }}>
                <colgroup>
                  {/* <col style={{ width: '8%' }} /> */}
                  <col style={{ width: '34%' }} />
                  <col style={{ width: '22%' }} />
                  <col style={{ width: '22%' }} />
                  <col style={{ width: '22%' }} />
                </colgroup>
                <thead>
                  <tr>
                    {/* <th>삭제</th> */}
                    <th>일시</th>
                    <th>이전 포인트</th>
                    <th>전환 포인트</th>
                    <th>이후 포인트</th>
                  </tr>
                </thead>
                <tbody>
                  {totalElement > 0 ? (
                    <>
                      {content.map(item => {
                        return (
                          <tr>
                            {/* <td>{item.memberId}</td> */}
                            <td>{convertToKstDateTime(item.executeDate)}</td>
                            <td>{item.beforePoint?.toString().replace(commonReg2, ',')}</td>
                            <td>{item.changePoint?.toString().replace(commonReg2, ',')}</td>
                            <td>{item.afterPoint?.toString().replace(commonReg2, ',')}</td>
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="5">포인트 전환 내역이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PointExchangeForm

// sign up form label
const CompFormLabel = styled.div`
  width: max-content;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: right;
`

export const CompFormField = styled.div`
  width: 400px;
  display: flex;
`

const CompExchangeWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;
  margin-top: 10px;

  svg {
    width: 40px;
    height: 40px;
    color: ${colorWalletSvg};
    margin: 0 5px;
  }
`

const CompExchangeHeaderWrap = styled.div`
  padding: 20px 10px 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  border-bottom: 1px solid #edae07;
`
const CompExchangeHeaderTwoWrap = styled.div`
  height: 50px;

  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const CompExchangeBodyOneWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 2px !important;
  padding: 10px 20px;
`

const CompExchangeBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  padding: 10px 20px;

  gap: 10px;
`

const CompExchangeBodyThirdWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

const CompExchangeHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 5px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

const CompExchangeHeaderThirdInfo = styled.span`
  font-style: normal;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

export const CompExchangeCardSubhead = styled.p`
  text-transform: none;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`

export const CompExchangeInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CompFormContainer = styled(FormContainer)`
  ${props =>
    props.horizontal &&
    `

    ${FormGroupField} {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 120px;
    }

    @media screen and (min-width: 480px) {

      ${FormGroupLabel} {
        width: 120px;
      }

      ${FormGroupField} {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;

      }

      ${FormGroupDescription}, ${FormButtonToolbar} {
        margin-left: 0px;
      }
    }
  `}
`

export const CompIoIosArrowBack = styled(IoIosArrowBack)`
  color: ${colorMoneyExchangeSvg} !important;
`

// endregion
