import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { searchMemberMoneyDeposit } from '../../../api/memberHistory/memberHistoryApi'
import CustomLoading from '../../../containers/CustomLoading'
import {
  MobileCustomerCenterContentWrap,
  MobileCustomerCenterHeaderInfo,
  MobileCustomerCenterHeaderWrap,
  MobileTableWrap,
  MobileTopWrap,
} from '../../../shared/components/MoneyCustomElement'
import MobileMoneyDepositListData from './MobileMoneyDepositListData'

const MobileMoneyDepositList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  const [loading, setLoading] = useState(true)

  /**
   *  회원 입금내역 조회
   */
  const [params, setParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberMoneyDeposit(params)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [params])

  const [depositListData, setDepositListData] = useState(MobileMoneyDepositListData(content))

  useEffect(() => {
    setDepositListData(MobileMoneyDepositListData(content))
  }, [content])

  const [rows, setData] = useState(depositListData.tableRowsData)

  useEffect(() => {
    setData(depositListData.tableRowsData)
  }, [depositListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  return (
    <MobileTopWrap>
      <MobileCustomerCenterHeaderWrap>
        <MobileCustomerCenterHeaderInfo>머니 입금내역</MobileCustomerCenterHeaderInfo>
      </MobileCustomerCenterHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <MobileCustomerCenterContentWrap>
          <MobileTableWrap>
            <ReactTableBase columns={depositListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </MobileTableWrap>
        </MobileCustomerCenterContentWrap>
      )}
    </MobileTopWrap>
  )
}

export default MobileMoneyDepositList
