import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'reactjs-crontab/dist/index.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { setSelectedSportId } from '../../../redux/sportsSpecialInfoSlice'
import { KyochonMainText } from '../../../utils/palette'

const MobileSportsSpecialHeader = () => {
  const dispatch = useDispatch()

  const onClickSelectedSportHandler = sportId => {
    dispatch(setSelectedSportId(sportId))
  }

  const { selectedSportId, specialCategory } = useSelector(state => {
    const { sportsSpecialInfo } = state

    return {
      selectedSportId: sportsSpecialInfo.selectedSportId,
      specialCategory: sportsSpecialInfo.specialCategory,
    }
  })

  const { specialSportsUsing, specialSportsName } = useSelector(state => {
    const { memberInfo } = state

    return {
      specialSportsUsing: memberInfo.specialSportsUsing,
      specialSportsName: memberInfo.specialSportsName,
    }
  })

  return (
    <>
      <div className="gamenoticewrap" style={{ textAlign: 'left', color: 'black', padding: '5px' }}>
        <div>
          <font color="#ffef00">
            <span style={{ fontSize: '18.6667px', lineHeight: '28px' }}>
              <b>
                <span style={{ color: 'rgb(166, 207, 0)' }}>
                  <span style={{ color: `${KyochonMainText}`, fontSize: '12pt' }}>{specialSportsName}</span>
                  <br />
                </span>
              </b>
            </span>
          </font>
        </div>
        {/* <div>
          <span style={{ fontSize: '10pt' }}>
            <span style={{ color: 'rgb(0, 0, 0)', fontSize: '9pt' }}>
              <br />
              <span>
                <b>
                  <span>
                    배팅금액은<span style={{ color: 'rgb(0, 117, 200)' }}>&nbsp;</span>
                  </span>
                </b>
              </span>
            </span>
            <b>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '9pt', fontFamily: '돋움체, dotumche' }}>
                <span style={{ color: 'rgb(0, 117, 200)' }}>5천원~100만원</span>까지
              </span>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '9pt', fontFamily: '돋움체, dotumche' }}>
                &nbsp;가능&nbsp;
              </span>
              <br />
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '9pt', fontFamily: '돋움체, dotumche' }}>축구&nbsp;</span>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '9pt', fontFamily: '돋움체, dotumche' }}>
                동일경기 승/언더오버
              </span>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: '9pt', fontFamily: '돋움체, dotumche' }}>
                &nbsp;배팅 가능 (<span style={{ color: 'rgb(255, 0, 0)' }}>동일 경기 승오버 배팅시 0.05차감</span>)
                <br />
                모든 경기 단 폴더 배팅 가능 (<span style={{ color: 'rgb(255, 0, 0)' }}>단폴더 배팅시 0.05 차감</span>)
              </span>
            </b>
          </span>
        </div>
        <div>
          <b>
            <span style={{ lineHeight: '28px' }}>
              <span style={{ fontSize: '9pt', fontFamily: '돋움체, dotumche' }}>양방배팅,</span>
            </span>
            <span style={{ fontSize: '10pt' }}>
              <span style={{ fontSize: '9pt', fontFamily: '돋움체, dotumche' }}>
                지속적인 단폴배팅 제재 대상입니다.
              </span>
            </span>
          </b>
        </div> */}
      </div>
      <div className="tabZone">
        <div className="tabFrame">
          <ul>
            {specialCategory.map((item, index) => {
              return (
                <li
                  key={`${item.sportId}-${index + 1}`}
                  className={item.sportId === selectedSportId ? 'item category-all active' : 'item category-all'}
                  onClick={() => {
                    onClickSelectedSportHandler(item.sportId)
                  }}
                >
                  {item.sportId !== -1 && (
                    <img
                      src={`${process.env.PUBLIC_URL}/img/sports/sportsType/side-${item.sportId}.png`}
                      width="20"
                      height="20"
                      alt=""
                    />
                  )}

                  <div className="txt" style={{ marginLeft: '2px' }}>
                    {item.sportName}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

export default MobileSportsSpecialHeader
