import React, { useEffect } from 'react'
import styled from 'styled-components'
import Portal from '../../../shared/components/Portal'

/**
 * @see : https://github.com/masroorejaz/react-simple-captcha
 */

const AlertPopup = ({ onClose, visible }) => {
  useEffect(() => {
    document.body.style = `overflow: hidden`
    return () => {
      document.body.style = `overflow: auto`
    }
  }, [])
  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }

  return (
    <Portal elementId="alert-popup">
      <AlertPopupOverlay visible={visible} />
      <AlertPopupWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
        <AlertPopupInner tabIndex="0">
          <AlertPopupHeader>
            <AlertPopupHeaderLogo src={`${process.env.PUBLIC_URL}/img/popup/popup-logo.png`} alt="" />
            <AlertPopupCloseButton onClick={close} src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`} alt="" />
          </AlertPopupHeader>
          <AlertPopupContentWrap>
            <AlertPopupContentInfo1>현재 점검중인 게임입니다.</AlertPopupContentInfo1>
            <AlertPopupContentInfo1>잠시후 다시 시도해주세요.</AlertPopupContentInfo1>
          </AlertPopupContentWrap>
        </AlertPopupInner>
      </AlertPopupWrapper>
    </Portal>
  )
}

export default AlertPopup

const AlertPopupWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`
const AlertPopupOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`

const AlertPopupInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #000000;

  width: 360px;
  top: 213px;
  margin: 0 auto;

  border: 1px solid #edae07;

  //   height: 300px;
`

const AlertPopupHeader = styled.div`
  height: 60px;
  border-bottom: 1px solid #edae07;

  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const AlertPopupHeaderLogo = styled.img`
  width: 200px;
  height: 50px;
`

// 닫기 아이콘
const AlertPopupCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const AlertPopupContentWrap = styled.div`
  padding: 20px 57px;

  text-align: center;

  // form 없을 때 임시로 사용
  display: flex;
  flex-direction: column;
  gap: 10px;

  justify-content: center;
  align-items: center;

  height: 200px;
`

// 이용 권한이 없습니다.
const AlertPopupContentInfo1 = styled.span`
  font-style: normal;
  font-weight: 600;
  line-height: 25px;

  /* 엘로우폰트 */

  color: #ffc01a;
`
