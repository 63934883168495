import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  createUserLogAction,
  initHomeInfoAction,
  initPopupInfoAction,
  initSportsInfoAction,
  initVirtualInfoAction,
  reAuthenticationAction,
} from '../../redux/memberInfoSlice'
import useAudio from '../../utils/audioHook'
import isLogin from '../../utils/enums/authority'
import { decodeAccessToken } from '../../utils/token'
import CustomLoading from '../CustomLoading'
import Footer from './Footer'
import Header from './Header'
import SportsGameSidebar from './SportsGameSidebar'

// TODO  https://fourwingsy.medium.com/%ED%8C%9D%EC%97%85%EC%99%80-%EB%A9%94%EC%8B%9C%EC%A7%80-with-react-afd1923797d8
const Layout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [pathName, setPathName] = useState('')

  const [noteAudioSrc, setNoteAudioSrc] = useState(`${process.env.PUBLIC_URL}/audio/noteAudio.mp3`)
  const [oneToOneAudioSrc, setOneToOneAudioSrc] = useState(`${process.env.PUBLIC_URL}/audio/oneToOneAudio.mp3`)

  const [playNoteAudio, pauseNoteAudio, pausedNoteAudio, noteAudioRef] = useAudio(noteAudioSrc)
  const [playOneToOneAudio, pauseOneToOneAudio, pausedOneToOneAudio, oneToOneAudioRef] = useAudio(oneToOneAudioSrc)

  useEffect(() => {
    setPathName(location.pathname)
  }, [location])

  const { noteRedisSize, noteMessageInfo, userId, userName, oneToOneRedisSize, homeInitLoading } = useSelector(
    state => {
      const { memberInfo } = state

      return {
        noteRedisSize: memberInfo.noteRedisSize,
        noteMessageInfo: memberInfo.noteMessageInfo,
        userId: memberInfo.userId,
        userName: memberInfo.userName,
        oneToOneRedisSize: memberInfo.oneToOneRedisSize,
        homeInitLoading: memberInfo.homeInitLoading,
      }
    },
  )

  useEffect(() => {
    dispatch(initHomeInfoAction())
    dispatch(initPopupInfoAction())
    dispatch(initSportsInfoAction())
    dispatch(initVirtualInfoAction())

    if (isLogin()) {
      dispatch(reAuthenticationAction({ newWindow: false }))
    }
  }, [dispatch])

  useEffect(() => {
    setPathName(location.pathname)

    if (isLogin()) {
      const userTokenInfo = decodeAccessToken()

      dispatch(
        createUserLogAction({
          userId: userTokenInfo.userId,
          userName: userTokenInfo.userName,
          position: location.pathname,
          devicePixelRatio: window.devicePixelRatio,
          userAgent: window.navigator.userAgent,
          resolution: `${window.screen.width}X${window.screen.height}`,
          windowSize: `${window.innerWidth}X${window.innerHeight}`,
          platform: window.navigator?.platform || '',
        }),
      )
    }
  }, [location])

  // 쪽지 모달이 켜져 있으면 false
  const [isValidPath, setIsValidPath] = useState(false)

  useEffect(() => {
    if (noteRedisSize > 0 && pathName !== '/note') {
      playNoteAudio()
      alert('쪽지함을 먼저 확인해주세요.')
      navigate('/note')
      return
    }
    if (noteRedisSize > 0 && pausedNoteAudio() && (pathName !== '/note' || pathName !== '/one-to-one')) {
      playNoteAudio()
      return
    }

    if (noteRedisSize === 0 && oneToOneRedisSize > 0 && pathName !== '/one-to-one') {
      playOneToOneAudio()
      alert('1:1문의 답변을 먼저 확인해주세요.')
      navigate('/one-to-one')
      return
    }

    if (
      noteRedisSize === 0 &&
      oneToOneRedisSize > 0 &&
      pausedOneToOneAudio() &&
      (pathName !== '/note' || pathName !== '/one-to-one')
    ) {
      playOneToOneAudio()
    }

    if (noteRedisSize > 0 && oneToOneRedisSize > 0 && pathName !== '/note') {
      playNoteAudio()
    }
  }, [oneToOneRedisSize, noteRedisSize, pathName])

  const containerSecondWrapRef = useRef(null)

  return (
    <div>
      {homeInitLoading && <CustomLoading />}
      {/* {isValidPath && noteRedisSize > 0 && <NoteAlert noteSize={noteRedisSize} />} */}
      {/* {isValidPath && noteRedisSize === 0 && oneToOneRedisSize > 0 && <OneToOneAlert noteSize={oneToOneRedisSize} />} */}
      {/* {sideMenuModalShow && <SideMenuModal visible={sideMenuModalShow} onClose={() => dispatch(setModalShow(false))} />} */}
      <Header />
      <div style={{ height: '13px', clear: 'both' }} />

      {/* <SportsGameSidebar /> */}

      <Outlet />

      {/* {getBettingCartComponent(pathName)} */}
      {/* <LiveChat /> */}

      <div style={{ height: '30px', clear: 'both' }} />
      <Footer />
    </div>
  )
}

export default Layout

const ContainerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const LeftSidebarWrap = styled.div`
  position: fixed;
  top: 160px;
  left: 0;
  margin-top: 10px;
  width: 300px;
  height: calc(100vh - 240px);
  border: solid 1px #edae07;
  // z-index: 301;
`

const RightSidebarWrap = styled.div`
  position: fixed;
  top: 160px;
  right: 5px;
  margin-top: 10px;
  width: 300px;
  height: calc(100vh - 240px);
  // border: solid 1px #edae07;
  // z-index: 301;
`

const BettingCartButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const ContainerSecondWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll !important;
`

const Container = styled.div`
  margin-top: 65px;
  background-color: #111314;
  transition: all 0.5s ease-out 0s;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  width: 100%;
  padding-left: 310px;
  padding-right: 330px;

  ${props =>
    !props.isShowLeftMenuSidebar &&
    `
    padding-left: 200px;
    padding-right: 440px;
`}

  @media (max-width: 1670px) {
    /* 대형 데스크탑보다 작은 화면에 적용되는 스타일 */
    padding-left: 70px;
    padding-right: 310px;
  }

  @media (max-width: 1440px) {
    padding-left: 70px;
    padding-right: 30px;
    min-width: max-content;
  }
`
