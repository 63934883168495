import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import { Collapse } from 'react-bootstrap'
import {
  createOnetoOne,
  deleteOnetoOneList,
  memberConfirmOneToOneAnswer,
} from '../../../api/customCenter/customCenterApi'
import { fetchOneToOneList } from '../../../redux/customCenterSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import {
  CustomerCenterContentWrap,
  CustomerCenterHeaderInfo,
  GoldQuestionButton,
  ListButton,
  MoneyFormModifyButtomInfo,
} from '../../../shared/components/MoneyCustomElement'
import { WebPageWrap } from '../../../shared/components/form/newForm'
import { convertToKstByFormat, convertToKstDateTime } from '../../../utils/dateTime'
import { OneToOneAnswerStatusEnums, OneToOneConfirmStatusEnums } from '../../../utils/enums/Operation/OneToOneEnums'
import { colorMainText } from '../../../utils/palette'
import CustomLoading from '../../CustomLoading'
import OnetoOneRadio from './OnetoOneRadio'
import OnetoOneWrite from './OnetoOneWrite'
import OnetoOneDetail from './OnetoOneDetail'
import { fetchOneToOneSize } from '../../../redux/memberInfoSlice'

const OnetoOneList = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [isWriteOnetoOneShow, setWriteOnetoOneShow] = useState(false)

  /**
   *  1:1문의 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [oneToOneParams, setOneToOneParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setOneToOneParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [checkRadio, setCheckRadio] = useState([])
  const [content, setContent] = useState([])
  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.oneToOneInfo.totalElement,
      apiContent: customCenter.oneToOneInfo.content,
    }
  })
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.oneToOneKey === item.oneToOneKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchOneToOneList = async () => {
    try {
      dispatch(fetchOneToOneList(oneToOneParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchOneToOneList()
  }, [oneToOneParams])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = oneToOneKey => {
    const idx = content.findIndex(item => item.oneToOneKey === oneToOneKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })
    }
  }

  // 문의하기 버튼
  const onWriteFromHandler = () => {
    setWriteOnetoOneShow(prev => !prev)
  }
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [questionLoading, setQuestionLoading] = useState(false)

  const onQuestionAccountHandler = () => {
    if (questionLoading) return
    const body = {
      questionTitle: '⚡ 자동 계좌문의',
      questionContent: '계좌문의 드립니다.',
    }
    setQuestionLoading(true)
    createOnetoOne(body)
      .then(res => {
        alert('계좌문의가 접수되었습니다.')
        setQuestionLoading(false)
        fetchSearchOneToOneList()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(message)
            break
          default:
            alert('계좌문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setQuestionLoading(false)
      })
  }

  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 1:1문의를 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteOnetoOneList({
        oneToOneKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchOneToOneList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchOneToOneList()
        })
    }
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  const [showDetail, setShowDetail] = useState(false)
  const [writeDetail, setWriteDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleRowClick = oneToOneKey => {
    const idx = content.findIndex(item => item.oneToOneKey === oneToOneKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })

      const idx2 = content.findIndex(item => item.oneToOneKey === oneToOneKey)
      if (idx2 !== -1 && content[idx2]?.confirmStatus === 'INCOMPLETE') {
        memberConfirmOneToOneAnswer({
          oneToOneKey: content[idx2].oneToOneKey,
        }).then(res => {
          dispatch(fetchOneToOneList(oneToOneParams))
          dispatch(fetchOneToOneSize())
        })
      }
    }

    const boardContent = content.find(item => item.oneToOneKey === oneToOneKey)
    if (!boardContent) return
    setSelectedItem(boardContent)
    setShowDetail(true)
  }

  const renderTitleWithLargeEmoji = title => {
    const parts = title.split(/(⚡)/g) // '⚡'을 기준으로 문자열을 분할
    return parts.map((part, index) =>
      part === '⚡' ? (
        <span key={index} style={{ fontSize: '2em', verticalAlign: 'middle' }}>
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      ),
    )
  }

  return (
    <>
      {loading && <CustomLoading />}
      {showDetail || writeDetail ? (
        <>
          {showDetail && (
            <OnetoOneDetail
              notice={selectedItem}
              onBack={() => setShowDetail(false)}
              fetchSearchOneToOneList={fetchSearchOneToOneList}
            />
          )}
          {writeDetail && (
            <OnetoOneWrite onBack={() => setWriteDetail(false)} fetchSearchOneToOneList={fetchSearchOneToOneList} />
          )}
        </>
      ) : (
        <div className="div_content" style={{ position: 'relative' }}>
          <div className="div_body">
            <div className="content">
              <table className="tb_notice_box">
                <tbody>
                  <tr>
                    <td className="td_notice_td">
                      <b>
                        <span style={{ fontSize: '11pt', display: 'flex', flexDirection: 'column' }}>
                          <span style={{ color: 'rgb(0, 0, 0)' }}>
                            궁금한 문의사항 및 개인정보변경에 관해서는 고객센터로 문의 바라며, 같은 내용의 문의는 자제
                            부탁드립니다.
                          </span>
                          <span style={{ color: 'rgb(0, 0, 0)' }}>
                            보다 자세한상담을 원하시면 공식텔레그램{' '}
                            <span
                              style={{ color: '#229ED9', fontWeight: '800', cursor: 'pointer' }}
                              onClick={() => {
                                window.open('https://t.me/Zeus_CSCS')
                              }}
                            >
                              @Zeus_CSCS
                            </span>{' '}
                            로 상담 부탁드립니다.
                          </span>
                        </span>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="height_20" />
              <div id="CPH_contact_list" className="div_list">
                <div style={{ display: 'flex', gap: '5px', margin: '0 0 5px 0', float: 'right' }}>
                  <a
                    className="btMoney"
                    style={{ width: '66px' }}
                    onClick={() => {
                      setCheckRadio(
                        content.filter(value => value.answerStatus !== 'INCOMPLETE').map(value => value.oneToOneKey),
                      )
                    }}
                  >
                    전체선택
                  </a>
                  <a
                    className="btMoney"
                    style={{ width: '66px' }}
                    onClick={() => {
                      onDeleteHandler()
                    }}
                  >
                    삭제하기
                  </a>
                  <a
                    className="btMoney"
                    style={{ width: '86px', color: 'red' }}
                    onClick={() => {
                      onQuestionAccountHandler()
                    }}
                  >
                    자동 계좌문의
                  </a>
                </div>
                <table style={{ textAlign: 'center' }}>
                  <colgroup>
                    <col style={{ width: '6%' }} />
                    <col style={{ width: '16%' }} />
                    <col style={{ width: '62%' }} />
                    <col style={{ width: '8%' }} />
                    <col style={{ width: '8%' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>선택</th>
                      <th>문의일자</th>
                      <th>문의제목</th>
                      <th>확인여부</th>
                      <th>답변여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    {content.length === 0 ? (
                      <tr>
                        <td colSpan="5">문의 내역이 없습니다.</td>
                      </tr>
                    ) : (
                      <>
                        {content.map(item => (
                          <>
                            <tr key={item.oneToOneKey}>
                              <td
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                {item.answerStatus !== 'INCOMPLETE' ? (
                                  <OnetoOneRadio
                                    rowInfo={{
                                      oneToOneKey: item.oneToOneKey,
                                      answerStatus: item.answerStatus,
                                    }}
                                    checkRadio={checkRadio}
                                    setCheckRadio={setCheckRadio}
                                  />
                                ) : (
                                  <>&nbsp;</>
                                )}
                              </td>
                              <td
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleRowClick(item.oneToOneKey)}
                              >
                                {convertToKstByFormat(item.registrationDate)}
                              </td>
                              <td
                                onClick={() => handleRowClick(item.oneToOneKey)}
                                style={{
                                  textAlign: 'center',
                                  paddingLeft: '10px',
                                  cursor: 'pointer',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {renderTitleWithLargeEmoji(item.questionTitle)}
                              </td>
                              <td
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleRowClick(item.oneToOneKey)}
                              >
                                {OneToOneConfirmStatusEnums[item.confirmStatus]}
                              </td>
                              <td
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleRowClick(item.oneToOneKey)}
                              >
                                {OneToOneAnswerStatusEnums[item.answerStatus]}
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="height_10" />
              {totalElement > 0 && (
                <>
                  <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                </>
              )}
              <div style={{ textAlign: 'left' }}>
                <a onClick={() => setWriteDetail(true)} className="normal_button">
                  문의하기
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OnetoOneList

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
`
export const OnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 14px;
  opacity: 0.7;

  color: ${colorMainText};
`
export const OneToOneHeaderWrap = styled.div`
  padding: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
  height: 70px;
  border-bottom: solid 1px #edae07;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;

  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  padding: 10px 5px;

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-weight: 600;
  }
`
