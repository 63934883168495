import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'reactjs-crontab/dist/index.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { setSelectedSportId } from '../../../redux/sportsOverseasInfoSlice'

const SportsOverseasHeader = () => {
  const dispatch = useDispatch()

  const onClickSelectedSportHandler = sportId => {
    dispatch(setSelectedSportId(sportId))
  }

  const { selectedSportId, overseasCategory } = useSelector(state => {
    const { sportsOverseasInfo } = state

    return {
      selectedSportId: sportsOverseasInfo.selectedSportId,
      overseasCategory: sportsOverseasInfo.overseasCategory,
    }
  })

  return (
    <>
      <div>
        <table className="tb_notice_box">
          <tbody>
            <tr>
              <td className="td_notice_td">
                <span style={{ fontSize: '10pt' }}>
                  <b>
                    <span style={{ color: 'rgb(0, 0, 0)' }}>
                      이용관련 규정은 게시판에 안내되어 있으니,숙지 후 이용 바랍니다.
                    </span>
                  </b>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className="tabZone">
          <div className="tabFrame">
            <ul>
              {overseasCategory.map((item, index) => {
                return (
                  <li
                    key={`${item.sportId}-${index + 1}`}
                    className={item.sportId === selectedSportId ? 'item category-all active' : 'item category-all'}
                    onClick={() => {
                      onClickSelectedSportHandler(item.sportId)
                    }}
                  >
                    {item.sportId !== -1 && (
                      <img
                        src={`${process.env.PUBLIC_URL}/img/sports/sportsType/side-${item.sportId}.png`}
                        width="20"
                        height="20"
                        alt=""
                      />
                    )}

                    <div className="txt" style={{ marginLeft: '2px' }}>
                      {item.sportName}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default SportsOverseasHeader
