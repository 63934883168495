import React, { useEffect, useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { HiOutlineRefresh } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { siginOut } from '../../api/authManage/authManageApi'
import CustomLoading from '../../containers/CustomLoading'
import MobileAuthorityAlertPopup from '../../containers/Popup/Error/MobileAuthorityAlertPopup'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { onClickMobileSignInHandlerInSideBar, onClickTitleHandler, onShowModalHandler } from '../../redux/modalSlice'
import isLogin, { setRecommendCode } from '../../utils/enums/authority'
import { HermesMainBorder, HermesMainText } from '../../utils/palette'
import MobileBettingHistoryPage from '../mobileBettingHistory/MobileBettingHistoryPage'
import MobileCouponUseForm from '../mobileCouponUse/MobileCouponUseForm'
import MobileNoteList from '../mobileCustomCenter/MobileNote/MobileNoteList'
import MobileOnetoOneList from '../mobileCustomCenter/MobileOnetoOne/MobileOnetoOneList'
import MobileMoneyDepositForm from '../mobileMoneyDeposit/MobileMoneyDepositForm'
import MobileMoneyWithdrawalForm from '../mobileMoneyWithdrawal/MobileMoneyWithdrawalForm'
import MobileMyInfo from '../mobileMyInfo/MobileMyInfo'
import MobilePointExchangeForm from '../mobilePointExchange/MobilePointExchangeForm'
import MobileMenuSidebar from './MobileMenuSidebar'
import MobileSignInPopup from './MobileSignIn'
import MobileSignUpPopup from './MobileSignUp'

const MobileHeader = () => {
  const loginCheck = isLogin()
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location?.pathname
  // 파트너 추천 코드 용 쿼리 스트링
  const [recParams] = useSearchParams()

  useEffect(() => {
    const rec = recParams.get('rec')

    if (!rec) {
      return
    }
    setRecommendCode(rec)
  }, [recParams])
  // redux 사용
  const dispatch = useDispatch()
  // 모바일 메뉴
  const [isMobileMenuSidebarShow, setIsMobileMenuSidebarShow] = useState(false)

  useEffect(() => {
    if (isMobileMenuSidebarShow) {
      document.body.style.overflow = 'hidden'
      document.documentElement.style.overflow = 'hidden' // html 요소에도 적용
    } else {
      document.body.style.overflow = 'auto'
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
      document.documentElement.style.overflow = 'auto'
    }
  }, [isMobileMenuSidebarShow])

  const changeMobileMenuSidebarShowHandler = () => {
    setIsMobileMenuSidebarShow(prev => !prev)
  }

  const onClickSignOutHandler = () => {
    siginOut()
      .then(res => {
        window.location.href = '/'
      })
      .catch(error => {
        window.location.href = '/'
      })
  }

  const { holdingMoney, totalGameMoney, holdemMoneyAmount, holdingMemberPoint } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      holdemMoneyAmount: memberInfo.holdemMoneyAmount,
      holdingMemberPoint: memberInfo.memberHoldingPoint,
    }
  })

  const { modalTitle, sideMenuModalShow, mobileSignInShow } = useSelector(state => {
    const { modalInfo } = state

    return {
      modalTitle: modalInfo.modalTitle,
      sideMenuModalShow: modalInfo.modalShow,
      mobileSignInShow: modalInfo.mobileSignInShow,
    }
  })

  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
    if (mobileSignInShow) {
      dispatch(onClickMobileSignInHandlerInSideBar(false))
    }
  }

  // 내정보 모달
  const [myPageModalShow, setMyPageModalShow] = useState(false)
  const onShowMyPageModal = () => {
    setMyPageModalShow(true)
  }
  const onCloseMyPageModal = () => {
    setMyPageModalShow(false)
  }

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onShowSignUpModal = () => {
    setSignUpModalShow(true)
  }
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  useEffect(() => {
    if (!isLogin()) return

    dispatch(fetchMemberInfoAction())

    const intervalId = setInterval(() => {
      dispatch(fetchMemberInfoAction())
    }, 5000)

    return () => {
      clearInterval(intervalId) // 컴포넌트 언마운트 시 인터벌 정리
    }
  }, [loginCheck])

  const modalClickHandler = e => {
    if (!isLogin()) {
      setSignInModalShow(true)
      return
    }
    dispatch(onClickTitleHandler(e))
  }

  const renderModal = title => {
    switch (title) {
      case 'MYPAGE':
        return (
          <MobileMyInfo
            visible={sideMenuModalShow}
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'MONEYDEPOSIT':
        return (
          <MobileMoneyDepositForm
            visible={sideMenuModalShow}
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'MONEYWITHDRAWAL':
        return (
          <MobileMoneyWithdrawalForm
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'POINTEXCHANGE':
        return (
          <MobilePointExchangeForm
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'COUPONUSE':
        return (
          <MobileCouponUseForm
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'NOTE':
        return (
          <MobileNoteList
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'ONETOONE':
        return (
          <MobileOnetoOneList
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      case 'BETTING':
        return (
          <MobileBettingHistoryPage
            visible
            onClose={() =>
              dispatch(
                onShowModalHandler({
                  isModalShow: false,
                  title: '',
                }),
              )
            }
          />
        )
      default:
        return null
    }
  }
  useEffect(() => {
    if (sideMenuModalShow) {
      // 현재 스크롤 위치를 저장합니다.
      const { scrollY } = window
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
    } else {
      // 저장된 스크롤 위치를 복원합니다.
      const savedScrollY = parseInt(document.body.style.top || '0', 10)
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, savedScrollY * -1)
    }
  }, [sideMenuModalShow])

  const [moneyExchangeLoading, setMoneyExchangeLoading] = useState(false)

  const [loading, setLoading] = useState(false)
  const onClickHoldemMoneyWithdrawHandler = () => {
    if (!isLogin()) {
      return
    }
    if (loading) {
      alert('홀덤 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    // if (holdemMoneyAmount <= 0) {
    //   alert('0원 회수는 불가능합니다.')
    //   return
    // }
    setLoading(true)

    // withdrawHoldemMoney()
    //   .then(res => {
    //     dispatch(fetchMemberInfo())
    //     setLoading(false)
    //   })
    //   .catch(error => {
    //     const { errorCode, message } = error.response.data
    //     switch (errorCode) {
    //       case 'CMD-FEIGN-1002':
    //         alert('게임 진행중에는 회수가 불가능합니다.')
    //         break
    //       default:
    //         alert('홀덤 머니 회수에 실패했습니다. 다시 시도해주세요.')
    //         break
    //     }
    //     setLoading(false)
    //   })
  }

  const onClickMenuModalHandler = title => {
    if (!isLogin()) {
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }
  return (
    <>
      {modalTitle && renderModal(modalTitle)}
      {signInModalShow && (
        <MobileSignInPopup
          visible={signInModalShow || mobileSignInShow}
          onClose={onCloseSignInModal}
          onShowSignUpModal={onShowSignUpModal}
        />
      )}
      {mobileSignInShow && (
        <MobileAuthorityAlertPopup
          visible={mobileSignInShow}
          onClose={() => dispatch(onClickMobileSignInHandlerInSideBar(false))}
        />
      )}
      {loading && <CustomLoading />}
      {signUpModalShow && <MobileSignUpPopup visible={signUpModalShow} onClose={onCloseSignUpModal} />}
      <div className="header">
        <div style={{ height: '3px' }} />
        <table
          style={{ padding: '0px', border: '0px', borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}
        >
          <tbody>
            <tr>
              <td style={{ width: '36px' }}>
                <img
                  alt=""
                  style={{ height: '36px', width: '36px' }}
                  src={`${process.env.PUBLIC_URL}/img/header/mobile_menu_btn.png`}
                  onClick={changeMobileMenuSidebarShowHandler}
                />
              </td>
              <td style={{ textAlign: 'center' }}>
                <a
                  onClick={() => {
                    if (pathName === '/mobile/main') {
                      window.location.href = '/mobile/main'
                      return
                    }
                    navigate('/mobile/main')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/logo/logo.png`} style={{ height: '36px' }} alt="" />
                </a>
              </td>
              <td style={{ width: '36px' }} />
            </tr>
          </tbody>
        </table>
        <div style={{ height: '2px' }} />
      </div>

      {/* <MobileHeaderWrap>
        <MobileHeaderInnerWrap>
          <MobileMenuButtonWrap onClick={changeMobileMenuSidebarShowHandler}>
            <MenuButtonIcon />
          </MobileMenuButtonWrap>
          <MobileHeaderLogoImg
            src={`${process.env.PUBLIC_URL}/img/main/Logo.png`}
            style={!isLogin() ? { marginLeft: '-100px' } : { marginLeft: '0px' }}
            alt=""
            onClick={e => {
              navigate('/mobile/main')
            }}
          />
          <MobileHeaderButtonWrap>
            {isLogin() ? (
              <MobileHeaderProfileImg
                src={`${process.env.PUBLIC_URL}/img/header/mobile_profile.png`}
                alt=""
                onClick={e => {
                  dispatch(clearAllInfo())
                  dispatch(
                    onShowModalHandler({
                      isModalShow: true,
                      title: 'MYPAGE',
                    }),
                  )
                }}
              />
            ) : (
              <>
                <SignButton
                  onClick={e => {
                    setSignInModalShow(true)
                  }}
                >
                  <SignButtonInfo>로그인</SignButtonInfo>
                </SignButton>
                <SignUpButton
                  onClick={e => {
                    setSignUpModalShow(true)
                  }}
                >
                  <SignUpButtonInfo>회원가입</SignUpButtonInfo>
                </SignUpButton>
              </>
            )}
          </MobileHeaderButtonWrap>
        </MobileHeaderInnerWrap>
        <MobileAssetWrap>
          <MobileAssetSportsMoney>
            <span>스포츠 캐쉬</span>
            <span style={{ color: `${HermesMainBorder}` }}>{holdingMoney?.toString().replace(commonReg2, ',')} 원</span>
          </MobileAssetSportsMoney>
          <MobileAssetCasinoMoney>
            <CasinoMoneyWithdrawBox onClick={onClickCasinoMoneyWithdrawHandler}>
              <CustomHiOutlineRefresh />
              <span>회수</span>
            </CasinoMoneyWithdrawBox>
            <span>카지노 캐쉬</span>
            <span style={{ color: `${HermesMainBorder}` }}>
              {totalGameMoney?.toString().replace(commonReg2, ',')} 원
            </span>
          </MobileAssetCasinoMoney>
        </MobileAssetWrap>
        <MobileAssetWrap>
          <MobileAssetSportsMoney
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onClickMenuModalHandler('POINTEXCHANGE')
            }}
          >
            <span>보유 포인트</span>
            <span style={{ color: `${HermesMainBorder}` }}>
              {holdingMemberPoint?.toString().replace(commonReg2, ',')} 원
            </span>
          </MobileAssetSportsMoney>
          <MobileAssetCasinoMoney>
            <CasinoMoneyWithdrawBox onClick={onClickHoldemMoneyWithdrawHandler}>
              <CustomHiOutlineRefresh />
              <span>회수</span>
            </CasinoMoneyWithdrawBox>
            <span>홀덤 캐쉬</span>
            <span style={{ color: `${HermesMainBorder}` }}>
              {holdemMoneyAmount?.toString().replace(commonReg2, ',')} 원
            </span>
          </MobileAssetCasinoMoney>
        </MobileAssetWrap>
      </MobileHeaderWrap> */}
      {isMobileMenuSidebarShow && (
        <MobileMenuSidebar
          isMobileMenuSidebarShow={isMobileMenuSidebarShow}
          changeMobileMenuSidebarShowHandler={changeMobileMenuSidebarShowHandler}
          setIsMobileMenuSidebarShow={setIsMobileMenuSidebarShow}
        />
      )}
      {isMobileMenuSidebarShow && <Overlay />}
    </>
  )
}

export default MobileHeader

const CasinoMoneyWithdrawBox = styled.div`
  display: flex;
  min-width: max-content;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  margin-top: -3px;
  &:hover {
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    span {
      background: ${HermesMainText};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  span {
    font-size: 13px;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 2px;
    color: #b2b2b2;
    &:hover {
      color: ${HermesMainBorder} !important;
    }
  }
`

// 카지노 머니 회수 아이콘
const CustomHiOutlineRefresh = styled(HiOutlineRefresh)`
  width: 15px !important;
  height: auto;
  cursor: pointer;
  color: ${HermesMainBorder} !important;
`

const MobileAssetWrap = styled.div`
  width: 100%;

  height: 35px;
  display: flex;
  justify-content: space-between;
  background: rgb(29, 30, 33);
  padding: 5px 10px;
`

const MobileAssetSportsMoney = styled.div`
  // width: 100%;
  display: flex;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  gap: 4px;
`

const MobileAssetCasinoMoney = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  gap: 4px;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); // 검은색 배경에 70% 투명도
  z-index: 999; // 다른 요소들 위에 나타나도록 z-index 설정
`

// mobile header wrap
const MobileHeaderWrap = styled.div`
  background: rgb(22, 23, 26);
  transition: background-color 0.5s linear;

  // padding: 5px 0px;

  position: fixed;
  top: 0;

  width: 100%;

  height: 115px;

  z-index: 500;

  display: flex;
  flex-direction: column;
  align-items: center;
`

// mobile header inner Wrap
const MobileHeaderInnerWrap = styled.div`
  position: relative;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  height: 100%;
`

// mobile header logo
const MobileHeaderProfileImg = styled.img`
  width: 32px;
  height: 32px;
`

const MobileHeaderLogoImg = styled.img`
  width: auto;
  height: 30px;

  position: absolute;
  left: 50%;
  top: 11px;
  transform: translateX(-50%);

  // border: 1px solid green;

  &:hover {
    cursor: pointer;
  }
`

const MobileHeaderButtonWrap = styled.div`
  width: min-content;
  height: 100%;

  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
  svg {
    color: rgb(112, 116, 120);
    width: 35px;
    height: 35px;
  }
`

const SignButtonInfo = styled.span`
  line-height: 22px;
  font-weight: 600;
  color: #fff;
`

const SignButton = styled.button`
  width: 100px;
  height: 38px;
  background: none;
  border-radius: 6px !important;
  outline: none;

  border: 2px solid ${HermesMainBorder};
`

const SignUpButton = styled.button`
  width: 100px;
  height: 38px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  outline: none;

  border: 2px solid ${HermesMainBorder};
`

// const SignButton = styled(ButtonH40)`
//   width: 75px;
//   height: 35px;

//   // 마우스 호버
//   &:hover {
//     ${SignButtonInfo} {
//       color: #ffc01a;
//     }
//   }

//   // 클릭
//   &:focus,
//   &:active,
//   &active:focus {
//     ${SignButtonInfo} {
//       color: #ffc01a;
//     }
//   }

//   // 비활성화
//   &:disabled {
//     ${SignButtonInfo} {
//       color: #ffffff4d;
//     }
//   }
// `

const SignUpButtonInfo = styled.span`
  font-style: normal;
  line-height: 22px;
  font-weight: 600;
  color: #000;
`

// 모바일 메뉴 버튼 wrap
const MobileMenuButtonWrap = styled.div`
  width: min-content;
  height: 100%;

  display: flex;
  align-items: center;

  margin-right: auto;

  &:hover {
    cursor: pointer;
  }
`

// 모바일 메뉴 아이콘
const MenuButtonIcon = styled(GiHamburgerMenu)`
  color: rgb(112, 116, 120);
  width: 20px;
  height: 20px;
`
