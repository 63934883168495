import { SocketIOEventName } from '../utils/enums/WebSocket/SocketIOEventName'
import { SocketIORequestMessageType } from '../utils/enums/WebSocket/SocketIORequestMessageType'
import { emitPromise, socket } from '../utils/socket'
import { getAccessToken } from '../utils/token'

/**
 * 로그인
 */
export const handleSignIn = async signInData => {
  try {
    const payload = {
      type: SocketIORequestMessageType.USER_LOGIN,
      payload: signInData,
    }

    return await emitPromise(socket, SocketIOEventName.CREATE, payload)
  } catch (error) {
    console.log('error ', error)
  }
}

/**
 * 토큰 발급
 */
export const createAccessToken = async tokenData => {
  try {
    const payload = {
      type: SocketIORequestMessageType.AUTHENTICATION,
      payload: tokenData,
    }

    return await emitPromise(socket, SocketIOEventName.CREATE, payload)
  } catch (error) {
    console.log('error ', error)
  }
}

/**
 * 토큰 재발급
 * 새로고침할때마다..
 */
export const handleReAuthentication = async () => {
  try {
    const payload = {
      type: SocketIORequestMessageType.RE_AUTHENTICATION,
      payload: {
        accessToken: getAccessToken(),
      },
    }

    return await emitPromise(socket, SocketIOEventName.CREATE, payload)
  } catch (error) {
    console.log('error ', error)
  }
}

/**
 * 토큰 제거
 */
export const handleDeleteAuthentication = async data => {
  try {
    const payload = {
      type: SocketIORequestMessageType.AUTHENTICATION,
      payload: data,
    }

    return await emitPromise(socket, SocketIOEventName.DELETE, payload)
  } catch (error) {
    console.log('error ', error)
  }
}

/**
 * 페이지 이동시마다 유저 로그 생성
 */
export const handleCreateClientUserLog = async data => {
  try {
    const payload = {
      type: SocketIORequestMessageType.CLIENT_USER_LOG,
      payload: data,
    }

    return await emitPromise(socket, SocketIOEventName.CREATE, payload)
  } catch (error) {
    console.log('error ', error)
  }
}
