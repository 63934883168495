import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { FaChevronDown } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { onShowModalHandler, toggleLeftMenuSidebarShow } from '../../redux/modalSlice'
import { selectSideSportMenu } from '../../redux/sportsOverseasInfoSlice'
import isLogin from '../../utils/enums/authority'
import CustomAlert from '../CustomAlert'
import AuthorityAlertPopup from '../Popup/Error/AuthorityAlertPopup'
import { HermesMainBorder } from '../../utils/palette'

const SportsGameSidebar = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { overseasCategory } = useSelector(state => {
    const { sportsOverseasInfo } = state

    return {
      overseasCategory: sportsOverseasInfo.overseasCategory,
    }
  })

  const { noteRedisSize, oneToOneRedisSize } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const { isShowLeftMenuSidebar } = useSelector(state => {
    const { modalInfo } = state

    return {
      isShowLeftMenuSidebar: modalInfo.isShowLeftMenuSidebar,
    }
  })

  const [isSmallSize, setIsSmallSize] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      const resultMatches = window.matchMedia('(max-width: 1670px)').matches

      setIsSmallSize(resultMatches)
      if (resultMatches) {
        dispatch(toggleLeftMenuSidebarShow(false))
      } else {
        dispatch(toggleLeftMenuSidebarShow(true))
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [isOpenedQuickLinks, setIsOpenedQuickLinks] = useState(true)

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onClickMenuModalHandler = title => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }

  const [selectedSportId, setSelectedSportId] = useState(-1)
  const [selectedLocationId, setSelectedLocationId] = useState(-1)

  // 종목 선택했을때
  const onClickSportHandler = sportId => {
    if (selectedSportId === -1 || selectedSportId !== sportId) {
      setSelectedSportId(sportId)
    } else {
      if (!isLogin()) {
        setAuthorityAlertShow(true)
        return
      }

      if (noteRedisSize > 0) {
        setNoteAlertShow(true)
        return
      }

      // 초기화
      setSelectedSportId(-1)

      dispatch(
        selectSideSportMenu({
          sportId,
          locationId: -1,
          leagueId: -1,
        }),
      )

      // TODO 국내 해외 스페셜 라이브 구분하기
      if (location.pathname !== '/sports/overseas') {
        navigate('/sports/overseas')
      }
    }
  }

  // 국가 선택시
  const onClickLocationHandler = (sportId, locationId) => {
    if (selectedLocationId === -1 || selectedLocationId !== locationId) {
      setSelectedSportId(sportId)
      setSelectedLocationId(locationId)
    } else {
      if (!isLogin()) {
        setAuthorityAlertShow(true)
        return
      }

      if (noteRedisSize > 0) {
        setNoteAlertShow(true)
        return
      }

      // 초기화
      setSelectedSportId(-1)
      setSelectedLocationId(-1)

      dispatch(
        selectSideSportMenu({
          sportId,
          locationId,
          leagueId: -1,
        }),
      )

      // TODO 국내 해외 스페셜 라이브 구분하기
      if (location.pathname !== '/sports/overseas') {
        navigate('/sports/overseas')
      }
    }
  }

  // 스포츠 리그 선택시
  const onClickLeagueHandler = (sportId, locationId, leagueId) => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    // 초기화
    setSelectedSportId(-1)
    setSelectedLocationId(-1)

    dispatch(
      selectSideSportMenu({
        sportId,
        locationId,
        leagueId,
      }),
    )

    // TODO 국내 해외 스페셜 라이브 구분하기
    if (location.pathname !== '/sports/overseas') {
      navigate('/sports/overseas')
    }
  }

  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    navigate(link)
  }

  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {authorityAlertShow && <AuthorityAlertPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />}
      <SidebarWrap className={`${isSmallSize ? 'smallSize' : ''} ${isShowLeftMenuSidebar ? 'menuOpen' : ''}`}>
        <SlimScrollDiv>
          <SidbarMenuWrap className="sidebar-container">
            <SportsMenuBodyWrap>
              <SportsMenuContentBox>
                {overseasCategory.map(sport => {
                  if (sport.sportId === -1) return

                  return (
                    <>
                      <SportsMenuItemWrap
                        onClick={() => {
                          onClickSportHandler(sport.sportId)
                        }}
                      >
                        <SportsMenuItemBox>
                          <div
                            style={{
                              whiteSpace: 'nowrap',
                              position: 'relative',
                              display: 'block',
                              width: '100%',
                            }}
                          >
                            <SportsMenuItemCount>{sport.sportCount}</SportsMenuItemCount>
                            <SportsMenuItemIconBox>
                              <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-${sport.sportId}.png`} alt="" />
                            </SportsMenuItemIconBox>
                            <SportsMenuItemText>{sport.sportName}</SportsMenuItemText>
                          </div>
                        </SportsMenuItemBox>
                      </SportsMenuItemWrap>
                      {sport.locations.length > 0 && (
                        <SportCustomCollapse in={sport.sportId === selectedSportId}>
                          <div>
                            {sport.locations.map(locationData => {
                              return (
                                <>
                                  <SportsMenuItemWrap
                                    onClick={() => {
                                      onClickLocationHandler(sport.sportId, locationData.locationId)
                                    }}
                                  >
                                    <SportsMenuItemBox active={locationData.locationId === selectedLocationId}>
                                      <div
                                        style={{
                                          whiteSpace: 'nowrap',
                                          position: 'relative',
                                          display: 'block',
                                          width: '100%',
                                        }}
                                      >
                                        <SportsMenuItemCount>{locationData.locationCount}</SportsMenuItemCount>
                                        <SportsMenuItemIconBox>
                                          <img src={locationData.locationImageUrl} alt="" />
                                        </SportsMenuItemIconBox>
                                        <SportsMenuItemText>{locationData.locationName}</SportsMenuItemText>
                                      </div>
                                    </SportsMenuItemBox>
                                  </SportsMenuItemWrap>
                                  {locationData.leagues.length > 0 && (
                                    <SportCustomCollapse in={locationData.locationId === selectedLocationId}>
                                      <div>
                                        {locationData.leagues.map(league => {
                                          return (
                                            <SportsMenuItemWrap
                                              onClick={() => {
                                                onClickLeagueHandler(
                                                  sport.sportId,
                                                  locationData.locationId,
                                                  league.leagueId,
                                                )
                                              }}
                                            >
                                              <SportsMenuItemBox isLeague>
                                                <div
                                                  style={{
                                                    whiteSpace: 'nowrap',
                                                    position: 'relative',
                                                    display: 'block',
                                                    width: '100%',
                                                  }}
                                                >
                                                  <SportsMenuItemCount>{league.leagueCount}</SportsMenuItemCount>
                                                  <SportsMenuItemIconBox>
                                                    <img src={league.leagueImageUrl} alt="" />
                                                  </SportsMenuItemIconBox>
                                                  <SportsMenuItemText>{league.leagueName}</SportsMenuItemText>
                                                </div>
                                              </SportsMenuItemBox>
                                            </SportsMenuItemWrap>
                                          )
                                        })}
                                      </div>
                                    </SportCustomCollapse>
                                  )}
                                </>
                              )
                            })}
                          </div>
                        </SportCustomCollapse>
                      )}
                    </>
                  )
                })}
              </SportsMenuContentBox>
            </SportsMenuBodyWrap>
          </SidbarMenuWrap>
        </SlimScrollDiv>
      </SidebarWrap>
    </>
  )
}

export default SportsGameSidebar

// collapse 부분
const QuickLinksCollapse = styled.div`
  height: 44px;
  padding: 12px 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  display: table;
  transition: background-color 0.1s ease-out 0s;
  white-space: nowrap;

  &:hover {
    background-color: #1f1f1f;
  }

  margin-top: 8px;
  font-weight: 900;
  cursor: pointer;
`

const SportsMenuHeaderBox = styled.div`
  padding: 12px 8px;
  white-space: nowrap;
  font-weight: 900;
  margin-top: 8px
  display: block;

`

const SportsMenuItemCount = styled.div`
  font-weight: 700;
  float: right;
  padding: 5px 0;
  padding-top: 7px;
  margin-top: 1px;
  display: inline;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  width: 16.66667%;
  background: #2b2b2b;
  font-size: 13px;
`

const SportsMenuItemIconBox = styled.div`
  width: 12.5%;
  float: left;
  display: block;
  margin-top: 4px;

  img {
    width: 16px;
  }
`

const SportsMenuItemText = styled.div`
  padding: 2px 3px 3px 3px;
  width: 70.83333%;
  float: left;
  font-size: 13px;
  display: block;
`

const SidbarQuickItemText = styled.div`
  float: left;
  padding-left: 24px;
  width: calc(100% - 16px);
  font-size: 14px;
`

const SidbarMenuWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-bottom: 30px;
  &::-webkit-scrollbar {
    width: 0 !important; /* 스크롤 바 너비를 0으로 설정하여 숨김 */
  }
`

const SidebarWrap = styled.div`
  transition: all 0.5s ease-out 0s;
  width: 50px; // 기본적으로 닫힌 상태
  position: fixed;
  margin-top: 180px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1010;
  -webkit-transform: translateZ(0);

  ${QuickLinksCollapse}, ${SportsMenuHeaderBox}, ${SportsMenuItemCount}, ${SportsMenuItemText}, ${SidbarQuickItemText} {
    display: none; // 기본적으로 숨김
  }

  ${SidbarMenuWrap} {
    &::-webkit-scrollbar {
      width: 0; /* 스크롤 바 너비를 0으로 설정하여 숨김 */
    }
  }

  &.menuOpen,
  &.smallSize:hover,
  &:not(.smallSize):not(.menuOpen):hover {
    width: 271px;

    ${QuickLinksCollapse} {
      display: table;
    }
    ${SportsMenuHeaderBox}, ${SportsMenuItemCount}, ${SportsMenuItemText}, ${SidbarQuickItemText} {
      display: block;
    }
    ${SidbarMenuWrap} {
      &::-webkit-scrollbar {
        width: 6px; /* 스크롤 바 너비를 0으로 설정하여 숨김 */
      }
    }
  }
`

// 스크롤 용도
const SlimScrollDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  height: 100%;
`

// text-white 부분
const QuickLinksWrap = styled.div`
  color: #fff;
  padding: 35px 8px 0 8px;
`

const QuickLinksText = styled.div`
  width: calc(100% - 24px);
  float: left;
  font-size: 14px;
`

const CustomFaChevronDown = styled(FaChevronDown)`
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-top: 3px;
  margin-right: 10px;
  float: right;
`

const CustomCollapse = styled(Collapse)`
  &.collapsing {
    will-change: height;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
    // transition: height 3s ease-in-out !important;
  }
`

const SportCustomCollapse = styled(Collapse)`
  &.collapsing {
    will-change: height;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
    // transition: height 3s ease-in-out !important;
  }
`

const QuickLinksBox = styled.div``

const SidbarQuickItem = styled.div`
  height: 44px;
  padding: 12px 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  display: table;
  transition: background-color 0.1s ease-out 0s;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background-color: #232425;
    color: ${HermesMainBorder};
    font-weight: 700;
  }
`

const SidbarQuickItemIconBox = styled.div`
  float: left;
  width: 16px;
  padding-left: 2px;
  diplay: block;
  cursor: pointer;
  white-space: nowrap;
  img {
    width: 100%;
  }
`

const SportsMenuBodyWrap = styled.div`
  border-radius: 0 0 3px 3px;
  background: #fff;
  padding: 0 8px;
  display: block;
`

const SportsMenuContentBox = styled.div`
  display: block;
`

const SportsMenuItemWrap = styled.div`
  position: relative;
  display: block;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
`
const SportsMenuItemBox = styled.div`
  line-height: 20px;
  position: relative;
  display: flex;
  background: #fff;
  padding: 12px 8px;
  border: none;
  transition: background-color 0.1s ease-out 0s;
  color: #000;
  cursor: pointer;
  height: 100%;
  border: solid 1px black;

  &:hover {
    cursor: pointer;
    background-color: #232425;
    color: ${HermesMainBorder};
    font-weight: 700;
  }

  ${props =>
    props.active &&
    `
    background-color: #232425;
    color: ${HermesMainBorder};
    font-weight: 700;
`}

  ${props =>
    props.isLeague &&
    `
    padding-left: 17px;
    &:hover {
      background-color: #555759;
      color: ${HermesMainBorder};
    font-weight: 700;
    }
`}
`

const SilverStyled = styled.div`
  border: none;

  // Colors - 위아래 순서 바꾸기!!
  background-image: linear-gradient(180deg, #525252 23.07%, #262626 91.02%),
    linear-gradient(180deg, #ffffff 0%, #424242 58.85%, #c1c1c1 63.02%, #dedede 83.85%, #313131 100%);

  background-origin: border-box;
  background-clip: content-box, border-box;

  // 마우스 호버
  &:hover {
    cursor: pointer;

    // css 나온대로 쓰고
    background: #000000;

    background: linear-gradient(0deg, #000000, #000000),
      linear-gradient(180deg, #ffffff 0%, #424242 58.85%, #c1c1c1 63.02%, #dedede 83.85%, #313131 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    cursor: pointer;

    background: #383838;

    background: linear-gradient(0deg, #383838, #383838),
      linear-gradient(180deg, #ffffff 0%, #424242 58.85%, #c1c1c1 63.02%, #dedede 83.85%, #313131 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 비활성화
  &:disabled {
    box-shadow: none;
    background: #242424;

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffffff4d;
  }
`

const GoldStyled = styled.div`
  border: none;
  // border: 1px solid #edae07;

  background: linear-gradient(180deg, #e2c98d 23.07%, #ba9954 99.82%);
  box-shadow: 0px 0px 5px 0px #745400 inset;

  background-image: linear-gradient(180deg, #e2c98d 23.07%, #ba9954 99.82%),
    linear-gradient(180deg, #fff7e4 0%, #fde2ab 53.12%, #c89030 67.19%, #ffe9b9 92.71%, #4a2111 100%);

  background-origin: border-box;
  background-clip: content-box, border-box;

  // 마우스 호버
  &:hover {
    cursor: pointer;

    // css 나온대로 쓰고
    background: #000000;

    background-image: linear-gradient(0deg, #000000, #000000),
      linear-gradient(180deg, #fff7e4 0%, #fde2ab 53.12%, #c89030 67.19%, #ffe9b9 92.71%, #4a2111 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    cursor: pointer;

    background: #383838;

    background-image: linear-gradient(0deg, #383838, #383838),
      linear-gradient(180deg, #fff7e4 0%, #fde2ab 53.12%, #c89030 67.19%, #ffe9b9 92.71%, #4a2111 100%);

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffc01a;
  }

  // 비활성화
  &:disabled {
    box-shadow: none;
    background: #242424;

    // 이건 계속 살려야 함
    background-origin: border-box;
    background-clip: content-box, border-box;

    color: #ffffff4d;
  }
`
