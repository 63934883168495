import { commonReg2 } from '@/utils/validate/commonValidate'
import { gameTypeEnums } from '../../utils/enums/SystemManagement/gameEnums'

const GameMoneyListData = content => {
  const columns = [
    {
      Header: '게임사',
      accessor: 'vendorName',
    },
    {
      Header: '게임사명',
      accessor: 'vendorNameKo',
    },
    {
      Header: '타입',
      accessor: 'vendorType',
      Cell: r => {
        const { row } = r
        const { original } = row
        return gameTypeEnums[original.vendorType]
      },
    },
    {
      Header: '게임머니',
      accessor: 'gameMoney',
      Cell: r => {
        const { row } = r
        const { original } = row
        return `${original.gameMoney.toString().replace(commonReg2, ',')} 원`
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default GameMoneyListData
