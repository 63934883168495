import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { deleteVirtualGameBettingHistory, searchVirtualGameBettingHistory } from '../../api/game/virtualGameApi'
import CustomLoading from '../../containers/CustomLoading'
import MobileCustomPagination from '../../shared/components/MobileCustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import {
  FixtureBetResult,
  VirtualGameBettingTypeKr,
  VirtualGameLeagueTypeKr,
  VirtualGameResultStatusEnums,
  VirtualGameSportType,
  VirtualResultSettlementTypeEnums,
} from '../../utils/enums/VirtualGame/VirtualGameEnums'
import { HermesMainBorder, HermesMainText } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'

const MobileVirtualGameBettingHistory = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [virtualGameBettingHistoryParams, setVirtualGameBettingHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setVirtualGameBettingHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [virtualGameSportType, setVirtualGameSportType] = useState(null)

  const onClickVirtualGameTypeHandler = type => {
    setVirtualGameSportType(type)

    setVirtualGameBettingHistoryParams(prev => ({
      ...prev,
      page: 0,
      virtualGameSportType: type || null,
    }))
  }

  const [totalElements, setTotalElements] = useState(0)
  const [virtualGameBettingHistoryContent, setVirtualGameBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchVirtualGameBettingHistory = async params => {
    await searchVirtualGameBettingHistory(params)
      .then(res => {
        setTotalElements(res.data.totalElements)
        setVirtualGameBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchVirtualGameBettingHistory(virtualGameBettingHistoryParams)
  }, [virtualGameBettingHistoryParams])

  /**
   * 페이징처리
   */
  const virtualGameBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (virtualGameBettingHistoryWrapRef.current) {
      virtualGameBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)

      deleteVirtualGameBettingHistory({
        virtualBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchVirtualGameBettingHistory(virtualGameBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchVirtualGameBettingHistory(virtualGameBettingHistoryParams)
        })
    }
  }

  const getFixtureBetResult = detailItem => {
    console.log(detailItem)
    switch (detailItem.sportType) {
      case 'VIRTUAL_SOCCER':
        return (
          <>
            {VirtualResultSettlementTypeEnums[detailItem.betSettlementType]}
            &nbsp;
            {detailItem.homeScore} : {detailItem.awayScore}
          </>
        )
      case 'VIRTUAL_BASKETBALL':
        return (
          <>
            {VirtualResultSettlementTypeEnums[detailItem.betSettlementType]}
            <br />
            <span style={{ color: 'black' }}>승패 : {FixtureBetResult[detailItem.winDrawLoseResult]}</span>
            <br />
            <span style={{ color: 'black' }}>핸디 : {FixtureBetResult[detailItem.handicapResult]}</span>
            <br />
            <span style={{ color: 'black' }}>언더/오버 : {FixtureBetResult[detailItem.overUnderResult]}</span>
          </>
        )
      case 'VIRTUAL_BASEBALL':
        return (
          <>
            {VirtualResultSettlementTypeEnums[detailItem.betSettlementType]}
            &nbsp;
            {detailItem.homeScore} : {detailItem.awayScore}
          </>
        )
      case 'VIRTUAL_GREYHOUNDS':
        return (
          <>
            {VirtualResultSettlementTypeEnums[detailItem.betSettlementType]}&nbsp; [{detailItem.raceFirstNumber}]{' '}
            {detailItem.raceFirstName}
          </>
        )
      case 'VIRTUAL_HORSE':
        return (
          <>
            {VirtualResultSettlementTypeEnums[detailItem.betSettlementType]}&nbsp; [{detailItem.raceFirstNumber}]{' '}
            {detailItem.raceFirstName}
          </>
        )
      default:
        return ''
    }
  }

  const getFixtureRowInfoCompo = detailItem => {
    switch (detailItem.marketId) {
      case 1:
      case 2:
        // 승무패
        // 승패
        return <>{VirtualGameBettingTypeKr[detailItem.selectedBetType]}</>
      case 3:
        // 핸디
        return (
          <>
            {VirtualGameBettingTypeKr[detailItem.selectedBetType]} {detailItem.handicapPoint}
          </>
        )
      case 4:
        // 언더오버
        return (
          <>
            {VirtualGameBettingTypeKr[detailItem.selectedBetType]} {detailItem.overUnderPoint}
          </>
        )
      case 6:
      case 7:
        // 총 골수
        // 더블찬스
        return <>{VirtualGameBettingTypeKr[detailItem.selectedBetType]}</>
      case 8:
        // 우승 맞추기
        return (
          <>
            [{detailItem.selectedRaceNumber}] {detailItem.selectedRaceName}{' '}
            {detailItem.selectedRaceRider && `, ${detailItem.selectedRaceRider}`}
          </>
        )
      case 9:
        // 홀짝
        return <> {VirtualGameBettingTypeKr[detailItem.selectedBetType]}</>
      default:
        return ''
    }
  }

  const getFixtureResultCompo = detailItem => {
    switch (detailItem.sportType) {
      case 'VIRTUAL_SOCCER':
        return (
          <div className="h-team" style={{ width: '60%' }}>
            <div className="default">
              <b style={{ paddingRight: '5px' }}>
                &nbsp;{detailItem.homeName} VS {detailItem.awayName}
              </b>
            </div>
          </div>
        )
      case 'VIRTUAL_BASKETBALL':
        return (
          <div className="h-team" style={{ width: '60%' }}>
            <div className="default">
              <b style={{ paddingRight: '5px' }}>
                &nbsp;{detailItem.homeName} VS {detailItem.awayName}
              </b>
            </div>
          </div>
        )
      case 'VIRTUAL_BASEBALL':
        return (
          <div className="h-team" style={{ width: '60%' }}>
            <div className="default">
              <b style={{ paddingRight: '5px' }}>
                &nbsp;{detailItem.homeName} VS {detailItem.awayName}
              </b>
            </div>
          </div>
        )
      case 'VIRTUAL_GREYHOUNDS':
        return (
          <div className="h-team" style={{ width: '60%' }}>
            <div className="default">
              <b style={{ paddingRight: '5px' }}>
                {detailItem.raceFirstName ? (
                  <span>
                    우승: [{detailItem.raceFirstNumber}] {detailItem.raceFirstName}
                  </span>
                ) : (
                  '-'
                )}
              </b>
            </div>
          </div>
        )
      case 'VIRTUAL_HORSE':
        return (
          <div className="h-team" style={{ width: '60%' }}>
            <div className="default">
              <b style={{ paddingRight: '5px' }}>
                {detailItem.raceFirstName ? (
                  <span>
                    우승: [{detailItem.raceFirstNumber}] {detailItem.raceFirstName}
                  </span>
                ) : (
                  '-'
                )}
              </b>
            </div>
          </div>
        )
      default:
        return ''
    }
  }

  return (
    <>
      {(deleteLoading || loading) && <CustomLoading />}
      <ListWrap style={{ width: '100%' }}>
        <SubMenuItemListWrap>
          <SubMenuItemBox
            active={!virtualGameSportType}
            onClick={() => {
              onClickVirtualGameTypeHandler(null)
            }}
          >
            전체
          </SubMenuItemBox>
          {Object.entries(VirtualGameSportType).map(([key, value]) => {
            return (
              <SubMenuItemBox
                active={key === virtualGameSportType}
                onClick={() => {
                  onClickVirtualGameTypeHandler(key)
                }}
              >
                {value}
              </SubMenuItemBox>
            )
          })}
        </SubMenuItemListWrap>
      </ListWrap>
      {virtualGameBettingHistoryContent.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          가상스포츠 배팅내역이 없습니다.
        </NoContentDiv>
      ) : (
        <>
          <div style={{ marginLeft: '10px', display: 'flex', gap: '5px' }}>
            <div
              style={{
                marginLeft: '5px',
                display: 'flex',
                gap: '5px',
                marginTop: '5px',
                width: '100%',
                justifyContent: 'right',
                paddingRight: '10px',
              }}
            >
              <button
                type="button"
                className="normal_button"
                style={{ height: '20px', width: '80px', color: '#000' }}
                onClick={() => {
                  // setCheckRadio(
                  //   sportsBettingHistoryContent
                  //     .filter(value => value.sportsResultStatus !== 'ING')
                  //     .map(value => value.sportsBettingLogKey),
                  // )
                }}
              >
                전체선택
              </button>
              <button
                type="button"
                style={{ height: '20px', width: '80px', color: '#000' }}
                className="normal_button"
                onClick={() => onDeleteHandler()}
              >
                삭제하기
              </button>
            </div>
          </div>
          {virtualGameBettingHistoryContent.map((item, index) => (
            <>
              <div className="hisinfo">
                <span style={{ float: 'right' }}>배팅시간 : {convertToKstByFormat(item.bettingAt)}</span>
              </div>

              <div className="choice_wrap2">
                {item.virtualDetailBettingLogList.map(detail => (
                  <div className="choice_wrap">
                    <div className="league">
                      {VirtualGameLeagueTypeKr[detail.leagueType]} &nbsp;[{detail.marketName}]
                    </div>
                    <div className="date">{convertToKstByFormat(detail.startDate, 'MM-DD HH:mm')}</div>

                    {getFixtureResultCompo(detail)}

                    <div className="center" style={{ width: '30%' }}>
                      <div
                        className="selected"
                        style={{
                          height: '38px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <b>{getFixtureRowInfoCompo(detail)}</b>
                      </div>
                    </div>
                    <div className="a-team" style={{ width: '10%' }}>
                      <div className="default">
                        &nbsp;<b>{detail.selectedBetPrice || '-'}</b>
                      </div>
                    </div>
                    <div className="result_wrap">
                      <div className="icon" />
                      <div className="value">
                        결과 :{' '}
                        <font>
                          <b>
                            <BettingHistoryContentBoxFooterInfo2 bettingResult={detail.betSettlementType}>
                              {getFixtureBetResult(detail)}
                            </BettingHistoryContentBoxFooterInfo2>
                          </b>
                        </font>
                        {/* <b>
                          <BettingHistoryContentBoxFooterInfo bettingResult={detail.betSettlementType}>
                            {SportsResultSettlementTypeEnumsV2[detail.betSettlementType]}
                          </BettingHistoryContentBoxFooterInfo>
                        </b> */}
                        {/* &nbsp; */}
                        {/* <span style={{ color: '#666666' }}>│</span>
                        &nbsp;스코어 : 0 vs 0&nbsp;<span style={{ color: '#666666' }}>│</span>
                        &nbsp;당첨 : <span style={{ color: '#fff' }}>진행</span> */}
                      </div>
                    </div>
                  </div>
                ))}

                <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="result_info2">
                  <tbody>
                    <tr>
                      <td>배팅금액</td>
                      <td className="result_info2_td">{item.bettingAmount.toString().replace(commonReg2, ',')}원</td>
                      <td>결과</td>
                      <td className="result_info2_td">
                        <font>
                          <b>
                            <BettingHistoryContentBoxFooterInfo1 bettingResult={item.virtualResultStatus}>
                              {VirtualGameResultStatusEnums[item.virtualResultStatus]}
                            </BettingHistoryContentBoxFooterInfo1>
                          </b>
                        </font>
                      </td>
                    </tr>
                    <tr>
                      <td>배당</td>
                      <td className="result_info2_td">
                        {(() => {
                          const {
                            realReduceBettingPerForSingleFolder,
                            realReduceBettingPerForCombination,
                            totalOddValue,
                            originTotalOddValue,
                          } = item
                          const reduceBettingValue =
                            realReduceBettingPerForSingleFolder || realReduceBettingPerForCombination

                          if (!reduceBettingValue) {
                            return <span>{totalOddValue}</span>
                          }

                          return (
                            <>
                              {Number(reduceBettingValue) < 0 ? (
                                <span>
                                  ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)}{' '}
                                  {reduceBettingValue?.toFixed(2)})
                                </span>
                              ) : (
                                <span>
                                  ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)} +{' '}
                                  {reduceBettingValue?.toFixed(2)})
                                </span>
                              )}
                            </>
                          )
                        })()}
                      </td>
                      <td>예상당첨금</td>
                      <td className="result_info2_td">
                        {item.bettingExpectedAmount.toString().replace(commonReg2, ',')}원
                      </td>
                    </tr>
                    <tr>
                      <td>당첨금액</td>
                      <td className="result_info2_td">
                        {item.bettingResultAmount.toString().replace(commonReg2, ',')}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {item.virtualResultStatus !== 'ING' && (
                  <span style={{ float: 'left' }}>
                    {/* <a>
                      <BettingHistoryRadio
                        sportsBettingLogKey={item.sportsBettingLogKey}
                        sportsResultStatus={item.sportsResultStatus}
                        checkRadio={checkRadio}
                        setCheckRadio={setCheckRadio}
                      />
                    </a> */}
                  </span>
                )}
              </div>
            </>
          ))}
          <>
            <MobileCustomPagination
              currentPage={page}
              size={size}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </>
        </>
      )}
    </>
  )
}

export default MobileVirtualGameBettingHistory

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 100px;
    height: auto;
  }
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const ListWrap = styled.div`
  display: flex;
  text-align: right;
  align-items: right;
  justify-content: right;
  padding-left: 10px;
  gap: 10px;
`

const ResultBox = styled.div`
  position: absolute;
  display: block;
  padding: 0px;
  text-align: center;
  z-index: 150;
  width: 60px !important;
  height: 14px !important;
  line-height: 14px;
  bottom: 1px;
  left: 50%;
  margin-left: -56px;
  margin-bottom: -1px;
  span {
    border-radius: 36px 36px 0px 0px;
    width: 100%;
    height: 100%;
    display: block;
    font-weight: bold;
    background: #fff;
    color: ${props => getColor(props.bettingResult)};
    border: 1px solid #d5d4d3;
    border-bottom: 0px;
  }
`
const BettingHistoryTopHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: black;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-top: solid 1px gray;
  height: 25px;
  align-items: center;
  padding: 0px 5px;
`

const BettingHistoryHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
  height: 25px;
  align-items: center;
`

const BettingHistoryTh = styled.th`
  border: none !important;
  width: ${props => props.width && `${props.width}%`};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;

  span {
    padding-left: 2px;
    width: 100%;
    font-weight: 300;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    img {
      width: 15px;
      height: 15px;
      margin-right: 2px;
      margin-bottom: -3px;
    }
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 600;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
  th {
    background: rgba(48, 48, 48, 1);
    padding: 2px 2px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead``

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getFonwWeight = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '900'
    case bettingResult === 'LOSE':
      return '900'
    case bettingResult === 'DRAW':
      return '900'
    default:
      return '300'
  }
}

const getColor2 = settlementType => {
  switch (true) {
    case settlementType === 'WINNER':
      return 'blue'
    case settlementType === 'LOSER':
      return 'red'
    case settlementType === 'REFUND':
      return 'grey'
    case settlementType === 'NOT_SETTLED':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'black'
    default:
      return '#000'
  }
}

const BettingHistoryContentBoxFooterInfo = styled.span`
  font-weight: 500;
  color: ${props => getColor2(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo1 = styled.span`
  width: 45%;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo2 = styled.span`
  width: 45%;
  color: ${props => getColor2(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo3 = styled.span`
  width: 24%;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;

  span {
  }
`

const BettingHistoryBottomContentBox = styled.div`
  padding-left: 5px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 22px;
  span {
  }
`

const BettingHistoryBottomTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  // border-bottom: solid 1px gray;
  &:last-child {
    border-bottom: solid 1px gray;
  }
`

const getColor3 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#f7f754'
    case 'SPECIAL':
      return '#84f567'
    case 'OVERSEAS':
      return '#ee73f5'
    case 'LIVE':
      return '#faba73'
    default:
      return '#000'
  }
}

const BettingHistoryContentItemBox = styled.span`
  width: 100%;
  color: ${props => getColor3(props.sportMarketType)};
`

const BettingCancelBox = styled.div`
  width: 100%;
`

const BettingCancelButton = styled.button`
  width: 20%;
  position: relative;
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: auto;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${HermesMainBorder};
  padding: 2px;
  background: #262626;
`

const BettingHistoryLongContentItemDetailInfo1 = styled.div`
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 2px 1px 2px;
  float: left;

  span {
    font-weight: 300;
  }
`

const BettingHistoryContentItemDetailInfo1 = styled.div`
  width: 60%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 2px 1px 2px;
  float: left;

  span {
    font-weight: 300;
  }
`

const BettingHistoryContentItemDetailInfo2 = styled.div`
  width: 39%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  cursor: pointer;
  padding: 0 5px;
  font-weight: 600;

  span {
    font-size: 11px;
  }
`

const SubMenuItemBox = styled.div`
  padding: 0px;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 24px;
  width: 80px;
  height: 23px;
  border: solid 1px #dedede;
  ${props =>
    props.active &&
    `
    border: solid 1px  #000 !important;
    color: #000 !important;
    `}
`

const SubMenuItemListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: left;
  margin-top: 0px;
  padding-bottom: 5px;
  gap: 10px;
  // border: solid 2px red;
`
