import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 게시판 관리 조회 API
export async function searchBoardList(params) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/boardlist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 메인페이지 공지사항, 이벤트 조회
export async function searchMainNoticeEventList(params) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/boardlist/main',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchBoardList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchBoardList() {
    await searchBoardList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchBoardList()
  }, [params])

  return { totalElement, content, fetchSearchBoardList }
}

// 쪽지 조회 API
export async function searchNoteList(params) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/notelist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchNoteList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchNoteList() {
    await searchNoteList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchNoteList()
  }, [params])

  return { totalElement, content, fetchSearchNoteList }
}

// 1:1문의 삭제 API
export async function deleteOnetoOneList(body) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/onetoone',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 1:1문의 관리 조회 API
export async function searchOnetoOneList(params) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/onetoone',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchOnetoOneList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchOnetoOneList() {
    await searchOnetoOneList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchOnetoOneList()
  }, [params])

  return { totalElement, content, fetchSearchOnetoOneList }
}

// 1:1문의 등록 API
export async function createOnetoOne(body) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/onetoone',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 회원 쪽지 상태값 변경 API
 */
export async function updateNoteStatus(body) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/notelist/notestatus',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 회원 전체읽기
 */
export async function updateNoteStatusAll(body) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/notelist/notestatus/all',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 쪽지 삭제 API
export async function deleteNoteList(body) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/notelist',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

export async function searchNoReadNoteCount() {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/notelist/count',
    method: 'GET',
  }).then(res => {
    return res
  })
  return response
}

/**
 * 답변 확인
 */
export async function memberConfirmOneToOneAnswer(body) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/onetoone/answer',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function searchAnswerCompleteOneToOne() {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/onetoone/count',
    method: 'GET',
  }).then(res => {
    return res
  })
  return response
}

/**
 * 1:1 문의 redis 삭제
 */
export async function deleteOneToOneRedis(body) {
  const response = await axios({
    url: '/tyson/member/v1/operation/management/onetoone/redis',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
