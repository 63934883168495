import React from 'react'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { MemberDepositStatusEnums } from '@/utils/enums/MyPage/myPageEnums'
import { convertToKstDateTime } from '@/utils/dateTime'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const AttendanceListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '회원 ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '출석일',
      accessor: 'attendanceDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.attendanceDate)
      },
    },
    {
      Header: '비고',
      accessor: 'attendanceMemo',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default AttendanceListData
