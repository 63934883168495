import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaStar, FaLock, FaSearchPlus } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { w3cwebsocket as WebSocket } from 'websocket'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'reactjs-crontab/dist/index.css'
import {
  addBettingCart,
  clearAllInfo,
  fetchSpecialSportsEvents,
  fetchSportsEventCount,
  fetchSportsEvents,
  handleWebSocketCrossSportsEvent,
  handleWebSocketSpecialSportsEvent,
  onClickHideSportsEventMainMarket,
  selectSportsEventOptionBtn,
  setSportsType,
} from '../../redux/sportsGameBettingSlice'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import MobileCustomPagination from '../../shared/components/MobileCustomPagination'
import MobileSportsMarketList from './MobileSportsMarketList'
import { decodeAccessToken } from '../../utils/token'
import { HermesMainText, HermesMainBorder, HermesMainSelect, HermesMainHover2 } from '../../utils/palette'
import MobileSportsSpecialMarketList from './MobileSportsSpecialMarketList'

const MobileSportsSpecialEventList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(150)
  const [sortType, setSortType] = useState('TODAY_SORT')
  const [searchBoolean, setSearchBoolean] = useState(false)
  const [searchTeamName, setSearchTeamName] = useState('')
  const [searchEnter, setSearchEnter] = useState(false)

  useEffect(() => {
    dispatch(fetchSportsEventCount())
  }, [])

  function createKey(item) {
    return `${item.sportsEventId}-${item.marketId}-${item.selectedSportsMarketOddKey}-${item.selectedOddName}-${item.name}`
  }

  function isActive(activeSportsMarketKeySet, sportsEventId, marketId, sportsMarketOddKey, selectedOddName, name) {
    const key = createKey({
      sportsEventId,
      marketId,
      selectedSportsMarketOddKey: sportsMarketOddKey,
      selectedOddName,
      name,
    })
    return activeSportsMarketKeySet.has(key)
  }

  const { totalElement, mainSportsEventList, sportsTypeInfo, bonusFolderInfo, selectedSportsEventId, sportsType } =
    useSelector(state => {
      const { sportsGameBetting } = state

      const { SPECIAL, BONUS } = sportsGameBetting.bettingCartList

      const activeSportsMarketKeySet = new Set([
        ...SPECIAL.map(item => createKey(item)),
        ...BONUS.map(item => createKey(item)),
      ])

      let updatedMainSportsEventList

      if (sortType === 'POPULAR_SORT') {
        updatedMainSportsEventList = Object.entries(sportsGameBetting.mainSportsEventList)
          .map(([key, group]) => {
            const updatedEvents = group.sportsEvents.map(sportsEvent => {
              return {
                ...sportsEvent,
                markets: sportsEvent.markets.map(market => ({
                  ...market,
                  isHomeActive: isActive(
                    activeSportsMarketKeySet,
                    sportsEvent.sportsEventId,
                    market.marketId,
                    market.homeSportsMarketOddKey,
                    market.homeOddName,
                    market.marketName === '승무패' ? '' : market.name,
                  ),
                  isAwayActive: isActive(
                    activeSportsMarketKeySet,
                    sportsEvent.sportsEventId,
                    market.marketId,
                    market.awaySportsMarketOddKey,
                    market.awayOddName,
                    market.marketName === '승무패' ? '' : market.name,
                  ),
                  isDrawActive: isActive(
                    activeSportsMarketKeySet,
                    sportsEvent.sportsEventId,
                    market.marketId,
                    market.drawSportsMarketOddKey,
                    'Draw',
                    market.marketName === '승무패' ? '' : market.name,
                  ),
                })),
              }
            })

            const totalMarketCount = updatedEvents.reduce((sum, event) => sum + event.markets.length, 0)

            return {
              key,
              ...group,
              sportsEvents: updatedEvents,
              totalMarketCount,
            }
          })
          .sort((a, b) => b.totalMarketCount - a.totalMarketCount)
      } else {
        updatedMainSportsEventList = Object.entries(sportsGameBetting.mainSportsEventList).map(([key, group]) => {
          const updatedEvents = group.sportsEvents.map(sportsEvent => {
            return {
              ...sportsEvent,
              markets: sportsEvent.markets.map(market => ({
                ...market,
                isHomeActive: isActive(
                  activeSportsMarketKeySet,
                  sportsEvent.sportsEventId,
                  market.marketId,
                  market.homeSportsMarketOddKey,
                  market.homeOddName,
                  market.marketName === '승무패' ? '' : market.name,
                ),
                isAwayActive: isActive(
                  activeSportsMarketKeySet,
                  sportsEvent.sportsEventId,
                  market.marketId,
                  market.awaySportsMarketOddKey,
                  market.awayOddName,
                  market.marketName === '승무패' ? '' : market.name,
                ),
                isDrawActive: isActive(
                  activeSportsMarketKeySet,
                  sportsEvent.sportsEventId,
                  market.marketId,
                  market.drawSportsMarketOddKey,
                  'Draw',
                  market.marketName === '승무패' ? '' : market.name,
                ),
              })),
            }
          })

          return {
            key,
            ...group,
            sportsEvents: updatedEvents,
          }
        })
      }

      return {
        totalElement: sportsGameBetting.totalElement,
        mainSportsEventList: updatedMainSportsEventList,
        sportsTypeInfo: sportsGameBetting.sportsTypeInfo,
        bonusFolderInfo: sportsGameBetting.bonusFolderInfo.map(bonus => ({
          ...bonus,
          isActive: isActive(activeSportsMarketKeySet, 0, bonus.marketId, null, `${bonus.bonusFolderNum}폴더 이상`, ''),
        })),
        selectedSportsEventId: sportsGameBetting.selectedSportsEventId,
        sportsType: sportsGameBetting.selectedSportsType,
      }
    })

  useEffect(() => {
    setSearchBoolean(prev => !prev)
    setSearchTeamName('')
    setPage(0)
  }, [sportsType])

  const params = useMemo(() => {
    return {
      sportsType,
      page: !searchTeamName ? page : 0,
      size,
      sortType,
      searchTeamName: !searchTeamName ? null : searchTeamName,
    }
  }, [sportsType, page, size, sortType, searchBoolean, searchEnter])

  const socketRef = useRef(null)
  const intervalIdRef = useRef(null)

  const handleSocketOpen = useCallback(() => {
    // 웹소켓 연결 후 초기 데이터 요청
    dispatch(fetchSpecialSportsEvents(params))
  }, [dispatch])

  const handleSocketMessage = useCallback(
    event => {
      const message = JSON.parse(event.data)
      dispatch(handleWebSocketSpecialSportsEvent(message))
    },
    [dispatch],
  )

  const handleSocketError = useCallback(event => {
    console.error('WebSocket error observed:', event)
    // logout 시키기
  }, [])

  useEffect(() => {
    // 웹소켓 인스턴스 생성
    const userKey = decodeAccessToken().userKey || ''
    const newSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_SPECIAL_SPORTS_URL}?userKey=${userKey}`)
    socketRef.current = newSocket

    // 웹소켓 이벤트 리스너 등록
    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('error', handleSocketError)

    return () => {
      // 컴포넌트 언마운트 시 웹소켓 연결 종료
      if (socketRef.current) {
        socketRef.current.close()
      }

      dispatch(clearAllInfo())
    }
  }, [handleSocketOpen, handleSocketMessage, handleSocketError])

  // params가 변경될 때마다 새로운 interval을 설정합니다.
  useEffect(() => {
    // 이전 interval 해제
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      dispatch(fetchSportsEvents(params))
    }

    // 일정한 주기로 파라미터를 서버로 보내기
    const id = setInterval(() => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(JSON.stringify(params))
      }
    }, 5000) // 1초마다 파라미터 전송

    intervalIdRef.current = id

    return () => {
      // 컴포넌트 언마운트 시 타이머 해제
      clearInterval(intervalIdRef.current)
    }
  }, [params])

  /**
   * 페이징처리
   */
  const sportsEventListWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsEventListWrapRef.current) {
      sportsEventListWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const onClickSportsEventType = info => {
    dispatch(setSportsType(info))
    setSortType('TODAY_SORT')
    setPage(0)
  }

  const onClickBetting = (type, item) => {
    dispatch(addBettingCart({ type, item }))
  }

  const onClickHideSportsEventMainMarketHandler = (key, visibled) => {
    dispatch(onClickHideSportsEventMainMarket({ key, isVisible: visibled }))
  }

  const getMarketIconComponent = (marketName, oddName) => {
    switch (true) {
      case marketName.includes('승무패') || marketName.includes('승패'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
            alt=""
            style={{ width: '10px', height: '7px' }}
          />
        )
      case marketName.includes('핸디'):
        return (
          // <img src={`${process.env.PUBLIC_URL}/img/sports/icon_h.png`} alt="" style={{ width: '7px', height: '7px' }} />
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('언더'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('오버'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      default:
    }
  }
  const [slidesToShow, setSlidesToShow] = useState(7)
  return (
    <SportsEventListWrap className="sports-container" ref={sportsEventListWrapRef}>
      <SportsGameEventsWrap>
        {sportsTypeInfo?.map((type, idx) => (
          <SportsGameEvent
            key={idx}
            $icon={type.sportsType}
            onClick={() => {
              onClickSportsEventType(type.sportsType === 'ALL' ? null : type.sportsType)
            }}
            active={(type.sportsType === 'ALL' && sportsType === null) || type.sportsType === sportsType}
          >
            <SportsGameEventCount
              active={(type.sportsType === 'ALL' && sportsType === null) || type.sportsType === sportsType}
            >
              {type.count}
            </SportsGameEventCount>
          </SportsGameEvent>
        ))}
      </SportsGameEventsWrap>
      <SportsGameMultiFolderWrap>
        <SportsGameMultiFolderHeader>
          <SportsGameMultiFolderHeaderIcon />

          <span style={{ color: '#fff' }}>다폴더 보너스 추가 배당</span>
        </SportsGameMultiFolderHeader>
        <SportsGameMultiFolderContent>
          {bonusFolderInfo.map((folder, index) => (
            <SportsGameMultiFolderContentItem
              key={index}
              isActive={folder.isActive}
              onClick={() => {
                const type = 'BONUS'
                const item = {
                  sportsEventId: 0,
                  sportsType: 'ETC',
                  sportsMarketKey: folder.marketId,
                  marketId: folder.marketId,
                  leagueName: '',
                  ccKr: '',
                  startAt: null,
                  homeName: '',
                  awayName: '',
                  marketName: '보너스 배당',
                  name: '',
                  homeSportsMarketOddKey: '',
                  homeOddName: `${folder.bonusFolderNum}폴더 이상`,
                  homeOddNameKr: `${folder.bonusFolderNum}폴더 이상`,
                  homeOddValue: folder.bonusFolderPer,
                  awaySportsMarketOddKey: '',
                  awayOddName: '',
                  awayOddNameKr: '',
                  awayOddValue: '',
                  drawSportsMarketOddKey: '',
                  drawOddName: '',
                  drawOddNameKr: '',
                  drawOddValue: '',
                  selectedSportsMarketPointKey: null,
                  selectedSportsMarketOddKey: null,
                  selectedOddName: `${folder.bonusFolderNum}폴더 이상`,
                  selectedOddNameKr: `${folder.bonusFolderNum}폴더 이상`,
                  selectedOddValue: folder.bonusFolderPer,
                }

                onClickBetting(type, item)
              }}
            >
              <SportsGameMultiFolderOne>
                <SportsEventTitleTypeImg
                  src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                  style={{ width: '25px', marginBottom: '-13px' }}
                  alt=""
                />
                <SportsGameMultiFolderContentOdd>{folder.bonusFolderPer}</SportsGameMultiFolderContentOdd>
              </SportsGameMultiFolderOne>
              <SportsGameMultiFolderTwo>
                <span>{folder.bonusFolderNum}폴더 이상</span>
              </SportsGameMultiFolderTwo>
            </SportsGameMultiFolderContentItem>
          ))}
        </SportsGameMultiFolderContent>
      </SportsGameMultiFolderWrap>
      <SportsGameSortingWrap>
        <SportsGameSortingLeftWrap>
          <SportsGameSortingItem isActive={sortType === 'POPULAR_SORT'} onClick={() => setSortType('POPULAR_SORT')}>
            <span>인기순 정렬</span>
          </SportsGameSortingItem>
          <SportsGameSortingItem isActive={sortType !== 'POPULAR_SORT'} onClick={() => setSortType('TODAY_SORT')}>
            <span>시간대 정렬</span>
          </SportsGameSortingItem>
        </SportsGameSortingLeftWrap>
        {sortType !== 'POPULAR_SORT' && (
          <SportsGameSortingRightWrap>
            <SportsGameSortingRightItem isActive={sortType === 'TODAY_SORT'} onClick={() => setSortType('TODAY_SORT')}>
              24시간내 경기
            </SportsGameSortingRightItem>
            <SportsGameSortingRightItem
              isActive={sortType === 'TOMORROW_SORT'}
              onClick={() => setSortType('TOMORROW_SORT')}
            >
              내일 경기
            </SportsGameSortingRightItem>
            <SportsGameSortingRightItem
              isActive={sortType === 'LEAGUE_SORT'}
              onClick={() => setSortType('LEAGUE_SORT')}
            >
              리그별
            </SportsGameSortingRightItem>
          </SportsGameSortingRightWrap>
        )}
      </SportsGameSortingWrap>
      <SportsGameSearchWrap>
        팀명 검색:
        <input
          type="text"
          name="searchTeamName"
          value={searchTeamName}
          placeholder="팀명을 검색하세요"
          onChange={e => {
            setSearchTeamName(e.target.value)
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setSearchEnter(prev => !prev)
            }
          }}
        />
        <FaSearchPlus
          onClick={() => {
            setSearchBoolean(prev => !prev)
          }}
        />
      </SportsGameSearchWrap>
      <SportsGameListWrap>
        {mainSportsEventList.length === 0 ? (
          <SportsGameEmptyInfoBox>해당종목의 경기가 없습니다.</SportsGameEmptyInfoBox>
        ) : (
          <>
            {mainSportsEventList.map(mainSportsEvent => (
              <SportsEventWrap key={mainSportsEvent.key}>
                <SportsEventTitleBox
                  onClick={() =>
                    onClickHideSportsEventMainMarketHandler(mainSportsEvent.key, !mainSportsEvent.isVisible)
                  }
                >
                  <SportsEventTitleTypeImg
                    src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${mainSportsEvent.sportsType}.png`}
                    alt=""
                  />
                  {mainSportsEvent.ccImage && <SportsEventTitleCountryImg src={mainSportsEvent.ccImage} alt="" />}
                  <SportsEventTitleCountryInfo>{mainSportsEvent.ccKr}</SportsEventTitleCountryInfo>
                  <SportsEventTitleArrowRightIcon />
                  {mainSportsEvent.leagueImage && (
                    <SportsEventTitleLeagueImg src={mainSportsEvent.leagueImage} alt="" />
                  )}
                  <SlimScrollDiv>
                    <SportsEventTitleLeagueInfo>{mainSportsEvent.leagueName}</SportsEventTitleLeagueInfo>
                  </SlimScrollDiv>

                  <SportsEventTitleLeagueCount>{mainSportsEvent.sportsEvents.length}경기</SportsEventTitleLeagueCount>
                  {mainSportsEvent.isVisible ? <SportsEventTitleArrowUpIcon /> : <SportsEventTitleArrowDownIcon />}
                </SportsEventTitleBox>
                {mainSportsEvent.isVisible && (
                  <SportsEventContentBox>
                    {mainSportsEvent.sportsEvents.map(sportsEvent => (
                      <>
                        <SportsEventContentTopWrap>
                          <SportsEventContentTopDate>
                            <span>{convertToKstByFormat(sportsEvent.startAt)}</span>
                          </SportsEventContentTopDate>
                          <SportsEventContentTopOption
                            onClick={() => {
                              dispatch(
                                selectSportsEventOptionBtn({
                                  key: mainSportsEvent.key,
                                  sportsEventId: sportsEvent.sportsEventId,
                                  isMobile: true,
                                }),
                              )
                            }}
                          >
                            <span> + 추가 배팅옵션 </span>
                          </SportsEventContentTopOption>
                        </SportsEventContentTopWrap>
                        <SportsEventContentItem
                          key={sportsEvent.sportsEventId}
                          onClick={() => {
                            dispatch(
                              selectSportsEventOptionBtn({
                                key: mainSportsEvent.key,
                                sportsEventId: sportsEvent.sportsEventId,
                                isMobile: true,
                              }),
                            )
                          }}
                        >
                          {sportsEvent.isLocked && (
                            <SportsEventContentItemOverlay>
                              <SportsEventContentItemLockIcon />
                            </SportsEventContentItemOverlay>
                          )}

                          <SportsEventContentItemHomeBox>
                            <SportsEventContentItemHomeName>{sportsEvent.homeName}</SportsEventContentItemHomeName>
                          </SportsEventContentItemHomeBox>
                          <SportsEventContentItemPoint>VS</SportsEventContentItemPoint>
                          <SportsEventContentItemAwayBox>
                            <SportsEventContentItemAwayName>{sportsEvent.awayName}</SportsEventContentItemAwayName>
                          </SportsEventContentItemAwayBox>
                        </SportsEventContentItem>
                        {selectedSportsEventId === sportsEvent.sportsEventId && sportsEvent.addNewOptionFlag && (
                          <MobileSportsSpecialMarketList />
                        )}
                      </>
                    ))}
                  </SportsEventContentBox>
                )}
              </SportsEventWrap>
            ))}
          </>
        )}
      </SportsGameListWrap>

      {totalElement > 0 && (
        <MobileCustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
      )}
    </SportsEventListWrap>
  )
}

export default MobileSportsSpecialEventList

const SlimScrollDiv = styled.div`
  // position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
`

const SportsGameSearchWrap = styled.div`
  width: 100%;
  border: 2px solid #222733;
  background: rgba(48, 48, 48, 1);
  border-radius: 3px;
  padding: 4px 8px;
  margin-top: 10px;
  position: relative;
  display: flex;
  gap: 5px;
  justify-content: right;
  align-items: center;
  font-weight: 400;
  color: #fff;
  font-size: 12px;
  input {
    height: 25px;
    border: solid 1px ${HermesMainBorder};
    background: ${HermesMainBorder};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 12px;
    padding-left: 5px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 1px ${HermesMainBorder};
    }
  }
  svg {
    width: 22px;
    height: 20px;
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &:hover {
      cursor: pointer;
    }
  }
`

const SportsGameEvent = styled.div`
  min-width: 60px;
  height: 59px;
  ${props =>
    props.$icon &&
    `
    background: ${
      props.active
        ? `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}-hover.png)`
        : `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}.png)`
    } no-repeat center;  
`}
`

const HeaderCustomSlider = styled(Slider)`
  position: relative;
  width: 100%;
  .slick-next {
    top: 20px;
    right: 10px;
    z-index: 100;
  }
  .slick-prev {
    top: 20px;
    left: 10px;
    z-index: 100;
  }
`

const SportsEventTitleBox = styled.div`
  // margin-top: 10px;
  padding: 0 10px;
  height: 32px;
  width: 100%;
  color: white;
  background: rgba(48, 48, 48, 1);
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const SportsEventTitleCountryImg = styled.img`
  width: 23px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleCountryInfo = styled.span`
  min-width: max-content;
  font-size: 11px;
  font-weight: 500;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleArrowRightIcon = styled(MdOutlineArrowForwardIos)`
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 17px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueImg = styled.img`
  width: 23px;
  height: 23px;
  margin-left: 5px;
  margin-top: 3px;
`

const SportsEventTitleLeagueInfo = styled.span`
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  margin-left: 5px;
  display: flex;
  jusfify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  overflow: auto;
`

const SportsEventTitleLeagueCount = styled.span`
  min-width: max-content;
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
`

const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 20px;
  height: auto;
  cursor: pointer;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 20px;
  height: auto;
  cursor: pointer;
`

const SportsEventWrap = styled.div`
  width: 100%;
  padding: 10px 5px 10px 5px;
  display: flex;
  flex-direction: column;
`

const SportsEventContentTopWrap = styled.div`
  width: 100%;
  min-height: 1px;
  color: white;
  margin-top: 3px;
  margin-bottom: 1px;
`

const SportsEventContentTopDate = styled.div`
  border-radius: 3px;
  color: #fff;
  float: left;
  padding: 4px 12px;
  background: ${HermesMainSelect};

  span {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const SportsEventContentTopOption = styled.div`
  border-radius: 3px;
  color: #fff;
  float: right;
  padding: 4px 12px;
  background: ${HermesMainSelect};

  span {
    width: 100%;
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
  }
`

const SportsEventContentItemHomeName = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 700;
  padding-left: 3px;
  word-break: break-all;
`

const SportsEventContentItemHomeOddValue = styled.div`
  width: auto;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-right: 2px;

  img {
    margin-right: 3px;
    margin-left: 3px;
  }
`

const SportsEventContentItemHomeBox = styled.div`
  width: calc(50% - 25px);
  height: 100%;
  display: flex;
  position: relative;

  border-color: #424242 transparent #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);
`

const SportsEventContentItemPoint = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #424242 transparent #232324 transparent !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);
  font-size: 12px;
  font-weight: 600;
`

const SportsEventContentItemAwayName = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 3px;
  font-weight: 700;
  word-break: break-all;
`

const SportsEventContentItemAwayOddValue = styled.div`
  width: auto;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 2px;

  img {
    margin-left: 3px;
    margin-right: 3px;
  }
`

const SportsEventContentItemAwayBox = styled.div`
  width: calc(50% - 20px);
  height: 100%;
  display: flex;
  position: relative;
  border-color: #424242 #181818 #232324 transparent !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);
`

const SportsEventContentItem = styled.div`
  width: 100%;
  height: 38px;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
`

const SportsEventContentItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);

  border: 1px solid ${HermesMainText};
`

const SportsEventContentItemLockIcon = styled(FaLock)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const SportsEventContentItemMarketName = styled.div`
  min-width: 65px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(48, 48, 48, 1);

  color: white;
  font-size: 11px;
  font-weight: 600;
`

const SportsEventContentBox = styled.div`
  width: 100%;
  min-height: 40px;
  color: white;
  padding: 1px 0px;
  display: flex;
  flex-direction: column;
  border: 2px solid #3a3a3a;
  gap: 2px;
`

const SportsGameListWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
`

const SportsEventListWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 13px 0px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
`

// 종목 부분
const SportsGameEventsWrap = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
`

const SportsGameEventCount = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: ${props => (props.active ? `${HermesMainBorder}` : '#fff')};
  font-size: 12px;
  float: right;
  padding: 3px 5px 3px 5px;
  margin: 1px;
`

// 다폴더 보너스 배당
const SportsGameMultiFolderWrap = styled.div`
  width: 100%;
  border: 2px solid #222733;
  background: rgba(48, 48, 48, 1);
  // border-radius: 3px;
  padding: 8px;
  margin-top: 20px;
  position: relative;
`

const SportsGameMultiFolderHeader = styled.div`
  padding: 3px;
  font-size: 12px !important;
  float: left;
  padding: 3px 14px;
  display: flex;
  gap: 5px;
`

const SportsGameMultiFolderHeaderIcon = styled(FaStar)`
  background: ${HermesMainText};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const SportsGameMultiFolderContent = styled.div`
  width: 100%;
  height: 50px;
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
`

const SportsGameMultiFolderContentItem = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  background: rgba(29, 29, 29, 1);
  border: none;
  border-radius: 5px;
  padding: 2px 15px;
  cursor: pointer;

  div {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
  }

  ${props =>
    props.isActive &&
    `
    border: solid 2px ${HermesMainText};
    div {
      background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
      font-size: 12px;
      font-weight: 600;
    }
    `}

  img {
    width 20px;
    height: 20px;
  }
`
const SportsGameMultiFolderOne = styled.div`
  width: 100%;
`
const SportsGameMultiFolderTwo = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  span {
    font-size: 11px !important;
  }
`
// 배당
const SportsGameMultiFolderContentOdd = styled.div`
  background: #3a3a3a;
  width: 40px;
  height: 18px;
  color: #ffba00;
  margin-top: 5px;
  border-radius: 50%;
  float: right;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

// 경기가 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-size: 12px;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

// 정렬
const SportsGameSortingWrap = styled.div`
  width: 100%;
  border: 2px solid #222733;
  background: rgba(48, 48, 48, 1);
  border-radius: 3px;
  padding: 2px 4px;
  margin-top: 20px;
  position: relative;
  display: flex;
`

const SportsGameSortingLeftWrap = styled.div`
  width: 50%;
  gap: 10px;
  display: flex;
`

const SportsGameSortingRightItem = styled.div`
  color: white;
  height: 25px;
  font-size: 12px;
  font-weight: 400;
  padding: 3px 0;
  padding: 6px 2px;
  display: flex;
  text-align: center;
  align-items: center;

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainHover2};
    color: ${HermesMainText} !important;
    border-radius: 10px;
    `}
`

const SportsGameSortingRightWrap = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
`

const SportsGameSortingItem = styled.div`
  // width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  background: rgba(29, 29, 29, 1);
  border: none;
  border-radius: 18px;
  padding: 0 10px;
  cursor: pointer;

  span {
    float: left;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainHover2};
    span {
      color: ${HermesMainText} !important;
    }
    `}
`
