import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMiniGameBettingLog, searchMiniGameInfo } from '../../../api/game/miniGameApi'
import {
  addBettingCart,
  setBettingCloseHandler,
  setMiniGameCompleteFlag,
  setMiniGameInfo,
  setResetData,
} from '../../../redux/miniGameBettingSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import { convertToKstDateTimeSimple } from '../../../utils/dateTime'
import {
  MiniGameBettingTypeEnums,
  MiniGameTypeEnums,
  PowerBallTypeEnums,
} from '../../../utils/enums/MiniGame/PowerBallEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MobileMiniGameBettingCart from '../../MobileLayout/MobileMiniGameBettingCart'
import MobileNtryTabs from './MobileNtryTabs'
import { formatSeconds, getCurrentTime } from '../../../containers/MiniGame/miniGameTimeUtils'
import MobileCustomPagination from '../../../shared/components/MobileCustomPagination'
import MobileMiniGameTabs from '../MobileMiniGameTabs'

const MobileEosPowerBallOne = () => {
  // redux 사용
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const [params, setParams] = useState({
    miniGameType: 'NTRY_EOS_POWER_BALL_1M',
  })

  const {
    miniGameType,
    miniGameBettingInfo,
    miniGameRound,
    deadLineTime,
    isShow,
    endTime,
    bettingCountOkFlag,
    bettingCloseFlag,
    bettingCompleteFlag,
  } = useSelector(state => {
    const { miniGameBetting } = state

    return {
      miniGameType: miniGameBetting.miniGameType,
      miniGameBettingInfo: miniGameBetting.miniGameBettingInfo,
      miniGameRound: miniGameBetting.miniGameRound,
      deadLineTime: miniGameBetting.deadLineTime,
      isShow: miniGameBetting.isShow,
      endTime: miniGameBetting.endTime,
      bettingCountOkFlag: miniGameBetting.bettingCountOkFlag,
      bettingCloseFlag: miniGameBetting.bettingCloseFlag,
      bettingCompleteFlag: miniGameBetting.bettingCompleteFlag,
    }
  })

  // 서버 시간 == 현재 시간
  const [currentTime, setCurrentTime] = useState(null)

  const intervalRef = useRef(null)
  // svt : serverTime , stt : startTime
  const getCurrentTimeDifference = deadLine => {
    if (!currentTime)
      return {
        minutes: 0,
        seconds: 0,
      }

    let totalSeconds = endTime - currentTime - deadLine

    if (totalSeconds < 0) {
      totalSeconds = 0
    }

    return {
      minutes: Math.floor(totalSeconds / 60),
      seconds: totalSeconds % 60,
    }
  }

  const [time, setTime] = useState({
    minutes: 0,
    seconds: 0,
  })

  const fetchSearchMiniGameInfo = async () => {
    await searchMiniGameInfo(params)
      .then(res => {
        dispatch(
          setMiniGameInfo({
            miniGameType: res.data.miniGameType,
            miniGameBettingInfo: JSON.parse(res.data.miniGameBettingPer),
            round: res.data.round,
            bettingCountOkFlag: res.data.bettingCountOkFlag,
            endTime: res.data.endTime,
            deadLineTime: res.data.deadLineTime,
            isShow: res.data.isShow,
          }),
        )

        setCurrentTime(res.data.serverTime)

        setTime(getCurrentTimeDifference(res.data.deadLineTime))

        if (!bettingCompleteFlag) {
          dispatch(setResetData())
        }
      })
      .catch(error => {})
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchMiniGameInfo()

    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentTime >= endTime) {
        fetchSearchMiniGameInfo()
        dispatch(setMiniGameCompleteFlag(false))
        dispatch(setResetData())
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [currentTime, endTime])

  const updateTimer = () => {
    setCurrentTime(getCurrentTime())
    setTime(getCurrentTimeDifference(deadLineTime))
  }

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(updateTimer, 1000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [time])

  /**
   *  배팅 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [totalPages, setTotalPages] = useState(0)

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  const [miniGameBettingLogParams, setMiniGameBettingLogParams] = useState({
    miniGameType: 'NTRY_EOS_POWER_BALL_1M',
    page,
    size,
  })

  useEffect(() => {
    setMiniGameBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const fetchSearchMiniGameBettingLog = () => {
    searchMiniGameBettingLog(miniGameBettingLogParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
      })
      .catch(error => {})
  }

  useEffect(() => {
    fetchSearchMiniGameBettingLog()
  }, [miniGameBettingLogParams, bettingCompleteFlag])

  const isDisabled = time.minutes === 0 && time.seconds === 0

  // 배팅 마감일 때 배팅카트 초기화
  useEffect(() => {
    dispatch(setBettingCloseHandler(isDisabled))
  }, [isDisabled])

  // 배팅 카트 담기
  const handleBettingCart = event => {
    const miniGameBettingPer = event.currentTarget.getAttribute('name') || 0
    const miniGameBettingType = event.currentTarget.id

    dispatch(
      addBettingCart({
        miniGameBettingPer,
        miniGameBettingType,
      }),
    )
  }

  const [selectedTab, setSelectedTab] = useState(1)

  const handleClick = tabNumber => {
    setSelectedTab(tabNumber)
  }

  return (
    <>
      <div className="mobile_mini_content">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <td>
                <MobileMiniGameTabs activeMenu="NTRY" />
                <MobileNtryTabs activeMenu="NTRY_EOS_POWER_BALL_1M" />
                <div
                  className="powerball_wrap"
                  style={{
                    background: `#13205c url(${process.env.PUBLIC_URL}/img/minigame/bg_powerball.png)`,
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ height: '20px' }} />

                    {!loading ? (
                      <MiniGameIframe
                        src="https://ntry.com/scores/eos_powerball/1min/main.php?is_mobile=Y"
                        title="EOS1"
                      />
                    ) : (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.1 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0.5, scale: 0.1 }}
                        transition={{ duration: 0.6 }}
                      >
                        <IframeOverlay>
                          <HeaderLogoImg src={`${process.env.PUBLIC_URL}/img/logo/logo.png`} alt="" />
                        </IframeOverlay>
                      </motion.div>
                    )}
                  </div>

                  <div id="powerball_count" className="powerball_count">
                    <span className="order" id="play_num_view">
                      [제 {miniGameRound || '-'} 회차] &nbsp;
                    </span>
                    <strong className="count" id="remaind_time">
                      {time.minutes} : {formatSeconds(time.seconds)}
                    </strong>
                  </div>

                  <MiniGameBettingSortTitleWrap>
                    <MiniGameBettingSortTitle onClick={() => handleClick(1)} selected={selectedTab === 1}>
                      일반배팅
                    </MiniGameBettingSortTitle>
                    <MiniGameBettingSortTitle onClick={() => handleClick(2)} selected={selectedTab === 2}>
                      조합배팅
                    </MiniGameBettingSortTitle>
                  </MiniGameBettingSortTitleWrap>
                  <div className="powerball_cnt" style={{ position: 'relative' }}>
                    {!isShow && (
                      <InspectOverlay>
                        <BiLock />
                        <span>점검중</span>
                      </InspectOverlay>
                    )}
                    {(isDisabled || !bettingCountOkFlag) && (
                      <InspectOverlay>
                        <BiLock />
                        <span>배팅 마감</span>
                      </InspectOverlay>
                    )}
                    {selectedTab === 1 ? (
                      <PowerballChoice id="powerball_choice" className="powerball_choice">
                        <PowerballChoiceInner className="powerball_choice_inner" colNum={2}>
                          <PowerballChoiceBox>
                            <h4>파워볼 홀/짝</h4>
                            <div>
                              <ul>
                                <li>
                                  <MiniGameBettingCartContentBlue
                                    id="POWER_BALL_ODD"
                                    name={miniGameBettingInfo?.powerOddPer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    홀
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerOddPer || 0}</span>
                                  </div>
                                </li>
                                <li>
                                  <MiniGameBettingCartContentRed
                                    id="POWER_BALL_EVEN"
                                    name={miniGameBettingInfo?.powerEvenPer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    짝
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerEvenPer || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                          <PowerballChoiceBox>
                            <h4>파워볼 4.5 언더/오버</h4>
                            <div>
                              <ul>
                                <li>
                                  <MiniGameBettingCartContentUnder
                                    id="POWER_BALL_UNDER"
                                    name={miniGameBettingInfo?.powerUnderPer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    언더
                                  </MiniGameBettingCartContentUnder>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerUnderPer || 0}</span>
                                  </div>
                                </li>
                                <li>
                                  <MiniGameBettingCartContentOver
                                    id="POWER_BALL_OVER"
                                    name={miniGameBettingInfo?.powerOverPer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    오버
                                  </MiniGameBettingCartContentOver>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerOverPer || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>
                        <PowerballChoiceInner className="powerball_choice_inner" colNum={2}>
                          <PowerballChoiceBox>
                            <h4>일반볼 홀/짝</h4>
                            <div>
                              <ul>
                                <li>
                                  <MiniGameBettingCartContentBlue
                                    id="NORMAL_BALL_ODD"
                                    name={miniGameBettingInfo?.normalOddPer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    홀
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalOddPer || 0}</span>
                                  </div>
                                </li>
                                <li>
                                  <MiniGameBettingCartContentRed
                                    id="NORMAL_BALL_EVEN"
                                    name={miniGameBettingInfo?.normalEvenPer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    짝
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalEvenPer || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                          <PowerballChoiceBox>
                            <h4>일반볼 72.5 언더/오버</h4>
                            <div>
                              <ul>
                                <li>
                                  <MiniGameBettingCartContentUnder
                                    id="NORMAL_BALL_UNDER"
                                    name={miniGameBettingInfo?.normalUnderPer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    언더
                                  </MiniGameBettingCartContentUnder>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalUnderPer || 0}</span>
                                  </div>
                                </li>
                                <li>
                                  <MiniGameBettingCartContentOver
                                    id="NORMAL_BALL_OVER"
                                    name={miniGameBettingInfo?.normalOverPer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    오버
                                  </MiniGameBettingCartContentOver>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalOverPer || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>
                        <PowerballChoiceInner className="powerball_choice_inner" colNum={1}>
                          <PowerballChoiceBox>
                            <h4>일반볼 구간별</h4>
                            <div>
                              <ul>
                                <li style={{ width: '33.333%' }}>
                                  <MiniGameBettingCartContentSize
                                    id="NORMAL_BALL_LARGE"
                                    name={miniGameBettingInfo?.normalLargePer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    대
                                  </MiniGameBettingCartContentSize>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalLargePer || 0}</span>
                                  </div>
                                  <div style={{ textAlign: 'center' }}>
                                    <span style={{ fontSize: '12px', color: '#fff' }}>81 ~ 130</span>
                                  </div>
                                </li>
                                <li style={{ width: '33.333%' }}>
                                  <MiniGameBettingCartContentSize
                                    id="NORMAL_BALL_MEDIUM"
                                    name={miniGameBettingInfo?.normalMediumPer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    중
                                  </MiniGameBettingCartContentSize>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalMediumPer || 0}</span>
                                  </div>
                                  <div style={{ textAlign: 'center' }}>
                                    <span style={{ fontSize: '12px', color: '#fff' }}>65 ~ 80</span>
                                  </div>
                                </li>
                                <li style={{ width: '33.333%' }}>
                                  <MiniGameBettingCartContentSize
                                    id="NORMAL_BALL_SMALL"
                                    name={miniGameBettingInfo?.normalSmallPer || 0}
                                    onClick={handleBettingCart}
                                  >
                                    소
                                  </MiniGameBettingCartContentSize>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalSmallPer || 0}</span>
                                  </div>
                                  <div style={{ textAlign: 'center' }}>
                                    <span style={{ fontSize: '12px', color: '#fff' }}>15 ~ 64</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>
                        <PowerballChoiceInner className="powerball_choice_inner" colNum={1}>
                          <PowerballChoiceBox>
                            <h4>파워볼 숫자</h4>
                            <div>
                              <ul>
                                <li style={{ width: '19.999%' }}>
                                  <MiniGameBettingCartContentBlack
                                    id="POWER_BALL_NUM_0"
                                    name={miniGameBettingInfo?.powerNum0Per || 0}
                                    onClick={handleBettingCart}
                                  >
                                    0
                                  </MiniGameBettingCartContentBlack>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerNum0Per || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '19.999%' }}>
                                  <MiniGameBettingCartContentBlack
                                    id="POWER_BALL_NUM_1"
                                    name={miniGameBettingInfo?.powerNum1Per || 0}
                                    onClick={handleBettingCart}
                                  >
                                    1
                                  </MiniGameBettingCartContentBlack>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerNum1Per || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '19.999%' }}>
                                  <MiniGameBettingCartContentBlack
                                    id="POWER_BALL_NUM_2"
                                    name={miniGameBettingInfo?.powerNum2Per || 0}
                                    onClick={handleBettingCart}
                                  >
                                    2
                                  </MiniGameBettingCartContentBlack>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerNum2Per || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '19.999%' }}>
                                  <MiniGameBettingCartContentBlack
                                    id="POWER_BALL_NUM_3"
                                    name={miniGameBettingInfo?.powerNum3Per || 0}
                                    onClick={handleBettingCart}
                                  >
                                    3
                                  </MiniGameBettingCartContentBlack>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerNum3Per || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '19.999%' }}>
                                  <MiniGameBettingCartContentBlack
                                    id="POWER_BALL_NUM_4"
                                    name={miniGameBettingInfo?.powerNum4Per || 0}
                                    onClick={handleBettingCart}
                                  >
                                    4
                                  </MiniGameBettingCartContentBlack>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerNum4Per || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '19.999%' }}>
                                  <MiniGameBettingCartContentBlack
                                    id="POWER_BALL_NUM_5"
                                    name={miniGameBettingInfo?.powerNum5Per || 0}
                                    onClick={handleBettingCart}
                                  >
                                    5
                                  </MiniGameBettingCartContentBlack>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerNum5Per || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '19.999%' }}>
                                  <MiniGameBettingCartContentBlack
                                    id="POWER_BALL_NUM_6"
                                    name={miniGameBettingInfo?.powerNum6Per || 0}
                                    onClick={handleBettingCart}
                                  >
                                    6
                                  </MiniGameBettingCartContentBlack>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerNum6Per || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '19.999%' }}>
                                  <MiniGameBettingCartContentBlack
                                    id="POWER_BALL_NUM_7"
                                    name={miniGameBettingInfo?.powerNum7Per || 0}
                                    onClick={handleBettingCart}
                                  >
                                    7
                                  </MiniGameBettingCartContentBlack>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerNum7Per || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '19.999%' }}>
                                  <MiniGameBettingCartContentBlack
                                    id="POWER_BALL_NUM_8"
                                    name={miniGameBettingInfo?.powerNum8Per || 0}
                                    onClick={handleBettingCart}
                                  >
                                    8
                                  </MiniGameBettingCartContentBlack>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerNum8Per || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '19.999%' }}>
                                  <MiniGameBettingCartContentBlack
                                    id="POWER_BALL_NUM_9"
                                    name={miniGameBettingInfo?.powerNum9Per || 0}
                                    onClick={handleBettingCart}
                                  >
                                    9
                                  </MiniGameBettingCartContentBlack>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerNum9Per || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>
                      </PowerballChoice>
                    ) : (
                      <PowerballChoice id="powerball_choice" className="powerball_choice">
                        <PowerballChoiceInner className="powerball_choice_inner" colNum={1}>
                          <PowerballChoiceBox>
                            <h4>파워볼 조합</h4>
                            <div>
                              <ul>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="POWER_BALL_ODD_UNDER"
                                    name={miniGameBettingInfo?.powerOddUnderPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    홀 + 언더
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerOddUnderPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="POWER_BALL_ODD_OVER"
                                    name={miniGameBettingInfo?.powerOddOverPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    홀 + 오버
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerOddOverPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="POWER_BALL_EVEN_UNDER"
                                    name={miniGameBettingInfo?.powerEvenUnderPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    짝 + 언더
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerEvenUnderPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="POWER_BALL_EVEN_OVER"
                                    name={miniGameBettingInfo?.powerEvenOverPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    짝 + 오버
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerEvenOverPer || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>
                        <PowerballChoiceInner className="powerball_choice_inner" colNum={1}>
                          <PowerballChoiceBox>
                            <h4>일반볼 조합</h4>
                            <div>
                              <ul>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="NORMAL_BALL_ODD_UNDER"
                                    name={miniGameBettingInfo?.normalOddUnderPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    홀 + 언더
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalOddUnderPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="NORMAL_BALL_ODD_OVER"
                                    name={miniGameBettingInfo?.normalOddOverPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    홀 + 오버
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalOddOverPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="NORMAL_BALL_EVEN_UNDER"
                                    name={miniGameBettingInfo?.normalEvenUnderPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    짝 + 언더
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalEvenUnderPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="NORMAL_BALL_EVEN_OVER"
                                    name={miniGameBettingInfo?.normalEvenOverPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    짝 + 오버
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalEvenOverPer || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>

                        <PowerballChoiceInner className="powerball_choice_inner" colNum={1}>
                          <PowerballChoiceBox>
                            <h4>파워볼 일반볼 조합</h4>
                            <div>
                              <ul>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="POWER_BALL_ODD_NORMAL_BALL_ODD"
                                    name={miniGameBettingInfo?.powerOddNormalOddPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    홀 + 홀
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerOddNormalOddPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="POWER_BALL_ODD_NORMAL_BALL_EVEN"
                                    name={miniGameBettingInfo?.powerOddNormalEvenPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    홀 + 짝
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerOddNormalEvenPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="POWER_BALL_EVEN_NORMAL_BALL_ODD"
                                    name={miniGameBettingInfo?.powerEvenNormalOddPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    짝 + 홀
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerEvenNormalOddPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '24.999%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="POWER_BALL_EVEN_NORMAL_BALL_EVEN"
                                    name={miniGameBettingInfo?.powerEvenNormalEvenPer || 0}
                                    onClick={handleBettingCart}
                                    style={{ fontSize: '16px', letterSpacing: '-3px' }}
                                  >
                                    짝 + 짝
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.powerEvenNormalEvenPer || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>
                        <PowerballChoiceInner className="powerball_choice_inner" colNum={1}>
                          <PowerballChoiceBox>
                            <h4>파워볼 숫자</h4>
                            <div>
                              <ul>
                                <li style={{ width: '33.333%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="NORMAL_BALL_ODD_SMALL"
                                    name={miniGameBettingInfo?.normalOddSmallPer || 0}
                                    onClick={handleBettingCart}
                                    style={{
                                      fontSize: '14px',
                                      letterSpacing: '-3px',
                                      width: '50px',
                                      height: '50px',
                                      lineHeight: '50px',
                                    }}
                                  >
                                    홀 + 소
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalOddSmallPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '33.333%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="NORMAL_BALL_ODD_MEDIUM"
                                    name={miniGameBettingInfo?.normalOddMediumPer || 0}
                                    onClick={handleBettingCart}
                                    style={{
                                      fontSize: '14px',
                                      letterSpacing: '-3px',
                                      width: '50px',
                                      height: '50px',
                                      lineHeight: '50px',
                                    }}
                                  >
                                    홀 + 중
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalOddMediumPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '33.333%' }}>
                                  <MiniGameBettingCartContentBlue
                                    id="NORMAL_BALL_ODD_LARGE"
                                    name={miniGameBettingInfo?.normalOddLargePer || 0}
                                    onClick={handleBettingCart}
                                    style={{
                                      fontSize: '14px',
                                      letterSpacing: '-3px',
                                      width: '50px',
                                      height: '50px',
                                      lineHeight: '50px',
                                    }}
                                  >
                                    홀 + 대
                                  </MiniGameBettingCartContentBlue>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalOddLargePer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '33.333%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="NORMAL_BALL_EVEN_SMALL"
                                    name={miniGameBettingInfo?.normalEvenSmallPer || 0}
                                    onClick={handleBettingCart}
                                    style={{
                                      fontSize: '14px',
                                      letterSpacing: '-3px',
                                      width: '50px',
                                      height: '50px',
                                      lineHeight: '50px',
                                    }}
                                  >
                                    짝 + 소
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalEvenSmallPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '33.333%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="NORMAL_BALL_EVEN_MEDIUM"
                                    name={miniGameBettingInfo?.normalEvenMediumPer || 0}
                                    onClick={handleBettingCart}
                                    style={{
                                      fontSize: '14px',
                                      letterSpacing: '-3px',
                                      width: '50px',
                                      height: '50px',
                                      lineHeight: '50px',
                                    }}
                                  >
                                    짝 + 중
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalEvenMediumPer || 0}</span>
                                  </div>
                                </li>
                                <li style={{ width: '33.333%' }}>
                                  <MiniGameBettingCartContentRed
                                    id="NORMAL_BALL_EVEN_LARGE"
                                    name={miniGameBettingInfo?.normalEvenLargePer || 0}
                                    onClick={handleBettingCart}
                                    style={{
                                      fontSize: '14px',
                                      letterSpacing: '-3px',
                                      width: '50px',
                                      height: '50px',
                                      lineHeight: '50px',
                                    }}
                                  >
                                    짝 + 대
                                  </MiniGameBettingCartContentRed>
                                  <div className="per">
                                    <span>{miniGameBettingInfo?.normalEvenLargePer || 0}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </PowerballChoiceBox>
                        </PowerballChoiceInner>
                      </PowerballChoice>
                    )}

                    {/* 배팅 카트 */}
                    <MobileMiniGameBettingCart />
                  </div>
                  {/* 배팅 내역 */}
                  <div className="powerball_btm">
                    <div className="powerball_bet_list">
                      <h4
                        style={{
                          paddingTop: '30px',
                          fontSize: '30px',
                          color: 'white',
                          textAlign: 'center',
                          marginBottom: '-20px',
                        }}
                      >
                        - 배팅내역 -
                      </h4>
                      <div className="powerball_bet_list_inner">
                        <table summary="배팅내역">
                          <colgroup>
                            <col width="30px" />
                            <col width="70px" />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col width="80px" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th scope="col">번호</th>
                              <th scope="col">배팅종목</th>
                              <th scope="col">회차</th>
                              <th scope="col">배당</th>
                              <th scope="col">배팅액</th>
                              <th scope="col">당첨액</th>
                              <th scope="col">배팅결과</th>
                              <th scope="col">배팅시간</th>
                            </tr>
                          </thead>
                          <tbody>
                            {content.map(item => {
                              return (
                                <tr>
                                  <td>{item.num}</td>
                                  <td>{PowerBallTypeEnums[item.miniGameBettingType]}</td>
                                  <td>{item.gameRound?.toString().replace(commonReg2, ',')}</td>
                                  <td>{item.bettingPer}</td>
                                  <td>{item.bettingAmount?.toString().replace(commonReg2, ',')}</td>
                                  <td>{item.winningAmount?.toString().replace(commonReg2, ',')}</td>
                                  <td>{MiniGameBettingTypeEnums[item.bettingResult]}</td>
                                  <td>{convertToKstDateTimeSimple(item.bettingAt)}</td>
                                </tr>
                              )
                            })}

                            <tr>
                              <td colSpan="10" style={{ margin: '0', padding: '3px 0' }}>
                                {totalElement > 0 && (
                                  <MobileCustomPagination
                                    currentPage={page}
                                    totalPages={totalPages}
                                    onPageChange={onPageChange}
                                    activeColor="#ff0000"
                                  />
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
export default MobileEosPowerBallOne

const PowerballChoice = styled.div`
  background: #213076;
  padding: 3px 0;
`

const PowerballChoiceInner = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.colNum ? `repeat(${props.colNum}, 1fr)` : 'repeat(2, 1fr)')};
  margin: 10px auto 0 !important;

  gap: 10px;
`

const PowerballChoiceBox = styled.div`
  background: linear-gradient(to bottom, #051153, #1d2a62);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 100%;
`

const MiniGameIframe = styled.iframe`
  width: 300px;
  height: 515px;
`

const GamePageArea = styled.div`
  display: flex;
  justify-content: center;
  background: #13205c;
  padding-top: 10px;
`

const MiniGameBettingSortTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
  margin-bottom: 10px;
`

const MiniGameBettingSortTitle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 3px solid #005dd6;

  border-radius: 5px;
  color: #fff;

  font-size: 14px;
  height: 50px;
  width: 48%;
  cursor: pointer;

  ${props =>
    props.selected &&
    `
    border: 3px solid #fff;
    background: #005dd6;
`}
`

const InspectOverlay = styled.div`
  position: absolute;
  z-index: 102;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  span {
    position: absolute;
    flex-direction: column;
    margin-top: 15px;
    top: 55%;
    left: 50%;
    font-size: 18px;
    transform: translate(-50%, -55%);
    color: #fff;
  }

  svg {
    /* 아이콘 스타일 */
    position: absolute;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
    color: #fff;
  }
`

export const HeaderLogoImg = styled.img`
  width: 270px;
  height: auto;
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const IframeOverlay = styled.div`
  position: relative;
  color: white;
  justify-content: center;
  width: 100%;
  height: 513px;
  background-color: rgba(255, 255, 255, 0.1);
`

export const TableBettingInfo = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  height: 30px;
  background: #1b1c1f;
  color: #fff;
  font-size: 14px;

  .text {
    flex-grow: 1;
    display: flex;
    justify-content: left;
    padding-left: 20px;
    align-items: center;
  }

  .icon {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-right: 10px; // 오른쪽 여백을 조정하려면 이 값을 조절하세요.
  }
`

export const BettingFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

const MiniGameBettingCartContentBlack = styled.div`
  border: 2px solid #c0c0c0;
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #5c5c5c 40%, #6c6c6c 60%, #7c7c7c 100%);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  display: block;
  margin: 0 auto;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;

  &:hover {
    border: 2px solid #fff;
    background-image: linear-gradient(to top, #4c4c4c 0%, #5c5c5c 40%, #6c6c6c 60%, #7c7c7c 100%);
  }
`

const MiniGameBettingCartContentRed = styled.div`
  border: 3px solid #e02727;
  background: linear-gradient(to bottom, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 25px;
  font-weight: 500;
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  line-height: 60px;

  text-align: center;

  &:hover {
    border: 3px solid #fff;
    background: linear-gradient(to top, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  }
`

const MiniGameBettingCartContentBlue = styled.div`
  border: 3px solid #0069ec;
  background: linear-gradient(to bottom, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 25px;
  font-weight: 500;
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;

  &:hover {
    border: 3px solid #fff;
    background: linear-gradient(to top, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  }
`

const MiniGameBettingCartContentOver = styled.div`
  border: 3px solid #e02727;
  background: linear-gradient(to bottom, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 25px;
  font-weight: 500;
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;

  &:hover {
    border: 3px solid #fff;
    background: linear-gradient(to top, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  }
`

const MiniGameBettingCartContentUnder = styled.div`
  border: 3px solid #0069ec;
  background: linear-gradient(to bottom, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 25px;
  font-weight: 500;
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;

  &:hover {
    border: 3px solid #fff;
    background: linear-gradient(to top, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  }
`

const MiniGameBettingCartContentSize = styled.div`
  border: 2px solid #1a9728;
  background: #1a9728;
  background-image: linear-gradient(#fff9, #fff0 50%, #00000017 0, #fff3);
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 25px;
  font-weight: 500;
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
`
