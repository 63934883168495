import React, { useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ReactQuill from 'react-quill'
import styled from 'styled-components'
import 'react-quill/dist/quill.snow.css' // Quill의 스타일 시트
import { createOnetoOne } from '../../../api/customCenter/customCenterApi'
import CustomLoading from '../../../containers/CustomLoading'
import { HermesMainBorder } from '../../../utils/palette'
// import './PartnerOnetoOne.css'

const MobileOnetoOneWrite = ({ onBack }) => {
  const [questionTitle, setQuestionTitle] = useState('')
  const onChangeQuestionTitle = e => {
    setQuestionTitle(e.target.value)
  }
  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  // 문의 내용
  // const [questionContent, setQuestionContent] = useState(EditorState.createEmpty())
  const [questionContent, setQuestionContent] = useState('')
  const onChangeQuestionContent = e => {
    setQuestionContent(e.target.value)
  }
  // const onChangeQuestionContentHandler = editorState => {
  //   setQuestionContent(editorState)
  // }
  const onSubmit = e => {
    if (apiFlag) return
    if (!questionTitle) return
    setApiFlag(true)
    // TODO userID에 로그인한사람 ID박아주기
    const body = {
      questionTitle,
      questionContent,
    }
    createOnetoOne(body)
      .then(res => {
        alert('1:1문의가 접수되었습니다.')
        onBack()
        setApiFlag(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(message)
            break
          default:
            alert('1:1문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        onBack()
        setApiFlag(false)
      })
  }

  return (
    <>
      {apiFlag && <CustomLoading />}
      <div style={{ padding: '0px 0 0px 0' }}>
        <div id="hiddenBar" style={{ background: '#fff', boxShadow: '0px 7px 6px -5px #DFE1E5;' }}>
          <div className="header">
            <div style={{ height: '3px' }} />
            <table
              style={{ padding: '0px', border: '0px', borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '36px' }}>
                    <img
                      alt=""
                      style={{ width: '36px', height: '36px' }}
                      src="/m/images/menu_btn.png?v=1"
                      //  onclick="showMenuOn();"
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <a href="/m/Default.aspx">
                      <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                    </a>
                  </td>
                  <td style={{ width: '36px' }}>
                    <img
                      style={{ width: '36px', height: '36px' }}
                      alt=""
                      src="/m/images/icons8-refresh-64.png?v=4"
                      //  onClick="window.location.reload();"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: '2px' }} />
          </div>
        </div>

        <div className="body_title">
          <span className="title1">
            <strong>고객센터</strong>
          </span>
          <span className="title2">
            <strong>&nbsp;Contact Us</strong>
          </span>
        </div>
        <div className="board_wrap">
          <div className="board_box">
            <table>
              <tbody>
                <tr>
                  <td className="box_td1">제 목</td>
                  <td className="box_td2" style={{ borderRight: 'solid 1px #000' }}>
                    <input
                      type="text"
                      id="ip_title"
                      style={{ width: '95%', height: '20px', border: 'none' }}
                      value={questionTitle}
                      onChange={e => {
                        onChangeQuestionTitle(e)
                      }}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <td colSpan="2" height="5px" />
                </tr> */}
                <tr>
                  <td className="box_td1">내 용</td>
                  <td className="box_td2">
                    <ReactQuill
                      placeholder={`해당 영역에 문의내용을 입력해주세요 \n\n매너 있는 채팅을 부탁드리며 '욕설 및 협박' 등의 내용을 기재 시 회원자격이 박탈됩니다`}
                      onChange={value => {
                        setQuestionContent(value) // questionContent 업데이트
                      }}
                      modules={{
                        toolbar: [
                          // [{ header: '1' }, { header: '2' }, { font: [] }],
                          // [{ list: 'ordered' }, { list: 'bullet' }],
                          // ['bold', 'italic', 'underline'],
                          // ['link'],
                        ],
                      }}
                      className="custom-quill"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="pageing_wrap">
          <table align="center" cellPadding="0" cellSpacing="5" border="0" width="100%" className="pageing_table">
            <tbody>
              <tr>
                <td
                  width="50%"
                  onClick={() => {
                    onBack()
                  }}
                >
                  ◀ 목록
                </td>
                <td width="50%" onClick={onSubmit}>
                  <b>글등록</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <Form
        onSubmit={onSubmit}
        initialValues={{
          userId: decodeAccessToken().userId,
          questionTitle,
          questionContent,
        }}
        validate={OnetoOneValidate}
      >
        {({ handleSubmit, form: { reset } }) => (
          <MobileCustomerCenterFormWrap horizontal onSubmit={handleSubmit}>
            <MobileCustomerCenterFormGroup>
              <MobileCustomerCenterFormLabel>문의 제목</MobileCustomerCenterFormLabel>
              <MobileCustomCenterFormField>
                <Field name="questionTitle">
                  {({ input, meta }) => (
                    <FormInputWrap>
                      <CustomCenterFormInput
                        {...input}
                        style={{ background: '#C2C3C4' }}
                        type="text"
                        value={questionTitle}
                        onChange={e => {
                          onChangeQuestionTitle(e)
                        }}
                        placeholder="제목을 작성하세요"
                      />
                      {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                    </FormInputWrap>
                  )}
                </Field>
              </MobileCustomCenterFormField>
            </MobileCustomerCenterFormGroup>
            <MobileCustomerCenterFormGroup>
              <MobileCustomerCenterFormLabel>문의 내용</MobileCustomerCenterFormLabel>
              <MobileCustomCenterFormField>
                <Field name="questionContent">
                  {({ input, meta }) => (
                    <FormInputWrap>
                      <CustomCenterTextArea
                        {...input}
                        type="text"
                        placeholder={`해당 영역에 문의내용을 입력해주세요 \n\n매너 있는 채팅을 부탁드리며 '욕설 및 협박' 등의 내용을 기재 시 회원자격이 박탈됩니다`}
                        value={questionContent}
                        onChange={e => {
                          onChangeQuestionContent(e)
                        }}
                      />
                      {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                    </FormInputWrap>
                  )}
                </Field>
              </MobileCustomCenterFormField>
            </MobileCustomerCenterFormGroup>
            <CustomCenterFormBottomWrap>
              <QuestionButton
                onClick={() => {
                  setWriteOnetoOneShow(false)
                }}
                variant="primary"
                type="button"
              >
                <MoneyFormModifyButtomInfo>목록</MoneyFormModifyButtomInfo>
              </QuestionButton>
              <BackButton variant="primary" type="submit">
                <MoneyFormModifyButtomInfo>문의완료</MoneyFormModifyButtomInfo>
              </BackButton>
            </CustomCenterFormBottomWrap>
          </MobileCustomerCenterFormWrap>
        )}
      </Form> */}
    </>
  )
}

export default MobileOnetoOneWrite

// endregion

const QuestionButton = styled.button`
  width: 100px;
  height: 38px;
  background: none;
  border-radius: 6px !important;
  outline: none;

  border: 2px solid ${HermesMainBorder};
`

const BackButton = styled.button`
  width: 100px;
  height: 38px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  outline: none;

  border: 2px solid ${HermesMainBorder};
`
