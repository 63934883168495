import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import isEqual from 'lodash/isEqual'
import { VirtualGameBettingTypeKr } from '../utils/enums/VirtualGame/VirtualGameEnums'

const calcBettingPer = state => {
  const priceNumList = state.bettingCartList.map(item => item.selectedBetInfo.betPrice)

  if (priceNumList.length === 0) {
    state.totalBettingPer = 0
    return
  }

  let totalBettingPer = Number(priceNumList[0])

  for (let i = 1; i < priceNumList.length; i++) {
    const value = Number(priceNumList[i])
    totalBettingPer *= value
  }

  state.totalBettingPer = Math.floor(totalBettingPer * 100) / 100
}

// 조합 가능여부 체크 - 종목별
const isVaildMarketCombination = (state, prevBetType, betType) => {
  const sportType = state.selectedVirtuaGameSportType
  const { virtualGameBettingInfos } = state

  if (sportType === 'VIRTUAL_SOCCER') {
    const virtualGameBettingInfo = virtualGameBettingInfos.VIRTUAL_SOCCER

    // 승/패 + 언오버
    if (
      (prevBetType === 'WIN_DRAW_LOSE_HOME_PICK' || prevBetType === 'WIN_DRAW_LOSE_AWAY_PICK') &&
      (betType === 'UNDER_OVER_OVER_PICK' || betType === 'UNDER_OVER_UNDER_PICK')
    ) {
      return virtualGameBettingInfo.usedWinLoseUnderOver
    }

    // 언오버 + 승/패
    if (
      (prevBetType === 'UNDER_OVER_OVER_PICK' || prevBetType === 'UNDER_OVER_UNDER_PICK') &&
      (betType === 'WIN_DRAW_LOSE_HOME_PICK' || betType === 'WIN_DRAW_LOSE_AWAY_PICK')
    ) {
      return virtualGameBettingInfo.usedWinLoseUnderOver
    }

    // 무 + 언오버
    if (
      prevBetType === 'WIN_DRAW_LOSE_DRAW_PICK' &&
      (betType === 'UNDER_OVER_OVER_PICK' || betType === 'UNDER_OVER_UNDER_PICK')
    ) {
      return virtualGameBettingInfo.usedDrawUnderOver
    }

    // 언오버 + 무
    if (
      (prevBetType === 'UNDER_OVER_OVER_PICK' || prevBetType === 'UNDER_OVER_UNDER_PICK') &&
      betType === 'WIN_DRAW_LOSE_DRAW_PICK'
    ) {
      return virtualGameBettingInfo.usedDrawUnderOver
    }

    // 승/패 + 핸디
    if (
      (prevBetType === 'WIN_DRAW_LOSE_HOME_PICK' || prevBetType === 'WIN_DRAW_LOSE_AWAY_PICK') &&
      (betType === 'HANDICAP_HOME_PICK' || betType === 'HANDICAP_AWAY_PICK')
    ) {
      return virtualGameBettingInfo.usedWinLoseHandicap
    }

    // 핸디 + 승/패
    if (
      (prevBetType === 'HANDICAP_HOME_PICK' || prevBetType === 'HANDICAP_AWAY_PICK') &&
      (betType === 'WIN_DRAW_LOSE_HOME_PICK' || betType === 'WIN_DRAW_LOSE_AWAY_PICK')
    ) {
      return virtualGameBettingInfo.usedWinLoseHandicap
    }

    // 무 + 핸디
    if (
      prevBetType === 'WIN_DRAW_LOSE_DRAW_PICK' &&
      (betType === 'HANDICAP_HOME_PICK' || betType === 'HANDICAP_AWAY_PICK')
    ) {
      return virtualGameBettingInfo.usedDrawHandicap
    }

    // 핸디 + 무
    if (
      (prevBetType === 'HANDICAP_HOME_PICK' || prevBetType === 'HANDICAP_AWAY_PICK') &&
      betType === 'WIN_DRAW_LOSE_DRAW_PICK'
    ) {
      return virtualGameBettingInfo.usedDrawHandicap
    }

    // 핸디 + 언오버
    if (
      (prevBetType === 'HANDICAP_HOME_PICK' || prevBetType === 'HANDICAP_AWAY_PICK') &&
      (betType === 'UNDER_OVER_OVER_PICK' || betType === 'UNDER_OVER_UNDER_PICK')
    ) {
      return virtualGameBettingInfo.usedHandicapUnderOver
    }

    // 언오버 + 핸디
    if (
      (prevBetType === 'UNDER_OVER_OVER_PICK' || prevBetType === 'UNDER_OVER_UNDER_PICK') &&
      (betType === 'HANDICAP_HOME_PICK' || betType === 'HANDICAP_AWAY_PICK')
    ) {
      return virtualGameBettingInfo.usedHandicapUnderOver
    }

    return false
  }

  if (sportType === 'VIRTUAL_BASKETBALL') {
    const virtualGameBettingInfo = virtualGameBettingInfos.VIRTUAL_BASKETBALL

    // 승/패 + 언오버
    if (
      (prevBetType === 'WIN_DRAW_LOSE_HOME_PICK' || prevBetType === 'WIN_DRAW_LOSE_AWAY_PICK') &&
      (betType === 'UNDER_OVER_OVER_PICK' || betType === 'UNDER_OVER_UNDER_PICK')
    ) {
      return virtualGameBettingInfo.usedWinLoseUnderOver
    }

    // 언오버 + 승/패
    if (
      (prevBetType === 'UNDER_OVER_OVER_PICK' || prevBetType === 'UNDER_OVER_UNDER_PICK') &&
      (betType === 'WIN_DRAW_LOSE_HOME_PICK' || betType === 'WIN_DRAW_LOSE_AWAY_PICK')
    ) {
      return virtualGameBettingInfo.usedWinLoseUnderOver
    }

    // 승/패 + 핸디
    if (
      (prevBetType === 'WIN_DRAW_LOSE_HOME_PICK' || prevBetType === 'WIN_DRAW_LOSE_AWAY_PICK') &&
      (betType === 'HANDICAP_HOME_PICK' || betType === 'HANDICAP_AWAY_PICK')
    ) {
      return virtualGameBettingInfo.usedWinLoseHandicap
    }

    // 핸디 + 승/패
    if (
      (prevBetType === 'HANDICAP_HOME_PICK' || prevBetType === 'HANDICAP_AWAY_PICK') &&
      (betType === 'WIN_DRAW_LOSE_HOME_PICK' || betType === 'WIN_DRAW_LOSE_AWAY_PICK')
    ) {
      return virtualGameBettingInfo.usedWinLoseHandicap
    }

    // 핸디 + 언오버
    if (
      (prevBetType === 'HANDICAP_HOME_PICK' || prevBetType === 'HANDICAP_AWAY_PICK') &&
      (betType === 'UNDER_OVER_OVER_PICK' || betType === 'UNDER_OVER_UNDER_PICK')
    ) {
      return virtualGameBettingInfo.usedHandicapUnderOver
    }

    // 언오버 + 핸디
    if (
      (prevBetType === 'UNDER_OVER_OVER_PICK' || prevBetType === 'UNDER_OVER_UNDER_PICK') &&
      (betType === 'HANDICAP_HOME_PICK' || betType === 'HANDICAP_AWAY_PICK')
    ) {
      return virtualGameBettingInfo.usedHandicapUnderOver
    }

    return false
  }

  if (sportType === 'VIRTUAL_BASEBALL') {
    const virtualGameBettingInfo = virtualGameBettingInfos.VIRTUAL_BASEBALL

    // 승/패 + 언오버
    if (
      (prevBetType === 'WIN_DRAW_LOSE_HOME_PICK' || prevBetType === 'WIN_DRAW_LOSE_AWAY_PICK') &&
      (betType === 'UNDER_OVER_OVER_PICK' || betType === 'UNDER_OVER_UNDER_PICK')
    ) {
      return virtualGameBettingInfo.usedWinLoseUnderOver
    }

    // 언오버 + 승/패
    if (
      (prevBetType === 'UNDER_OVER_OVER_PICK' || prevBetType === 'UNDER_OVER_UNDER_PICK') &&
      (betType === 'WIN_DRAW_LOSE_HOME_PICK' || betType === 'WIN_DRAW_LOSE_AWAY_PICK')
    ) {
      return virtualGameBettingInfo.usedWinLoseUnderOver
    }

    // 무 + 언오버
    if (
      prevBetType === 'WIN_DRAW_LOSE_DRAW_PICK' &&
      (betType === 'UNDER_OVER_OVER_PICK' || betType === 'UNDER_OVER_UNDER_PICK')
    ) {
      return virtualGameBettingInfo.usedDrawUnderOver
    }

    // 언오버 + 무
    if (
      (prevBetType === 'UNDER_OVER_OVER_PICK' || prevBetType === 'UNDER_OVER_UNDER_PICK') &&
      betType === 'WIN_DRAW_LOSE_DRAW_PICK'
    ) {
      return virtualGameBettingInfo.usedDrawUnderOver
    }

    return false
  }

  return false
}

const virtualGameBettingSlice = createSlice({
  name: 'virtualGameBetting',
  initialState: {
    /**
     * 가상게임 기본 정보
     */
    selectedVirtuaGameSportType: null,

    virtualGameBettingInfos: {},
    marketCombinationList: {},

    /**
     * 아래부터 배팅카트 요소
     */
    bettingCartLoading: false,
    totalBettingPer: 0,
    bettingCartList: [],
    bettingCartAlertMessage: '',
    bettingCartUpdateMessage: '',
  },
  reducers: {
    // 종목 선택
    setSelectedVirtuaGameSportType: (state, action) => {
      state.selectedVirtuaGameSportType = action.payload
    },

    // 초기 데이터 세팅
    setVirtualGameBettingInfos: (state, action) => {
      const virtualGameBettingInfos = action.payload

      state.virtualGameBettingInfos = virtualGameBettingInfos.reduce((acc, item) => {
        if (!acc[item.virtualGameSportType]) {
          acc[item.virtualGameSportType] = {}
        }

        acc[item.virtualGameSportType] = item

        return acc
      }, {})
    },

    // 배팅 카트 로딩 업데이트
    setBettingCartLoading: (state, action) => {
      state.bettingCartLoading = action.payload
    },

    // 배팅카트 담기
    addBettingCartItem: (state, action) => {
      const { fixture, marketInfo, selectedBetInfo } = action.payload
      if (!fixture || !marketInfo || !selectedBetInfo) return

      // 동일한 배팅아이템 일때 빼기
      const equalMarketKey = selectedBetInfo.raceNumber
        ? `${fixture.eventId}-${fixture.leagueType}-${marketInfo.marketId}-${selectedBetInfo.betType}-${selectedBetInfo.raceNumber}`
        : `${fixture.eventId}-${fixture.leagueType}-${marketInfo.marketId}-${selectedBetInfo.betType}`

      const equalMarketIdIdx = state.bettingCartList.findIndex(item => {
        if (item.selectedBetInfo.raceNumber) {
          return (
            `${item.fixture.eventId}-${item.fixture.leagueType}-${item.marketInfo.marketId}-${item.selectedBetInfo.betType}-${item.selectedBetInfo.raceNumber}` ===
            equalMarketKey
          )
        }

        return (
          `${item.fixture.eventId}-${item.fixture.leagueType}-${item.marketInfo.marketId}-${item.selectedBetInfo.betType}` ===
          equalMarketKey
        )
      })

      if (equalMarketIdIdx !== -1) {
        state.bettingCartList.splice(equalMarketIdIdx, 1)
        // 총 배당 계산
        calcBettingPer(state)
        return
      }

      const virtualGameBettingInfo = state.virtualGameBettingInfos[state.selectedVirtuaGameSportType]

      // 2개 부터 아래 체크
      const sameLeagueKey = `${fixture.eventId}-${fixture.leagueType}`
      const sameLeagueItems = state.bettingCartList.filter(
        item => `${item.fixture.eventId}-${item.fixture.leagueType}` === sameLeagueKey,
      )

      // 리그가 같은게 없어
      if (sameLeagueItems.length === 0) {
        // 서로 다른 리그와 배팅 가능여부 체크
        // ex) 프리미어 , 유로컵 => 유로컵
        if (virtualGameBettingInfo.diffLeagueBetting) {
          state.bettingCartList.push(action.payload)
        } else {
          state.bettingCartList = []
          state.bettingCartList.push(action.payload)
        }

        // 총 배당 계산
        calcBettingPer(state)
        return
      }

      // 같은 리그가 존재
      if (sameLeagueItems.length === 1) {
        const key = `${fixture.eventId}-${fixture.leagueType}-${marketInfo.marketId}`

        const sameMarketIdIdx = state.bettingCartList.findIndex(
          item => `${item.fixture.eventId}-${item.fixture.leagueType}-${item.marketInfo.marketId}` === key,
        )

        if (sameMarketIdIdx === -1) {
          const prevBettingItem = sameLeagueItems[0]

          // 조합이 전부 false 이면 변경되도록
          const tempVirtualGameBettingInfo = state.virtualGameBettingInfos[state.selectedVirtuaGameSportType]

          const allNotUseCombination =
            !tempVirtualGameBettingInfo.usedWinLoseUnderOver &&
            !tempVirtualGameBettingInfo.usedWinLoseHandicap &&
            !tempVirtualGameBettingInfo.usedDrawUnderOver &&
            !tempVirtualGameBettingInfo.usedDrawHandicap &&
            !tempVirtualGameBettingInfo.usedDrawUnderOver

          if (allNotUseCombination) {
            const idx = state.bettingCartList.findIndex(
              item =>
                `${item.fixture.eventId}-${item.fixture.leagueType}` === `${fixture.eventId}-${fixture.leagueType}`,
            )
            state.bettingCartList[idx] = action.payload
            // 총 배당 계산
            calcBettingPer(state)
            return
          }

          const combinationResult = isVaildMarketCombination(
            state,
            prevBettingItem.selectedBetInfo.betType,
            selectedBetInfo.betType,
          )

          if (combinationResult) {
            // 조합 가능
            state.bettingCartList.push(action.payload)
            // 총 배당 계산
            calcBettingPer(state)
          } else {
            // 조합 불가
            state.bettingCartAlertMessage = `[${prevBettingItem.marketInfo.marketName} - ${
              VirtualGameBettingTypeKr[prevBettingItem.selectedBetInfo.betType]
            }] , [${marketInfo.marketName} - ${
              VirtualGameBettingTypeKr[selectedBetInfo.betType]
            }] 조합이 불가능 합니다.`
            return
          }
        } else {
          state.bettingCartList[sameMarketIdIdx] = action.payload
          // 총 배당 계산
          calcBettingPer(state)
          return
        }
      }

      // 유로컵 , 프리미어, 유로컵 , 유로컵 -> 같은 리그가 3개 부터는 조합불가로
      if (sameLeagueItems.length >= 2) {
        const key = `${fixture.eventId}-${fixture.leagueType}-${marketInfo.marketId}`

        const sameMarketIdIdx = state.bettingCartList.findIndex(
          item => `${item.fixture.eventId}-${item.fixture.leagueType}-${item.marketInfo.marketId}` === key,
        )

        if (sameMarketIdIdx === -1) {
          // 조합 불가 문구
        } else {
          state.bettingCartList[sameMarketIdIdx] = action.payload
          // 총 배당 계산
          calcBettingPer(state)
        }
      }
    },

    // 배팅카트에서 단건 삭제
    removeBettingCartItem: (state, action) => {
      const bettingCartItem = action.payload

      const key = `${bettingCartItem.fixture.eventId}-${bettingCartItem.fixture.leagueType}-${bettingCartItem.marketInfo.marketId}`

      // 1. 동일한 마켓을 선택시 삭제하기
      const deleteIdx = state.bettingCartList.findIndex(
        item => `${item.fixture.eventId}-${item.fixture.leagueType}-${item.marketInfo.marketId}` === key,
      )

      if (deleteIdx === -1) return

      state.bettingCartList.splice(deleteIdx, 1)

      // 최종 총 배당 계산하기
      calcBettingPer(state)
    },
    // 배팅카트에서 전체 삭제
    removeAllBettingCartItem: (state, action) => {
      state.bettingCartList = []
      state.totalBettingPer = 0
    },

    // 배팅카트 Alert Close 처리
    onClickBettingCartAlertCloseHandler: (state, action) => {
      state.bettingCartAlertMessage = ''
    },

    // 배팅카트 Alert Close 처리
    onClickBettingCartUpdateMessageCloseHandler: (state, action) => {
      state.bettingCartUpdateMessage = ''
    },
  },
})

export const {
  setSelectedVirtuaGameSportType,
  setVirtualGameBettingInfos,
  setBettingCartLoading,
  addBettingCartItem,
  removeAllBettingCartItem,
  removeBettingCartItem,
  onClickBettingCartAlertCloseHandler,
  onClickBettingCartUpdateMessageCloseHandler,
} = virtualGameBettingSlice.actions

export default virtualGameBettingSlice.reducer
