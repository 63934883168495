import { useEffect, useState } from 'react'
import axios from '../base/axios'

export async function fetchClientHeaderPolling(params) {
  const response = await axios({
    url: '/tyson/member/v1/header-info/polling',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function fetchClientInspectionPolling(params) {
  const response = await axios({
    url: '/tyson/member/v1/header-info/polling/inspection',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
