import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { confirmRecommendCode, searchRecommendCode, siginIn } from '../../api/authManage/authManageApi'
import { ButtonH40 } from '../../shared/components/GoldButton'
import SignUpPopup from '../Popup/SignUp/SignUpPopup'
import { HermesMainBorder, HermesMainHover, HermesMainHover2 } from '../../utils/palette'
import SignUpModal from '../Layout/SignUpModal'
import { createAccessToken, handleSignIn } from '../../socketio/userAuthSocketIOHandler'
import { setUserInfo } from '../../redux/memberInfoSlice'
import { setSportsBettingInfos } from '../../redux/sportsBettingSlice'
import { setAccessToken } from '../../utils/token'
import CustomLoading from '../CustomLoading'
import { confirmCode } from '../../redux/authSlice'
// import styles from '../../css/common2.css'

const accountIcon = `${process.env.PUBLIC_URL}/img/account/account_icon.png`
const passwdIcon = `${process.env.PUBLIC_URL}/img/account/passwd_icon.png`

const LoginPage = () => {
  const dispatch = useDispatch()
  const [recParams] = useSearchParams()
  const [memberId, setMemberId] = useState('')
  const [memberPassword, setMemberPassword] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const originalMinWidth = document.body.style.minWidth
    const originalOverflowY = document.documentElement.style.overflowY

    document.body.style.minWidth = '390px'
    document.documentElement.style.overflowY = 'hidden'

    return () => {
      document.body.style.minWidth = originalMinWidth
      document.documentElement.style.overflowY = originalOverflowY
    }
  }, [])
  // const [inputCaptchaCode, setInputCaptchaCode] = useState('')

  // useEffect(() => {
  //   loadCaptchaEnginge(4, 'black', 'white', 'numbers')
  // }, [])

  const [siginInFlag, setSignInFlag] = useState(false)
  const [loginIp, setLoginIp] = useState('')
  const [userAgent, setUserAgent] = useState('')

  useEffect(() => {
    axios.get('https://geolocation-db.com/json/').then(res => {
      setLoginIp(res.data.IPv4 || res.data.IPv6)
    })
  }, [])

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onShowSignUpModal = () => {
    setSignUpModalShow(true)
  }
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  const resetData = () => {
    setMemberId('')
    setMemberPassword('')
  }

  const onSubmit = e => {
    if (!memberId) {
      alert('아이디를 입력하세요.')
      resetData()
      return
    }
    if (!memberPassword) {
      alert('비밀번호를 입력하세요.')
      resetData()
      return
    }

    if (siginInFlag) {
      return
    }

    setSignInFlag(true)

    const body = {
      userId: memberId,
      password: memberPassword,
      // eslint-disable-next-line no-useless-escape
      host: window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1'),
    }

    handleSignIn(body)
      .then(signInRes => {
        if (!signInRes.status) {
          alert(signInRes.data)
          setSignInFlag(false)
          return
        }
        const tokenData = {
          userId: memberId,
          password: memberPassword,
        }

        // 토큰 발급
        createAccessToken(tokenData)
          .then(atRes => {
            if (!atRes.status) {
              alert('다시 로그인해주세요.')
              setSignInFlag(false)
              return
            }
            // 유저 정보 리덕스에 저장
            dispatch(setUserInfo(atRes.data.user))

            dispatch(setSportsBettingInfos(atRes.data.user.sportsBettingInfos))

            // 로컬 스토리지에 토큰 저장
            setAccessToken(atRes.data.accessToken)
            setSignInFlag(false)
            const noteSize = atRes.data.user.noteRedisSize
            const oneToOneSize = atRes.data.user.oneToOneRedisSize

            if (!noteSize || !oneToOneSize) {
              navigate('/main')
            } else if (noteSize !== 0 && oneToOneSize === 0) {
              // alert('쪽지함을 먼저 확인해주세요.')
              navigate('note')
            } else if (noteSize !== 0 && oneToOneSize !== 0) {
              // alert('쪽지함을 먼저 확인해주세요.')
              navigate('note')
            } else if (noteSize === 0 && oneToOneSize !== 0) {
              // alert('1:1문의 답변을 먼저 확인해주세요.')
              navigate('/one-to-one')
            } else {
              navigate('/main')
            }
          })
          .finally(() => {
            setSignInFlag(false)
            resetData()
          })
      })
      .finally(() => {
        setSignInFlag(false)
        resetData()
      })
  }

  const { status, error } = useSelector(state => state.auth)

  const [showCodePage, setShowCodePage] = useState(false)
  const [recommendCode, setRecommendCode] = useState()

  useEffect(() => {
    searchRecommendCode().then(res => {
      const rec = res.data.recommendCode
      if (rec) {
        setRecommendCode(rec)
      }
    })
  }, [])

  const checkRecommendCode = e => {
    if (!recommendCode) {
      alert('가입코드를 입력하세요.')
      return
    }

    if (siginInFlag) {
      return
    }
    setSignInFlag(true)

    dispatch(confirmCode(recommendCode))
      .unwrap()
      .then(() => {
        setSignInFlag(false)
        navigate(`/signup?recommendCode=${encodeURIComponent(recommendCode)}`)
      })
      .catch(() => {
        setSignInFlag(false)
        alert('유효하지 않는 가입코드입니다.')
      })
  }
  return (
    <>
      <LoginPageWrap>
        <LoginVideo>
          <video autoPlay loop muted>
            <source src="https://ivbk.s3.ap-northeast-3.amazonaws.com/zeus/log.mp4" type="video/mp4" />
          </video>
        </LoginVideo>
        <LoginForm>
          {siginInFlag && <CustomLoading />}
          <div style={{ height: '195px' }} />
          <div style={{ width: '272px', margin: '0 auto' }}>
            <img
              style={{ width: '270px', height: '95px' }}
              src={`${process.env.PUBLIC_URL}/img/logo/logo.png`}
              alt=""
            />
          </div>
          <div style={{ height: '20px' }} />
          {showCodePage ? (
            <>
              <div style={{ width: '300px', margin: '0 auto', height: '34px' }}>
                <input
                  autoComplete="off"
                  id="ip_joincode"
                  type="text"
                  className="passwd_input"
                  placeholder="가입코드"
                  style={{
                    touchAction: 'none',
                    backgroundImage: `url(${passwdIcon})`,
                  }}
                  value={recommendCode}
                  onChange={e => {
                    setRecommendCode(e.target.value)
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      checkRecommendCode()
                    }
                  }}
                />
              </div>
              <div style={{ height: '20px' }} />
              <div style={{ width: '300px', margin: '0 auto', height: '30px', textAlign: 'center' }}>
                <a
                  onClick={() => {
                    checkRecommendCode()
                  }}
                >
                  <input style={{ width: '145px' }} type="button" className="login_button" value="가입하기" />
                </a>
                <a
                  style={{ marginLeft: '5px' }}
                  onClick={() => {
                    setShowCodePage(false)
                  }}
                >
                  <input style={{ width: '145px' }} type="button" className="join_button" value="뒤로가기" />
                </a>
              </div>
              <div style={{ height: '30px' }} />
            </>
          ) : (
            <>
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  memberId,
                  memberPassword,
                  // inputCaptchaCode,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <div onSubmit={handleSubmit} style={{}}>
                    <div style={{ width: '300px', margin: '0 auto', height: '34px' }}>
                      <Field name="memberId">
                        {({ input, meta }) => (
                          <input
                            {...input}
                            type="text"
                            value={memberId}
                            autoComplete="off"
                            style={{
                              width: '250px',
                              touchAction: 'none',
                              backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/account_icon.png)`,
                            }}
                            placeholder="회원아이디"
                            onChange={e => {
                              setMemberId(e.target.value)
                            }}
                            className="account_input"
                          />
                        )}
                      </Field>
                    </div>
                    <div style={{ height: '10px' }} />
                    <div style={{ width: '300px', margin: '0 auto', height: '34px' }}>
                      <Field name="memberPassword">
                        {({ input, meta }) => (
                          <input
                            {...input}
                            type="password"
                            inputMode="latin"
                            style={{ touchAction: 'none', backgroundImage: `url(${passwdIcon})`, width: '250px' }}
                            value={memberPassword}
                            placeholder="비밀번호"
                            onChange={e => {
                              setMemberPassword(e.target.value)
                            }}
                            className="passwd_input"
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                onSubmit()
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div style={{ height: '20px' }} />
                    <div
                      style={{
                        width: '300px',
                        margin: '0 auto',
                        height: '30px',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        verticalAlign: 'middle',
                        display: 'flex',
                      }}
                    >
                      <button style={{ width: '145px' }} type="submit" className="login_button" onClick={onSubmit}>
                        로그인
                      </button>
                      <input
                        style={{ width: '145px', height: '30px' }}
                        type="button"
                        className="join_button"
                        value="회원가입"
                        onClick={() => {
                          setShowCodePage(true)
                        }}
                      />
                    </div>
                  </div>
                )}
              </Form>
              <div style={{ height: '30px' }} />
              <div
                style={{
                  width: '400px',
                  margin: '0 auto',
                  height: '30px',
                  textAlign: 'center',
                  color: '#999',
                  cursor: 'pointer',
                }}
                // onClick={() => {
                //   window.open('https://www./')
                // }}
              >
                Copyrightⓒ 2019 All rights reserved by 제우스.
              </div>
            </>
          )}
        </LoginForm>
      </LoginPageWrap>
    </>
  )
}

export default LoginPage

const LoginPageWrap = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  margin-top: 0em;
  font-size: 12px;
  overflow: hidden;
`

const LoginVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // overflow: hidden;
  //
  // transform: translate(-50%, -50%);
  // transform: scaleY(1.5);
  z-index: -1;
  // border: soild red 10px;

  video {
    // border: 1px solid red;
    // width: 100%;
    // height: 100%;
    position: absolute;
    // top: -8.7%;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: scale-down;
  }
`

const LoginForm = styled.div`
  margin-top: 110px;
`
