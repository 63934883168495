export const SportsResultStatusEnums = {
  ING: '진행중',
  WIN: '적중',
  LOSE: '미적중',
  DRAW: '적특',
  CANCEL: '취소',
}

export const SportsResultSettlementTypeEnumsV2 = {
  NOT_SETTLED: '진행중',
  WINNER: '적중',
  LOSER: '미적중',
  REFUND: '적특',
  CANCELLED: '취소',
  HALF_LOST: '미적중',
  HALF_WON: '적중',
  INVALID: '무효',
}

export const SportTypeEnums = {
  SOCCER: '축구',
  AMERICAN_FOOTBALL: '미식축구',
  BOXING: '복싱/UFC',
  TENNIS: '테니스',
  BASEBALL: '야구',
  ICE_HOCKEY: '아이스하키',
  BASKETBALL: '농구',
  HANDBALL: '핸드볼',
  VOLLEYBALL: '배구',
  TABLE_TENNIS: '탁구',
  E_SPORTS: 'E-SPORTS',
  ETC: '기타',
}

export const SportMarketType = {
  DOMESTIC: 'DOMESTIC',
  OVERSEAS: 'OVERSEAS',
  SPECIAL: 'SPECIAL',
  LIVE: 'LIVE',
}

export const SportMarketTypeV2 = {
  DOMESTIC: '국내형',
  OVERSEAS: '해외형',
  SPECIAL: '스페셜',
  LIVE: '인플레이',
}

export const FixtureType = {
  PRE_MATCH: 'PRE_MATCH',
  IN_PLAY: 'IN_PLAY',
}

export const FixtureSortType = {
  POPULAR: 'POPULAR',
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
}
