import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { searchMemberPointDeposit } from '../../../api/memberHistory/memberHistoryApi'
import CustomLoading from '../../../containers/CustomLoading'
import {
  MobileCompHistoryHeaderWrap,
  MobileCustomerCenterContentWrap,
  MobileCustomerCenterHeaderInfo,
  MobileMoneyHeaderSubhead,
  MobileTableWrap,
  MobileTopWrap,
} from '../../../shared/components/MoneyCustomElement'
import MobilePointDepositListData from './MobilePointDepositListData'

const MobilePointDepositList = () => {
  /**
    마이페이지 - 콤프입금내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [params, setParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    searchMemberPointDeposit(params)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [params])

  const [pointDepositListData, setPointDepositListData] = useState(MobilePointDepositListData(content))

  useEffect(() => {
    setPointDepositListData(MobilePointDepositListData(content))
  }, [content])

  const [rows, setData] = useState(pointDepositListData.tableRowsData)
  useEffect(() => {
    setData(pointDepositListData.tableRowsData)
  }, [pointDepositListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <MobileTopWrap>
      <CustomMobileCompHistoryHeaderWrap>
        <MobileCustomerCenterHeaderInfo>포인트 입금내역</MobileCustomerCenterHeaderInfo>
        <MobileMoneyHeaderSubhead>* 카지노, 미니게임 배팅 시 1분이내 지급됩니다.</MobileMoneyHeaderSubhead>
        <MobileMoneyHeaderSubhead>* 스포츠 배팅 시 최종 결과가 나온 후 1분이내에 지급됩니다.</MobileMoneyHeaderSubhead>
      </CustomMobileCompHistoryHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <MobileCustomerCenterContentWrap>
          <MobileTableWrap>
            <ReactTableBase columns={pointDepositListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </MobileTableWrap>
        </MobileCustomerCenterContentWrap>
      )}
    </MobileTopWrap>
  )
}

export default MobilePointDepositList

const CustomMobileCompHistoryHeaderWrap = styled(MobileCompHistoryHeaderWrap)`
  height: 85px;
  padding-top: 5px;
`
