import React, { useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import { fetchNoticeBoardList } from '../../../redux/customCenterSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import {
  CustomerCenterContentWrap,
  CustomerCenterHeaderInfo,
  CustomerCenterHeaderWrap,
} from '../../../shared/components/MoneyCustomElement'
import { WebPageWrap } from '../../../shared/components/form/newForm'
import { BoardTypeEnums } from '../../../utils/enums/Operation/boardEnums'
import CustomLoading from '../../CustomLoading'
import NoticeDetail from './NoticeDetail'

const NoticeListPage = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [boardParams, setBoardParams] = useState({
    boardType: 'NOTICE',
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [content, setContent] = useState([])

  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.noticeInfo.totalElement,
      apiContent: customCenter.noticeInfo.content,
    }
  })
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.boardKey === item.boardKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchBoardList = async () => {
    try {
      dispatch(fetchNoticeBoardList(boardParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchBoardList()
  }, [boardParams])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = boardKey => {
    setContent(prev => {
      const idx = prev.findIndex(item => item.boardKey === boardKey)

      if (idx !== -1) {
        // 원래의 state를 복사하여 새로운 배열을 만듭니다.
        const newState = [...prev]
        newState[idx] = {
          ...newState[idx],
          isVisible: !newState[idx].isVisible,
        }

        // 새로운 배열을 반환합니다.
        return newState
      }

      // 인덱스를 찾지 못한 경우, 원래의 state를 그대로 반환합니다.
      return prev
    })
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  const [showDetail, setShowDetail] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleRowClick = boardKey => {
    const boardContent = content.find(item => item.boardKey === boardKey)
    if (!boardContent) return
    setSelectedItem(boardContent)
    setShowDetail(true)
  }

  return (
    <>
      {showDetail ? (
        <NoticeDetail notice={selectedItem} onBack={() => setShowDetail(false)} />
      ) : (
        <div className="div_content" style={{ position: 'relative' }}>
          <div className="div_body">
            <div className="content">
              <table className="tb_notice_box">
                <tbody>
                  <tr>
                    <td className="td_notice_td">
                      <span style={{ fontSize: '10pt' }}>
                        <b>
                          <span style={{ color: 'rgb(0,0,0)' }}>항시 공지사항을 숙지하시고 이용하시기 바랍니다. </span>
                        </b>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="height_20" />
              <div className="div_list">
                <div id="div_board_list" className="board_list">
                  <table style={{ textAlign: 'center' }}>
                    <colgroup>
                      <col style={{ width: '8%' }} />
                      <col style={{ width: '8%' }} />
                      <col style={{ width: '67%' }} />
                      <col style={{ width: '17%' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>번호</th>
                        <th>구분</th>
                        <th>제목</th>
                        <th>작성자</th>
                      </tr>
                    </thead>
                    <tbody>
                      {content.length === 0 ? (
                        <tr>
                          <td colSpan="4">공지사항 내역이 없습니다.</td>
                        </tr>
                      ) : (
                        <>
                          {content.map(item => (
                            <>
                              <tr key={item.boardKey}>
                                <td>{item.num}</td>
                                <td>
                                  {item.type === 'NOTICE' ? (
                                    <span className="ic_notice">{BoardTypeEnums[item.type]}</span>
                                  ) : (
                                    <span className="ic_bettingrule">{BoardTypeEnums[item.type]}</span>
                                  )}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: '10px',
                                    cursor: 'pointer',
                                    wordBreak: 'break-all',
                                  }}
                                  onClick={() => handleRowClick(item.boardKey)}
                                >
                                  <b style={{ color: 'rgb(0,0,0)', fontWeight: '900' }}>★Zeus★&nbsp;</b>
                                  <span style={{ color: 'rgb(0,0,0)', fontWeight: '500' }}>
                                    &nbsp;
                                    {item.boardTitle}
                                  </span>
                                </td>
                                <td>
                                  <img
                                    src={`${process.env.PUBLIC_URL}/img/level/08.png`}
                                    alt=""
                                    align="absmiddle"
                                    style={{ marginRight: '5px', marginTop: '-2px', width: '40px', height: '32px' }}
                                  />{' '}
                                  제우스 운영팀
                                </td>
                              </tr>
                            </>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* <div className="height_10" />
            <div style={{ textAlign: 'center' }}>
              <a href="/one-to-one-write" className="normal_button">
                문의하기
              </a>
            </div> */}
            </div>
          </div>
        </div>
      )}
    </>
    // <WebPageWrap ref={webPageWrapRef}>
    //   <CustomerCenterHeaderWrap>
    //     <CustomerCenterHeaderInfo>공지사항</CustomerCenterHeaderInfo>
    //   </CustomerCenterHeaderWrap>
    //   {loading ? (
    //     <CustomLoading />
    //   ) : (
    //     <CustomerCenterContentWrap>
    //       <CustomerCenterContentTable>
    //         <CustomerCenterContentTableHead>
    //           <CustomerCenterContentTableHeadTr>
    //             <th style={{ width: '8%' }}>번호</th>
    //             <th style={{ width: '10%' }}>구분</th>
    //             <th style={{ width: '82%' }}>내용</th>
    //           </CustomerCenterContentTableHeadTr>
    //         </CustomerCenterContentTableHead>
    //         <CustomerCenterContentTableBody>
    //           {content.map(item => (
    //             <>
    //               <CustomerCenterContentTableBodyTr key={item.boardKey}>
    //                 <CustomerCenterContentBox>
    //                   <CustomerCenterContenItemBox width="8">{item.num}</CustomerCenterContenItemBox>
    //                   <CustomerCenterContenItemBox width="10">{BoardTypeEnums[item.type]}</CustomerCenterContenItemBox>
    //                   <CustomerCenterContenItemBox
    //                     width="82"
    //                     onClick={() => onClickRowHandler(item.boardKey)}
    //                     style={{ cursor: 'pointer', flexDirection: 'column' }}
    //                   >
    //                     {item.boardTitle}
    //                   </CustomerCenterContenItemBox>
    //                 </CustomerCenterContentBox>
    //               </CustomerCenterContentTableBodyTr>
    //               <Collapse in={item.isVisible}>
    //                 <CustomerCenterContentTableBodyTr>
    //                   <CustomerCenterContentDetailBox>
    //                     {ReactHtmlParser(item.boardContent, { transform: transformFn })}
    //                   </CustomerCenterContentDetailBox>
    //                 </CustomerCenterContentTableBodyTr>
    //               </Collapse>
    //             </>
    //           ))}
    //         </CustomerCenterContentTableBody>
    //       </CustomerCenterContentTable>
    //       {totalElement > 0 && (
    //         <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
    //       )}
    //     </CustomerCenterContentWrap>
    //   )}
    // </WebPageWrap>
  )
}
export default NoticeListPage

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;

  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  padding: 10px 5px;

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-weight: 600;
  }
`
