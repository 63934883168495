import React from 'react'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { MemberDepositStatusEnums } from '@/utils/enums/MyPage/myPageEnums'
import { convertToKstByFormat } from '@/utils/dateTime'
import DepositDeleteRadio from '../../../containers/MoneyDeposit/DepositDeleteRadio'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MobileMoneyDepositListData = (content, checkRadio, setCheckRadio) => {
  const columns = [
    {
      Header: '선택',
      accessor: 'deleteRadio',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <DepositDeleteRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
    },
    {
      Header: '신청액',
      accessor: 'applyMoney',
      disableGlobalFilter: true,
      width: 90,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.applyMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 90,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstByFormat(original.executeDate, 'MM-DD HH:mm')
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 30,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MemberDepositStatusEnums[original.status]
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default MobileMoneyDepositListData
