export const signUpValidate = values => {
  const errors = {}
  if (!values.userId) {
    errors.userId = '필수 입력 항목입니다.'
  }
  if (values.userId && !values.isConfirmUserId) {
    errors.userId = '아이디 중복 확인 해주세요.'
  }

  if (!values.nickName) {
    errors.nickName = '필수 입력 항목입니다.'
  }

  if (values.nickName && !values.isConfirmNickName) {
    errors.nickName = '닉네임 중복 확인 해주세요.'
  }

  if (!values.password) {
    errors.password = '필수 입력 항목입니다.'
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = '필수 입력 항목입니다.'
  }
  if (values.confirmPassword && !(values.password === values.confirmPassword)) {
    errors.confirmPassword = '비밀번호가 일치하지 않습니다.'
  }
  if (!values.memberName) {
    errors.memberName = '필수 입력 항목입니다.'
  }
  if (!values.memberPhoneNum) {
    errors.memberPhoneNum = '필수 입력 항목입니다.'
  }
  // if (!values.withdrawalPassword) {
  //   errors.withdrawalPassword = '필수 입력 항목입니다.'
  // }
  if (!values.bankName) {
    errors.bankName = '필수 선택 항목입니다.'
  }
  if (!values.accountNum) {
    errors.accountNum = '필수 입력 항목입니다.'
  }
  if (!values.userBirthDayFirst) {
    errors.userBirthDayFirst = '앞 6자리 필수 입력 항목입니다.'
  }

  if (values.userBirthDayFirst?.length < 6) {
    errors.userBirthDayFirst = '앞 6자리 필수 입력 항목입니다.'
  }

  if (!values.userBirthDaySecond) {
    errors.userBirthDayFirst = '뒤 첫번째 1자리 필수 입력 항목입니다.'
  }

  // 지인 추천 , 추천코드 둘다 입력하면 불가
  if (!values.recommendCode) {
    errors.recommendCode = '추천코드를 입력해주세요.'
  }
  if (values.recommendCode && !values.isConfirmRecommendCode) {
    errors.recommendCode = '추천코드 확인해주세요.'
  }

  return errors
}

export const signInValidate = values => {
  const errors = {}
  if (!values.memberId) {
    errors.memberId = '필수 입력 항목입니다.'
  }
  if (!values.memberPassword) {
    errors.memberPassword = '필수 입력 항목입니다.'
  }

  return errors
}
