import React, { useMemo, useState } from 'react'
import { convertToKstDate } from '@/utils/dateTime'
import { commonReg2 } from '@/utils/validate/commonValidate'

const MobileFriendListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 25,
    },
    {
      Header: '지인 ID',
      accessor: 'friendId',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: '발생 롤링액',
      accessor: 'rollingPoint',
      disableGlobalFilter: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.rollingPoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '가입일',
      accessor: 'registrationDate',
      disableGlobalFilter: true,
      width: 55,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{convertToKstDate(original.registrationDate)}</>
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MobileFriendListData
