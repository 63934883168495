import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { confirmRecommendCode } from '../api/authManage/authManageApi'

// Thunk for confirming recommend code
export const confirmCode = createAsyncThunk('auth/confirmCode', async (recommendCode, { rejectWithValue }) => {
  try {
    const response = await confirmRecommendCode({ recommendCode })
    return response
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isCodeValid: false,
    status: 'idle',
    error: null,
  },
  reducers: {
    resetCodeValidity(state) {
      state.isCodeValid = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(confirmCode.pending, state => {
        state.status = 'loading'
      })
      .addCase(confirmCode.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.isCodeValid = true
      })
      .addCase(confirmCode.rejected, (state, action) => {
        state.status = 'failed'
        state.isCodeValid = false
        state.error = action.payload
      })
  },
})

export const { resetCodeValidity } = authSlice.actions

export default authSlice.reducer
