import React from 'react'
import { Navigate } from 'react-router'

const MiniGameMain = () => {
  return (
    <div className="div_content" style={{ position: 'relative' }}>
      <div className="div_body">
        <div className="content">
          <div
            style={{
              textAlign: 'center',
              margin: '20px',
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '10px',
              borderBottom: 'solid gray 1px',
            }}
          >
            <span style={{ fontSize: '20px', fontWeight: '500' }}>- 미니게임 -</span>
          </div>
          <div className="mini_main_wrapper">
            {/* <div className="mini_main_img">
              <img
                src={`${process.env.PUBLIC_URL}/img/main/mini_bg.png`}
                alt="Background"
                style={{ width: '100%', height: 'auto' }}
              />
            </div> */}
            <div className="mini_main_venderwrap">
              <div className="mini_main_venders">
                <div
                  className="mini_main_vender"
                  onClick={() => {
                    window.location.href = '/minigame/ntry/eos-pb-1m'
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/ntry_logo.png`} alt="Background" />
                </div>
                <div
                  className="mini_main_vender"
                  onClick={() => {
                    window.location.href = '/minigame/boscore/stat1m'
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/boscore_logo.png`} alt="Background" />
                </div>
                {/* <div
                  className="mini_main_vender"
                  onClick={() => {
                    window.location.href = '/minigame/named/n/powerball3m'
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/named_logo.png`} alt="Background" />
                </div>
                <div
                  className="mini_main_vender"
                  onClick={() => {
                    window.location.href = '/minigame/sureman/roulette1m'
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/sureman_logo.png`} alt="Background" />
                </div> */}
                <div
                  className="mini_main_vender"
                  onClick={() => {
                    window.location.href = '/minigame/gopick/raccoon'
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/gopick_logo.png`} alt="Background" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiniGameMain
