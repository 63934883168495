import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CasinoBettingHistoryRadio = ({ casinoBettingLogKey, checkRadio, setCheckRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        setCheckRadio(prev => [...prev, casinoBettingLogKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== casinoBettingLogKey))
        break
      default:
        break
    }
  }
  return (
    <div>
      <CustomCheckInput
        style={{ width: '100%' }}
        checked={checkRadio.find(e => e === casinoBettingLogKey)}
        type="checkbox"
        name="chech"
        onChange={e => {
          approveHandler(e)
        }}
      />
    </div>
  )
}
export default CasinoBettingHistoryRadio

CasinoBettingHistoryRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  casinoBettingLogKey: PropTypes.string,
}

CasinoBettingHistoryRadio.defaultProps = {
  casinoBettingLogKey: null,
  checkRadio: [],
}

const CustomCheckInput = styled.input`
  accent-color: ${props => (props.checked ? '#000' : 'initial')};
`
