import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { Card, CardBody } from '@/shared/components/Card'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import MobileMoneyWithdrawalListData from './MobileMoneyWithdrawalListData'
import { searchMemberMoneyWithdrawal } from '../../../api/memberHistory/memberHistoryApi'
import CustomLoading from '../../../containers/CustomLoading'
import { MyPageContentWrap, MyPageHeaderInfo, MyPageHeaderWrap } from '../../../containers/MyPage/MyPageElement'
import {
  MobileCustomerCenterContentWrap,
  MobileCustomerCenterHeaderInfo,
  MobileCustomerCenterHeaderWrap,
  MobileTopWrap,
  MobileTableWrap,
} from '../../../shared/components/MoneyCustomElement'

const MobileMoneyWithdrawalList = () => {
  /**
   * 회원 머니출금내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  const [params, setParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  // const { totalElement, content } = useSearchMemberMoneyWithdrawal(params)

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    searchMemberMoneyWithdrawal(params)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [params])

  const [moneyWithdrawalListData, setMoneyWithdrawalListData] = useState(MobileMoneyWithdrawalListData(content))

  useEffect(() => {
    setMoneyWithdrawalListData(MobileMoneyWithdrawalListData(content))
  }, [content])

  const [rows, setData] = useState(moneyWithdrawalListData.tableRowsData)
  useEffect(() => {
    setData(moneyWithdrawalListData.tableRowsData)
  }, [moneyWithdrawalListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <MobileTopWrap>
      <MobileCustomerCenterHeaderWrap>
        <MobileCustomerCenterHeaderInfo>머니 출금내역</MobileCustomerCenterHeaderInfo>
      </MobileCustomerCenterHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <MobileCustomerCenterContentWrap>
          <MobileTableWrap>
            <ReactTableBase columns={moneyWithdrawalListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </MobileTableWrap>
        </MobileCustomerCenterContentWrap>
      )}
    </MobileTopWrap>
  )
}

export default MobileMoneyWithdrawalList
