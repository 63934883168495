import React from 'react'

const NtryTabs = ({ activeMenu }) => {
  return (
    <div className="mini_content">
      <div className="tab_wrap">
        <ul className="sub_tap">
          <li
            className={activeMenu === 'NTRY_EOS_POWER_BALL_1M' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/eos-pb-1m'
            }}
          >
            <a>EOS파워볼 1분</a>
          </li>
          <li
            className={activeMenu === 'NTRY_EOS_POWER_BALL_2M' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/eos-pb-2m'
            }}
          >
            <a>EOS파워볼 2분</a>
          </li>
          <li
            className={activeMenu === 'NTRY_EOS_POWER_BALL_3M' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/eos-pb-3m'
            }}
          >
            <a>EOS파워볼 3분</a>
          </li>
          <li
            className={activeMenu === 'NTRY_EOS_POWER_BALL_4M' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/eos-pb-4m'
            }}
          >
            <a>EOS파워볼 4분</a>
          </li>
          <li
            className={activeMenu === 'NTRY_EOS_POWER_BALL_5M' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/eos-pb-5m'
            }}
          >
            <a>EOS파워볼 5분</a>
          </li>
          <li
            className={activeMenu === 'NTRY_EOS_SPEED_KENO' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/eos-speedkeno'
            }}
          >
            <a>EOS 스피드키노</a>
          </li>
          <li
            className={activeMenu === 'NTRY_POWER_BALL' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/eos-pb'
            }}
          >
            <a>파워볼</a>
          </li>
          <li
            className={activeMenu === 'NTRY_POWER_LADDER' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/power-ladder'
            }}
          >
            <a>파워사다리</a>
          </li>
          <li
            className={activeMenu === 'NTRY_KENO_LADDER' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/keno-ladder'
            }}
          >
            <a>키노사다리</a>
          </li>
          <li
            className={activeMenu === 'NTRY_SPEED_KENO' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/speed-keno'
            }}
          >
            <a>스피드키노</a>
          </li>
          <li
            className={activeMenu === 'NTRY_SEVEN_BALL_1MIN' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/seven-1m'
            }}
          >
            <a>세븐볼 1분</a>
          </li>
          <li
            className={activeMenu === 'NTRY_SEVEN_BALL_3MIN' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/seven-3m'
            }}
          >
            <a>세븐볼 3분</a>
          </li>
          <li
            className={activeMenu === 'NTRY_SEVEN_BALL_5MIN' ? 'item active' : 'item'}
            onClick={() => {
              window.location.href = '/minigame/ntry/seven-5m'
            }}
          >
            <a>세븐볼 5분</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default NtryTabs
