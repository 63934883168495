import { CustomCenterFormBottomWrap } from '@/shared/components/MoneyCustomElement'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  searchMemberAttendanceHistory,
  searchMemberYesterdayAttendance,
} from '../../api/memberHistory/memberHistoryApi'
import { createUserAttendance } from '../../api/memberManage/memberManageApi'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import SilverButtonH40 from '../../shared/components/SilverButton'
import { MoneyFormWrap, WebPageWrap } from '../../shared/components/form/newForm'
import { colorMainText } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomLoading from '../CustomLoading'
import AttendanceListData from './AttendanceListData'

const AttendanceForm = () => {
  const [myInfoLoading, setMyInfoLoading] = useState(true)
  const [assetLoading, setAssetLoading] = useState(true)
  const [loading, setLoading] = useState(true)

  /**
   *  회원 어제 출석연속 번호 조회
   */
  const [consecutiveNum, setConsecutiveNum] = useState(0)
  const [minimumAttendanceAmount, setMinimumAttendanceAmount] = useState(0)
  const [thirdAttendanceBonus, setThirdAttendanceBonus] = useState(0)
  const [sixthAttendanceBonus, setSixthAttendanceBonus] = useState(0)

  useEffect(() => {
    setLoading(true)
    searchMemberYesterdayAttendance()
      .then(res => {
        setConsecutiveNum(res.data.consecutiveNum)
        setMinimumAttendanceAmount(res.data.minimumAttendanceAmount)
        setThirdAttendanceBonus(res.data.thirdAttendanceBonus)
        setSixthAttendanceBonus(res.data.sixthAttendanceBonus)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const fetchSearchMemberConsecutiveNum = async () => {
    await searchMemberYesterdayAttendance()
      .then(res => {
        setConsecutiveNum(res.data.consecutiveNum)
      })
      .catch(error => {})
  }

  /**
   *  회원 출석내역 조회
   */

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberAttendanceHistory(historyParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [historyParams])

  const fetchSearchMemberAttendance = async () => {
    setLoading(true)
    await searchMemberAttendanceHistory(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [historyListData, setHistoryListData] = useState(AttendanceListData(content))

  useEffect(() => {
    setHistoryListData(AttendanceListData(content))
  }, [content])

  const [rows, setData] = useState(historyListData.tableRowsData)

  useEffect(() => {
    setData(historyListData.tableRowsData)
  }, [historyListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  // redux 사용
  const dispatch = useDispatch()

  const onSubmit = e => {
    if (apiFlag) return
    setApiFlag(true)
    const body = {}

    createUserAttendance(body)
      .then(res => {
        if (consecutiveNum === 2) {
          alert(`${thirdAttendanceBonus?.toString().replace(commonReg2, ',')} 포인트 지급이 완료됐습니다.`)
        } else if (consecutiveNum === 5) {
          alert(`${sixthAttendanceBonus?.toString().replace(commonReg2, ',')} 포인트 지급이 완료됐습니다.`)
        } else {
          alert('출석이 완료됐습니다.')
        }
        dispatch(fetchMemberInfo())
        fetchSearchMemberConsecutiveNum()
        fetchSearchMemberAttendance()
        setApiFlag(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ATTD-1001':
            alert(`${message}`)
            break
          case 'ATTD-1002':
            alert(`${message}`)
            break
          case 'EVENT-1002':
            alert(`${message}`)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('출석이 실패했습니다. 다시 시도해주세요.')
        }
        setApiFlag(false)
      })
  }

  return (
    <>
      <div className="div_content" style={{ position: 'relative' }}>
        <div className="div_body">
          <div>
            <table cellPadding="0" cellSpacing="0" style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ backgroundColor: '#9aeaf1' }}>
                    <div style={{ height: '15px' }} />
                    <div style={{ textAlign: 'center' }}>
                      <img alt="" src="/skin/hp0424/pc/img/dc_title.png" />
                    </div>

                    <div style={{ height: '20px' }} />
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      className="tb_solid_table"
                      style={{ width: '600px', fontWeight: 'bold', backgroundColor: '#9aeaf1', textAlign: 'center' }}
                      align="center"
                    >
                      <tbody>
                        <tr>
                          <td style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: '#fff' }}>
                            1일차
                          </td>
                          <td style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: '#fff' }}>
                            2일차
                          </td>
                          <td style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: '#fff' }}>
                            3일차
                          </td>
                          <td style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: '#fff' }}>
                            4일차
                          </td>
                          <td style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: '#fff' }}>
                            5일차
                          </td>
                          <td style={{ height: '24px', lineHeight: '24px', backgroundColor: '#3097a0', color: '#fff' }}>
                            6일차
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: '100px',
                              height: '100px',
                              verticalAlign: 'top',
                              textAlign: 'left',
                              backgroundImage:
                                consecutiveNum > 0
                                  ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                  : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                            }}
                          >
                            <div
                              style={{ textAlign: 'center', height: '100px', lineHeight: '125px', color: '#ff0000' }}
                            >
                              &nbsp;
                            </div>
                          </td>
                          <td
                            style={{
                              width: '100px',
                              height: '100px',
                              verticalAlign: 'top',
                              textAlign: 'left',
                              backgroundImage:
                                consecutiveNum > 1
                                  ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                  : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                            }}
                          >
                            <div
                              style={{ textAlign: 'center', height: '100px', lineHeight: '125px', color: '#ff0000' }}
                            >
                              &nbsp;
                            </div>
                          </td>
                          <td
                            style={{
                              width: '100px',
                              height: '100px',
                              verticalAlign: 'top',
                              textAlign: 'left',
                              backgroundImage:
                                consecutiveNum > 2
                                  ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                  : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                            }}
                          >
                            <div
                              style={{ textAlign: 'center', height: '100px', lineHeight: '125px', color: '#ff0000' }}
                            >
                              {thirdAttendanceBonus?.toString().replace(commonReg2, ',')}P
                            </div>
                          </td>
                          <td
                            style={{
                              width: '100px',
                              height: '100px',
                              verticalAlign: 'top',
                              textAlign: 'left',
                              backgroundImage:
                                consecutiveNum > 3
                                  ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                  : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                            }}
                          >
                            <div
                              style={{ textAlign: 'center', height: '100px', lineHeight: '125px', color: '#ff0000' }}
                            >
                              &nbsp;
                            </div>
                          </td>
                          <td
                            style={{
                              width: '100px',
                              height: '100px',
                              verticalAlign: 'top',
                              textAlign: 'left',
                              backgroundImage:
                                consecutiveNum > 4
                                  ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                  : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                            }}
                          >
                            <div
                              style={{ textAlign: 'center', height: '100px', lineHeight: '125px', color: '#ff0000' }}
                            >
                              &nbsp;
                            </div>
                          </td>
                          <td
                            style={{
                              width: '100px',
                              height: '100px',
                              verticalAlign: 'top',
                              textAlign: 'left',
                              backgroundImage:
                                consecutiveNum > 5
                                  ? `url(${process.env.PUBLIC_URL}/img/main/Check.jpg)`
                                  : `url(${process.env.PUBLIC_URL}/img/main/noCheck.jpg)`,
                            }}
                          >
                            <div
                              style={{ textAlign: 'center', height: '100px', lineHeight: '125px', color: '#ff0000' }}
                            >
                              {sixthAttendanceBonus?.toString().replace(commonReg2, ',')}P
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style={{ height: '20px' }} />
                    <div style={{ textAlign: 'center', color: '#3097a0' }}>
                      {minimumAttendanceAmount?.toString().replace(commonReg2, ',')}원 이상 입금 후 출석체크가 가능함.
                    </div>
                  </td>
                  <td style={{ width: '300px', backgroundColor: '#caf3f9', verticalAlign: 'top' }}>
                    <div style={{ height: '100px' }} />
                    <div style={{ textAlign: 'center' }}>
                      <a
                      //  href="javascript:check_evnet();"
                      >
                        <img alt="" src={`${process.env.PUBLIC_URL}/img/main/btGoCheck.png`} />
                      </a>
                    </div>
                    <div style={{ height: '30px' }} />
                    <div>
                      <div
                        style={{
                          width: '210px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          backgroundColor: '#fff',
                          border: '2px solid #aaaaaa',
                          borderRadius: '5px',
                          textAlign: 'center',
                        }}
                      >
                        <div style={{ height: '15px' }} />
                        <div style={{ fontSize: '20px', height: '30px', lineHeight: '30px' }}>
                          <b>
                            연속 출석 : <font color="red">{consecutiveNum}</font>일
                          </b>
                        </div>
                        <div style={{ height: '10px' }} />

                        {/* <div>
                          오늘 포인트 : <font color="red">500</font>포인트
                        </div> */}

                        <div style={{ height: '10px' }} />
                        <div>
                          금일이벤트 참여 : <font color="blue">미참여</font>
                        </div>
                        <div style={{ height: '15px' }} />
                      </div>
                    </div>
                    <div style={{ height: '25px' }} />
                    <div>
                      <div
                        style={{
                          width: '210px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          backgroundColor: '#77c2c8',
                          height: '1px',
                        }}
                      />
                    </div>
                    <div style={{ height: '20px' }} />
                    <div>
                      <img style={{ marginLeft: '50px' }} alt="" src="/skin/hp0424/pc/img/check_detail.jpg" />
                    </div>
                    <div style={{ height: '20px' }} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default AttendanceForm

const AttendanceBtn = styled(SilverButtonH40)`
  width: 202px;
  height: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:hover {
    cursor: pointer;
    background: #333;
  }
`

const AttendanceBtnInfo = styled.div`
  display: flex;
  color: white;
  &:hover {
    cursor: pointer;
  }
`

const AttendanceWrap = styled.div`
  width: 120px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: solid 1px #575757;
`

const AttendanceImg = styled.img`
  width: 110px;
  height: 110px;
  display: flex;
`

const AttendanceInfo = styled.div`
  display: flex;
  color: #b6b6b6;
`

const MoneyDepositHeaderThirdInfo = styled.span`
  font-style: normal;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

const MoneyDepositHeaderTwoWrap = styled.div`
  height: 50px;
  width: 100%;
  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

const MoneyDepositWrap = styled.div`
  margin-top: 10px;
  background: #000000;
  border: 1px solid #edae07;

  height: 100%;
  min-height: 550px;

  // position: relative;
  // top: 30px;
`

const MoneyDepositHeaderWrap = styled.div`
  border-top: 1px solid #edae07;
  padding: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;

  border-bottom: 1px solid #edae07;
`

const MoneyDepositHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 5px;
  /* 엘로우폰트 */

  color: #ffc01a;
`
export const MoneyDepositCardSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`

const MoneyDepositBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

export const MoneyDepositInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 20px;
`
