import axios from '../base/axios'

// recaptcha 확인
export async function createReloadCheckLog(body) {
  const response = await axios({
    url: '/tyson/member/v1/reload-check',
    method: 'POST',
    data: body,
  }).then(res => {
    return res.data
  })
  return response
}
