import React from 'react'

const CustomPagination = ({ currentPage, totalPages, onPageChange, activeColor = '#0748d7' }) => {
  const handlePageChange = pageNumber => {
    if (pageNumber === currentPage) return
    onPageChange(pageNumber)
  }
  const pageSize = 10 // 페이지 그룹의 크기

  // 현재 페이지 그룹의 시작 페이지와 끝 페이지 계산
  const startPage = Math.floor(currentPage / pageSize) * pageSize
  const endPage = Math.min(startPage + pageSize - 1, totalPages - 1)
  return (
    <div className="div_pager">
      <table className="tb_pager">
        <tbody>
          <tr>
            <td
              className="pager_icon"
              onClick={() => {
                if (currentPage === 0) return
                handlePageChange(0)
              }}
            >
              ◀
            </td>
            <td
              className="pager_icon"
              onClick={() => {
                if (currentPage === 0) return
                handlePageChange(currentPage - 1)
              }}
            >
              이전
            </td>
            {[...Array(endPage - startPage + 1).keys()].map(pageIndex => {
              const pageNumber = startPage + pageIndex
              return (
                <td
                  onClick={() => handlePageChange(pageNumber)}
                  style={{ color: currentPage === pageNumber ? `${activeColor}` : '#000' }}
                >
                  {pageNumber + 1}
                </td>
              )
            })}
            <td
              className="pager_icon"
              onClick={() => {
                if (currentPage === totalPages - 1) return
                handlePageChange(currentPage + 1)
              }}
            >
              다음
            </td>
            <td
              className="pager_icon"
              onClick={() => {
                if (currentPage === totalPages - 1) return
                handlePageChange(totalPages - 1)
              }}
            >
              ▶
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CustomPagination
