// 현재시간 기준 seconds 단위 가져오기
export const getCurrentTime = () => {
  const currentTime = new Date()
  const timestampInSeconds = Math.floor(currentTime.getTime() / 1000)
  return timestampInSeconds
}

// 현재시간 기준 00초로 변환해서 seconds 단위 가져오기
export const getCurrentTimeWithZeroSeconds = () => {
  const currentTime = new Date()
  currentTime.setSeconds(0)
  const timestampInSeconds = Math.floor(currentTime.getTime() / 1000)
  return timestampInSeconds
}

// 현재시간 기준 +1 분 00초로 변환해서 seconds 단위 가져오기
export const getCurrentTimePlusOneMinuteWithZeroSeconds = () => {
  const currentTime = new Date()
  currentTime.setSeconds(0)
  currentTime.setMinutes(currentTime.getMinutes() + 1)
  const timestampInSeconds = Math.floor(currentTime.getTime() / 1000)
  return timestampInSeconds
}

export const formatSeconds = seconds => {
  if (seconds === -1) {
    return '00'
  }
  return seconds < 10 ? `0${seconds}` : seconds
}
