import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import 'reactjs-crontab/dist/index.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import { VirtualGameSportType } from '../../utils/enums/VirtualGame/VirtualGameEnums'
import { HermesMainSelect, HermesMainText } from '../../utils/palette'
import { setVirtualGameParam } from '../../redux/virtualGameInfoSlice'

const VirtualGameHeader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { selectedVirtuaGameSportType } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      selectedVirtuaGameSportType: virtualGameBetting.selectedVirtuaGameSportType,
    }
  })

  const { virtualSportList } = useSelector(state => {
    const { virtualGameInfo } = state

    return {
      virtualSportList: virtualGameInfo.virtualSportList.toSorted((a, b) => a.sportOrderNum - b.sportOrderNum),
    }
  })

  const onClickSelectedVirtualSportHandler = sportType => {
    switch (sportType) {
      case 'VIRTUAL_SOCCER':
        if (window.location.pathname === '/virtual/soccer') {
          dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
          return
        }
        return navigate('/virtual/soccer')

      case 'VIRTUAL_BASKETBALL':
        if (window.location.pathname === '/virtual/basketball') {
          dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
          return
        }
        return navigate('/virtual/basketball')
      case 'VIRTUAL_BASEBALL':
        if (window.location.pathname === '/virtual/baseball') {
          dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
          return
        }
        return navigate('/virtual/baseball')
      case 'VIRTUAL_GREYHOUNDS':
        if (window.location.pathname === '/virtual/greyhounds') {
          dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
          return
        }
        return navigate('/virtual/greyhounds')
      case 'VIRTUAL_HORSE':
        if (window.location.pathname === '/virtual/horse') {
          dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
          return
        }
        return navigate('/virtual/horse')
      default:
        return navigate('/')
    }
  }

  console.log('virtualSportList::', virtualSportList)

  return (
    <>
      <div>
        <table className="tb_notice_box">
          <tbody>
            <tr>
              <td className="td_notice_td">
                <span style={{ fontSize: '10pt' }}>
                  <b>
                    <span style={{ color: 'rgb(0, 0, 0)' }}>
                      이용관련 규정은 게시판에 안내되어 있으니,숙지 후 이용 바랍니다.
                    </span>
                  </b>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className="tabZone">
          <div className="tabFrame">
            <ul>
              {virtualSportList.map(item => {
                return (
                  <li
                    onClick={() => {
                      onClickSelectedVirtualSportHandler(item.sportType)
                    }}
                    className={
                      item.sportType === selectedVirtuaGameSportType ? 'item category-all active' : 'item category-all'
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/virtual-game/${item.sportType}.png`}
                      width="20"
                      height="20"
                      alt=""
                    />

                    <div className="txt" style={{ marginLeft: '2px' }}>
                      {VirtualGameSportType[item.sportType]}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default VirtualGameHeader
