import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { searchMemberPointExchange } from '../../../api/memberHistory/memberHistoryApi'
import CustomLoading from '../../../containers/CustomLoading'
import {
  MobileCompHistoryHeaderWrap,
  MobileCustomerCenterContentWrap,
  MobileCustomerCenterHeaderInfo,
  MobileTableWrap,
  MobileTopWrap,
} from '../../../shared/components/MoneyCustomElement'
import MobilePointExchangeListData from './MobilePointExchangeListData'

const MobilePointExchangeList = () => {
  /**
   * 포인트 전환내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [params, setParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    searchMemberPointExchange(params)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [params])

  const [pointExchangeListData, setPointExchangeListData] = useState(MobilePointExchangeListData(content))

  useEffect(() => {
    setPointExchangeListData(MobilePointExchangeListData(content))
  }, [content])

  const [rows, setData] = useState(pointExchangeListData.tableRowsData)

  useEffect(() => {
    setData(pointExchangeListData.tableRowsData)
  }, [pointExchangeListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <MobileTopWrap>
      <MobileCompHistoryHeaderWrap>
        <MobileCustomerCenterHeaderInfo>포인트 전환내역</MobileCustomerCenterHeaderInfo>
      </MobileCompHistoryHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <MobileCustomerCenterContentWrap>
          <MobileTableWrap>
            <ReactTableBase columns={pointExchangeListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </MobileTableWrap>
        </MobileCustomerCenterContentWrap>
      )}
    </MobileTopWrap>
  )
}

export default MobilePointExchangeList
