import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const NoteRadio = ({ rowInfo, setCheckRadio, checkRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        if (rowInfo.status === 'NOREAD') {
          alert('미확인 건은 삭제하실 수 없습니다.')
          break
        }
        setCheckRadio(prev => [...prev, rowInfo.noteKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== rowInfo.noteKey))
        break
      default:
        break
    }
  }

  return (
    <div style={{ cursor: 'pointer', marginLeft: '-5px' }}>
      <CustomCheckInput
        style={{ width: '100%', cursor: 'pointer', minWidth: '13px' }}
        checked={rowInfo.status === 'NOREAD' ? false : checkRadio.find(e => e === rowInfo.noteKey)}
        type="checkbox"
        name="chech"
        onChange={e => approveHandler(e)}
      />
    </div>
  )
}
export default NoteRadio

NoteRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  rowInfo: PropTypes.shape({
    noteKey: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
}

NoteRadio.defaultProps = {
  checkRadio: [],
}

const CustomCheckInput = styled.input`
  accent-color: ${props => (props.checked ? '#000' : 'initial')};
`
