import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import isEqual from 'lodash/isEqual'
import { FixtureSortType, FixtureType, SportMarketType } from '../utils/enums/SportsGame/SportsGameEnums'

const calcBettingPer = state => {
  const priceNumList = state.bettingCartList.map(item => item.selectedBetInfo.priceNum)

  if (priceNumList.length === 0) {
    state.totalBettingPer = 0
    return
  }

  let bettingPerExcludeBonusPer = Number(priceNumList[0])

  for (let i = 1; i < priceNumList.length; i++) {
    const value = Number(priceNumList[i])
    bettingPerExcludeBonusPer *= value
  }

  state.bettingPerExcludeBonusPer = Math.floor(bettingPerExcludeBonusPer * 100) / 100

  if (Object.keys(state.selectedBonusFolder).length !== 0) {
    const totalBettingPer = bettingPerExcludeBonusPer * state.selectedBonusFolder.bonusFolderPer

    state.totalBettingPer = Math.floor(totalBettingPer * 100) / 100
  } else {
    state.totalBettingPer = Math.floor(bettingPerExcludeBonusPer * 100) / 100
  }
}

const checkBonusFolder = state => {
  if (Object.keys(state.selectedBonusFolder).length === 0) return

  const bonusFolderPerCriteria = state.sportsBettingInfos[state.activeSportsMarketType]?.bonusFolderPerCriteria || 0

  const bettingCartList = state.bettingCartList.filter(item => item.selectedBetInfo.priceNum >= bonusFolderPerCriteria)

  if (bettingCartList.length < state.selectedBonusFolder.bonusFolderNum) {
    state.selectedBonusFolder = {}
  }
}

const autoSelectBonusFolder = state => {
  const bonusFolderPerCriteria = state.sportsBettingInfos[state.activeSportsMarketType]?.bonusFolderPerCriteria || 0

  const bettingCartList = state.bettingCartList.filter(item => item.selectedBetInfo.priceNum >= bonusFolderPerCriteria)

  const len = bettingCartList.length

  let result

  const bonusFolderInfo = state.sportsBettingInfos[state.activeSportsMarketType]?.bonusFolderInfo || []

  for (let i = bonusFolderInfo.length - 1; i >= 0; i -= 1) {
    if (
      bonusFolderInfo[i].bonusFolderNum <= len &&
      bonusFolderInfo[i].bonusFolderNum !== 0 &&
      bonusFolderInfo[i].bonusFolderPer !== 0
    ) {
      result = bonusFolderInfo[i]
      break
    }
  }

  if (result) {
    state.selectedBonusFolder = result
  }
}

// Helper function to find item index by fixture
function findFixtureIndex(state, fixtureId) {
  return state.bettingCartList.findIndex(item => item.fixtureId === fixtureId)
}

// Helper function to find item index by fixture and market
function findFixtureMarketIndex(state, fixtureId, marketId) {
  return state.bettingCartList.findIndex(item => item.fixtureId === fixtureId && item.marketId === marketId)
}

// Helper function to handle market replacement or removal
function handleMarketModification(state, index, newItem) {
  const currentBetId = state.bettingCartList[index].selectedBetInfo.betId

  if (currentBetId === newItem.selectedBetInfo.betId) {
    state.bettingCartList.splice(index, 1)
  } else {
    state.bettingCartList[index] = newItem
  }
}

// Handle new market combination logic
function handleNewMarketCombination(state, itemList, newItem, combinationData) {
  // 사용은 하는데, 등록은 안한 것
  //

  if (itemList.length === 0) {
    // 담긴 마켓이 없을 때
    state.bettingCartList.push(newItem)
    return
  }

  //  조합 리스트에서 체크 하기
  let tempMarketId

  if (newItem.marketFamilyType === 2 && newItem.selectedBetInfo.betName === 'X') {
    // 무승부 Bet
    tempMarketId = 'X'
  } else {
    tempMarketId = newItem.marketId.toString()
  }

  const marketIds = [...itemList.map(item => item.marketId.toString()), tempMarketId]

  const isCombinationValid =
    combinationData?.marketCombinationIds?.some(combination =>
      marketIds.every(marketId => combination.includes(marketId)),
    ) ?? false

  if (isCombinationValid) {
    state.bettingCartList.push(newItem)
  } else {
    const marketNameList = [...itemList.map(item => item.marketName), newItem.marketName].join(', ')
    state.bettingCartAlertMessage = `[${marketNameList}] 은 조합이 불가능합니다.`
  }
}

const sportsBettingSlice = createSlice({
  name: 'sportsBetting',
  initialState: {
    /**
     * 스포츠 기본 정보
     */
    sportsBettingInfos: {},
    marketCombinationList: {},
    liveMarketCombinationList: {},

    // 국내형 / 해외형 / 스페셜 / 라이브
    activeSportsMarketType: SportMarketType.DOMESTIC,

    /**
     * 아래부터 배팅카트 요소
     */
    bettingCartLoading: false,
    bettingMoney: 0,
    totalBettingPer: 0, // 총 배당
    bettingPerExcludeBonusPer: 0,
    bettingCartList: [],
    selectedBonusFolder: {},
    bettingCartAlertMessage: '',
    bettingCartUpdateMessage: '',
    bettingDelay: false,
  },
  reducers: {
    // 초기 데이터 세팅
    setSportsBettingInfos: (state, action) => {
      const sportsBettingInfos = action.payload

      state.sportsBettingInfos = sportsBettingInfos.reduce((acc, item) => {
        if (!acc[item.sportMarketType]) {
          acc[item.sportMarketType] = {}
        }

        const {
          firstBonusFolderNum,
          firstBonusFolderPer,
          secondBonusFolderNum,
          secondBonusFolderPer,
          thirdBonusFolderNum,
          thirdBonusFolderPer,
          ...rest
        } = item

        acc[item.sportMarketType] = {
          ...rest,
          bonusFolderInfo: [
            {
              bonusFolderNum: firstBonusFolderNum,
              bonusFolderPer: firstBonusFolderPer,
            },
            {
              bonusFolderNum: secondBonusFolderNum,
              bonusFolderPer: secondBonusFolderPer,
            },
            {
              bonusFolderNum: thirdBonusFolderNum,
              bonusFolderPer: thirdBonusFolderPer,
            },
          ],
        }

        return acc
      }, {})
    },

    // 배팅 딜레이
    setBettingDelay: (state, action) => {
      state.bettingDelay = action.payload
    },

    setSportMarketCombination: (state, action) => {
      const marketCombinationList = action.payload

      state.marketCombinationList = marketCombinationList.reduce((acc, item) => {
        // sportId로 먼저 그룹화
        if (!acc[item.sportId]) {
          acc[item.sportId] = {}
        }

        // sportMarketType으로 그룹화
        if (!acc[item.sportId][item.sportMarketType]) {
          acc[item.sportId][item.sportMarketType] = {
            usedMarketCombination: item.usedMarketCombination,
            marketCombinationIds: [],
          }
        }

        // 현재 아이템을 해당 그룹에 추가
        if (item.marketCombinationId.length !== 0) {
          acc[item.sportId][item.sportMarketType].marketCombinationIds.push(item.marketCombinationId)
        }

        return acc
      }, {})
    },
    setSportLiveMarketCombination: (state, action) => {
      const liveMarketCombinationList = action.payload

      state.liveMarketCombinationList = liveMarketCombinationList.reduce((acc, item) => {
        // sportId로 먼저 그룹화
        if (!acc[item.sportId]) {
          acc[item.sportId] = {}
        }

        // sportMarketType으로 그룹화
        if (!acc[item.sportId][item.sportMarketType]) {
          acc[item.sportId][item.sportMarketType] = {
            usedMarketCombination: item.usedMarketCombination,
            marketCombinationIds: [],
          }
        }

        // 현재 아이템을 해당 그룹에 추가
        if (item.marketCombinationId.length !== 0) {
          acc[item.sportId][item.sportMarketType].marketCombinationIds.push(item.marketCombinationId)
        }

        return acc
      }, {})
    },
    setActiveSportsMarketType: (state, action) => {
      const activeSportsMarketType = action.payload

      state.activeSportsMarketType = activeSportsMarketType
    },

    // 배팅 카트 로딩 업데이트
    setBettingCartLoading: (state, action) => {
      state.bettingCartLoading = action.payload
    },

    // 보너스 폴더 담기
    setSelectedBonusFolder: (state, action) => {
      const selectedBonusFolder = action.payload

      if (Object.keys(selectedBonusFolder).length === 0) {
        state.selectedBonusFolder = selectedBonusFolder

        calcBettingPer(state)

        return
      }

      // 이미 안에 담겨있을 때, 삭제처리
      if (state.selectedBonusFolder.bonusFolderNum === selectedBonusFolder.bonusFolderNum) {
        state.selectedBonusFolder = {}

        calcBettingPer(state)

        return
      }

      const bonusFolderPerCriteria = state.sportsBettingInfos[state.activeSportsMarketType]?.bonusFolderPerCriteria || 0

      const bettingCartList = state.bettingCartList.filter(
        item => item.selectedBetInfo.priceNum >= bonusFolderPerCriteria,
      )

      if (bettingCartList.length >= selectedBonusFolder.bonusFolderNum) {
        state.selectedBonusFolder = selectedBonusFolder
        calcBettingPer(state)
      } else {
        if (bettingCartList.length === state.bettingCartList.length) {
          // 단순 폴더수 차이
          state.bettingCartAlertMessage = `${selectedBonusFolder.bonusFolderNum} 폴더 이상만 해당 보너스 선택이 가능합니다.`
        } else {
          state.bettingCartAlertMessage = `배당이 ${bonusFolderPerCriteria} 이하인 경우 보너스 선택이 불가합니다.`
        }
      }
    },

    // 배팅카트 담기
    addBettingCartItem: (state, action) => {
      const bettingCartItem = action.payload

      const { sportId, sportMarketType } = bettingCartItem

      // 1. 종목, 마켓 타입으로 먼저 조회

      const combinationList =
        state.activeSportsMarketType === SportMarketType.LIVE
          ? state.liveMarketCombinationList
          : state.marketCombinationList

      const marketCombinationData = combinationList?.[sportId]?.[sportMarketType] ?? {}

      if (marketCombinationData?.usedMarketCombination === false || Object.keys(marketCombinationData).length === 0) {
        const fixtureIndex = findFixtureIndex(state, bettingCartItem.fixtureId)

        if (fixtureIndex !== -1) {
          handleMarketModification(state, fixtureIndex, bettingCartItem)
        } else {
          state.bettingCartList.push(bettingCartItem)
        }
      } else {
        // 4. 사용하면, 체크 후 처리하기

        // 배열이 비워있으면, 배팅카트에 해당 경기에 해당하는 마켓이 있을시 팅겨내기
        const bettingCartItemList = state.bettingCartList.filter(item => item.fixtureId === bettingCartItem.fixtureId)

        const index = findFixtureMarketIndex(state, bettingCartItem.fixtureId, bettingCartItem.marketId)

        if (index !== -1) {
          handleMarketModification(state, index, bettingCartItem)
        } else {
          handleNewMarketCombination(state, bettingCartItemList, bettingCartItem, marketCombinationData)
        }
      }

      // 보너스 자동 선택
      const bonusFolderAutoSelect =
        state.sportsBettingInfos[state.activeSportsMarketType]?.bonusFolderAutoSelect || false

      if (bonusFolderAutoSelect) {
        autoSelectBonusFolder(state)
      }

      // 보너스 체크
      checkBonusFolder(state)

      // 총 배당 계산
      calcBettingPer(state)
    },

    // 배팅카트에서 단건 삭제
    removeBettingCartItem: (state, action) => {
      const bettingCartItem = action.payload

      const prevBettingCartList = [...state.bettingCartList]

      // 1. 동일한 마켓을 선택시 삭제하기
      const prevSelectedBetInfoIndex = prevBettingCartList.findIndex(
        prevBettingCartItem => prevBettingCartItem?.selectedBetInfo?.betId === bettingCartItem?.selectedBetInfo?.betId,
      )

      if (prevSelectedBetInfoIndex === -1) return

      state.bettingCartList.splice(prevSelectedBetInfoIndex, 1)

      // 보너스 자동 선택
      const bonusFolderAutoSelect =
        state.sportsBettingInfos[state.activeSportsMarketType]?.bonusFolderAutoSelect || false

      if (bonusFolderAutoSelect) {
        autoSelectBonusFolder(state)
      }

      // 보너스 체크
      checkBonusFolder(state)

      // 최종 총 배당 계산하기
      calcBettingPer(state)
    },
    // 배팅카트에서 전체 삭제
    removeAllBettingCartItem: (state, action) => {
      state.bettingCartList = []
      state.selectedBonusFolder = {}

      state.totalBettingPer = 0
      state.bettingMoney = 0
    },

    // 배팅카트 Alert Close 처리
    onClickBettingCartAlertCloseHandler: (state, action) => {
      state.bettingCartAlertMessage = ''
    },

    // 배팅카트 Alert Close 처리
    onClickBettingCartUpdateMessageCloseHandler: (state, action) => {
      state.bettingCartUpdateMessage = ''
    },

    // 경기 변경 등 웹소켓 용 처리 핸들러
    onUpdatedBettingCartItemHandlerByFixture: (state, action) => {
      if (state.bettingCartList.length === 0) return

      const { fixture, sportMarketType } = action.payload

      // 내려온 배당의 타입(해외향)이 현재 선택한 타입(국내형)과 다르면 무시
      if (state.activeSportsMarketType !== sportMarketType) return
      if (Object.keys(fixture).length === 0) return

      const findBettingCartItem = state.bettingCartList.filter(item => item.fixtureId === fixture.fixtureId)

      if (findBettingCartItem.length === 0) return

      let updated = false

      findBettingCartItem.forEach(item => {
        const prevItem = { ...item }

        Object.assign(item, fixture)

        if (!isEqual(prevItem, item) && !updated) {
          updated = true
        }
      })

      if (updated && !state.bettingCartUpdateMessage) {
        state.bettingCartUpdateMessage = '선택된 경기중 변경된 사항이 있습니다.'
      }
    },

    // 배당 변경 등 웹소켓 용 처리 핸들러
    onUpdatedBettingCartItemHandlerByMarket: (state, action) => {
      if (state.bettingCartList.length === 0) return

      const { market, sportMarketType } = action.payload

      // 내려온 배당의 타입(해외향)이 현재 선택한 타입(국내형)과 다르면 무시
      if (state.activeSportsMarketType !== sportMarketType) return
      if (market.length === 0) return

      const findBettingCartItem = state.bettingCartList.find(
        item => item.eventBookmakerKey === market.eventBookmakerKey,
      )

      if (!findBettingCartItem) return

      findBettingCartItem.usedMarket = market.usedMarket
      findBettingCartItem.marketVisible = market.marketVisible

      // bets 변경을 위한 작업
      const idx = market.betLines.findIndex(betLine =>
        betLine.bets.some(bet => bet.eventBetKey === findBettingCartItem.selectedBetInfo.eventBetKey),
      )

      if (idx !== -1) {
        findBettingCartItem.bets = market.betLines[idx]?.bets ?? []

        const betLine = market.betLines[idx]

        const updatedBet = betLine.bets.find(bet => bet.eventBetKey === findBettingCartItem.selectedBetInfo.eventBetKey)

        const { isActive, ...selectedBetInfo } = findBettingCartItem.selectedBetInfo

        if (!isEqual(selectedBetInfo, updatedBet) && !state.bettingCartUpdateMessage) {
          state.bettingCartUpdateMessage = '선택된 경기중 변경된 사항이 있습니다.'
        }

        findBettingCartItem.selectedBetInfo = updatedBet
      }
    },
  },
})

export const {
  setBettingDelay,
  setSportsBettingInfos,
  setSportMarketCombination,
  setSportLiveMarketCombination,
  setActiveSportsMarketType,
  setSelectedBonusFolder,
  setBettingCartLoading,
  addBettingCartItem,
  removeBettingCartItem,
  removeAllBettingCartItem,
  onClickMaxMinRadioButtonHandler,
  onClickBettingCartAlertCloseHandler,
  onClickBettingCartUpdateMessageCloseHandler,
  onUpdatedBettingCartItemHandlerByFixture,
  onUpdatedBettingCartItemHandlerByMarket,
} = sportsBettingSlice.actions

export default sportsBettingSlice.reducer
