import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaStar } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import {
  addLiveBettingCart,
  fetchLiveSportsEvents,
  fetchSportsBonusFolder,
  handleWebSocketLiveSportsEvent,
  onClickHideLiveSportsEventMarket,
} from '../../redux/sportsGameBettingSlice'
import CustomLoading from '../CustomLoading'
import { decodeAccessToken } from '../../utils/token'
import {
  HermesMainBorder,
  HermesMainHover,
  HermesMainHover2,
  HermesMainSelect,
  HermesMainText,
} from '../../utils/palette'
import { setSelectedSportId } from '../../redux/sportsLiveInfoSlice'

const LiveSportsGamePage = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  function createKey(item) {
    return `${item.sportsEventId}-${item.marketId}-${item.selectedOddName}-${item.name}`
  }

  function isActive(activeSportsMarketKeySet, sportsEventId, marketId, selectedOddName, name) {
    const key = createKey({ sportsEventId, marketId, selectedOddName, name })
    return activeSportsMarketKeySet.has(key)
  }

  const { loading, loadingInfo, liveSportsEventList, sportsTypeInfo } = useSelector(state => {
    const { sportsGameBetting } = state

    const { LIVE } = sportsGameBetting.bettingCartList

    const activeLiveSportsMarketKeySet = new Set([...LIVE.map(item => createKey(item))])

    const liveSportsTypeList = new Set(['ALL', 'BASKETBALL', 'BASEBALL', 'VOLLEYBALL', 'ICE_HOCKEY', 'E_SPORTS'])
    let updatedSportsTypeInfo = sportsGameBetting.sportsTypeInfo

    // 'ALL'을 제외한 liveSportsTypeList에 포함된 sportsType들을 필터링
    const filteredSportsTypeInfo = updatedSportsTypeInfo.filter(item => liveSportsTypeList.has(item.sportsType))

    // 'ALL'의 count 값을 'BASKETBALL', 'BASEBALL', 'VOLLEYBALL', 'ICE_HOCKEY', 'E_SPORTS'의 count 합으로 대체
    const restCountSum = filteredSportsTypeInfo
      .filter(item => item.sportsType !== 'ALL')
      .reduce((sum, item) => sum + item.count, 0)

    updatedSportsTypeInfo = filteredSportsTypeInfo.map(item => {
      if (item.sportsType === 'ALL') {
        return { ...item, count: restCountSum }
      }
      return item
    })

    return {
      loading: sportsGameBetting.loading,
      loadingInfo: sportsGameBetting.loadingInfo,
      sportsTypeInfo: updatedSportsTypeInfo,
      liveSportsEventList: sportsGameBetting.liveSportsEventList.map(event => ({
        ...event,
        markets: event.markets.map(market => ({
          ...market,
          isHomeActive: isActive(
            activeLiveSportsMarketKeySet,
            event.sportsEventId,
            market.marketId,
            market.homeOddName,
            market.marketName.includes('승무패') ? '' : market.name,
          ),
          isAwayActive: isActive(
            activeLiveSportsMarketKeySet,
            event.sportsEventId,
            market.marketId,
            market.awayOddName,
            market.marketName.includes('승무패') ? '' : market.name,
          ),
          isDrawActive: isActive(
            activeLiveSportsMarketKeySet,
            event.sportsEventId,
            market.marketId,
            'Draw',
            market.marketName.includes('승무패') ? '' : market.name,
          ),
        })),
      })),
    }
  })

  const [sportsType, setSportsType] = useState(null)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(30)

  useEffect(() => {
    dispatch(fetchSportsBonusFolder())
  }, [])

  const params = useMemo(() => {
    return {
      sportsType,
      page,
      size,
    }
  }, [sportsType, page, size])

  const onClickSportsEventType = info => {
    setSportsType(info)
    setPage(0)
  }

  const socketRef = useRef(null)
  const intervalIdRef = useRef(null)

  const handleSocketOpen = useCallback(() => {
    // 웹소켓 연결 후 초기 데이터 요청
    dispatch(fetchLiveSportsEvents(params))
  }, [dispatch])

  const handleSocketMessage = useCallback(
    event => {
      const message = JSON.parse(event.data)
      dispatch(handleWebSocketLiveSportsEvent(message))
    },
    [dispatch],
  )

  const handleSocketError = useCallback(event => {
    console.error('WebSocket error observed:', event)
    // logout 시키기
  }, [])

  useEffect(() => {
    // 웹소켓 인스턴스 생성
    if (location.pathname !== '/live-sports') {
      return
    }

    const userKey = decodeAccessToken().userKey || ''
    const newSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_LIVE_SPORTS_URL}?userKey=${userKey}`)
    socketRef.current = newSocket

    // 웹소켓 이벤트 리스너 등록
    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('message', handleSocketMessage)
    newSocket.addEventListener('error', handleSocketError)

    return () => {
      // 컴포넌트 언마운트 시 웹소켓 연결 종료
      if (socketRef.current) {
        socketRef.current.close()
      }
    }
  }, [location, handleSocketOpen, handleSocketMessage, handleSocketError])

  // params가 변경될 때마다 새로운 interval을 설정합니다.
  useEffect(() => {
    // 이전 interval 해제
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      dispatch(fetchLiveSportsEvents(params))
    }

    // 일정한 주기로 파라미터를 서버로 보내기
    const id = setInterval(() => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(JSON.stringify(params))
      }
    }, 1000) // 1초마다 파라미터 전송

    intervalIdRef.current = id

    return () => {
      // 컴포넌트 언마운트 시 타이머 해제
      clearInterval(intervalIdRef.current)
    }
  }, [params])

  const onClickHideSportsEventMainMarketHandler = (sportsEventId, visibled) => {
    dispatch(onClickHideLiveSportsEventMarket({ sportsEventId, isVisible: visibled }))
  }

  const onClickBetting = (type, item) => {
    dispatch(addLiveBettingCart({ type, item }))
  }

  const getMarketIconComponent = (marketName, oddName) => {
    switch (true) {
      case marketName.includes('승무패') || marketName.includes('승패'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
            alt=""
            style={{ width: '10px', height: '7px' }}
          />
        )
      case marketName.includes('핸디'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('언더'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('오버'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      default:
    }
  }

  const getSportsTypeIconUrl = type => {
    switch (type) {
      case 'SOCCER':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-soccer.png`} alt="" />
      case 'BASKETBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-basketball.png`} alt="" />
      case 'BASEBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-baseball.png`} alt="" />
      case 'VOLLEYBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-volleyball.png`} alt="" />
      case 'ICE_HOCKEY':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-ice-hockey.png`} alt="" />
      case 'HANDBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-handball.png`} alt="" />
      case 'TENNIS':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-tennis.png`} alt="" />
      case 'AMERICAN_FOOTBALL':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-football.png`} alt="" />
      case 'TABLE_TENNIS':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-table-tennis.png`} alt="" />
      case 'BOXING':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-boxing.png`} alt="" />
      case 'E_SPORTS':
        return <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-esports.png`} alt="" />
      default:
        return <div style={{ width: '16px', height: '23px' }} />
    }
  }

  return (
    <SportsGamePageWrap>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart info={loadingInfo || ''} />
        </SportsGamePageLoadingOverlay>
      )}
      <SportsGameHeaderBox>
        <SportsGameHeaderInnerBox>
          <div style={{ width: '100%', position: 'relative' }}>
            <div style={{ position: 'relative' }}>
              <SportsEventTypeListBox>
                {sportsTypeInfo.map((item, index) => (
                  <SportsEventTypeItemBox
                    onClick={() => {
                      onClickSportsEventType(item.sportsType === 'ALL' ? null : item.sportsType)
                    }}
                    active={(item.sportsType === 'ALL' && sportsType === null) || item.sportsType === sportsType}
                  >
                    <SportsEventTypeIcon>{getSportsTypeIconUrl(item.sportsType)}</SportsEventTypeIcon>
                    <SportsEventTypeTitle>{item.typeKr}</SportsEventTypeTitle>
                    <SportsEventTypeCount>{item.count}</SportsEventTypeCount>
                  </SportsEventTypeItemBox>
                ))}
              </SportsEventTypeListBox>
            </div>
          </div>
        </SportsGameHeaderInnerBox>
      </SportsGameHeaderBox>

      <SportsGameContentWrap>
        <LiveSportsGameInfoWrap>
          <LiveSportsGameInfoIcon>※</LiveSportsGameInfoIcon>
          <LiveSportsGameInfo>
            실시간으로 배당을 받아오기 때문에, 사라져도 자동갱신 되니 새로고침을 하지 않으셔도 됩니다. <br /> 실시간
            배팅규정은 “실시간 규정확인” 을 열람하시면 확인 가능합니다.{' '}
          </LiveSportsGameInfo>
        </LiveSportsGameInfoWrap>

        <SportsGameListWrap className="sidebar-container">
          {liveSportsEventList.length === 0 ? (
            <SportsGameEmptyInfoBox>현재 진행중인 경기가 없습니다.</SportsGameEmptyInfoBox>
          ) : (
            <>
              {liveSportsEventList.map(sportsEvent => (
                <SportsEventWrap key={`${sportsEvent.sportsEventId}/${sportsEvent.prematchId}`}>
                  <SportsEventTitleBox
                    onClick={() => {
                      onClickHideSportsEventMainMarketHandler(sportsEvent.sportsEventId, !sportsEvent.isVisible)
                    }}
                  >
                    <SportsEventTitleTypeImg
                      src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${sportsEvent.sportsType}.png`}
                      alt=""
                    />
                    {sportsEvent.ccImage && <SportsEventTitleCountryImg src={sportsEvent.ccImage} alt="" />}
                    <SportsEventTitleCountryInfo>{sportsEvent.ccKr}</SportsEventTitleCountryInfo>
                    <SportsEventTitleArrowRightIcon />
                    {sportsEvent.leagueImage && <SportsEventTitleLeagueImg src={sportsEvent.leagueImage} alt="" />}
                    <SportsEventTitleLeagueInfo>{sportsEvent.leagueName}</SportsEventTitleLeagueInfo>
                    <SportsEventTitleLeagueInfo style={{ marginLeft: '20px' }}>
                      {sportsEvent.homeName} vs {sportsEvent.awayName}
                    </SportsEventTitleLeagueInfo>
                    {sportsEvent.isVisible ? <SportsEventTitleArrowUpIcon /> : <SportsEventTitleArrowDownIcon />}
                  </SportsEventTitleBox>

                  {sportsEvent.isVisible && (
                    <SportsEventContentBox>
                      <SportsEventContentTopWrap>
                        <SportsEventContentTopDate>
                          <span>
                            {sportsEvent.statusKr} - {sportsEvent.periodKr}
                          </span>
                        </SportsEventContentTopDate>
                      </SportsEventContentTopWrap>
                      {sportsEvent.markets.map((market, index) => {
                        if (market.marketStop) return
                        return (
                          <SportsEventContentItem key={market.sportsMarketKey}>
                            <SportsEventContentItemMarketName>{market?.marketName}</SportsEventContentItemMarketName>
                            <SportsEventContentItemHomeBox
                              isActive={market.isHomeActive}
                              onClick={() => {
                                const type = 'LIVE'

                                const item = {
                                  sportsEventId: sportsEvent.sportsEventId,
                                  sportsType: sportsEvent.sportsType,
                                  sportsMarketKey: market.sportsMarketKey,
                                  marketId: market.marketId,
                                  leagueName: sportsEvent.leagueName,
                                  ccKr: sportsEvent.ccKr,
                                  startAt: sportsEvent.startAt,
                                  homeName: sportsEvent.homeName,
                                  awayName: sportsEvent.awayName,
                                  marketName: market.marketName,
                                  name: market.name,
                                  homeSportsMarketOddKey: market.homeSportsMarketOddKey,
                                  homeOddName: market.homeOddName,
                                  homeOddNameKr: market.homeOddNameKr,
                                  homeOddValue: market.homeOddValue,
                                  awaySportsMarketOddKey: market.awaySportsMarketOddKey,
                                  awayOddName: market.awayOddName,
                                  awayOddNameKr: market.awayOddNameKr,
                                  awayOddValue: market.awayOddValue,
                                  drawSportsMarketOddKey: market.drawSportsMarketOddKey,
                                  drawOddName: market.drawOddName,
                                  drawOddNameKr: market.drawOddNameKr,
                                  drawOddValue: market.drawOddValue,
                                  selectedSportsMarketPointKey: market.sportsMarketPointKey,
                                  selectedSportsMarketOddKey: market.homeSportsMarketOddKey,
                                  selectedOddName: market.homeOddName,
                                  selectedOddNameKr: market.homeOddNameKr,
                                  selectedOddValue: market.homeOddValue,
                                }

                                onClickBetting(type, item)
                              }}
                            >
                              <SportsEventContentItemHomeName>
                                {market?.marketName.includes('득점') ? (
                                  <>{market?.homeOddNameKr}</>
                                ) : (
                                  <>
                                    {sportsEvent.homeName}
                                    {sportsEvent.homeImage && <img src={sportsEvent.homeImage} alt="" />}
                                  </>
                                )}
                              </SportsEventContentItemHomeName>
                              <SportsEventContentItemHomeOddValue>
                                {getMarketIconComponent(market.marketName, market.homeOddNameKr)}

                                {market?.homeOddValue}
                              </SportsEventContentItemHomeOddValue>
                            </SportsEventContentItemHomeBox>
                            <SportsEventContentItemPoint
                              isActive={market.isDrawActive}
                              onClick={() => {
                                if (!market.marketName.includes('승무패')) return

                                const type = 'LIVE'

                                const item = {
                                  sportsEventId: sportsEvent.sportsEventId,
                                  sportsType: sportsEvent.sportsType,
                                  sportsMarketKey: market.sportsMarketKey,
                                  marketId: market.marketId,
                                  leagueName: sportsEvent.leagueName,
                                  ccKr: sportsEvent.ccKr,
                                  startAt: sportsEvent.startAt,
                                  homeName: sportsEvent.homeName,
                                  awayName: sportsEvent.awayName,
                                  marketName: market.marketName,
                                  name: '',
                                  homeSportsMarketOddKey: market.homeSportsMarketOddKey,
                                  homeOddName: market.homeOddName,
                                  homeOddNameKr: market.homeOddNameKr,
                                  homeOddValue: market.homeOddValue,
                                  awaySportsMarketOddKey: market.awaySportsMarketOddKey,
                                  awayOddName: market.awayOddName,
                                  awayOddNameKr: market.awayOddNameKr,
                                  awayOddValue: market.awayOddValue,
                                  drawSportsMarketOddKey: market.drawSportsMarketOddKey,
                                  drawOddName: market.drawOddName,
                                  drawOddNameKr: market.drawOddNameKr,
                                  drawOddValue: market.drawOddValue,
                                  selectedSportsMarketPointKey: market.sportsMarketPointKey,
                                  selectedSportsMarketOddKey: market.drawSportsMarketOddKey,
                                  selectedOddName: market.drawOddName,
                                  selectedOddNameKr: market.drawOddNameKr,
                                  selectedOddValue: market.drawOddValue,
                                }

                                onClickBetting(type, item)
                              }}
                            >
                              {market.drawOddValue || market?.name || 'VS'}
                            </SportsEventContentItemPoint>
                            <SportsEventContentItemAwayBox
                              isActive={market.isAwayActive}
                              onClick={() => {
                                const type = 'LIVE'

                                const item = {
                                  sportsEventId: sportsEvent.sportsEventId,
                                  sportsType: sportsEvent.sportsType,
                                  sportsMarketKey: market.sportsMarketKey,
                                  marketId: market.marketId,
                                  leagueName: sportsEvent.leagueName,
                                  ccKr: sportsEvent.ccKr,
                                  startAt: sportsEvent.startAt,
                                  homeName: sportsEvent.homeName,
                                  awayName: sportsEvent.awayName,
                                  marketName: market.marketName,
                                  name: market.name,
                                  homeSportsMarketOddKey: market.homeSportsMarketOddKey,
                                  homeOddName: market.homeOddName,
                                  homeOddNameKr: market.homeOddNameKr,
                                  homeOddValue: market.homeOddValue,
                                  awaySportsMarketOddKey: market.awaySportsMarketOddKey,
                                  awayOddName: market.awayOddName,
                                  awayOddNameKr: market.awayOddNameKr,
                                  awayOddValue: market.awayOddValue,
                                  drawSportsMarketOddKey: market.drawSportsMarketOddKey,
                                  drawOddName: market.drawOddName,
                                  drawOddNameKr: market.drawOddNameKr,
                                  drawOddValue: market.drawOddValue,
                                  selectedSportsMarketPointKey: market.sportsMarketPointKey,
                                  selectedSportsMarketOddKey: market.awaySportsMarketOddKey,
                                  selectedOddName: market.awayOddName,
                                  selectedOddNameKr: market.awayOddNameKr,
                                  selectedOddValue: market.awayOddValue,
                                }

                                onClickBetting(type, item)
                              }}
                            >
                              <SportsEventContentItemAwayOddValue>
                                {market?.awayOddValue}
                                {getMarketIconComponent(market.marketName, market.awayOddNameKr)}
                              </SportsEventContentItemAwayOddValue>
                              <SportsEventContentItemAwayName>
                                {market?.marketName.includes('득점') ? (
                                  <>{market?.awayOddNameKr}</>
                                ) : (
                                  <>
                                    {sportsEvent.awayImage && <img src={sportsEvent.awayImage} alt="" />}
                                    {sportsEvent.awayName}
                                  </>
                                )}
                              </SportsEventContentItemAwayName>
                            </SportsEventContentItemAwayBox>
                          </SportsEventContentItem>
                        )
                      })}
                    </SportsEventContentBox>
                  )}
                </SportsEventWrap>
              ))}
            </>
          )}
        </SportsGameListWrap>
      </SportsGameContentWrap>
    </SportsGamePageWrap>
  )
}

export default LiveSportsGamePage

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsGamePageWrap = styled.div`
  width: 1277px;
  overflow: hidden;
`

const SportsGameHeaderWrap = styled.div`
  border-top: 1px solid #edae07;
  width: 100%;
  height: 60px;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edae07;
`

const SportsGameHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #ffc01a;
`

const SportsGameEventCount = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  float: right;
  padding: 3px 5px 3px 5px;
  margin: 1px;
`

const SportsGameContentWrap = styled.div`
  width: 100%;
  height: calc(100vh - 165px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const SportsGameTypeWrap = styled.div`
  width: 30%;
  display: flex;
`
const SportsGameType = styled.div`
  width: 160px;
  height: 59px;
  float: left;
  margin-left: 1px;
  cursor: pointer;

  ${props =>
    props.$icon &&
    `
    background: ${
      props.active
        ? `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}-hover.png)`
        : `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}.png)`
    } no-repeat center;  
`}

  &:hover {
    background: ${props => props.$icon && `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}-hover.png)`}
      no-repeat center;
  }
`

const LiveSportsGameInfoIcon = styled.div`
  color: ${HermesMainHover2};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
`

const LiveSportsGameInfo = styled.div`
  color: white;
  padding: 10px 0 10px 10px;
  word-break: keep-all;
  line-height: 15px;
`

const LiveSportsGameInfoWrap = styled.div`
  width: 100%;
  border: 2px solid #222733;
  background: #191919;
  border-radius: 3px;
  padding: 5px;
  margin-top: 20px;
  position: relative;
  display: flex;
`

const SportsGameListWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  color: white;
`

// 경기가 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const SportsEventTitleBox = styled.div`
  // margin-top: 10px;
  padding: 0 10px;
  height: 36px;
  width: 100%;
  color: white;
  background: rgba(48, 48, 48, 1);
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const SportsEventTitleCountryImg = styled.img`
  width: 23px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleCountryInfo = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleArrowRightIcon = styled(MdOutlineArrowForwardIos)`
  color: ${HermesMainHover2};
  width: 17px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueImg = styled.img`
  width: 23px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueInfo = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
`
const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: ${HermesMainHover2};
  width: 20px;
  height: auto;
  margin-left: auto;
  cursor: pointer;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: ${HermesMainHover2};
  width: 20px;
  height: auto;
  margin-left: auto;
  cursor: pointer;
`

const SportsEventWrap = styled.div`
  width: 100%;
  padding: 10px 5px 10px 5px;
  display: flex;
  flex-direction: column;
`

const SportsEventContentTopWrap = styled.div`
  width: 100%;
  min-height: 1px;
  color: white;
  margin-bottom: 1px;
`

const SportsEventContentTopDate = styled.div`
  border-radius: 3px;
  color: #fff;
  float: left;
  padding: 4px 12px;
  background: ${HermesMainSelect};

  span {
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 500;
  }
`

const SportsEventContentItem = styled.div`
  width: 100%;
  height: 36px;
  color: white;
  display: flex;
  align-items: center;
`

const SportsEventContentItemMarketName = styled.div`
  min-width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(48, 48, 48, 1);
  color: #fff;
  font-weight: 600;
`

const SportsEventContentItemHomeName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }

  img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`

const SportsEventContentItemHomeOddValue = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  padding-right: 10px;
  &:hover {
    cursor: pointer;
  }

  img {
    margin-right: 5px;
  }
`

const SportsEventContentItemHomeBox = styled.div`
  width: calc(50% - 39px);
  height: 100%;
  display: flex;

  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);

  &:hover {
    cursor: pointer;
    background: ${HermesMainSelect};
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `}
`

const SportsEventContentItemPoint = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);
  font-weight: 600;

  &:hover {
    cursor: pointer;
    background: ${HermesMainSelect};
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `}
`

const SportsEventContentItemAwayName = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`

const SportsEventContentItemAwayOddValue = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  padding-left: 10px;

  &:hover {
    cursor: pointer;
  }

  img {
    margin-left: 5px;
  }
`

const SportsEventContentItemAwayBox = styled.div`
  width: calc(50% - 39px);
  height: 100%;
  display: flex;
  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);

  &:hover {
    cursor: pointer;
    background: ${HermesMainSelect};
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `}
`

const SportsEventContentBox = styled.div`
  width: 100%;
  min-height: 50px;
  color: white;
  padding: 7px;
  display: flex;
  flex-direction: column;
  border: 2px solid #3a3a3a;
  gap: 2px;
`

const SportsEventListWrap = styled.div`
  width: 100%;
  height: calc(100vh - 220px);
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

// 종목 부분
const SportsGameEventsWrap = styled.div`
  width: 100%;
  display: flex;
`

const SportsGameEvent = styled.div`
  width: 160px;
  height: 59px;
  float: left;
  margin-left: 1px;
  cursor: pointer;

  ${props =>
    props.$icon &&
    `
    background: ${
      props.active
        ? `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}-hover.png)`
        : `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}.png)`
    } no-repeat center;  
`}

  &:hover {
    background: ${props => props.$icon && `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}-hover.png)`}
      no-repeat center;
  }
`

// 다폴더 보너스 배당
const SportsGameMultiFolderWrap = styled.div`
  width: 100%;
  border: 2px solid #222733;
  background: #191919;
  border-radius: 3px;
  padding: 8px;
  margin-top: 20px;
  position: relative;
`

const SportsGameMultiFolderHeader = styled.div`
  position: absolute;
  left: 0px;
  top: -15px;
  background-color: #222733;
  border-radius: 5px;
  padding: 3px;
  float: left;
  padding: 3px 14px;
  display: flex;
  gap: 5px;
`

const SportsGameHeaderBox = styled.div`
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-top: 48px;
  box-sizing: border-box;
  position: relative;
`

const SportsGameHeaderInnerBox = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
`

const SportsEventTypeListBox = styled.div`
  width: calc(100% - 32px);
  float: left;
  display: block;
`

const SportsEventTypeItemBox = styled.div`
  float: left;
  background: #1c1f21;
  border-radius: 16px;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  margin-right: 16px;
  min-width: 120px;
  width: calc(16.6% - 16px);
  transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s;
  display: block;
  padding-right: 20px;

  // active 일 때,
  ${props =>
    props.active &&
    `
    background: ${HermesMainHover2};
    font-weight: 700;
 `}

  &:hover {
    background: ${HermesMainSelect};
    background: ${HermesMainText};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const SportsEventTypeIcon = styled.div`
  float: left;
  margin-right: 12px;
  display: block;

  img {
    margin-top: 3px;
    width: 16px;
    height: 16px;
  }
`

const SportsEventTypeTitle = styled.div`
  float: left;
  margin-top: 2px;
  display: block;
`

const SportsEventTypeCount = styled.div`
  float: right;
  margin-top: 2px;
  display: block;
`
