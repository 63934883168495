import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { GrFormClose } from 'react-icons/gr'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { searchNormalPopupImgList } from '../../../api/popupImg/popupImgApi'
import {
  CloseButton,
  CloseButtonWrap,
  PopupBody,
  PopupContent,
  PopupHeader,
  PopupImgItem,
  PopupImgWrap,
  PopupOverlay,
  PopupWrapper,
  MobilePopupWrapper,
  MobilePopupContent,
  MobilePopupBody,
  MobilePopupImgItem,
  CloseButtonWrap2,
  CloseButton2,
  CloseButton3,
} from '../PopupElements'
import Portal from '../../../shared/components/Portal'

/**
 * @see : https://sycdev.tistory.com/m/19
 */

const NormalPopup = () => {
  const location = useLocation()

  // visible : localStorage에서 가져와 -> 데이터가 있으면 false , 없으면 true
  const [popupList, setPopupList] = useState([])

  const checkVisible = title => {
    const check = localStorage.getItem(`popup-${title}`)
    const today = new Date().getDate()
    if (!check) {
      return true
    }
    return Number(check) !== today
  }

  useEffect(() => {
    searchNormalPopupImgList({ page: 0, size: 4 }).then(res => {
      const filteredItems = res.data.content.filter(item => item.mobileBigPopup)
      setPopupList(
        filteredItems.map(item => ({
          title: item.title,
          imgName: item.imgName,
          visible: checkVisible(item.title),
        })),
      )
    })
  }, [])

  useEffect(() => {
    if (popupList.filter(el => el.visible).length <= 0) {
      return () => {
        document.body.style = `overflow: auto`
      }
    }

    if (location.pathname !== '/mobile/main') {
      return () => {
        document.body.style = `overflow: auto`
      }
    }

    document.body.style = `overflow: hidden`
    return () => {
      document.body.style = `overflow: auto`
    }
  }, [popupList])

  const [visible, setVisible] = useState(true)
  const [totalVisible, setTotalVisible] = useState(false)

  const checkTotalVisible = () => {
    const visiblePopup = popupList.filter(value => value.visible === true)

    if (visiblePopup.length > 0) {
      setTotalVisible(true)
    } else {
      setTotalVisible(false)
    }
  }
  // 오늘 그만보기 , 닫기 핸들러

  const closeHandler = (e, isDayClose = false) => {
    checkTotalVisible()
    const title = e.target.getAttribute('value')
    // 오늘 그만보기 일때
    if (isDayClose) {
      // +1일 계산
      const expiredDate = new Date().getDate()
      // 로컬스토리지 저장
      localStorage.setItem(`popup-${title}`, expiredDate)
    }
    setVisible(false)
    setPopupList(prev => prev.map(item => (item.title === `${title}` ? { ...item, visible: false } : item)))
  }

  useEffect(() => {
    checkTotalVisible()
  }, [popupList])

  return (
    <>
      <Portal elementId="normal-popup">
        <PopupOverlay totalVisible={totalVisible} />
        <MobilePopupWrapper tabIndex="-1" visible={totalVisible}>
          {popupList.map((item, index) => {
            return (
              <>
                <MobilePopupContent visible={item.visible} tabIndex="0" key={index}>
                  <MobilePopupBody>
                    <CloseButtonWrap2>
                      <CloseButton3>
                        <img value={item.title} src={`${process.env.PUBLIC_URL}/img/popup/popup_notice.png`} alt="" />
                        공지사항
                      </CloseButton3>
                      <CloseButton2
                        value={item.title}
                        onClick={e => {
                          closeHandler(e, true)
                        }}
                      >
                        오늘 하루 열지 않기 <CustomClose />
                      </CloseButton2>
                    </CloseButtonWrap2>
                    <PopupImgWrap>
                      <MobilePopupImgItem src={item.imgName} />
                    </PopupImgWrap>
                    <CloseButtonWrap
                      value={item.title}
                      onClick={e => {
                        closeHandler(e)
                      }}
                    >
                      <img value={item.title} src={`${process.env.PUBLIC_URL}/img/popup/popup_new_close.png`} alt="" />
                    </CloseButtonWrap>
                  </MobilePopupBody>
                </MobilePopupContent>
              </>
            )
          })}
        </MobilePopupWrapper>
      </Portal>
    </>
  )
}

export default React.memo(NormalPopup)

const CustomClose = styled(GrFormClose)``

export const PopupCloseBtn = styled.div`
  &:hover {
    cursor: pointer;
    background: black;
  }
  position: absolute;
  left: 340px;
  // right: 5%;
  top: 10px;
  // bottom: 90%;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobilePopupCloseBtn = styled.div`
  &:hover {
    cursor: pointer;
    background: black;
  }
  position: absolute;
  // left: 260px;
  right: 10px;
  top: 10px;
  // bottom: 90%;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PopupIcon = styled.img`
  width: 25px;
  height: 25px;
`
