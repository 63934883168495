import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { HermesMainText } from '../../utils/palette'

const MobileFooterMenu = () => {
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(true)
  const [scrollTimeout, setScrollTimeout] = useState(null)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout)
      }
      setIsVisible(false)
      const newTimeout = setTimeout(() => {
        setIsVisible(true)
      }, 200)
      setScrollTimeout(newTimeout)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (scrollTimeout) {
        clearTimeout(scrollTimeout)
      }
    }
  }, [scrollTimeout])

  const navigate = useNavigate()

  if (location.pathname !== '/mobile/main') {
    return null
  }

  const onClickMenuHandler = link => {
    navigate(link)
  }

  return (
    <div>
      <footer id="footer" className={isVisible ? '' : 'footer-hidden'}>
        <div className="div_main_button2">
          <div style={{ height: '3px' }} />
          <div style={{ height: '20px', marginTop: '-10px', lineHeight: '20px', width: '80px', color: '#976038' }}>
            &nbsp;빠른메뉴
          </div>

          <div style={{ height: '0px', background: 'rgba(0,0,0,12)' }} />
          <div style={{ height: '3px' }} />

          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/money/deposit')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/charge.png`} height="45" />
                  <br />
                  충전하기
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/money/withdrawal')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/exchange.png`} height="45" />
                  <br />
                  환전하기
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/betting-history')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/bet_list.png`} height="45" />
                  <br />
                  배팅내역
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/onetoone')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/contact.png`} height="45" />
                  <br />
                  고객센터
                </td>
                <td className="td_bt_empty" />
                <td
                  onClick={() => {
                    onClickMenuHandler('/mobile/note')
                  }}
                >
                  <img alt="" src={`${process.env.PUBLIC_URL}/img/menu/mobile/message.png`} height="45" />
                  <br />
                  쪽지함
                </td>
                <td className="td_bt_empty" />
              </tr>
            </tbody>
          </table>
        </div>
      </footer>
    </div>
  )
}

export default MobileFooterMenu

const SolutionWrap = styled.div`
  width: 100%;
  margin-top: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  // background: #424242;
  color: white;
  font-family: 'Noto Sans', sans-serif;
`

// Footer bottom
const FooterBottomWrap = styled.div`
  box-sizing: border-box;
  background: rgb(17, 19, 20);
  // border: solid 2px blue;
  /* 라인컬러 */
  z-index: 115;
  // border-top: 2px solid #880000;
  padding: 0px 10px;
  padding-bottom: 10px;
`

const FooterBottomInfoWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  word-break: break-word;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const FooterBottomInfo = styled.span`
  font-style: normal;
  font-weight: 400;
  color: rgb(116, 116, 116);
`
