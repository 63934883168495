import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { searchSportsFixtureBalanceList } from '../../../api/game/sportsGameApi'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { selectSideSportMenu } from '../../../redux/sportsDomesticInfoSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import CustomLoading from '../../../containers/CustomLoading'
import MobileSportsBalanceContent from './MobileSportsBalanceContent'
import MobileSportsBettingCartSidebar from '../../MobileLayout/MobileSportsBettingCartSidebar'
import { KyochonMainText } from '../../../utils/palette'

const MobileSportsBalancePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { domesticLoading, bettingCartLoading } = useSelector(state => {
    const { sportsDomesticInfo, sportsBetting } = state

    return {
      domesticLoading: sportsDomesticInfo.domesticLoading,
      bettingCartLoading: sportsBetting.bettingCartLoading,
    }
  })

  return (
    <>
      {(domesticLoading || bettingCartLoading) && <CustomLoading />}
      <div style={{ padding: 0 }}>
        <div className="gamenoticewrap" style={{ textAlign: 'left', color: 'black', padding: '5px' }}>
          <div>
            <font color="#ffef00">
              <span style={{ fontSize: '18.6667px', lineHeight: '28px' }}>
                <b>
                  <span style={{ color: 'rgb(166, 207, 0)' }}>
                    <span style={{ color: `${KyochonMainText}`, fontSize: '12pt' }}>스포츠밸런스</span>
                    <br />
                  </span>
                </b>
              </span>
            </font>
          </div>
        </div>
        <MobileSportsBalanceContent />
        <MobileSportsBettingCartSidebar />
      </div>
    </>
  )
}

export default MobileSportsBalancePage
