import jwtDecode from 'jwt-decode'
import { getCookie, removeCookie, setCookie } from './cookie'

/**
 * Local Storage Access Token 조회
 */
export function getAccessToken() {
  return getCookie('ur_at')
}

export function deleteAccessToken() {
  removeCookie('ur_at')
}

export const decodeAccessToken = at => {
  const accessToken = at ?? getAccessToken()

  if (!accessToken) return null

  return jwtDecode(accessToken)
}

export function setAccessToken(accessToken) {
  const date = new Date()
  // date.setTime(date.getTime() + 1 * 30 * 1000) // 현재 시간 + 1분
  date.setTime(decodeAccessToken(accessToken).exp * 1000) // 토큰 만료시간

  if (window.location.host.includes('localhost')) {
    setCookie('ur_at', accessToken, {
      path: '/',
      secure: true,
      sameSite: 'none',
      expires: date,
    })
  } else {
    // eslint-disable-next-line no-useless-escape
    const domain = `.${window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1')}`

    setCookie('ur_at', accessToken, {
      path: '/',
      secure: true,
      domain,
      sameSite: 'none',
      expires: date,
    })
  }
}
