import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CustomAlert from '../../containers/CustomAlert'
import isLogin from '../../utils/enums/authority'
import MobileSignInPopup from '../MobileLayout/MobileSignIn'
import { fetchNoticeBoardList } from '../../redux/customCenterSlice'
import { BoardTypeEnums } from '../../utils/enums/Operation/boardEnums'

const MobileMainNoticeMenu = () => {
  const navigate = useNavigate()

  const { noteRedisSize, oneToOneRedisSize } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [boardParams, setBoardParams] = useState({
    boardType: 'NOTICE',
    page,
    size,
  })

  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.noticeInfo.totalElement,
      apiContent: customCenter.noticeInfo.content,
    }
  })

  const fetchSearchBoardList = async () => {
    try {
      dispatch(fetchNoticeBoardList(boardParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchBoardList()
  }, [])

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)
  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    navigate(link)
  }

  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {authorityAlertShow && <MobileSignInPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />}
      <div id="main_notice">
        <table style={{ width: '100%' }}>
          <tbody>
            {apiContent.length === 0 ? (
              <tr>
                <td>
                  <span className="subject">
                    <a>공지사항 내역이 없습니다.</a>
                  </span>
                </td>
              </tr>
            ) : (
              <>
                {apiContent.map(item => (
                  <>
                    <tr>
                      <td>
                        <span className="subject">
                          <a href="/mobile/notice">
                            [{BoardTypeEnums[item.type]}]&nbsp;{item.boardTitle}
                          </a>
                        </span>
                      </td>
                    </tr>
                  </>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default MobileMainNoticeMenu
