import {
  FormButtonToolbar,
  FormContainer,
  FormGroupDescription,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { commonReg2 } from '@/utils/validate/commonValidate'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMemberPointDeposit, searchMemberPointExchange } from '../../api/memberHistory/memberHistoryApi'
import { createMemberPointExchange } from '../../api/memberManage/memberManageApi'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import { convertToKstByFormat, minusDayToKst } from '../../utils/dateTime'
import {
  HermesMainBorder,
  colorMainText,
  colorMoneyExchangeBottomBorder,
  colorMoneyExchangeSvg,
  colorWalletSvg,
} from '../../utils/palette'
import MobilePointDepositListData from '../mobileMyPage/mobilePointDepositHistory/MobilePointDepositListData'
import './MobilePointExchange.css'

const MobilePointExchangeForm = ({ visible, onClose }) => {
  const [pageLoading, setPageLoading] = useState(true)
  const [writeLoading, setWriteLoading] = useState(false)
  const dispatch = useDispatch()
  /**
    회원 콤프액 조회
  */
  const { holdingMoney, pointAmount, memberTotalGameMoney } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      pointAmount: memberInfo.memberHoldingPoint,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
    }
  })

  /**
    회원 최근 일주일 롤링 전환내역 조회
*/
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [depositPage, setDepositPage] = useState(0)
  const [depositSize, setDepositSize] = useState(10)

  const [pointWeekHistoryParams, setPointWeekHistoryParams] = useState({
    page,
    size,
    startDate: minusDayToKst(7),
  })

  const [pointDepositHistoryParams, setPointDepositHistoryParams] = useState({
    page: depositPage,
    size: depositSize,
  })

  useEffect(() => {
    setPointDepositHistoryParams(prev => ({
      ...prev,
      page: depositPage,
    }))
  }, [depositPage])

  useEffect(() => {
    setPointWeekHistoryParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  // 포인트 입금 내역
  const [depositTotalElement, setDepositTotalElement] = useState(0)
  const [depositContent, setDepositContent] = useState([])

  const fetchSearchMemberPointDeposit = async () => {
    setPageLoading(true)
    await searchMemberPointDeposit(pointDepositHistoryParams)
      .then(response => {
        setDepositTotalElement(response.data.totalElement)
        setDepositContent(response.data.content)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
      })
  }

  const [pointDepositListData, setPointDepositListData] = useState(MobilePointDepositListData(depositContent))

  useEffect(() => {
    setPointDepositListData(MobilePointDepositListData(depositContent))
  }, [depositContent])

  const [depositRows, setDepositRowsData] = useState(pointDepositListData.tableRowsData)

  useEffect(() => {
    setDepositRowsData(pointDepositListData.tableRowsData)
  }, [pointDepositListData])

  // 포인트 전환 내역
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberPointExchange = async () => {
    setPageLoading(true)
    await searchMemberPointExchange(pointWeekHistoryParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberPointDeposit()
    fetchSearchMemberPointExchange()
  }, [])

  const onSubmit = e => {
    if (writeLoading) return
    if (pointAmount === 0) return
    setWriteLoading(true)
    createMemberPointExchange()
      .then(res => {
        alert('포인트 전환이 완료되었습니다.')
        setWriteLoading(false)
        fetchSearchMemberPointDeposit()
        fetchSearchMemberPointExchange()
        dispatch(fetchMemberInfo())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('포인트 전환이 실패했습니다. 다시 시도해주세요.')
        }
        setWriteLoading(false)
      })
  }

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <>
      <div style={{ padding: '0px 0 0px 0' }}>
        <div id="hiddenBar" style={{ background: '#fff', boxShadow: '0px 7px 6px -5px #DFE1E5' }}>
          <div className="header">
            <div style={{ height: '3px' }} />
            <table
              style={{ padding: '0px', border: '0px', borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '36px' }}>
                    <img
                      alt=""
                      style={{ height: '36px', width: '36px' }}
                      src="/m/images/menu_btn.png?v=1"
                      // onClick="showMenuOn()"
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <a
                    //  href="/m/Default.aspx"
                    >
                      <img src="/pc/account/img/google_lopo2.png?v=1" style={{ height: '36px' }} alt="" />
                    </a>
                  </td>
                  <td style={{ width: '36px' }}>
                    <img
                      style={{ width: '36px', height: '36px' }}
                      alt=""
                      src="/m/images/icons8-refresh-64.png?v=4"
                      //  onclick="window.location.reload()"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: '2px' }} />
          </div>
        </div>

        {/* <div id="page_title"> */}
        <div className="body_title">
          <span className="title1">
            <strong>포인트전환</strong>
          </span>{' '}
          <span className="title2">
            <strong>&nbsp;Point Exchange</strong>
          </span>
        </div>
        {/* </div> */}

        <div className="mypage_wrap">
          <div className="charge_box">
            <input type="hidden" name="action_mode" value="point_exchange" />
            <input type="hidden" name="my_point" id="my_point" value="1050" />
            <input type="hidden" name="min_point" id="min_point" value="5000" />
            <input type="hidden" name="charge_mode" id="charge_mode" value="mobile" />

            <table align="center" cellPadding="0" cellSpacing="0" border="0" width="100%" className="charge_box_table">
              <tbody>
                {/* <tr>
                  <td className="charge_box_td2" colSpan="2">
                    <div className="guide_text">
                      <ul>
                        <b>적립금 포인트 안내</b>
                      </ul>
                      <ul>
                        <li>
                          배팅머니 적립금(미당첨)
                          <br />
                          회원님께서 배팅하신 금액의 일정 % 만큼 포인트를 등급별로 차등지급됩니다.(미당첨시 적용){' '}
                        </li>
                        <li>적립금 포인트는 5,000원 이상 부터 배팅머니로 사용하여 사용 하실 수 있습니다.</li>
                      </ul>
                    </div>
                  </td>
                </tr> */}

                <tr>
                  <td className="charge_box_td1">&nbsp;보유</td>
                  <td className="charge_box_td2">
                    <span style={{ color: '#ffba00', fontSize: '12px' }}>
                      <b>{pointAmount?.toString().replace(commonReg2, ',')}&nbsp;포인트</b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="btn_wrap" style={{ paddingBottom: '10px' }}>
            <a
              onClick={() => {
                onSubmit()
              }}
            >
              <font
                className="bt_type1"
                style={{ width: '200px', height: '30px', lineHeight: '30px', fontWeight: '100' }}
              >
                포인트전환
              </font>
            </a>
          </div>
        </div>
        <div className="mypage_wrap">
          <table className="mypage_list_table">
            <tbody>
              {totalElement > 0 ? (
                <>
                  {content.map(item => {
                    return (
                      <tr>
                        <td>
                          {convertToKstByFormat(item.executeDate, 'YY.MM.DD')} [
                          {convertToKstByFormat(item.executeDate, 'HH:mm')}]&nbsp;
                          <b>{item.changePoint?.toString().replace(commonReg2, ',')} 포인트 전환</b>
                        </td>
                      </tr>
                    )
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan="2">최근 입금요청 내역이 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default MobilePointExchangeForm

export const CustomContentRowBig = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    flex: 1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
    height: 62px;
    font-size: 14px;
    color: #fff;
    font-weight: 700 !important;
    width: 110px;
    &:not(:last-child) {
      margin-right: 10px;
    }

    ${({ selectedIndex }) =>
      selectedIndex !== null &&
      `
      &:nth-child(${selectedIndex + 1}) {
        border-bottom: solid 1px ${HermesMainBorder};
      }
    `}
  }
`

// sign up form label
const CompFormLabel = styled.div`
  width: max-content;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: right;
`

export const CompFormField = styled.div`
  width: 400px;
  display: flex;
`

const CompExchangeWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;

  position: relative;
  top: 30px;

  svg {
    width: 40px;
    height: 40px;
    color: ${colorWalletSvg};
    margin: 0 5px;
  }
`

const CompExchangeHeaderWrap = styled.div`
  height: 100px;

  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  // border: 1px solid red;
  border-bottom: 1px solid #edae07;
`
const CompExchangeHeaderTwoWrap = styled.div`
  height: 50px;

  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const CompExchangeBodyOneWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 2px !important;
  padding: 10px 20px;
  // border: 1px solid red;
  // border-bottom: 1px solid #edae07;
`

const CompExchangeBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 20px;

  gap: 10px;
`

const CompExchangeBodyThirdWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  padding: 10px 2px;

  gap: 10px;
`

const CompExchangeHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #edae07;
`

const CompExchangeHeaderThirdInfo = styled.span`
  font-style: normal;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #edae07;
`

export const CompExchangeCardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 5px;
  color: ${colorMainText};
`

export const CompExchangeInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CompCustomFormInputWrap = styled(FormInputWrap)`
  width: 45%;
  text-align: left;
`
const CompFormGroupIconWrap = styled(FormGroupIcon)`
  width: max-content;
  font-size: 12px;
`

const CompCustomFormGroupIcon = styled(FormGroupIcon)`
  width: 26%;
  font-size: 12px;
`

export const CompFormContainer = styled(FormContainer)`
  ${props =>
    props.horizontal &&
    `

    ${FormGroupField} {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 120px;
    }

    @media screen and (min-width: 480px) {

      ${FormGroupLabel} {
        width: 120px;
      }

      ${FormGroupField} {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;

      }

      ${FormGroupDescription}, ${FormButtonToolbar} {
        margin-left: 0px;
      }
    }
  `}
`

export const CompIoIosArrowBack = styled(IoIosArrowBack)`
  color: ${colorMoneyExchangeSvg} !important;
`

const CompExchangeInfo = styled.div`
  float: left;
  width: 100%;
  border-bottom: solid 2px ${colorMoneyExchangeBottomBorder};
  margin: 0px 0px 7px 0px;
  font-size: 12px;
  font-weight: 700;
  color: ${colorMainText};
`

// endregion

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 0px;

  gap: 10px;

  .react-table__column-header {
    font-size: 12px;
    font-weight: 700;
    padding: 16px 0;
  }

  thead th {
    padding: 0;
  }

  tbody tr td {
    font-family: 'Noto Sans', sans-serif;
    padding: 14px 0;
    font-size: 12px;
    font-weight: 700;
  }
`
