import React, { useEffect, useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import PacmanLoader from 'react-spinners/PacmanLoader'
import BeatLoader from 'react-spinners/BeatLoader'
import styled from 'styled-components'
import { IoClose } from 'react-icons/io5'
import { ImWarning } from 'react-icons/im'
import { isMobile } from 'react-device-detect'
import { HermesMainBorder, HermesMainHover, HermesMainHover2, MainPageBackground } from '../utils/palette'

const CustomBettingAlert = ({ bettingDelayTime }) => {
  const [seconds, setSeconds] = useState(bettingDelayTime)

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1)
      }, 1000)
      return () => clearInterval(interval) // 컴포넌트가 언마운트될 때 인터벌 클리어
    }
  }, [seconds])

  return (
    <CustomAlertDialogWrap>
      <CustomAlertDialogBox isMobile={isMobile} style={{ marginTop: '20px' }}>
        <CustomAlertHeaderBox />
        <CustomAlertBodyWrap>
          <CustomAlertBodyBox>
            <CustomAlertBodyContentBox>
              <CustomAlertBodyContentHeaderBox>
                배팅
                {/* {!isInspection && <CustomIoClose onClick={() => onClickCloseHandler()} />} */}
              </CustomAlertBodyContentHeaderBox>
              <CustomAlertBodyContentBodyBox>
                <CustomAlertBodyContentBodyIconBox>
                  <CustomImWarning />
                </CustomAlertBodyContentBodyIconBox>
                <CustomAlertBodyContentBodyInfoBox>
                  <div>배팅 처리중입니다. </div>
                  <div>남은시간 {seconds}초</div>
                </CustomAlertBodyContentBodyInfoBox>
              </CustomAlertBodyContentBodyBox>
            </CustomAlertBodyContentBox>
          </CustomAlertBodyBox>
        </CustomAlertBodyWrap>
      </CustomAlertDialogBox>
    </CustomAlertDialogWrap>
  )
}

export default CustomBettingAlert

const CustomAlertDialogWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  display: block;
  z-index: 99999;

  ${props =>
    props.isInspection &&
    `
    background-color: rgba(0, 0, 0, 0.8);
`}
`

const CustomAlertDialogBox = styled.div`
  background: none;
  width: ${props => (props.isMobile ? '360px' : '380px')};
  transform: none;
  left: 0;
  position: relative;
  margin: 0 auto;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: block;
`

const CustomAlertHeaderBox = styled.div`
  display: none;
  padding: 20px 20px 10px;
  box-sizing: border-box;
`

const CustomAlertBodyWrap = styled.div`
  padding: 0;
  height: 100%;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  box-sizing: border-box;
  display: block;
`

const CustomAlertBodyBox = styled.div`
  transition: all 0.3s linear;
  opacity: 1;
  box-sizing: border-box;
  display: block;
`

const CustomAlertBodyContentBox = styled.div`
  border: none;
  box-shadow: 0 4px 64px rgba(0, 0, 0, 0.39);
  border-radius: 6px;
  padding: 16px;
  color: #000;
  background: linear-gradient(#fff, #f5fcff);
  box-sizing: border-box;
  display: block;
`

const CustomAlertBodyContentHeaderBox = styled.div`
  font-size: 16px;
  line-height: 30px;
  border-bottom: 1px solid #000;
  padding-bottom: 16px;
  position: relative;
  box-sizing: border-box;
  display: block;
  color: #000;
`

const CustomIoClose = styled(IoClose)`
  border-radius: 6px;
  transition: background-color 0.1s ease-out 0s;
  float: right !important;
  cursor: pointer;
  margin-top: 3px;
  width: 24px;
  height: 24px;

  &:hover {
    background-color: gray;
  }
`

const CustomAlertBodyContentBodyBox = styled.div`
  text-align: center;
  box-sizing: border-box;
  display: block;
`

const CustomAlertBodyContentBodyIconBox = styled.div`
  padding: 48px 0px 24px;
  position: relative;
  box-sizing: border-box;
  display: block;
`

const CustomImWarning = styled(ImWarning)`
  color: ${HermesMainHover};
  width: 68px;
  height: 68px;
`

const CustomAlertBodyContentBodyInfoBox = styled.div`
  box-sizing: border-box;
  display: block;
  div {
    word-break: keep-all;
    box-sizing: border-box;
    display: block;
    text-align: center;

    font-weight: 500;
  }
`

const CustomAlertBodyContentBodyCloseBtnBox = styled.div`
  background: ${HermesMainBorder};
  border: 1px solid ${HermesMainHover};
  transition: background-color 0.1s ease-out 0s, border 0.1s ease-out 0s;
  border-radius: 6px !important;
  cursor: pointer;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  margin-top: 24px !important;
  box-sizing: border-box;
  display: block;
  text-align: center;
`

const CheckButton = styled.div`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background: ${HermesMainHover2};
  }
  width: 100px;
  height: 30px;
  color: #fff;
  background: ${HermesMainBorder};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AlertOverlay = styled.div`
  position: ${props => (props.isMobile ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
`

const LoadingIconWrap = styled.div`
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
  height: 200px;
  background-color: ${MainPageBackground};

  display: flex;
  flex-direction: column;

  border: 1px solid ${HermesMainBorder};
  z-index: 900;

  ${props =>
    props.isMobile &&
    `
    position: absolute;
    top: 35vh;
    width: 100%;
    width: calc(100% - 10px);
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 300;
`}
`
const LoadingIcon = styled(BeatLoader)`
  position: relative;
  top: 40px;
  margin: 0 auto;
`

const LoadingInfoWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const LoadingInfo = styled.p`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  text-align: center;
  width: 100%;
  color: #fff;
`

const LoadingHeader = styled.div`
  height: 60px;
  border: 1px solid ${HermesMainBorder};

  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const LoadingHeaderLogo = styled.img`
  width: 200px;
  height: 50px;
`

export const CloseBtn = styled.div`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background: ${HermesMainHover2};
  }
  position: absolute;
  right: 2%;
  width: 40px;
  height: 40px;
  background: ${HermesMainBorder};
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
`
